export const countryList = [
  {
    name: "Afghanistan",
    iso3: "AFG",
    iso2: "AF",
    numeric_code: "004",
    phone_code: "93",
    capital: "Kabul",
    currency: "AFN",
    currency_name: "Afghan afghani",
    currency_symbol: "؋",
    tld: ".af",
    native: "افغانستان",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Afghan",
    timezones: [
      {
        zoneName: "Asia/Kabul",
        gmtOffset: 16200,
        gmtOffsetName: "UTC+04:30",
        abbreviation: "AFT",
        tzName: "Afghanistan Time",
      },
    ],
    translations: {
      kr: "아프가니스탄",
      "pt-BR": "Afeganistão",
      pt: "Afeganistão",
      nl: "Afghanistan",
      hr: "Afganistan",
      fa: "افغانستان",
      de: "Afghanistan",
      es: "Afganistán",
      fr: "Afghanistan",
      ja: "アフガニスタン",
      it: "Afghanistan",
      cn: "阿富汗",
      tr: "Afganistan",
    },
    latitude: "33.00000000",
    longitude: "65.00000000",
    emoji: "🇦🇫",
    emojiU: "U+1F1E6 U+1F1EB",
    states: [
      {
        id: 3901,
        name: "Badakhshan",
        state_code: "BDS",
        latitude: "36.73477250",
        longitude: "70.81199530",
        type: null,
      },
      {
        id: 3871,
        name: "Badghis",
        state_code: "BDG",
        latitude: "35.16713390",
        longitude: "63.76953840",
        type: null,
      },
      {
        id: 3875,
        name: "Baghlan",
        state_code: "BGL",
        latitude: "36.17890260",
        longitude: "68.74530640",
        type: null,
      },
      {
        id: 3884,
        name: "Balkh",
        state_code: "BAL",
        latitude: "36.75506030",
        longitude: "66.89753720",
        type: null,
      },
      {
        id: 3872,
        name: "Bamyan",
        state_code: "BAM",
        latitude: "34.81000670",
        longitude: "67.82121040",
        type: null,
      },
      {
        id: 3892,
        name: "Daykundi",
        state_code: "DAY",
        latitude: "33.66949500",
        longitude: "66.04635340",
        type: null,
      },
      {
        id: 3899,
        name: "Farah",
        state_code: "FRA",
        latitude: "32.49532800",
        longitude: "62.26266270",
        type: null,
      },
      {
        id: 3889,
        name: "Faryab",
        state_code: "FYB",
        latitude: "36.07956130",
        longitude: "64.90595500",
        type: null,
      },
      {
        id: 3870,
        name: "Ghazni",
        state_code: "GHA",
        latitude: "33.54505870",
        longitude: "68.41739720",
        type: null,
      },
      {
        id: 3888,
        name: "Ghōr",
        state_code: "GHO",
        latitude: "34.09957760",
        longitude: "64.90595500",
        type: null,
      },
      {
        id: 3873,
        name: "Helmand",
        state_code: "HEL",
        latitude: "39.29893610",
        longitude: "-76.61604720",
        type: null,
      },
      {
        id: 3887,
        name: "Herat",
        state_code: "HER",
        latitude: "34.35286500",
        longitude: "62.20402870",
        type: null,
      },
      {
        id: 3886,
        name: "Jowzjan",
        state_code: "JOW",
        latitude: "36.89696920",
        longitude: "65.66585680",
        type: null,
      },
      {
        id: 3902,
        name: "Kabul",
        state_code: "KAB",
        latitude: "34.55534940",
        longitude: "69.20748600",
        type: null,
      },
      {
        id: 3890,
        name: "Kandahar",
        state_code: "KAN",
        latitude: "31.62887100",
        longitude: "65.73717490",
        type: null,
      },
      {
        id: 3879,
        name: "Kapisa",
        state_code: "KAP",
        latitude: "34.98105720",
        longitude: "69.62145620",
        type: null,
      },
      {
        id: 3878,
        name: "Khost",
        state_code: "KHO",
        latitude: "33.33384720",
        longitude: "69.93716730",
        type: null,
      },
      {
        id: 3876,
        name: "Kunar",
        state_code: "KNR",
        latitude: "34.84658930",
        longitude: "71.09731700",
        type: null,
      },
      {
        id: 3900,
        name: "Kunduz Province",
        state_code: "KDZ",
        latitude: "36.72855110",
        longitude: "68.86789820",
        type: null,
      },
      {
        id: 3891,
        name: "Laghman",
        state_code: "LAG",
        latitude: "34.68976870",
        longitude: "70.14558050",
        type: null,
      },
      {
        id: 3897,
        name: "Logar",
        state_code: "LOG",
        latitude: "34.01455180",
        longitude: "69.19239160",
        type: null,
      },
      {
        id: 3882,
        name: "Nangarhar",
        state_code: "NAN",
        latitude: "34.17183130",
        longitude: "70.62167940",
        type: null,
      },
      {
        id: 3896,
        name: "Nimruz",
        state_code: "NIM",
        latitude: "31.02614880",
        longitude: "62.45041540",
        type: null,
      },
      {
        id: 3880,
        name: "Nuristan",
        state_code: "NUR",
        latitude: "35.32502230",
        longitude: "70.90712360",
        type: null,
      },
      {
        id: 3894,
        name: "Paktia",
        state_code: "PIA",
        latitude: "33.70619900",
        longitude: "69.38310790",
        type: null,
      },
      {
        id: 3877,
        name: "Paktika",
        state_code: "PKA",
        latitude: "32.26453860",
        longitude: "68.52471490",
        type: null,
      },
      {
        id: 3881,
        name: "Panjshir",
        state_code: "PAN",
        latitude: "38.88023910",
        longitude: "-77.17172380",
        type: null,
      },
      {
        id: 3895,
        name: "Parwan",
        state_code: "PAR",
        latitude: "34.96309770",
        longitude: "68.81088490",
        type: null,
      },
      {
        id: 3883,
        name: "Samangan",
        state_code: "SAM",
        latitude: "36.31555060",
        longitude: "67.96428630",
        type: null,
      },
      {
        id: 3885,
        name: "Sar-e Pol",
        state_code: "SAR",
        latitude: "36.21662800",
        longitude: "65.93336000",
        type: null,
      },
      {
        id: 3893,
        name: "Takhar",
        state_code: "TAK",
        latitude: "36.66980130",
        longitude: "69.47845410",
        type: null,
      },
      {
        id: 3898,
        name: "Urozgan",
        state_code: "URU",
        latitude: "32.92712870",
        longitude: "66.14152630",
        type: null,
      },
      {
        id: 3874,
        name: "Zabul",
        state_code: "ZAB",
        latitude: "32.19187820",
        longitude: "67.18944880",
        type: null,
      },
    ],
  },
  {
    name: "Aland Islands",
    iso3: "ALA",
    iso2: "AX",
    numeric_code: "248",
    phone_code: "+358-18",
    capital: "Mariehamn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ax",
    native: "Åland",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Aland Island",
    timezones: [
      {
        zoneName: "Europe/Mariehamn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "올란드 제도",
      "pt-BR": "Ilhas de Aland",
      pt: "Ilhas de Aland",
      nl: "Ålandeilanden",
      hr: "Ålandski otoci",
      fa: "جزایر الند",
      de: "Åland",
      es: "Alandia",
      fr: "Åland",
      ja: "オーランド諸島",
      it: "Isole Aland",
      cn: "奥兰群岛",
      tr: "Åland Adalari",
    },
    latitude: "60.11666700",
    longitude: "19.90000000",
    emoji: "🇦🇽",
    emojiU: "U+1F1E6 U+1F1FD",
    states: [],
  },
  {
    name: "Albania",
    iso3: "ALB",
    iso2: "AL",
    numeric_code: "008",
    phone_code: "355",
    capital: "Tirana",
    currency: "ALL",
    currency_name: "Albanian lek",
    currency_symbol: "Lek",
    tld: ".al",
    native: "Shqipëria",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Albanian ",
    timezones: [
      {
        zoneName: "Europe/Tirane",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "알바니아",
      "pt-BR": "Albânia",
      pt: "Albânia",
      nl: "Albanië",
      hr: "Albanija",
      fa: "آلبانی",
      de: "Albanien",
      es: "Albania",
      fr: "Albanie",
      ja: "アルバニア",
      it: "Albania",
      cn: "阿尔巴尼亚",
      tr: "Arnavutluk",
    },
    latitude: "41.00000000",
    longitude: "20.00000000",
    emoji: "🇦🇱",
    emojiU: "U+1F1E6 U+1F1F1",
    states: [
      {
        id: 603,
        name: "Berat County",
        state_code: "01",
        latitude: "40.69530120",
        longitude: "20.04496620",
        type: null,
      },
      {
        id: 629,
        name: "Berat District",
        state_code: "BR",
        latitude: "40.70863770",
        longitude: "19.94373140",
        type: null,
      },
      {
        id: 607,
        name: "Bulqizë District",
        state_code: "BU",
        latitude: "41.49425870",
        longitude: "20.21471570",
        type: null,
      },
      {
        id: 618,
        name: "Delvinë District",
        state_code: "DL",
        latitude: "39.94813640",
        longitude: "20.09558910",
        type: null,
      },
      {
        id: 608,
        name: "Devoll District",
        state_code: "DV",
        latitude: "40.64473470",
        longitude: "20.95066360",
        type: null,
      },
      {
        id: 610,
        name: "Dibër County",
        state_code: "09",
        latitude: "41.58881630",
        longitude: "20.23556470",
        type: null,
      },
      {
        id: 605,
        name: "Dibër District",
        state_code: "DI",
        latitude: "41.58881630",
        longitude: "20.23556470",
        type: null,
      },
      {
        id: 632,
        name: "Durrës County",
        state_code: "02",
        latitude: "41.50809720",
        longitude: "19.61631850",
        type: null,
      },
      {
        id: 639,
        name: "Durrës District",
        state_code: "DR",
        latitude: "41.37065170",
        longitude: "19.52110630",
        type: null,
      },
      {
        id: 598,
        name: "Elbasan County",
        state_code: "03",
        latitude: "41.12666720",
        longitude: "20.23556470",
        type: null,
      },
      {
        id: 631,
        name: "Fier County",
        state_code: "04",
        latitude: "40.91913920",
        longitude: "19.66393090",
        type: null,
      },
      {
        id: 627,
        name: "Fier District",
        state_code: "FR",
        latitude: "40.72750400",
        longitude: "19.56275960",
        type: null,
      },
      {
        id: 604,
        name: "Gjirokastër County",
        state_code: "05",
        latitude: "40.06728740",
        longitude: "20.10452290",
        type: null,
      },
      {
        id: 621,
        name: "Gjirokastër District",
        state_code: "GJ",
        latitude: "40.06728740",
        longitude: "20.10452290",
        type: null,
      },
      {
        id: 617,
        name: "Gramsh District",
        state_code: "GR",
        latitude: "40.86698730",
        longitude: "20.18493230",
        type: null,
      },
      {
        id: 600,
        name: "Has District",
        state_code: "HA",
        latitude: "42.79013360",
        longitude: "-83.61220120",
        type: null,
      },
      {
        id: 594,
        name: "Kavajë District",
        state_code: "KA",
        latitude: "41.18445290",
        longitude: "19.56275960",
        type: null,
      },
      {
        id: 628,
        name: "Kolonjë District",
        state_code: "ER",
        latitude: "40.33732620",
        longitude: "20.67946760",
        type: null,
      },
      {
        id: 630,
        name: "Korçë County",
        state_code: "06",
        latitude: "40.59056700",
        longitude: "20.61689210",
        type: null,
      },
      {
        id: 597,
        name: "Korçë District",
        state_code: "KO",
        latitude: "40.59056700",
        longitude: "20.61689210",
        type: null,
      },
      {
        id: 614,
        name: "Krujë District",
        state_code: "KR",
        latitude: "41.50947650",
        longitude: "19.77107320",
        type: null,
      },
      {
        id: 612,
        name: "Kuçovë District",
        state_code: "KC",
        latitude: "40.78370630",
        longitude: "19.87823480",
        type: null,
      },
      {
        id: 601,
        name: "Kukës County",
        state_code: "07",
        latitude: "42.08074640",
        longitude: "20.41429230",
        type: null,
      },
      {
        id: 623,
        name: "Kukës District",
        state_code: "KU",
        latitude: "42.08074640",
        longitude: "20.41429230",
        type: null,
      },
      {
        id: 622,
        name: "Kurbin District",
        state_code: "KB",
        latitude: "41.64126440",
        longitude: "19.70559500",
        type: null,
      },
      {
        id: 609,
        name: "Lezhë County",
        state_code: "08",
        latitude: "41.78137590",
        longitude: "19.80679160",
        type: null,
      },
      {
        id: 595,
        name: "Lezhë District",
        state_code: "LE",
        latitude: "41.78607300",
        longitude: "19.64607580",
        type: null,
      },
      {
        id: 596,
        name: "Librazhd District",
        state_code: "LB",
        latitude: "41.18292320",
        longitude: "20.31747690",
        type: null,
      },
      {
        id: 599,
        name: "Lushnjë District",
        state_code: "LU",
        latitude: "40.94198300",
        longitude: "19.69964280",
        type: null,
      },
      {
        id: 602,
        name: "Malësi e Madhe District",
        state_code: "MM",
        latitude: "42.42451730",
        longitude: "19.61631850",
        type: null,
      },
      {
        id: 637,
        name: "Mallakastër District",
        state_code: "MK",
        latitude: "40.52733760",
        longitude: "19.78297910",
        type: null,
      },
      {
        id: 635,
        name: "Mat District",
        state_code: "MT",
        latitude: "41.59376750",
        longitude: "19.99732440",
        type: null,
      },
      {
        id: 638,
        name: "Mirditë District",
        state_code: "MR",
        latitude: "41.76428600",
        longitude: "19.90205090",
        type: null,
      },
      {
        id: 619,
        name: "Peqin District",
        state_code: "PQ",
        latitude: "41.04709020",
        longitude: "19.75023840",
        type: null,
      },
      {
        id: 625,
        name: "Përmet District",
        state_code: "PR",
        latitude: "40.23618370",
        longitude: "20.35173340",
        type: null,
      },
      {
        id: 606,
        name: "Pogradec District",
        state_code: "PG",
        latitude: "40.90153140",
        longitude: "20.65562890",
        type: null,
      },
      {
        id: 620,
        name: "Pukë District",
        state_code: "PU",
        latitude: "42.04697720",
        longitude: "19.89609680",
        type: null,
      },
      {
        id: 624,
        name: "Sarandë District",
        state_code: "SR",
        latitude: "39.85921190",
        longitude: "20.02710010",
        type: null,
      },
      {
        id: 611,
        name: "Shkodër County",
        state_code: "10",
        latitude: "42.15037100",
        longitude: "19.66393090",
        type: null,
      },
      {
        id: 626,
        name: "Shkodër District",
        state_code: "SH",
        latitude: "42.06929850",
        longitude: "19.50325590",
        type: null,
      },
      {
        id: 593,
        name: "Skrapar District",
        state_code: "SK",
        latitude: "40.53499460",
        longitude: "20.28322170",
        type: null,
      },
      {
        id: 616,
        name: "Tepelenë District",
        state_code: "TE",
        latitude: "40.29666320",
        longitude: "20.01816730",
        type: null,
      },
      {
        id: 615,
        name: "Tirana County",
        state_code: "11",
        latitude: "41.24275980",
        longitude: "19.80679160",
        type: null,
      },
      {
        id: 633,
        name: "Tirana District",
        state_code: "TR",
        latitude: "41.32754590",
        longitude: "19.81869820",
        type: null,
      },
      {
        id: 636,
        name: "Tropojë District",
        state_code: "TP",
        latitude: "42.39821510",
        longitude: "20.16259550",
        type: null,
      },
      {
        id: 634,
        name: "Vlorë County",
        state_code: "12",
        latitude: "40.15009600",
        longitude: "19.80679160",
        type: null,
      },
      {
        id: 613,
        name: "Vlorë District",
        state_code: "VL",
        latitude: "40.46606680",
        longitude: "19.49135600",
        type: null,
      },
    ],
  },
  {
    name: "Algeria",
    iso3: "DZA",
    iso2: "DZ",
    numeric_code: "012",
    phone_code: "213",
    capital: "Algiers",
    currency: "DZD",
    currency_name: "Algerian dinar",
    currency_symbol: "دج",
    tld: ".dz",
    native: "الجزائر",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Algerian",
    timezones: [
      {
        zoneName: "Africa/Algiers",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "알제리",
      "pt-BR": "Argélia",
      pt: "Argélia",
      nl: "Algerije",
      hr: "Alžir",
      fa: "الجزایر",
      de: "Algerien",
      es: "Argelia",
      fr: "Algérie",
      ja: "アルジェリア",
      it: "Algeria",
      cn: "阿尔及利亚",
      tr: "Cezayir",
    },
    latitude: "28.00000000",
    longitude: "3.00000000",
    emoji: "🇩🇿",
    emojiU: "U+1F1E9 U+1F1FF",
    states: [
      {
        id: 1118,
        name: "Adrar",
        state_code: "01",
        latitude: "26.41813100",
        longitude: "-0.60147170",
        type: null,
      },
      {
        id: 1119,
        name: "Aïn Defla",
        state_code: "44",
        latitude: "36.25094290",
        longitude: "1.93938150",
        type: null,
      },
      {
        id: 1122,
        name: "Aïn Témouchent",
        state_code: "46",
        latitude: "35.29926980",
        longitude: "-1.13927920",
        type: null,
      },
      {
        id: 1144,
        name: "Algiers",
        state_code: "16",
        latitude: "36.69972940",
        longitude: "3.05761990",
        type: null,
      },
      {
        id: 1103,
        name: "Annaba",
        state_code: "23",
        latitude: "36.80205080",
        longitude: "7.52472430",
        type: null,
      },
      {
        id: 1142,
        name: "Batna",
        state_code: "05",
        latitude: "35.59659540",
        longitude: "5.89871390",
        type: null,
      },
      {
        id: 1108,
        name: "Béchar",
        state_code: "08",
        latitude: "31.62380980",
        longitude: "-2.21624430",
        type: null,
      },
      {
        id: 1128,
        name: "Béjaïa",
        state_code: "06",
        latitude: "36.75152580",
        longitude: "5.05568370",
        type: null,
      },
      {
        id: 4909,
        name: "Béni Abbès",
        state_code: "53",
        latitude: "30.08310420",
        longitude: "-2.83450520",
        type: null,
      },
      {
        id: 1114,
        name: "Biskra",
        state_code: "07",
        latitude: "34.84494370",
        longitude: "5.72485670",
        type: null,
      },
      {
        id: 1111,
        name: "Blida",
        state_code: "09",
        latitude: "36.53112300",
        longitude: "2.89762540",
        type: null,
      },
      {
        id: 4908,
        name: "Bordj Baji Mokhtar",
        state_code: "52",
        latitude: "22.96633500",
        longitude: "-3.94727320",
        type: null,
      },
      {
        id: 1116,
        name: "Bordj Bou Arréridj",
        state_code: "34",
        latitude: "36.07399250",
        longitude: "4.76302710",
        type: null,
      },
      {
        id: 1104,
        name: "Bouïra",
        state_code: "10",
        latitude: "36.36918460",
        longitude: "3.90061940",
        type: null,
      },
      {
        id: 1125,
        name: "Boumerdès",
        state_code: "35",
        latitude: "36.68395590",
        longitude: "3.62178020",
        type: null,
      },
      {
        id: 1105,
        name: "Chlef",
        state_code: "02",
        latitude: "36.16935150",
        longitude: "1.28910360",
        type: null,
      },
      {
        id: 1121,
        name: "Constantine",
        state_code: "25",
        latitude: "36.33739110",
        longitude: "6.66381200",
        type: null,
      },
      {
        id: 4912,
        name: "Djanet",
        state_code: "56",
        latitude: "23.83108720",
        longitude: "8.70046720",
        type: null,
      },
      {
        id: 1098,
        name: "Djelfa",
        state_code: "17",
        latitude: "34.67039560",
        longitude: "3.25037610",
        type: null,
      },
      {
        id: 1129,
        name: "El Bayadh",
        state_code: "32",
        latitude: "32.71488240",
        longitude: "0.90566230",
        type: null,
      },
      {
        id: 4905,
        name: "El M'ghair",
        state_code: "49",
        latitude: "33.95405610",
        longitude: "5.13464180",
        type: null,
      },
      {
        id: 4906,
        name: "El Menia",
        state_code: "50",
        latitude: "31.36422500",
        longitude: "2.57844950",
        type: null,
      },
      {
        id: 1099,
        name: "El Oued",
        state_code: "39",
        latitude: "33.36781100",
        longitude: "6.85165110",
        type: null,
      },
      {
        id: 1100,
        name: "El Tarf",
        state_code: "36",
        latitude: "36.75766780",
        longitude: "8.30763430",
        type: null,
      },
      {
        id: 1127,
        name: "Ghardaïa",
        state_code: "47",
        latitude: "32.49437410",
        longitude: "3.64446000",
        type: null,
      },
      {
        id: 1137,
        name: "Guelma",
        state_code: "24",
        latitude: "36.46274440",
        longitude: "7.43308330",
        type: null,
      },
      {
        id: 1112,
        name: "Illizi",
        state_code: "33",
        latitude: "26.16900050",
        longitude: "8.48424650",
        type: null,
      },
      {
        id: 4914,
        name: "In Guezzam",
        state_code: "58",
        latitude: "20.38643230",
        longitude: "4.77893940",
        type: null,
      },
      {
        id: 4913,
        name: "In Salah",
        state_code: "57",
        latitude: "27.21492290",
        longitude: "1.84843960",
        type: null,
      },
      {
        id: 1113,
        name: "Jijel",
        state_code: "18",
        latitude: "36.71796810",
        longitude: "5.98325770",
        type: null,
      },
      {
        id: 1126,
        name: "Khenchela",
        state_code: "40",
        latitude: "35.42694040",
        longitude: "7.14601550",
        type: null,
      },
      {
        id: 1138,
        name: "Laghouat",
        state_code: "03",
        latitude: "33.80783410",
        longitude: "2.86282940",
        type: null,
      },
      {
        id: 1134,
        name: "M'Sila",
        state_code: "28",
        latitude: "35.71866460",
        longitude: "4.52334230",
        type: null,
      },
      {
        id: 1124,
        name: "Mascara",
        state_code: "29",
        latitude: "35.39041250",
        longitude: "0.14949880",
        type: null,
      },
      {
        id: 1109,
        name: "Médéa",
        state_code: "26",
        latitude: "36.26370780",
        longitude: "2.75878570",
        type: null,
      },
      {
        id: 1132,
        name: "Mila",
        state_code: "43",
        latitude: "36.36479570",
        longitude: "6.15269850",
        type: null,
      },
      {
        id: 1140,
        name: "Mostaganem",
        state_code: "27",
        latitude: "35.95830540",
        longitude: "0.33718890",
        type: null,
      },
      {
        id: 1102,
        name: "Naama",
        state_code: "45",
        latitude: "33.26673170",
        longitude: "-0.31286590",
        type: null,
      },
      {
        id: 1101,
        name: "Oran",
        state_code: "31",
        latitude: "35.60823510",
        longitude: "-0.56360900",
        type: null,
      },
      {
        id: 1139,
        name: "Ouargla",
        state_code: "30",
        latitude: "32.22648630",
        longitude: "5.72998210",
        type: null,
      },
      {
        id: 4907,
        name: "Ouled Djellal",
        state_code: "51",
        latitude: "34.41782210",
        longitude: "4.96858430",
        type: null,
      },
      {
        id: 1136,
        name: "Oum El Bouaghi",
        state_code: "04",
        latitude: "35.86887890",
        longitude: "7.11082660",
        type: null,
      },
      {
        id: 1130,
        name: "Relizane",
        state_code: "48",
        latitude: "35.73834050",
        longitude: "0.75328090",
        type: null,
      },
      {
        id: 1123,
        name: "Saïda",
        state_code: "20",
        latitude: "34.84152070",
        longitude: "0.14560550",
        type: null,
      },
      {
        id: 1141,
        name: "Sétif",
        state_code: "19",
        latitude: "36.30733890",
        longitude: "5.56172790",
        type: null,
      },
      {
        id: 4902,
        name: "Sidi Bel Abbès",
        state_code: "22",
        latitude: "34.68060240",
        longitude: "-1.09994950",
        type: null,
      },
      {
        id: 1110,
        name: "Skikda",
        state_code: "21",
        latitude: "36.67211980",
        longitude: "6.83509990",
        type: null,
      },
      {
        id: 1143,
        name: "Souk Ahras",
        state_code: "41",
        latitude: "36.28010620",
        longitude: "7.93840330",
        type: null,
      },
      {
        id: 1135,
        name: "Tamanghasset",
        state_code: "11",
        latitude: "22.79029720",
        longitude: "5.51932680",
        type: null,
      },
      {
        id: 1117,
        name: "Tébessa",
        state_code: "12",
        latitude: "35.12906910",
        longitude: "7.95928630",
        type: null,
      },
      {
        id: 1106,
        name: "Tiaret",
        state_code: "14",
        latitude: "35.37086890",
        longitude: "1.32178520",
        type: null,
      },
      {
        id: 4910,
        name: "Timimoun",
        state_code: "54",
        latitude: "29.67890600",
        longitude: "0.50046080",
        type: null,
      },
      {
        id: 1120,
        name: "Tindouf",
        state_code: "37",
        latitude: "27.80631190",
        longitude: "-5.72998210",
        type: null,
      },
      {
        id: 1115,
        name: "Tipasa",
        state_code: "42",
        latitude: "36.54626500",
        longitude: "2.18432850",
        type: null,
      },
      {
        id: 1133,
        name: "Tissemsilt",
        state_code: "38",
        latitude: "35.60537810",
        longitude: "1.81309800",
        type: null,
      },
      {
        id: 1131,
        name: "Tizi Ouzou",
        state_code: "15",
        latitude: "36.70691100",
        longitude: "4.23333550",
        type: null,
      },
      {
        id: 1107,
        name: "Tlemcen",
        state_code: "13",
        latitude: "34.67802840",
        longitude: "-1.36621600",
        type: null,
      },
      {
        id: 4911,
        name: "Touggourt",
        state_code: "55",
        latitude: "33.12484760",
        longitude: "5.78327150",
        type: null,
      },
    ],
  },
  {
    name: "American Samoa",
    iso3: "ASM",
    iso2: "AS",
    numeric_code: "016",
    phone_code: "+1-684",
    capital: "Pago Pago",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".as",
    native: "American Samoa",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "American Samoan",
    timezones: [
      {
        zoneName: "Pacific/Pago_Pago",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time",
      },
    ],
    translations: {
      kr: "아메리칸사모아",
      "pt-BR": "Samoa Americana",
      pt: "Samoa Americana",
      nl: "Amerikaans Samoa",
      hr: "Američka Samoa",
      fa: "ساموآی آمریکا",
      de: "Amerikanisch-Samoa",
      es: "Samoa Americana",
      fr: "Samoa américaines",
      ja: "アメリカ領サモア",
      it: "Samoa Americane",
      cn: "美属萨摩亚",
      tr: "Amerikan Samoasi",
    },
    latitude: "-14.33333333",
    longitude: "-170.00000000",
    emoji: "🇦🇸",
    emojiU: "U+1F1E6 U+1F1F8",
    states: [],
  },
  {
    name: "Andorra",
    iso3: "AND",
    iso2: "AD",
    numeric_code: "020",
    phone_code: "376",
    capital: "Andorra la Vella",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ad",
    native: "Andorra",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Andorran",
    timezones: [
      {
        zoneName: "Europe/Andorra",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "안도라",
      "pt-BR": "Andorra",
      pt: "Andorra",
      nl: "Andorra",
      hr: "Andora",
      fa: "آندورا",
      de: "Andorra",
      es: "Andorra",
      fr: "Andorre",
      ja: "アンドラ",
      it: "Andorra",
      cn: "安道尔",
      tr: "Andorra",
    },
    latitude: "42.50000000",
    longitude: "1.50000000",
    emoji: "🇦🇩",
    emojiU: "U+1F1E6 U+1F1E9",
    states: [
      {
        id: 488,
        name: "Andorra la Vella",
        state_code: "07",
        latitude: "42.50631740",
        longitude: "1.52183550",
        type: null,
      },
      {
        id: 489,
        name: "Canillo",
        state_code: "02",
        latitude: "42.59782490",
        longitude: "1.65663770",
        type: null,
      },
      {
        id: 487,
        name: "Encamp",
        state_code: "03",
        latitude: "42.53597640",
        longitude: "1.58367730",
        type: null,
      },
      {
        id: 492,
        name: "Escaldes-Engordany",
        state_code: "08",
        latitude: "42.49093790",
        longitude: "1.58869660",
        type: null,
      },
      {
        id: 493,
        name: "La Massana",
        state_code: "04",
        latitude: "42.54562500",
        longitude: "1.51473920",
        type: null,
      },
      {
        id: 491,
        name: "Ordino",
        state_code: "05",
        latitude: "42.59944330",
        longitude: "1.54023270",
        type: null,
      },
      {
        id: 490,
        name: "Sant Julià de Lòria",
        state_code: "06",
        latitude: "42.45296310",
        longitude: "1.49182350",
        type: null,
      },
    ],
  },
  {
    name: "Angola",
    iso3: "AGO",
    iso2: "AO",
    numeric_code: "024",
    phone_code: "244",
    capital: "Luanda",
    currency: "AOA",
    currency_name: "Angolan kwanza",
    currency_symbol: "Kz",
    tld: ".ao",
    native: "Angola",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Angolan",
    timezones: [
      {
        zoneName: "Africa/Luanda",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "앙골라",
      "pt-BR": "Angola",
      pt: "Angola",
      nl: "Angola",
      hr: "Angola",
      fa: "آنگولا",
      de: "Angola",
      es: "Angola",
      fr: "Angola",
      ja: "アンゴラ",
      it: "Angola",
      cn: "安哥拉",
      tr: "Angola",
    },
    latitude: "-12.50000000",
    longitude: "18.50000000",
    emoji: "🇦🇴",
    emojiU: "U+1F1E6 U+1F1F4",
    states: [
      {
        id: 221,
        name: "Bengo Province",
        state_code: "BGO",
        latitude: "-9.10422570",
        longitude: "13.72891670",
        type: null,
      },
      {
        id: 218,
        name: "Benguela Province",
        state_code: "BGU",
        latitude: "-12.80037440",
        longitude: "13.91439900",
        type: null,
      },
      {
        id: 212,
        name: "Bié Province",
        state_code: "BIE",
        latitude: "-12.57279070",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 228,
        name: "Cabinda Province",
        state_code: "CAB",
        latitude: "-5.02487490",
        longitude: "12.34638750",
        type: null,
      },
      {
        id: 226,
        name: "Cuando Cubango Province",
        state_code: "CCU",
        latitude: "-16.41808240",
        longitude: "18.80761950",
        type: null,
      },
      {
        id: 217,
        name: "Cuanza Norte Province",
        state_code: "CNO",
        latitude: "-9.23985130",
        longitude: "14.65878210",
        type: null,
      },
      {
        id: 216,
        name: "Cuanza Sul",
        state_code: "CUS",
        latitude: "-10.59519100",
        longitude: "15.40680790",
        type: null,
      },
      {
        id: 215,
        name: "Cunene Province",
        state_code: "CNN",
        latitude: "-16.28022210",
        longitude: "16.15809370",
        type: null,
      },
      {
        id: 213,
        name: "Huambo Province",
        state_code: "HUA",
        latitude: "-12.52682210",
        longitude: "15.59433880",
        type: null,
      },
      {
        id: 225,
        name: "Huíla Province",
        state_code: "HUI",
        latitude: "-14.92805530",
        longitude: "14.65878210",
        type: null,
      },
      {
        id: 222,
        name: "Luanda Province",
        state_code: "LUA",
        latitude: "-9.03508800",
        longitude: "13.26634790",
        type: null,
      },
      {
        id: 223,
        name: "Lunda Norte Province",
        state_code: "LNO",
        latitude: "-8.35250220",
        longitude: "19.18800470",
        type: null,
      },
      {
        id: 220,
        name: "Lunda Sul Province",
        state_code: "LSU",
        latitude: "-10.28665780",
        longitude: "20.71224650",
        type: null,
      },
      {
        id: 227,
        name: "Malanje Province",
        state_code: "MAL",
        latitude: "-9.82511830",
        longitude: "16.91225100",
        type: null,
      },
      {
        id: 219,
        name: "Moxico Province",
        state_code: "MOX",
        latitude: "-13.42935790",
        longitude: "20.33088140",
        type: null,
      },
      {
        id: 224,
        name: "Uíge Province",
        state_code: "UIG",
        latitude: "-7.17367320",
        longitude: "15.40680790",
        type: null,
      },
      {
        id: 214,
        name: "Zaire Province",
        state_code: "ZAI",
        latitude: "-6.57334580",
        longitude: "13.17403480",
        type: null,
      },
    ],
  },
  {
    name: "Anguilla",
    iso3: "AIA",
    iso2: "AI",
    numeric_code: "660",
    phone_code: "+1-264",
    capital: "The Valley",
    currency: "XCD",
    currency_name: "East Caribbean dollar",
    currency_symbol: "$",
    tld: ".ai",
    native: "Anguilla",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Anguillan",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "앵귈라",
      "pt-BR": "Anguila",
      pt: "Anguila",
      nl: "Anguilla",
      hr: "Angvila",
      fa: "آنگویلا",
      de: "Anguilla",
      es: "Anguilla",
      fr: "Anguilla",
      ja: "アンギラ",
      it: "Anguilla",
      cn: "安圭拉",
      tr: "Anguilla",
    },
    latitude: "18.25000000",
    longitude: "-63.16666666",
    emoji: "🇦🇮",
    emojiU: "U+1F1E6 U+1F1EE",
    states: [],
  },
  {
    name: "Antarctica",
    iso3: "ATA",
    iso2: "AQ",
    numeric_code: "010",
    phone_code: "672",
    capital: "",
    currency: "AAD",
    currency_name: "Antarctican dollar",
    currency_symbol: "$",
    tld: ".aq",
    native: "Antarctica",
    region: "Polar",
    region_id: "6",
    subregion: "",
    subregion_id: null,
    nationality: "Antarctic",
    timezones: [
      {
        zoneName: "Antarctica/Casey",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time",
      },
      {
        zoneName: "Antarctica/Davis",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "DAVT",
        tzName: "Davis Time",
      },
      {
        zoneName: "Antarctica/DumontDUrville",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "DDUT",
        tzName: "Dumont d'Urville Time",
      },
      {
        zoneName: "Antarctica/Mawson",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MAWT",
        tzName: "Mawson Station Time",
      },
      {
        zoneName: "Antarctica/McMurdo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time",
      },
      {
        zoneName: "Antarctica/Palmer",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "Antarctica/Rothera",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ROTT",
        tzName: "Rothera Research Station Time",
      },
      {
        zoneName: "Antarctica/Syowa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "SYOT",
        tzName: "Showa Station Time",
      },
      {
        zoneName: "Antarctica/Troll",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
      {
        zoneName: "Antarctica/Vostok",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "VOST",
        tzName: "Vostok Station Time",
      },
    ],
    translations: {
      kr: "남극",
      "pt-BR": "Antártida",
      pt: "Antárctida",
      nl: "Antarctica",
      hr: "Antarktika",
      fa: "جنوبگان",
      de: "Antarktika",
      es: "Antártida",
      fr: "Antarctique",
      ja: "南極大陸",
      it: "Antartide",
      cn: "南极洲",
      tr: "Antartika",
    },
    latitude: "-74.65000000",
    longitude: "4.48000000",
    emoji: "🇦🇶",
    emojiU: "U+1F1E6 U+1F1F6",
    states: [],
  },
  {
    name: "Antigua and Barbuda",
    iso3: "ATG",
    iso2: "AG",
    numeric_code: "028",
    phone_code: "+1-268",
    capital: "St. John's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ag",
    native: "Antigua and Barbuda",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Antiguan or Barbudan",
    timezones: [
      {
        zoneName: "America/Antigua",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "앤티가 바부다",
      "pt-BR": "Antígua e Barbuda",
      pt: "Antígua e Barbuda",
      nl: "Antigua en Barbuda",
      hr: "Antigva i Barbuda",
      fa: "آنتیگوا و باربودا",
      de: "Antigua und Barbuda",
      es: "Antigua y Barbuda",
      fr: "Antigua-et-Barbuda",
      ja: "アンティグア・バーブーダ",
      it: "Antigua e Barbuda",
      cn: "安提瓜和巴布达",
      tr: "Antigua Ve Barbuda",
    },
    latitude: "17.05000000",
    longitude: "-61.80000000",
    emoji: "🇦🇬",
    emojiU: "U+1F1E6 U+1F1EC",
    states: [
      {
        id: 3708,
        name: "Barbuda",
        state_code: "10",
        latitude: "17.62662420",
        longitude: "-61.77130280",
        type: null,
      },
      {
        id: 3703,
        name: "Redonda",
        state_code: "11",
        latitude: "16.93841600",
        longitude: "-62.34551480",
        type: null,
      },
      {
        id: 3709,
        name: "Saint George Parish",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3706,
        name: "Saint John Parish",
        state_code: "04",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3707,
        name: "Saint Mary Parish",
        state_code: "05",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3705,
        name: "Saint Paul Parish",
        state_code: "06",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3704,
        name: "Saint Peter Parish",
        state_code: "07",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3710,
        name: "Saint Philip Parish",
        state_code: "08",
        latitude: "40.43682580",
        longitude: "-80.06855320",
        type: null,
      },
    ],
  },
  {
    name: "Argentina",
    iso3: "ARG",
    iso2: "AR",
    numeric_code: "032",
    phone_code: "54",
    capital: "Buenos Aires",
    currency: "ARS",
    currency_name: "Argentine peso",
    currency_symbol: "$",
    tld: ".ar",
    native: "Argentina",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Argentine",
    timezones: [
      {
        zoneName: "America/Argentina/Buenos_Aires",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Catamarca",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Cordoba",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Jujuy",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/La_Rioja",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Mendoza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Rio_Gallegos",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Salta",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/San_Juan",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/San_Luis",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Tucuman",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
      {
        zoneName: "America/Argentina/Ushuaia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "ART",
        tzName: "Argentina Time",
      },
    ],
    translations: {
      kr: "아르헨티나",
      "pt-BR": "Argentina",
      pt: "Argentina",
      nl: "Argentinië",
      hr: "Argentina",
      fa: "آرژانتین",
      de: "Argentinien",
      es: "Argentina",
      fr: "Argentine",
      ja: "アルゼンチン",
      it: "Argentina",
      cn: "阿根廷",
      tr: "Arjantin",
    },
    latitude: "-34.00000000",
    longitude: "-64.00000000",
    emoji: "🇦🇷",
    emojiU: "U+1F1E6 U+1F1F7",
    states: [
      {
        id: 3656,
        name: "Buenos Aires",
        state_code: "B",
        latitude: "-37.20172850",
        longitude: "-59.84106970",
        type: "province",
      },
      {
        id: 3647,
        name: "Catamarca",
        state_code: "K",
        latitude: "-28.47158770",
        longitude: "-65.78772090",
        type: "province",
      },
      {
        id: 3640,
        name: "Chaco",
        state_code: "H",
        latitude: "-27.42571750",
        longitude: "-59.02437840",
        type: "province",
      },
      {
        id: 3651,
        name: "Chubut",
        state_code: "U",
        latitude: "-43.29342460",
        longitude: "-65.11148180",
        type: "province",
      },
      {
        id: 4880,
        name: "Ciudad Autónoma de Buenos Aires",
        state_code: "C",
        latitude: "-34.60368440",
        longitude: "-58.38155910",
        type: "city",
      },
      {
        id: 3642,
        name: "Córdoba",
        state_code: "X",
        latitude: "-31.39928760",
        longitude: "-64.26438420",
        type: "province",
      },
      {
        id: 3638,
        name: "Corrientes",
        state_code: "W",
        latitude: "-27.46921310",
        longitude: "-58.83063490",
        type: "province",
      },
      {
        id: 3654,
        name: "Entre Ríos",
        state_code: "E",
        latitude: "-31.77466540",
        longitude: "-60.49564610",
        type: "province",
      },
      {
        id: 3652,
        name: "Formosa",
        state_code: "P",
        latitude: "-26.18948040",
        longitude: "-58.22428060",
        type: "province",
      },
      {
        id: 3645,
        name: "Jujuy",
        state_code: "Y",
        latitude: "-24.18433970",
        longitude: "-65.30217700",
        type: "province",
      },
      {
        id: 3655,
        name: "La Pampa",
        state_code: "L",
        latitude: "-36.61475730",
        longitude: "-64.28392090",
        type: "province",
      },
      {
        id: 3653,
        name: "La Rioja",
        state_code: "F",
        latitude: "-29.41937930",
        longitude: "-66.85599320",
        type: "province",
      },
      {
        id: 3646,
        name: "Mendoza",
        state_code: "M",
        latitude: "-32.88945870",
        longitude: "-68.84583860",
        type: "province",
      },
      {
        id: 3644,
        name: "Misiones",
        state_code: "N",
        latitude: "-27.42692550",
        longitude: "-55.94670760",
        type: "province",
      },
      {
        id: 3648,
        name: "Neuquén",
        state_code: "Q",
        latitude: "-38.94587000",
        longitude: "-68.07309250",
        type: "province",
      },
      {
        id: 3639,
        name: "Río Negro",
        state_code: "R",
        latitude: "-40.82614340",
        longitude: "-63.02663390",
        type: "province",
      },
      {
        id: 3643,
        name: "Salta",
        state_code: "A",
        latitude: "-24.79976880",
        longitude: "-65.41503670",
        type: "province",
      },
      {
        id: 3634,
        name: "San Juan",
        state_code: "J",
        latitude: "-31.53169760",
        longitude: "-68.56769620",
        type: "province",
      },
      {
        id: 3636,
        name: "San Luis",
        state_code: "D",
        latitude: "-33.29620420",
        longitude: "-66.32949480",
        type: "province",
      },
      {
        id: 3649,
        name: "Santa Cruz",
        state_code: "Z",
        latitude: "-51.63528210",
        longitude: "-69.24743530",
        type: "province",
      },
      {
        id: 3641,
        name: "Santa Fe",
        state_code: "S",
        latitude: "-31.58551090",
        longitude: "-60.72380160",
        type: "province",
      },
      {
        id: 3635,
        name: "Santiago del Estero",
        state_code: "G",
        latitude: "-27.78335740",
        longitude: "-64.26416700",
        type: "province",
      },
      {
        id: 3650,
        name: "Tierra del Fuego",
        state_code: "V",
        latitude: "-54.80539980",
        longitude: "-68.32420610",
        type: "province",
      },
      {
        id: 3637,
        name: "Tucumán",
        state_code: "T",
        latitude: "-26.82211270",
        longitude: "-65.21929030",
        type: "province",
      },
    ],
  },
  {
    name: "Armenia",
    iso3: "ARM",
    iso2: "AM",
    numeric_code: "051",
    phone_code: "374",
    capital: "Yerevan",
    currency: "AMD",
    currency_name: "Armenian dram",
    currency_symbol: "֏",
    tld: ".am",
    native: "Հայաստան",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Armenian",
    timezones: [
      {
        zoneName: "Asia/Yerevan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AMT",
        tzName: "Armenia Time",
      },
    ],
    translations: {
      kr: "아르메니아",
      "pt-BR": "Armênia",
      pt: "Arménia",
      nl: "Armenië",
      hr: "Armenija",
      fa: "ارمنستان",
      de: "Armenien",
      es: "Armenia",
      fr: "Arménie",
      ja: "アルメニア",
      it: "Armenia",
      cn: "亚美尼亚",
      tr: "Ermenistan",
    },
    latitude: "40.00000000",
    longitude: "45.00000000",
    emoji: "🇦🇲",
    emojiU: "U+1F1E6 U+1F1F2",
    states: [
      {
        id: 2023,
        name: "Aragatsotn Region",
        state_code: "AG",
        latitude: "40.33473010",
        longitude: "44.37482960",
        type: null,
      },
      {
        id: 2024,
        name: "Ararat Province",
        state_code: "AR",
        latitude: "39.91394150",
        longitude: "44.72000040",
        type: null,
      },
      {
        id: 2026,
        name: "Armavir Region",
        state_code: "AV",
        latitude: "40.15546310",
        longitude: "44.03724460",
        type: null,
      },
      {
        id: 2028,
        name: "Gegharkunik Province",
        state_code: "GR",
        latitude: "40.35264260",
        longitude: "45.12604140",
        type: null,
      },
      {
        id: 2033,
        name: "Kotayk Region",
        state_code: "KT",
        latitude: "40.54102140",
        longitude: "44.76901480",
        type: null,
      },
      {
        id: 2029,
        name: "Lori Region",
        state_code: "LO",
        latitude: "40.96984520",
        longitude: "44.49001380",
        type: null,
      },
      {
        id: 2031,
        name: "Shirak Region",
        state_code: "SH",
        latitude: "40.96308140",
        longitude: "43.81024610",
        type: null,
      },
      {
        id: 2027,
        name: "Syunik Province",
        state_code: "SU",
        latitude: "39.51331120",
        longitude: "46.33932340",
        type: null,
      },
      {
        id: 2032,
        name: "Tavush Region",
        state_code: "TV",
        latitude: "40.88662960",
        longitude: "45.33934900",
        type: null,
      },
      {
        id: 2025,
        name: "Vayots Dzor Region",
        state_code: "VD",
        latitude: "39.76419960",
        longitude: "45.33375280",
        type: null,
      },
      {
        id: 2030,
        name: "Yerevan",
        state_code: "ER",
        latitude: "40.18720230",
        longitude: "44.51520900",
        type: null,
      },
    ],
  },
  {
    name: "Aruba",
    iso3: "ABW",
    iso2: "AW",
    numeric_code: "533",
    phone_code: "297",
    capital: "Oranjestad",
    currency: "AWG",
    currency_name: "Aruban florin",
    currency_symbol: "ƒ",
    tld: ".aw",
    native: "Aruba",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Aruban",
    timezones: [
      {
        zoneName: "America/Aruba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "아루바",
      "pt-BR": "Aruba",
      pt: "Aruba",
      nl: "Aruba",
      hr: "Aruba",
      fa: "آروبا",
      de: "Aruba",
      es: "Aruba",
      fr: "Aruba",
      ja: "アルバ",
      it: "Aruba",
      cn: "阿鲁巴",
      tr: "Aruba",
    },
    latitude: "12.50000000",
    longitude: "-69.96666666",
    emoji: "🇦🇼",
    emojiU: "U+1F1E6 U+1F1FC",
    states: [],
  },
  {
    name: "Australia",
    iso3: "AUS",
    iso2: "AU",
    numeric_code: "036",
    phone_code: "61",
    capital: "Canberra",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".au",
    native: "Australia",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Australian",
    timezones: [
      {
        zoneName: "Antarctica/Macquarie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MIST",
        tzName: "Macquarie Island Station Time",
      },
      {
        zoneName: "Australia/Adelaide",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time",
      },
      {
        zoneName: "Australia/Brisbane",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time",
      },
      {
        zoneName: "Australia/Broken_Hill",
        gmtOffset: 37800,
        gmtOffsetName: "UTC+10:30",
        abbreviation: "ACDT",
        tzName: "Australian Central Daylight Saving Time",
      },
      {
        zoneName: "Australia/Currie",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Darwin",
        gmtOffset: 34200,
        gmtOffsetName: "UTC+09:30",
        abbreviation: "ACST",
        tzName: "Australian Central Standard Time",
      },
      {
        zoneName: "Australia/Eucla",
        gmtOffset: 31500,
        gmtOffsetName: "UTC+08:45",
        abbreviation: "ACWST",
        tzName: "Australian Central Western Standard Time (Unofficial)",
      },
      {
        zoneName: "Australia/Hobart",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Lindeman",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "AEST",
        tzName: "Australian Eastern Standard Time",
      },
      {
        zoneName: "Australia/Lord_Howe",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "LHST",
        tzName: "Lord Howe Summer Time",
      },
      {
        zoneName: "Australia/Melbourne",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
      {
        zoneName: "Australia/Perth",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "AWST",
        tzName: "Australian Western Standard Time",
      },
      {
        zoneName: "Australia/Sydney",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "AEDT",
        tzName: "Australian Eastern Daylight Saving Time",
      },
    ],
    translations: {
      kr: "호주",
      "pt-BR": "Austrália",
      pt: "Austrália",
      nl: "Australië",
      hr: "Australija",
      fa: "استرالیا",
      de: "Australien",
      es: "Australia",
      fr: "Australie",
      ja: "オーストラリア",
      it: "Australia",
      cn: "澳大利亚",
      tr: "Avustralya",
    },
    latitude: "-27.00000000",
    longitude: "133.00000000",
    emoji: "🇦🇺",
    emojiU: "U+1F1E6 U+1F1FA",
    states: [
      {
        id: 3907,
        name: "Australian Capital Territory",
        state_code: "ACT",
        latitude: "-35.47346790",
        longitude: "149.01236790",
        type: "territory",
      },
      {
        id: 3909,
        name: "New South Wales",
        state_code: "NSW",
        latitude: "-31.25321830",
        longitude: "146.92109900",
        type: "state",
      },
      {
        id: 3910,
        name: "Northern Territory",
        state_code: "NT",
        latitude: "-19.49141080",
        longitude: "132.55096030",
        type: "territory",
      },
      {
        id: 3905,
        name: "Queensland",
        state_code: "QLD",
        latitude: "-20.91757380",
        longitude: "142.70279560",
        type: "state",
      },
      {
        id: 3904,
        name: "South Australia",
        state_code: "SA",
        latitude: "-30.00023150",
        longitude: "136.20915470",
        type: "state",
      },
      {
        id: 3908,
        name: "Tasmania",
        state_code: "TAS",
        latitude: "-41.45451960",
        longitude: "145.97066470",
        type: "state",
      },
      {
        id: 3903,
        name: "Victoria",
        state_code: "VIC",
        latitude: "-36.48564230",
        longitude: "140.97794250",
        type: "state",
      },
      {
        id: 3906,
        name: "Western Australia",
        state_code: "WA",
        latitude: "-27.67281680",
        longitude: "121.62830980",
        type: "state",
      },
    ],
  },
  {
    name: "Austria",
    iso3: "AUT",
    iso2: "AT",
    numeric_code: "040",
    phone_code: "43",
    capital: "Vienna",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".at",
    native: "Österreich",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Austrian",
    timezones: [
      {
        zoneName: "Europe/Vienna",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "오스트리아",
      "pt-BR": "áustria",
      pt: "áustria",
      nl: "Oostenrijk",
      hr: "Austrija",
      fa: "اتریش",
      de: "Österreich",
      es: "Austria",
      fr: "Autriche",
      ja: "オーストリア",
      it: "Austria",
      cn: "奥地利",
      tr: "Avusturya",
    },
    latitude: "47.33333333",
    longitude: "13.33333333",
    emoji: "🇦🇹",
    emojiU: "U+1F1E6 U+1F1F9",
    states: [
      {
        id: 2062,
        name: "Burgenland",
        state_code: "1",
        latitude: "47.15371650",
        longitude: "16.26887970",
        type: null,
      },
      {
        id: 2057,
        name: "Carinthia",
        state_code: "2",
        latitude: "46.72220300",
        longitude: "14.18058820",
        type: null,
      },
      {
        id: 2065,
        name: "Lower Austria",
        state_code: "3",
        latitude: "48.10807700",
        longitude: "15.80495580",
        type: null,
      },
      {
        id: 2061,
        name: "Salzburg",
        state_code: "5",
        latitude: "47.80949000",
        longitude: "13.05501000",
        type: null,
      },
      {
        id: 2059,
        name: "Styria",
        state_code: "6",
        latitude: "47.35934420",
        longitude: "14.46998270",
        type: null,
      },
      {
        id: 2064,
        name: "Tyrol",
        state_code: "7",
        latitude: "47.25374140",
        longitude: "11.60148700",
        type: null,
      },
      {
        id: 2058,
        name: "Upper Austria",
        state_code: "4",
        latitude: "48.02585400",
        longitude: "13.97236650",
        type: null,
      },
      {
        id: 2060,
        name: "Vienna",
        state_code: "9",
        latitude: "48.20817430",
        longitude: "16.37381890",
        type: null,
      },
      {
        id: 2063,
        name: "Vorarlberg",
        state_code: "8",
        latitude: "47.24974270",
        longitude: "9.97973730",
        type: null,
      },
    ],
  },
  {
    name: "Azerbaijan",
    iso3: "AZE",
    iso2: "AZ",
    numeric_code: "031",
    phone_code: "994",
    capital: "Baku",
    currency: "AZN",
    currency_name: "Azerbaijani manat",
    currency_symbol: "m",
    tld: ".az",
    native: "Azərbaycan",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Azerbaijani, Azeri",
    timezones: [
      {
        zoneName: "Asia/Baku",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "AZT",
        tzName: "Azerbaijan Time",
      },
    ],
    translations: {
      kr: "아제르바이잔",
      "pt-BR": "Azerbaijão",
      pt: "Azerbaijão",
      nl: "Azerbeidzjan",
      hr: "Azerbajdžan",
      fa: "آذربایجان",
      de: "Aserbaidschan",
      es: "Azerbaiyán",
      fr: "Azerbaïdjan",
      ja: "アゼルバイジャン",
      it: "Azerbaijan",
      cn: "阿塞拜疆",
      tr: "Azerbaycan",
    },
    latitude: "40.50000000",
    longitude: "47.50000000",
    emoji: "🇦🇿",
    emojiU: "U+1F1E6 U+1F1FF",
    states: [
      {
        id: 540,
        name: "Absheron District",
        state_code: "ABS",
        latitude: "40.36296930",
        longitude: "49.27368150",
        type: null,
      },
      {
        id: 559,
        name: "Agdam District",
        state_code: "AGM",
        latitude: "39.99318530",
        longitude: "46.99495620",
        type: null,
      },
      {
        id: 553,
        name: "Agdash District",
        state_code: "AGS",
        latitude: "40.63354270",
        longitude: "47.46743100",
        type: null,
      },
      {
        id: 577,
        name: "Aghjabadi District",
        state_code: "AGC",
        latitude: "28.78918410",
        longitude: "77.51607880",
        type: null,
      },
      {
        id: 543,
        name: "Agstafa District",
        state_code: "AGA",
        latitude: "41.26559330",
        longitude: "45.51342910",
        type: null,
      },
      {
        id: 547,
        name: "Agsu District",
        state_code: "AGU",
        latitude: "40.52833390",
        longitude: "48.36508350",
        type: null,
      },
      {
        id: 528,
        name: "Astara District",
        state_code: "AST",
        latitude: "38.49378450",
        longitude: "48.69443650",
        type: null,
      },
      {
        id: 575,
        name: "Babek District",
        state_code: "BAB",
        latitude: "39.15076130",
        longitude: "45.44853680",
        type: null,
      },
      {
        id: 552,
        name: "Baku",
        state_code: "BA",
        latitude: "40.40926170",
        longitude: "49.86709240",
        type: null,
      },
      {
        id: 560,
        name: "Balakan District",
        state_code: "BAL",
        latitude: "41.70375090",
        longitude: "46.40442130",
        type: null,
      },
      {
        id: 569,
        name: "Barda District",
        state_code: "BAR",
        latitude: "40.37065550",
        longitude: "47.13789090",
        type: null,
      },
      {
        id: 554,
        name: "Beylagan District",
        state_code: "BEY",
        latitude: "39.77230730",
        longitude: "47.61541660",
        type: null,
      },
      {
        id: 532,
        name: "Bilasuvar District",
        state_code: "BIL",
        latitude: "39.45988330",
        longitude: "48.55098130",
        type: null,
      },
      {
        id: 561,
        name: "Dashkasan District",
        state_code: "DAS",
        latitude: "40.52022570",
        longitude: "46.07793040",
        type: null,
      },
      {
        id: 527,
        name: "Fizuli District",
        state_code: "FUZ",
        latitude: "39.53786050",
        longitude: "47.30338770",
        type: null,
      },
      {
        id: 585,
        name: "Ganja",
        state_code: "GA",
        latitude: "36.36873380",
        longitude: "-95.99857670",
        type: null,
      },
      {
        id: 589,
        name: "Gədəbəy",
        state_code: "GAD",
        latitude: "40.56996390",
        longitude: "45.81068830",
        type: null,
      },
      {
        id: 573,
        name: "Gobustan District",
        state_code: "QOB",
        latitude: "40.53261040",
        longitude: "48.92737500",
        type: null,
      },
      {
        id: 551,
        name: "Goranboy District",
        state_code: "GOR",
        latitude: "40.53805060",
        longitude: "46.59908910",
        type: null,
      },
      {
        id: 531,
        name: "Goychay",
        state_code: "GOY",
        latitude: "40.62361680",
        longitude: "47.74030340",
        type: null,
      },
      {
        id: 574,
        name: "Goygol District",
        state_code: "GYG",
        latitude: "40.55953780",
        longitude: "46.33149530",
        type: null,
      },
      {
        id: 571,
        name: "Hajigabul District",
        state_code: "HAC",
        latitude: "40.03937700",
        longitude: "48.92025330",
        type: null,
      },
      {
        id: 544,
        name: "Imishli District",
        state_code: "IMI",
        latitude: "39.86946860",
        longitude: "48.06652180",
        type: null,
      },
      {
        id: 564,
        name: "Ismailli District",
        state_code: "ISM",
        latitude: "40.74299360",
        longitude: "48.21255560",
        type: null,
      },
      {
        id: 570,
        name: "Jabrayil District",
        state_code: "CAB",
        latitude: "39.26455440",
        longitude: "46.96215620",
        type: null,
      },
      {
        id: 578,
        name: "Jalilabad District",
        state_code: "CAL",
        latitude: "39.20516320",
        longitude: "48.51006040",
        type: null,
      },
      {
        id: 572,
        name: "Julfa District",
        state_code: "CUL",
        latitude: "38.96049830",
        longitude: "45.62929390",
        type: null,
      },
      {
        id: 525,
        name: "Kalbajar District",
        state_code: "KAL",
        latitude: "40.10243290",
        longitude: "46.03648720",
        type: null,
      },
      {
        id: 567,
        name: "Kangarli District",
        state_code: "KAN",
        latitude: "39.38719400",
        longitude: "45.16398520",
        type: null,
      },
      {
        id: 590,
        name: "Khachmaz District",
        state_code: "XAC",
        latitude: "41.45911680",
        longitude: "48.80206260",
        type: null,
      },
      {
        id: 537,
        name: "Khizi District",
        state_code: "XIZ",
        latitude: "40.91094890",
        longitude: "49.07292640",
        type: null,
      },
      {
        id: 524,
        name: "Khojali District",
        state_code: "XCI",
        latitude: "39.91325530",
        longitude: "46.79430500",
        type: null,
      },
      {
        id: 549,
        name: "Kurdamir District",
        state_code: "KUR",
        latitude: "40.36986510",
        longitude: "48.16446260",
        type: null,
      },
      {
        id: 541,
        name: "Lachin District",
        state_code: "LAC",
        latitude: "39.63834140",
        longitude: "46.54608530",
        type: null,
      },
      {
        id: 587,
        name: "Lankaran",
        state_code: "LAN",
        latitude: "38.75286690",
        longitude: "48.84750150",
        type: null,
      },
      {
        id: 558,
        name: "Lankaran District",
        state_code: "LA",
        latitude: "38.75286690",
        longitude: "48.84750150",
        type: null,
      },
      {
        id: 546,
        name: "Lerik District",
        state_code: "LER",
        latitude: "38.77361920",
        longitude: "48.41514830",
        type: null,
      },
      {
        id: 568,
        name: "Martuni",
        state_code: "XVD",
        latitude: "39.79146930",
        longitude: "47.11008140",
        type: null,
      },
      {
        id: 555,
        name: "Masally District",
        state_code: "MAS",
        latitude: "39.03407220",
        longitude: "48.65893540",
        type: null,
      },
      {
        id: 580,
        name: "Mingachevir",
        state_code: "MI",
        latitude: "40.77025630",
        longitude: "47.04960150",
        type: null,
      },
      {
        id: 562,
        name: "Nakhchivan Autonomous Republic",
        state_code: "NX",
        latitude: "39.32568140",
        longitude: "45.49126480",
        type: null,
      },
      {
        id: 530,
        name: "Neftchala District",
        state_code: "NEF",
        latitude: "39.38810520",
        longitude: "49.24137430",
        type: null,
      },
      {
        id: 556,
        name: "Oghuz District",
        state_code: "OGU",
        latitude: "41.07279240",
        longitude: "47.46506720",
        type: null,
      },
      {
        id: 534,
        name: "Ordubad District",
        state_code: "ORD",
        latitude: "38.90216220",
        longitude: "46.02376250",
        type: null,
      },
      {
        id: 542,
        name: "Qabala District",
        state_code: "QAB",
        latitude: "40.92539250",
        longitude: "47.80161060",
        type: null,
      },
      {
        id: 526,
        name: "Qakh District",
        state_code: "QAX",
        latitude: "41.42068270",
        longitude: "46.93201840",
        type: null,
      },
      {
        id: 521,
        name: "Qazakh District",
        state_code: "QAZ",
        latitude: "41.09710740",
        longitude: "45.35163310",
        type: null,
      },
      {
        id: 563,
        name: "Quba District",
        state_code: "QBA",
        latitude: "41.15642420",
        longitude: "48.41350210",
        type: null,
      },
      {
        id: 548,
        name: "Qubadli District",
        state_code: "QBI",
        latitude: "39.27139960",
        longitude: "46.63543120",
        type: null,
      },
      {
        id: 588,
        name: "Qusar District",
        state_code: "QUS",
        latitude: "41.42668860",
        longitude: "48.43455770",
        type: null,
      },
      {
        id: 557,
        name: "Saatly District",
        state_code: "SAT",
        latitude: "39.90955030",
        longitude: "48.35951220",
        type: null,
      },
      {
        id: 565,
        name: "Sabirabad District",
        state_code: "SAB",
        latitude: "39.98706630",
        longitude: "48.46925450",
        type: null,
      },
      {
        id: 522,
        name: "Sadarak District",
        state_code: "SAD",
        latitude: "39.71051140",
        longitude: "44.88642770",
        type: null,
      },
      {
        id: 545,
        name: "Salyan District",
        state_code: "SAL",
        latitude: "28.35248110",
        longitude: "82.12784000",
        type: null,
      },
      {
        id: 536,
        name: "Samukh District",
        state_code: "SMX",
        latitude: "40.76046310",
        longitude: "46.40631810",
        type: null,
      },
      {
        id: 591,
        name: "Shabran District",
        state_code: "SBN",
        latitude: "41.22283760",
        longitude: "48.84573040",
        type: null,
      },
      {
        id: 579,
        name: "Shahbuz District",
        state_code: "SAH",
        latitude: "39.44521030",
        longitude: "45.65680090",
        type: null,
      },
      {
        id: 518,
        name: "Shaki",
        state_code: "SA",
        latitude: "41.19747530",
        longitude: "47.15712410",
        type: null,
      },
      {
        id: 586,
        name: "Shaki District",
        state_code: "SAK",
        latitude: "41.11346620",
        longitude: "47.13169270",
        type: null,
      },
      {
        id: 529,
        name: "Shamakhi District",
        state_code: "SMI",
        latitude: "40.63187310",
        longitude: "48.63638010",
        type: null,
      },
      {
        id: 583,
        name: "Shamkir District",
        state_code: "SKR",
        latitude: "40.82881440",
        longitude: "46.01668790",
        type: null,
      },
      {
        id: 535,
        name: "Sharur District",
        state_code: "SAR",
        latitude: "39.55363320",
        longitude: "44.98456800",
        type: null,
      },
      {
        id: 520,
        name: "Shirvan",
        state_code: "SR",
        latitude: "39.94697070",
        longitude: "48.92239190",
        type: null,
      },
      {
        id: 592,
        name: "Shusha District",
        state_code: "SUS",
        latitude: "39.75374380",
        longitude: "46.74647550",
        type: null,
      },
      {
        id: 584,
        name: "Siazan District",
        state_code: "SIY",
        latitude: "41.07838330",
        longitude: "49.11184770",
        type: null,
      },
      {
        id: 582,
        name: "Sumqayit",
        state_code: "SM",
        latitude: "40.58547650",
        longitude: "49.63174110",
        type: null,
      },
      {
        id: 519,
        name: "Tartar District",
        state_code: "TAR",
        latitude: "40.34438750",
        longitude: "46.93765190",
        type: null,
      },
      {
        id: 533,
        name: "Tovuz District",
        state_code: "TOV",
        latitude: "40.99545230",
        longitude: "45.61659070",
        type: null,
      },
      {
        id: 539,
        name: "Ujar District",
        state_code: "UCA",
        latitude: "40.50675250",
        longitude: "47.64896410",
        type: null,
      },
      {
        id: 550,
        name: "Yardymli District",
        state_code: "YAR",
        latitude: "38.90589170",
        longitude: "48.24961270",
        type: null,
      },
      {
        id: 538,
        name: "Yevlakh",
        state_code: "YE",
        latitude: "40.61966380",
        longitude: "47.15003240",
        type: null,
      },
      {
        id: 523,
        name: "Yevlakh District",
        state_code: "YEV",
        latitude: "40.61966380",
        longitude: "47.15003240",
        type: null,
      },
      {
        id: 581,
        name: "Zangilan District",
        state_code: "ZAN",
        latitude: "39.08568990",
        longitude: "46.65247280",
        type: null,
      },
      {
        id: 566,
        name: "Zaqatala District",
        state_code: "ZAQ",
        latitude: "41.59068890",
        longitude: "46.72403730",
        type: null,
      },
      {
        id: 576,
        name: "Zardab District",
        state_code: "ZAR",
        latitude: "40.21481140",
        longitude: "47.71494400",
        type: null,
      },
    ],
  },
  {
    name: "Bahrain",
    iso3: "BHR",
    iso2: "BH",
    numeric_code: "048",
    phone_code: "973",
    capital: "Manama",
    currency: "BHD",
    currency_name: "Bahraini dinar",
    currency_symbol: ".د.ب",
    tld: ".bh",
    native: "‏البحرين",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Bahraini",
    timezones: [
      {
        zoneName: "Asia/Bahrain",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "바레인",
      "pt-BR": "Bahrein",
      pt: "Barém",
      nl: "Bahrein",
      hr: "Bahrein",
      fa: "بحرین",
      de: "Bahrain",
      es: "Bahrein",
      fr: "Bahreïn",
      ja: "バーレーン",
      it: "Bahrein",
      cn: "巴林",
      tr: "Bahreyn",
    },
    latitude: "26.00000000",
    longitude: "50.55000000",
    emoji: "🇧🇭",
    emojiU: "U+1F1E7 U+1F1ED",
    states: [
      {
        id: 1992,
        name: "Capital",
        state_code: "13",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 1996,
        name: "Central",
        state_code: "16",
        latitude: "26.14260930",
        longitude: "50.56532940",
        type: null,
      },
      {
        id: 1995,
        name: "Muharraq",
        state_code: "15",
        latitude: "26.26856530",
        longitude: "50.64825170",
        type: null,
      },
      {
        id: 1994,
        name: "Northern",
        state_code: "17",
        latitude: "26.15519140",
        longitude: "50.48251730",
        type: null,
      },
      {
        id: 1993,
        name: "Southern",
        state_code: "14",
        latitude: "25.93810180",
        longitude: "50.57568870",
        type: null,
      },
    ],
  },
  {
    name: "Bangladesh",
    iso3: "BGD",
    iso2: "BD",
    numeric_code: "050",
    phone_code: "880",
    capital: "Dhaka",
    currency: "BDT",
    currency_name: "Bangladeshi taka",
    currency_symbol: "৳",
    tld: ".bd",
    native: "Bangladesh",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Bangladeshi",
    timezones: [
      {
        zoneName: "Asia/Dhaka",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BDT",
        tzName: "Bangladesh Standard Time",
      },
    ],
    translations: {
      kr: "방글라데시",
      "pt-BR": "Bangladesh",
      pt: "Bangladeche",
      nl: "Bangladesh",
      hr: "Bangladeš",
      fa: "بنگلادش",
      de: "Bangladesch",
      es: "Bangladesh",
      fr: "Bangladesh",
      ja: "バングラデシュ",
      it: "Bangladesh",
      cn: "孟加拉",
      tr: "Bangladeş",
    },
    latitude: "24.00000000",
    longitude: "90.00000000",
    emoji: "🇧🇩",
    emojiU: "U+1F1E7 U+1F1E9",
    states: [
      {
        id: 796,
        name: "Bagerhat District",
        state_code: "05",
        latitude: "22.66024360",
        longitude: "89.78954780",
        type: "district",
      },
      {
        id: 802,
        name: "Bahadia",
        state_code: "33",
        latitude: "23.78987120",
        longitude: "90.16714830",
        type: null,
      },
      {
        id: 752,
        name: "Bandarban District",
        state_code: "01",
        latitude: "21.83110020",
        longitude: "92.36863210",
        type: "district",
      },
      {
        id: 784,
        name: "Barguna District",
        state_code: "02",
        latitude: "22.09529150",
        longitude: "90.11206960",
        type: "district",
      },
      {
        id: 818,
        name: "Barisal District",
        state_code: "06",
        latitude: "22.70220980",
        longitude: "90.36963160",
        type: "district",
      },
      {
        id: 807,
        name: "Barisal Division",
        state_code: "A",
        latitude: "22.38111310",
        longitude: "90.33718890",
        type: "division",
      },
      {
        id: 756,
        name: "Bhola District",
        state_code: "07",
        latitude: "22.17853150",
        longitude: "90.71010230",
        type: "district",
      },
      {
        id: 797,
        name: "Bogra District",
        state_code: "03",
        latitude: "24.85104020",
        longitude: "89.36972250",
        type: "district",
      },
      {
        id: 810,
        name: "Brahmanbaria District",
        state_code: "04",
        latitude: "23.96081810",
        longitude: "91.11150140",
        type: "district",
      },
      {
        id: 768,
        name: "Chandpur District",
        state_code: "09",
        latitude: "23.25131480",
        longitude: "90.85178460",
        type: "district",
      },
      {
        id: 761,
        name: "Chapai Nawabganj District",
        state_code: "45",
        latitude: "24.74131110",
        longitude: "88.29120690",
        type: "district",
      },
      {
        id: 785,
        name: "Chittagong District",
        state_code: "10",
        latitude: "22.51501050",
        longitude: "91.75388170",
        type: "district",
      },
      {
        id: 803,
        name: "Chittagong Division",
        state_code: "B",
        latitude: "23.17931570",
        longitude: "91.98815270",
        type: "division",
      },
      {
        id: 788,
        name: "Chuadanga District",
        state_code: "12",
        latitude: "23.61605120",
        longitude: "88.82630060",
        type: "district",
      },
      {
        id: 763,
        name: "Comilla District",
        state_code: "08",
        latitude: "23.45756670",
        longitude: "91.18089960",
        type: "district",
      },
      {
        id: 751,
        name: "Cox's Bazar District",
        state_code: "11",
        latitude: "21.56406260",
        longitude: "92.02821290",
        type: "district",
      },
      {
        id: 771,
        name: "Dhaka District",
        state_code: "13",
        latitude: "23.81051400",
        longitude: "90.33718890",
        type: "district",
      },
      {
        id: 760,
        name: "Dhaka Division",
        state_code: "C",
        latitude: "23.95357420",
        longitude: "90.14949880",
        type: "division",
      },
      {
        id: 783,
        name: "Dinajpur District",
        state_code: "14",
        latitude: "25.62791230",
        longitude: "88.63317580",
        type: "district",
      },
      {
        id: 762,
        name: "Faridpur District",
        state_code: "15",
        latitude: "23.54239190",
        longitude: "89.63089210",
        type: "district",
      },
      {
        id: 816,
        name: "Feni District",
        state_code: "16",
        latitude: "22.94087840",
        longitude: "91.40666460",
        type: "district",
      },
      {
        id: 795,
        name: "Gaibandha District",
        state_code: "19",
        latitude: "25.32969280",
        longitude: "89.54296520",
        type: "district",
      },
      {
        id: 798,
        name: "Gazipur District",
        state_code: "18",
        latitude: "24.09581710",
        longitude: "90.41251810",
        type: "district",
      },
      {
        id: 792,
        name: "Gopalganj District",
        state_code: "17",
        latitude: "26.48315840",
        longitude: "84.43655000",
        type: "district",
      },
      {
        id: 805,
        name: "Habiganj District",
        state_code: "20",
        latitude: "24.47712360",
        longitude: "91.45065650",
        type: "district",
      },
      {
        id: 808,
        name: "Jamalpur District",
        state_code: "21",
        latitude: "25.08309260",
        longitude: "89.78532180",
        type: "district",
      },
      {
        id: 757,
        name: "Jessore District",
        state_code: "22",
        latitude: "23.16340140",
        longitude: "89.21816640",
        type: "district",
      },
      {
        id: 778,
        name: "Jhalokati District",
        state_code: "25",
        latitude: "22.57208000",
        longitude: "90.18696440",
        type: "district",
      },
      {
        id: 789,
        name: "Jhenaidah District",
        state_code: "23",
        latitude: "23.54498730",
        longitude: "89.17260310",
        type: "district",
      },
      {
        id: 806,
        name: "Joypurhat District",
        state_code: "24",
        latitude: "25.09473490",
        longitude: "89.09449370",
        type: "district",
      },
      {
        id: 786,
        name: "Khagrachari District",
        state_code: "29",
        latitude: "23.13217510",
        longitude: "91.94902100",
        type: "district",
      },
      {
        id: 811,
        name: "Khulna District",
        state_code: "27",
        latitude: "22.67377350",
        longitude: "89.39665810",
        type: "district",
      },
      {
        id: 775,
        name: "Khulna Division",
        state_code: "D",
        latitude: "22.80878160",
        longitude: "89.24671910",
        type: "division",
      },
      {
        id: 779,
        name: "Kishoreganj District",
        state_code: "26",
        latitude: "24.42604570",
        longitude: "90.98206680",
        type: "district",
      },
      {
        id: 793,
        name: "Kurigram District",
        state_code: "28",
        latitude: "25.80724140",
        longitude: "89.62947460",
        type: "district",
      },
      {
        id: 774,
        name: "Kushtia District",
        state_code: "30",
        latitude: "23.89069950",
        longitude: "89.10993680",
        type: "district",
      },
      {
        id: 819,
        name: "Lakshmipur District",
        state_code: "31",
        latitude: "22.94467440",
        longitude: "90.82819070",
        type: "district",
      },
      {
        id: 780,
        name: "Lalmonirhat District",
        state_code: "32",
        latitude: "25.99233980",
        longitude: "89.28472510",
        type: "district",
      },
      {
        id: 817,
        name: "Madaripur District",
        state_code: "36",
        latitude: "23.23933460",
        longitude: "90.18696440",
        type: "district",
      },
      {
        id: 776,
        name: "Meherpur District",
        state_code: "39",
        latitude: "23.80519910",
        longitude: "88.67235780",
        type: "district",
      },
      {
        id: 794,
        name: "Moulvibazar District",
        state_code: "38",
        latitude: "24.30953440",
        longitude: "91.73149030",
        type: "district",
      },
      {
        id: 790,
        name: "Munshiganj District",
        state_code: "35",
        latitude: "23.49809310",
        longitude: "90.41266210",
        type: "district",
      },
      {
        id: 766,
        name: "Mymensingh District",
        state_code: "34",
        latitude: "24.75385750",
        longitude: "90.40729190",
        type: "district",
      },
      {
        id: 758,
        name: "Mymensingh Division",
        state_code: "H",
        latitude: "24.71362000",
        longitude: "90.45023680",
        type: "division",
      },
      {
        id: 814,
        name: "Naogaon District",
        state_code: "48",
        latitude: "24.91315970",
        longitude: "88.75309520",
        type: "district",
      },
      {
        id: 769,
        name: "Narail District",
        state_code: "43",
        latitude: "23.11629290",
        longitude: "89.58404040",
        type: "district",
      },
      {
        id: 770,
        name: "Narayanganj District",
        state_code: "40",
        latitude: "23.71466010",
        longitude: "90.56360900",
        type: "district",
      },
      {
        id: 787,
        name: "Natore District",
        state_code: "44",
        latitude: "24.41024300",
        longitude: "89.00761770",
        type: "district",
      },
      {
        id: 764,
        name: "Netrokona District",
        state_code: "41",
        latitude: "24.81032840",
        longitude: "90.86564150",
        type: "district",
      },
      {
        id: 772,
        name: "Nilphamari District",
        state_code: "46",
        latitude: "25.84827980",
        longitude: "88.94141340",
        type: "district",
      },
      {
        id: 815,
        name: "Noakhali District",
        state_code: "47",
        latitude: "22.87237890",
        longitude: "91.09731840",
        type: "district",
      },
      {
        id: 754,
        name: "Pabna District",
        state_code: "49",
        latitude: "24.15850500",
        longitude: "89.44807180",
        type: "district",
      },
      {
        id: 800,
        name: "Panchagarh District",
        state_code: "52",
        latitude: "26.27087050",
        longitude: "88.59517510",
        type: "district",
      },
      {
        id: 777,
        name: "Patuakhali District",
        state_code: "51",
        latitude: "22.22486320",
        longitude: "90.45475030",
        type: "district",
      },
      {
        id: 791,
        name: "Pirojpur District",
        state_code: "50",
        latitude: "22.57907440",
        longitude: "89.97592640",
        type: "district",
      },
      {
        id: 773,
        name: "Rajbari District",
        state_code: "53",
        latitude: "23.71513400",
        longitude: "89.58748190",
        type: "district",
      },
      {
        id: 813,
        name: "Rajshahi District",
        state_code: "54",
        latitude: "24.37330870",
        longitude: "88.60487160",
        type: "district",
      },
      {
        id: 753,
        name: "Rajshahi Division",
        state_code: "E",
        latitude: "24.71057760",
        longitude: "88.94138650",
        type: "division",
      },
      {
        id: 809,
        name: "Rangamati Hill District",
        state_code: "56",
        latitude: "22.73241730",
        longitude: "92.29851340",
        type: "district",
      },
      {
        id: 759,
        name: "Rangpur District",
        state_code: "55",
        latitude: "25.74679250",
        longitude: "89.25083350",
        type: "district",
      },
      {
        id: 750,
        name: "Rangpur Division",
        state_code: "F",
        latitude: "25.84833880",
        longitude: "88.94138650",
        type: "division",
      },
      {
        id: 799,
        name: "Satkhira District",
        state_code: "58",
        latitude: "22.31548120",
        longitude: "89.11145250",
        type: "district",
      },
      {
        id: 801,
        name: "Shariatpur District",
        state_code: "62",
        latitude: "23.24232140",
        longitude: "90.43477110",
        type: "district",
      },
      {
        id: 755,
        name: "Sherpur District",
        state_code: "57",
        latitude: "25.07462350",
        longitude: "90.14949040",
        type: "district",
      },
      {
        id: 781,
        name: "Sirajganj District",
        state_code: "59",
        latitude: "24.31411150",
        longitude: "89.56996150",
        type: "district",
      },
      {
        id: 812,
        name: "Sunamganj District",
        state_code: "61",
        latitude: "25.07145350",
        longitude: "91.39916270",
        type: "district",
      },
      {
        id: 767,
        name: "Sylhet District",
        state_code: "60",
        latitude: "24.89933570",
        longitude: "91.87004730",
        type: "district",
      },
      {
        id: 765,
        name: "Sylhet Division",
        state_code: "G",
        latitude: "24.70498110",
        longitude: "91.67606910",
        type: "division",
      },
      {
        id: 782,
        name: "Tangail District",
        state_code: "63",
        latitude: "24.39174270",
        longitude: "89.99482570",
        type: "district",
      },
      {
        id: 804,
        name: "Thakurgaon District",
        state_code: "64",
        latitude: "26.04183920",
        longitude: "88.42826160",
        type: "district",
      },
    ],
  },
  {
    name: "Barbados",
    iso3: "BRB",
    iso2: "BB",
    numeric_code: "052",
    phone_code: "+1-246",
    capital: "Bridgetown",
    currency: "BBD",
    currency_name: "Barbadian dollar",
    currency_symbol: "Bds$",
    tld: ".bb",
    native: "Barbados",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Barbadian",
    timezones: [
      {
        zoneName: "America/Barbados",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "바베이도스",
      "pt-BR": "Barbados",
      pt: "Barbados",
      nl: "Barbados",
      hr: "Barbados",
      fa: "باربادوس",
      de: "Barbados",
      es: "Barbados",
      fr: "Barbade",
      ja: "バルバドス",
      it: "Barbados",
      cn: "巴巴多斯",
      tr: "Barbados",
    },
    latitude: "13.16666666",
    longitude: "-59.53333333",
    emoji: "🇧🇧",
    emojiU: "U+1F1E7 U+1F1E7",
    states: [
      {
        id: 1228,
        name: "Christ Church",
        state_code: "01",
        latitude: "36.00604070",
        longitude: "-95.92112100",
        type: null,
      },
      {
        id: 1229,
        name: "Saint Andrew",
        state_code: "02",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 1226,
        name: "Saint George",
        state_code: "03",
        latitude: "37.09652780",
        longitude: "-113.56841640",
        type: null,
      },
      {
        id: 1224,
        name: "Saint James",
        state_code: "04",
        latitude: "48.52356600",
        longitude: "-1.32378850",
        type: null,
      },
      {
        id: 1227,
        name: "Saint John",
        state_code: "05",
        latitude: "45.27331530",
        longitude: "-66.06330800",
        type: null,
      },
      {
        id: 1223,
        name: "Saint Joseph",
        state_code: "06",
        latitude: "39.76745780",
        longitude: "-94.84668100",
        type: null,
      },
      {
        id: 1221,
        name: "Saint Lucy",
        state_code: "07",
        latitude: "38.76146250",
        longitude: "-77.44914390",
        type: null,
      },
      {
        id: 1230,
        name: "Saint Michael",
        state_code: "08",
        latitude: "36.03597700",
        longitude: "-95.84905200",
        type: null,
      },
      {
        id: 1222,
        name: "Saint Peter",
        state_code: "09",
        latitude: "37.08271190",
        longitude: "-94.51712500",
        type: null,
      },
      {
        id: 1220,
        name: "Saint Philip",
        state_code: "10",
        latitude: "35.23311400",
        longitude: "-89.43640420",
        type: null,
      },
      {
        id: 1225,
        name: "Saint Thomas",
        state_code: "11",
        latitude: "18.33809650",
        longitude: "-64.89409460",
        type: null,
      },
    ],
  },
  {
    name: "Belarus",
    iso3: "BLR",
    iso2: "BY",
    numeric_code: "112",
    phone_code: "375",
    capital: "Minsk",
    currency: "BYN",
    currency_name: "Belarusian ruble",
    currency_symbol: "Br",
    tld: ".by",
    native: "Белару́сь",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Belarusian",
    timezones: [
      {
        zoneName: "Europe/Minsk",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
    ],
    translations: {
      kr: "벨라루스",
      "pt-BR": "Bielorrússia",
      pt: "Bielorrússia",
      nl: "Wit-Rusland",
      hr: "Bjelorusija",
      fa: "بلاروس",
      de: "Weißrussland",
      es: "Bielorrusia",
      fr: "Biélorussie",
      ja: "ベラルーシ",
      it: "Bielorussia",
      cn: "白俄罗斯",
      tr: "Belarus",
    },
    latitude: "53.00000000",
    longitude: "28.00000000",
    emoji: "🇧🇾",
    emojiU: "U+1F1E7 U+1F1FE",
    states: [
      {
        id: 2959,
        name: "Brest Region",
        state_code: "BR",
        latitude: "52.52966410",
        longitude: "25.46064800",
        type: null,
      },
      {
        id: 2955,
        name: "Gomel Region",
        state_code: "HO",
        latitude: "52.16487540",
        longitude: "29.13332510",
        type: null,
      },
      {
        id: 2956,
        name: "Grodno Region",
        state_code: "HR",
        latitude: "53.65999450",
        longitude: "25.34485710",
        type: null,
      },
      {
        id: 2958,
        name: "Minsk",
        state_code: "HM",
        latitude: "53.90060110",
        longitude: "27.55897200",
        type: null,
      },
      {
        id: 2957,
        name: "Minsk Region",
        state_code: "MI",
        latitude: "54.10678890",
        longitude: "27.41292450",
        type: null,
      },
      {
        id: 2954,
        name: "Mogilev Region",
        state_code: "MA",
        latitude: "53.51017910",
        longitude: "30.40064440",
        type: null,
      },
      {
        id: 2960,
        name: "Vitebsk Region",
        state_code: "VI",
        latitude: "55.29598330",
        longitude: "28.75836270",
        type: null,
      },
    ],
  },
  {
    name: "Belgium",
    iso3: "BEL",
    iso2: "BE",
    numeric_code: "056",
    phone_code: "32",
    capital: "Brussels",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".be",
    native: "België",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Belgian",
    timezones: [
      {
        zoneName: "Europe/Brussels",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "벨기에",
      "pt-BR": "Bélgica",
      pt: "Bélgica",
      nl: "België",
      hr: "Belgija",
      fa: "بلژیک",
      de: "Belgien",
      es: "Bélgica",
      fr: "Belgique",
      ja: "ベルギー",
      it: "Belgio",
      cn: "比利时",
      tr: "Belçika",
    },
    latitude: "50.83333333",
    longitude: "4.00000000",
    emoji: "🇧🇪",
    emojiU: "U+1F1E7 U+1F1EA",
    states: [
      {
        id: 1381,
        name: "Antwerp",
        state_code: "VAN",
        latitude: "51.21944750",
        longitude: "4.40246430",
        type: null,
      },
      {
        id: 1376,
        name: "Brussels-Capital Region",
        state_code: "BRU",
        latitude: "50.85034630",
        longitude: "4.35172110",
        type: null,
      },
      {
        id: 1377,
        name: "East Flanders",
        state_code: "VOV",
        latitude: "51.03621010",
        longitude: "3.73731240",
        type: null,
      },
      {
        id: 1373,
        name: "Flanders",
        state_code: "VLG",
        latitude: "51.01087060",
        longitude: "3.72646130",
        type: null,
      },
      {
        id: 1374,
        name: "Flemish Brabant",
        state_code: "VBR",
        latitude: "50.88154340",
        longitude: "4.56459700",
        type: null,
      },
      {
        id: 1375,
        name: "Hainaut",
        state_code: "WHT",
        latitude: "50.52570760",
        longitude: "4.06210170",
        type: null,
      },
      {
        id: 1384,
        name: "Liège",
        state_code: "WLG",
        latitude: "50.63255740",
        longitude: "5.57966620",
        type: null,
      },
      {
        id: 1372,
        name: "Limburg",
        state_code: "VLI",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 1379,
        name: "Luxembourg",
        state_code: "WLX",
        latitude: "49.81527300",
        longitude: "6.12958300",
        type: null,
      },
      {
        id: 1378,
        name: "Namur",
        state_code: "WNA",
        latitude: "50.46738830",
        longitude: "4.87198540",
        type: null,
      },
      {
        id: 1380,
        name: "Wallonia",
        state_code: "WAL",
        latitude: "50.41756370",
        longitude: "4.45100660",
        type: null,
      },
      {
        id: 1382,
        name: "Walloon Brabant",
        state_code: "WBR",
        latitude: "50.63324100",
        longitude: "4.52431500",
        type: null,
      },
      {
        id: 1383,
        name: "West Flanders",
        state_code: "VWV",
        latitude: "51.04047470",
        longitude: "2.99942130",
        type: null,
      },
    ],
  },
  {
    name: "Belize",
    iso3: "BLZ",
    iso2: "BZ",
    numeric_code: "084",
    phone_code: "501",
    capital: "Belmopan",
    currency: "BZD",
    currency_name: "Belize dollar",
    currency_symbol: "$",
    tld: ".bz",
    native: "Belize",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Belizean",
    timezones: [
      {
        zoneName: "America/Belize",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America)",
      },
    ],
    translations: {
      kr: "벨리즈",
      "pt-BR": "Belize",
      pt: "Belize",
      nl: "Belize",
      hr: "Belize",
      fa: "بلیز",
      de: "Belize",
      es: "Belice",
      fr: "Belize",
      ja: "ベリーズ",
      it: "Belize",
      cn: "伯利兹",
      tr: "Belize",
    },
    latitude: "17.25000000",
    longitude: "-88.75000000",
    emoji: "🇧🇿",
    emojiU: "U+1F1E7 U+1F1FF",
    states: [
      {
        id: 264,
        name: "Belize District",
        state_code: "BZ",
        latitude: "17.56776790",
        longitude: "-88.40160410",
        type: null,
      },
      {
        id: 269,
        name: "Cayo District",
        state_code: "CY",
        latitude: "17.09844450",
        longitude: "-88.94138650",
        type: null,
      },
      {
        id: 266,
        name: "Corozal District",
        state_code: "CZL",
        latitude: "18.13492380",
        longitude: "-88.24611830",
        type: null,
      },
      {
        id: 268,
        name: "Orange Walk District",
        state_code: "OW",
        latitude: "17.76035300",
        longitude: "-88.86469800",
        type: null,
      },
      {
        id: 265,
        name: "Stann Creek District",
        state_code: "SC",
        latitude: "16.81166310",
        longitude: "-88.40160410",
        type: null,
      },
      {
        id: 267,
        name: "Toledo District",
        state_code: "TOL",
        latitude: "16.24919230",
        longitude: "-88.86469800",
        type: null,
      },
    ],
  },
  {
    name: "Benin",
    iso3: "BEN",
    iso2: "BJ",
    numeric_code: "204",
    phone_code: "229",
    capital: "Porto-Novo",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bj",
    native: "Bénin",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Beninese, Beninois",
    timezones: [
      {
        zoneName: "Africa/Porto-Novo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "베냉",
      "pt-BR": "Benin",
      pt: "Benim",
      nl: "Benin",
      hr: "Benin",
      fa: "بنین",
      de: "Benin",
      es: "Benín",
      fr: "Bénin",
      ja: "ベナン",
      it: "Benin",
      cn: "贝宁",
      tr: "Benin",
    },
    latitude: "9.50000000",
    longitude: "2.25000000",
    emoji: "🇧🇯",
    emojiU: "U+1F1E7 U+1F1EF",
    states: [
      {
        id: 3077,
        name: "Alibori Department",
        state_code: "AL",
        latitude: "10.96810930",
        longitude: "2.77798130",
        type: null,
      },
      {
        id: 3076,
        name: "Atakora Department",
        state_code: "AK",
        latitude: "10.79549310",
        longitude: "1.67606910",
        type: null,
      },
      {
        id: 3079,
        name: "Atlantique Department",
        state_code: "AQ",
        latitude: "6.65883910",
        longitude: "2.22366670",
        type: null,
      },
      {
        id: 3078,
        name: "Borgou Department",
        state_code: "BO",
        latitude: "9.53408640",
        longitude: "2.77798130",
        type: null,
      },
      {
        id: 3070,
        name: "Collines Department",
        state_code: "CO",
        latitude: "8.30222970",
        longitude: "2.30244600",
        type: null,
      },
      {
        id: 3072,
        name: "Donga Department",
        state_code: "DO",
        latitude: "9.71918670",
        longitude: "1.67606910",
        type: null,
      },
      {
        id: 3071,
        name: "Kouffo Department",
        state_code: "KO",
        latitude: "7.00358940",
        longitude: "1.75388170",
        type: null,
      },
      {
        id: 3081,
        name: "Littoral Department",
        state_code: "LI",
        latitude: "6.38069730",
        longitude: "2.44063870",
        type: null,
      },
      {
        id: 3075,
        name: "Mono Department",
        state_code: "MO",
        latitude: "37.92186080",
        longitude: "-118.95286450",
        type: null,
      },
      {
        id: 3080,
        name: "Ouémé Department",
        state_code: "OU",
        latitude: "6.61481520",
        longitude: "2.49999180",
        type: null,
      },
      {
        id: 3074,
        name: "Plateau Department",
        state_code: "PL",
        latitude: "7.34451410",
        longitude: "2.53960300",
        type: null,
      },
      {
        id: 3073,
        name: "Zou Department",
        state_code: "ZO",
        latitude: "7.34692680",
        longitude: "2.06651970",
        type: null,
      },
    ],
  },
  {
    name: "Bermuda",
    iso3: "BMU",
    iso2: "BM",
    numeric_code: "060",
    phone_code: "+1-441",
    capital: "Hamilton",
    currency: "BMD",
    currency_name: "Bermudian dollar",
    currency_symbol: "$",
    tld: ".bm",
    native: "Bermuda",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Bermudian, Bermudan",
    timezones: [
      {
        zoneName: "Atlantic/Bermuda",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "버뮤다",
      "pt-BR": "Bermudas",
      pt: "Bermudas",
      nl: "Bermuda",
      hr: "Bermudi",
      fa: "برمودا",
      de: "Bermuda",
      es: "Bermudas",
      fr: "Bermudes",
      ja: "バミューダ",
      it: "Bermuda",
      cn: "百慕大",
      tr: "Bermuda",
    },
    latitude: "32.33333333",
    longitude: "-64.75000000",
    emoji: "🇧🇲",
    emojiU: "U+1F1E7 U+1F1F2",
    states: [
      {
        id: 4860,
        name: "Devonshire",
        state_code: "DEV",
        latitude: "32.30380620",
        longitude: "-64.76069540",
        type: "municipality",
      },
      {
        id: 4861,
        name: "Hamilton",
        state_code: "HA",
        latitude: "32.34494320",
        longitude: "-64.72365000",
        type: "municipality",
      },
      {
        id: 4863,
        name: "Paget",
        state_code: "PAG",
        latitude: "32.28107400",
        longitude: "-64.77847870",
        type: "municipality",
      },
      {
        id: 4864,
        name: "Pembroke",
        state_code: "PEM",
        latitude: "32.30076720",
        longitude: "-64.79626300",
        type: "municipality",
      },
      {
        id: 4866,
        name: "Saint George's",
        state_code: "SGE",
        latitude: "17.12577590",
        longitude: "-62.56198110",
        type: "municipality",
      },
      {
        id: 4867,
        name: "Sandys",
        state_code: "SAN",
        latitude: "32.29995280",
        longitude: "-64.86741030",
        type: "municipality",
      },
      {
        id: 4868,
        name: "Smith's",
        state_code: "SMI",
        latitude: "32.31339660",
        longitude: "-64.73105880",
        type: "municipality",
      },
      {
        id: 4869,
        name: "Southampton",
        state_code: "SOU",
        latitude: "32.25400950",
        longitude: "-64.82590580",
        type: "municipality",
      },
      {
        id: 4870,
        name: "Warwick",
        state_code: "WAR",
        latitude: "32.26615340",
        longitude: "-64.80811980",
        type: "municipality",
      },
    ],
  },
  {
    name: "Bhutan",
    iso3: "BTN",
    iso2: "BT",
    numeric_code: "064",
    phone_code: "975",
    capital: "Thimphu",
    currency: "BTN",
    currency_name: "Bhutanese ngultrum",
    currency_symbol: "Nu.",
    tld: ".bt",
    native: "ʼbrug-yul",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Bhutanese",
    timezones: [
      {
        zoneName: "Asia/Thimphu",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "BTT",
        tzName: "Bhutan Time",
      },
    ],
    translations: {
      kr: "부탄",
      "pt-BR": "Butão",
      pt: "Butão",
      nl: "Bhutan",
      hr: "Butan",
      fa: "بوتان",
      de: "Bhutan",
      es: "Bután",
      fr: "Bhoutan",
      ja: "ブータン",
      it: "Bhutan",
      cn: "不丹",
      tr: "Butan",
    },
    latitude: "27.50000000",
    longitude: "90.50000000",
    emoji: "🇧🇹",
    emojiU: "U+1F1E7 U+1F1F9",
    states: [
      {
        id: 240,
        name: "Bumthang District",
        state_code: "33",
        latitude: "27.64183900",
        longitude: "90.67730460",
        type: null,
      },
      {
        id: 239,
        name: "Chukha District",
        state_code: "12",
        latitude: "27.07843040",
        longitude: "89.47421770",
        type: null,
      },
      {
        id: 238,
        name: "Dagana District",
        state_code: "22",
        latitude: "27.03228610",
        longitude: "89.88793040",
        type: null,
      },
      {
        id: 229,
        name: "Gasa District",
        state_code: "GA",
        latitude: "28.01858860",
        longitude: "89.92532330",
        type: null,
      },
      {
        id: 232,
        name: "Haa District",
        state_code: "13",
        latitude: "27.26516690",
        longitude: "89.17059980",
        type: null,
      },
      {
        id: 234,
        name: "Lhuntse District",
        state_code: "44",
        latitude: "27.82649890",
        longitude: "91.13530200",
        type: null,
      },
      {
        id: 242,
        name: "Mongar District",
        state_code: "42",
        latitude: "27.26170590",
        longitude: "91.28910360",
        type: null,
      },
      {
        id: 237,
        name: "Paro District",
        state_code: "11",
        latitude: "27.42859490",
        longitude: "89.41665160",
        type: null,
      },
      {
        id: 244,
        name: "Pemagatshel District",
        state_code: "43",
        latitude: "27.00238200",
        longitude: "91.34692470",
        type: null,
      },
      {
        id: 235,
        name: "Punakha District",
        state_code: "23",
        latitude: "27.69037160",
        longitude: "89.88793040",
        type: null,
      },
      {
        id: 243,
        name: "Samdrup Jongkhar District",
        state_code: "45",
        latitude: "26.80356820",
        longitude: "91.50392070",
        type: null,
      },
      {
        id: 246,
        name: "Samtse District",
        state_code: "14",
        latitude: "27.02918320",
        longitude: "89.05615320",
        type: null,
      },
      {
        id: 247,
        name: "Sarpang District",
        state_code: "31",
        latitude: "26.93730410",
        longitude: "90.48799160",
        type: null,
      },
      {
        id: 241,
        name: "Thimphu District",
        state_code: "15",
        latitude: "27.47122160",
        longitude: "89.63390410",
        type: null,
      },
      {
        id: 236,
        name: "Trashigang District",
        state_code: "41",
        latitude: "27.25667950",
        longitude: "91.75388170",
        type: null,
      },
      {
        id: 245,
        name: "Trongsa District",
        state_code: "32",
        latitude: "27.50022690",
        longitude: "90.50806340",
        type: null,
      },
      {
        id: 230,
        name: "Tsirang District",
        state_code: "21",
        latitude: "27.03220700",
        longitude: "90.18696440",
        type: null,
      },
      {
        id: 231,
        name: "Wangdue Phodrang District",
        state_code: "24",
        latitude: "27.45260460",
        longitude: "90.06749280",
        type: null,
      },
      {
        id: 233,
        name: "Zhemgang District",
        state_code: "34",
        latitude: "27.07697500",
        longitude: "90.82940020",
        type: null,
      },
    ],
  },
  {
    name: "Bolivia",
    iso3: "BOL",
    iso2: "BO",
    numeric_code: "068",
    phone_code: "591",
    capital: "Sucre",
    currency: "BOB",
    currency_name: "Bolivian boliviano",
    currency_symbol: "Bs.",
    tld: ".bo",
    native: "Bolivia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Bolivian",
    timezones: [
      {
        zoneName: "America/La_Paz",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BOT",
        tzName: "Bolivia Time",
      },
    ],
    translations: {
      kr: "볼리비아",
      "pt-BR": "Bolívia",
      pt: "Bolívia",
      nl: "Bolivia",
      hr: "Bolivija",
      fa: "بولیوی",
      de: "Bolivien",
      es: "Bolivia",
      fr: "Bolivie",
      ja: "ボリビア多民族国",
      it: "Bolivia",
      cn: "玻利维亚",
      tr: "Bolivya",
    },
    latitude: "-17.00000000",
    longitude: "-65.00000000",
    emoji: "🇧🇴",
    emojiU: "U+1F1E7 U+1F1F4",
    states: [
      {
        id: 3375,
        name: "Beni Department",
        state_code: "B",
        latitude: "-14.37827470",
        longitude: "-65.09577920",
        type: null,
      },
      {
        id: 3382,
        name: "Chuquisaca Department",
        state_code: "H",
        latitude: "-20.02491440",
        longitude: "-64.14782360",
        type: null,
      },
      {
        id: 3381,
        name: "Cochabamba Department",
        state_code: "C",
        latitude: "-17.56816750",
        longitude: "-65.47573600",
        type: null,
      },
      {
        id: 3380,
        name: "La Paz Department",
        state_code: "L",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3376,
        name: "Oruro Department",
        state_code: "O",
        latitude: "-18.57115790",
        longitude: "-67.76159830",
        type: null,
      },
      {
        id: 3379,
        name: "Pando Department",
        state_code: "N",
        latitude: "-10.79889010",
        longitude: "-66.99880110",
        type: null,
      },
      {
        id: 3383,
        name: "Potosí Department",
        state_code: "P",
        latitude: "-20.62471300",
        longitude: "-66.99880110",
        type: null,
      },
      {
        id: 3377,
        name: "Santa Cruz Department",
        state_code: "S",
        latitude: "-16.74760370",
        longitude: "-62.07509980",
        type: null,
      },
      {
        id: 3378,
        name: "Tarija Department",
        state_code: "T",
        latitude: "-21.58315950",
        longitude: "-63.95861110",
        type: null,
      },
    ],
  },
  {
    name: "Bonaire, Sint Eustatius and Saba",
    iso3: "BES",
    iso2: "BQ",
    numeric_code: "535",
    phone_code: "599",
    capital: "Kralendijk",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".an",
    native: "Caribisch Nederland",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Bonaire",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "보네르 섬",
      "pt-BR": "Bonaire",
      pt: "Bonaire",
      fa: "بونیر",
      de: "Bonaire, Sint Eustatius und Saba",
      fr: "Bonaire, Saint-Eustache et Saba",
      it: "Bonaire, Saint-Eustache e Saba",
      cn: "博内尔岛、圣尤斯特歇斯和萨巴岛",
      tr: "Karayip Hollandasi",
    },
    latitude: "12.15000000",
    longitude: "-68.26666700",
    emoji: "🇧🇶",
    emojiU: "U+1F1E7 U+1F1F6",
    states: [
      {
        id: 5086,
        name: "Bonaire",
        state_code: "BQ1",
        latitude: "12.20189020",
        longitude: "-68.26238220",
        type: "special municipality",
      },
      {
        id: 5087,
        name: "Saba",
        state_code: "BQ2",
        latitude: "17.63546420",
        longitude: "-63.23267630",
        type: "special municipality",
      },
      {
        id: 5088,
        name: "Sint Eustatius",
        state_code: "BQ3",
        latitude: "17.48903060",
        longitude: "-62.97355500",
        type: "special municipality",
      },
    ],
  },
  {
    name: "Bosnia and Herzegovina",
    iso3: "BIH",
    iso2: "BA",
    numeric_code: "070",
    phone_code: "387",
    capital: "Sarajevo",
    currency: "BAM",
    currency_name: "Bosnia and Herzegovina convertible mark",
    currency_symbol: "KM",
    tld: ".ba",
    native: "Bosna i Hercegovina",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Bosnian or Herzegovinian",
    timezones: [
      {
        zoneName: "Europe/Sarajevo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "보스니아 헤르체고비나",
      "pt-BR": "Bósnia e Herzegovina",
      pt: "Bósnia e Herzegovina",
      nl: "Bosnië en Herzegovina",
      hr: "Bosna i Hercegovina",
      fa: "بوسنی و هرزگوین",
      de: "Bosnien und Herzegowina",
      es: "Bosnia y Herzegovina",
      fr: "Bosnie-Herzégovine",
      ja: "ボスニア・ヘルツェゴビナ",
      it: "Bosnia ed Erzegovina",
      cn: "波斯尼亚和黑塞哥维那",
      tr: "Bosna Hersek",
    },
    latitude: "44.00000000",
    longitude: "18.00000000",
    emoji: "🇧🇦",
    emojiU: "U+1F1E7 U+1F1E6",
    states: [
      {
        id: 472,
        name: "Bosnian Podrinje Canton",
        state_code: "05",
        latitude: "43.68749000",
        longitude: "18.82443940",
        type: null,
      },
      {
        id: 460,
        name: "Brčko District",
        state_code: "BRC",
        latitude: "44.84059440",
        longitude: "18.74215300",
        type: null,
      },
      {
        id: 471,
        name: "Canton 10",
        state_code: "10",
        latitude: "43.95341550",
        longitude: "16.94251870",
        type: null,
      },
      {
        id: 462,
        name: "Central Bosnia Canton",
        state_code: "06",
        latitude: "44.13818560",
        longitude: "17.68667140",
        type: null,
      },
      {
        id: 467,
        name: "Federation of Bosnia and Herzegovina",
        state_code: "BIH",
        latitude: "43.88748970",
        longitude: "17.84279300",
        type: null,
      },
      {
        id: 463,
        name: "Herzegovina-Neretva Canton",
        state_code: "07",
        latitude: "43.52651590",
        longitude: "17.76362100",
        type: null,
      },
      {
        id: 464,
        name: "Posavina Canton",
        state_code: "02",
        latitude: "45.07520940",
        longitude: "18.37763040",
        type: null,
      },
      {
        id: 470,
        name: "Republika Srpska",
        state_code: "SRP",
        latitude: "44.72801860",
        longitude: "17.31481360",
        type: null,
      },
      {
        id: 466,
        name: "Sarajevo Canton",
        state_code: "09",
        latitude: "43.85125640",
        longitude: "18.29534420",
        type: null,
      },
      {
        id: 461,
        name: "Tuzla Canton",
        state_code: "03",
        latitude: "44.53434630",
        longitude: "18.69727970",
        type: null,
      },
      {
        id: 465,
        name: "Una-Sana Canton",
        state_code: "01",
        latitude: "44.65031160",
        longitude: "16.31716290",
        type: null,
      },
      {
        id: 469,
        name: "West Herzegovina Canton",
        state_code: "08",
        latitude: "43.43692440",
        longitude: "17.38488310",
        type: null,
      },
      {
        id: 468,
        name: "Zenica-Doboj Canton",
        state_code: "04",
        latitude: "44.21271090",
        longitude: "18.16046250",
        type: null,
      },
    ],
  },
  {
    name: "Botswana",
    iso3: "BWA",
    iso2: "BW",
    numeric_code: "072",
    phone_code: "267",
    capital: "Gaborone",
    currency: "BWP",
    currency_name: "Botswana pula",
    currency_symbol: "P",
    tld: ".bw",
    native: "Botswana",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Motswana, Botswanan",
    timezones: [
      {
        zoneName: "Africa/Gaborone",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "보츠와나",
      "pt-BR": "Botsuana",
      pt: "Botsuana",
      nl: "Botswana",
      hr: "Bocvana",
      fa: "بوتسوانا",
      de: "Botswana",
      es: "Botswana",
      fr: "Botswana",
      ja: "ボツワナ",
      it: "Botswana",
      cn: "博茨瓦纳",
      tr: "Botsvana",
    },
    latitude: "-22.00000000",
    longitude: "24.00000000",
    emoji: "🇧🇼",
    emojiU: "U+1F1E7 U+1F1FC",
    states: [
      {
        id: 3067,
        name: "Central District",
        state_code: "CE",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3061,
        name: "Ghanzi District",
        state_code: "GH",
        latitude: "-21.86523140",
        longitude: "21.85685860",
        type: null,
      },
      {
        id: 3066,
        name: "Kgalagadi District",
        state_code: "KG",
        latitude: "-24.75502850",
        longitude: "21.85685860",
        type: null,
      },
      {
        id: 3062,
        name: "Kgatleng District",
        state_code: "KL",
        latitude: "-24.19704450",
        longitude: "26.23046160",
        type: null,
      },
      {
        id: 3069,
        name: "Kweneng District",
        state_code: "KW",
        latitude: "-23.83672490",
        longitude: "25.28375850",
        type: null,
      },
      {
        id: 3060,
        name: "Ngamiland",
        state_code: "NG",
        latitude: "-19.19053210",
        longitude: "23.00119890",
        type: null,
      },
      {
        id: 3068,
        name: "North-East District",
        state_code: "NE",
        latitude: "37.58844610",
        longitude: "-94.68637820",
        type: null,
      },
      {
        id: 3065,
        name: "North-West District",
        state_code: "NW",
        latitude: "39.34463070",
        longitude: "-76.68542830",
        type: null,
      },
      {
        id: 3064,
        name: "South-East District",
        state_code: "SE",
        latitude: "31.21637980",
        longitude: "-82.35270440",
        type: null,
      },
      {
        id: 3063,
        name: "Southern District",
        state_code: "SO",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Bouvet Island",
    iso3: "BVT",
    iso2: "BV",
    numeric_code: "074",
    phone_code: "0055",
    capital: "",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".bv",
    native: "Bouvetøya",
    region: "",
    region_id: null,
    subregion: "",
    subregion_id: null,
    nationality: "Bouvet Island",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "부벳 섬",
      "pt-BR": "Ilha Bouvet",
      pt: "Ilha Bouvet",
      nl: "Bouveteiland",
      hr: "Otok Bouvet",
      fa: "جزیره بووه",
      de: "Bouvetinsel",
      es: "Isla Bouvet",
      fr: "Île Bouvet",
      ja: "ブーベ島",
      it: "Isola Bouvet",
      cn: "布维岛",
      tr: "Bouvet Adasi",
    },
    latitude: "-54.43333333",
    longitude: "3.40000000",
    emoji: "🇧🇻",
    emojiU: "U+1F1E7 U+1F1FB",
    states: [],
  },
  {
    name: "Brazil",
    iso3: "BRA",
    iso2: "BR",
    numeric_code: "076",
    phone_code: "55",
    capital: "Brasilia",
    currency: "BRL",
    currency_name: "Brazilian real",
    currency_symbol: "R$",
    tld: ".br",
    native: "Brasil",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Brazilian",
    timezones: [
      {
        zoneName: "America/Araguaina",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Bahia",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Belem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Boa_Vista",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Campo_Grande",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Cuiaba",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "BRT",
        tzName: "Brasilia Time",
      },
      {
        zoneName: "America/Eirunepe",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time",
      },
      {
        zoneName: "America/Fortaleza",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Maceio",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Manaus",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)",
      },
      {
        zoneName: "America/Noronha",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "FNT",
        tzName: "Fernando de Noronha Time",
      },
      {
        zoneName: "America/Porto_Velho",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AMT",
        tzName: "Amazon Time (Brazil)[3",
      },
      {
        zoneName: "America/Recife",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Rio_Branco",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ACT",
        tzName: "Acre Time",
      },
      {
        zoneName: "America/Santarem",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
      {
        zoneName: "America/Sao_Paulo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "BRT",
        tzName: "Brasília Time",
      },
    ],
    translations: {
      kr: "브라질",
      "pt-BR": "Brasil",
      pt: "Brasil",
      nl: "Brazilië",
      hr: "Brazil",
      fa: "برزیل",
      de: "Brasilien",
      es: "Brasil",
      fr: "Brésil",
      ja: "ブラジル",
      it: "Brasile",
      cn: "巴西",
      tr: "Brezilya",
    },
    latitude: "-10.00000000",
    longitude: "-55.00000000",
    emoji: "🇧🇷",
    emojiU: "U+1F1E7 U+1F1F7",
    states: [
      {
        id: 2012,
        name: "Acre",
        state_code: "AC",
        latitude: "-9.02379600",
        longitude: "-70.81199500",
        type: null,
      },
      {
        id: 2007,
        name: "Alagoas",
        state_code: "AL",
        latitude: "-9.57130580",
        longitude: "-36.78195050",
        type: null,
      },
      {
        id: 1999,
        name: "Amapá",
        state_code: "AP",
        latitude: "0.90199250",
        longitude: "-52.00295650",
        type: null,
      },
      {
        id: 2004,
        name: "Amazonas",
        state_code: "AM",
        latitude: "-3.07000000",
        longitude: "-61.66000000",
        type: null,
      },
      {
        id: 2002,
        name: "Bahia",
        state_code: "BA",
        latitude: "-11.40987400",
        longitude: "-41.28085700",
        type: null,
      },
      {
        id: 2016,
        name: "Ceará",
        state_code: "CE",
        latitude: "-5.49839770",
        longitude: "-39.32062410",
        type: null,
      },
      {
        id: 2017,
        name: "Distrito Federal",
        state_code: "DF",
        latitude: "-15.79976540",
        longitude: "-47.86447150",
        type: null,
      },
      {
        id: 2018,
        name: "Espírito Santo",
        state_code: "ES",
        latitude: "-19.18342290",
        longitude: "-40.30886260",
        type: null,
      },
      {
        id: 2000,
        name: "Goiás",
        state_code: "GO",
        latitude: "-15.82703690",
        longitude: "-49.83622370",
        type: null,
      },
      {
        id: 2015,
        name: "Maranhão",
        state_code: "MA",
        latitude: "-4.96094980",
        longitude: "-45.27441590",
        type: null,
      },
      {
        id: 2011,
        name: "Mato Grosso",
        state_code: "MT",
        latitude: "-12.68187120",
        longitude: "-56.92109900",
        type: null,
      },
      {
        id: 2010,
        name: "Mato Grosso do Sul",
        state_code: "MS",
        latitude: "-20.77222950",
        longitude: "-54.78515310",
        type: null,
      },
      {
        id: 1998,
        name: "Minas Gerais",
        state_code: "MG",
        latitude: "-18.51217800",
        longitude: "-44.55503080",
        type: null,
      },
      {
        id: 2009,
        name: "Pará",
        state_code: "PA",
        latitude: "-1.99812710",
        longitude: "-54.93061520",
        type: null,
      },
      {
        id: 2005,
        name: "Paraíba",
        state_code: "PB",
        latitude: "-7.23996090",
        longitude: "-36.78195050",
        type: null,
      },
      {
        id: 2022,
        name: "Paraná",
        state_code: "PR",
        latitude: "-25.25208880",
        longitude: "-52.02154150",
        type: null,
      },
      {
        id: 2006,
        name: "Pernambuco",
        state_code: "PE",
        latitude: "-8.81371730",
        longitude: "-36.95410700",
        type: null,
      },
      {
        id: 2008,
        name: "Piauí",
        state_code: "PI",
        latitude: "-7.71834010",
        longitude: "-42.72892360",
        type: null,
      },
      {
        id: 1997,
        name: "Rio de Janeiro",
        state_code: "RJ",
        latitude: "-22.90684670",
        longitude: "-43.17289650",
        type: null,
      },
      {
        id: 2019,
        name: "Rio Grande do Norte",
        state_code: "RN",
        latitude: "-5.40258030",
        longitude: "-36.95410700",
        type: null,
      },
      {
        id: 2001,
        name: "Rio Grande do Sul",
        state_code: "RS",
        latitude: "-30.03463160",
        longitude: "-51.21769860",
        type: null,
      },
      {
        id: 2013,
        name: "Rondônia",
        state_code: "RO",
        latitude: "-11.50573410",
        longitude: "-63.58061100",
        type: null,
      },
      {
        id: 4858,
        name: "Roraima",
        state_code: "RR",
        latitude: "2.73759710",
        longitude: "-62.07509980",
        type: null,
      },
      {
        id: 2014,
        name: "Santa Catarina",
        state_code: "SC",
        latitude: "-27.33000000",
        longitude: "-49.44000000",
        type: null,
      },
      {
        id: 2021,
        name: "São Paulo",
        state_code: "SP",
        latitude: "-23.55051990",
        longitude: "-46.63330940",
        type: null,
      },
      {
        id: 2003,
        name: "Sergipe",
        state_code: "SE",
        latitude: "-10.57409340",
        longitude: "-37.38565810",
        type: null,
      },
      {
        id: 2020,
        name: "Tocantins",
        state_code: "TO",
        latitude: "-10.17528000",
        longitude: "-48.29824740",
        type: null,
      },
    ],
  },
  {
    name: "British Indian Ocean Territory",
    iso3: "IOT",
    iso2: "IO",
    numeric_code: "086",
    phone_code: "246",
    capital: "Diego Garcia",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".io",
    native: "British Indian Ocean Territory",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "BIOT",
    timezones: [
      {
        zoneName: "Indian/Chagos",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "IOT",
        tzName: "Indian Ocean Time",
      },
    ],
    translations: {
      kr: "영국령 인도양 지역",
      "pt-BR": "Território Britânico do Oceano íÍdico",
      pt: "Território Britânico do Oceano Índico",
      nl: "Britse Gebieden in de Indische Oceaan",
      hr: "Britanski Indijskooceanski teritorij",
      fa: "قلمرو بریتانیا در اقیانوس هند",
      de: "Britisches Territorium im Indischen Ozean",
      es: "Territorio Británico del Océano Índico",
      fr: "Territoire britannique de l'océan Indien",
      ja: "イギリス領インド洋地域",
      it: "Territorio britannico dell'oceano indiano",
      cn: "英属印度洋领地",
      tr: "Britanya Hint Okyanusu Topraklari",
    },
    latitude: "-6.00000000",
    longitude: "71.50000000",
    emoji: "🇮🇴",
    emojiU: "U+1F1EE U+1F1F4",
    states: [],
  },
  {
    name: "Brunei",
    iso3: "BRN",
    iso2: "BN",
    numeric_code: "096",
    phone_code: "673",
    capital: "Bandar Seri Begawan",
    currency: "BND",
    currency_name: "Brunei dollar",
    currency_symbol: "B$",
    tld: ".bn",
    native: "Negara Brunei Darussalam",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Bruneian",
    timezones: [
      {
        zoneName: "Asia/Brunei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "BNT",
        tzName: "Brunei Darussalam Time",
      },
    ],
    translations: {
      kr: "브루나이",
      "pt-BR": "Brunei",
      pt: "Brunei",
      nl: "Brunei",
      hr: "Brunej",
      fa: "برونئی",
      de: "Brunei",
      es: "Brunei",
      fr: "Brunei",
      ja: "ブルネイ・ダルサラーム",
      it: "Brunei",
      cn: "文莱",
      tr: "Brunei",
    },
    latitude: "4.50000000",
    longitude: "114.66666666",
    emoji: "🇧🇳",
    emojiU: "U+1F1E7 U+1F1F3",
    states: [
      {
        id: 1217,
        name: "Belait District",
        state_code: "BE",
        latitude: "4.37507490",
        longitude: "114.61928990",
        type: null,
      },
      {
        id: 1216,
        name: "Brunei-Muara District",
        state_code: "BM",
        latitude: "4.93112060",
        longitude: "114.95168690",
        type: null,
      },
      {
        id: 1218,
        name: "Temburong District",
        state_code: "TE",
        latitude: "4.62041280",
        longitude: "115.14148400",
        type: null,
      },
      {
        id: 1219,
        name: "Tutong District",
        state_code: "TU",
        latitude: "4.71403730",
        longitude: "114.66679390",
        type: null,
      },
    ],
  },
  {
    name: "Bulgaria",
    iso3: "BGR",
    iso2: "BG",
    numeric_code: "100",
    phone_code: "359",
    capital: "Sofia",
    currency: "BGN",
    currency_name: "Bulgarian lev",
    currency_symbol: "Лв.",
    tld: ".bg",
    native: "България",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Bulgarian",
    timezones: [
      {
        zoneName: "Europe/Sofia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "불가리아",
      "pt-BR": "Bulgária",
      pt: "Bulgária",
      nl: "Bulgarije",
      hr: "Bugarska",
      fa: "بلغارستان",
      de: "Bulgarien",
      es: "Bulgaria",
      fr: "Bulgarie",
      ja: "ブルガリア",
      it: "Bulgaria",
      cn: "保加利亚",
      tr: "Bulgaristan",
    },
    latitude: "43.00000000",
    longitude: "25.00000000",
    emoji: "🇧🇬",
    emojiU: "U+1F1E7 U+1F1EC",
    states: [
      {
        id: 4699,
        name: "Blagoevgrad Province",
        state_code: "01",
        latitude: "42.02086140",
        longitude: "23.09433560",
        type: null,
      },
      {
        id: 4715,
        name: "Burgas Province",
        state_code: "02",
        latitude: "42.50480000",
        longitude: "27.46260790",
        type: null,
      },
      {
        id: 4718,
        name: "Dobrich Province",
        state_code: "08",
        latitude: "43.57278600",
        longitude: "27.82728020",
        type: null,
      },
      {
        id: 4693,
        name: "Gabrovo Province",
        state_code: "07",
        latitude: "42.86847000",
        longitude: "25.31688900",
        type: null,
      },
      {
        id: 4704,
        name: "Haskovo Province",
        state_code: "26",
        latitude: "41.93441780",
        longitude: "25.55546720",
        type: null,
      },
      {
        id: 4702,
        name: "Kardzhali Province",
        state_code: "09",
        latitude: "41.63384160",
        longitude: "25.37766870",
        type: null,
      },
      {
        id: 4703,
        name: "Kyustendil Province",
        state_code: "10",
        latitude: "42.28687990",
        longitude: "22.69396350",
        type: null,
      },
      {
        id: 4710,
        name: "Lovech Province",
        state_code: "11",
        latitude: "43.13677980",
        longitude: "24.71393350",
        type: null,
      },
      {
        id: 4696,
        name: "Montana Province",
        state_code: "12",
        latitude: "43.40851480",
        longitude: "23.22575890",
        type: null,
      },
      {
        id: 4712,
        name: "Pazardzhik Province",
        state_code: "13",
        latitude: "42.19275670",
        longitude: "24.33362260",
        type: null,
      },
      {
        id: 4695,
        name: "Pernik Province",
        state_code: "14",
        latitude: "42.60519900",
        longitude: "23.03779160",
        type: null,
      },
      {
        id: 4706,
        name: "Pleven Province",
        state_code: "15",
        latitude: "43.41701690",
        longitude: "24.60667080",
        type: null,
      },
      {
        id: 4701,
        name: "Plovdiv Province",
        state_code: "16",
        latitude: "42.13540790",
        longitude: "24.74529040",
        type: null,
      },
      {
        id: 4698,
        name: "Razgrad Province",
        state_code: "17",
        latitude: "43.52717050",
        longitude: "26.52412280",
        type: null,
      },
      {
        id: 4713,
        name: "Ruse Province",
        state_code: "18",
        latitude: "43.83559640",
        longitude: "25.96561440",
        type: null,
      },
      {
        id: 4882,
        name: "Shumen",
        state_code: "27",
        latitude: "43.27123980",
        longitude: "26.93612860",
        type: null,
      },
      {
        id: 4708,
        name: "Silistra Province",
        state_code: "19",
        latitude: "44.11471010",
        longitude: "27.26714540",
        type: null,
      },
      {
        id: 4700,
        name: "Sliven Province",
        state_code: "20",
        latitude: "42.68167020",
        longitude: "26.32285690",
        type: null,
      },
      {
        id: 4694,
        name: "Smolyan Province",
        state_code: "21",
        latitude: "41.57741480",
        longitude: "24.70108710",
        type: null,
      },
      {
        id: 4705,
        name: "Sofia City Province",
        state_code: "22",
        latitude: "42.75701090",
        longitude: "23.45046830",
        type: null,
      },
      {
        id: 4719,
        name: "Sofia Province",
        state_code: "23",
        latitude: "42.67344000",
        longitude: "23.83349370",
        type: null,
      },
      {
        id: 4707,
        name: "Stara Zagora Province",
        state_code: "24",
        latitude: "42.42577090",
        longitude: "25.63448550",
        type: null,
      },
      {
        id: 4714,
        name: "Targovishte Province",
        state_code: "25",
        latitude: "43.24623490",
        longitude: "26.56912510",
        type: null,
      },
      {
        id: 4717,
        name: "Varna Province",
        state_code: "03",
        latitude: "43.20464770",
        longitude: "27.91054880",
        type: null,
      },
      {
        id: 4709,
        name: "Veliko Tarnovo Province",
        state_code: "04",
        latitude: "43.07565390",
        longitude: "25.61715000",
        type: null,
      },
      {
        id: 4697,
        name: "Vidin Province",
        state_code: "05",
        latitude: "43.99617390",
        longitude: "22.86795150",
        type: null,
      },
      {
        id: 4711,
        name: "Vratsa Province",
        state_code: "06",
        latitude: "43.21018060",
        longitude: "23.55292100",
        type: null,
      },
      {
        id: 4716,
        name: "Yambol Province",
        state_code: "28",
        latitude: "42.48414940",
        longitude: "26.50352960",
        type: null,
      },
    ],
  },
  {
    name: "Burkina Faso",
    iso3: "BFA",
    iso2: "BF",
    numeric_code: "854",
    phone_code: "226",
    capital: "Ouagadougou",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".bf",
    native: "Burkina Faso",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Burkinabe",
    timezones: [
      {
        zoneName: "Africa/Ouagadougou",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "부르키나 파소",
      "pt-BR": "Burkina Faso",
      pt: "Burquina Faso",
      nl: "Burkina Faso",
      hr: "Burkina Faso",
      fa: "بورکینافاسو",
      de: "Burkina Faso",
      es: "Burkina Faso",
      fr: "Burkina Faso",
      ja: "ブルキナファソ",
      it: "Burkina Faso",
      cn: "布基纳法索",
      tr: "Burkina Faso",
    },
    latitude: "13.00000000",
    longitude: "-2.00000000",
    emoji: "🇧🇫",
    emojiU: "U+1F1E7 U+1F1EB",
    states: [
      {
        id: 3160,
        name: "Balé Province",
        state_code: "BAL",
        latitude: "11.78206020",
        longitude: "-3.01757120",
        type: null,
      },
      {
        id: 3155,
        name: "Bam Province",
        state_code: "BAM",
        latitude: "13.44613300",
        longitude: "-1.59839590",
        type: null,
      },
      {
        id: 3120,
        name: "Banwa Province",
        state_code: "BAN",
        latitude: "12.13230530",
        longitude: "-4.15137640",
        type: null,
      },
      {
        id: 3152,
        name: "Bazèga Province",
        state_code: "BAZ",
        latitude: "11.97676920",
        longitude: "-1.44346900",
        type: null,
      },
      {
        id: 3138,
        name: "Boucle du Mouhoun Region",
        state_code: "01",
        latitude: "12.41660000",
        longitude: "-3.41955270",
        type: null,
      },
      {
        id: 3121,
        name: "Bougouriba Province",
        state_code: "BGR",
        latitude: "10.87226460",
        longitude: "-3.33889170",
        type: null,
      },
      {
        id: 3131,
        name: "Boulgou",
        state_code: "BLG",
        latitude: "11.43367660",
        longitude: "-0.37483540",
        type: null,
      },
      {
        id: 3153,
        name: "Cascades Region",
        state_code: "02",
        latitude: "10.40729920",
        longitude: "-4.56244260",
        type: null,
      },
      {
        id: 3136,
        name: "Centre",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3162,
        name: "Centre-Est Region",
        state_code: "04",
        latitude: "11.52476740",
        longitude: "-0.14949880",
        type: null,
      },
      {
        id: 3127,
        name: "Centre-Nord Region",
        state_code: "05",
        latitude: "13.17244640",
        longitude: "-0.90566230",
        type: null,
      },
      {
        id: 3115,
        name: "Centre-Ouest Region",
        state_code: "06",
        latitude: "11.87984660",
        longitude: "-2.30244600",
        type: null,
      },
      {
        id: 3149,
        name: "Centre-Sud Region",
        state_code: "07",
        latitude: "11.52289110",
        longitude: "-1.05861350",
        type: null,
      },
      {
        id: 3167,
        name: "Comoé Province",
        state_code: "COM",
        latitude: "10.40729920",
        longitude: "-4.56244260",
        type: null,
      },
      {
        id: 3158,
        name: "Est Region",
        state_code: "08",
        latitude: "12.43655260",
        longitude: "0.90566230",
        type: null,
      },
      {
        id: 3148,
        name: "Ganzourgou Province",
        state_code: "GAN",
        latitude: "12.25376480",
        longitude: "-0.75328090",
        type: null,
      },
      {
        id: 3122,
        name: "Gnagna Province",
        state_code: "GNA",
        latitude: "12.89749920",
        longitude: "0.07467670",
        type: null,
      },
      {
        id: 3143,
        name: "Gourma Province",
        state_code: "GOU",
        latitude: "12.16244730",
        longitude: "0.67730460",
        type: null,
      },
      {
        id: 3165,
        name: "Hauts-Bassins Region",
        state_code: "09",
        latitude: "11.49420030",
        longitude: "-4.23333550",
        type: null,
      },
      {
        id: 3129,
        name: "Houet Province",
        state_code: "HOU",
        latitude: "11.13204470",
        longitude: "-4.23333550",
        type: null,
      },
      {
        id: 3135,
        name: "Ioba Province",
        state_code: "IOB",
        latitude: "11.05620340",
        longitude: "-3.01757120",
        type: null,
      },
      {
        id: 3168,
        name: "Kadiogo Province",
        state_code: "KAD",
        latitude: "12.34258970",
        longitude: "-1.44346900",
        type: null,
      },
      {
        id: 3112,
        name: "Kénédougou Province",
        state_code: "KEN",
        latitude: "11.39193950",
        longitude: "-4.97665400",
        type: null,
      },
      {
        id: 3132,
        name: "Komondjari Province",
        state_code: "KMD",
        latitude: "12.71265270",
        longitude: "0.67730460",
        type: null,
      },
      {
        id: 3157,
        name: "Kompienga Province",
        state_code: "KMP",
        latitude: "11.52383620",
        longitude: "0.75328090",
        type: null,
      },
      {
        id: 3146,
        name: "Kossi Province",
        state_code: "KOS",
        latitude: "12.96045800",
        longitude: "-3.90626880",
        type: null,
      },
      {
        id: 3133,
        name: "Koulpélogo Province",
        state_code: "KOP",
        latitude: "11.52476740",
        longitude: "0.14949880",
        type: null,
      },
      {
        id: 3161,
        name: "Kouritenga Province",
        state_code: "KOT",
        latitude: "12.16318130",
        longitude: "-0.22446620",
        type: null,
      },
      {
        id: 3147,
        name: "Kourwéogo Province",
        state_code: "KOW",
        latitude: "12.70774950",
        longitude: "-1.75388170",
        type: null,
      },
      {
        id: 3159,
        name: "Léraba Province",
        state_code: "LER",
        latitude: "10.66487850",
        longitude: "-5.31025050",
        type: null,
      },
      {
        id: 3151,
        name: "Loroum Province",
        state_code: "LOR",
        latitude: "13.81298140",
        longitude: "-2.06651970",
        type: null,
      },
      {
        id: 3123,
        name: "Mouhoun",
        state_code: "MOU",
        latitude: "12.14323810",
        longitude: "-3.33889170",
        type: null,
      },
      {
        id: 3116,
        name: "Nahouri Province",
        state_code: "NAO",
        latitude: "11.25022670",
        longitude: "-1.13530200",
        type: null,
      },
      {
        id: 3113,
        name: "Namentenga Province",
        state_code: "NAM",
        latitude: "13.08125840",
        longitude: "-0.52578230",
        type: null,
      },
      {
        id: 3142,
        name: "Nayala Province",
        state_code: "NAY",
        latitude: "12.69645580",
        longitude: "-3.01757120",
        type: null,
      },
      {
        id: 3164,
        name: "Nord Region, Burkina Faso",
        state_code: "10",
        latitude: "13.71825200",
        longitude: "-2.30244600",
        type: null,
      },
      {
        id: 3156,
        name: "Noumbiel Province",
        state_code: "NOU",
        latitude: "9.84409460",
        longitude: "-2.97755580",
        type: null,
      },
      {
        id: 3141,
        name: "Oubritenga Province",
        state_code: "OUB",
        latitude: "12.70960870",
        longitude: "-1.44346900",
        type: null,
      },
      {
        id: 3144,
        name: "Oudalan Province",
        state_code: "OUD",
        latitude: "14.47190200",
        longitude: "-0.45023680",
        type: null,
      },
      {
        id: 3117,
        name: "Passoré Province",
        state_code: "PAS",
        latitude: "12.88812210",
        longitude: "-2.22366670",
        type: null,
      },
      {
        id: 3125,
        name: "Plateau-Central Region",
        state_code: "11",
        latitude: "12.25376480",
        longitude: "-0.75328090",
        type: null,
      },
      {
        id: 3163,
        name: "Poni Province",
        state_code: "PON",
        latitude: "10.33259960",
        longitude: "-3.33889170",
        type: null,
      },
      {
        id: 3114,
        name: "Sahel Region",
        state_code: "12",
        latitude: "14.10008650",
        longitude: "-0.14949880",
        type: null,
      },
      {
        id: 3154,
        name: "Sanguié Province",
        state_code: "SNG",
        latitude: "12.15018610",
        longitude: "-2.69838680",
        type: null,
      },
      {
        id: 3126,
        name: "Sanmatenga Province",
        state_code: "SMT",
        latitude: "13.35653040",
        longitude: "-1.05861350",
        type: null,
      },
      {
        id: 3139,
        name: "Séno Province",
        state_code: "SEN",
        latitude: "14.00722340",
        longitude: "-0.07467670",
        type: null,
      },
      {
        id: 3119,
        name: "Sissili Province",
        state_code: "SIS",
        latitude: "11.24412190",
        longitude: "-2.22366670",
        type: null,
      },
      {
        id: 3166,
        name: "Soum Province",
        state_code: "SOM",
        latitude: "14.09628410",
        longitude: "-1.36621600",
        type: null,
      },
      {
        id: 3137,
        name: "Sourou Province",
        state_code: "SOR",
        latitude: "13.34180300",
        longitude: "-2.93757390",
        type: null,
      },
      {
        id: 3140,
        name: "Sud-Ouest Region",
        state_code: "13",
        latitude: "10.42314930",
        longitude: "-3.25836260",
        type: null,
      },
      {
        id: 3128,
        name: "Tapoa Province",
        state_code: "TAP",
        latitude: "12.24970720",
        longitude: "1.67606910",
        type: null,
      },
      {
        id: 3134,
        name: "Tuy Province",
        state_code: "TUI",
        latitude: "38.88868400",
        longitude: "-77.00471900",
        type: null,
      },
      {
        id: 3124,
        name: "Yagha Province",
        state_code: "YAG",
        latitude: "13.35761570",
        longitude: "0.75328090",
        type: null,
      },
      {
        id: 3150,
        name: "Yatenga Province",
        state_code: "YAT",
        latitude: "13.62493440",
        longitude: "-2.38136210",
        type: null,
      },
      {
        id: 3145,
        name: "Ziro Province",
        state_code: "ZIR",
        latitude: "11.60949950",
        longitude: "-1.90992380",
        type: null,
      },
      {
        id: 3130,
        name: "Zondoma Province",
        state_code: "ZON",
        latitude: "13.11659260",
        longitude: "-2.42087130",
        type: null,
      },
      {
        id: 3118,
        name: "Zoundwéogo Province",
        state_code: "ZOU",
        latitude: "11.61411740",
        longitude: "-0.98206680",
        type: null,
      },
    ],
  },
  {
    name: "Burundi",
    iso3: "BDI",
    iso2: "BI",
    numeric_code: "108",
    phone_code: "257",
    capital: "Bujumbura",
    currency: "BIF",
    currency_name: "Burundian franc",
    currency_symbol: "FBu",
    tld: ".bi",
    native: "Burundi",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Burundian",
    timezones: [
      {
        zoneName: "Africa/Bujumbura",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "부룬디",
      "pt-BR": "Burundi",
      pt: "Burúndi",
      nl: "Burundi",
      hr: "Burundi",
      fa: "بوروندی",
      de: "Burundi",
      es: "Burundi",
      fr: "Burundi",
      ja: "ブルンジ",
      it: "Burundi",
      cn: "布隆迪",
      tr: "Burundi",
    },
    latitude: "-3.50000000",
    longitude: "30.00000000",
    emoji: "🇧🇮",
    emojiU: "U+1F1E7 U+1F1EE",
    states: [
      {
        id: 3196,
        name: "Bubanza Province",
        state_code: "BB",
        latitude: "-3.15724030",
        longitude: "29.37149090",
        type: null,
      },
      {
        id: 3198,
        name: "Bujumbura Mairie Province",
        state_code: "BM",
        latitude: "-3.38841410",
        longitude: "29.34826460",
        type: null,
      },
      {
        id: 3200,
        name: "Bujumbura Rural Province",
        state_code: "BL",
        latitude: "-3.50901440",
        longitude: "29.46435900",
        type: null,
      },
      {
        id: 3202,
        name: "Bururi Province",
        state_code: "BR",
        latitude: "-3.90068510",
        longitude: "29.51077080",
        type: null,
      },
      {
        id: 3201,
        name: "Cankuzo Province",
        state_code: "CA",
        latitude: "-3.15277880",
        longitude: "30.61998950",
        type: null,
      },
      {
        id: 3190,
        name: "Cibitoke Province",
        state_code: "CI",
        latitude: "-2.81028970",
        longitude: "29.18557850",
        type: null,
      },
      {
        id: 3197,
        name: "Gitega Province",
        state_code: "GI",
        latitude: "-3.49290510",
        longitude: "29.92779470",
        type: null,
      },
      {
        id: 3194,
        name: "Karuzi Province",
        state_code: "KR",
        latitude: "-3.13403470",
        longitude: "30.11273500",
        type: null,
      },
      {
        id: 3192,
        name: "Kayanza Province",
        state_code: "KY",
        latitude: "-3.00779810",
        longitude: "29.64991620",
        type: null,
      },
      {
        id: 3195,
        name: "Kirundo Province",
        state_code: "KI",
        latitude: "-2.57628820",
        longitude: "30.11273500",
        type: null,
      },
      {
        id: 3188,
        name: "Makamba Province",
        state_code: "MA",
        latitude: "-4.32570620",
        longitude: "29.69626770",
        type: null,
      },
      {
        id: 3193,
        name: "Muramvya Province",
        state_code: "MU",
        latitude: "-3.28983980",
        longitude: "29.64991620",
        type: null,
      },
      {
        id: 3186,
        name: "Muyinga Province",
        state_code: "MY",
        latitude: "-2.77935110",
        longitude: "30.29741990",
        type: null,
      },
      {
        id: 3187,
        name: "Mwaro Province",
        state_code: "MW",
        latitude: "-3.50259180",
        longitude: "29.64991620",
        type: null,
      },
      {
        id: 3199,
        name: "Ngozi Province",
        state_code: "NG",
        latitude: "-2.89582430",
        longitude: "29.88152030",
        type: null,
      },
      {
        id: 3185,
        name: "Rumonge Province",
        state_code: "RM",
        latitude: "-3.97540490",
        longitude: "29.43880140",
        type: null,
      },
      {
        id: 3189,
        name: "Rutana Province",
        state_code: "RT",
        latitude: "-3.87915230",
        longitude: "30.06652360",
        type: null,
      },
      {
        id: 3191,
        name: "Ruyigi Province",
        state_code: "RY",
        latitude: "-3.44620700",
        longitude: "30.25127280",
        type: null,
      },
    ],
  },
  {
    name: "Cambodia",
    iso3: "KHM",
    iso2: "KH",
    numeric_code: "116",
    phone_code: "855",
    capital: "Phnom Penh",
    currency: "KHR",
    currency_name: "Cambodian riel",
    currency_symbol: "KHR",
    tld: ".kh",
    native: "Kâmpŭchéa",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Cambodian",
    timezones: [
      {
        zoneName: "Asia/Phnom_Penh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "캄보디아",
      "pt-BR": "Camboja",
      pt: "Camboja",
      nl: "Cambodja",
      hr: "Kambodža",
      fa: "کامبوج",
      de: "Kambodscha",
      es: "Camboya",
      fr: "Cambodge",
      ja: "カンボジア",
      it: "Cambogia",
      cn: "柬埔寨",
      tr: "Kamboçya",
    },
    latitude: "13.00000000",
    longitude: "105.00000000",
    emoji: "🇰🇭",
    emojiU: "U+1F1F0 U+1F1ED",
    states: [
      {
        id: 3984,
        name: "Banteay Meanchey",
        state_code: "1",
        latitude: "13.75319140",
        longitude: "102.98961500",
        type: "province",
      },
      {
        id: 3976,
        name: "Battambang",
        state_code: "2",
        latitude: "13.02869710",
        longitude: "102.98961500",
        type: "province",
      },
      {
        id: 3991,
        name: "Kampong Cham",
        state_code: "3",
        latitude: "12.09829180",
        longitude: "105.31311850",
        type: "province",
      },
      {
        id: 3979,
        name: "Kampong Chhnang",
        state_code: "4",
        latitude: "12.13923520",
        longitude: "104.56552730",
        type: "province",
      },
      {
        id: 3988,
        name: "Kampong Speu",
        state_code: "5",
        latitude: "11.61551090",
        longitude: "104.37919120",
        type: "province",
      },
      {
        id: 5070,
        name: "Kampong Thom",
        state_code: "6",
        latitude: "12.81674850",
        longitude: "103.84131040",
        type: "province",
      },
      {
        id: 3981,
        name: "Kampot",
        state_code: "7",
        latitude: "10.73253510",
        longitude: "104.37919120",
        type: "province",
      },
      {
        id: 3983,
        name: "Kandal",
        state_code: "8",
        latitude: "11.22373830",
        longitude: "105.12589550",
        type: "province",
      },
      {
        id: 3978,
        name: "Kep",
        state_code: "23",
        latitude: "10.53608900",
        longitude: "104.35591580",
        type: "province",
      },
      {
        id: 3982,
        name: "Koh Kong",
        state_code: "9",
        latitude: "11.57628040",
        longitude: "103.35872880",
        type: "province",
      },
      {
        id: 3986,
        name: "Kratie",
        state_code: "10",
        latitude: "12.50436080",
        longitude: "105.96998780",
        type: "province",
      },
      {
        id: 3985,
        name: "Mondulkiri",
        state_code: "11",
        latitude: "12.78794270",
        longitude: "107.10119310",
        type: "province",
      },
      {
        id: 3987,
        name: "Oddar Meanchey",
        state_code: "22",
        latitude: "14.16097380",
        longitude: "103.82162610",
        type: "province",
      },
      {
        id: 3980,
        name: "Pailin",
        state_code: "24",
        latitude: "12.90929620",
        longitude: "102.66755750",
        type: "province",
      },
      {
        id: 3994,
        name: "Phnom Penh",
        state_code: "12",
        latitude: "11.55637380",
        longitude: "104.92820990",
        type: "autonomous municipality",
      },
      {
        id: 3973,
        name: "Preah Vihear",
        state_code: "13",
        latitude: "14.00857970",
        longitude: "104.84546190",
        type: "province",
      },
      {
        id: 3974,
        name: "Prey Veng",
        state_code: "14",
        latitude: "11.38024420",
        longitude: "105.50054830",
        type: "province",
      },
      {
        id: 3977,
        name: "Pursat",
        state_code: "15",
        latitude: "12.27209560",
        longitude: "103.72891670",
        type: "province",
      },
      {
        id: 3990,
        name: "Ratanakiri",
        state_code: "16",
        latitude: "13.85766070",
        longitude: "107.10119310",
        type: "province",
      },
      {
        id: 3992,
        name: "Siem Reap",
        state_code: "17",
        latitude: "13.33026600",
        longitude: "104.10013260",
        type: "province",
      },
      {
        id: 3989,
        name: "Sihanoukville",
        state_code: "18",
        latitude: "10.75818990",
        longitude: "103.82162610",
        type: "province",
      },
      {
        id: 3993,
        name: "Stung Treng",
        state_code: "19",
        latitude: "13.57647300",
        longitude: "105.96998780",
        type: "province",
      },
      {
        id: 3972,
        name: "Svay Rieng",
        state_code: "20",
        latitude: "11.14272200",
        longitude: "105.82902980",
        type: "province",
      },
      {
        id: 3975,
        name: "Takeo",
        state_code: "21",
        latitude: "10.93215190",
        longitude: "104.79877100",
        type: "province",
      },
    ],
  },
  {
    name: "Cameroon",
    iso3: "CMR",
    iso2: "CM",
    numeric_code: "120",
    phone_code: "237",
    capital: "Yaounde",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cm",
    native: "Cameroon",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Cameroonian",
    timezones: [
      {
        zoneName: "Africa/Douala",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "카메룬",
      "pt-BR": "Camarões",
      pt: "Camarões",
      nl: "Kameroen",
      hr: "Kamerun",
      fa: "کامرون",
      de: "Kamerun",
      es: "Camerún",
      fr: "Cameroun",
      ja: "カメルーン",
      it: "Camerun",
      cn: "喀麦隆",
      tr: "Kamerun",
    },
    latitude: "6.00000000",
    longitude: "12.00000000",
    emoji: "🇨🇲",
    emojiU: "U+1F1E8 U+1F1F2",
    states: [
      {
        id: 2663,
        name: "Adamawa",
        state_code: "AD",
        latitude: "9.32647510",
        longitude: "12.39838530",
        type: null,
      },
      {
        id: 2660,
        name: "Centre",
        state_code: "CE",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2661,
        name: "East",
        state_code: "ES",
        latitude: "39.01853360",
        longitude: "-94.27924110",
        type: null,
      },
      {
        id: 2656,
        name: "Far North",
        state_code: "EN",
        latitude: "66.76134510",
        longitude: "124.12375300",
        type: null,
      },
      {
        id: 2662,
        name: "Littoral",
        state_code: "LT",
        latitude: "48.46227570",
        longitude: "-68.51780710",
        type: null,
      },
      {
        id: 2665,
        name: "North",
        state_code: "NO",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        type: null,
      },
      {
        id: 2657,
        name: "Northwest",
        state_code: "NW",
        latitude: "36.37118570",
        longitude: "-94.19346060",
        type: null,
      },
      {
        id: 2659,
        name: "South",
        state_code: "SU",
        latitude: "37.63159500",
        longitude: "-97.34584090",
        type: null,
      },
      {
        id: 2658,
        name: "Southwest",
        state_code: "SW",
        latitude: "36.19088130",
        longitude: "-95.88974480",
        type: null,
      },
      {
        id: 2664,
        name: "West",
        state_code: "OU",
        latitude: "37.03649890",
        longitude: "-95.67059870",
        type: null,
      },
    ],
  },
  {
    name: "Canada",
    iso3: "CAN",
    iso2: "CA",
    numeric_code: "124",
    phone_code: "1",
    capital: "Ottawa",
    currency: "CAD",
    currency_name: "Canadian dollar",
    currency_symbol: "$",
    tld: ".ca",
    native: "Canada",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Canadian",
    timezones: [
      {
        zoneName: "America/Atikokan",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)",
      },
      {
        zoneName: "America/Blanc-Sablon",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Cambridge_Bay",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Creston",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Dawson_Creek",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Edmonton",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Fort_Nelson",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America)",
      },
      {
        zoneName: "America/Glace_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Goose_Bay",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Halifax",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Inuvik",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Iqaluit",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Moncton",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
      {
        zoneName: "America/Nipigon",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Pangnirtung",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Rainy_River",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Rankin_Inlet",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Regina",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Resolute",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/St_Johns",
        gmtOffset: -12600,
        gmtOffsetName: "UTC-03:30",
        abbreviation: "NST",
        tzName: "Newfoundland Standard Time",
      },
      {
        zoneName: "America/Swift_Current",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Thunder_Bay",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Toronto",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Vancouver",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
      {
        zoneName: "America/Whitehorse",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Winnipeg",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Yellowknife",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
    ],
    translations: {
      kr: "캐나다",
      "pt-BR": "Canadá",
      pt: "Canadá",
      nl: "Canada",
      hr: "Kanada",
      fa: "کانادا",
      de: "Kanada",
      es: "Canadá",
      fr: "Canada",
      ja: "カナダ",
      it: "Canada",
      cn: "加拿大",
      tr: "Kanada",
    },
    latitude: "60.00000000",
    longitude: "-95.00000000",
    emoji: "🇨🇦",
    emojiU: "U+1F1E8 U+1F1E6",
    states: [
      {
        id: 872,
        name: "Alberta",
        state_code: "AB",
        latitude: "53.93327060",
        longitude: "-116.57650350",
        type: "province",
      },
      {
        id: 875,
        name: "British Columbia",
        state_code: "BC",
        latitude: "53.72666830",
        longitude: "-127.64762050",
        type: "province",
      },
      {
        id: 867,
        name: "Manitoba",
        state_code: "MB",
        latitude: "53.76086080",
        longitude: "-98.81387620",
        type: "province",
      },
      {
        id: 868,
        name: "New Brunswick",
        state_code: "NB",
        latitude: "46.56531630",
        longitude: "-66.46191640",
        type: "province",
      },
      {
        id: 877,
        name: "Newfoundland and Labrador",
        state_code: "NL",
        latitude: "53.13550910",
        longitude: "-57.66043640",
        type: "province",
      },
      {
        id: 878,
        name: "Northwest Territories",
        state_code: "NT",
        latitude: "64.82554410",
        longitude: "-124.84573340",
        type: "territory",
      },
      {
        id: 874,
        name: "Nova Scotia",
        state_code: "NS",
        latitude: "44.68198660",
        longitude: "-63.74431100",
        type: "province",
      },
      {
        id: 876,
        name: "Nunavut",
        state_code: "NU",
        latitude: "70.29977110",
        longitude: "-83.10757700",
        type: "territory",
      },
      {
        id: 866,
        name: "Ontario",
        state_code: "ON",
        latitude: "51.25377500",
        longitude: "-85.32321400",
        type: "province",
      },
      {
        id: 871,
        name: "Prince Edward Island",
        state_code: "PE",
        latitude: "46.51071200",
        longitude: "-63.41681360",
        type: "province",
      },
      {
        id: 873,
        name: "Quebec",
        state_code: "QC",
        latitude: "52.93991590",
        longitude: "-73.54913610",
        type: "province",
      },
      {
        id: 870,
        name: "Saskatchewan",
        state_code: "SK",
        latitude: "52.93991590",
        longitude: "-106.45086390",
        type: "province",
      },
      {
        id: 869,
        name: "Yukon",
        state_code: "YT",
        latitude: "35.50672150",
        longitude: "-97.76254410",
        type: "territory",
      },
    ],
  },
  {
    name: "Cape Verde",
    iso3: "CPV",
    iso2: "CV",
    numeric_code: "132",
    phone_code: "238",
    capital: "Praia",
    currency: "CVE",
    currency_name: "Cape Verdean escudo",
    currency_symbol: "$",
    tld: ".cv",
    native: "Cabo Verde",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Verdean",
    timezones: [
      {
        zoneName: "Atlantic/Cape_Verde",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "CVT",
        tzName: "Cape Verde Time",
      },
    ],
    translations: {
      kr: "카보베르데",
      "pt-BR": "Cabo Verde",
      pt: "Cabo Verde",
      nl: "Kaapverdië",
      hr: "Zelenortska Republika",
      fa: "کیپ ورد",
      de: "Kap Verde",
      es: "Cabo Verde",
      fr: "Cap Vert",
      ja: "カーボベルデ",
      it: "Capo Verde",
      cn: "佛得角",
      tr: "Cabo Verde",
    },
    latitude: "16.00000000",
    longitude: "-24.00000000",
    emoji: "🇨🇻",
    emojiU: "U+1F1E8 U+1F1FB",
    states: [
      {
        id: 2994,
        name: "Barlavento Islands",
        state_code: "B",
        latitude: "16.82368450",
        longitude: "-23.99348810",
        type: null,
      },
      {
        id: 2999,
        name: "Boa Vista",
        state_code: "BV",
        latitude: "38.74346600",
        longitude: "-120.73042970",
        type: null,
      },
      {
        id: 2996,
        name: "Brava",
        state_code: "BR",
        latitude: "40.98977780",
        longitude: "-73.68357150",
        type: null,
      },
      {
        id: 2991,
        name: "Maio Municipality",
        state_code: "MA",
        latitude: "15.20030980",
        longitude: "-23.16797930",
        type: null,
      },
      {
        id: 2987,
        name: "Mosteiros",
        state_code: "MO",
        latitude: "37.89043480",
        longitude: "-25.82075560",
        type: null,
      },
      {
        id: 2997,
        name: "Paul",
        state_code: "PA",
        latitude: "37.06250000",
        longitude: "-95.67706800",
        type: null,
      },
      {
        id: 2989,
        name: "Porto Novo",
        state_code: "PN",
        latitude: "6.49685740",
        longitude: "2.62885230",
        type: null,
      },
      {
        id: 2988,
        name: "Praia",
        state_code: "PR",
        latitude: "14.93305000",
        longitude: "-23.51332670",
        type: null,
      },
      {
        id: 2982,
        name: "Ribeira Brava Municipality",
        state_code: "RB",
        latitude: "16.60707390",
        longitude: "-24.20338430",
        type: null,
      },
      {
        id: 3002,
        name: "Ribeira Grande",
        state_code: "RG",
        latitude: "37.82103690",
        longitude: "-25.51481370",
        type: null,
      },
      {
        id: 2984,
        name: "Ribeira Grande de Santiago",
        state_code: "RS",
        latitude: "14.98302980",
        longitude: "-23.65617250",
        type: null,
      },
      {
        id: 2998,
        name: "Sal",
        state_code: "SL",
        latitude: "26.59581220",
        longitude: "-80.20450830",
        type: null,
      },
      {
        id: 2985,
        name: "Santa Catarina",
        state_code: "CA",
        latitude: "-27.24233920",
        longitude: "-50.21885560",
        type: null,
      },
      {
        id: 2995,
        name: "Santa Catarina do Fogo",
        state_code: "CF",
        latitude: "14.93091040",
        longitude: "-24.32225770",
        type: null,
      },
      {
        id: 3004,
        name: "Santa Cruz",
        state_code: "CR",
        latitude: "36.97411710",
        longitude: "-122.03079630",
        type: null,
      },
      {
        id: 2986,
        name: "São Domingos",
        state_code: "SD",
        latitude: "15.02861650",
        longitude: "-23.56392200",
        type: null,
      },
      {
        id: 3000,
        name: "São Filipe",
        state_code: "SF",
        latitude: "14.89516790",
        longitude: "-24.49456360",
        type: null,
      },
      {
        id: 2993,
        name: "São Lourenço dos Órgãos",
        state_code: "SO",
        latitude: "15.05378410",
        longitude: "-23.60856120",
        type: null,
      },
      {
        id: 2990,
        name: "São Miguel",
        state_code: "SM",
        latitude: "37.78041100",
        longitude: "-25.49704660",
        type: null,
      },
      {
        id: 3001,
        name: "São Vicente",
        state_code: "SV",
        latitude: "-23.96071570",
        longitude: "-46.39620220",
        type: null,
      },
      {
        id: 2992,
        name: "Sotavento Islands",
        state_code: "S",
        latitude: "15.00000000",
        longitude: "-24.00000000",
        type: null,
      },
      {
        id: 2983,
        name: "Tarrafal",
        state_code: "TA",
        latitude: "15.27605780",
        longitude: "-23.74840770",
        type: null,
      },
      {
        id: 3003,
        name: "Tarrafal de São Nicolau",
        state_code: "TS",
        latitude: "16.56364980",
        longitude: "-24.35494200",
        type: null,
      },
    ],
  },
  {
    name: "Cayman Islands",
    iso3: "CYM",
    iso2: "KY",
    numeric_code: "136",
    phone_code: "+1-345",
    capital: "George Town",
    currency: "KYD",
    currency_name: "Cayman Islands dollar",
    currency_symbol: "$",
    tld: ".ky",
    native: "Cayman Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Caymanian",
    timezones: [
      {
        zoneName: "America/Cayman",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "케이먼 제도",
      "pt-BR": "Ilhas Cayman",
      pt: "Ilhas Caimão",
      nl: "Caymaneilanden",
      hr: "Kajmanski otoci",
      fa: "جزایر کیمن",
      de: "Kaimaninseln",
      es: "Islas Caimán",
      fr: "Îles Caïmans",
      ja: "ケイマン諸島",
      it: "Isole Cayman",
      cn: "开曼群岛",
      tr: "Cayman Adalari",
    },
    latitude: "19.50000000",
    longitude: "-80.50000000",
    emoji: "🇰🇾",
    emojiU: "U+1F1F0 U+1F1FE",
    states: [],
  },
  {
    name: "Central African Republic",
    iso3: "CAF",
    iso2: "CF",
    numeric_code: "140",
    phone_code: "236",
    capital: "Bangui",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".cf",
    native: "Ködörösêse tî Bêafrîka",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Central African",
    timezones: [
      {
        zoneName: "Africa/Bangui",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "중앙아프리카 공화국",
      "pt-BR": "República Centro-Africana",
      pt: "República Centro-Africana",
      nl: "Centraal-Afrikaanse Republiek",
      hr: "Srednjoafrička Republika",
      fa: "جمهوری آفریقای مرکزی",
      de: "Zentralafrikanische Republik",
      es: "República Centroafricana",
      fr: "République centrafricaine",
      ja: "中央アフリカ共和国",
      it: "Repubblica Centrafricana",
      cn: "中非",
      tr: "Orta Afrika Cumhuriyeti",
    },
    latitude: "7.00000000",
    longitude: "21.00000000",
    emoji: "🇨🇫",
    emojiU: "U+1F1E8 U+1F1EB",
    states: [
      {
        id: 1259,
        name: "Bamingui-Bangoran Prefecture",
        state_code: "BB",
        latitude: "8.27334550",
        longitude: "20.71224650",
        type: null,
      },
      {
        id: 1262,
        name: "Bangui",
        state_code: "BGF",
        latitude: "4.39467350",
        longitude: "18.55818990",
        type: null,
      },
      {
        id: 1264,
        name: "Basse-Kotto Prefecture",
        state_code: "BK",
        latitude: "4.87193190",
        longitude: "21.28450250",
        type: null,
      },
      {
        id: 1258,
        name: "Haut-Mbomou Prefecture",
        state_code: "HM",
        latitude: "6.25371340",
        longitude: "25.47335540",
        type: null,
      },
      {
        id: 1268,
        name: "Haute-Kotto Prefecture",
        state_code: "HK",
        latitude: "7.79643790",
        longitude: "23.38235450",
        type: null,
      },
      {
        id: 1263,
        name: "Kémo Prefecture",
        state_code: "KG",
        latitude: "5.88677940",
        longitude: "19.37832060",
        type: null,
      },
      {
        id: 1256,
        name: "Lobaye Prefecture",
        state_code: "LB",
        latitude: "4.35259810",
        longitude: "17.47951730",
        type: null,
      },
      {
        id: 1257,
        name: "Mambéré-Kadéï",
        state_code: "HS",
        latitude: "4.70556530",
        longitude: "15.96998780",
        type: null,
      },
      {
        id: 1266,
        name: "Mbomou Prefecture",
        state_code: "MB",
        latitude: "5.55683700",
        longitude: "23.76328280",
        type: null,
      },
      {
        id: 1253,
        name: "Nana-Grébizi Economic Prefecture",
        state_code: "KB",
        latitude: "7.18486070",
        longitude: "19.37832060",
        type: null,
      },
      {
        id: 1260,
        name: "Nana-Mambéré Prefecture",
        state_code: "NM",
        latitude: "5.69321350",
        longitude: "15.21948080",
        type: null,
      },
      {
        id: 1255,
        name: "Ombella-M'Poko Prefecture",
        state_code: "MP",
        latitude: "5.11888250",
        longitude: "18.42760470",
        type: null,
      },
      {
        id: 1265,
        name: "Ouaka Prefecture",
        state_code: "UK",
        latitude: "6.31682160",
        longitude: "20.71224650",
        type: null,
      },
      {
        id: 1254,
        name: "Ouham Prefecture",
        state_code: "AC",
        latitude: "7.09091100",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 1267,
        name: "Ouham-Pendé Prefecture",
        state_code: "OP",
        latitude: "6.48509840",
        longitude: "16.15809370",
        type: null,
      },
      {
        id: 1252,
        name: "Sangha-Mbaéré",
        state_code: "SE",
        latitude: "3.43686070",
        longitude: "16.34637910",
        type: null,
      },
      {
        id: 1261,
        name: "Vakaga Prefecture",
        state_code: "VK",
        latitude: "9.51132960",
        longitude: "22.23840170",
        type: null,
      },
    ],
  },
  {
    name: "Chad",
    iso3: "TCD",
    iso2: "TD",
    numeric_code: "148",
    phone_code: "235",
    capital: "N'Djamena",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".td",
    native: "Tchad",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Chadian",
    timezones: [
      {
        zoneName: "Africa/Ndjamena",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "차드",
      "pt-BR": "Chade",
      pt: "Chade",
      nl: "Tsjaad",
      hr: "Čad",
      fa: "چاد",
      de: "Tschad",
      es: "Chad",
      fr: "Tchad",
      ja: "チャド",
      it: "Ciad",
      cn: "乍得",
      tr: "Çad",
    },
    latitude: "15.00000000",
    longitude: "19.00000000",
    emoji: "🇹🇩",
    emojiU: "U+1F1F9 U+1F1E9",
    states: [
      {
        id: 3583,
        name: "Bahr el Gazel",
        state_code: "BG",
        latitude: "14.77022660",
        longitude: "16.91225100",
        type: "province",
      },
      {
        id: 3590,
        name: "Batha",
        state_code: "BA",
        latitude: "13.93717750",
        longitude: "18.42760470",
        type: "province",
      },
      {
        id: 3574,
        name: "Borkou",
        state_code: "BO",
        latitude: "17.86888450",
        longitude: "18.80761950",
        type: "province",
      },
      {
        id: 5114,
        name: "Chari-Baguirmi",
        state_code: "CB",
        latitude: "11.46186260",
        longitude: "15.24463940",
        type: "province",
      },
      {
        id: 3575,
        name: "Ennedi-Est",
        state_code: "EE",
        latitude: "16.34204960",
        longitude: "23.00119890",
        type: "province",
      },
      {
        id: 3584,
        name: "Ennedi-Ouest",
        state_code: "EO",
        latitude: "18.97756300",
        longitude: "21.85685860",
        type: "province",
      },
      {
        id: 3576,
        name: "Guéra",
        state_code: "GR",
        latitude: "11.12190150",
        longitude: "18.42760470",
        type: "province",
      },
      {
        id: 3573,
        name: "Hadjer-Lamis",
        state_code: "HL",
        latitude: "12.45772730",
        longitude: "16.72346390",
        type: "province",
      },
      {
        id: 3588,
        name: "Kanem",
        state_code: "KA",
        latitude: "14.87812620",
        longitude: "15.40680790",
        type: "province",
      },
      {
        id: 3577,
        name: "Lac",
        state_code: "LC",
        latitude: "13.69153770",
        longitude: "14.10013260",
        type: "province",
      },
      {
        id: 3585,
        name: "Logone Occidental",
        state_code: "LO",
        latitude: "8.75967600",
        longitude: "15.87600400",
        type: "province",
      },
      {
        id: 3591,
        name: "Logone Oriental",
        state_code: "LR",
        latitude: "8.31499490",
        longitude: "16.34637910",
        type: "province",
      },
      {
        id: 3589,
        name: "Mandoul",
        state_code: "MA",
        latitude: "8.60309100",
        longitude: "17.47951730",
        type: "province",
      },
      {
        id: 3580,
        name: "Mayo-Kebbi Est",
        state_code: "ME",
        latitude: "9.40460390",
        longitude: "14.84546190",
        type: "province",
      },
      {
        id: 3571,
        name: "Mayo-Kebbi Ouest",
        state_code: "MO",
        latitude: "10.41130140",
        longitude: "15.59433880",
        type: "province",
      },
      {
        id: 3570,
        name: "Moyen-Chari",
        state_code: "MC",
        latitude: "9.06399980",
        longitude: "18.42760470",
        type: "province",
      },
      {
        id: 3586,
        name: "N'Djamena",
        state_code: "ND",
        latitude: "12.13484570",
        longitude: "15.05574150",
        type: "province",
      },
      {
        id: 3582,
        name: "Ouaddaï",
        state_code: "OD",
        latitude: "13.74847600",
        longitude: "20.71224650",
        type: "province",
      },
      {
        id: 3592,
        name: "Salamat",
        state_code: "SA",
        latitude: "10.96916010",
        longitude: "20.71224650",
        type: "province",
      },
      {
        id: 3572,
        name: "Sila",
        state_code: "SI",
        latitude: "12.13074000",
        longitude: "21.28450250",
        type: "province",
      },
      {
        id: 3579,
        name: "Tandjilé",
        state_code: "TA",
        latitude: "9.66257290",
        longitude: "16.72346390",
        type: "province",
      },
      {
        id: 3587,
        name: "Tibesti",
        state_code: "TI",
        latitude: "21.36500310",
        longitude: "16.91225100",
        type: "province",
      },
      {
        id: 3581,
        name: "Wadi Fira",
        state_code: "WF",
        latitude: "15.08924160",
        longitude: "21.47528510",
        type: "province",
      },
    ],
  },
  {
    name: "Chile",
    iso3: "CHL",
    iso2: "CL",
    numeric_code: "152",
    phone_code: "56",
    capital: "Santiago",
    currency: "CLP",
    currency_name: "Chilean peso",
    currency_symbol: "$",
    tld: ".cl",
    native: "Chile",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Chilean",
    timezones: [
      {
        zoneName: "America/Punta_Arenas",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "America/Santiago",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "CLST",
        tzName: "Chile Summer Time",
      },
      {
        zoneName: "Pacific/Easter",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EASST",
        tzName: "Easter Island Summer Time",
      },
    ],
    translations: {
      kr: "칠리",
      "pt-BR": "Chile",
      pt: "Chile",
      nl: "Chili",
      hr: "Čile",
      fa: "شیلی",
      de: "Chile",
      es: "Chile",
      fr: "Chili",
      ja: "チリ",
      it: "Cile",
      cn: "智利",
      tr: "Şili",
    },
    latitude: "-30.00000000",
    longitude: "-71.00000000",
    emoji: "🇨🇱",
    emojiU: "U+1F1E8 U+1F1F1",
    states: [
      {
        id: 2828,
        name: "Aisén del General Carlos Ibañez del Campo",
        state_code: "AI",
        latitude: "-46.37834500",
        longitude: "-72.30076230",
        type: null,
      },
      {
        id: 2832,
        name: "Antofagasta",
        state_code: "AN",
        latitude: "-23.83691040",
        longitude: "-69.28775350",
        type: null,
      },
      {
        id: 2829,
        name: "Arica y Parinacota",
        state_code: "AP",
        latitude: "-18.59404850",
        longitude: "-69.47845410",
        type: null,
      },
      {
        id: 2823,
        name: "Atacama",
        state_code: "AT",
        latitude: "-27.56605580",
        longitude: "-70.05031400",
        type: null,
      },
      {
        id: 2827,
        name: "Biobío",
        state_code: "BI",
        latitude: "-37.44644280",
        longitude: "-72.14161320",
        type: null,
      },
      {
        id: 2825,
        name: "Coquimbo",
        state_code: "CO",
        latitude: "-30.54018100",
        longitude: "-70.81199530",
        type: null,
      },
      {
        id: 2826,
        name: "La Araucanía",
        state_code: "AR",
        latitude: "-38.94892100",
        longitude: "-72.33111300",
        type: null,
      },
      {
        id: 2838,
        name: "Libertador General Bernardo O'Higgins",
        state_code: "LI",
        latitude: "-34.57553740",
        longitude: "-71.00223110",
        type: null,
      },
      {
        id: 2835,
        name: "Los Lagos",
        state_code: "LL",
        latitude: "-41.91977790",
        longitude: "-72.14161320",
        type: null,
      },
      {
        id: 2834,
        name: "Los Ríos",
        state_code: "LR",
        latitude: "-40.23102170",
        longitude: "-72.33111300",
        type: null,
      },
      {
        id: 2836,
        name: "Magallanes y de la Antártica Chilena",
        state_code: "MA",
        latitude: "-52.20643160",
        longitude: "-72.16850010",
        type: null,
      },
      {
        id: 2833,
        name: "Maule",
        state_code: "ML",
        latitude: "-35.51636030",
        longitude: "-71.57239530",
        type: null,
      },
      {
        id: 2831,
        name: "Ñuble",
        state_code: "NB",
        latitude: "-36.72257430",
        longitude: "-71.76224810",
        type: null,
      },
      {
        id: 2824,
        name: "Región Metropolitana de Santiago",
        state_code: "RM",
        latitude: "-33.43755450",
        longitude: "-70.65048960",
        type: null,
      },
      {
        id: 2837,
        name: "Tarapacá",
        state_code: "TA",
        latitude: "-20.20287990",
        longitude: "-69.28775350",
        type: null,
      },
      {
        id: 2830,
        name: "Valparaíso",
        state_code: "VS",
        latitude: "-33.04723800",
        longitude: "-71.61268850",
        type: null,
      },
    ],
  },
  {
    name: "China",
    iso3: "CHN",
    iso2: "CN",
    numeric_code: "156",
    phone_code: "86",
    capital: "Beijing",
    currency: "CNY",
    currency_name: "Chinese yuan",
    currency_symbol: "¥",
    tld: ".cn",
    native: "中国",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Chinese",
    timezones: [
      {
        zoneName: "Asia/Shanghai",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
      {
        zoneName: "Asia/Urumqi",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "XJT",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "중국",
      "pt-BR": "China",
      pt: "China",
      nl: "China",
      hr: "Kina",
      fa: "چین",
      de: "China",
      es: "China",
      fr: "Chine",
      ja: "中国",
      it: "Cina",
      cn: "中国",
      tr: "Çin",
    },
    latitude: "35.00000000",
    longitude: "105.00000000",
    emoji: "🇨🇳",
    emojiU: "U+1F1E8 U+1F1F3",
    states: [
      {
        id: 2251,
        name: "Anhui",
        state_code: "AH",
        latitude: "30.60067730",
        longitude: "117.92490020",
        type: "province",
      },
      {
        id: 2257,
        name: "Beijing",
        state_code: "BJ",
        latitude: "39.90419990",
        longitude: "116.40739630",
        type: "municipality",
      },
      {
        id: 2271,
        name: "Chongqing",
        state_code: "CQ",
        latitude: "29.43158610",
        longitude: "106.91225100",
        type: "municipality",
      },
      {
        id: 2248,
        name: "Fujian",
        state_code: "FJ",
        latitude: "26.48368420",
        longitude: "117.92490020",
        type: "province",
      },
      {
        id: 2275,
        name: "Gansu",
        state_code: "GS",
        latitude: "35.75183260",
        longitude: "104.28611160",
        type: "province",
      },
      {
        id: 2279,
        name: "Guangdong",
        state_code: "GD",
        latitude: "23.37903330",
        longitude: "113.76328280",
        type: "province",
      },
      {
        id: 2278,
        name: "Guangxi Zhuang",
        state_code: "GX",
        latitude: "23.72475990",
        longitude: "108.80761950",
        type: "autonomous region",
      },
      {
        id: 2261,
        name: "Guizhou",
        state_code: "GZ",
        latitude: "26.84296450",
        longitude: "107.29028390",
        type: "province",
      },
      {
        id: 2273,
        name: "Hainan",
        state_code: "HI",
        latitude: "19.56639470",
        longitude: "109.94968600",
        type: "province",
      },
      {
        id: 2280,
        name: "Hebei",
        state_code: "HE",
        latitude: "37.89565940",
        longitude: "114.90422080",
        type: "province",
      },
      {
        id: 2265,
        name: "Heilongjiang",
        state_code: "HL",
        latitude: "47.12164720",
        longitude: "128.73823100",
        type: "province",
      },
      {
        id: 2259,
        name: "Henan",
        state_code: "HA",
        latitude: "34.29043020",
        longitude: "113.38235450",
        type: "province",
      },
      {
        id: 2267,
        name: "Hong Kong SAR",
        state_code: "HK",
        latitude: "22.31930390",
        longitude: "114.16936110",
        type: "special administrative region",
      },
      {
        id: 2274,
        name: "Hubei",
        state_code: "HB",
        latitude: "30.73781180",
        longitude: "112.23840170",
        type: "province",
      },
      {
        id: 2258,
        name: "Hunan",
        state_code: "HN",
        latitude: "27.36830090",
        longitude: "109.28193470",
        type: "province",
      },
      {
        id: 2269,
        name: "Inner Mongolia",
        state_code: "NM",
        latitude: "43.37822000",
        longitude: "115.05948150",
        type: "autonomous region",
      },
      {
        id: 2250,
        name: "Jiangsu",
        state_code: "JS",
        latitude: "33.14017150",
        longitude: "119.78892480",
        type: "province",
      },
      {
        id: 2256,
        name: "Jiangxi",
        state_code: "JX",
        latitude: "27.08745640",
        longitude: "114.90422080",
        type: "province",
      },
      {
        id: 2253,
        name: "Jilin",
        state_code: "JL",
        latitude: "43.83788300",
        longitude: "126.54957200",
        type: "province",
      },
      {
        id: 2268,
        name: "Liaoning",
        state_code: "LN",
        latitude: "41.94365430",
        longitude: "122.52903760",
        type: "province",
      },
      {
        id: 2266,
        name: "Macau SAR",
        state_code: "MO",
        latitude: "22.19874500",
        longitude: "113.54387300",
        type: "special administrative region",
      },
      {
        id: 2262,
        name: "Ningxia Huizu",
        state_code: "NX",
        latitude: "37.19873100",
        longitude: "106.15809370",
        type: "autonomous region",
      },
      {
        id: 2270,
        name: "Qinghai",
        state_code: "QH",
        latitude: "35.74479800",
        longitude: "96.40773580",
        type: "province",
      },
      {
        id: 2272,
        name: "Shaanxi",
        state_code: "SN",
        latitude: "35.39399080",
        longitude: "109.18800470",
        type: "province",
      },
      {
        id: 2252,
        name: "Shandong",
        state_code: "SD",
        latitude: "37.80060640",
        longitude: "-122.26999180",
        type: "province",
      },
      {
        id: 2249,
        name: "Shanghai",
        state_code: "SH",
        latitude: "31.23041600",
        longitude: "121.47370100",
        type: "municipality",
      },
      {
        id: 2254,
        name: "Shanxi",
        state_code: "SX",
        latitude: "37.24256490",
        longitude: "111.85685860",
        type: "province",
      },
      {
        id: 2277,
        name: "Sichuan",
        state_code: "SC",
        latitude: "30.26380320",
        longitude: "102.80547530",
        type: "province",
      },
      {
        id: 2255,
        name: "Taiwan",
        state_code: "TW",
        latitude: "23.69781000",
        longitude: "120.96051500",
        type: "province",
      },
      {
        id: 2276,
        name: "Tianjin",
        state_code: "TJ",
        latitude: "39.12522910",
        longitude: "117.01534350",
        type: "municipality",
      },
      {
        id: 2263,
        name: "Xinjiang",
        state_code: "XJ",
        latitude: "42.52463570",
        longitude: "87.53958550",
        type: "autonomous region",
      },
      {
        id: 2264,
        name: "Xizang",
        state_code: "XZ",
        latitude: "30.15336050",
        longitude: "88.78786780",
        type: "autonomous region",
      },
      {
        id: 2260,
        name: "Yunnan",
        state_code: "YN",
        latitude: "24.47528470",
        longitude: "101.34310580",
        type: "province",
      },
      {
        id: 2247,
        name: "Zhejiang",
        state_code: "ZJ",
        latitude: "29.14164320",
        longitude: "119.78892480",
        type: "province",
      },
    ],
  },
  {
    name: "Christmas Island",
    iso3: "CXR",
    iso2: "CX",
    numeric_code: "162",
    phone_code: "61",
    capital: "Flying Fish Cove",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cx",
    native: "Christmas Island",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Christmas Island",
    timezones: [
      {
        zoneName: "Indian/Christmas",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "CXT",
        tzName: "Christmas Island Time",
      },
    ],
    translations: {
      kr: "크리스마스 섬",
      "pt-BR": "Ilha Christmas",
      pt: "Ilha do Natal",
      nl: "Christmaseiland",
      hr: "Božićni otok",
      fa: "جزیره کریسمس",
      de: "Weihnachtsinsel",
      es: "Isla de Navidad",
      fr: "Île Christmas",
      ja: "クリスマス島",
      it: "Isola di Natale",
      cn: "圣诞岛",
      tr: "Christmas Adasi",
    },
    latitude: "-10.50000000",
    longitude: "105.66666666",
    emoji: "🇨🇽",
    emojiU: "U+1F1E8 U+1F1FD",
    states: [],
  },
  {
    name: "Cocos (Keeling) Islands",
    iso3: "CCK",
    iso2: "CC",
    numeric_code: "166",
    phone_code: "61",
    capital: "West Island",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".cc",
    native: "Cocos (Keeling) Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Cocos Island",
    timezones: [
      {
        zoneName: "Indian/Cocos",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "CCT",
        tzName: "Cocos Islands Time",
      },
    ],
    translations: {
      kr: "코코스 제도",
      "pt-BR": "Ilhas Cocos",
      pt: "Ilhas dos Cocos",
      nl: "Cocoseilanden",
      hr: "Kokosovi Otoci",
      fa: "جزایر کوکوس",
      de: "Kokosinseln",
      es: "Islas Cocos o Islas Keeling",
      fr: "Îles Cocos",
      ja: "ココス（キーリング）諸島",
      it: "Isole Cocos e Keeling",
      cn: "科科斯（基林）群岛",
      tr: "Cocos Adalari",
    },
    latitude: "-12.50000000",
    longitude: "96.83333333",
    emoji: "🇨🇨",
    emojiU: "U+1F1E8 U+1F1E8",
    states: [],
  },
  {
    name: "Colombia",
    iso3: "COL",
    iso2: "CO",
    numeric_code: "170",
    phone_code: "57",
    capital: "Bogotá",
    currency: "COP",
    currency_name: "Colombian peso",
    currency_symbol: "$",
    tld: ".co",
    native: "Colombia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Colombian",
    timezones: [
      {
        zoneName: "America/Bogota",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "COT",
        tzName: "Colombia Time",
      },
    ],
    translations: {
      kr: "콜롬비아",
      "pt-BR": "Colômbia",
      pt: "Colômbia",
      nl: "Colombia",
      hr: "Kolumbija",
      fa: "کلمبیا",
      de: "Kolumbien",
      es: "Colombia",
      fr: "Colombie",
      ja: "コロンビア",
      it: "Colombia",
      cn: "哥伦比亚",
      tr: "Kolombiya",
    },
    latitude: "4.00000000",
    longitude: "-72.00000000",
    emoji: "🇨🇴",
    emojiU: "U+1F1E8 U+1F1F4",
    states: [
      {
        id: 2895,
        name: "Amazonas",
        state_code: "AMA",
        latitude: "-1.44291230",
        longitude: "-71.57239530",
        type: null,
      },
      {
        id: 2890,
        name: "Antioquia",
        state_code: "ANT",
        latitude: "7.19860640",
        longitude: "-75.34121790",
        type: null,
      },
      {
        id: 2881,
        name: "Arauca",
        state_code: "ARA",
        latitude: "6.54730600",
        longitude: "-71.00223110",
        type: null,
      },
      {
        id: 2900,
        name: "Archipiélago de San Andrés, Providencia y Santa Catalina",
        state_code: "SAP",
        latitude: "12.55673240",
        longitude: "-81.71852530",
        type: null,
      },
      {
        id: 2880,
        name: "Atlántico",
        state_code: "ATL",
        latitude: "10.69661590",
        longitude: "-74.87410450",
        type: null,
      },
      {
        id: 4921,
        name: "Bogotá D.C.",
        state_code: "DC",
        latitude: "4.28204150",
        longitude: "-74.50270420",
        type: "capital district",
      },
      {
        id: 2893,
        name: "Bolívar",
        state_code: "BOL",
        latitude: "8.67043820",
        longitude: "-74.03001220",
        type: null,
      },
      {
        id: 2903,
        name: "Boyacá",
        state_code: "BOY",
        latitude: "5.45451100",
        longitude: "-73.36200300",
        type: null,
      },
      {
        id: 2887,
        name: "Caldas",
        state_code: "CAL",
        latitude: "5.29826000",
        longitude: "-75.24790610",
        type: null,
      },
      {
        id: 2891,
        name: "Caquetá",
        state_code: "CAQ",
        latitude: "0.86989200",
        longitude: "-73.84190630",
        type: null,
      },
      {
        id: 2892,
        name: "Casanare",
        state_code: "CAS",
        latitude: "5.75892690",
        longitude: "-71.57239530",
        type: null,
      },
      {
        id: 2884,
        name: "Cauca",
        state_code: "CAU",
        latitude: "2.70498130",
        longitude: "-76.82596520",
        type: null,
      },
      {
        id: 2899,
        name: "Cesar",
        state_code: "CES",
        latitude: "9.33729480",
        longitude: "-73.65362090",
        type: null,
      },
      {
        id: 2876,
        name: "Chocó",
        state_code: "CHO",
        latitude: "5.25280330",
        longitude: "-76.82596520",
        type: null,
      },
      {
        id: 2898,
        name: "Córdoba",
        state_code: "COR",
        latitude: "8.04929300",
        longitude: "-75.57405000",
        type: null,
      },
      {
        id: 2875,
        name: "Cundinamarca",
        state_code: "CUN",
        latitude: "5.02600300",
        longitude: "-74.03001220",
        type: null,
      },
      {
        id: 2882,
        name: "Guainía",
        state_code: "GUA",
        latitude: "2.58539300",
        longitude: "-68.52471490",
        type: null,
      },
      {
        id: 2888,
        name: "Guaviare",
        state_code: "GUV",
        latitude: "2.04392400",
        longitude: "-72.33111300",
        type: null,
      },
      {
        id: 4871,
        name: "Huila",
        state_code: "HUI",
        latitude: "2.53593490",
        longitude: "-75.52766990",
        type: "department",
      },
      {
        id: 2889,
        name: "La Guajira",
        state_code: "LAG",
        latitude: "11.35477430",
        longitude: "-72.52048270",
        type: null,
      },
      {
        id: 2886,
        name: "Magdalena",
        state_code: "MAG",
        latitude: "10.41130140",
        longitude: "-74.40566120",
        type: null,
      },
      {
        id: 2878,
        name: "Meta",
        state_code: "MET",
        latitude: "39.76732580",
        longitude: "-104.97535950",
        type: null,
      },
      {
        id: 2897,
        name: "Nariño",
        state_code: "NAR",
        latitude: "1.28915100",
        longitude: "-77.35794000",
        type: null,
      },
      {
        id: 2877,
        name: "Norte de Santander",
        state_code: "NSA",
        latitude: "7.94628310",
        longitude: "-72.89880690",
        type: null,
      },
      {
        id: 2896,
        name: "Putumayo",
        state_code: "PUT",
        latitude: "0.43595060",
        longitude: "-75.52766990",
        type: null,
      },
      {
        id: 2874,
        name: "Quindío",
        state_code: "QUI",
        latitude: "4.46101910",
        longitude: "-75.66735600",
        type: null,
      },
      {
        id: 2879,
        name: "Risaralda",
        state_code: "RIS",
        latitude: "5.31584750",
        longitude: "-75.99276520",
        type: null,
      },
      {
        id: 2901,
        name: "Santander",
        state_code: "SAN",
        latitude: "6.64370760",
        longitude: "-73.65362090",
        type: null,
      },
      {
        id: 2902,
        name: "Sucre",
        state_code: "SUC",
        latitude: "8.81397700",
        longitude: "-74.72328300",
        type: null,
      },
      {
        id: 2883,
        name: "Tolima",
        state_code: "TOL",
        latitude: "4.09251680",
        longitude: "-75.15453810",
        type: null,
      },
      {
        id: 2904,
        name: "Valle del Cauca",
        state_code: "VAC",
        latitude: "3.80088930",
        longitude: "-76.64127120",
        type: null,
      },
      {
        id: 2885,
        name: "Vaupés",
        state_code: "VAU",
        latitude: "0.85535610",
        longitude: "-70.81199530",
        type: null,
      },
      {
        id: 2894,
        name: "Vichada",
        state_code: "VID",
        latitude: "4.42344520",
        longitude: "-69.28775350",
        type: null,
      },
    ],
  },
  {
    name: "Comoros",
    iso3: "COM",
    iso2: "KM",
    numeric_code: "174",
    phone_code: "269",
    capital: "Moroni",
    currency: "KMF",
    currency_name: "Comorian franc",
    currency_symbol: "CF",
    tld: ".km",
    native: "Komori",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Comoran, Comorian",
    timezones: [
      {
        zoneName: "Indian/Comoro",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "코모로",
      "pt-BR": "Comores",
      pt: "Comores",
      nl: "Comoren",
      hr: "Komori",
      fa: "کومور",
      de: "Union der Komoren",
      es: "Comoras",
      fr: "Comores",
      ja: "コモロ",
      it: "Comore",
      cn: "科摩罗",
      tr: "Komorlar",
    },
    latitude: "-12.16666666",
    longitude: "44.25000000",
    emoji: "🇰🇲",
    emojiU: "U+1F1F0 U+1F1F2",
    states: [
      {
        id: 2821,
        name: "Anjouan",
        state_code: "A",
        latitude: "-12.21381450",
        longitude: "44.43706060",
        type: null,
      },
      {
        id: 2822,
        name: "Grande Comore",
        state_code: "G",
        latitude: "-11.71673380",
        longitude: "43.36807880",
        type: null,
      },
      {
        id: 2820,
        name: "Mohéli",
        state_code: "M",
        latitude: "-12.33773760",
        longitude: "43.73340890",
        type: null,
      },
    ],
  },
  {
    name: "Congo",
    iso3: "COG",
    iso2: "CG",
    numeric_code: "178",
    phone_code: "242",
    capital: "Brazzaville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FC",
    tld: ".cg",
    native: "République du Congo",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Congolese",
    timezones: [
      {
        zoneName: "Africa/Brazzaville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "콩고",
      "pt-BR": "Congo",
      pt: "Congo",
      nl: "Congo [Republiek]",
      hr: "Kongo",
      fa: "کنگو",
      de: "Kongo",
      es: "Congo",
      fr: "Congo",
      ja: "コンゴ共和国",
      it: "Congo",
      cn: "刚果",
      tr: "Kongo",
    },
    latitude: "-1.00000000",
    longitude: "15.00000000",
    emoji: "🇨🇬",
    emojiU: "U+1F1E8 U+1F1EC",
    states: [
      {
        id: 2866,
        name: "Bouenza Department",
        state_code: "11",
        latitude: "-4.11280790",
        longitude: "13.72891670",
        type: null,
      },
      {
        id: 2870,
        name: "Brazzaville",
        state_code: "BZV",
        latitude: "-4.26335970",
        longitude: "15.24288530",
        type: null,
      },
      {
        id: 2864,
        name: "Cuvette Department",
        state_code: "8",
        latitude: "-0.28774460",
        longitude: "16.15809370",
        type: null,
      },
      {
        id: 2869,
        name: "Cuvette-Ouest Department",
        state_code: "15",
        latitude: "0.14475500",
        longitude: "14.47233010",
        type: null,
      },
      {
        id: 2867,
        name: "Kouilou Department",
        state_code: "5",
        latitude: "-4.14284130",
        longitude: "11.88917210",
        type: null,
      },
      {
        id: 2868,
        name: "Lékoumou Department",
        state_code: "2",
        latitude: "-3.17038200",
        longitude: "13.35872880",
        type: null,
      },
      {
        id: 2865,
        name: "Likouala Department",
        state_code: "7",
        latitude: "2.04392400",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 2872,
        name: "Niari Department",
        state_code: "9",
        latitude: "-3.18427000",
        longitude: "12.25479190",
        type: null,
      },
      {
        id: 2862,
        name: "Plateaux Department",
        state_code: "14",
        latitude: "-2.06800880",
        longitude: "15.40680790",
        type: null,
      },
      {
        id: 2863,
        name: "Pointe-Noire",
        state_code: "16",
        latitude: "-4.76916230",
        longitude: "11.86636200",
        type: null,
      },
      {
        id: 2873,
        name: "Pool Department",
        state_code: "12",
        latitude: "-3.77626280",
        longitude: "14.84546190",
        type: null,
      },
      {
        id: 2871,
        name: "Sangha Department",
        state_code: "13",
        latitude: "1.46623280",
        longitude: "15.40680790",
        type: null,
      },
    ],
  },
  {
    name: "Cook Islands",
    iso3: "COK",
    iso2: "CK",
    numeric_code: "184",
    phone_code: "682",
    capital: "Avarua",
    currency: "NZD",
    currency_name: "Cook Islands dollar",
    currency_symbol: "$",
    tld: ".ck",
    native: "Cook Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Cook Island",
    timezones: [
      {
        zoneName: "Pacific/Rarotonga",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "CKT",
        tzName: "Cook Island Time",
      },
    ],
    translations: {
      kr: "쿡 제도",
      "pt-BR": "Ilhas Cook",
      pt: "Ilhas Cook",
      nl: "Cookeilanden",
      hr: "Cookovo Otočje",
      fa: "جزایر کوک",
      de: "Cookinseln",
      es: "Islas Cook",
      fr: "Îles Cook",
      ja: "クック諸島",
      it: "Isole Cook",
      cn: "库克群岛",
      tr: "Cook Adalari",
    },
    latitude: "-21.23333333",
    longitude: "-159.76666666",
    emoji: "🇨🇰",
    emojiU: "U+1F1E8 U+1F1F0",
    states: [],
  },
  {
    name: "Costa Rica",
    iso3: "CRI",
    iso2: "CR",
    numeric_code: "188",
    phone_code: "506",
    capital: "San Jose",
    currency: "CRC",
    currency_name: "Costa Rican colón",
    currency_symbol: "₡",
    tld: ".cr",
    native: "Costa Rica",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Costa Rican",
    timezones: [
      {
        zoneName: "America/Costa_Rica",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "코스타리카",
      "pt-BR": "Costa Rica",
      pt: "Costa Rica",
      nl: "Costa Rica",
      hr: "Kostarika",
      fa: "کاستاریکا",
      de: "Costa Rica",
      es: "Costa Rica",
      fr: "Costa Rica",
      ja: "コスタリカ",
      it: "Costa Rica",
      cn: "哥斯达黎加",
      tr: "Kosta Rika",
    },
    latitude: "10.00000000",
    longitude: "-84.00000000",
    emoji: "🇨🇷",
    emojiU: "U+1F1E8 U+1F1F7",
    states: [
      {
        id: 1215,
        name: "Alajuela Province",
        state_code: "A",
        latitude: "10.39158300",
        longitude: "-84.43827210",
        type: null,
      },
      {
        id: 1209,
        name: "Guanacaste Province",
        state_code: "G",
        latitude: "10.62673990",
        longitude: "-85.44367060",
        type: null,
      },
      {
        id: 1212,
        name: "Heredia Province",
        state_code: "H",
        latitude: "10.47352300",
        longitude: "-84.01674230",
        type: null,
      },
      {
        id: 1213,
        name: "Limón Province",
        state_code: "L",
        latitude: "9.98963980",
        longitude: "-83.03324170",
        type: null,
      },
      {
        id: 1211,
        name: "Provincia de Cartago",
        state_code: "C",
        latitude: "9.86223110",
        longitude: "-83.92141870",
        type: null,
      },
      {
        id: 1210,
        name: "Puntarenas Province",
        state_code: "P",
        latitude: "9.21695310",
        longitude: "-83.33618800",
        type: null,
      },
      {
        id: 1214,
        name: "San José Province",
        state_code: "SJ",
        latitude: "9.91297270",
        longitude: "-84.07682940",
        type: null,
      },
    ],
  },
  {
    name: "Cote D'Ivoire (Ivory Coast)",
    iso3: "CIV",
    iso2: "CI",
    numeric_code: "384",
    phone_code: "225",
    capital: "Yamoussoukro",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ci",
    native: null,
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Ivorian",
    timezones: [
      {
        zoneName: "Africa/Abidjan",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "코트디부아르",
      "pt-BR": "Costa do Marfim",
      pt: "Costa do Marfim",
      nl: "Ivoorkust",
      hr: "Obala Bjelokosti",
      fa: "ساحل عاج",
      de: "Elfenbeinküste",
      es: "Costa de Marfil",
      fr: "Côte d'Ivoire",
      ja: "コートジボワール",
      it: "Costa D'Avorio",
      cn: "科特迪瓦",
      tr: "Kotdivuar",
    },
    latitude: "8.00000000",
    longitude: "-5.00000000",
    emoji: "🇨🇮",
    emojiU: "U+1F1E8 U+1F1EE",
    states: [
      {
        id: 2634,
        name: "Abidjan",
        state_code: "AB",
        latitude: "5.35995170",
        longitude: "-4.00825630",
        type: null,
      },
      {
        id: 2626,
        name: "Agnéby",
        state_code: "16",
        latitude: "5.32245030",
        longitude: "-4.34495290",
        type: null,
      },
      {
        id: 2636,
        name: "Bafing Region",
        state_code: "17",
        latitude: "8.32520470",
        longitude: "-7.52472430",
        type: null,
      },
      {
        id: 2643,
        name: "Bas-Sassandra District",
        state_code: "BS",
        latitude: "5.27983560",
        longitude: "-6.15269850",
        type: null,
      },
      {
        id: 2635,
        name: "Bas-Sassandra Region",
        state_code: "09",
        latitude: "5.35679160",
        longitude: "-6.74939930",
        type: null,
      },
      {
        id: 2654,
        name: "Comoé District",
        state_code: "CM",
        latitude: "5.55279300",
        longitude: "-3.25836260",
        type: null,
      },
      {
        id: 2644,
        name: "Denguélé District",
        state_code: "DN",
        latitude: "48.07077630",
        longitude: "-68.56093410",
        type: null,
      },
      {
        id: 2642,
        name: "Denguélé Region",
        state_code: "10",
        latitude: "9.46623720",
        longitude: "-7.43813550",
        type: null,
      },
      {
        id: 2645,
        name: "Dix-Huit Montagnes",
        state_code: "06",
        latitude: "7.37623730",
        longitude: "-7.43813550",
        type: null,
      },
      {
        id: 2633,
        name: "Fromager",
        state_code: "18",
        latitude: "45.54502130",
        longitude: "-73.60462230",
        type: null,
      },
      {
        id: 2651,
        name: "Gôh-Djiboua District",
        state_code: "GD",
        latitude: "5.87113930",
        longitude: "-5.56172790",
        type: null,
      },
      {
        id: 2638,
        name: "Haut-Sassandra",
        state_code: "02",
        latitude: "6.87578480",
        longitude: "-6.57833870",
        type: null,
      },
      {
        id: 2632,
        name: "Lacs District",
        state_code: "LC",
        latitude: "48.19801690",
        longitude: "-80.45644120",
        type: null,
      },
      {
        id: 2640,
        name: "Lacs Region",
        state_code: "07",
        latitude: "47.73958660",
        longitude: "-70.41866520",
        type: null,
      },
      {
        id: 2627,
        name: "Lagunes District",
        state_code: "LG",
        latitude: "5.88273340",
        longitude: "-4.23333550",
        type: null,
      },
      {
        id: 2639,
        name: "Lagunes region",
        state_code: "01",
        latitude: "5.88273340",
        longitude: "-4.23333550",
        type: null,
      },
      {
        id: 2631,
        name: "Marahoué Region",
        state_code: "12",
        latitude: "6.88462070",
        longitude: "-5.89871390",
        type: null,
      },
      {
        id: 2629,
        name: "Montagnes District",
        state_code: "MG",
        latitude: "7.37623730",
        longitude: "-7.43813550",
        type: null,
      },
      {
        id: 2646,
        name: "Moyen-Cavally",
        state_code: "19",
        latitude: "6.52087930",
        longitude: "-7.61142170",
        type: null,
      },
      {
        id: 2630,
        name: "Moyen-Comoé",
        state_code: "05",
        latitude: "6.65149170",
        longitude: "-3.50034540",
        type: null,
      },
      {
        id: 2655,
        name: "N'zi-Comoé",
        state_code: "11",
        latitude: "7.24567490",
        longitude: "-4.23333550",
        type: null,
      },
      {
        id: 2648,
        name: "Sassandra-Marahoué District",
        state_code: "SM",
        latitude: "6.88033480",
        longitude: "-6.23759470",
        type: null,
      },
      {
        id: 2625,
        name: "Savanes Region",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2628,
        name: "Sud-Bandama",
        state_code: "15",
        latitude: "5.53570830",
        longitude: "-5.56172790",
        type: null,
      },
      {
        id: 2652,
        name: "Sud-Comoé",
        state_code: "13",
        latitude: "5.55279300",
        longitude: "-3.25836260",
        type: null,
      },
      {
        id: 2637,
        name: "Vallée du Bandama District",
        state_code: "VB",
        latitude: "8.27897800",
        longitude: "-4.89356270",
        type: null,
      },
      {
        id: 2647,
        name: "Vallée du Bandama Region",
        state_code: "04",
        latitude: "8.27897800",
        longitude: "-4.89356270",
        type: null,
      },
      {
        id: 2650,
        name: "Woroba District",
        state_code: "WR",
        latitude: "8.24913720",
        longitude: "-6.92091350",
        type: null,
      },
      {
        id: 2649,
        name: "Worodougou",
        state_code: "14",
        latitude: "8.25489620",
        longitude: "-6.57833870",
        type: null,
      },
      {
        id: 2653,
        name: "Yamoussoukro",
        state_code: "YM",
        latitude: "6.82762280",
        longitude: "-5.28934330",
        type: null,
      },
      {
        id: 2641,
        name: "Zanzan Region",
        state_code: "ZZ",
        latitude: "8.82079040",
        longitude: "-3.41955270",
        type: null,
      },
    ],
  },
  {
    name: "Croatia",
    iso3: "HRV",
    iso2: "HR",
    numeric_code: "191",
    phone_code: "385",
    capital: "Zagreb",
    currency: "HRK",
    currency_name: "Croatian kuna",
    currency_symbol: "kn",
    tld: ".hr",
    native: "Hrvatska",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Croatian",
    timezones: [
      {
        zoneName: "Europe/Zagreb",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "크로아티아",
      "pt-BR": "Croácia",
      pt: "Croácia",
      nl: "Kroatië",
      hr: "Hrvatska",
      fa: "کرواسی",
      de: "Kroatien",
      es: "Croacia",
      fr: "Croatie",
      ja: "クロアチア",
      it: "Croazia",
      cn: "克罗地亚",
      tr: "Hirvatistan",
    },
    latitude: "45.16666666",
    longitude: "15.50000000",
    emoji: "🇭🇷",
    emojiU: "U+1F1ED U+1F1F7",
    states: [
      {
        id: 734,
        name: "Bjelovar-Bilogora",
        state_code: "07",
        latitude: "45.89879720",
        longitude: "16.84230930",
        type: "county",
      },
      {
        id: 737,
        name: "Brod-Posavina",
        state_code: "12",
        latitude: "45.26379510",
        longitude: "17.32645620",
        type: "county",
      },
      {
        id: 728,
        name: "Dubrovnik-Neretva",
        state_code: "19",
        latitude: "43.07665880",
        longitude: "17.52684710",
        type: "county",
      },
      {
        id: 743,
        name: "Istria",
        state_code: "18",
        latitude: "45.12864550",
        longitude: "13.90154200",
        type: "county",
      },
      {
        id: 5069,
        name: "Karlovac",
        state_code: "04",
        latitude: "45.26133520",
        longitude: "15.52542016",
        type: "county",
      },
      {
        id: 742,
        name: "Koprivnica-Križevci",
        state_code: "06",
        latitude: "46.15689190",
        longitude: "16.83908260",
        type: "county",
      },
      {
        id: 729,
        name: "Krapina-Zagorje",
        state_code: "02",
        latitude: "46.10133930",
        longitude: "15.88096930",
        type: "county",
      },
      {
        id: 731,
        name: "Lika-Senj",
        state_code: "09",
        latitude: "44.61922180",
        longitude: "15.47016080",
        type: "county",
      },
      {
        id: 726,
        name: "Međimurje",
        state_code: "20",
        latitude: "46.37666440",
        longitude: "16.42132980",
        type: "county",
      },
      {
        id: 740,
        name: "Osijek-Baranja",
        state_code: "14",
        latitude: "45.55764280",
        longitude: "18.39421410",
        type: "county",
      },
      {
        id: 724,
        name: "Požega-Slavonia",
        state_code: "11",
        latitude: "45.34178680",
        longitude: "17.81143590",
        type: "county",
      },
      {
        id: 735,
        name: "Primorje-Gorski Kotar",
        state_code: "08",
        latitude: "45.31739960",
        longitude: "14.81674660",
        type: "county",
      },
      {
        id: 730,
        name: "Šibenik-Knin",
        state_code: "15",
        latitude: "43.92814850",
        longitude: "16.10376940",
        type: "county",
      },
      {
        id: 733,
        name: "Sisak-Moslavina",
        state_code: "03",
        latitude: "45.38379260",
        longitude: "16.53809940",
        type: "county",
      },
      {
        id: 725,
        name: "Split-Dalmatia",
        state_code: "17",
        latitude: "43.52403280",
        longitude: "16.81783770",
        type: "county",
      },
      {
        id: 739,
        name: "Varaždin",
        state_code: "05",
        latitude: "46.23174730",
        longitude: "16.33605590",
        type: "county",
      },
      {
        id: 732,
        name: "Virovitica-Podravina",
        state_code: "10",
        latitude: "45.65579850",
        longitude: "17.79324720",
        type: "county",
      },
      {
        id: 741,
        name: "Vukovar-Syrmia",
        state_code: "16",
        latitude: "45.17735520",
        longitude: "18.80535270",
        type: "county",
      },
      {
        id: 727,
        name: "Zadar",
        state_code: "13",
        latitude: "44.14693900",
        longitude: "15.61649430",
        type: "county",
      },
      {
        id: 736,
        name: "Zagreb",
        state_code: "01",
        latitude: "45.87066120",
        longitude: "16.39549100",
        type: "county",
      },
      {
        id: 738,
        name: "Zagreb",
        state_code: "21",
        latitude: "45.81501080",
        longitude: "15.98191890",
        type: "city",
      },
    ],
  },
  {
    name: "Cuba",
    iso3: "CUB",
    iso2: "CU",
    numeric_code: "192",
    phone_code: "53",
    capital: "Havana",
    currency: "CUP",
    currency_name: "Cuban peso",
    currency_symbol: "$",
    tld: ".cu",
    native: "Cuba",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Cuban",
    timezones: [
      {
        zoneName: "America/Havana",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "CST",
        tzName: "Cuba Standard Time",
      },
    ],
    translations: {
      kr: "쿠바",
      "pt-BR": "Cuba",
      pt: "Cuba",
      nl: "Cuba",
      hr: "Kuba",
      fa: "کوبا",
      de: "Kuba",
      es: "Cuba",
      fr: "Cuba",
      ja: "キューバ",
      it: "Cuba",
      cn: "古巴",
      tr: "Küba",
    },
    latitude: "21.50000000",
    longitude: "-80.00000000",
    emoji: "🇨🇺",
    emojiU: "U+1F1E8 U+1F1FA",
    states: [
      {
        id: 283,
        name: "Artemisa Province",
        state_code: "15",
        latitude: "22.75229030",
        longitude: "-82.99316070",
        type: null,
      },
      {
        id: 286,
        name: "Camagüey Province",
        state_code: "09",
        latitude: "21.21672470",
        longitude: "-77.74520810",
        type: null,
      },
      {
        id: 282,
        name: "Ciego de Ávila Province",
        state_code: "08",
        latitude: "21.93295150",
        longitude: "-78.56608520",
        type: null,
      },
      {
        id: 287,
        name: "Cienfuegos Province",
        state_code: "06",
        latitude: "22.23797830",
        longitude: "-80.36586500",
        type: null,
      },
      {
        id: 275,
        name: "Granma Province",
        state_code: "12",
        latitude: "20.38449020",
        longitude: "-76.64127120",
        type: null,
      },
      {
        id: 285,
        name: "Guantánamo Province",
        state_code: "14",
        latitude: "20.14559170",
        longitude: "-74.87410450",
        type: null,
      },
      {
        id: 272,
        name: "Havana Province",
        state_code: "03",
        latitude: "23.05406980",
        longitude: "-82.34518900",
        type: null,
      },
      {
        id: 279,
        name: "Holguín Province",
        state_code: "11",
        latitude: "20.78378930",
        longitude: "-75.80690820",
        type: null,
      },
      {
        id: 278,
        name: "Isla de la Juventud",
        state_code: "99",
        latitude: "21.70847370",
        longitude: "-82.82202320",
        type: null,
      },
      {
        id: 281,
        name: "Las Tunas Province",
        state_code: "10",
        latitude: "21.06051620",
        longitude: "-76.91820970",
        type: null,
      },
      {
        id: 284,
        name: "Matanzas Province",
        state_code: "04",
        latitude: "22.57671230",
        longitude: "-81.33994140",
        type: null,
      },
      {
        id: 276,
        name: "Mayabeque Province",
        state_code: "16",
        latitude: "22.89265290",
        longitude: "-81.95348150",
        type: null,
      },
      {
        id: 277,
        name: "Pinar del Río Province",
        state_code: "01",
        latitude: "22.40762560",
        longitude: "-83.84730150",
        type: null,
      },
      {
        id: 274,
        name: "Sancti Spíritus Province",
        state_code: "07",
        latitude: "21.99382140",
        longitude: "-79.47038850",
        type: null,
      },
      {
        id: 273,
        name: "Santiago de Cuba Province",
        state_code: "13",
        latitude: "20.23976820",
        longitude: "-75.99276520",
        type: null,
      },
      {
        id: 280,
        name: "Villa Clara Province",
        state_code: "05",
        latitude: "22.49372040",
        longitude: "-79.91927020",
        type: null,
      },
    ],
  },
  {
    name: "Curaçao",
    iso3: "CUW",
    iso2: "CW",
    numeric_code: "531",
    phone_code: "599",
    capital: "Willemstad",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "ƒ",
    tld: ".cw",
    native: "Curaçao",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Curacaoan",
    timezones: [
      {
        zoneName: "America/Curacao",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "퀴라소",
      "pt-BR": "Curaçao",
      pt: "Curaçao",
      nl: "Curaçao",
      fa: "کوراسائو",
      de: "Curaçao",
      fr: "Curaçao",
      it: "Curaçao",
      cn: "库拉索",
      tr: "Curaçao",
    },
    latitude: "12.11666700",
    longitude: "-68.93333300",
    emoji: "🇨🇼",
    emojiU: "U+1F1E8 U+1F1FC",
    states: [],
  },
  {
    name: "Cyprus",
    iso3: "CYP",
    iso2: "CY",
    numeric_code: "196",
    phone_code: "357",
    capital: "Nicosia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".cy",
    native: "Κύπρος",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Cypriot",
    timezones: [
      {
        zoneName: "Asia/Famagusta",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Asia/Nicosia",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "키프로스",
      "pt-BR": "Chipre",
      pt: "Chipre",
      nl: "Cyprus",
      hr: "Cipar",
      fa: "قبرس",
      de: "Zypern",
      es: "Chipre",
      fr: "Chypre",
      ja: "キプロス",
      it: "Cipro",
      cn: "塞浦路斯",
      tr: "Kuzey Kıbrıs Türk Cumhuriyeti",
    },
    latitude: "35.00000000",
    longitude: "33.00000000",
    emoji: "🇨🇾",
    emojiU: "U+1F1E8 U+1F1FE",
    states: [
      {
        id: 749,
        name: "Famagusta District (Mağusa)",
        state_code: "04",
        latitude: "35.28570230",
        longitude: "33.84112880",
        type: "district",
      },
      {
        id: 744,
        name: "Kyrenia District (Keryneia)",
        state_code: "06",
        latitude: "35.29919400",
        longitude: "33.23632460",
        type: "district",
      },
      {
        id: 747,
        name: "Larnaca District (Larnaka)",
        state_code: "03",
        latitude: "34.85072060",
        longitude: "33.48319060",
        type: "district",
      },
      {
        id: 748,
        name: "Limassol District (Leymasun)",
        state_code: "02",
        latitude: "34.70713010",
        longitude: "33.02261740",
        type: "district",
      },
      {
        id: 745,
        name: "Nicosia District (Lefkoşa)",
        state_code: "01",
        latitude: "35.18556590",
        longitude: "33.38227640",
        type: "district",
      },
      {
        id: 746,
        name: "Paphos District (Pafos)",
        state_code: "05",
        latitude: "34.91645940",
        longitude: "32.49200880",
        type: "district",
      },
    ],
  },
  {
    name: "Czech Republic",
    iso3: "CZE",
    iso2: "CZ",
    numeric_code: "203",
    phone_code: "420",
    capital: "Prague",
    currency: "CZK",
    currency_name: "Czech koruna",
    currency_symbol: "Kč",
    tld: ".cz",
    native: "Česká republika",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Czech",
    timezones: null,
    translations: {
      kr: "체코",
      "pt-BR": "República Tcheca",
      pt: "República Checa",
      nl: "Tsjechië",
      hr: "Češka",
      fa: "جمهوری چک",
      de: "Tschechische Republik",
      es: "República Checa",
      fr: "République tchèque",
      ja: "チェコ",
      it: "Repubblica Ceca",
      cn: "捷克",
      tr: "Çekya",
    },
    latitude: "49.75000000",
    longitude: "15.50000000",
    emoji: "🇨🇿",
    emojiU: "U+1F1E8 U+1F1FF",
    states: [
      {
        id: 4627,
        name: "Benešov",
        state_code: "201",
        latitude: "49.69008280",
        longitude: "14.77643990",
        type: null,
      },
      {
        id: 4620,
        name: "Beroun",
        state_code: "202",
        latitude: "49.95734280",
        longitude: "13.98407150",
        type: null,
      },
      {
        id: 4615,
        name: "Blansko",
        state_code: "641",
        latitude: "49.36485020",
        longitude: "16.64775520",
        type: null,
      },
      {
        id: 4542,
        name: "Břeclav",
        state_code: "644",
        latitude: "48.75314000",
        longitude: "16.88251690",
        type: null,
      },
      {
        id: 4568,
        name: "Brno-město",
        state_code: "642",
        latitude: "49.19506020",
        longitude: "16.60683710",
        type: null,
      },
      {
        id: 4545,
        name: "Brno-venkov",
        state_code: "643",
        latitude: "49.12501380",
        longitude: "16.45588240",
        type: null,
      },
      {
        id: 4644,
        name: "Bruntál",
        state_code: "801",
        latitude: "49.98817670",
        longitude: "17.46369410",
        type: null,
      },
      {
        id: 4633,
        name: "Česká Lípa",
        state_code: "511",
        latitude: "50.67852010",
        longitude: "14.53969910",
        type: null,
      },
      {
        id: 4556,
        name: "České Budějovice",
        state_code: "311",
        latitude: "48.97755530",
        longitude: "14.51507470",
        type: null,
      },
      {
        id: 4543,
        name: "Český Krumlov",
        state_code: "312",
        latitude: "48.81273540",
        longitude: "14.31746570",
        type: null,
      },
      {
        id: 4573,
        name: "Cheb",
        state_code: "411",
        latitude: "50.07953340",
        longitude: "12.36986360",
        type: null,
      },
      {
        id: 4553,
        name: "Chomutov",
        state_code: "422",
        latitude: "50.45838720",
        longitude: "13.30179100",
        type: null,
      },
      {
        id: 4634,
        name: "Chrudim",
        state_code: "531",
        latitude: "49.88302160",
        longitude: "15.82908660",
        type: null,
      },
      {
        id: 4609,
        name: "Děčín",
        state_code: "421",
        latitude: "50.77255630",
        longitude: "14.21276120",
        type: null,
      },
      {
        id: 4641,
        name: "Domažlice",
        state_code: "321",
        latitude: "49.43970270",
        longitude: "12.93114350",
        type: null,
      },
      {
        id: 4559,
        name: "Frýdek-Místek",
        state_code: "802",
        latitude: "49.68193050",
        longitude: "18.36732160",
        type: null,
      },
      {
        id: 4611,
        name: "Havlíčkův Brod",
        state_code: "631",
        latitude: "49.60433640",
        longitude: "15.57965520",
        type: null,
      },
      {
        id: 4561,
        name: "Hodonín",
        state_code: "645",
        latitude: "48.85293910",
        longitude: "17.12600250",
        type: null,
      },
      {
        id: 4580,
        name: "Hradec Králové",
        state_code: "521",
        latitude: "50.24148050",
        longitude: "15.67430000",
        type: null,
      },
      {
        id: 4612,
        name: "Jablonec nad Nisou",
        state_code: "512",
        latitude: "50.72205280",
        longitude: "15.17031350",
        type: null,
      },
      {
        id: 4625,
        name: "Jeseník",
        state_code: "711",
        latitude: "50.22462490",
        longitude: "17.19804710",
        type: null,
      },
      {
        id: 4640,
        name: "Jičín",
        state_code: "522",
        latitude: "50.43533250",
        longitude: "15.36104400",
        type: null,
      },
      {
        id: 4613,
        name: "Jihlava",
        state_code: "632",
        latitude: "49.39837820",
        longitude: "15.58704150",
        type: null,
      },
      {
        id: 4639,
        name: "Jihočeský kraj",
        state_code: "31",
        latitude: "48.94577890",
        longitude: "14.44160550",
        type: null,
      },
      {
        id: 4602,
        name: "Jihomoravský kraj",
        state_code: "64",
        latitude: "48.95445280",
        longitude: "16.76768990",
        type: null,
      },
      {
        id: 4624,
        name: "Jindřichův Hradec",
        state_code: "313",
        latitude: "49.14448230",
        longitude: "15.00613890",
        type: null,
      },
      {
        id: 4581,
        name: "Karlovarský kraj",
        state_code: "41",
        latitude: "50.14350000",
        longitude: "12.75018990",
        type: null,
      },
      {
        id: 4604,
        name: "Karlovy Vary",
        state_code: "412",
        latitude: "50.14350000",
        longitude: "12.75018990",
        type: null,
      },
      {
        id: 4586,
        name: "Karviná",
        state_code: "803",
        latitude: "49.85665240",
        longitude: "18.54321860",
        type: null,
      },
      {
        id: 4631,
        name: "Kladno",
        state_code: "203",
        latitude: "50.19402580",
        longitude: "14.10436570",
        type: null,
      },
      {
        id: 4591,
        name: "Klatovy",
        state_code: "322",
        latitude: "49.39555490",
        longitude: "13.29509370",
        type: null,
      },
      {
        id: 4618,
        name: "Kolín",
        state_code: "204",
        latitude: "49.98832930",
        longitude: "15.05519770",
        type: null,
      },
      {
        id: 4575,
        name: "Kraj Vysočina",
        state_code: "63",
        latitude: "49.44900520",
        longitude: "15.64059340",
        type: null,
      },
      {
        id: 4614,
        name: "Královéhradecký kraj",
        state_code: "52",
        latitude: "50.35124840",
        longitude: "15.79764590",
        type: null,
      },
      {
        id: 4593,
        name: "Kroměříž",
        state_code: "721",
        latitude: "49.29165820",
        longitude: "17.39938000",
        type: null,
      },
      {
        id: 4923,
        name: "Kutná Hora",
        state_code: "205",
        latitude: "49.94920890",
        longitude: "15.24704400",
        type: null,
      },
      {
        id: 4590,
        name: "Liberec",
        state_code: "513",
        latitude: "50.75641010",
        longitude: "14.99650410",
        type: null,
      },
      {
        id: 4601,
        name: "Liberecký kraj",
        state_code: "51",
        latitude: "50.65942400",
        longitude: "14.76324240",
        type: null,
      },
      {
        id: 4605,
        name: "Litoměřice",
        state_code: "423",
        latitude: "50.53841970",
        longitude: "14.13054580",
        type: null,
      },
      {
        id: 4617,
        name: "Louny",
        state_code: "424",
        latitude: "50.35398120",
        longitude: "13.80335510",
        type: null,
      },
      {
        id: 4638,
        name: "Mělník",
        state_code: "206",
        latitude: "50.31044150",
        longitude: "14.51792230",
        type: null,
      },
      {
        id: 4643,
        name: "Mladá Boleslav",
        state_code: "207",
        latitude: "50.42523170",
        longitude: "14.93624770",
        type: null,
      },
      {
        id: 4600,
        name: "Moravskoslezský kraj",
        state_code: "80",
        latitude: "49.73053270",
        longitude: "18.23326370",
        type: null,
      },
      {
        id: 4629,
        name: "Most",
        state_code: "425",
        latitude: "37.15540830",
        longitude: "-94.29488840",
        type: null,
      },
      {
        id: 4550,
        name: "Náchod",
        state_code: "523",
        latitude: "50.41457220",
        longitude: "16.16563470",
        type: null,
      },
      {
        id: 4548,
        name: "Nový Jičín",
        state_code: "804",
        latitude: "49.59432510",
        longitude: "18.01353560",
        type: null,
      },
      {
        id: 4582,
        name: "Nymburk",
        state_code: "208",
        latitude: "50.18558160",
        longitude: "15.04366040",
        type: null,
      },
      {
        id: 4574,
        name: "Olomouc",
        state_code: "712",
        latitude: "49.59377800",
        longitude: "17.25087870",
        type: null,
      },
      {
        id: 4589,
        name: "Olomoucký kraj",
        state_code: "71",
        latitude: "49.65865490",
        longitude: "17.08114060",
        type: null,
      },
      {
        id: 4623,
        name: "Opava",
        state_code: "805",
        latitude: "49.90837570",
        longitude: "17.91633800",
        type: null,
      },
      {
        id: 4584,
        name: "Ostrava-město",
        state_code: "806",
        latitude: "49.82092260",
        longitude: "18.26252430",
        type: null,
      },
      {
        id: 4547,
        name: "Pardubice",
        state_code: "532",
        latitude: "49.94444790",
        longitude: "16.28569160",
        type: null,
      },
      {
        id: 4588,
        name: "Pardubický kraj",
        state_code: "53",
        latitude: "49.94444790",
        longitude: "16.28569160",
        type: null,
      },
      {
        id: 4645,
        name: "Pelhřimov",
        state_code: "633",
        latitude: "49.43062070",
        longitude: "15.22298300",
        type: null,
      },
      {
        id: 4560,
        name: "Písek",
        state_code: "314",
        latitude: "49.34199380",
        longitude: "14.24697600",
        type: null,
      },
      {
        id: 4608,
        name: "Plzeň-jih",
        state_code: "324",
        latitude: "49.59048850",
        longitude: "13.57158610",
        type: null,
      },
      {
        id: 4544,
        name: "Plzeň-město",
        state_code: "323",
        latitude: "49.73843140",
        longitude: "13.37363710",
        type: null,
      },
      {
        id: 4564,
        name: "Plzeň-sever",
        state_code: "325",
        latitude: "49.87748930",
        longitude: "13.25374280",
        type: null,
      },
      {
        id: 4607,
        name: "Plzeňský kraj",
        state_code: "32",
        latitude: "49.41348120",
        longitude: "13.31572460",
        type: null,
      },
      {
        id: 4578,
        name: "Prachatice",
        state_code: "315",
        latitude: "49.01091000",
        longitude: "14.00000050",
        type: null,
      },
      {
        id: 4606,
        name: "Praha-východ",
        state_code: "209",
        latitude: "49.93893070",
        longitude: "14.79244720",
        type: null,
      },
      {
        id: 4619,
        name: "Praha-západ",
        state_code: "20A",
        latitude: "49.89352350",
        longitude: "14.32937790",
        type: null,
      },
      {
        id: 4598,
        name: "Praha, Hlavní město",
        state_code: "10",
        latitude: "50.07553810",
        longitude: "14.43780050",
        type: null,
      },
      {
        id: 4626,
        name: "Přerov",
        state_code: "714",
        latitude: "49.46713560",
        longitude: "17.50773320",
        type: null,
      },
      {
        id: 4546,
        name: "Příbram",
        state_code: "20B",
        latitude: "49.69479590",
        longitude: "14.08238100",
        type: null,
      },
      {
        id: 4551,
        name: "Prostějov",
        state_code: "713",
        latitude: "49.44184010",
        longitude: "17.12779040",
        type: null,
      },
      {
        id: 4558,
        name: "Rakovník",
        state_code: "20C",
        latitude: "50.10612300",
        longitude: "13.73966230",
        type: null,
      },
      {
        id: 4583,
        name: "Rokycany",
        state_code: "326",
        latitude: "49.82628270",
        longitude: "13.68749430",
        type: null,
      },
      {
        id: 4636,
        name: "Rychnov nad Kněžnou",
        state_code: "524",
        latitude: "50.16596510",
        longitude: "16.27768420",
        type: null,
      },
      {
        id: 4596,
        name: "Semily",
        state_code: "514",
        latitude: "50.60515760",
        longitude: "15.32814090",
        type: null,
      },
      {
        id: 4595,
        name: "Sokolov",
        state_code: "413",
        latitude: "50.20134340",
        longitude: "12.60546360",
        type: null,
      },
      {
        id: 4628,
        name: "Strakonice",
        state_code: "316",
        latitude: "49.26040430",
        longitude: "13.91030850",
        type: null,
      },
      {
        id: 4554,
        name: "Středočeský kraj",
        state_code: "20",
        latitude: "49.87822230",
        longitude: "14.93629550",
        type: null,
      },
      {
        id: 4642,
        name: "Šumperk",
        state_code: "715",
        latitude: "49.97784070",
        longitude: "16.97177540",
        type: null,
      },
      {
        id: 4571,
        name: "Svitavy",
        state_code: "533",
        latitude: "49.75516290",
        longitude: "16.46918610",
        type: null,
      },
      {
        id: 4565,
        name: "Tábor",
        state_code: "317",
        latitude: "49.36462930",
        longitude: "14.71912930",
        type: null,
      },
      {
        id: 4646,
        name: "Tachov",
        state_code: "327",
        latitude: "49.79878030",
        longitude: "12.63619210",
        type: null,
      },
      {
        id: 4621,
        name: "Teplice",
        state_code: "426",
        latitude: "50.65846050",
        longitude: "13.75132270",
        type: null,
      },
      {
        id: 4597,
        name: "Třebíč",
        state_code: "634",
        latitude: "49.21478690",
        longitude: "15.87955160",
        type: null,
      },
      {
        id: 4579,
        name: "Trutnov",
        state_code: "525",
        latitude: "50.56538380",
        longitude: "15.90909230",
        type: null,
      },
      {
        id: 4592,
        name: "Uherské Hradiště",
        state_code: "722",
        latitude: "49.05979690",
        longitude: "17.49585010",
        type: null,
      },
      {
        id: 4576,
        name: "Ústecký kraj",
        state_code: "42",
        latitude: "50.61190370",
        longitude: "13.78700860",
        type: null,
      },
      {
        id: 4599,
        name: "Ústí nad Labem",
        state_code: "427",
        latitude: "50.61190370",
        longitude: "13.78700860",
        type: null,
      },
      {
        id: 4647,
        name: "Ústí nad Orlicí",
        state_code: "534",
        latitude: "49.97218010",
        longitude: "16.39966170",
        type: null,
      },
      {
        id: 4572,
        name: "Vsetín",
        state_code: "723",
        latitude: "49.37932500",
        longitude: "18.06181620",
        type: null,
      },
      {
        id: 4622,
        name: "Vyškov",
        state_code: "646",
        latitude: "49.21274450",
        longitude: "16.98559270",
        type: null,
      },
      {
        id: 4648,
        name: "Žďár nad Sázavou",
        state_code: "635",
        latitude: "49.56430120",
        longitude: "15.93910300",
        type: null,
      },
      {
        id: 4563,
        name: "Zlín",
        state_code: "724",
        latitude: "49.16960520",
        longitude: "17.80252200",
        type: null,
      },
      {
        id: 4552,
        name: "Zlínský kraj",
        state_code: "72",
        latitude: "49.21622960",
        longitude: "17.77203530",
        type: null,
      },
      {
        id: 4630,
        name: "Znojmo",
        state_code: "647",
        latitude: "48.92723270",
        longitude: "16.10378080",
        type: null,
      },
    ],
  },
  {
    name: "Democratic Republic of the Congo",
    iso3: "COD",
    iso2: "CD",
    numeric_code: "180",
    phone_code: "243",
    capital: "Kinshasa",
    currency: "CDF",
    currency_name: "Congolese Franc",
    currency_symbol: "FC",
    tld: ".cd",
    native: "République démocratique du Congo",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Congolese",
    timezones: [
      {
        zoneName: "Africa/Kinshasa",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
      {
        zoneName: "Africa/Lubumbashi",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "콩고 민주 공화국",
      "pt-BR": "RD Congo",
      pt: "RD Congo",
      nl: "Congo [DRC]",
      hr: "Kongo, Demokratska Republika",
      fa: "جمهوری کنگو",
      de: "Kongo (Dem. Rep.)",
      es: "Congo (Rep. Dem.)",
      fr: "Congo (Rép. dém.)",
      ja: "コンゴ民主共和国",
      it: "Congo (Rep. Dem.)",
      cn: "刚果（金）",
      tr: "Kongo Demokratik Cumhuriyeti",
    },
    latitude: "0.00000000",
    longitude: "25.00000000",
    emoji: "🇨🇩",
    emojiU: "U+1F1E8 U+1F1E9",
    states: [
      {
        id: 2753,
        name: "Bas-Uélé",
        state_code: "BU",
        latitude: "3.99010090",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 2744,
        name: "Équateur",
        state_code: "EQ",
        latitude: "-1.83123900",
        longitude: "-78.18340600",
        type: null,
      },
      {
        id: 2750,
        name: "Haut-Katanga",
        state_code: "HK",
        latitude: "-10.41020750",
        longitude: "27.54958460",
        type: null,
      },
      {
        id: 2758,
        name: "Haut-Lomami",
        state_code: "HL",
        latitude: "-7.70527520",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 2734,
        name: "Haut-Uélé",
        state_code: "HU",
        latitude: "3.58451540",
        longitude: "28.29943500",
        type: null,
      },
      {
        id: 2751,
        name: "Ituri",
        state_code: "IT",
        latitude: "1.59576820",
        longitude: "29.41793240",
        type: null,
      },
      {
        id: 2757,
        name: "Kasaï",
        state_code: "KS",
        latitude: "-5.04719790",
        longitude: "20.71224650",
        type: null,
      },
      {
        id: 2742,
        name: "Kasaï Central",
        state_code: "KC",
        latitude: "-8.44045910",
        longitude: "20.41659340",
        type: null,
      },
      {
        id: 2735,
        name: "Kasaï Oriental",
        state_code: "KE",
        latitude: "-6.03362300",
        longitude: "23.57285010",
        type: null,
      },
      {
        id: 2741,
        name: "Kinshasa",
        state_code: "KN",
        latitude: "-4.44193110",
        longitude: "15.26629310",
        type: null,
      },
      {
        id: 2746,
        name: "Kongo Central",
        state_code: "BC",
        latitude: "-5.23656850",
        longitude: "13.91439900",
        type: null,
      },
      {
        id: 2740,
        name: "Kwango",
        state_code: "KG",
        latitude: "-6.43374090",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 2759,
        name: "Kwilu",
        state_code: "KL",
        latitude: "-5.11888250",
        longitude: "18.42760470",
        type: null,
      },
      {
        id: 2747,
        name: "Lomami",
        state_code: "LO",
        latitude: "-6.14539310",
        longitude: "24.52426400",
        type: null,
      },
      {
        id: 4953,
        name: "Lualaba",
        state_code: "LU",
        latitude: "-10.48086980",
        longitude: "25.62978160",
        type: null,
      },
      {
        id: 2755,
        name: "Mai-Ndombe",
        state_code: "MN",
        latitude: "-2.63574340",
        longitude: "18.42760470",
        type: null,
      },
      {
        id: 2745,
        name: "Maniema",
        state_code: "MA",
        latitude: "-3.07309290",
        longitude: "26.04138890",
        type: null,
      },
      {
        id: 2752,
        name: "Mongala",
        state_code: "MO",
        latitude: "1.99623240",
        longitude: "21.47528510",
        type: null,
      },
      {
        id: 2749,
        name: "Nord-Kivu",
        state_code: "NK",
        latitude: "-0.79177290",
        longitude: "29.04599270",
        type: null,
      },
      {
        id: 2739,
        name: "Nord-Ubangi",
        state_code: "NU",
        latitude: "3.78787260",
        longitude: "21.47528510",
        type: null,
      },
      {
        id: 2743,
        name: "Sankuru",
        state_code: "SA",
        latitude: "-2.84374530",
        longitude: "23.38235450",
        type: null,
      },
      {
        id: 2738,
        name: "Sud-Kivu",
        state_code: "SK",
        latitude: "-3.01165800",
        longitude: "28.29943500",
        type: null,
      },
      {
        id: 2748,
        name: "Sud-Ubangi",
        state_code: "SU",
        latitude: "3.22999420",
        longitude: "19.18800470",
        type: null,
      },
      {
        id: 2733,
        name: "Tanganyika",
        state_code: "TA",
        latitude: "-6.27401180",
        longitude: "27.92490020",
        type: null,
      },
      {
        id: 2756,
        name: "Tshopo",
        state_code: "TO",
        latitude: "0.54554620",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 2732,
        name: "Tshuapa",
        state_code: "TU",
        latitude: "-0.99030230",
        longitude: "23.02888440",
        type: null,
      },
    ],
  },
  {
    name: "Denmark",
    iso3: "DNK",
    iso2: "DK",
    numeric_code: "208",
    phone_code: "45",
    capital: "Copenhagen",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".dk",
    native: "Danmark",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Danish",
    timezones: [
      {
        zoneName: "Europe/Copenhagen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "덴마크",
      "pt-BR": "Dinamarca",
      pt: "Dinamarca",
      nl: "Denemarken",
      hr: "Danska",
      fa: "دانمارک",
      de: "Dänemark",
      es: "Dinamarca",
      fr: "Danemark",
      ja: "デンマーク",
      it: "Danimarca",
      cn: "丹麦",
      tr: "Danimarka",
    },
    latitude: "56.00000000",
    longitude: "10.00000000",
    emoji: "🇩🇰",
    emojiU: "U+1F1E9 U+1F1F0",
    states: [
      {
        id: 1530,
        name: "Capital Region of Denmark",
        state_code: "84",
        latitude: "55.67518120",
        longitude: "12.54932610",
        type: null,
      },
      {
        id: 1531,
        name: "Central Denmark Region",
        state_code: "82",
        latitude: "56.30213900",
        longitude: "9.30277700",
        type: null,
      },
      {
        id: 1532,
        name: "North Denmark Region",
        state_code: "81",
        latitude: "56.83074160",
        longitude: "9.49305270",
        type: null,
      },
      {
        id: 1529,
        name: "Region of Southern Denmark",
        state_code: "83",
        latitude: "55.33077140",
        longitude: "9.09249030",
        type: null,
      },
      {
        id: 1528,
        name: "Region Zealand",
        state_code: "85",
        latitude: "55.46325180",
        longitude: "11.72149790",
        type: null,
      },
    ],
  },
  {
    name: "Djibouti",
    iso3: "DJI",
    iso2: "DJ",
    numeric_code: "262",
    phone_code: "253",
    capital: "Djibouti",
    currency: "DJF",
    currency_name: "Djiboutian franc",
    currency_symbol: "Fdj",
    tld: ".dj",
    native: "Djibouti",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Djiboutian",
    timezones: [
      {
        zoneName: "Africa/Djibouti",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "지부티",
      "pt-BR": "Djibuti",
      pt: "Djibuti",
      nl: "Djibouti",
      hr: "Džibuti",
      fa: "جیبوتی",
      de: "Dschibuti",
      es: "Yibuti",
      fr: "Djibouti",
      ja: "ジブチ",
      it: "Gibuti",
      cn: "吉布提",
      tr: "Cibuti",
    },
    latitude: "11.50000000",
    longitude: "43.00000000",
    emoji: "🇩🇯",
    emojiU: "U+1F1E9 U+1F1EF",
    states: [
      {
        id: 2933,
        name: "Ali Sabieh Region",
        state_code: "AS",
        latitude: "11.19289730",
        longitude: "42.94169800",
        type: null,
      },
      {
        id: 2932,
        name: "Arta Region",
        state_code: "AR",
        latitude: "11.52555280",
        longitude: "42.84794740",
        type: null,
      },
      {
        id: 2930,
        name: "Dikhil Region",
        state_code: "DI",
        latitude: "11.10543360",
        longitude: "42.37047440",
        type: null,
      },
      {
        id: 2929,
        name: "Djibouti",
        state_code: "DJ",
        latitude: "11.82513800",
        longitude: "42.59027500",
        type: null,
      },
      {
        id: 2928,
        name: "Obock Region",
        state_code: "OB",
        latitude: "12.38956910",
        longitude: "43.01948970",
        type: null,
      },
      {
        id: 2931,
        name: "Tadjourah Region",
        state_code: "TA",
        latitude: "11.93388850",
        longitude: "42.39383750",
        type: null,
      },
    ],
  },
  {
    name: "Dominica",
    iso3: "DMA",
    iso2: "DM",
    numeric_code: "212",
    phone_code: "+1-767",
    capital: "Roseau",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".dm",
    native: "Dominica",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Dominican",
    timezones: [
      {
        zoneName: "America/Dominica",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "도미니카 연방",
      "pt-BR": "Dominica",
      pt: "Dominica",
      nl: "Dominica",
      hr: "Dominika",
      fa: "دومینیکا",
      de: "Dominica",
      es: "Dominica",
      fr: "Dominique",
      ja: "ドミニカ国",
      it: "Dominica",
      cn: "多米尼加",
      tr: "Dominika",
    },
    latitude: "15.41666666",
    longitude: "-61.33333333",
    emoji: "🇩🇲",
    emojiU: "U+1F1E9 U+1F1F2",
    states: [
      {
        id: 4082,
        name: "Saint Andrew Parish",
        state_code: "02",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4078,
        name: "Saint David Parish",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4079,
        name: "Saint George Parish",
        state_code: "04",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4076,
        name: "Saint John Parish",
        state_code: "05",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4085,
        name: "Saint Joseph Parish",
        state_code: "06",
        latitude: "39.02227120",
        longitude: "-94.71765040",
        type: null,
      },
      {
        id: 4083,
        name: "Saint Luke Parish",
        state_code: "07",
        latitude: "42.10513630",
        longitude: "-80.05707220",
        type: null,
      },
      {
        id: 4077,
        name: "Saint Mark Parish",
        state_code: "08",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4080,
        name: "Saint Patrick Parish",
        state_code: "09",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4084,
        name: "Saint Paul Parish",
        state_code: "10",
        latitude: "38.86146000",
        longitude: "-90.74356190",
        type: null,
      },
      {
        id: 4081,
        name: "Saint Peter Parish",
        state_code: "11",
        latitude: "40.45241940",
        longitude: "-80.00850560",
        type: null,
      },
    ],
  },
  {
    name: "Dominican Republic",
    iso3: "DOM",
    iso2: "DO",
    numeric_code: "214",
    phone_code: "+1-809 and 1-829",
    capital: "Santo Domingo",
    currency: "DOP",
    currency_name: "Dominican peso",
    currency_symbol: "$",
    tld: ".do",
    native: "República Dominicana",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Dominican",
    timezones: [
      {
        zoneName: "America/Santo_Domingo",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "도미니카 공화국",
      "pt-BR": "República Dominicana",
      pt: "República Dominicana",
      nl: "Dominicaanse Republiek",
      hr: "Dominikanska Republika",
      fa: "جمهوری دومینیکن",
      de: "Dominikanische Republik",
      es: "República Dominicana",
      fr: "République dominicaine",
      ja: "ドミニカ共和国",
      it: "Repubblica Dominicana",
      cn: "多明尼加共和国",
      tr: "Dominik Cumhuriyeti",
    },
    latitude: "19.00000000",
    longitude: "-70.66666666",
    emoji: "🇩🇴",
    emojiU: "U+1F1E9 U+1F1F4",
    states: [
      {
        id: 4114,
        name: "Azua Province",
        state_code: "02",
        latitude: "18.45527090",
        longitude: "-70.73809280",
        type: null,
      },
      {
        id: 4105,
        name: "Baoruco Province",
        state_code: "03",
        latitude: "18.48798980",
        longitude: "-71.41822490",
        type: null,
      },
      {
        id: 4090,
        name: "Barahona Province",
        state_code: "04",
        latitude: "18.21390660",
        longitude: "-71.10437590",
        type: null,
      },
      {
        id: 4107,
        name: "Dajabón Province",
        state_code: "05",
        latitude: "19.54992410",
        longitude: "-71.70865140",
        type: null,
      },
      {
        id: 4095,
        name: "Distrito Nacional",
        state_code: "01",
        latitude: "18.48605750",
        longitude: "-69.93121170",
        type: null,
      },
      {
        id: 4113,
        name: "Duarte Province",
        state_code: "06",
        latitude: "19.20908230",
        longitude: "-70.02700040",
        type: null,
      },
      {
        id: 4086,
        name: "El Seibo Province",
        state_code: "08",
        latitude: "18.76584960",
        longitude: "-69.04066800",
        type: null,
      },
      {
        id: 4102,
        name: "Espaillat Province",
        state_code: "09",
        latitude: "19.62776580",
        longitude: "-70.27867750",
        type: null,
      },
      {
        id: 4106,
        name: "Hato Mayor Province",
        state_code: "30",
        latitude: "18.76357990",
        longitude: "-69.25576370",
        type: null,
      },
      {
        id: 4089,
        name: "Hermanas Mirabal Province",
        state_code: "19",
        latitude: "19.37475590",
        longitude: "-70.35132350",
        type: null,
      },
      {
        id: 4097,
        name: "Independencia",
        state_code: "10",
        latitude: "32.63357480",
        longitude: "-115.42892940",
        type: null,
      },
      {
        id: 4109,
        name: "La Altagracia Province",
        state_code: "11",
        latitude: "18.58502360",
        longitude: "-68.62010720",
        type: null,
      },
      {
        id: 4087,
        name: "La Romana Province",
        state_code: "12",
        latitude: "18.43102710",
        longitude: "-68.98373730",
        type: null,
      },
      {
        id: 4116,
        name: "La Vega Province",
        state_code: "13",
        latitude: "19.22115540",
        longitude: "-70.52887530",
        type: null,
      },
      {
        id: 4094,
        name: "María Trinidad Sánchez Province",
        state_code: "14",
        latitude: "19.37345970",
        longitude: "-69.85144390",
        type: null,
      },
      {
        id: 4099,
        name: "Monseñor Nouel Province",
        state_code: "28",
        latitude: "18.92152340",
        longitude: "-70.38368150",
        type: null,
      },
      {
        id: 4115,
        name: "Monte Cristi Province",
        state_code: "15",
        latitude: "19.73968990",
        longitude: "-71.44339840",
        type: null,
      },
      {
        id: 4111,
        name: "Monte Plata Province",
        state_code: "29",
        latitude: "18.80808780",
        longitude: "-69.78691460",
        type: null,
      },
      {
        id: 4101,
        name: "Pedernales Province",
        state_code: "16",
        latitude: "17.85376260",
        longitude: "-71.33032090",
        type: null,
      },
      {
        id: 4096,
        name: "Peravia Province",
        state_code: "17",
        latitude: "18.27865940",
        longitude: "-70.33358870",
        type: null,
      },
      {
        id: 4092,
        name: "Puerto Plata Province",
        state_code: "18",
        latitude: "19.75432250",
        longitude: "-70.83328470",
        type: null,
      },
      {
        id: 4103,
        name: "Samaná Province",
        state_code: "20",
        latitude: "19.20583710",
        longitude: "-69.33629490",
        type: null,
      },
      {
        id: 4091,
        name: "San Cristóbal Province",
        state_code: "21",
        latitude: "18.41804140",
        longitude: "-70.10658490",
        type: null,
      },
      {
        id: 4112,
        name: "San José de Ocoa Province",
        state_code: "31",
        latitude: "18.54385800",
        longitude: "-70.50418160",
        type: null,
      },
      {
        id: 4098,
        name: "San Juan Province",
        state_code: "22",
        latitude: "-31.52871270",
        longitude: "-68.53604030",
        type: null,
      },
      {
        id: 4110,
        name: "San Pedro de Macorís",
        state_code: "23",
        latitude: "18.46266000",
        longitude: "-69.30512340",
        type: null,
      },
      {
        id: 4088,
        name: "Sánchez Ramírez Province",
        state_code: "24",
        latitude: "19.05270600",
        longitude: "-70.14922640",
        type: null,
      },
      {
        id: 4108,
        name: "Santiago Province",
        state_code: "25",
        latitude: "-33.45000000",
        longitude: "-70.66670000",
        type: null,
      },
      {
        id: 4100,
        name: "Santiago Rodríguez Province",
        state_code: "26",
        latitude: "19.47131810",
        longitude: "-71.33958010",
        type: null,
      },
      {
        id: 4093,
        name: "Santo Domingo Province",
        state_code: "32",
        latitude: "18.51042530",
        longitude: "-69.84040540",
        type: null,
      },
      {
        id: 4104,
        name: "Valverde Province",
        state_code: "27",
        latitude: "19.58812210",
        longitude: "-70.98033100",
        type: null,
      },
    ],
  },
  {
    name: "Ecuador",
    iso3: "ECU",
    iso2: "EC",
    numeric_code: "218",
    phone_code: "593",
    capital: "Quito",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".ec",
    native: "Ecuador",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Ecuadorian",
    timezones: [
      {
        zoneName: "America/Guayaquil",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "ECT",
        tzName: "Ecuador Time",
      },
      {
        zoneName: "Pacific/Galapagos",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "GALT",
        tzName: "Galápagos Time",
      },
    ],
    translations: {
      kr: "에콰도르",
      "pt-BR": "Equador",
      pt: "Equador",
      nl: "Ecuador",
      hr: "Ekvador",
      fa: "اکوادور",
      de: "Ecuador",
      es: "Ecuador",
      fr: "Équateur",
      ja: "エクアドル",
      it: "Ecuador",
      cn: "厄瓜多尔",
      tr: "Ekvator",
    },
    latitude: "-2.00000000",
    longitude: "-77.50000000",
    emoji: "🇪🇨",
    emojiU: "U+1F1EA U+1F1E8",
    states: [
      {
        id: 2923,
        name: "Azuay",
        state_code: "A",
        latitude: "-2.89430680",
        longitude: "-78.99683440",
        type: "province",
      },
      {
        id: 2920,
        name: "Bolívar",
        state_code: "B",
        latitude: "-1.70958280",
        longitude: "-79.04504290",
        type: "province",
      },
      {
        id: 2917,
        name: "Cañar",
        state_code: "F",
        latitude: "-2.55893150",
        longitude: "-78.93881910",
        type: "province",
      },
      {
        id: 2915,
        name: "Carchi",
        state_code: "C",
        latitude: "0.50269120",
        longitude: "-77.90425210",
        type: "province",
      },
      {
        id: 2925,
        name: "Chimborazo",
        state_code: "H",
        latitude: "-1.66479950",
        longitude: "-78.65432550",
        type: "province",
      },
      {
        id: 2921,
        name: "Cotopaxi",
        state_code: "X",
        latitude: "-0.83842060",
        longitude: "-78.66626780",
        type: "province",
      },
      {
        id: 2924,
        name: "El Oro",
        state_code: "O",
        latitude: "-3.25924130",
        longitude: "-79.95835410",
        type: "province",
      },
      {
        id: 2922,
        name: "Esmeraldas",
        state_code: "E",
        latitude: "0.96817890",
        longitude: "-79.65172020",
        type: "province",
      },
      {
        id: 2905,
        name: "Galápagos",
        state_code: "W",
        latitude: "-0.95376910",
        longitude: "-90.96560190",
        type: "province",
      },
      {
        id: 2914,
        name: "Guayas",
        state_code: "G",
        latitude: "-1.95748390",
        longitude: "-79.91927020",
        type: "province",
      },
      {
        id: 2911,
        name: "Imbabura",
        state_code: "I",
        latitude: "0.34997680",
        longitude: "-78.12601290",
        type: "province",
      },
      {
        id: 5068,
        name: "Loja",
        state_code: "L",
        latitude: "-3.99313000",
        longitude: "-79.20422000",
        type: "province",
      },
      {
        id: 2910,
        name: "Los Ríos",
        state_code: "R",
        latitude: "-1.02306070",
        longitude: "-79.46088970",
        type: "province",
      },
      {
        id: 2913,
        name: "Manabí",
        state_code: "M",
        latitude: "-1.05434340",
        longitude: "-80.45264400",
        type: "province",
      },
      {
        id: 2918,
        name: "Morona-Santiago",
        state_code: "S",
        latitude: "-2.30510620",
        longitude: "-78.11468660",
        type: "province",
      },
      {
        id: 2916,
        name: "Napo",
        state_code: "N",
        latitude: "-0.99559640",
        longitude: "-77.81296840",
        type: "province",
      },
      {
        id: 2926,
        name: "Orellana",
        state_code: "D",
        latitude: "-0.45451630",
        longitude: "-76.99502860",
        type: "province",
      },
      {
        id: 2907,
        name: "Pastaza",
        state_code: "Y",
        latitude: "-1.48822650",
        longitude: "-78.00310570",
        type: "province",
      },
      {
        id: 2927,
        name: "Pichincha",
        state_code: "P",
        latitude: "-0.14648470",
        longitude: "-78.47519450",
        type: "province",
      },
      {
        id: 2912,
        name: "Santa Elena",
        state_code: "SE",
        latitude: "-2.22671050",
        longitude: "-80.85949900",
        type: "province",
      },
      {
        id: 2919,
        name: "Santo Domingo de los Tsáchilas",
        state_code: "SD",
        latitude: "-0.25218820",
        longitude: "-79.18793830",
        type: "province",
      },
      {
        id: 2906,
        name: "Sucumbíos",
        state_code: "U",
        latitude: "0.08892310",
        longitude: "-76.88975570",
        type: "province",
      },
      {
        id: 2908,
        name: "Tungurahua",
        state_code: "T",
        latitude: "-1.26352840",
        longitude: "-78.56608520",
        type: "province",
      },
      {
        id: 2909,
        name: "Zamora Chinchipe",
        state_code: "Z",
        latitude: "-4.06558920",
        longitude: "-78.95035250",
        type: "province",
      },
    ],
  },
  {
    name: "Egypt",
    iso3: "EGY",
    iso2: "EG",
    numeric_code: "818",
    phone_code: "20",
    capital: "Cairo",
    currency: "EGP",
    currency_name: "Egyptian pound",
    currency_symbol: "ج.م",
    tld: ".eg",
    native: "مصر‎",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Egyptian",
    timezones: [
      {
        zoneName: "Africa/Cairo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "이집트",
      "pt-BR": "Egito",
      pt: "Egipto",
      nl: "Egypte",
      hr: "Egipat",
      fa: "مصر",
      de: "Ägypten",
      es: "Egipto",
      fr: "Égypte",
      ja: "エジプト",
      it: "Egitto",
      cn: "埃及",
      tr: "Mısır",
    },
    latitude: "27.00000000",
    longitude: "30.00000000",
    emoji: "🇪🇬",
    emojiU: "U+1F1EA U+1F1EC",
    states: [
      {
        id: 3235,
        name: "Alexandria",
        state_code: "ALX",
        latitude: "30.87605680",
        longitude: "29.74260400",
        type: null,
      },
      {
        id: 3225,
        name: "Aswan",
        state_code: "ASN",
        latitude: "23.69664980",
        longitude: "32.71813750",
        type: null,
      },
      {
        id: 3236,
        name: "Asyut",
        state_code: "AST",
        latitude: "27.21338310",
        longitude: "31.44561790",
        type: null,
      },
      {
        id: 3241,
        name: "Beheira",
        state_code: "BH",
        latitude: "30.84809860",
        longitude: "30.34355060",
        type: null,
      },
      {
        id: 3230,
        name: "Beni Suef",
        state_code: "BNS",
        latitude: "28.89388370",
        longitude: "31.44561790",
        type: null,
      },
      {
        id: 3223,
        name: "Cairo",
        state_code: "C",
        latitude: "29.95375640",
        longitude: "31.53700030",
        type: null,
      },
      {
        id: 3245,
        name: "Dakahlia",
        state_code: "DK",
        latitude: "31.16560440",
        longitude: "31.49131820",
        type: null,
      },
      {
        id: 3224,
        name: "Damietta",
        state_code: "DT",
        latitude: "31.36257990",
        longitude: "31.67393710",
        type: null,
      },
      {
        id: 3238,
        name: "Faiyum",
        state_code: "FYM",
        latitude: "29.30840210",
        longitude: "30.84284970",
        type: null,
      },
      {
        id: 3234,
        name: "Gharbia",
        state_code: "GH",
        latitude: "30.87535560",
        longitude: "31.03351000",
        type: null,
      },
      {
        id: 3239,
        name: "Giza",
        state_code: "GZ",
        latitude: "28.76662160",
        longitude: "29.23207840",
        type: null,
      },
      {
        id: 3244,
        name: "Ismailia",
        state_code: "IS",
        latitude: "30.58309340",
        longitude: "32.26538870",
        type: null,
      },
      {
        id: 3222,
        name: "Kafr el-Sheikh",
        state_code: "KFS",
        latitude: "31.30854440",
        longitude: "30.80394740",
        type: null,
      },
      {
        id: 3242,
        name: "Luxor",
        state_code: "LX",
        latitude: "25.39444440",
        longitude: "32.49200880",
        type: null,
      },
      {
        id: 3231,
        name: "Matrouh",
        state_code: "MT",
        latitude: "29.56963500",
        longitude: "26.41938900",
        type: null,
      },
      {
        id: 3243,
        name: "Minya",
        state_code: "MN",
        latitude: "28.28472900",
        longitude: "30.52790960",
        type: null,
      },
      {
        id: 3228,
        name: "Monufia",
        state_code: "MNF",
        latitude: "30.59724550",
        longitude: "30.98763210",
        type: null,
      },
      {
        id: 3246,
        name: "New Valley",
        state_code: "WAD",
        latitude: "24.54556380",
        longitude: "27.17353160",
        type: null,
      },
      {
        id: 3227,
        name: "North Sinai",
        state_code: "SIN",
        latitude: "30.28236500",
        longitude: "33.61757700",
        type: null,
      },
      {
        id: 3229,
        name: "Port Said",
        state_code: "PTS",
        latitude: "31.07586060",
        longitude: "32.26538870",
        type: null,
      },
      {
        id: 3232,
        name: "Qalyubia",
        state_code: "KB",
        latitude: "30.32923680",
        longitude: "31.21684660",
        type: null,
      },
      {
        id: 3247,
        name: "Qena",
        state_code: "KN",
        latitude: "26.23460330",
        longitude: "32.98883190",
        type: null,
      },
      {
        id: 3240,
        name: "Red Sea",
        state_code: "BA",
        latitude: "24.68263160",
        longitude: "34.15319470",
        type: null,
      },
      {
        id: 5067,
        name: "Sharqia",
        state_code: "SHR",
        latitude: "30.67305450",
        longitude: "31.15932470",
        type: null,
      },
      {
        id: 3226,
        name: "Sohag",
        state_code: "SHG",
        latitude: "26.69383400",
        longitude: "32.17460500",
        type: null,
      },
      {
        id: 3237,
        name: "South Sinai",
        state_code: "JS",
        latitude: "29.31018280",
        longitude: "34.15319470",
        type: null,
      },
      {
        id: 3233,
        name: "Suez",
        state_code: "SUZ",
        latitude: "29.36822550",
        longitude: "32.17460500",
        type: null,
      },
    ],
  },
  {
    name: "El Salvador",
    iso3: "SLV",
    iso2: "SV",
    numeric_code: "222",
    phone_code: "503",
    capital: "San Salvador",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".sv",
    native: "El Salvador",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Salvadoran",
    timezones: [
      {
        zoneName: "America/El_Salvador",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "엘살바도르",
      "pt-BR": "El Salvador",
      pt: "El Salvador",
      nl: "El Salvador",
      hr: "Salvador",
      fa: "السالوادور",
      de: "El Salvador",
      es: "El Salvador",
      fr: "Salvador",
      ja: "エルサルバドル",
      it: "El Salvador",
      cn: "萨尔瓦多",
      tr: "El Salvador",
    },
    latitude: "13.83333333",
    longitude: "-88.91666666",
    emoji: "🇸🇻",
    emojiU: "U+1F1F8 U+1F1FB",
    states: [
      {
        id: 4139,
        name: "Ahuachapán Department",
        state_code: "AH",
        latitude: "13.82161480",
        longitude: "-89.92532330",
        type: null,
      },
      {
        id: 4132,
        name: "Cabañas Department",
        state_code: "CA",
        latitude: "13.86482880",
        longitude: "-88.74939980",
        type: null,
      },
      {
        id: 4131,
        name: "Chalatenango Department",
        state_code: "CH",
        latitude: "14.19166480",
        longitude: "-89.17059980",
        type: null,
      },
      {
        id: 4137,
        name: "Cuscatlán Department",
        state_code: "CU",
        latitude: "13.86619570",
        longitude: "-89.05615320",
        type: null,
      },
      {
        id: 4134,
        name: "La Libertad Department",
        state_code: "LI",
        latitude: "13.68176610",
        longitude: "-89.36062980",
        type: null,
      },
      {
        id: 4136,
        name: "La Paz Department",
        state_code: "PA",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4138,
        name: "La Unión Department",
        state_code: "UN",
        latitude: "13.48864430",
        longitude: "-87.89424510",
        type: null,
      },
      {
        id: 4130,
        name: "Morazán Department",
        state_code: "MO",
        latitude: "13.76820000",
        longitude: "-88.12913870",
        type: null,
      },
      {
        id: 4135,
        name: "San Miguel Department",
        state_code: "SM",
        latitude: "13.44510410",
        longitude: "-88.24611830",
        type: null,
      },
      {
        id: 4133,
        name: "San Salvador Department",
        state_code: "SS",
        latitude: "13.77399970",
        longitude: "-89.20867730",
        type: null,
      },
      {
        id: 4127,
        name: "San Vicente Department",
        state_code: "SV",
        latitude: "13.58685610",
        longitude: "-88.74939980",
        type: null,
      },
      {
        id: 4128,
        name: "Santa Ana Department",
        state_code: "SA",
        latitude: "14.14611210",
        longitude: "-89.51200840",
        type: null,
      },
      {
        id: 4140,
        name: "Sonsonate Department",
        state_code: "SO",
        latitude: "13.68235800",
        longitude: "-89.66281110",
        type: null,
      },
      {
        id: 4129,
        name: "Usulután Department",
        state_code: "US",
        latitude: "13.44706340",
        longitude: "-88.55653100",
        type: null,
      },
    ],
  },
  {
    name: "Equatorial Guinea",
    iso3: "GNQ",
    iso2: "GQ",
    numeric_code: "226",
    phone_code: "240",
    capital: "Malabo",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".gq",
    native: "Guinea Ecuatorial",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Equatorial Guinean, Equatoguinean",
    timezones: [
      {
        zoneName: "Africa/Malabo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "적도 기니",
      "pt-BR": "Guiné Equatorial",
      pt: "Guiné Equatorial",
      nl: "Equatoriaal-Guinea",
      hr: "Ekvatorijalna Gvineja",
      fa: "گینه استوایی",
      de: "Äquatorial-Guinea",
      es: "Guinea Ecuatorial",
      fr: "Guinée-Équatoriale",
      ja: "赤道ギニア",
      it: "Guinea Equatoriale",
      cn: "赤道几内亚",
      tr: "Ekvator Ginesi",
    },
    latitude: "2.00000000",
    longitude: "10.00000000",
    emoji: "🇬🇶",
    emojiU: "U+1F1EC U+1F1F6",
    states: [
      {
        id: 3444,
        name: "Annobón Province",
        state_code: "AN",
        latitude: "-1.42687820",
        longitude: "5.63528010",
        type: null,
      },
      {
        id: 3446,
        name: "Bioko Norte Province",
        state_code: "BN",
        latitude: "3.65950720",
        longitude: "8.79218360",
        type: null,
      },
      {
        id: 3443,
        name: "Bioko Sur Province",
        state_code: "BS",
        latitude: "3.42097850",
        longitude: "8.61606740",
        type: null,
      },
      {
        id: 3445,
        name: "Centro Sur Province",
        state_code: "CS",
        latitude: "1.34360840",
        longitude: "10.43965600",
        type: null,
      },
      {
        id: 3442,
        name: "Insular Region",
        state_code: "I",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        type: null,
      },
      {
        id: 3439,
        name: "Kié-Ntem Province",
        state_code: "KN",
        latitude: "2.02809300",
        longitude: "11.07117580",
        type: null,
      },
      {
        id: 3441,
        name: "Litoral Province",
        state_code: "LI",
        latitude: "1.57502440",
        longitude: "9.81249350",
        type: null,
      },
      {
        id: 3438,
        name: "Río Muni",
        state_code: "C",
        latitude: "1.46106060",
        longitude: "9.67868940",
        type: null,
      },
      {
        id: 3440,
        name: "Wele-Nzas Province",
        state_code: "WN",
        latitude: "1.41661620",
        longitude: "11.07117580",
        type: null,
      },
    ],
  },
  {
    name: "Eritrea",
    iso3: "ERI",
    iso2: "ER",
    numeric_code: "232",
    phone_code: "291",
    capital: "Asmara",
    currency: "ERN",
    currency_name: "Eritrean nakfa",
    currency_symbol: "Nfk",
    tld: ".er",
    native: "ኤርትራ",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Eritrean",
    timezones: [
      {
        zoneName: "Africa/Asmara",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "에리트레아",
      "pt-BR": "Eritreia",
      pt: "Eritreia",
      nl: "Eritrea",
      hr: "Eritreja",
      fa: "اریتره",
      de: "Eritrea",
      es: "Eritrea",
      fr: "Érythrée",
      ja: "エリトリア",
      it: "Eritrea",
      cn: "厄立特里亚",
      tr: "Eritre",
    },
    latitude: "15.00000000",
    longitude: "39.00000000",
    emoji: "🇪🇷",
    emojiU: "U+1F1EA U+1F1F7",
    states: [
      {
        id: 3425,
        name: "Anseba Region",
        state_code: "AN",
        latitude: "16.47455310",
        longitude: "37.80876930",
        type: null,
      },
      {
        id: 3427,
        name: "Debub Region",
        state_code: "DU",
        latitude: "14.94786920",
        longitude: "39.15436770",
        type: null,
      },
      {
        id: 3428,
        name: "Gash-Barka Region",
        state_code: "GB",
        latitude: "15.40688250",
        longitude: "37.63866220",
        type: null,
      },
      {
        id: 3426,
        name: "Maekel Region",
        state_code: "MA",
        latitude: "15.35514090",
        longitude: "38.86236830",
        type: null,
      },
      {
        id: 3424,
        name: "Northern Red Sea Region",
        state_code: "SK",
        latitude: "16.25839970",
        longitude: "38.82054540",
        type: null,
      },
      {
        id: 3429,
        name: "Southern Red Sea Region",
        state_code: "DK",
        latitude: "13.51371030",
        longitude: "41.76064720",
        type: null,
      },
    ],
  },
  {
    name: "Estonia",
    iso3: "EST",
    iso2: "EE",
    numeric_code: "233",
    phone_code: "372",
    capital: "Tallinn",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ee",
    native: "Eesti",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Estonian",
    timezones: [
      {
        zoneName: "Europe/Tallinn",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "에스토니아",
      "pt-BR": "Estônia",
      pt: "Estónia",
      nl: "Estland",
      hr: "Estonija",
      fa: "استونی",
      de: "Estland",
      es: "Estonia",
      fr: "Estonie",
      ja: "エストニア",
      it: "Estonia",
      cn: "爱沙尼亚",
      tr: "Estonya",
    },
    latitude: "59.00000000",
    longitude: "26.00000000",
    emoji: "🇪🇪",
    emojiU: "U+1F1EA U+1F1EA",
    states: [
      {
        id: 3567,
        name: "Harju County",
        state_code: "37",
        latitude: "59.33342390",
        longitude: "25.24669740",
        type: null,
      },
      {
        id: 3555,
        name: "Hiiu County",
        state_code: "39",
        latitude: "58.92395530",
        longitude: "22.59194680",
        type: null,
      },
      {
        id: 3569,
        name: "Ida-Viru County",
        state_code: "44",
        latitude: "59.25926630",
        longitude: "27.41365350",
        type: null,
      },
      {
        id: 3566,
        name: "Järva County",
        state_code: "51",
        latitude: "58.88667130",
        longitude: "25.50006240",
        type: null,
      },
      {
        id: 3565,
        name: "Jõgeva County",
        state_code: "49",
        latitude: "58.75061430",
        longitude: "26.36048780",
        type: null,
      },
      {
        id: 3568,
        name: "Lääne County",
        state_code: "57",
        latitude: "58.97227420",
        longitude: "23.87408340",
        type: null,
      },
      {
        id: 3564,
        name: "Lääne-Viru County",
        state_code: "59",
        latitude: "59.30188160",
        longitude: "26.32803120",
        type: null,
      },
      {
        id: 3562,
        name: "Pärnu County",
        state_code: "67",
        latitude: "58.52619520",
        longitude: "24.40201590",
        type: null,
      },
      {
        id: 3563,
        name: "Põlva County",
        state_code: "65",
        latitude: "58.11606220",
        longitude: "27.20663940",
        type: null,
      },
      {
        id: 3559,
        name: "Rapla County",
        state_code: "70",
        latitude: "58.84926250",
        longitude: "24.73465690",
        type: null,
      },
      {
        id: 3561,
        name: "Saare County",
        state_code: "74",
        latitude: "58.48497210",
        longitude: "22.61364080",
        type: null,
      },
      {
        id: 3557,
        name: "Tartu County",
        state_code: "78",
        latitude: "58.40571280",
        longitude: "26.80157600",
        type: null,
      },
      {
        id: 3558,
        name: "Valga County",
        state_code: "82",
        latitude: "57.91034410",
        longitude: "26.16018190",
        type: null,
      },
      {
        id: 3556,
        name: "Viljandi County",
        state_code: "84",
        latitude: "58.28217460",
        longitude: "25.57522330",
        type: null,
      },
      {
        id: 3560,
        name: "Võru County",
        state_code: "86",
        latitude: "57.73773720",
        longitude: "27.13989380",
        type: null,
      },
    ],
  },
  {
    name: "Eswatini",
    iso3: "SWZ",
    iso2: "SZ",
    numeric_code: "748",
    phone_code: "268",
    capital: "Mbabane",
    currency: "SZL",
    currency_name: "Lilangeni",
    currency_symbol: "E",
    tld: ".sz",
    native: "Swaziland",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Swazi",
    timezones: [
      {
        zoneName: "Africa/Mbabane",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "에스와티니",
      "pt-BR": "Suazilândia",
      pt: "Suazilândia",
      nl: "Swaziland",
      hr: "Svazi",
      fa: "سوازیلند",
      de: "Swasiland",
      es: "Suazilandia",
      fr: "Swaziland",
      ja: "スワジランド",
      it: "Swaziland",
      cn: "斯威士兰",
      tr: "Esvatini",
    },
    latitude: "-26.50000000",
    longitude: "31.50000000",
    emoji: "🇸🇿",
    emojiU: "U+1F1F8 U+1F1FF",
    states: [
      {
        id: 969,
        name: "Hhohho District",
        state_code: "HH",
        latitude: "-26.13656620",
        longitude: "31.35416310",
        type: null,
      },
      {
        id: 970,
        name: "Lubombo District",
        state_code: "LU",
        latitude: "-26.78517730",
        longitude: "31.81070790",
        type: null,
      },
      {
        id: 968,
        name: "Manzini District",
        state_code: "MA",
        latitude: "-26.50819990",
        longitude: "31.37131640",
        type: null,
      },
      {
        id: 971,
        name: "Shiselweni District",
        state_code: "SH",
        latitude: "-26.98275770",
        longitude: "31.35416310",
        type: null,
      },
    ],
  },
  {
    name: "Ethiopia",
    iso3: "ETH",
    iso2: "ET",
    numeric_code: "231",
    phone_code: "251",
    capital: "Addis Ababa",
    currency: "ETB",
    currency_name: "Ethiopian birr",
    currency_symbol: "Nkf",
    tld: ".et",
    native: "ኢትዮጵያ",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Ethiopian",
    timezones: [
      {
        zoneName: "Africa/Addis_Ababa",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "에티오피아",
      "pt-BR": "Etiópia",
      pt: "Etiópia",
      nl: "Ethiopië",
      hr: "Etiopija",
      fa: "اتیوپی",
      de: "Äthiopien",
      es: "Etiopía",
      fr: "Éthiopie",
      ja: "エチオピア",
      it: "Etiopia",
      cn: "埃塞俄比亚",
      tr: "Etiyopya",
    },
    latitude: "8.00000000",
    longitude: "38.00000000",
    emoji: "🇪🇹",
    emojiU: "U+1F1EA U+1F1F9",
    states: [
      {
        id: 11,
        name: "Addis Ababa",
        state_code: "AA",
        latitude: "8.98060340",
        longitude: "38.75776050",
        type: null,
      },
      {
        id: 6,
        name: "Afar Region",
        state_code: "AF",
        latitude: "11.75593880",
        longitude: "40.95868800",
        type: null,
      },
      {
        id: 3,
        name: "Amhara Region",
        state_code: "AM",
        latitude: "11.34942470",
        longitude: "37.97845850",
        type: null,
      },
      {
        id: 9,
        name: "Benishangul-Gumuz Region",
        state_code: "BE",
        latitude: "10.78028890",
        longitude: "35.56578620",
        type: null,
      },
      {
        id: 8,
        name: "Dire Dawa",
        state_code: "DD",
        latitude: "9.60087470",
        longitude: "41.85014200",
        type: null,
      },
      {
        id: 10,
        name: "Gambela Region",
        state_code: "GA",
        latitude: "7.92196870",
        longitude: "34.15319470",
        type: null,
      },
      {
        id: 7,
        name: "Harari Region",
        state_code: "HA",
        latitude: "9.31486600",
        longitude: "42.19677160",
        type: null,
      },
      {
        id: 5,
        name: "Oromia Region",
        state_code: "OR",
        latitude: "7.54603770",
        longitude: "40.63468510",
        type: null,
      },
      {
        id: 2,
        name: "Somali Region",
        state_code: "SO",
        latitude: "6.66122930",
        longitude: "43.79084530",
        type: null,
      },
      {
        id: 1,
        name: "Southern Nations, Nationalities, and Peoples' Region",
        state_code: "SN",
        latitude: "6.51569110",
        longitude: "36.95410700",
        type: null,
      },
      {
        id: 4,
        name: "Tigray Region",
        state_code: "TI",
        latitude: "14.03233360",
        longitude: "38.31657250",
        type: null,
      },
    ],
  },
  {
    name: "Falkland Islands",
    iso3: "FLK",
    iso2: "FK",
    numeric_code: "238",
    phone_code: "500",
    capital: "Stanley",
    currency: "FKP",
    currency_name: "Falkland Islands pound",
    currency_symbol: "£",
    tld: ".fk",
    native: "Falkland Islands",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Falkland Island",
    timezones: [
      {
        zoneName: "Atlantic/Stanley",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "FKST",
        tzName: "Falkland Islands Summer Time",
      },
    ],
    translations: {
      kr: "포클랜드 제도",
      "pt-BR": "Ilhas Malvinas",
      pt: "Ilhas Falkland",
      nl: "Falklandeilanden [Islas Malvinas]",
      hr: "Falklandski Otoci",
      fa: "جزایر فالکلند",
      de: "Falklandinseln",
      es: "Islas Malvinas",
      fr: "Îles Malouines",
      ja: "フォークランド（マルビナス）諸島",
      it: "Isole Falkland o Isole Malvine",
      cn: "福克兰群岛",
      tr: "Falkland Adalari",
    },
    latitude: "-51.75000000",
    longitude: "-59.00000000",
    emoji: "🇫🇰",
    emojiU: "U+1F1EB U+1F1F0",
    states: [],
  },
  {
    name: "Faroe Islands",
    iso3: "FRO",
    iso2: "FO",
    numeric_code: "234",
    phone_code: "298",
    capital: "Torshavn",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".fo",
    native: "Føroyar",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Faroese",
    timezones: [
      {
        zoneName: "Atlantic/Faroe",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
    ],
    translations: {
      kr: "페로 제도",
      "pt-BR": "Ilhas Faroé",
      pt: "Ilhas Faroé",
      nl: "Faeröer",
      hr: "Farski Otoci",
      fa: "جزایر فارو",
      de: "Färöer-Inseln",
      es: "Islas Faroe",
      fr: "Îles Féroé",
      ja: "フェロー諸島",
      it: "Isole Far Oer",
      cn: "法罗群岛",
      tr: "Faroe Adalari",
    },
    latitude: "62.00000000",
    longitude: "-7.00000000",
    emoji: "🇫🇴",
    emojiU: "U+1F1EB U+1F1F4",
    states: [],
  },
  {
    name: "Fiji Islands",
    iso3: "FJI",
    iso2: "FJ",
    numeric_code: "242",
    phone_code: "679",
    capital: "Suva",
    currency: "FJD",
    currency_name: "Fijian dollar",
    currency_symbol: "FJ$",
    tld: ".fj",
    native: "Fiji",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Fijian",
    timezones: [
      {
        zoneName: "Pacific/Fiji",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "FJT",
        tzName: "Fiji Time",
      },
    ],
    translations: {
      kr: "피지",
      "pt-BR": "Fiji",
      pt: "Fiji",
      nl: "Fiji",
      hr: "Fiđi",
      fa: "فیجی",
      de: "Fidschi",
      es: "Fiyi",
      fr: "Fidji",
      ja: "フィジー",
      it: "Figi",
      cn: "斐济",
      tr: "Fiji",
    },
    latitude: "-18.00000000",
    longitude: "175.00000000",
    emoji: "🇫🇯",
    emojiU: "U+1F1EB U+1F1EF",
    states: [
      {
        id: 1917,
        name: "Ba",
        state_code: "01",
        latitude: "36.06138930",
        longitude: "-95.80058720",
        type: null,
      },
      {
        id: 1930,
        name: "Bua",
        state_code: "02",
        latitude: "43.09645840",
        longitude: "-89.50088000",
        type: null,
      },
      {
        id: 1924,
        name: "Cakaudrove",
        state_code: "03",
        latitude: "-16.58141050",
        longitude: "179.51200840",
        type: null,
      },
      {
        id: 1929,
        name: "Central Division",
        state_code: "C",
        latitude: "34.04400660",
        longitude: "-118.24727380",
        type: null,
      },
      {
        id: 1932,
        name: "Eastern Division",
        state_code: "E",
        latitude: "32.80943050",
        longitude: "-117.12899370",
        type: null,
      },
      {
        id: 1934,
        name: "Kadavu",
        state_code: "04",
        latitude: "-19.01271220",
        longitude: "178.18766760",
        type: null,
      },
      {
        id: 1933,
        name: "Lau",
        state_code: "05",
        latitude: "31.66870150",
        longitude: "-106.39557630",
        type: null,
      },
      {
        id: 1916,
        name: "Lomaiviti",
        state_code: "06",
        latitude: "-17.70900000",
        longitude: "179.09100000",
        type: null,
      },
      {
        id: 1922,
        name: "Macuata",
        state_code: "07",
        latitude: "-16.48649220",
        longitude: "179.28472510",
        type: null,
      },
      {
        id: 1919,
        name: "Nadroga-Navosa",
        state_code: "08",
        latitude: "-17.98652780",
        longitude: "177.65811300",
        type: null,
      },
      {
        id: 1927,
        name: "Naitasiri",
        state_code: "09",
        latitude: "-17.89757540",
        longitude: "178.20715980",
        type: null,
      },
      {
        id: 1928,
        name: "Namosi",
        state_code: "10",
        latitude: "-18.08641760",
        longitude: "178.12913870",
        type: null,
      },
      {
        id: 1921,
        name: "Northern Division",
        state_code: "N",
        latitude: "32.87687660",
        longitude: "-117.21563450",
        type: null,
      },
      {
        id: 1926,
        name: "Ra",
        state_code: "11",
        latitude: "37.10031530",
        longitude: "-95.67442460",
        type: null,
      },
      {
        id: 1920,
        name: "Rewa",
        state_code: "12",
        latitude: "34.79235170",
        longitude: "-82.36092640",
        type: null,
      },
      {
        id: 1931,
        name: "Rotuma",
        state_code: "R",
        latitude: "-12.50250690",
        longitude: "177.07241640",
        type: null,
      },
      {
        id: 1925,
        name: "Serua",
        state_code: "13",
        latitude: "-18.18047490",
        longitude: "178.05097900",
        type: null,
      },
      {
        id: 1918,
        name: "Tailevu",
        state_code: "14",
        latitude: "-17.82691110",
        longitude: "178.29324800",
        type: null,
      },
      {
        id: 1923,
        name: "Western Division",
        state_code: "W",
        latitude: "42.96621980",
        longitude: "-78.70211340",
        type: null,
      },
    ],
  },
  {
    name: "Finland",
    iso3: "FIN",
    iso2: "FI",
    numeric_code: "246",
    phone_code: "358",
    capital: "Helsinki",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".fi",
    native: "Suomi",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Finnish",
    timezones: [
      {
        zoneName: "Europe/Helsinki",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "핀란드",
      "pt-BR": "Finlândia",
      pt: "Finlândia",
      nl: "Finland",
      hr: "Finska",
      fa: "فنلاند",
      de: "Finnland",
      es: "Finlandia",
      fr: "Finlande",
      ja: "フィンランド",
      it: "Finlandia",
      cn: "芬兰",
      tr: "Finlandiya",
    },
    latitude: "64.00000000",
    longitude: "26.00000000",
    emoji: "🇫🇮",
    emojiU: "U+1F1EB U+1F1EE",
    states: [
      {
        id: 1509,
        name: "Åland Islands",
        state_code: "01",
        latitude: "60.17852470",
        longitude: "19.91561050",
        type: "region",
      },
      {
        id: 1511,
        name: "Central Finland",
        state_code: "08",
        latitude: "62.56667430",
        longitude: "25.55494450",
        type: "region",
      },
      {
        id: 1494,
        name: "Central Ostrobothnia",
        state_code: "07",
        latitude: "63.56217350",
        longitude: "24.00136310",
        type: "region",
      },
      {
        id: 1507,
        name: "Finland Proper",
        state_code: "19",
        latitude: "60.36279140",
        longitude: "22.44393690",
        type: "region",
      },
      {
        id: 1496,
        name: "Kainuu",
        state_code: "05",
        latitude: "64.37365640",
        longitude: "28.74374750",
        type: "region",
      },
      {
        id: 1512,
        name: "Kymenlaakso",
        state_code: "09",
        latitude: "60.78051200",
        longitude: "26.88293360",
        type: "region",
      },
      {
        id: 1500,
        name: "Lapland",
        state_code: "10",
        latitude: "67.92223040",
        longitude: "26.50464380",
        type: "region",
      },
      {
        id: 1504,
        name: "North Karelia",
        state_code: "13",
        latitude: "62.80620780",
        longitude: "30.15538870",
        type: "region",
      },
      {
        id: 1505,
        name: "Northern Ostrobothnia",
        state_code: "14",
        latitude: "65.27949300",
        longitude: "26.28904170",
        type: "region",
      },
      {
        id: 1503,
        name: "Northern Savonia",
        state_code: "15",
        latitude: "63.08448000",
        longitude: "27.02535040",
        type: "region",
      },
      {
        id: 1508,
        name: "Ostrobothnia",
        state_code: "12",
        latitude: "63.11817570",
        longitude: "21.90610620",
        type: "region",
      },
      {
        id: 1502,
        name: "Päijänne Tavastia",
        state_code: "16",
        latitude: "61.32300410",
        longitude: "25.73224960",
        type: "region",
      },
      {
        id: 1506,
        name: "Pirkanmaa",
        state_code: "11",
        latitude: "61.69869180",
        longitude: "23.78955980",
        type: "region",
      },
      {
        id: 1501,
        name: "Satakunta",
        state_code: "17",
        latitude: "61.59327580",
        longitude: "22.14830810",
        type: "region",
      },
      {
        id: 1497,
        name: "South Karelia",
        state_code: "02",
        latitude: "61.11819490",
        longitude: "28.10243720",
        type: "region",
      },
      {
        id: 1498,
        name: "Southern Ostrobothnia",
        state_code: "03",
        latitude: "62.94330990",
        longitude: "23.52852670",
        type: "region",
      },
      {
        id: 1495,
        name: "Southern Savonia",
        state_code: "04",
        latitude: "61.69451480",
        longitude: "27.80050150",
        type: "region",
      },
      {
        id: 1493,
        name: "Tavastia Proper",
        state_code: "06",
        latitude: "60.90701500",
        longitude: "24.30054980",
        type: "region",
      },
      {
        id: 1510,
        name: "Uusimaa",
        state_code: "18",
        latitude: "60.21872000",
        longitude: "25.27162100",
        type: "region",
      },
    ],
  },
  {
    name: "France",
    iso3: "FRA",
    iso2: "FR",
    numeric_code: "250",
    phone_code: "33",
    capital: "Paris",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".fr",
    native: "France",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "French",
    timezones: [
      {
        zoneName: "Europe/Paris",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "프랑스",
      "pt-BR": "França",
      pt: "França",
      nl: "Frankrijk",
      hr: "Francuska",
      fa: "فرانسه",
      de: "Frankreich",
      es: "Francia",
      fr: "France",
      ja: "フランス",
      it: "Francia",
      cn: "法国",
      tr: "Fransa",
    },
    latitude: "46.00000000",
    longitude: "2.00000000",
    emoji: "🇫🇷",
    emojiU: "U+1F1EB U+1F1F7",
    states: [
      {
        id: 4967,
        name: "Ain",
        state_code: "01",
        latitude: "46.06508600",
        longitude: "4.88861500",
        type: "metropolitan department",
      },
      {
        id: 4968,
        name: "Aisne",
        state_code: "02",
        latitude: "49.45289210",
        longitude: "3.04651110",
        type: "metropolitan department",
      },
      {
        id: 4969,
        name: "Allier",
        state_code: "03",
        latitude: "46.36708630",
        longitude: "2.58082770",
        type: "metropolitan department",
      },
      {
        id: 4970,
        name: "Alpes-de-Haute-Provence",
        state_code: "04",
        latitude: "44.16377520",
        longitude: "5.67247800",
        type: "metropolitan department",
      },
      {
        id: 4972,
        name: "Alpes-Maritimes",
        state_code: "06",
        latitude: "43.92041700",
        longitude: "6.61678220",
        type: "metropolitan department",
      },
      {
        id: 4811,
        name: "Alsace",
        state_code: "6AE",
        latitude: "48.31817950",
        longitude: "7.44162410",
        type: "European collectivity",
      },
      {
        id: 4973,
        name: "Ardèche",
        state_code: "07",
        latitude: "44.81486950",
        longitude: "3.81334830",
        type: "metropolitan department",
      },
      {
        id: 4974,
        name: "Ardennes",
        state_code: "08",
        latitude: "49.69759510",
        longitude: "4.14895760",
        type: "metropolitan department",
      },
      {
        id: 4975,
        name: "Ariège",
        state_code: "09",
        latitude: "42.94347830",
        longitude: "0.94048640",
        type: "metropolitan department",
      },
      {
        id: 4976,
        name: "Aube",
        state_code: "10",
        latitude: "48.31975470",
        longitude: "3.56371040",
        type: "metropolitan department",
      },
      {
        id: 4977,
        name: "Aude",
        state_code: "11",
        latitude: "43.05411400",
        longitude: "1.90384760",
        type: "metropolitan department",
      },
      {
        id: 4798,
        name: "Auvergne-Rhône-Alpes",
        state_code: "ARA",
        latitude: "45.44714310",
        longitude: "4.38525070",
        type: "metropolitan region",
      },
      {
        id: 4978,
        name: "Aveyron",
        state_code: "12",
        latitude: "44.31563620",
        longitude: "2.08523790",
        type: "metropolitan department",
      },
      {
        id: 5035,
        name: "Bas-Rhin",
        state_code: "67",
        latitude: "48.59864440",
        longitude: "7.02666760",
        type: "metropolitan department",
      },
      {
        id: 4979,
        name: "Bouches-du-Rhône",
        state_code: "13",
        latitude: "43.54038650",
        longitude: "4.46138290",
        type: "metropolitan department",
      },
      {
        id: 4825,
        name: "Bourgogne-Franche-Comté",
        state_code: "BFC",
        latitude: "47.28051270",
        longitude: "4.99943720",
        type: "metropolitan region",
      },
      {
        id: 4807,
        name: "Bretagne",
        state_code: "BRE",
        latitude: "48.20204710",
        longitude: "-2.93264350",
        type: "metropolitan region",
      },
      {
        id: 4981,
        name: "Calvados",
        state_code: "14",
        latitude: "49.09035140",
        longitude: "-0.91706480",
        type: "metropolitan department",
      },
      {
        id: 4982,
        name: "Cantal",
        state_code: "15",
        latitude: "45.04921770",
        longitude: "2.15672720",
        type: "metropolitan department",
      },
      {
        id: 4818,
        name: "Centre-Val de Loire",
        state_code: "CVL",
        latitude: "47.75156860",
        longitude: "1.67506310",
        type: "metropolitan region",
      },
      {
        id: 4983,
        name: "Charente",
        state_code: "16",
        latitude: "45.66584790",
        longitude: "-0.31845770",
        type: "metropolitan department",
      },
      {
        id: 4984,
        name: "Charente-Maritime",
        state_code: "17",
        latitude: "45.72968280",
        longitude: "-1.33881160",
        type: "metropolitan department",
      },
      {
        id: 4985,
        name: "Cher",
        state_code: "18",
        latitude: "47.02436280",
        longitude: "1.86627320",
        type: "metropolitan department",
      },
      {
        id: 5064,
        name: "Clipperton",
        state_code: "CP",
        latitude: "10.28335410",
        longitude: "-109.22542150",
        type: "dependency",
      },
      {
        id: 4986,
        name: "Corrèze",
        state_code: "19",
        latitude: "45.34237070",
        longitude: "1.31717330",
        type: "metropolitan department",
      },
      {
        id: 4806,
        name: "Corse",
        state_code: "20R",
        latitude: "42.03960420",
        longitude: "9.01289260",
        type: "metropolitan collectivity with special status",
      },
      {
        id: 4996,
        name: "Corse-du-Sud",
        state_code: "2A",
        latitude: "41.85720550",
        longitude: "8.41091830",
        type: "metropolitan department",
      },
      {
        id: 4987,
        name: "Côte-d'Or",
        state_code: "21",
        latitude: "47.46513020",
        longitude: "4.23154950",
        type: "metropolitan department",
      },
      {
        id: 4988,
        name: "Côtes-d'Armor",
        state_code: "22",
        latitude: "48.46633360",
        longitude: "-3.34789610",
        type: "metropolitan department",
      },
      {
        id: 4989,
        name: "Creuse",
        state_code: "23",
        latitude: "46.05903940",
        longitude: "1.43150500",
        type: "metropolitan department",
      },
      {
        id: 5047,
        name: "Deux-Sèvres",
        state_code: "79",
        latitude: "46.53868170",
        longitude: "-0.90199480",
        type: "metropolitan department",
      },
      {
        id: 4990,
        name: "Dordogne",
        state_code: "24",
        latitude: "45.14234160",
        longitude: "0.14274080",
        type: "metropolitan department",
      },
      {
        id: 4991,
        name: "Doubs",
        state_code: "25",
        latitude: "46.93217740",
        longitude: "6.34762140",
        type: "metropolitan department",
      },
      {
        id: 4992,
        name: "Drôme",
        state_code: "26",
        latitude: "44.72933570",
        longitude: "4.67821580",
        type: "metropolitan department",
      },
      {
        id: 5059,
        name: "Essonne",
        state_code: "91",
        latitude: "48.53046150",
        longitude: "1.96990560",
        type: "metropolitan department",
      },
      {
        id: 4993,
        name: "Eure",
        state_code: "27",
        latitude: "49.07540350",
        longitude: "0.48937320",
        type: "metropolitan department",
      },
      {
        id: 4994,
        name: "Eure-et-Loir",
        state_code: "28",
        latitude: "48.44697840",
        longitude: "0.81470250",
        type: "metropolitan department",
      },
      {
        id: 4995,
        name: "Finistère",
        state_code: "29",
        latitude: "48.22696100",
        longitude: "-4.82437330",
        type: "metropolitan department",
      },
      {
        id: 4822,
        name: "French Guiana",
        state_code: "973",
        latitude: "3.93388900",
        longitude: "-53.12578200",
        type: "overseas region",
      },
      {
        id: 4824,
        name: "French Polynesia",
        state_code: "PF",
        latitude: "-17.67974200",
        longitude: "-149.40684300",
        type: "overseas collectivity",
      },
      {
        id: 5065,
        name: "French Southern and Antarctic Lands",
        state_code: "TF",
        latitude: "-47.54466040",
        longitude: "51.28375420",
        type: "overseas territory",
      },
      {
        id: 4998,
        name: "Gard",
        state_code: "30",
        latitude: "43.95952760",
        longitude: "3.49356810",
        type: "metropolitan department",
      },
      {
        id: 5000,
        name: "Gers",
        state_code: "32",
        latitude: "43.69505340",
        longitude: "-0.09997280",
        type: "metropolitan department",
      },
      {
        id: 5001,
        name: "Gironde",
        state_code: "33",
        latitude: "44.89584690",
        longitude: "-1.59405320",
        type: "metropolitan department",
      },
      {
        id: 4820,
        name: "Grand-Est",
        state_code: "GES",
        latitude: "48.69980300",
        longitude: "6.18780740",
        type: "metropolitan region",
      },
      {
        id: 4829,
        name: "Guadeloupe",
        state_code: "971",
        latitude: "16.26500000",
        longitude: "-61.55100000",
        type: "overseas region",
      },
      {
        id: 5036,
        name: "Haut-Rhin",
        state_code: "68",
        latitude: "47.86537740",
        longitude: "6.67113810",
        type: "metropolitan department",
      },
      {
        id: 4997,
        name: "Haute-Corse",
        state_code: "2B",
        latitude: "42.42958660",
        longitude: "8.50625610",
        type: "metropolitan department",
      },
      {
        id: 4999,
        name: "Haute-Garonne",
        state_code: "31",
        latitude: "43.30505550",
        longitude: "0.68455150",
        type: "metropolitan department",
      },
      {
        id: 5011,
        name: "Haute-Loire",
        state_code: "43",
        latitude: "45.08538060",
        longitude: "3.22607070",
        type: "metropolitan department",
      },
      {
        id: 5020,
        name: "Haute-Marne",
        state_code: "52",
        latitude: "48.13248210",
        longitude: "4.69834990",
        type: "metropolitan department",
      },
      {
        id: 5039,
        name: "Haute-Saône",
        state_code: "70",
        latitude: "47.63789960",
        longitude: "5.53550550",
        type: "metropolitan department",
      },
      {
        id: 5043,
        name: "Haute-Savoie",
        state_code: "74",
        latitude: "46.04452770",
        longitude: "5.86413800",
        type: "metropolitan department",
      },
      {
        id: 5055,
        name: "Haute-Vienne",
        state_code: "87",
        latitude: "45.91868780",
        longitude: "0.70972060",
        type: "metropolitan department",
      },
      {
        id: 4971,
        name: "Hautes-Alpes",
        state_code: "05",
        latitude: "44.65626820",
        longitude: "5.68732110",
        type: "metropolitan department",
      },
      {
        id: 5033,
        name: "Hautes-Pyrénées",
        state_code: "65",
        latitude: "43.14294620",
        longitude: "-0.40097360",
        type: "metropolitan department",
      },
      {
        id: 4828,
        name: "Hauts-de-France",
        state_code: "HDF",
        latitude: "50.48011530",
        longitude: "2.79372650",
        type: "metropolitan region",
      },
      {
        id: 5060,
        name: "Hauts-de-Seine",
        state_code: "92",
        latitude: "48.84030080",
        longitude: "2.10125590",
        type: "metropolitan department",
      },
      {
        id: 5002,
        name: "Hérault",
        state_code: "34",
        latitude: "43.59111200",
        longitude: "2.80661080",
        type: "metropolitan department",
      },
      {
        id: 4796,
        name: "Île-de-France",
        state_code: "IDF",
        latitude: "48.84991980",
        longitude: "2.63704110",
        type: "metropolitan region",
      },
      {
        id: 5003,
        name: "Ille-et-Vilaine",
        state_code: "35",
        latitude: "48.17624840",
        longitude: "-2.21304010",
        type: "metropolitan department",
      },
      {
        id: 5004,
        name: "Indre",
        state_code: "36",
        latitude: "46.81175500",
        longitude: "0.97555230",
        type: "metropolitan department",
      },
      {
        id: 5005,
        name: "Indre-et-Loire",
        state_code: "37",
        latitude: "47.22285820",
        longitude: "0.14896190",
        type: "metropolitan department",
      },
      {
        id: 5006,
        name: "Isère",
        state_code: "38",
        latitude: "45.28922710",
        longitude: "4.99023550",
        type: "metropolitan department",
      },
      {
        id: 5007,
        name: "Jura",
        state_code: "39",
        latitude: "46.78287410",
        longitude: "5.16918440",
        type: "metropolitan department",
      },
      {
        id: 4823,
        name: "La Réunion",
        state_code: "974",
        latitude: "-21.11514100",
        longitude: "55.53638400",
        type: "overseas region",
      },
      {
        id: 5008,
        name: "Landes",
        state_code: "40",
        latitude: "44.00950800",
        longitude: "-1.25385790",
        type: "metropolitan department",
      },
      {
        id: 5009,
        name: "Loir-et-Cher",
        state_code: "41",
        latitude: "47.65937600",
        longitude: "0.85376310",
        type: "metropolitan department",
      },
      {
        id: 5010,
        name: "Loire",
        state_code: "42",
        latitude: "46.35228120",
        longitude: "-1.17563390",
        type: "metropolitan department",
      },
      {
        id: 5012,
        name: "Loire-Atlantique",
        state_code: "44",
        latitude: "47.34757210",
        longitude: "-2.34663120",
        type: "metropolitan department",
      },
      {
        id: 5013,
        name: "Loiret",
        state_code: "45",
        latitude: "47.91354310",
        longitude: "1.76009900",
        type: "metropolitan department",
      },
      {
        id: 5014,
        name: "Lot",
        state_code: "46",
        latitude: "44.62460700",
        longitude: "1.03576310",
        type: "metropolitan department",
      },
      {
        id: 5015,
        name: "Lot-et-Garonne",
        state_code: "47",
        latitude: "44.36873140",
        longitude: "-0.09161690",
        type: "metropolitan department",
      },
      {
        id: 5016,
        name: "Lozère",
        state_code: "48",
        latitude: "44.54227790",
        longitude: "2.92934590",
        type: "metropolitan department",
      },
      {
        id: 5017,
        name: "Maine-et-Loire",
        state_code: "49",
        latitude: "47.38900340",
        longitude: "-1.12025270",
        type: "metropolitan department",
      },
      {
        id: 5018,
        name: "Manche",
        state_code: "50",
        latitude: "49.08817340",
        longitude: "-2.46272090",
        type: "metropolitan department",
      },
      {
        id: 5019,
        name: "Marne",
        state_code: "51",
        latitude: "48.96107450",
        longitude: "3.65737670",
        type: "metropolitan department",
      },
      {
        id: 4827,
        name: "Martinique",
        state_code: "972",
        latitude: "14.64152800",
        longitude: "-61.02417400",
        type: "overseas region",
      },
      {
        id: 5021,
        name: "Mayenne",
        state_code: "53",
        latitude: "48.30668420",
        longitude: "-0.64901820",
        type: "metropolitan department",
      },
      {
        id: 4797,
        name: "Mayotte",
        state_code: "976",
        latitude: "-12.82750000",
        longitude: "45.16624400",
        type: "overseas region",
      },
      {
        id: 5038,
        name: "Métropole de Lyon",
        state_code: "69M",
        latitude: "45.74826290",
        longitude: "4.59584040",
        type: "metropolitan department",
      },
      {
        id: 5022,
        name: "Meurthe-et-Moselle",
        state_code: "54",
        latitude: "48.95566150",
        longitude: "5.71423500",
        type: "metropolitan department",
      },
      {
        id: 5023,
        name: "Meuse",
        state_code: "55",
        latitude: "49.01246200",
        longitude: "4.81087340",
        type: "metropolitan department",
      },
      {
        id: 5024,
        name: "Morbihan",
        state_code: "56",
        latitude: "47.74395180",
        longitude: "-3.44555240",
        type: "metropolitan department",
      },
      {
        id: 5025,
        name: "Moselle",
        state_code: "57",
        latitude: "49.02045660",
        longitude: "6.20553220",
        type: "metropolitan department",
      },
      {
        id: 5026,
        name: "Nièvre",
        state_code: "58",
        latitude: "47.11921640",
        longitude: "2.97797130",
        type: "metropolitan department",
      },
      {
        id: 5027,
        name: "Nord",
        state_code: "59",
        latitude: "50.52854770",
        longitude: "2.60007760",
        type: "metropolitan department",
      },
      {
        id: 4804,
        name: "Normandie",
        state_code: "NOR",
        latitude: "48.87987040",
        longitude: "0.17125290",
        type: "metropolitan region",
      },
      {
        id: 4795,
        name: "Nouvelle-Aquitaine",
        state_code: "NAQ",
        latitude: "45.70871820",
        longitude: "0.62689100",
        type: "metropolitan region",
      },
      {
        id: 4799,
        name: "Occitanie",
        state_code: "OCC",
        latitude: "43.89272320",
        longitude: "3.28276250",
        type: "metropolitan region",
      },
      {
        id: 5028,
        name: "Oise",
        state_code: "60",
        latitude: "49.41173350",
        longitude: "1.86688250",
        type: "metropolitan department",
      },
      {
        id: 5029,
        name: "Orne",
        state_code: "61",
        latitude: "48.57576440",
        longitude: "-0.50242950",
        type: "metropolitan department",
      },
      {
        id: 4816,
        name: "Paris",
        state_code: "75C",
        latitude: "48.85661400",
        longitude: "2.35222190",
        type: "metropolitan collectivity with special status",
      },
      {
        id: 5030,
        name: "Pas-de-Calais",
        state_code: "62",
        latitude: "50.51446990",
        longitude: "1.81149800",
        type: "metropolitan department",
      },
      {
        id: 4802,
        name: "Pays-de-la-Loire",
        state_code: "PDL",
        latitude: "47.76328360",
        longitude: "-0.32996870",
        type: "metropolitan region",
      },
      {
        id: 4812,
        name: "Provence-Alpes-Côte-d’Azur",
        state_code: "PAC",
        latitude: "43.93516910",
        longitude: "6.06791940",
        type: "metropolitan region",
      },
      {
        id: 5031,
        name: "Puy-de-Dôme",
        state_code: "63",
        latitude: "45.77141850",
        longitude: "2.62626760",
        type: "metropolitan department",
      },
      {
        id: 5032,
        name: "Pyrénées-Atlantiques",
        state_code: "64",
        latitude: "43.18681700",
        longitude: "-1.44170710",
        type: "metropolitan department",
      },
      {
        id: 5034,
        name: "Pyrénées-Orientales",
        state_code: "66",
        latitude: "42.62541790",
        longitude: "1.88929580",
        type: "metropolitan department",
      },
      {
        id: 5037,
        name: "Rhône",
        state_code: "69",
        latitude: "44.93433000",
        longitude: "4.24093290",
        type: "metropolitan department",
      },
      {
        id: 4821,
        name: "Saint Pierre and Miquelon",
        state_code: "PM",
        latitude: "46.88520000",
        longitude: "-56.31590000",
        type: "overseas collectivity",
      },
      {
        id: 4794,
        name: "Saint-Barthélemy",
        state_code: "BL",
        latitude: "17.90051340",
        longitude: "-62.82058710",
        type: "overseas collectivity",
      },
      {
        id: 4809,
        name: "Saint-Martin",
        state_code: "MF",
        latitude: "18.07082980",
        longitude: "-63.05008090",
        type: "overseas collectivity",
      },
      {
        id: 5040,
        name: "Saône-et-Loire",
        state_code: "71",
        latitude: "46.65548830",
        longitude: "3.98350500",
        type: "metropolitan department",
      },
      {
        id: 5041,
        name: "Sarthe",
        state_code: "72",
        latitude: "48.02627330",
        longitude: "-0.32613170",
        type: "metropolitan department",
      },
      {
        id: 5042,
        name: "Savoie",
        state_code: "73",
        latitude: "45.49469900",
        longitude: "5.84329840",
        type: "metropolitan department",
      },
      {
        id: 5045,
        name: "Seine-et-Marne",
        state_code: "77",
        latitude: "48.61853940",
        longitude: "2.41525610",
        type: "metropolitan department",
      },
      {
        id: 5044,
        name: "Seine-Maritime",
        state_code: "76",
        latitude: "49.66096810",
        longitude: "0.36775610",
        type: "metropolitan department",
      },
      {
        id: 5061,
        name: "Seine-Saint-Denis",
        state_code: "93",
        latitude: "48.90993180",
        longitude: "2.30573790",
        type: "metropolitan department",
      },
      {
        id: 5048,
        name: "Somme",
        state_code: "80",
        latitude: "49.96859220",
        longitude: "1.73106960",
        type: "metropolitan department",
      },
      {
        id: 5049,
        name: "Tarn",
        state_code: "81",
        latitude: "43.79149770",
        longitude: "1.67588930",
        type: "metropolitan department",
      },
      {
        id: 5050,
        name: "Tarn-et-Garonne",
        state_code: "82",
        latitude: "44.08089500",
        longitude: "1.08916570",
        type: "metropolitan department",
      },
      {
        id: 5058,
        name: "Territoire de Belfort",
        state_code: "90",
        latitude: "47.62930720",
        longitude: "6.66962000",
        type: "metropolitan department",
      },
      {
        id: 5063,
        name: "Val-d'Oise",
        state_code: "95",
        latitude: "49.07518180",
        longitude: "1.82169140",
        type: "metropolitan department",
      },
      {
        id: 5062,
        name: "Val-de-Marne",
        state_code: "94",
        latitude: "48.77470040",
        longitude: "2.32210390",
        type: "metropolitan department",
      },
      {
        id: 5051,
        name: "Var",
        state_code: "83",
        latitude: "43.39507300",
        longitude: "5.73424170",
        type: "metropolitan department",
      },
      {
        id: 5052,
        name: "Vaucluse",
        state_code: "84",
        latitude: "44.04475000",
        longitude: "4.64277180",
        type: "metropolitan department",
      },
      {
        id: 5053,
        name: "Vendée",
        state_code: "85",
        latitude: "46.67541030",
        longitude: "-2.02983920",
        type: "metropolitan department",
      },
      {
        id: 5054,
        name: "Vienne",
        state_code: "86",
        latitude: "45.52213140",
        longitude: "4.84531360",
        type: "metropolitan department",
      },
      {
        id: 5056,
        name: "Vosges",
        state_code: "88",
        latitude: "48.16301730",
        longitude: "5.73556000",
        type: "metropolitan department",
      },
      {
        id: 4810,
        name: "Wallis and Futuna",
        state_code: "WF",
        latitude: "-14.29380000",
        longitude: "-178.11650000",
        type: "overseas collectivity",
      },
      {
        id: 5057,
        name: "Yonne",
        state_code: "89",
        latitude: "47.85476140",
        longitude: "3.03394040",
        type: "metropolitan department",
      },
      {
        id: 5046,
        name: "Yvelines",
        state_code: "78",
        latitude: "48.76153010",
        longitude: "1.27729490",
        type: "metropolitan department",
      },
    ],
  },
  {
    name: "French Guiana",
    iso3: "GUF",
    iso2: "GF",
    numeric_code: "254",
    phone_code: "594",
    capital: "Cayenne",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gf",
    native: "Guyane française",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "French Guianese",
    timezones: [
      {
        zoneName: "America/Cayenne",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "GFT",
        tzName: "French Guiana Time",
      },
    ],
    translations: {
      kr: "프랑스령 기아나",
      "pt-BR": "Guiana Francesa",
      pt: "Guiana Francesa",
      nl: "Frans-Guyana",
      hr: "Francuska Gvajana",
      fa: "گویان فرانسه",
      de: "Französisch Guyana",
      es: "Guayana Francesa",
      fr: "Guayane",
      ja: "フランス領ギアナ",
      it: "Guyana francese",
      cn: "法属圭亚那",
      tr: "Fransiz Guyanasi",
    },
    latitude: "4.00000000",
    longitude: "-53.00000000",
    emoji: "🇬🇫",
    emojiU: "U+1F1EC U+1F1EB",
    states: [],
  },
  {
    name: "French Polynesia",
    iso3: "PYF",
    iso2: "PF",
    numeric_code: "258",
    phone_code: "689",
    capital: "Papeete",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".pf",
    native: "Polynésie française",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "French Polynesia",
    timezones: [
      {
        zoneName: "Pacific/Gambier",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "GAMT",
        tzName: "Gambier Islands Time",
      },
      {
        zoneName: "Pacific/Marquesas",
        gmtOffset: -34200,
        gmtOffsetName: "UTC-09:30",
        abbreviation: "MART",
        tzName: "Marquesas Islands Time",
      },
      {
        zoneName: "Pacific/Tahiti",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "TAHT",
        tzName: "Tahiti Time",
      },
    ],
    translations: {
      kr: "프랑스령 폴리네시아",
      "pt-BR": "Polinésia Francesa",
      pt: "Polinésia Francesa",
      nl: "Frans-Polynesië",
      hr: "Francuska Polinezija",
      fa: "پلی‌نزی فرانسه",
      de: "Französisch-Polynesien",
      es: "Polinesia Francesa",
      fr: "Polynésie française",
      ja: "フランス領ポリネシア",
      it: "Polinesia Francese",
      cn: "法属波利尼西亚",
      tr: "Fransiz Polinezyasi",
    },
    latitude: "-15.00000000",
    longitude: "-140.00000000",
    emoji: "🇵🇫",
    emojiU: "U+1F1F5 U+1F1EB",
    states: [],
  },
  {
    name: "French Southern Territories",
    iso3: "ATF",
    iso2: "TF",
    numeric_code: "260",
    phone_code: "262",
    capital: "Port-aux-Francais",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".tf",
    native: "Territoire des Terres australes et antarctiques fr",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "French Southern Territories",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time",
      },
    ],
    translations: {
      kr: "프랑스령 남방 및 남극",
      "pt-BR": "Terras Austrais e Antárticas Francesas",
      pt: "Terras Austrais e Antárticas Francesas",
      nl: "Franse Gebieden in de zuidelijke Indische Oceaan",
      hr: "Francuski južni i antarktički teritoriji",
      fa: "سرزمین‌های جنوبی و جنوبگانی فرانسه",
      de: "Französische Süd- und Antarktisgebiete",
      es: "Tierras Australes y Antárticas Francesas",
      fr: "Terres australes et antarctiques françaises",
      ja: "フランス領南方・南極地域",
      it: "Territori Francesi del Sud",
      cn: "法属南部领地",
      tr: "Fransiz Güney Topraklari",
    },
    latitude: "-49.25000000",
    longitude: "69.16700000",
    emoji: "🇹🇫",
    emojiU: "U+1F1F9 U+1F1EB",
    states: [],
  },
  {
    name: "Gabon",
    iso3: "GAB",
    iso2: "GA",
    numeric_code: "266",
    phone_code: "241",
    capital: "Libreville",
    currency: "XAF",
    currency_name: "Central African CFA franc",
    currency_symbol: "FCFA",
    tld: ".ga",
    native: "Gabon",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Gabonese",
    timezones: [
      {
        zoneName: "Africa/Libreville",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "가봉",
      "pt-BR": "Gabão",
      pt: "Gabão",
      nl: "Gabon",
      hr: "Gabon",
      fa: "گابن",
      de: "Gabun",
      es: "Gabón",
      fr: "Gabon",
      ja: "ガボン",
      it: "Gabon",
      cn: "加蓬",
      tr: "Gabon",
    },
    latitude: "-1.00000000",
    longitude: "11.75000000",
    emoji: "🇬🇦",
    emojiU: "U+1F1EC U+1F1E6",
    states: [
      {
        id: 2727,
        name: "Estuaire Province",
        state_code: "1",
        latitude: "0.44328640",
        longitude: "10.08072980",
        type: null,
      },
      {
        id: 2726,
        name: "Haut-Ogooué Province",
        state_code: "2",
        latitude: "-1.47625440",
        longitude: "13.91439900",
        type: null,
      },
      {
        id: 2730,
        name: "Moyen-Ogooué Province",
        state_code: "3",
        latitude: "-0.44278400",
        longitude: "10.43965600",
        type: null,
      },
      {
        id: 2731,
        name: "Ngounié Province",
        state_code: "4",
        latitude: "-1.49303030",
        longitude: "10.98070030",
        type: null,
      },
      {
        id: 2725,
        name: "Nyanga Province",
        state_code: "5",
        latitude: "-2.88210330",
        longitude: "11.16173560",
        type: null,
      },
      {
        id: 2724,
        name: "Ogooué-Ivindo Province",
        state_code: "6",
        latitude: "0.88183110",
        longitude: "13.17403480",
        type: null,
      },
      {
        id: 2729,
        name: "Ogooué-Lolo Province",
        state_code: "7",
        latitude: "-0.88440930",
        longitude: "12.43805810",
        type: null,
      },
      {
        id: 2728,
        name: "Ogooué-Maritime Province",
        state_code: "8",
        latitude: "-1.34659750",
        longitude: "9.72326730",
        type: null,
      },
      {
        id: 2723,
        name: "Woleu-Ntem Province",
        state_code: "9",
        latitude: "2.29898270",
        longitude: "11.44669140",
        type: null,
      },
    ],
  },
  {
    name: "Gambia The",
    iso3: "GMB",
    iso2: "GM",
    numeric_code: "270",
    phone_code: "220",
    capital: "Banjul",
    currency: "GMD",
    currency_name: "Gambian dalasi",
    currency_symbol: "D",
    tld: ".gm",
    native: "Gambia",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Gambian",
    timezones: [
      {
        zoneName: "Africa/Banjul",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "감비아",
      "pt-BR": "Gâmbia",
      pt: "Gâmbia",
      nl: "Gambia",
      hr: "Gambija",
      fa: "گامبیا",
      de: "Gambia",
      es: "Gambia",
      fr: "Gambie",
      ja: "ガンビア",
      it: "Gambia",
      cn: "冈比亚",
      tr: "Gambiya",
    },
    latitude: "13.46666666",
    longitude: "-16.56666666",
    emoji: "🇬🇲",
    emojiU: "U+1F1EC U+1F1F2",
    states: [
      {
        id: 2666,
        name: "Banjul",
        state_code: "B",
        latitude: "13.45487610",
        longitude: "-16.57903230",
        type: null,
      },
      {
        id: 2669,
        name: "Central River Division",
        state_code: "M",
        latitude: "13.59944690",
        longitude: "-14.89216680",
        type: null,
      },
      {
        id: 2670,
        name: "Lower River Division",
        state_code: "L",
        latitude: "13.35533060",
        longitude: "-15.92299000",
        type: null,
      },
      {
        id: 2671,
        name: "North Bank Division",
        state_code: "N",
        latitude: "13.52854360",
        longitude: "-16.01699710",
        type: null,
      },
      {
        id: 2668,
        name: "Upper River Division",
        state_code: "U",
        latitude: "13.42573660",
        longitude: "-14.00723480",
        type: null,
      },
      {
        id: 2667,
        name: "West Coast Division",
        state_code: "W",
        latitude: "5.97727980",
        longitude: "116.07542880",
        type: null,
      },
    ],
  },
  {
    name: "Georgia",
    iso3: "GEO",
    iso2: "GE",
    numeric_code: "268",
    phone_code: "995",
    capital: "Tbilisi",
    currency: "GEL",
    currency_name: "Georgian lari",
    currency_symbol: "ლ",
    tld: ".ge",
    native: "საქართველო",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Georgian",
    timezones: [
      {
        zoneName: "Asia/Tbilisi",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GET",
        tzName: "Georgia Standard Time",
      },
    ],
    translations: {
      kr: "조지아",
      "pt-BR": "Geórgia",
      pt: "Geórgia",
      nl: "Georgië",
      hr: "Gruzija",
      fa: "گرجستان",
      de: "Georgien",
      es: "Georgia",
      fr: "Géorgie",
      ja: "グルジア",
      it: "Georgia",
      cn: "格鲁吉亚",
      tr: "Gürcistan",
    },
    latitude: "42.00000000",
    longitude: "43.50000000",
    emoji: "🇬🇪",
    emojiU: "U+1F1EC U+1F1EA",
    states: [
      {
        id: 901,
        name: "Abkhazia",
        state_code: "AB",
        latitude: "43.00155440",
        longitude: "41.02340700",
        type: null,
      },
      {
        id: 900,
        name: "Adjara",
        state_code: "AJ",
        latitude: "41.60056260",
        longitude: "42.06883830",
        type: null,
      },
      {
        id: 907,
        name: "Guria",
        state_code: "GU",
        latitude: "41.94427360",
        longitude: "42.04580910",
        type: null,
      },
      {
        id: 905,
        name: "Imereti",
        state_code: "IM",
        latitude: "42.23010800",
        longitude: "42.90086640",
        type: null,
      },
      {
        id: 910,
        name: "Kakheti",
        state_code: "KA",
        latitude: "41.64816020",
        longitude: "45.69055540",
        type: null,
      },
      {
        id: 897,
        name: "Khelvachauri Municipality",
        state_code: "29",
        latitude: "41.58019260",
        longitude: "41.66107420",
        type: null,
      },
      {
        id: 904,
        name: "Kvemo Kartli",
        state_code: "KK",
        latitude: "41.47918330",
        longitude: "44.65604510",
        type: null,
      },
      {
        id: 902,
        name: "Mtskheta-Mtianeti",
        state_code: "MM",
        latitude: "42.16821850",
        longitude: "44.65060580",
        type: null,
      },
      {
        id: 909,
        name: "Racha-Lechkhumi and Kvemo Svaneti",
        state_code: "RL",
        latitude: "42.67188730",
        longitude: "43.05628360",
        type: null,
      },
      {
        id: 908,
        name: "Samegrelo-Zemo Svaneti",
        state_code: "SZ",
        latitude: "42.73522470",
        longitude: "42.16893620",
        type: null,
      },
      {
        id: 906,
        name: "Samtskhe-Javakheti",
        state_code: "SJ",
        latitude: "41.54792960",
        longitude: "43.27764000",
        type: null,
      },
      {
        id: 898,
        name: "Senaki Municipality",
        state_code: "50",
        latitude: "42.26963600",
        longitude: "42.06568960",
        type: null,
      },
      {
        id: 903,
        name: "Shida Kartli",
        state_code: "SK",
        latitude: "42.07569440",
        longitude: "43.95404620",
        type: null,
      },
      {
        id: 899,
        name: "Tbilisi",
        state_code: "TB",
        latitude: "41.71513770",
        longitude: "44.82709600",
        type: null,
      },
    ],
  },
  {
    name: "Germany",
    iso3: "DEU",
    iso2: "DE",
    numeric_code: "276",
    phone_code: "49",
    capital: "Berlin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".de",
    native: "Deutschland",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "German",
    timezones: [
      {
        zoneName: "Europe/Berlin",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
      {
        zoneName: "Europe/Busingen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "독일",
      "pt-BR": "Alemanha",
      pt: "Alemanha",
      nl: "Duitsland",
      hr: "Njemačka",
      fa: "آلمان",
      de: "Deutschland",
      es: "Alemania",
      fr: "Allemagne",
      ja: "ドイツ",
      it: "Germania",
      cn: "德国",
      tr: "Almanya",
    },
    latitude: "51.00000000",
    longitude: "9.00000000",
    emoji: "🇩🇪",
    emojiU: "U+1F1E9 U+1F1EA",
    states: [
      {
        id: 3006,
        name: "Baden-Württemberg",
        state_code: "BW",
        latitude: "48.66160370",
        longitude: "9.35013360",
        type: null,
      },
      {
        id: 3009,
        name: "Bavaria",
        state_code: "BY",
        latitude: "48.79044720",
        longitude: "11.49788950",
        type: null,
      },
      {
        id: 3010,
        name: "Berlin",
        state_code: "BE",
        latitude: "52.52000660",
        longitude: "13.40495400",
        type: null,
      },
      {
        id: 3013,
        name: "Brandenburg",
        state_code: "BB",
        latitude: "52.41252870",
        longitude: "12.53164440",
        type: null,
      },
      {
        id: 3014,
        name: "Bremen",
        state_code: "HB",
        latitude: "53.07929620",
        longitude: "8.80169360",
        type: null,
      },
      {
        id: 3016,
        name: "Hamburg",
        state_code: "HH",
        latitude: "53.55108460",
        longitude: "9.99368190",
        type: null,
      },
      {
        id: 3018,
        name: "Hesse",
        state_code: "HE",
        latitude: "50.65205150",
        longitude: "9.16243760",
        type: null,
      },
      {
        id: 3008,
        name: "Lower Saxony",
        state_code: "NI",
        latitude: "52.63670360",
        longitude: "9.84507660",
        type: null,
      },
      {
        id: 3007,
        name: "Mecklenburg-Vorpommern",
        state_code: "MV",
        latitude: "53.61265050",
        longitude: "12.42959530",
        type: null,
      },
      {
        id: 3017,
        name: "North Rhine-Westphalia",
        state_code: "NW",
        latitude: "51.43323670",
        longitude: "7.66159380",
        type: null,
      },
      {
        id: 3019,
        name: "Rhineland-Palatinate",
        state_code: "RP",
        latitude: "50.11834600",
        longitude: "7.30895270",
        type: null,
      },
      {
        id: 3020,
        name: "Saarland",
        state_code: "SL",
        latitude: "49.39642340",
        longitude: "7.02296070",
        type: null,
      },
      {
        id: 3021,
        name: "Saxony",
        state_code: "SN",
        latitude: "51.10454070",
        longitude: "13.20173840",
        type: null,
      },
      {
        id: 3011,
        name: "Saxony-Anhalt",
        state_code: "ST",
        latitude: "51.95026490",
        longitude: "11.69227340",
        type: null,
      },
      {
        id: 3005,
        name: "Schleswig-Holstein",
        state_code: "SH",
        latitude: "54.21936720",
        longitude: "9.69611670",
        type: null,
      },
      {
        id: 3015,
        name: "Thuringia",
        state_code: "TH",
        latitude: "51.01098920",
        longitude: "10.84534600",
        type: null,
      },
    ],
  },
  {
    name: "Ghana",
    iso3: "GHA",
    iso2: "GH",
    numeric_code: "288",
    phone_code: "233",
    capital: "Accra",
    currency: "GHS",
    currency_name: "Ghanaian cedi",
    currency_symbol: "GH₵",
    tld: ".gh",
    native: "Ghana",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Ghanaian",
    timezones: [
      {
        zoneName: "Africa/Accra",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "가나",
      "pt-BR": "Gana",
      pt: "Gana",
      nl: "Ghana",
      hr: "Gana",
      fa: "غنا",
      de: "Ghana",
      es: "Ghana",
      fr: "Ghana",
      ja: "ガーナ",
      it: "Ghana",
      cn: "加纳",
      tr: "Gana",
    },
    latitude: "8.00000000",
    longitude: "-2.00000000",
    emoji: "🇬🇭",
    emojiU: "U+1F1EC U+1F1ED",
    states: [
      {
        id: 53,
        name: "Ahafo",
        state_code: "AF",
        latitude: "7.58213720",
        longitude: "-2.54974630",
        type: "region",
      },
      {
        id: 48,
        name: "Ashanti",
        state_code: "AH",
        latitude: "6.74704360",
        longitude: "-1.52086240",
        type: "region",
      },
      {
        id: 4959,
        name: "Bono",
        state_code: "BO",
        latitude: "7.65000000",
        longitude: "-2.50000000",
        type: "region",
      },
      {
        id: 4958,
        name: "Bono East",
        state_code: "BE",
        latitude: "7.75000000",
        longitude: "-1.05000000",
        type: "region",
      },
      {
        id: 52,
        name: "Central",
        state_code: "CP",
        latitude: "5.50000000",
        longitude: "-1.00000000",
        type: "region",
      },
      {
        id: 50,
        name: "Eastern",
        state_code: "EP",
        latitude: "6.50000000",
        longitude: "-0.50000000",
        type: "region",
      },
      {
        id: 54,
        name: "Greater Accra",
        state_code: "AA",
        latitude: "5.81428360",
        longitude: "0.07467670",
        type: "region",
      },
      {
        id: 4960,
        name: "North East",
        state_code: "NE",
        latitude: "10.51666700",
        longitude: "-0.36666700",
        type: "region",
      },
      {
        id: 51,
        name: "Northern",
        state_code: "NP",
        latitude: "9.50000000",
        longitude: "-1.00000000",
        type: "region",
      },
      {
        id: 4961,
        name: "Oti",
        state_code: "OT",
        latitude: "7.90000000",
        longitude: "0.30000000",
        type: "region",
      },
      {
        id: 4962,
        name: "Savannah",
        state_code: "SV",
        latitude: "9.08333300",
        longitude: "-1.81666700",
        type: "region",
      },
      {
        id: 55,
        name: "Upper East",
        state_code: "UE",
        latitude: "10.70824990",
        longitude: "-0.98206680",
        type: "region",
      },
      {
        id: 57,
        name: "Upper West",
        state_code: "UW",
        latitude: "10.25297570",
        longitude: "-2.14502450",
        type: "region",
      },
      {
        id: 56,
        name: "Volta",
        state_code: "TV",
        latitude: "6.57813730",
        longitude: "0.45023680",
        type: "region",
      },
      {
        id: 49,
        name: "Western",
        state_code: "WP",
        latitude: "5.50000000",
        longitude: "-2.50000000",
        type: "region",
      },
      {
        id: 4963,
        name: "Western North",
        state_code: "WN",
        latitude: "6.30000000",
        longitude: "-2.80000000",
        type: "region",
      },
    ],
  },
  {
    name: "Gibraltar",
    iso3: "GIB",
    iso2: "GI",
    numeric_code: "292",
    phone_code: "350",
    capital: "Gibraltar",
    currency: "GIP",
    currency_name: "Gibraltar pound",
    currency_symbol: "£",
    tld: ".gi",
    native: "Gibraltar",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Gibraltar",
    timezones: [
      {
        zoneName: "Europe/Gibraltar",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "지브롤터",
      "pt-BR": "Gibraltar",
      pt: "Gibraltar",
      nl: "Gibraltar",
      hr: "Gibraltar",
      fa: "جبل‌طارق",
      de: "Gibraltar",
      es: "Gibraltar",
      fr: "Gibraltar",
      ja: "ジブラルタル",
      it: "Gibilterra",
      cn: "直布罗陀",
      tr: "Cebelitarik",
    },
    latitude: "36.13333333",
    longitude: "-5.35000000",
    emoji: "🇬🇮",
    emojiU: "U+1F1EC U+1F1EE",
    states: [],
  },
  {
    name: "Greece",
    iso3: "GRC",
    iso2: "GR",
    numeric_code: "300",
    phone_code: "30",
    capital: "Athens",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gr",
    native: "Ελλάδα",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Greek, Hellenic",
    timezones: [
      {
        zoneName: "Europe/Athens",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "그리스",
      "pt-BR": "Grécia",
      pt: "Grécia",
      nl: "Griekenland",
      hr: "Grčka",
      fa: "یونان",
      de: "Griechenland",
      es: "Grecia",
      fr: "Grèce",
      ja: "ギリシャ",
      it: "Grecia",
      cn: "希腊",
      tr: "Yunanistan",
    },
    latitude: "39.00000000",
    longitude: "22.00000000",
    emoji: "🇬🇷",
    emojiU: "U+1F1EC U+1F1F7",
    states: [
      {
        id: 2116,
        name: "Achaea Regional Unit",
        state_code: "13",
        latitude: "38.11587290",
        longitude: "21.95224910",
        type: null,
      },
      {
        id: 2123,
        name: "Aetolia-Acarnania Regional Unit",
        state_code: "01",
        latitude: "38.70843860",
        longitude: "21.37989280",
        type: null,
      },
      {
        id: 2098,
        name: "Arcadia Prefecture",
        state_code: "12",
        latitude: "37.55578250",
        longitude: "22.33377690",
        type: null,
      },
      {
        id: 2105,
        name: "Argolis Regional Unit",
        state_code: "11",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2122,
        name: "Attica Region",
        state_code: "I",
        latitude: "38.04575680",
        longitude: "23.85847370",
        type: null,
      },
      {
        id: 2126,
        name: "Boeotia Regional Unit",
        state_code: "03",
        latitude: "38.36636640",
        longitude: "23.09650640",
        type: null,
      },
      {
        id: 2128,
        name: "Central Greece Region",
        state_code: "H",
        latitude: "38.60439840",
        longitude: "22.71521310",
        type: null,
      },
      {
        id: 2125,
        name: "Central Macedonia",
        state_code: "B",
        latitude: "40.62117300",
        longitude: "23.19180210",
        type: null,
      },
      {
        id: 2115,
        name: "Chania Regional Unit",
        state_code: "94",
        latitude: "35.51382980",
        longitude: "24.01803670",
        type: null,
      },
      {
        id: 2124,
        name: "Corfu Prefecture",
        state_code: "22",
        latitude: "39.62498380",
        longitude: "19.92234610",
        type: null,
      },
      {
        id: 2129,
        name: "Corinthia Regional Unit",
        state_code: "15",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2109,
        name: "Crete Region",
        state_code: "M",
        latitude: "35.24011700",
        longitude: "24.80926910",
        type: null,
      },
      {
        id: 2130,
        name: "Drama Regional Unit",
        state_code: "52",
        latitude: "41.23400230",
        longitude: "24.23904980",
        type: null,
      },
      {
        id: 2120,
        name: "East Attica Regional Unit",
        state_code: "A2",
        latitude: "38.20540930",
        longitude: "23.85847370",
        type: null,
      },
      {
        id: 2117,
        name: "East Macedonia and Thrace",
        state_code: "A",
        latitude: "41.12951260",
        longitude: "24.88771910",
        type: null,
      },
      {
        id: 2110,
        name: "Epirus Region",
        state_code: "D",
        latitude: "39.57064130",
        longitude: "20.76428430",
        type: null,
      },
      {
        id: 2101,
        name: "Euboea",
        state_code: "04",
        latitude: "38.52360360",
        longitude: "23.85847370",
        type: null,
      },
      {
        id: 2102,
        name: "Grevena Prefecture",
        state_code: "51",
        latitude: "40.08376260",
        longitude: "21.42732990",
        type: null,
      },
      {
        id: 2099,
        name: "Imathia Regional Unit",
        state_code: "53",
        latitude: "40.60600670",
        longitude: "22.14302150",
        type: null,
      },
      {
        id: 2113,
        name: "Ioannina Regional Unit",
        state_code: "33",
        latitude: "39.66502880",
        longitude: "20.85374660",
        type: null,
      },
      {
        id: 2131,
        name: "Ionian Islands Region",
        state_code: "F",
        latitude: "37.96948980",
        longitude: "21.38023720",
        type: null,
      },
      {
        id: 2095,
        name: "Karditsa Regional Unit",
        state_code: "41",
        latitude: "39.36402580",
        longitude: "21.92140490",
        type: null,
      },
      {
        id: 2100,
        name: "Kastoria Regional Unit",
        state_code: "56",
        latitude: "40.51926910",
        longitude: "21.26871710",
        type: null,
      },
      {
        id: 2127,
        name: "Kefalonia Prefecture",
        state_code: "23",
        latitude: "38.17536750",
        longitude: "20.56921790",
        type: null,
      },
      {
        id: 2111,
        name: "Kilkis Regional Unit",
        state_code: "57",
        latitude: "40.99370710",
        longitude: "22.87536740",
        type: null,
      },
      {
        id: 2112,
        name: "Kozani Prefecture",
        state_code: "58",
        latitude: "40.30055860",
        longitude: "21.78877370",
        type: null,
      },
      {
        id: 2106,
        name: "Laconia",
        state_code: "16",
        latitude: "43.52785460",
        longitude: "-71.47035090",
        type: null,
      },
      {
        id: 2132,
        name: "Larissa Prefecture",
        state_code: "42",
        latitude: "39.63902240",
        longitude: "22.41912540",
        type: null,
      },
      {
        id: 2104,
        name: "Lefkada Regional Unit",
        state_code: "24",
        latitude: "38.83336630",
        longitude: "20.70691080",
        type: null,
      },
      {
        id: 2107,
        name: "Pella Regional Unit",
        state_code: "59",
        latitude: "40.91480390",
        longitude: "22.14302150",
        type: null,
      },
      {
        id: 2119,
        name: "Peloponnese Region",
        state_code: "J",
        latitude: "37.50794720",
        longitude: "22.37349000",
        type: null,
      },
      {
        id: 2114,
        name: "Phthiotis Prefecture",
        state_code: "06",
        latitude: "38.99978500",
        longitude: "22.33377690",
        type: null,
      },
      {
        id: 2103,
        name: "Preveza Prefecture",
        state_code: "34",
        latitude: "38.95926490",
        longitude: "20.75171550",
        type: null,
      },
      {
        id: 2121,
        name: "Serres Prefecture",
        state_code: "62",
        latitude: "41.08638540",
        longitude: "23.54838190",
        type: null,
      },
      {
        id: 2118,
        name: "South Aegean",
        state_code: "L",
        latitude: "37.08553020",
        longitude: "25.14892150",
        type: null,
      },
      {
        id: 2097,
        name: "Thessaloniki Regional Unit",
        state_code: "54",
        latitude: "40.64006290",
        longitude: "22.94441910",
        type: null,
      },
      {
        id: 2096,
        name: "West Greece Region",
        state_code: "G",
        latitude: "38.51154960",
        longitude: "21.57067860",
        type: null,
      },
      {
        id: 2108,
        name: "West Macedonia Region",
        state_code: "C",
        latitude: "40.30040580",
        longitude: "21.79035590",
        type: null,
      },
    ],
  },
  {
    name: "Greenland",
    iso3: "GRL",
    iso2: "GL",
    numeric_code: "304",
    phone_code: "299",
    capital: "Nuuk",
    currency: "DKK",
    currency_name: "Danish krone",
    currency_symbol: "Kr.",
    tld: ".gl",
    native: "Kalaallit Nunaat",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Greenlandic",
    timezones: [
      {
        zoneName: "America/Danmarkshavn",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
      {
        zoneName: "America/Nuuk",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "WGT",
        tzName: "West Greenland Time",
      },
      {
        zoneName: "America/Scoresbysund",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "EGT",
        tzName: "Eastern Greenland Time",
      },
      {
        zoneName: "America/Thule",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "그린란드",
      "pt-BR": "Groelândia",
      pt: "Gronelândia",
      nl: "Groenland",
      hr: "Grenland",
      fa: "گرینلند",
      de: "Grönland",
      es: "Groenlandia",
      fr: "Groenland",
      ja: "グリーンランド",
      it: "Groenlandia",
      cn: "格陵兰岛",
      tr: "Grönland",
    },
    latitude: "72.00000000",
    longitude: "-40.00000000",
    emoji: "🇬🇱",
    emojiU: "U+1F1EC U+1F1F1",
    states: [],
  },
  {
    name: "Grenada",
    iso3: "GRD",
    iso2: "GD",
    numeric_code: "308",
    phone_code: "+1-473",
    capital: "St. George's",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".gd",
    native: "Grenada",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Grenadian",
    timezones: [
      {
        zoneName: "America/Grenada",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "그레나다",
      "pt-BR": "Granada",
      pt: "Granada",
      nl: "Grenada",
      hr: "Grenada",
      fa: "گرنادا",
      de: "Grenada",
      es: "Grenada",
      fr: "Grenade",
      ja: "グレナダ",
      it: "Grenada",
      cn: "格林纳达",
      tr: "Grenada",
    },
    latitude: "12.11666666",
    longitude: "-61.66666666",
    emoji: "🇬🇩",
    emojiU: "U+1F1EC U+1F1E9",
    states: [
      {
        id: 3867,
        name: "Carriacou and Petite Martinique",
        state_code: "10",
        latitude: "12.47858880",
        longitude: "-61.44938420",
        type: null,
      },
      {
        id: 3865,
        name: "Saint Andrew Parish",
        state_code: "01",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3869,
        name: "Saint David Parish",
        state_code: "02",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3864,
        name: "Saint George Parish",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3868,
        name: "Saint John Parish",
        state_code: "04",
        latitude: "30.11183310",
        longitude: "-90.48799160",
        type: null,
      },
      {
        id: 3866,
        name: "Saint Mark Parish",
        state_code: "05",
        latitude: "40.58818630",
        longitude: "-73.94957010",
        type: null,
      },
      {
        id: 3863,
        name: "Saint Patrick Parish",
        state_code: "06",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Guadeloupe",
    iso3: "GLP",
    iso2: "GP",
    numeric_code: "312",
    phone_code: "590",
    capital: "Basse-Terre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".gp",
    native: "Guadeloupe",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Guadeloupe",
    timezones: [
      {
        zoneName: "America/Guadeloupe",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "과들루프",
      "pt-BR": "Guadalupe",
      pt: "Guadalupe",
      nl: "Guadeloupe",
      hr: "Gvadalupa",
      fa: "جزیره گوادلوپ",
      de: "Guadeloupe",
      es: "Guadalupe",
      fr: "Guadeloupe",
      ja: "グアドループ",
      it: "Guadeloupa",
      cn: "瓜德罗普岛",
      tr: "Guadeloupe",
    },
    latitude: "16.25000000",
    longitude: "-61.58333300",
    emoji: "🇬🇵",
    emojiU: "U+1F1EC U+1F1F5",
    states: [],
  },
  {
    name: "Guam",
    iso3: "GUM",
    iso2: "GU",
    numeric_code: "316",
    phone_code: "+1-671",
    capital: "Hagatna",
    currency: "USD",
    currency_name: "US Dollar",
    currency_symbol: "$",
    tld: ".gu",
    native: "Guam",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Guamanian, Guambat",
    timezones: [
      {
        zoneName: "Pacific/Guam",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHST",
        tzName: "Chamorro Standard Time",
      },
    ],
    translations: {
      kr: "괌",
      "pt-BR": "Guam",
      pt: "Guame",
      nl: "Guam",
      hr: "Guam",
      fa: "گوام",
      de: "Guam",
      es: "Guam",
      fr: "Guam",
      ja: "グアム",
      it: "Guam",
      cn: "关岛",
      tr: "Guam",
    },
    latitude: "13.46666666",
    longitude: "144.78333333",
    emoji: "🇬🇺",
    emojiU: "U+1F1EC U+1F1FA",
    states: [],
  },
  {
    name: "Guatemala",
    iso3: "GTM",
    iso2: "GT",
    numeric_code: "320",
    phone_code: "502",
    capital: "Guatemala City",
    currency: "GTQ",
    currency_name: "Guatemalan quetzal",
    currency_symbol: "Q",
    tld: ".gt",
    native: "Guatemala",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Guatemalan",
    timezones: [
      {
        zoneName: "America/Guatemala",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "과테말라",
      "pt-BR": "Guatemala",
      pt: "Guatemala",
      nl: "Guatemala",
      hr: "Gvatemala",
      fa: "گواتمالا",
      de: "Guatemala",
      es: "Guatemala",
      fr: "Guatemala",
      ja: "グアテマラ",
      it: "Guatemala",
      cn: "危地马拉",
      tr: "Guatemala",
    },
    latitude: "15.50000000",
    longitude: "-90.25000000",
    emoji: "🇬🇹",
    emojiU: "U+1F1EC U+1F1F9",
    states: [
      {
        id: 3671,
        name: "Alta Verapaz Department",
        state_code: "AV",
        latitude: "15.59428830",
        longitude: "-90.14949880",
        type: null,
      },
      {
        id: 3674,
        name: "Baja Verapaz Department",
        state_code: "BV",
        latitude: "15.12558670",
        longitude: "-90.37483540",
        type: null,
      },
      {
        id: 3675,
        name: "Chimaltenango Department",
        state_code: "CM",
        latitude: "14.56347870",
        longitude: "-90.98206680",
        type: null,
      },
      {
        id: 3666,
        name: "Chiquimula Department",
        state_code: "CQ",
        latitude: "14.75149990",
        longitude: "-89.47421770",
        type: null,
      },
      {
        id: 3662,
        name: "El Progreso Department",
        state_code: "PR",
        latitude: "14.93887320",
        longitude: "-90.07467670",
        type: null,
      },
      {
        id: 3677,
        name: "Escuintla Department",
        state_code: "ES",
        latitude: "14.19109120",
        longitude: "-90.98206680",
        type: null,
      },
      {
        id: 3672,
        name: "Guatemala Department",
        state_code: "GU",
        latitude: "14.56494010",
        longitude: "-90.52578230",
        type: null,
      },
      {
        id: 3670,
        name: "Huehuetenango Department",
        state_code: "HU",
        latitude: "15.58799140",
        longitude: "-91.67606910",
        type: null,
      },
      {
        id: 3659,
        name: "Izabal Department",
        state_code: "IZ",
        latitude: "15.49765170",
        longitude: "-88.86469800",
        type: null,
      },
      {
        id: 3658,
        name: "Jalapa Department",
        state_code: "JA",
        latitude: "14.61214460",
        longitude: "-89.96267990",
        type: null,
      },
      {
        id: 3673,
        name: "Jutiapa Department",
        state_code: "JU",
        latitude: "14.19308020",
        longitude: "-89.92532330",
        type: null,
      },
      {
        id: 3669,
        name: "Petén Department",
        state_code: "PE",
        latitude: "16.91203300",
        longitude: "-90.29957850",
        type: null,
      },
      {
        id: 3668,
        name: "Quetzaltenango Department",
        state_code: "QZ",
        latitude: "14.79243300",
        longitude: "-91.71495800",
        type: null,
      },
      {
        id: 3657,
        name: "Quiché Department",
        state_code: "QC",
        latitude: "15.49838080",
        longitude: "-90.98206680",
        type: null,
      },
      {
        id: 3664,
        name: "Retalhuleu Department",
        state_code: "RE",
        latitude: "14.52454850",
        longitude: "-91.68578800",
        type: null,
      },
      {
        id: 3676,
        name: "Sacatepéquez Department",
        state_code: "SA",
        latitude: "14.51783790",
        longitude: "-90.71527490",
        type: null,
      },
      {
        id: 3667,
        name: "San Marcos Department",
        state_code: "SM",
        latitude: "14.93095690",
        longitude: "-91.90992380",
        type: null,
      },
      {
        id: 3665,
        name: "Santa Rosa Department",
        state_code: "SR",
        latitude: "38.44057590",
        longitude: "-122.70375430",
        type: null,
      },
      {
        id: 3661,
        name: "Sololá Department",
        state_code: "SO",
        latitude: "14.74852300",
        longitude: "-91.28910360",
        type: null,
      },
      {
        id: 3660,
        name: "Suchitepéquez Department",
        state_code: "SU",
        latitude: "14.42159820",
        longitude: "-91.40482490",
        type: null,
      },
      {
        id: 3663,
        name: "Totonicapán Department",
        state_code: "TO",
        latitude: "14.91734020",
        longitude: "-91.36139230",
        type: null,
      },
    ],
  },
  {
    name: "Guernsey and Alderney",
    iso3: "GGY",
    iso2: "GG",
    numeric_code: "831",
    phone_code: "+44-1481",
    capital: "St Peter Port",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".gg",
    native: "Guernsey",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Channel Island",
    timezones: [
      {
        zoneName: "Europe/Guernsey",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "건지, 올더니",
      "pt-BR": "Guernsey",
      pt: "Guernsey",
      nl: "Guernsey",
      hr: "Guernsey",
      fa: "گرنزی",
      de: "Guernsey",
      es: "Guernsey",
      fr: "Guernesey",
      ja: "ガーンジー",
      it: "Guernsey",
      cn: "根西岛",
      tr: "Alderney",
    },
    latitude: "49.46666666",
    longitude: "-2.58333333",
    emoji: "🇬🇬",
    emojiU: "U+1F1EC U+1F1EC",
    states: [],
  },
  {
    name: "Guinea",
    iso3: "GIN",
    iso2: "GN",
    numeric_code: "324",
    phone_code: "224",
    capital: "Conakry",
    currency: "GNF",
    currency_name: "Guinean franc",
    currency_symbol: "FG",
    tld: ".gn",
    native: "Guinée",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Guinean",
    timezones: [
      {
        zoneName: "Africa/Conakry",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "기니",
      "pt-BR": "Guiné",
      pt: "Guiné",
      nl: "Guinee",
      hr: "Gvineja",
      fa: "گینه",
      de: "Guinea",
      es: "Guinea",
      fr: "Guinée",
      ja: "ギニア",
      it: "Guinea",
      cn: "几内亚",
      tr: "Gine",
    },
    latitude: "11.00000000",
    longitude: "-10.00000000",
    emoji: "🇬🇳",
    emojiU: "U+1F1EC U+1F1F3",
    states: [
      {
        id: 2672,
        name: "Beyla Prefecture",
        state_code: "BE",
        latitude: "8.91981780",
        longitude: "-8.30884410",
        type: null,
      },
      {
        id: 2699,
        name: "Boffa Prefecture",
        state_code: "BF",
        latitude: "10.18082540",
        longitude: "-14.03916150",
        type: null,
      },
      {
        id: 2709,
        name: "Boké Prefecture",
        state_code: "BK",
        latitude: "11.08473790",
        longitude: "-14.37919120",
        type: null,
      },
      {
        id: 2676,
        name: "Boké Region",
        state_code: "B",
        latitude: "11.18646720",
        longitude: "-14.10013260",
        type: null,
      },
      {
        id: 2686,
        name: "Conakry",
        state_code: "C",
        latitude: "9.64118550",
        longitude: "-13.57840120",
        type: null,
      },
      {
        id: 2705,
        name: "Coyah Prefecture",
        state_code: "CO",
        latitude: "9.77155350",
        longitude: "-13.31252990",
        type: null,
      },
      {
        id: 2679,
        name: "Dabola Prefecture",
        state_code: "DB",
        latitude: "10.72978060",
        longitude: "-11.11078540",
        type: null,
      },
      {
        id: 2706,
        name: "Dalaba Prefecture",
        state_code: "DL",
        latitude: "10.68681760",
        longitude: "-12.24906970",
        type: null,
      },
      {
        id: 2688,
        name: "Dinguiraye Prefecture",
        state_code: "DI",
        latitude: "11.68442220",
        longitude: "-10.80000510",
        type: null,
      },
      {
        id: 2681,
        name: "Dubréka Prefecture",
        state_code: "DU",
        latitude: "9.79073480",
        longitude: "-13.51477350",
        type: null,
      },
      {
        id: 2682,
        name: "Faranah Prefecture",
        state_code: "FA",
        latitude: "9.90573990",
        longitude: "-10.80000510",
        type: null,
      },
      {
        id: 2683,
        name: "Forécariah Prefecture",
        state_code: "FO",
        latitude: "9.38861870",
        longitude: "-13.08179030",
        type: null,
      },
      {
        id: 2675,
        name: "Fria Prefecture",
        state_code: "FR",
        latitude: "10.36745430",
        longitude: "-13.58418710",
        type: null,
      },
      {
        id: 2685,
        name: "Gaoual Prefecture",
        state_code: "GA",
        latitude: "11.57628040",
        longitude: "-13.35872880",
        type: null,
      },
      {
        id: 2711,
        name: "Guéckédou Prefecture",
        state_code: "GU",
        latitude: "8.56496880",
        longitude: "-10.13111630",
        type: null,
      },
      {
        id: 2704,
        name: "Kankan Prefecture",
        state_code: "KA",
        latitude: "10.30344650",
        longitude: "-9.36730840",
        type: null,
      },
      {
        id: 2697,
        name: "Kankan Region",
        state_code: "K",
        latitude: "10.12092300",
        longitude: "-9.54509740",
        type: null,
      },
      {
        id: 2710,
        name: "Kérouané Prefecture",
        state_code: "KE",
        latitude: "9.25366430",
        longitude: "-9.01289260",
        type: null,
      },
      {
        id: 2693,
        name: "Kindia Prefecture",
        state_code: "KD",
        latitude: "10.10132920",
        longitude: "-12.71351210",
        type: null,
      },
      {
        id: 2701,
        name: "Kindia Region",
        state_code: "D",
        latitude: "10.17816940",
        longitude: "-12.98961500",
        type: null,
      },
      {
        id: 2691,
        name: "Kissidougou Prefecture",
        state_code: "KS",
        latitude: "9.22520220",
        longitude: "-10.08072980",
        type: null,
      },
      {
        id: 2692,
        name: "Koubia Prefecture",
        state_code: "KB",
        latitude: "11.58235400",
        longitude: "-11.89202370",
        type: null,
      },
      {
        id: 2703,
        name: "Koundara Prefecture",
        state_code: "KN",
        latitude: "12.48940210",
        longitude: "-13.30675620",
        type: null,
      },
      {
        id: 2695,
        name: "Kouroussa Prefecture",
        state_code: "KO",
        latitude: "10.64892290",
        longitude: "-9.88505860",
        type: null,
      },
      {
        id: 2680,
        name: "Labé Prefecture",
        state_code: "LA",
        latitude: "11.35419390",
        longitude: "-12.34638750",
        type: null,
      },
      {
        id: 2677,
        name: "Labé Region",
        state_code: "L",
        latitude: "11.32320420",
        longitude: "-12.28913140",
        type: null,
      },
      {
        id: 2690,
        name: "Lélouma Prefecture",
        state_code: "LE",
        latitude: "11.18333300",
        longitude: "-12.93333300",
        type: null,
      },
      {
        id: 2708,
        name: "Lola Prefecture",
        state_code: "LO",
        latitude: "7.96138180",
        longitude: "-8.39649380",
        type: null,
      },
      {
        id: 2702,
        name: "Macenta Prefecture",
        state_code: "MC",
        latitude: "8.46157950",
        longitude: "-9.27855830",
        type: null,
      },
      {
        id: 2700,
        name: "Mali Prefecture",
        state_code: "ML",
        latitude: "11.98370900",
        longitude: "-12.25479190",
        type: null,
      },
      {
        id: 2689,
        name: "Mamou Prefecture",
        state_code: "MM",
        latitude: "10.57360240",
        longitude: "-11.88917210",
        type: null,
      },
      {
        id: 2698,
        name: "Mamou Region",
        state_code: "M",
        latitude: "10.57360240",
        longitude: "-11.88917210",
        type: null,
      },
      {
        id: 2673,
        name: "Mandiana Prefecture",
        state_code: "MD",
        latitude: "10.61728270",
        longitude: "-8.69857160",
        type: null,
      },
      {
        id: 2678,
        name: "Nzérékoré Prefecture",
        state_code: "NZ",
        latitude: "7.74783590",
        longitude: "-8.82525020",
        type: null,
      },
      {
        id: 2684,
        name: "Nzérékoré Region",
        state_code: "N",
        latitude: "8.03858700",
        longitude: "-8.83627550",
        type: null,
      },
      {
        id: 2694,
        name: "Pita Prefecture",
        state_code: "PI",
        latitude: "10.80620860",
        longitude: "-12.71351210",
        type: null,
      },
      {
        id: 2707,
        name: "Siguiri Prefecture",
        state_code: "SI",
        latitude: "11.41481130",
        longitude: "-9.17883040",
        type: null,
      },
      {
        id: 2687,
        name: "Télimélé Prefecture",
        state_code: "TE",
        latitude: "10.90893640",
        longitude: "-13.02993310",
        type: null,
      },
      {
        id: 2696,
        name: "Tougué Prefecture",
        state_code: "TO",
        latitude: "11.38415830",
        longitude: "-11.61577730",
        type: null,
      },
      {
        id: 2674,
        name: "Yomou Prefecture",
        state_code: "YO",
        latitude: "7.56962790",
        longitude: "-9.25915710",
        type: null,
      },
    ],
  },
  {
    name: "Guinea-Bissau",
    iso3: "GNB",
    iso2: "GW",
    numeric_code: "624",
    phone_code: "245",
    capital: "Bissau",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".gw",
    native: "Guiné-Bissau",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Bissau-Guinean",
    timezones: [
      {
        zoneName: "Africa/Bissau",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "기니비사우",
      "pt-BR": "Guiné-Bissau",
      pt: "Guiné-Bissau",
      nl: "Guinee-Bissau",
      hr: "Gvineja Bisau",
      fa: "گینه بیسائو",
      de: "Guinea-Bissau",
      es: "Guinea-Bisáu",
      fr: "Guinée-Bissau",
      ja: "ギニアビサウ",
      it: "Guinea-Bissau",
      cn: "几内亚比绍",
      tr: "Gine-bissau",
    },
    latitude: "12.00000000",
    longitude: "-15.00000000",
    emoji: "🇬🇼",
    emojiU: "U+1F1EC U+1F1FC",
    states: [
      {
        id: 2720,
        name: "Bafatá",
        state_code: "BA",
        latitude: "12.17352430",
        longitude: "-14.65295200",
        type: null,
      },
      {
        id: 2714,
        name: "Biombo Region",
        state_code: "BM",
        latitude: "11.85290610",
        longitude: "-15.73511710",
        type: null,
      },
      {
        id: 2722,
        name: "Bolama Region",
        state_code: "BL",
        latitude: "11.14805910",
        longitude: "-16.13457050",
        type: null,
      },
      {
        id: 2713,
        name: "Cacheu Region",
        state_code: "CA",
        latitude: "12.05514160",
        longitude: "-16.06401790",
        type: null,
      },
      {
        id: 2719,
        name: "Gabú Region",
        state_code: "GA",
        latitude: "11.89624880",
        longitude: "-14.10013260",
        type: null,
      },
      {
        id: 2721,
        name: "Leste Province",
        state_code: "L",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2717,
        name: "Norte Province",
        state_code: "N",
        latitude: "7.87218110",
        longitude: "123.88577470",
        type: null,
      },
      {
        id: 2718,
        name: "Oio Region",
        state_code: "OI",
        latitude: "12.27607090",
        longitude: "-15.31311850",
        type: null,
      },
      {
        id: 2715,
        name: "Quinara Region",
        state_code: "QU",
        latitude: "11.79556200",
        longitude: "-15.17268160",
        type: null,
      },
      {
        id: 2716,
        name: "Sul Province",
        state_code: "S",
        latitude: "-10.28665780",
        longitude: "20.71224650",
        type: null,
      },
      {
        id: 2712,
        name: "Tombali Region",
        state_code: "TO",
        latitude: "11.36326960",
        longitude: "-14.98561760",
        type: null,
      },
    ],
  },
  {
    name: "Guyana",
    iso3: "GUY",
    iso2: "GY",
    numeric_code: "328",
    phone_code: "592",
    capital: "Georgetown",
    currency: "GYD",
    currency_name: "Guyanese dollar",
    currency_symbol: "$",
    tld: ".gy",
    native: "Guyana",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Guyanese",
    timezones: [
      {
        zoneName: "America/Guyana",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "GYT",
        tzName: "Guyana Time",
      },
    ],
    translations: {
      kr: "가이아나",
      "pt-BR": "Guiana",
      pt: "Guiana",
      nl: "Guyana",
      hr: "Gvajana",
      fa: "گویان",
      de: "Guyana",
      es: "Guyana",
      fr: "Guyane",
      ja: "ガイアナ",
      it: "Guyana",
      cn: "圭亚那",
      tr: "Guyana",
    },
    latitude: "5.00000000",
    longitude: "-59.00000000",
    emoji: "🇬🇾",
    emojiU: "U+1F1EC U+1F1FE",
    states: [
      {
        id: 2764,
        name: "Barima-Waini",
        state_code: "BA",
        latitude: "7.48824190",
        longitude: "-59.65644940",
        type: null,
      },
      {
        id: 2760,
        name: "Cuyuni-Mazaruni",
        state_code: "CU",
        latitude: "6.46421410",
        longitude: "-60.21107520",
        type: null,
      },
      {
        id: 2767,
        name: "Demerara-Mahaica",
        state_code: "DE",
        latitude: "6.54642600",
        longitude: "-58.09820460",
        type: null,
      },
      {
        id: 2766,
        name: "East Berbice-Corentyne",
        state_code: "EB",
        latitude: "2.74779220",
        longitude: "-57.46272590",
        type: null,
      },
      {
        id: 2768,
        name: "Essequibo Islands-West Demerara",
        state_code: "ES",
        latitude: "6.57201320",
        longitude: "-58.46299970",
        type: null,
      },
      {
        id: 2762,
        name: "Mahaica-Berbice",
        state_code: "MA",
        latitude: "6.23849600",
        longitude: "-57.91625550",
        type: null,
      },
      {
        id: 2765,
        name: "Pomeroon-Supenaam",
        state_code: "PM",
        latitude: "7.12941660",
        longitude: "-58.92062950",
        type: null,
      },
      {
        id: 2761,
        name: "Potaro-Siparuni",
        state_code: "PT",
        latitude: "4.78558530",
        longitude: "-59.28799770",
        type: null,
      },
      {
        id: 2763,
        name: "Upper Demerara-Berbice",
        state_code: "UD",
        latitude: "5.30648790",
        longitude: "-58.18929210",
        type: null,
      },
      {
        id: 2769,
        name: "Upper Takutu-Upper Essequibo",
        state_code: "UT",
        latitude: "2.92395950",
        longitude: "-58.73736340",
        type: null,
      },
    ],
  },
  {
    name: "Haiti",
    iso3: "HTI",
    iso2: "HT",
    numeric_code: "332",
    phone_code: "509",
    capital: "Port-au-Prince",
    currency: "HTG",
    currency_name: "Haitian gourde",
    currency_symbol: "G",
    tld: ".ht",
    native: "Haïti",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Haitian",
    timezones: [
      {
        zoneName: "America/Port-au-Prince",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "아이티",
      "pt-BR": "Haiti",
      pt: "Haiti",
      nl: "Haïti",
      hr: "Haiti",
      fa: "هائیتی",
      de: "Haiti",
      es: "Haiti",
      fr: "Haïti",
      ja: "ハイチ",
      it: "Haiti",
      cn: "海地",
      tr: "Haiti",
    },
    latitude: "19.00000000",
    longitude: "-72.41666666",
    emoji: "🇭🇹",
    emojiU: "U+1F1ED U+1F1F9",
    states: [
      {
        id: 4123,
        name: "Artibonite",
        state_code: "AR",
        latitude: "19.36290200",
        longitude: "-72.42581450",
        type: null,
      },
      {
        id: 4125,
        name: "Centre",
        state_code: "CE",
        latitude: "32.83702510",
        longitude: "-96.77738820",
        type: null,
      },
      {
        id: 4119,
        name: "Grand'Anse",
        state_code: "GA",
        latitude: "12.01666670",
        longitude: "-61.76666670",
        type: null,
      },
      {
        id: 4118,
        name: "Nippes",
        state_code: "NI",
        latitude: "18.39907350",
        longitude: "-73.41802110",
        type: null,
      },
      {
        id: 4117,
        name: "Nord",
        state_code: "ND",
        latitude: "43.19052600",
        longitude: "-89.43792100",
        type: null,
      },
      {
        id: 4121,
        name: "Nord-Est",
        state_code: "NE",
        latitude: "19.48897230",
        longitude: "-71.85713310",
        type: null,
      },
      {
        id: 4126,
        name: "Nord-Ouest",
        state_code: "NO",
        latitude: "19.83740090",
        longitude: "-73.04052770",
        type: null,
      },
      {
        id: 4120,
        name: "Ouest",
        state_code: "OU",
        latitude: "45.45472490",
        longitude: "-73.65023650",
        type: null,
      },
      {
        id: 4122,
        name: "Sud",
        state_code: "SD",
        latitude: "29.92132480",
        longitude: "-90.09737720",
        type: null,
      },
      {
        id: 4124,
        name: "Sud-Est",
        state_code: "SE",
        latitude: "18.27835980",
        longitude: "-72.35479150",
        type: null,
      },
    ],
  },
  {
    name: "Heard Island and McDonald Islands",
    iso3: "HMD",
    iso2: "HM",
    numeric_code: "334",
    phone_code: "672",
    capital: "",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".hm",
    native: "Heard Island and McDonald Islands",
    region: "",
    region_id: null,
    subregion: "",
    subregion_id: null,
    nationality: "Heard Island or McDonald Islands",
    timezones: [
      {
        zoneName: "Indian/Kerguelen",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TFT",
        tzName: "French Southern and Antarctic Time",
      },
    ],
    translations: {
      kr: "허드 맥도날드 제도",
      "pt-BR": "Ilha Heard e Ilhas McDonald",
      pt: "Ilha Heard e Ilhas McDonald",
      nl: "Heard- en McDonaldeilanden",
      hr: "Otok Heard i otočje McDonald",
      fa: "جزیره هرد و جزایر مک‌دونالد",
      de: "Heard und die McDonaldinseln",
      es: "Islas Heard y McDonald",
      fr: "Îles Heard-et-MacDonald",
      ja: "ハード島とマクドナルド諸島",
      it: "Isole Heard e McDonald",
      cn: "赫德·唐纳岛及麦唐纳岛",
      tr: "Heard Adasi Ve Mcdonald Adalari",
    },
    latitude: "-53.10000000",
    longitude: "72.51666666",
    emoji: "🇭🇲",
    emojiU: "U+1F1ED U+1F1F2",
    states: [],
  },
  {
    name: "Honduras",
    iso3: "HND",
    iso2: "HN",
    numeric_code: "340",
    phone_code: "504",
    capital: "Tegucigalpa",
    currency: "HNL",
    currency_name: "Honduran lempira",
    currency_symbol: "L",
    tld: ".hn",
    native: "Honduras",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Honduran",
    timezones: [
      {
        zoneName: "America/Tegucigalpa",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "온두라스",
      "pt-BR": "Honduras",
      pt: "Honduras",
      nl: "Honduras",
      hr: "Honduras",
      fa: "هندوراس",
      de: "Honduras",
      es: "Honduras",
      fr: "Honduras",
      ja: "ホンジュラス",
      it: "Honduras",
      cn: "洪都拉斯",
      tr: "Honduras",
    },
    latitude: "15.00000000",
    longitude: "-86.50000000",
    emoji: "🇭🇳",
    emojiU: "U+1F1ED U+1F1F3",
    states: [
      {
        id: 4047,
        name: "Atlántida Department",
        state_code: "AT",
        latitude: "15.66962830",
        longitude: "-87.14228950",
        type: null,
      },
      {
        id: 4045,
        name: "Bay Islands Department",
        state_code: "IB",
        latitude: "16.48266140",
        longitude: "-85.87932520",
        type: null,
      },
      {
        id: 4041,
        name: "Choluteca Department",
        state_code: "CH",
        latitude: "13.25043250",
        longitude: "-87.14228950",
        type: null,
      },
      {
        id: 4051,
        name: "Colón Department",
        state_code: "CL",
        latitude: "15.64259650",
        longitude: "-85.52002400",
        type: null,
      },
      {
        id: 4042,
        name: "Comayagua Department",
        state_code: "CM",
        latitude: "14.55348280",
        longitude: "-87.61863790",
        type: null,
      },
      {
        id: 4049,
        name: "Copán Department",
        state_code: "CP",
        latitude: "14.93608380",
        longitude: "-88.86469800",
        type: null,
      },
      {
        id: 4046,
        name: "Cortés Department",
        state_code: "CR",
        latitude: "15.49235080",
        longitude: "-88.09007620",
        type: null,
      },
      {
        id: 4043,
        name: "El Paraíso Department",
        state_code: "EP",
        latitude: "13.98212940",
        longitude: "-86.49965460",
        type: null,
      },
      {
        id: 4052,
        name: "Francisco Morazán Department",
        state_code: "FM",
        latitude: "14.45411000",
        longitude: "-87.06242610",
        type: null,
      },
      {
        id: 4048,
        name: "Gracias a Dios Department",
        state_code: "GD",
        latitude: "15.34180600",
        longitude: "-84.60604490",
        type: null,
      },
      {
        id: 4044,
        name: "Intibucá Department",
        state_code: "IN",
        latitude: "14.37273400",
        longitude: "-88.24611830",
        type: null,
      },
      {
        id: 4058,
        name: "La Paz Department",
        state_code: "LP",
        latitude: "-15.08924160",
        longitude: "-68.52471490",
        type: null,
      },
      {
        id: 4054,
        name: "Lempira Department",
        state_code: "LE",
        latitude: "14.18876980",
        longitude: "-88.55653100",
        type: null,
      },
      {
        id: 4056,
        name: "Ocotepeque Department",
        state_code: "OC",
        latitude: "14.51703470",
        longitude: "-89.05615320",
        type: null,
      },
      {
        id: 4050,
        name: "Olancho Department",
        state_code: "OL",
        latitude: "14.80674060",
        longitude: "-85.76666450",
        type: null,
      },
      {
        id: 4053,
        name: "Santa Bárbara Department",
        state_code: "SB",
        latitude: "15.12027950",
        longitude: "-88.40160410",
        type: null,
      },
      {
        id: 4055,
        name: "Valle Department",
        state_code: "VA",
        latitude: "13.57829360",
        longitude: "-87.57912870",
        type: null,
      },
      {
        id: 4057,
        name: "Yoro Department",
        state_code: "YO",
        latitude: "15.29496790",
        longitude: "-87.14228950",
        type: null,
      },
    ],
  },
  {
    name: "Hong Kong S.A.R.",
    iso3: "HKG",
    iso2: "HK",
    numeric_code: "344",
    phone_code: "852",
    capital: "Hong Kong",
    currency: "HKD",
    currency_name: "Hong Kong dollar",
    currency_symbol: "$",
    tld: ".hk",
    native: "香港",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Hong Kong, Hong Kongese",
    timezones: [
      {
        zoneName: "Asia/Hong_Kong",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "HKT",
        tzName: "Hong Kong Time",
      },
    ],
    translations: {
      kr: "홍콩",
      "pt-BR": "Hong Kong",
      pt: "Hong Kong",
      nl: "Hongkong",
      hr: "Hong Kong",
      fa: "هنگ‌کنگ",
      de: "Hong Kong",
      es: "Hong Kong",
      fr: "Hong Kong",
      ja: "香港",
      it: "Hong Kong",
      cn: "中国香港",
      tr: "Hong Kong",
    },
    latitude: "22.25000000",
    longitude: "114.16666666",
    emoji: "🇭🇰",
    emojiU: "U+1F1ED U+1F1F0",
    states: [
      {
        id: 4889,
        name: "Central and Western",
        state_code: "HCW",
        latitude: "22.28666000",
        longitude: "114.15497000",
        type: "district",
      },
      {
        id: 4891,
        name: "Eastern",
        state_code: "HEA",
        latitude: "22.28411000",
        longitude: "114.22414000",
        type: "district",
      },
      {
        id: 4888,
        name: "Islands",
        state_code: "NIS",
        latitude: "22.26114000",
        longitude: "113.94608000",
        type: "district",
      },
      {
        id: 4895,
        name: "Kowloon City",
        state_code: "KKC",
        latitude: "22.32820000",
        longitude: "114.19155000",
        type: "district",
      },
      {
        id: 4898,
        name: "Kwai Tsing",
        state_code: "NKT",
        latitude: "22.35488000",
        longitude: "114.08401000",
        type: "district",
      },
      {
        id: 4897,
        name: "Kwun Tong",
        state_code: "KKT",
        latitude: "22.31326000",
        longitude: "114.22581000",
        type: "district",
      },
      {
        id: 4900,
        name: "North",
        state_code: "NNO",
        latitude: "22.49471000",
        longitude: "114.13812000",
        type: "district",
      },
      {
        id: 4887,
        name: "Sai Kung",
        state_code: "NSK",
        latitude: "22.38143000",
        longitude: "114.27052000",
        type: "district",
      },
      {
        id: 4901,
        name: "Sha Tin",
        state_code: "NST",
        latitude: "22.38715000",
        longitude: "114.19534000",
        type: "district",
      },
      {
        id: 4894,
        name: "Sham Shui Po",
        state_code: "KSS",
        latitude: "22.33074000",
        longitude: "114.16220000",
        type: "district",
      },
      {
        id: 4892,
        name: "Southern",
        state_code: "HSO",
        latitude: "22.24725000",
        longitude: "114.15884000",
        type: "district",
      },
      {
        id: 4885,
        name: "Tai Po",
        state_code: "NTP",
        latitude: "22.45085000",
        longitude: "114.16422000",
        type: "district",
      },
      {
        id: 4884,
        name: "Tsuen Wan",
        state_code: "NTW",
        latitude: "22.36281000",
        longitude: "114.12907000",
        type: "district",
      },
      {
        id: 4899,
        name: "Tuen Mun",
        state_code: "NTM",
        latitude: "22.39163000",
        longitude: "113.97708850",
        type: "district",
      },
      {
        id: 4890,
        name: "Wan Chai",
        state_code: "HWC",
        latitude: "22.27968000",
        longitude: "114.17168000",
        type: "district",
      },
      {
        id: 4896,
        name: "Wong Tai Sin",
        state_code: "KWT",
        latitude: "22.33353000",
        longitude: "114.19686000",
        type: "district",
      },
      {
        id: 4893,
        name: "Yau Tsim Mong",
        state_code: "KYT",
        latitude: "22.32138000",
        longitude: "114.17260000",
        type: "district",
      },
      {
        id: 4883,
        name: "Yuen Long",
        state_code: "NYL",
        latitude: "22.44559000",
        longitude: "114.02218000",
        type: "district",
      },
    ],
  },
  {
    name: "Hungary",
    iso3: "HUN",
    iso2: "HU",
    numeric_code: "348",
    phone_code: "36",
    capital: "Budapest",
    currency: "HUF",
    currency_name: "Hungarian forint",
    currency_symbol: "Ft",
    tld: ".hu",
    native: "Magyarország",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Hungarian, Magyar",
    timezones: [
      {
        zoneName: "Europe/Budapest",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "헝가리",
      "pt-BR": "Hungria",
      pt: "Hungria",
      nl: "Hongarije",
      hr: "Mađarska",
      fa: "مجارستان",
      de: "Ungarn",
      es: "Hungría",
      fr: "Hongrie",
      ja: "ハンガリー",
      it: "Ungheria",
      cn: "匈牙利",
      tr: "Macaristan",
    },
    latitude: "47.00000000",
    longitude: "20.00000000",
    emoji: "🇭🇺",
    emojiU: "U+1F1ED U+1F1FA",
    states: [
      {
        id: 1048,
        name: "Bács-Kiskun",
        state_code: "BK",
        latitude: "46.56614370",
        longitude: "19.42724640",
        type: "county",
      },
      {
        id: 1055,
        name: "Baranya",
        state_code: "BA",
        latitude: "46.04845850",
        longitude: "18.27191730",
        type: "county",
      },
      {
        id: 1060,
        name: "Békés",
        state_code: "BE",
        latitude: "46.67048990",
        longitude: "21.04349960",
        type: "county",
      },
      {
        id: 1036,
        name: "Békéscsaba",
        state_code: "BC",
        latitude: "46.67359390",
        longitude: "21.08773090",
        type: "city with county rights",
      },
      {
        id: 1058,
        name: "Borsod-Abaúj-Zemplén",
        state_code: "BZ",
        latitude: "48.29394010",
        longitude: "20.69341120",
        type: "county",
      },
      {
        id: 1064,
        name: "Budapest",
        state_code: "BU",
        latitude: "47.49791200",
        longitude: "19.04023500",
        type: "capital city",
      },
      {
        id: 1031,
        name: "Csongrád County",
        state_code: "CS",
        latitude: "46.41670500",
        longitude: "20.25661610",
        type: "county",
      },
      {
        id: 1032,
        name: "Debrecen",
        state_code: "DE",
        latitude: "47.53160490",
        longitude: "21.62731240",
        type: "city with county rights",
      },
      {
        id: 1049,
        name: "Dunaújváros",
        state_code: "DU",
        latitude: "46.96190590",
        longitude: "18.93552270",
        type: "city with county rights",
      },
      {
        id: 1037,
        name: "Eger",
        state_code: "EG",
        latitude: "47.90253480",
        longitude: "20.37722840",
        type: "city with county rights",
      },
      {
        id: 1028,
        name: "Érd",
        state_code: "ER",
        latitude: "47.39197180",
        longitude: "18.90454400",
        type: "city with county rights",
      },
      {
        id: 1044,
        name: "Fejér County",
        state_code: "FE",
        latitude: "47.12179320",
        longitude: "18.52948150",
        type: "county",
      },
      {
        id: 1041,
        name: "Győr",
        state_code: "GY",
        latitude: "47.68745690",
        longitude: "17.65039740",
        type: "city with county rights",
      },
      {
        id: 1042,
        name: "Győr-Moson-Sopron County",
        state_code: "GS",
        latitude: "47.65092850",
        longitude: "17.25058830",
        type: "county",
      },
      {
        id: 1063,
        name: "Hajdú-Bihar County",
        state_code: "HB",
        latitude: "47.46883550",
        longitude: "21.54532270",
        type: "county",
      },
      {
        id: 1040,
        name: "Heves County",
        state_code: "HE",
        latitude: "47.80576170",
        longitude: "20.20385590",
        type: "county",
      },
      {
        id: 1027,
        name: "Hódmezővásárhely",
        state_code: "HV",
        latitude: "46.41812620",
        longitude: "20.33003150",
        type: "city with county rights",
      },
      {
        id: 1043,
        name: "Jász-Nagykun-Szolnok County",
        state_code: "JN",
        latitude: "47.25555790",
        longitude: "20.52324560",
        type: "county",
      },
      {
        id: 1067,
        name: "Kaposvár",
        state_code: "KV",
        latitude: "46.35936060",
        longitude: "17.79676390",
        type: "city with county rights",
      },
      {
        id: 1056,
        name: "Kecskemét",
        state_code: "KM",
        latitude: "46.89637110",
        longitude: "19.68968610",
        type: "city with county rights",
      },
      {
        id: 5085,
        name: "Komárom-Esztergom",
        state_code: "KE",
        latitude: "47.57797860",
        longitude: "18.12568550",
        type: "county",
      },
      {
        id: 1065,
        name: "Miskolc",
        state_code: "MI",
        latitude: "48.10347750",
        longitude: "20.77843840",
        type: "city with county rights",
      },
      {
        id: 1030,
        name: "Nagykanizsa",
        state_code: "NK",
        latitude: "46.45902180",
        longitude: "16.98967960",
        type: "city with county rights",
      },
      {
        id: 1051,
        name: "Nógrád County",
        state_code: "NO",
        latitude: "47.90410310",
        longitude: "19.04985040",
        type: "county",
      },
      {
        id: 1034,
        name: "Nyíregyháza",
        state_code: "NY",
        latitude: "47.94953240",
        longitude: "21.72440530",
        type: "city with county rights",
      },
      {
        id: 1053,
        name: "Pécs",
        state_code: "PS",
        latitude: "46.07273450",
        longitude: "18.23226600",
        type: "city with county rights",
      },
      {
        id: 1059,
        name: "Pest County",
        state_code: "PE",
        latitude: "47.44800010",
        longitude: "19.46181280",
        type: "county",
      },
      {
        id: 1068,
        name: "Salgótarján",
        state_code: "ST",
        latitude: "48.09352370",
        longitude: "19.79998130",
        type: "city with county rights",
      },
      {
        id: 1035,
        name: "Somogy County",
        state_code: "SO",
        latitude: "46.55485900",
        longitude: "17.58667320",
        type: "county",
      },
      {
        id: 1057,
        name: "Sopron",
        state_code: "SN",
        latitude: "47.68166190",
        longitude: "16.58447950",
        type: "city with county rights",
      },
      {
        id: 1045,
        name: "Szabolcs-Szatmár-Bereg County",
        state_code: "SZ",
        latitude: "48.03949540",
        longitude: "22.00333000",
        type: "county",
      },
      {
        id: 1029,
        name: "Szeged",
        state_code: "SD",
        latitude: "46.25301020",
        longitude: "20.14142530",
        type: "city with county rights",
      },
      {
        id: 1033,
        name: "Székesfehérvár",
        state_code: "SF",
        latitude: "47.18602620",
        longitude: "18.42213580",
        type: "city with county rights",
      },
      {
        id: 1061,
        name: "Szekszárd",
        state_code: "SS",
        latitude: "46.34743260",
        longitude: "18.70622930",
        type: "city with county rights",
      },
      {
        id: 1047,
        name: "Szolnok",
        state_code: "SK",
        latitude: "47.16213550",
        longitude: "20.18247120",
        type: "city with county rights",
      },
      {
        id: 1052,
        name: "Szombathely",
        state_code: "SH",
        latitude: "47.23068510",
        longitude: "16.62184410",
        type: "city with county rights",
      },
      {
        id: 1066,
        name: "Tatabánya",
        state_code: "TB",
        latitude: "47.56924600",
        longitude: "18.40481800",
        type: "city with county rights",
      },
      {
        id: 1038,
        name: "Tolna County",
        state_code: "TO",
        latitude: "46.47627540",
        longitude: "18.55706270",
        type: "county",
      },
      {
        id: 1039,
        name: "Vas County",
        state_code: "VA",
        latitude: "47.09291110",
        longitude: "16.68121830",
        type: "county",
      },
      {
        id: 1062,
        name: "Veszprém",
        state_code: "VM",
        latitude: "47.10280870",
        longitude: "17.90930190",
        type: "city with county rights",
      },
      {
        id: 1054,
        name: "Veszprém County",
        state_code: "VE",
        latitude: "47.09309740",
        longitude: "17.91007630",
        type: "county",
      },
      {
        id: 1046,
        name: "Zala County",
        state_code: "ZA",
        latitude: "46.73844040",
        longitude: "16.91522520",
        type: "county",
      },
      {
        id: 1050,
        name: "Zalaegerszeg",
        state_code: "ZE",
        latitude: "46.84169360",
        longitude: "16.84163220",
        type: "county",
      },
    ],
  },
  {
    name: "Iceland",
    iso3: "ISL",
    iso2: "IS",
    numeric_code: "352",
    phone_code: "354",
    capital: "Reykjavik",
    currency: "ISK",
    currency_name: "Icelandic króna",
    currency_symbol: "kr",
    tld: ".is",
    native: "Ísland",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Icelandic",
    timezones: [
      {
        zoneName: "Atlantic/Reykjavik",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "아이슬란드",
      "pt-BR": "Islândia",
      pt: "Islândia",
      nl: "IJsland",
      hr: "Island",
      fa: "ایسلند",
      de: "Island",
      es: "Islandia",
      fr: "Islande",
      ja: "アイスランド",
      it: "Islanda",
      cn: "冰岛",
      tr: "İzlanda",
    },
    latitude: "65.00000000",
    longitude: "-18.00000000",
    emoji: "🇮🇸",
    emojiU: "U+1F1EE U+1F1F8",
    states: [
      {
        id: 3431,
        name: "Capital Region",
        state_code: "1",
        latitude: "38.56569570",
        longitude: "-92.18169490",
        type: null,
      },
      {
        id: 3433,
        name: "Eastern Region",
        state_code: "7",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3437,
        name: "Northeastern Region",
        state_code: "6",
        latitude: "43.29942850",
        longitude: "-74.21793260",
        type: null,
      },
      {
        id: 3435,
        name: "Northwestern Region",
        state_code: "5",
        latitude: "41.91339320",
        longitude: "-73.04716880",
        type: null,
      },
      {
        id: 3430,
        name: "Southern Peninsula Region",
        state_code: "2",
        latitude: "63.91548030",
        longitude: "-22.36496670",
        type: null,
      },
      {
        id: 3434,
        name: "Southern Region",
        state_code: "8",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3436,
        name: "Western Region",
        state_code: "3",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3432,
        name: "Westfjords",
        state_code: "4",
        latitude: "65.91961500",
        longitude: "-21.88117640",
        type: null,
      },
    ],
  },
  {
    name: "India",
    iso3: "IND",
    iso2: "IN",
    numeric_code: "356",
    phone_code: "91",
    capital: "New Delhi",
    currency: "INR",
    currency_name: "Indian rupee",
    currency_symbol: "₹",
    tld: ".in",
    native: "भारत",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Indian",
    timezones: [
      {
        zoneName: "Asia/Kolkata",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time",
      },
    ],
    translations: {
      kr: "인도",
      "pt-BR": "Índia",
      pt: "Índia",
      nl: "India",
      hr: "Indija",
      fa: "هند",
      de: "Indien",
      es: "India",
      fr: "Inde",
      ja: "インド",
      it: "India",
      cn: "印度",
      tr: "Hindistan",
    },
    latitude: "20.00000000",
    longitude: "77.00000000",
    emoji: "🇮🇳",
    emojiU: "U+1F1EE U+1F1F3",
    states: [
      {
        id: 4023,
        name: "Andaman and Nicobar Islands",
        state_code: "AN",
        latitude: "11.74008670",
        longitude: "92.65864010",
        type: "Union territory",
      },
      {
        id: 4017,
        name: "Andhra Pradesh",
        state_code: "AP",
        latitude: "15.91289980",
        longitude: "79.73998750",
        type: "state",
      },
      {
        id: 4024,
        name: "Arunachal Pradesh",
        state_code: "AR",
        latitude: "28.21799940",
        longitude: "94.72775280",
        type: "state",
      },
      {
        id: 4027,
        name: "Assam",
        state_code: "AS",
        latitude: "26.20060430",
        longitude: "92.93757390",
        type: "state",
      },
      {
        id: 4037,
        name: "Bihar",
        state_code: "BR",
        latitude: "25.09607420",
        longitude: "85.31311940",
        type: "state",
      },
      {
        id: 4031,
        name: "Chandigarh",
        state_code: "CH",
        latitude: "30.73331480",
        longitude: "76.77941790",
        type: "Union territory",
      },
      {
        id: 4040,
        name: "Chhattisgarh",
        state_code: "CT",
        latitude: "21.27865670",
        longitude: "81.86614420",
        type: "state",
      },
      {
        id: 4033,
        name: "Dadra and Nagar Haveli and Daman and Diu",
        state_code: "DH",
        latitude: "20.39737360",
        longitude: "72.83279910",
        type: "Union territory",
      },
      {
        id: 4021,
        name: "Delhi",
        state_code: "DL",
        latitude: "28.70405920",
        longitude: "77.10249020",
        type: "Union territory",
      },
      {
        id: 4009,
        name: "Goa",
        state_code: "GA",
        latitude: "15.29932650",
        longitude: "74.12399600",
        type: "state",
      },
      {
        id: 4030,
        name: "Gujarat",
        state_code: "GJ",
        latitude: "22.25865200",
        longitude: "71.19238050",
        type: "state",
      },
      {
        id: 4007,
        name: "Haryana",
        state_code: "HR",
        latitude: "29.05877570",
        longitude: "76.08560100",
        type: "state",
      },
      {
        id: 4020,
        name: "Himachal Pradesh",
        state_code: "HP",
        latitude: "31.10482940",
        longitude: "77.17339010",
        type: "state",
      },
      {
        id: 4029,
        name: "Jammu and Kashmir",
        state_code: "JK",
        latitude: "33.27783900",
        longitude: "75.34121790",
        type: "Union territory",
      },
      {
        id: 4025,
        name: "Jharkhand",
        state_code: "JH",
        latitude: "23.61018080",
        longitude: "85.27993540",
        type: "state",
      },
      {
        id: 4026,
        name: "Karnataka",
        state_code: "KA",
        latitude: "15.31727750",
        longitude: "75.71388840",
        type: "state",
      },
      {
        id: 4028,
        name: "Kerala",
        state_code: "KL",
        latitude: "10.85051590",
        longitude: "76.27108330",
        type: "state",
      },
      {
        id: 4852,
        name: "Ladakh",
        state_code: "LA",
        latitude: "34.22684750",
        longitude: "77.56194190",
        type: "Union territory",
      },
      {
        id: 4019,
        name: "Lakshadweep",
        state_code: "LD",
        latitude: "10.32802650",
        longitude: "72.78463360",
        type: "Union territory",
      },
      {
        id: 4039,
        name: "Madhya Pradesh",
        state_code: "MP",
        latitude: "22.97342290",
        longitude: "78.65689420",
        type: "state",
      },
      {
        id: 4008,
        name: "Maharashtra",
        state_code: "MH",
        latitude: "19.75147980",
        longitude: "75.71388840",
        type: "state",
      },
      {
        id: 4010,
        name: "Manipur",
        state_code: "MN",
        latitude: "24.66371730",
        longitude: "93.90626880",
        type: "state",
      },
      {
        id: 4006,
        name: "Meghalaya",
        state_code: "ML",
        latitude: "25.46703080",
        longitude: "91.36621600",
        type: "state",
      },
      {
        id: 4036,
        name: "Mizoram",
        state_code: "MZ",
        latitude: "23.16454300",
        longitude: "92.93757390",
        type: "state",
      },
      {
        id: 4018,
        name: "Nagaland",
        state_code: "NL",
        latitude: "26.15843540",
        longitude: "94.56244260",
        type: "state",
      },
      {
        id: 4013,
        name: "Odisha",
        state_code: "OR",
        latitude: "20.95166580",
        longitude: "85.09852360",
        type: "state",
      },
      {
        id: 4011,
        name: "Puducherry",
        state_code: "PY",
        latitude: "11.94159150",
        longitude: "79.80831330",
        type: "Union territory",
      },
      {
        id: 4015,
        name: "Punjab",
        state_code: "PB",
        latitude: "31.14713050",
        longitude: "75.34121790",
        type: "state",
      },
      {
        id: 4014,
        name: "Rajasthan",
        state_code: "RJ",
        latitude: "27.02380360",
        longitude: "74.21793260",
        type: "state",
      },
      {
        id: 4034,
        name: "Sikkim",
        state_code: "SK",
        latitude: "27.53297180",
        longitude: "88.51221780",
        type: "state",
      },
      {
        id: 4035,
        name: "Tamil Nadu",
        state_code: "TN",
        latitude: "11.12712250",
        longitude: "78.65689420",
        type: "state",
      },
      {
        id: 4012,
        name: "Telangana",
        state_code: "TG",
        latitude: "18.11243720",
        longitude: "79.01929970",
        type: "state",
      },
      {
        id: 4038,
        name: "Tripura",
        state_code: "TR",
        latitude: "23.94084820",
        longitude: "91.98815270",
        type: "state",
      },
      {
        id: 4022,
        name: "Uttar Pradesh",
        state_code: "UP",
        latitude: "26.84670880",
        longitude: "80.94615920",
        type: "state",
      },
      {
        id: 4016,
        name: "Uttarakhand",
        state_code: "UK",
        latitude: "30.06675300",
        longitude: "79.01929970",
        type: "state",
      },
      {
        id: 4853,
        name: "West Bengal",
        state_code: "WB",
        latitude: "22.98675690",
        longitude: "87.85497550",
        type: "state",
      },
    ],
  },
  {
    name: "Indonesia",
    iso3: "IDN",
    iso2: "ID",
    numeric_code: "360",
    phone_code: "62",
    capital: "Jakarta",
    currency: "IDR",
    currency_name: "Indonesian rupiah",
    currency_symbol: "Rp",
    tld: ".id",
    native: "Indonesia",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Indonesian",
    timezones: [
      {
        zoneName: "Asia/Jakarta",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time",
      },
      {
        zoneName: "Asia/Jayapura",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "WIT",
        tzName: "Eastern Indonesian Time",
      },
      {
        zoneName: "Asia/Makassar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "WITA",
        tzName: "Central Indonesia Time",
      },
      {
        zoneName: "Asia/Pontianak",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "WIB",
        tzName: "Western Indonesian Time",
      },
    ],
    translations: {
      kr: "인도네시아",
      "pt-BR": "Indonésia",
      pt: "Indonésia",
      nl: "Indonesië",
      hr: "Indonezija",
      fa: "اندونزی",
      de: "Indonesien",
      es: "Indonesia",
      fr: "Indonésie",
      ja: "インドネシア",
      it: "Indonesia",
      cn: "印度尼西亚",
      tr: "Endonezya",
    },
    latitude: "-5.00000000",
    longitude: "120.00000000",
    emoji: "🇮🇩",
    emojiU: "U+1F1EE U+1F1E9",
    states: [
      {
        id: 1822,
        name: "Aceh",
        state_code: "AC",
        latitude: "4.69513500",
        longitude: "96.74939930",
        type: "province",
      },
      {
        id: 1826,
        name: "Bali",
        state_code: "BA",
        latitude: "-8.34053890",
        longitude: "115.09195090",
        type: "province",
      },
      {
        id: 1810,
        name: "Banten",
        state_code: "BT",
        latitude: "-6.40581720",
        longitude: "106.06401790",
        type: "province",
      },
      {
        id: 1793,
        name: "Bengkulu",
        state_code: "BE",
        latitude: "-3.79284510",
        longitude: "102.26076410",
        type: "province",
      },
      {
        id: 1829,
        name: "DI Yogyakarta",
        state_code: "YO",
        latitude: "-7.87538490",
        longitude: "110.42620880",
        type: "province",
      },
      {
        id: 1805,
        name: "DKI Jakarta",
        state_code: "JK",
        latitude: "-6.20876340",
        longitude: "106.84559900",
        type: "capital district",
      },
      {
        id: 1812,
        name: "Gorontalo",
        state_code: "GO",
        latitude: "0.54354420",
        longitude: "123.05676930",
        type: "province",
      },
      {
        id: 1815,
        name: "Jambi",
        state_code: "JA",
        latitude: "-1.61012290",
        longitude: "103.61312030",
        type: "province",
      },
      {
        id: 1825,
        name: "Jawa Barat",
        state_code: "JB",
        latitude: "-7.09091100",
        longitude: "107.66888700",
        type: "province",
      },
      {
        id: 1802,
        name: "Jawa Tengah",
        state_code: "JT",
        latitude: "-7.15097500",
        longitude: "110.14025940",
        type: "province",
      },
      {
        id: 1827,
        name: "Jawa Timur",
        state_code: "JI",
        latitude: "-7.53606390",
        longitude: "112.23840170",
        type: "province",
      },
      {
        id: 1806,
        name: "Kalimantan Barat",
        state_code: "KB",
        latitude: "0.47734750",
        longitude: "106.61314050",
        type: "province",
      },
      {
        id: 1819,
        name: "Kalimantan Selatan",
        state_code: "KS",
        latitude: "-3.09264150",
        longitude: "115.28375850",
        type: "province",
      },
      {
        id: 1794,
        name: "Kalimantan Tengah",
        state_code: "KT",
        latitude: "-1.68148780",
        longitude: "113.38235450",
        type: "province",
      },
      {
        id: 1804,
        name: "Kalimantan Timur",
        state_code: "KI",
        latitude: "0.53865860",
        longitude: "116.41938900",
        type: "province",
      },
      {
        id: 1824,
        name: "Kalimantan Utara",
        state_code: "KU",
        latitude: "3.07309290",
        longitude: "116.04138890",
        type: "province",
      },
      {
        id: 1820,
        name: "Kepulauan Bangka Belitung",
        state_code: "BB",
        latitude: "-2.74105130",
        longitude: "106.44058720",
        type: "province",
      },
      {
        id: 1807,
        name: "Kepulauan Riau",
        state_code: "KR",
        latitude: "3.94565140",
        longitude: "108.14286690",
        type: "province",
      },
      {
        id: 1811,
        name: "Lampung",
        state_code: "LA",
        latitude: "-4.55858490",
        longitude: "105.40680790",
        type: "province",
      },
      {
        id: 1800,
        name: "Maluku",
        state_code: "MA",
        latitude: "-3.23846160",
        longitude: "130.14527340",
        type: "province",
      },
      {
        id: 1801,
        name: "Maluku Utara",
        state_code: "MU",
        latitude: "1.57099930",
        longitude: "127.80876930",
        type: "province",
      },
      {
        id: 1814,
        name: "Nusa Tenggara Barat",
        state_code: "NB",
        latitude: "-8.65293340",
        longitude: "117.36164760",
        type: "province",
      },
      {
        id: 1818,
        name: "Nusa Tenggara Timur",
        state_code: "NT",
        latitude: "-8.65738190",
        longitude: "121.07937050",
        type: "province",
      },
      {
        id: 1798,
        name: "Papua",
        state_code: "PA",
        latitude: "-5.01222020",
        longitude: "141.34701590",
        type: "province",
      },
      {
        id: 1799,
        name: "Papua Barat",
        state_code: "PB",
        latitude: "-1.33611540",
        longitude: "133.17471620",
        type: "province",
      },
      {
        id: 1809,
        name: "Riau",
        state_code: "RI",
        latitude: "0.29334690",
        longitude: "101.70682940",
        type: "province",
      },
      {
        id: 1817,
        name: "Sulawesi Barat",
        state_code: "SR",
        latitude: "-2.84413710",
        longitude: "119.23207840",
        type: "province",
      },
      {
        id: 1795,
        name: "Sulawesi Selatan",
        state_code: "SN",
        latitude: "-3.66879940",
        longitude: "119.97405340",
        type: "province",
      },
      {
        id: 1813,
        name: "Sulawesi Tengah",
        state_code: "ST",
        latitude: "-1.43002540",
        longitude: "121.44561790",
        type: "province",
      },
      {
        id: 1796,
        name: "Sulawesi Tenggara",
        state_code: "SG",
        latitude: "-4.14491000",
        longitude: "122.17460500",
        type: "province",
      },
      {
        id: 1808,
        name: "Sulawesi Utara",
        state_code: "SA",
        latitude: "0.62469320",
        longitude: "123.97500180",
        type: "province",
      },
      {
        id: 1828,
        name: "Sumatera Barat",
        state_code: "SB",
        latitude: "-0.73993970",
        longitude: "100.80000510",
        type: "province",
      },
      {
        id: 1816,
        name: "Sumatera Selatan",
        state_code: "SS",
        latitude: "-3.31943740",
        longitude: "103.91439900",
        type: "province",
      },
      {
        id: 1792,
        name: "Sumatera Utara",
        state_code: "SU",
        latitude: "2.11535470",
        longitude: "99.54509740",
        type: "province",
      },
    ],
  },
  {
    name: "Iran",
    iso3: "IRN",
    iso2: "IR",
    numeric_code: "364",
    phone_code: "98",
    capital: "Tehran",
    currency: "IRR",
    currency_name: "Iranian rial",
    currency_symbol: "﷼",
    tld: ".ir",
    native: "ایران",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Iranian, Persian",
    timezones: [
      {
        zoneName: "Asia/Tehran",
        gmtOffset: 12600,
        gmtOffsetName: "UTC+03:30",
        abbreviation: "IRDT",
        tzName: "Iran Daylight Time",
      },
    ],
    translations: {
      kr: "이란",
      "pt-BR": "Irã",
      pt: "Irão",
      nl: "Iran",
      hr: "Iran",
      fa: "ایران",
      de: "Iran",
      es: "Iran",
      fr: "Iran",
      ja: "イラン・イスラム共和国",
      cn: "伊朗",
      tr: "İran",
    },
    latitude: "32.00000000",
    longitude: "53.00000000",
    emoji: "🇮🇷",
    emojiU: "U+1F1EE U+1F1F7",
    states: [
      {
        id: 3929,
        name: "Alborz",
        state_code: "30",
        latitude: "35.99604670",
        longitude: "50.92892460",
        type: "province",
      },
      {
        id: 3934,
        name: "Ardabil",
        state_code: "24",
        latitude: "38.48532760",
        longitude: "47.89112090",
        type: "province",
      },
      {
        id: 3932,
        name: "Bushehr",
        state_code: "18",
        latitude: "28.76207390",
        longitude: "51.51500770",
        type: "province",
      },
      {
        id: 3921,
        name: "Chaharmahal and Bakhtiari",
        state_code: "14",
        latitude: "31.99704190",
        longitude: "50.66138490",
        type: "province",
      },
      {
        id: 3944,
        name: "East Azerbaijan",
        state_code: "03",
        latitude: "37.90357330",
        longitude: "46.26821090",
        type: "province",
      },
      {
        id: 3939,
        name: "Fars",
        state_code: "07",
        latitude: "29.10438130",
        longitude: "53.04589300",
        type: "province",
      },
      {
        id: 3920,
        name: "Gilan",
        state_code: "01",
        latitude: "37.28094550",
        longitude: "49.59241340",
        type: "province",
      },
      {
        id: 3933,
        name: "Golestan",
        state_code: "27",
        latitude: "37.28981230",
        longitude: "55.13758340",
        type: "province",
      },
      {
        id: 4920,
        name: "Hamadan",
        state_code: "13",
        latitude: "34.91936070",
        longitude: "47.48329250",
        type: "province",
      },
      {
        id: 3937,
        name: "Hormozgan",
        state_code: "22",
        latitude: "27.13872300",
        longitude: "55.13758340",
        type: "province",
      },
      {
        id: 3918,
        name: "Ilam",
        state_code: "16",
        latitude: "33.29576180",
        longitude: "46.67053400",
        type: "province",
      },
      {
        id: 3923,
        name: "Isfahan",
        state_code: "10",
        latitude: "33.27710730",
        longitude: "52.36133780",
        type: "province",
      },
      {
        id: 3943,
        name: "Kerman",
        state_code: "08",
        latitude: "29.48500890",
        longitude: "57.64390480",
        type: "province",
      },
      {
        id: 3919,
        name: "Kermanshah",
        state_code: "05",
        latitude: "34.45762330",
        longitude: "46.67053400",
        type: "province",
      },
      {
        id: 3917,
        name: "Khuzestan",
        state_code: "06",
        latitude: "31.43601490",
        longitude: "49.04131200",
        type: "province",
      },
      {
        id: 3926,
        name: "Kohgiluyeh and Boyer-Ahmad",
        state_code: "17",
        latitude: "30.72458600",
        longitude: "50.84563230",
        type: "province",
      },
      {
        id: 3935,
        name: "Kurdistan",
        state_code: "12",
        latitude: "35.95535790",
        longitude: "47.13621250",
        type: "province",
      },
      {
        id: 3928,
        name: "Lorestan",
        state_code: "15",
        latitude: "33.58183940",
        longitude: "48.39881860",
        type: "province",
      },
      {
        id: 3916,
        name: "Markazi",
        state_code: "00",
        latitude: "34.61230500",
        longitude: "49.85472660",
        type: "province",
      },
      {
        id: 3938,
        name: "Mazandaran",
        state_code: "02",
        latitude: "36.22623930",
        longitude: "52.53186040",
        type: "province",
      },
      {
        id: 3942,
        name: "North Khorasan",
        state_code: "28",
        latitude: "37.47103530",
        longitude: "57.10131880",
        type: "province",
      },
      {
        id: 3941,
        name: "Qazvin",
        state_code: "26",
        latitude: "36.08813170",
        longitude: "49.85472660",
        type: "province",
      },
      {
        id: 3922,
        name: "Qom",
        state_code: "25",
        latitude: "34.64157640",
        longitude: "50.87460350",
        type: "province",
      },
      {
        id: 3927,
        name: "Razavi Khorasan",
        state_code: "09",
        latitude: "35.10202530",
        longitude: "59.10417580",
        type: "province",
      },
      {
        id: 3940,
        name: "Semnan",
        state_code: "20",
        latitude: "35.22555850",
        longitude: "54.43421380",
        type: "province",
      },
      {
        id: 3931,
        name: "Sistan and Baluchestan",
        state_code: "11",
        latitude: "27.52999060",
        longitude: "60.58206760",
        type: "province",
      },
      {
        id: 3930,
        name: "South Khorasan",
        state_code: "29",
        latitude: "32.51756430",
        longitude: "59.10417580",
        type: "province",
      },
      {
        id: 3945,
        name: "Tehran",
        state_code: "23",
        latitude: "35.72484160",
        longitude: "51.38165300",
        type: "province",
      },
      {
        id: 3924,
        name: "West Azarbaijan",
        state_code: "04",
        latitude: "37.45500620",
        longitude: "45.00000000",
        type: "province",
      },
      {
        id: 3936,
        name: "Yazd",
        state_code: "21",
        latitude: "32.10063870",
        longitude: "54.43421380",
        type: "province",
      },
      {
        id: 3925,
        name: "Zanjan",
        state_code: "19",
        latitude: "36.50181850",
        longitude: "48.39881860",
        type: "province",
      },
    ],
  },
  {
    name: "Iraq",
    iso3: "IRQ",
    iso2: "IQ",
    numeric_code: "368",
    phone_code: "964",
    capital: "Baghdad",
    currency: "IQD",
    currency_name: "Iraqi dinar",
    currency_symbol: "د.ع",
    tld: ".iq",
    native: "العراق",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Iraqi",
    timezones: [
      {
        zoneName: "Asia/Baghdad",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "이라크",
      "pt-BR": "Iraque",
      pt: "Iraque",
      nl: "Irak",
      hr: "Irak",
      fa: "عراق",
      de: "Irak",
      es: "Irak",
      fr: "Irak",
      ja: "イラク",
      it: "Iraq",
      cn: "伊拉克",
      tr: "Irak",
    },
    latitude: "33.00000000",
    longitude: "44.00000000",
    emoji: "🇮🇶",
    emojiU: "U+1F1EE U+1F1F6",
    states: [
      {
        id: 3964,
        name: "Al Anbar",
        state_code: "AN",
        latitude: "32.55976140",
        longitude: "41.91964710",
        type: "governorate",
      },
      {
        id: 3958,
        name: "Al Muthanna",
        state_code: "MU",
        latitude: "29.91331710",
        longitude: "45.29938620",
        type: "governorate",
      },
      {
        id: 3956,
        name: "Al-Qādisiyyah",
        state_code: "QA",
        latitude: "32.04369100",
        longitude: "45.14945050",
        type: "governorate",
      },
      {
        id: 3955,
        name: "Babylon",
        state_code: "BB",
        latitude: "32.46819100",
        longitude: "44.55019350",
        type: "governorate",
      },
      {
        id: 3959,
        name: "Baghdad",
        state_code: "BG",
        latitude: "33.31526180",
        longitude: "44.36606530",
        type: "governorate",
      },
      {
        id: 3960,
        name: "Basra",
        state_code: "BA",
        latitude: "30.51142520",
        longitude: "47.82962530",
        type: "governorate",
      },
      {
        id: 3954,
        name: "Dhi Qar",
        state_code: "DQ",
        latitude: "31.10422920",
        longitude: "46.36246860",
        type: "governorate",
      },
      {
        id: 3965,
        name: "Diyala",
        state_code: "DI",
        latitude: "33.77334870",
        longitude: "45.14945050",
        type: "governorate",
      },
      {
        id: 3967,
        name: "Dohuk",
        state_code: "DA",
        latitude: "36.90772520",
        longitude: "43.06316890",
        type: "governorate",
      },
      {
        id: 3968,
        name: "Erbil",
        state_code: "AR",
        latitude: "36.55706280",
        longitude: "44.38512630",
        type: "governorate",
      },
      {
        id: 3957,
        name: "Karbala",
        state_code: "KA",
        latitude: "32.40454930",
        longitude: "43.86732220",
        type: "governorate",
      },
      {
        id: 3971,
        name: "Kirkuk",
        state_code: "KI",
        latitude: "35.32920140",
        longitude: "43.94367880",
        type: "governorate",
      },
      {
        id: 3966,
        name: "Maysan",
        state_code: "MA",
        latitude: "31.87340020",
        longitude: "47.13621250",
        type: "governorate",
      },
      {
        id: 3962,
        name: "Najaf",
        state_code: "NA",
        latitude: "31.35174860",
        longitude: "44.09603110",
        type: "governorate",
      },
      {
        id: 3963,
        name: "Nineveh",
        state_code: "NI",
        latitude: "36.22957400",
        longitude: "42.23624350",
        type: "governorate",
      },
      {
        id: 3961,
        name: "Saladin",
        state_code: "SD",
        latitude: "34.53375270",
        longitude: "43.48373800",
        type: "governorate",
      },
      {
        id: 3969,
        name: "Sulaymaniyah",
        state_code: "SU",
        latitude: "35.54663480",
        longitude: "45.30036830",
        type: "governorate",
      },
      {
        id: 3970,
        name: "Wasit",
        state_code: "WA",
        latitude: "32.60240940",
        longitude: "45.75209850",
        type: "governorate",
      },
    ],
  },
  {
    name: "Ireland",
    iso3: "IRL",
    iso2: "IE",
    numeric_code: "372",
    phone_code: "353",
    capital: "Dublin",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".ie",
    native: "Éire",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Irish",
    timezones: [
      {
        zoneName: "Europe/Dublin",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "아일랜드",
      "pt-BR": "Irlanda",
      pt: "Irlanda",
      nl: "Ierland",
      hr: "Irska",
      fa: "ایرلند",
      de: "Irland",
      es: "Irlanda",
      fr: "Irlande",
      ja: "アイルランド",
      it: "Irlanda",
      cn: "爱尔兰",
      tr: "İrlanda",
    },
    latitude: "53.00000000",
    longitude: "-8.00000000",
    emoji: "🇮🇪",
    emojiU: "U+1F1EE U+1F1EA",
    states: [
      {
        id: 1095,
        name: "Carlow",
        state_code: "CW",
        latitude: "52.72322170",
        longitude: "-6.81082950",
        type: "county",
      },
      {
        id: 1088,
        name: "Cavan",
        state_code: "CN",
        latitude: "53.97654240",
        longitude: "-7.29966230",
        type: "county",
      },
      {
        id: 1091,
        name: "Clare",
        state_code: "CE",
        latitude: "43.04664000",
        longitude: "-87.89958100",
        type: "county",
      },
      {
        id: 1087,
        name: "Connacht",
        state_code: "C",
        latitude: "53.83762430",
        longitude: "-8.95844810",
        type: "province",
      },
      {
        id: 1074,
        name: "Cork",
        state_code: "CO",
        latitude: "51.89851430",
        longitude: "-8.47560350",
        type: "county",
      },
      {
        id: 1071,
        name: "Donegal",
        state_code: "DL",
        latitude: "54.65489930",
        longitude: "-8.10409670",
        type: "county",
      },
      {
        id: 1072,
        name: "Dublin",
        state_code: "D",
        latitude: "53.34980530",
        longitude: "-6.26030970",
        type: "county",
      },
      {
        id: 1079,
        name: "Galway",
        state_code: "G",
        latitude: "53.35645090",
        longitude: "-8.85341130",
        type: "county",
      },
      {
        id: 1077,
        name: "Kerry",
        state_code: "KY",
        latitude: "52.15446070",
        longitude: "-9.56686330",
        type: "county",
      },
      {
        id: 1082,
        name: "Kildare",
        state_code: "KE",
        latitude: "53.21204340",
        longitude: "-6.81947080",
        type: "county",
      },
      {
        id: 1090,
        name: "Kilkenny",
        state_code: "KK",
        latitude: "52.57769570",
        longitude: "-7.21800200",
        type: "county",
      },
      {
        id: 1096,
        name: "Laois",
        state_code: "LS",
        latitude: "52.99429500",
        longitude: "-7.33230070",
        type: "county",
      },
      {
        id: 1073,
        name: "Leinster",
        state_code: "L",
        latitude: "53.32715380",
        longitude: "-7.51408410",
        type: "province",
      },
      {
        id: 1094,
        name: "Limerick",
        state_code: "LK",
        latitude: "52.50905170",
        longitude: "-8.74749550",
        type: "county",
      },
      {
        id: 1076,
        name: "Longford",
        state_code: "LD",
        latitude: "53.72749820",
        longitude: "-7.79315270",
        type: "county",
      },
      {
        id: 1083,
        name: "Louth",
        state_code: "LH",
        latitude: "53.92523240",
        longitude: "-6.48894230",
        type: "county",
      },
      {
        id: 1084,
        name: "Mayo",
        state_code: "MO",
        latitude: "54.01526040",
        longitude: "-9.42893690",
        type: "county",
      },
      {
        id: 1092,
        name: "Meath",
        state_code: "MH",
        latitude: "53.60554800",
        longitude: "-6.65641690",
        type: "county",
      },
      {
        id: 1075,
        name: "Monaghan",
        state_code: "MN",
        latitude: "54.24920460",
        longitude: "-6.96831320",
        type: "county",
      },
      {
        id: 1080,
        name: "Munster",
        state_code: "M",
        latitude: "51.94711970",
        longitude: "7.58453200",
        type: "province",
      },
      {
        id: 1078,
        name: "Offaly",
        state_code: "OY",
        latitude: "53.23568710",
        longitude: "-7.71222290",
        type: "county",
      },
      {
        id: 1081,
        name: "Roscommon",
        state_code: "RN",
        latitude: "53.75926040",
        longitude: "-8.26816210",
        type: "county",
      },
      {
        id: 1070,
        name: "Sligo",
        state_code: "SO",
        latitude: "54.15532770",
        longitude: "-8.60645320",
        type: "county",
      },
      {
        id: 1069,
        name: "Tipperary",
        state_code: "TA",
        latitude: "52.47378940",
        longitude: "-8.16185140",
        type: "county",
      },
      {
        id: 1086,
        name: "Ulster",
        state_code: "U",
        latitude: "54.76165550",
        longitude: "-6.96122730",
        type: "province",
      },
      {
        id: 1089,
        name: "Waterford",
        state_code: "WD",
        latitude: "52.19435490",
        longitude: "-7.62275120",
        type: "county",
      },
      {
        id: 1097,
        name: "Westmeath",
        state_code: "WH",
        latitude: "53.53453080",
        longitude: "-7.46532170",
        type: "county",
      },
      {
        id: 1093,
        name: "Wexford",
        state_code: "WX",
        latitude: "52.47936030",
        longitude: "-6.58399130",
        type: "county",
      },
      {
        id: 1085,
        name: "Wicklow",
        state_code: "WW",
        latitude: "52.98623130",
        longitude: "-6.36725430",
        type: "county",
      },
    ],
  },
  {
    name: "Israel",
    iso3: "ISR",
    iso2: "IL",
    numeric_code: "376",
    phone_code: "972",
    capital: "Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "₪",
    tld: ".il",
    native: "יִשְׂרָאֵל",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Israeli",
    timezones: [
      {
        zoneName: "Asia/Jerusalem",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "IST",
        tzName: "Israel Standard Time",
      },
    ],
    translations: {
      kr: "이스라엘",
      "pt-BR": "Israel",
      pt: "Israel",
      nl: "Israël",
      hr: "Izrael",
      fa: "اسرائیل",
      de: "Israel",
      es: "Israel",
      fr: "Israël",
      ja: "イスラエル",
      it: "Israele",
      cn: "以色列",
      tr: "İsrail",
    },
    latitude: "31.50000000",
    longitude: "34.75000000",
    emoji: "🇮🇱",
    emojiU: "U+1F1EE U+1F1F1",
    states: [
      {
        id: 1367,
        name: "Central District",
        state_code: "M",
        latitude: "47.60875830",
        longitude: "-122.29642350",
        type: null,
      },
      {
        id: 1369,
        name: "Haifa District",
        state_code: "HA",
        latitude: "32.48141110",
        longitude: "34.99475100",
        type: null,
      },
      {
        id: 1370,
        name: "Jerusalem District",
        state_code: "JM",
        latitude: "31.76482430",
        longitude: "34.99475100",
        type: null,
      },
      {
        id: 1366,
        name: "Northern District",
        state_code: "Z",
        latitude: "36.15118640",
        longitude: "-95.99517630",
        type: null,
      },
      {
        id: 1368,
        name: "Southern District",
        state_code: "D",
        latitude: "40.71375860",
        longitude: "-74.00090590",
        type: null,
      },
      {
        id: 1371,
        name: "Tel Aviv District",
        state_code: "TA",
        latitude: "32.09290750",
        longitude: "34.80721650",
        type: null,
      },
    ],
  },
  {
    name: "Italy",
    iso3: "ITA",
    iso2: "IT",
    numeric_code: "380",
    phone_code: "39",
    capital: "Rome",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".it",
    native: "Italia",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Italian",
    timezones: [
      {
        zoneName: "Europe/Rome",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "이탈리아",
      "pt-BR": "Itália",
      pt: "Itália",
      nl: "Italië",
      hr: "Italija",
      fa: "ایتالیا",
      de: "Italien",
      es: "Italia",
      fr: "Italie",
      ja: "イタリア",
      it: "Italia",
      cn: "意大利",
      tr: "İtalya",
    },
    latitude: "42.83333333",
    longitude: "12.83333333",
    emoji: "🇮🇹",
    emojiU: "U+1F1EE U+1F1F9",
    states: [
      {
        id: 1679,
        name: "Abruzzo",
        state_code: "65",
        latitude: "42.19201190",
        longitude: "13.72891670",
        type: "region",
      },
      {
        id: 1727,
        name: "Agrigento",
        state_code: "AG",
        latitude: "37.31052020",
        longitude: "13.58579780",
        type: "free municipal consortium",
      },
      {
        id: 1783,
        name: "Alessandria",
        state_code: "AL",
        latitude: "44.81755870",
        longitude: "8.70466270",
        type: "province",
      },
      {
        id: 1672,
        name: "Ancona",
        state_code: "AN",
        latitude: "43.54932450",
        longitude: "13.26634790",
        type: "province",
      },
      {
        id: 1716,
        name: "Aosta Valley",
        state_code: "23",
        latitude: "45.73888780",
        longitude: "7.42618660",
        type: "autonomous region",
      },
      {
        id: 1688,
        name: "Apulia",
        state_code: "75",
        latitude: "40.79283930",
        longitude: "17.10119310",
        type: "region",
      },
      {
        id: 1681,
        name: "Ascoli Piceno",
        state_code: "AP",
        latitude: "42.86389330",
        longitude: "13.58997330",
        type: "province",
      },
      {
        id: 1780,
        name: "Asti",
        state_code: "AT",
        latitude: "44.90076520",
        longitude: "8.20643150",
        type: "province",
      },
      {
        id: 1692,
        name: "Avellino",
        state_code: "AV",
        latitude: "40.99645100",
        longitude: "15.12589550",
        type: "province",
      },
      {
        id: 1686,
        name: "Barletta-Andria-Trani",
        state_code: "BT",
        latitude: "41.20045430",
        longitude: "16.20514840",
        type: "province",
      },
      {
        id: 1706,
        name: "Basilicata",
        state_code: "77",
        latitude: "40.64307660",
        longitude: "15.96998780",
        type: "region",
      },
      {
        id: 1689,
        name: "Belluno",
        state_code: "BL",
        latitude: "46.24976590",
        longitude: "12.19695650",
        type: "province",
      },
      {
        id: 1701,
        name: "Benevento",
        state_code: "BN",
        latitude: "41.20350930",
        longitude: "14.75209390",
        type: "province",
      },
      {
        id: 1704,
        name: "Bergamo",
        state_code: "BG",
        latitude: "45.69826420",
        longitude: "9.67726980",
        type: "province",
      },
      {
        id: 1778,
        name: "Biella",
        state_code: "BI",
        latitude: "45.56281760",
        longitude: "8.05827170",
        type: "province",
      },
      {
        id: 1717,
        name: "Brescia",
        state_code: "BS",
        latitude: "45.54155260",
        longitude: "10.21180190",
        type: "province",
      },
      {
        id: 1714,
        name: "Brindisi",
        state_code: "BR",
        latitude: "40.61126630",
        longitude: "17.76362100",
        type: "province",
      },
      {
        id: 1703,
        name: "Calabria",
        state_code: "78",
        latitude: "39.30877140",
        longitude: "16.34637910",
        type: "region",
      },
      {
        id: 1718,
        name: "Caltanissetta",
        state_code: "CL",
        latitude: "37.48601300",
        longitude: "14.06149820",
        type: "free municipal consortium",
      },
      {
        id: 1669,
        name: "Campania",
        state_code: "72",
        latitude: "40.66708870",
        longitude: "15.10681130",
        type: "region",
      },
      {
        id: 1721,
        name: "Campobasso",
        state_code: "CB",
        latitude: "41.67388650",
        longitude: "14.75209390",
        type: "province",
      },
      {
        id: 1731,
        name: "Caserta",
        state_code: "CE",
        latitude: "41.20783540",
        longitude: "14.10013260",
        type: "province",
      },
      {
        id: 1728,
        name: "Catanzaro",
        state_code: "CZ",
        latitude: "38.88963480",
        longitude: "16.44058720",
        type: "province",
      },
      {
        id: 1739,
        name: "Chieti",
        state_code: "CH",
        latitude: "42.03344280",
        longitude: "14.37919120",
        type: "province",
      },
      {
        id: 1740,
        name: "Como",
        state_code: "CO",
        latitude: "45.80804160",
        longitude: "9.08517930",
        type: "province",
      },
      {
        id: 1742,
        name: "Cosenza",
        state_code: "CS",
        latitude: "39.56441050",
        longitude: "16.25221430",
        type: "province",
      },
      {
        id: 1751,
        name: "Cremona",
        state_code: "CR",
        latitude: "45.20143750",
        longitude: "9.98365820",
        type: "province",
      },
      {
        id: 1754,
        name: "Crotone",
        state_code: "KR",
        latitude: "39.13098560",
        longitude: "17.00670310",
        type: "province",
      },
      {
        id: 1775,
        name: "Cuneo",
        state_code: "CN",
        latitude: "44.59703140",
        longitude: "7.61142170",
        type: "province",
      },
      {
        id: 1773,
        name: "Emilia-Romagna",
        state_code: "45",
        latitude: "44.59676070",
        longitude: "11.21863960",
        type: "region",
      },
      {
        id: 1723,
        name: "Enna",
        state_code: "EN",
        latitude: "37.56762160",
        longitude: "14.27953490",
        type: "free municipal consortium",
      },
      {
        id: 1744,
        name: "Fermo",
        state_code: "FM",
        latitude: "43.09313670",
        longitude: "13.58997330",
        type: "province",
      },
      {
        id: 1746,
        name: "Ferrara",
        state_code: "FE",
        latitude: "44.76636800",
        longitude: "11.76440680",
        type: "province",
      },
      {
        id: 1771,
        name: "Foggia",
        state_code: "FG",
        latitude: "41.63844800",
        longitude: "15.59433880",
        type: "province",
      },
      {
        id: 1779,
        name: "Forlì-Cesena",
        state_code: "FC",
        latitude: "43.99476810",
        longitude: "11.98046130",
        type: "province",
      },
      {
        id: 1756,
        name: "Friuli–Venezia Giulia",
        state_code: "36",
        latitude: "46.22591770",
        longitude: "13.10336460",
        type: "autonomous region",
      },
      {
        id: 1776,
        name: "Frosinone",
        state_code: "FR",
        latitude: "41.65765280",
        longitude: "13.63627150",
        type: "province",
      },
      {
        id: 1777,
        name: "Gorizia",
        state_code: "GO",
        latitude: "45.90538990",
        longitude: "13.51637250",
        type: "decentralized regional entity",
      },
      {
        id: 1787,
        name: "Grosseto",
        state_code: "GR",
        latitude: "42.85180070",
        longitude: "11.25237920",
        type: "province",
      },
      {
        id: 1788,
        name: "Imperia",
        state_code: "IM",
        latitude: "43.94186600",
        longitude: "7.82863680",
        type: "province",
      },
      {
        id: 1789,
        name: "Isernia",
        state_code: "IS",
        latitude: "41.58915550",
        longitude: "14.19309180",
        type: "province",
      },
      {
        id: 1781,
        name: "L'Aquila",
        state_code: "AQ",
        latitude: "42.12563170",
        longitude: "13.63627150",
        type: "province",
      },
      {
        id: 1791,
        name: "La Spezia",
        state_code: "SP",
        latitude: "44.24479130",
        longitude: "9.76786870",
        type: "province",
      },
      {
        id: 1674,
        name: "Latina",
        state_code: "LT",
        latitude: "41.40874760",
        longitude: "13.08179030",
        type: "province",
      },
      {
        id: 1678,
        name: "Lazio",
        state_code: "62",
        latitude: "41.81224100",
        longitude: "12.73851000",
        type: "region",
      },
      {
        id: 1675,
        name: "Lecce",
        state_code: "LE",
        latitude: "40.23473930",
        longitude: "18.14286690",
        type: "province",
      },
      {
        id: 1677,
        name: "Lecco",
        state_code: "LC",
        latitude: "45.93829410",
        longitude: "9.38572900",
        type: "province",
      },
      {
        id: 1768,
        name: "Liguria",
        state_code: "42",
        latitude: "44.31679170",
        longitude: "8.39649380",
        type: "region",
      },
      {
        id: 1745,
        name: "Livorno",
        state_code: "LI",
        latitude: "43.02398480",
        longitude: "10.66471010",
        type: "province",
      },
      {
        id: 1747,
        name: "Lodi",
        state_code: "LO",
        latitude: "45.24050360",
        longitude: "9.52925120",
        type: "province",
      },
      {
        id: 1705,
        name: "Lombardy",
        state_code: "25",
        latitude: "45.47906710",
        longitude: "9.84524330",
        type: "region",
      },
      {
        id: 1749,
        name: "Lucca",
        state_code: "LU",
        latitude: "43.83767360",
        longitude: "10.49505300",
        type: "province",
      },
      {
        id: 1750,
        name: "Macerata",
        state_code: "MC",
        latitude: "43.24593220",
        longitude: "13.26634790",
        type: "province",
      },
      {
        id: 1758,
        name: "Mantua",
        state_code: "MN",
        latitude: "45.16677280",
        longitude: "10.77536130",
        type: "province",
      },
      {
        id: 1670,
        name: "Marche",
        state_code: "57",
        latitude: "43.30456200",
        longitude: "13.71947000",
        type: "region",
      },
      {
        id: 1759,
        name: "Massa and Carrara",
        state_code: "MS",
        latitude: "44.22139980",
        longitude: "10.03596610",
        type: "province",
      },
      {
        id: 1760,
        name: "Matera",
        state_code: "MT",
        latitude: "40.66634960",
        longitude: "16.60436360",
        type: "province",
      },
      {
        id: 1761,
        name: "Medio Campidano",
        state_code: "VS",
        latitude: "39.53173890",
        longitude: "8.70407500",
        type: "province",
      },
      {
        id: 1757,
        name: "Modena",
        state_code: "MO",
        latitude: "44.55137990",
        longitude: "10.91804800",
        type: "province",
      },
      {
        id: 1695,
        name: "Molise",
        state_code: "67",
        latitude: "41.67388650",
        longitude: "14.75209390",
        type: "region",
      },
      {
        id: 1769,
        name: "Monza and Brianza",
        state_code: "MB",
        latitude: "45.62359900",
        longitude: "9.25880150",
        type: "province",
      },
      {
        id: 1774,
        name: "Novara",
        state_code: "NO",
        latitude: "45.54851330",
        longitude: "8.51507930",
        type: "province",
      },
      {
        id: 1790,
        name: "Nuoro",
        state_code: "NU",
        latitude: "40.32869040",
        longitude: "9.45615500",
        type: "province",
      },
      {
        id: 1786,
        name: "Oristano",
        state_code: "OR",
        latitude: "40.05990680",
        longitude: "8.74811670",
        type: "province",
      },
      {
        id: 1665,
        name: "Padua",
        state_code: "PD",
        latitude: "45.36618640",
        longitude: "11.82091390",
        type: "province",
      },
      {
        id: 1668,
        name: "Palermo",
        state_code: "PA",
        latitude: "38.11569000",
        longitude: "13.36148680",
        type: "province",
      },
      {
        id: 1666,
        name: "Parma",
        state_code: "PR",
        latitude: "44.80153220",
        longitude: "10.32793540",
        type: "province",
      },
      {
        id: 1676,
        name: "Pavia",
        state_code: "PV",
        latitude: "45.32181660",
        longitude: "8.84662360",
        type: "province",
      },
      {
        id: 1691,
        name: "Perugia",
        state_code: "PG",
        latitude: "42.93800400",
        longitude: "12.62162110",
        type: "province",
      },
      {
        id: 1693,
        name: "Pesaro and Urbino",
        state_code: "PU",
        latitude: "43.61301180",
        longitude: "12.71351210",
        type: "province",
      },
      {
        id: 1694,
        name: "Pescara",
        state_code: "PE",
        latitude: "42.35706550",
        longitude: "13.96080910",
        type: "province",
      },
      {
        id: 1696,
        name: "Piacenza",
        state_code: "PC",
        latitude: "44.82631120",
        longitude: "9.52914470",
        type: "province",
      },
      {
        id: 1702,
        name: "Piedmont",
        state_code: "21",
        latitude: "45.05223660",
        longitude: "7.51538850",
        type: "region",
      },
      {
        id: 1685,
        name: "Pisa",
        state_code: "PI",
        latitude: "43.72283150",
        longitude: "10.40171940",
        type: "province",
      },
      {
        id: 1687,
        name: "Pistoia",
        state_code: "PT",
        latitude: "43.95437330",
        longitude: "10.89030990",
        type: "province",
      },
      {
        id: 1690,
        name: "Pordenone",
        state_code: "PN",
        latitude: "46.03788620",
        longitude: "12.71083500",
        type: "decentralized regional entity",
      },
      {
        id: 1697,
        name: "Potenza",
        state_code: "PZ",
        latitude: "40.41821940",
        longitude: "15.87600400",
        type: "province",
      },
      {
        id: 1700,
        name: "Prato",
        state_code: "PO",
        latitude: "44.04539000",
        longitude: "11.11644520",
        type: "province",
      },
      {
        id: 1729,
        name: "Ragusa",
        state_code: "RG",
        latitude: "36.92692730",
        longitude: "14.72551290",
        type: "free municipal consortium",
      },
      {
        id: 1707,
        name: "Ravenna",
        state_code: "RA",
        latitude: "44.41844430",
        longitude: "12.20359980",
        type: "province",
      },
      {
        id: 1708,
        name: "Reggio Emilia",
        state_code: "RE",
        latitude: "44.58565800",
        longitude: "10.55647360",
        type: "province",
      },
      {
        id: 1712,
        name: "Rieti",
        state_code: "RI",
        latitude: "42.36744050",
        longitude: "12.89750980",
        type: "province",
      },
      {
        id: 1713,
        name: "Rimini",
        state_code: "RN",
        latitude: "44.06782880",
        longitude: "12.56951580",
        type: "province",
      },
      {
        id: 1719,
        name: "Rovigo",
        state_code: "RO",
        latitude: "45.02418180",
        longitude: "11.82381620",
        type: "province",
      },
      {
        id: 1720,
        name: "Salerno",
        state_code: "SA",
        latitude: "40.42878320",
        longitude: "15.21948080",
        type: "province",
      },
      {
        id: 1715,
        name: "Sardinia",
        state_code: "88",
        latitude: "40.12087520",
        longitude: "9.01289260",
        type: "autonomous region",
      },
      {
        id: 1722,
        name: "Sassari",
        state_code: "SS",
        latitude: "40.79679070",
        longitude: "8.57504070",
        type: "province",
      },
      {
        id: 1732,
        name: "Savona",
        state_code: "SV",
        latitude: "44.28879950",
        longitude: "8.26505800",
        type: "province",
      },
      {
        id: 1709,
        name: "Sicily",
        state_code: "82",
        latitude: "37.59999380",
        longitude: "14.01535570",
        type: "autonomous region",
      },
      {
        id: 1734,
        name: "Siena",
        state_code: "SI",
        latitude: "43.29377320",
        longitude: "11.43391480",
        type: "province",
      },
      {
        id: 1667,
        name: "Siracusa",
        state_code: "SR",
        latitude: "37.06569240",
        longitude: "15.28571090",
        type: "free municipal consortium",
      },
      {
        id: 1741,
        name: "Sondrio",
        state_code: "SO",
        latitude: "46.17276360",
        longitude: "9.79949170",
        type: "province",
      },
      {
        id: 1730,
        name: "South Sardinia",
        state_code: "SU",
        latitude: "39.38935350",
        longitude: "8.93970000",
        type: "province",
      },
      {
        id: 1743,
        name: "Taranto",
        state_code: "TA",
        latitude: "40.57409010",
        longitude: "17.24299760",
        type: "province",
      },
      {
        id: 1752,
        name: "Teramo",
        state_code: "TE",
        latitude: "42.58956080",
        longitude: "13.63627150",
        type: "province",
      },
      {
        id: 1755,
        name: "Terni",
        state_code: "TR",
        latitude: "42.56345340",
        longitude: "12.52980280",
        type: "province",
      },
      {
        id: 1733,
        name: "Trapani",
        state_code: "TP",
        latitude: "38.01831160",
        longitude: "12.51482650",
        type: "free municipal consortium",
      },
      {
        id: 1725,
        name: "Trentino-South Tyrol",
        state_code: "32",
        latitude: "46.43366620",
        longitude: "11.16932960",
        type: "autonomous region",
      },
      {
        id: 1762,
        name: "Treviso",
        state_code: "TV",
        latitude: "45.66685170",
        longitude: "12.24306170",
        type: "province",
      },
      {
        id: 1763,
        name: "Trieste",
        state_code: "TS",
        latitude: "45.68948230",
        longitude: "13.78330720",
        type: "decentralized regional entity",
      },
      {
        id: 1664,
        name: "Tuscany",
        state_code: "52",
        latitude: "43.77105130",
        longitude: "11.24862080",
        type: "region",
      },
      {
        id: 1764,
        name: "Udine",
        state_code: "UD",
        latitude: "46.14079720",
        longitude: "13.16628960",
        type: "decentralized regional entity",
      },
      {
        id: 1683,
        name: "Umbria",
        state_code: "55",
        latitude: "42.93800400",
        longitude: "12.62162110",
        type: "region",
      },
      {
        id: 1765,
        name: "Varese",
        state_code: "VA",
        latitude: "45.79902600",
        longitude: "8.73009450",
        type: "province",
      },
      {
        id: 1753,
        name: "Veneto",
        state_code: "34",
        latitude: "45.44146620",
        longitude: "12.31525950",
        type: "region",
      },
      {
        id: 1726,
        name: "Verbano-Cusio-Ossola",
        state_code: "VB",
        latitude: "46.13996880",
        longitude: "8.27246490",
        type: "province",
      },
      {
        id: 1785,
        name: "Vercelli",
        state_code: "VC",
        latitude: "45.32022040",
        longitude: "8.41850800",
        type: "province",
      },
      {
        id: 1736,
        name: "Verona",
        state_code: "VR",
        latitude: "45.44184980",
        longitude: "11.07353160",
        type: "province",
      },
      {
        id: 1737,
        name: "Vibo Valentia",
        state_code: "VV",
        latitude: "38.63785650",
        longitude: "16.20514840",
        type: "province",
      },
      {
        id: 1738,
        name: "Vicenza",
        state_code: "VI",
        latitude: "45.69839950",
        longitude: "11.56614650",
        type: "province",
      },
      {
        id: 1735,
        name: "Viterbo",
        state_code: "VT",
        latitude: "42.40024200",
        longitude: "11.88917210",
        type: "province",
      },
    ],
  },
  {
    name: "Jamaica",
    iso3: "JAM",
    iso2: "JM",
    numeric_code: "388",
    phone_code: "+1-876",
    capital: "Kingston",
    currency: "JMD",
    currency_name: "Jamaican dollar",
    currency_symbol: "J$",
    tld: ".jm",
    native: "Jamaica",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Jamaican",
    timezones: [
      {
        zoneName: "America/Jamaica",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "자메이카",
      "pt-BR": "Jamaica",
      pt: "Jamaica",
      nl: "Jamaica",
      hr: "Jamajka",
      fa: "جامائیکا",
      de: "Jamaika",
      es: "Jamaica",
      fr: "Jamaïque",
      ja: "ジャマイカ",
      it: "Giamaica",
      cn: "牙买加",
      tr: "Jamaika",
    },
    latitude: "18.25000000",
    longitude: "-77.50000000",
    emoji: "🇯🇲",
    emojiU: "U+1F1EF U+1F1F2",
    states: [
      {
        id: 3753,
        name: "Clarendon Parish",
        state_code: "13",
        latitude: "17.95571830",
        longitude: "-77.24051530",
        type: null,
      },
      {
        id: 3749,
        name: "Hanover Parish",
        state_code: "09",
        latitude: "18.40977070",
        longitude: "-78.13363800",
        type: null,
      },
      {
        id: 3748,
        name: "Kingston Parish",
        state_code: "01",
        latitude: "17.96832710",
        longitude: "-76.78270200",
        type: null,
      },
      {
        id: 3754,
        name: "Manchester Parish",
        state_code: "12",
        latitude: "18.06696540",
        longitude: "-77.51607880",
        type: null,
      },
      {
        id: 3752,
        name: "Portland Parish",
        state_code: "04",
        latitude: "18.08442740",
        longitude: "-76.41002670",
        type: null,
      },
      {
        id: 3751,
        name: "Saint Andrew",
        state_code: "02",
        latitude: "37.22451030",
        longitude: "-95.70211890",
        type: null,
      },
      {
        id: 3744,
        name: "Saint Ann Parish",
        state_code: "06",
        latitude: "37.28714520",
        longitude: "-77.41035330",
        type: null,
      },
      {
        id: 3746,
        name: "Saint Catherine Parish",
        state_code: "14",
        latitude: "18.03641340",
        longitude: "-77.05644640",
        type: null,
      },
      {
        id: 3743,
        name: "Saint Elizabeth Parish",
        state_code: "11",
        latitude: "38.99253080",
        longitude: "-94.58992000",
        type: null,
      },
      {
        id: 3745,
        name: "Saint James Parish",
        state_code: "08",
        latitude: "30.01792920",
        longitude: "-90.79132270",
        type: null,
      },
      {
        id: 3747,
        name: "Saint Mary Parish",
        state_code: "05",
        latitude: "36.09252200",
        longitude: "-95.97384400",
        type: null,
      },
      {
        id: 3750,
        name: "Saint Thomas Parish",
        state_code: "03",
        latitude: "41.44253890",
        longitude: "-81.74022180",
        type: null,
      },
      {
        id: 3755,
        name: "Trelawny Parish",
        state_code: "07",
        latitude: "18.35261430",
        longitude: "-77.60778650",
        type: null,
      },
      {
        id: 3742,
        name: "Westmoreland Parish",
        state_code: "10",
        latitude: "18.29443780",
        longitude: "-78.15644320",
        type: null,
      },
    ],
  },
  {
    name: "Japan",
    iso3: "JPN",
    iso2: "JP",
    numeric_code: "392",
    phone_code: "81",
    capital: "Tokyo",
    currency: "JPY",
    currency_name: "Japanese yen",
    currency_symbol: "¥",
    tld: ".jp",
    native: "日本",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Japanese",
    timezones: [
      {
        zoneName: "Asia/Tokyo",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "JST",
        tzName: "Japan Standard Time",
      },
    ],
    translations: {
      kr: "일본",
      "pt-BR": "Japão",
      pt: "Japão",
      nl: "Japan",
      hr: "Japan",
      fa: "ژاپن",
      de: "Japan",
      es: "Japón",
      fr: "Japon",
      ja: "日本",
      it: "Giappone",
      cn: "日本",
      tr: "Japonya",
    },
    latitude: "36.00000000",
    longitude: "138.00000000",
    emoji: "🇯🇵",
    emojiU: "U+1F1EF U+1F1F5",
    states: [
      {
        id: 827,
        name: "Aichi Prefecture",
        state_code: "23",
        latitude: "35.01825050",
        longitude: "137.29238930",
        type: null,
      },
      {
        id: 829,
        name: "Akita Prefecture",
        state_code: "05",
        latitude: "40.13762930",
        longitude: "140.33434100",
        type: null,
      },
      {
        id: 839,
        name: "Aomori Prefecture",
        state_code: "02",
        latitude: "40.76570770",
        longitude: "140.91758790",
        type: null,
      },
      {
        id: 821,
        name: "Chiba Prefecture",
        state_code: "12",
        latitude: "35.33541550",
        longitude: "140.18325160",
        type: null,
      },
      {
        id: 865,
        name: "Ehime Prefecture",
        state_code: "38",
        latitude: "33.60253060",
        longitude: "132.78575830",
        type: null,
      },
      {
        id: 848,
        name: "Fukui Prefecture",
        state_code: "18",
        latitude: "35.89622700",
        longitude: "136.21115790",
        type: null,
      },
      {
        id: 861,
        name: "Fukuoka Prefecture",
        state_code: "40",
        latitude: "33.56625590",
        longitude: "130.71585700",
        type: null,
      },
      {
        id: 847,
        name: "Fukushima Prefecture",
        state_code: "07",
        latitude: "37.38343730",
        longitude: "140.18325160",
        type: null,
      },
      {
        id: 858,
        name: "Gifu Prefecture",
        state_code: "21",
        latitude: "35.74374910",
        longitude: "136.98051030",
        type: null,
      },
      {
        id: 862,
        name: "Gunma Prefecture",
        state_code: "10",
        latitude: "36.56053880",
        longitude: "138.87999720",
        type: null,
      },
      {
        id: 828,
        name: "Hiroshima Prefecture",
        state_code: "34",
        latitude: "34.88234080",
        longitude: "133.01948970",
        type: null,
      },
      {
        id: 832,
        name: "Hokkaidō Prefecture",
        state_code: "01",
        latitude: "43.22032660",
        longitude: "142.86347370",
        type: null,
      },
      {
        id: 831,
        name: "Hyōgo Prefecture",
        state_code: "28",
        latitude: "34.85795180",
        longitude: "134.54537870",
        type: null,
      },
      {
        id: 851,
        name: "Ibaraki Prefecture",
        state_code: "08",
        latitude: "36.21935710",
        longitude: "140.18325160",
        type: null,
      },
      {
        id: 830,
        name: "Ishikawa Prefecture",
        state_code: "17",
        latitude: "36.32603170",
        longitude: "136.52896530",
        type: null,
      },
      {
        id: 856,
        name: "Iwate Prefecture",
        state_code: "03",
        latitude: "39.58329890",
        longitude: "141.25345740",
        type: null,
      },
      {
        id: 864,
        name: "Kagawa Prefecture",
        state_code: "37",
        latitude: "34.22259150",
        longitude: "134.01991520",
        type: null,
      },
      {
        id: 840,
        name: "Kagoshima Prefecture",
        state_code: "46",
        latitude: "31.39119580",
        longitude: "130.87785860",
        type: null,
      },
      {
        id: 842,
        name: "Kanagawa Prefecture",
        state_code: "14",
        latitude: "35.49135350",
        longitude: "139.28414300",
        type: null,
      },
      {
        id: 4924,
        name: "Kōchi Prefecture",
        state_code: "39",
        latitude: "33.28791610",
        longitude: "132.27592620",
        type: null,
      },
      {
        id: 846,
        name: "Kumamoto Prefecture",
        state_code: "43",
        latitude: "32.85944270",
        longitude: "130.79691490",
        type: null,
      },
      {
        id: 834,
        name: "Kyōto Prefecture",
        state_code: "26",
        latitude: "35.15666090",
        longitude: "135.52519820",
        type: null,
      },
      {
        id: 833,
        name: "Mie Prefecture",
        state_code: "24",
        latitude: "33.81439010",
        longitude: "136.04870470",
        type: null,
      },
      {
        id: 857,
        name: "Miyagi Prefecture",
        state_code: "04",
        latitude: "38.63061200",
        longitude: "141.11930480",
        type: null,
      },
      {
        id: 855,
        name: "Miyazaki Prefecture",
        state_code: "45",
        latitude: "32.60360220",
        longitude: "131.44125100",
        type: null,
      },
      {
        id: 843,
        name: "Nagano Prefecture",
        state_code: "20",
        latitude: "36.15439410",
        longitude: "137.92182040",
        type: null,
      },
      {
        id: 849,
        name: "Nagasaki Prefecture",
        state_code: "42",
        latitude: "33.24885250",
        longitude: "129.69309120",
        type: null,
      },
      {
        id: 824,
        name: "Nara Prefecture",
        state_code: "29",
        latitude: "34.29755280",
        longitude: "135.82797340",
        type: null,
      },
      {
        id: 841,
        name: "Niigata Prefecture",
        state_code: "15",
        latitude: "37.51783860",
        longitude: "138.92697940",
        type: null,
      },
      {
        id: 822,
        name: "Ōita Prefecture",
        state_code: "44",
        latitude: "33.15892990",
        longitude: "131.36111210",
        type: null,
      },
      {
        id: 820,
        name: "Okayama Prefecture",
        state_code: "33",
        latitude: "34.89634070",
        longitude: "133.63753140",
        type: null,
      },
      {
        id: 853,
        name: "Okinawa Prefecture",
        state_code: "47",
        latitude: "26.12019110",
        longitude: "127.70250120",
        type: null,
      },
      {
        id: 859,
        name: "Ōsaka Prefecture",
        state_code: "27",
        latitude: "34.64133150",
        longitude: "135.56293940",
        type: null,
      },
      {
        id: 863,
        name: "Saga Prefecture",
        state_code: "41",
        latitude: "33.30783710",
        longitude: "130.22712430",
        type: null,
      },
      {
        id: 860,
        name: "Saitama Prefecture",
        state_code: "11",
        latitude: "35.99625130",
        longitude: "139.44660050",
        type: null,
      },
      {
        id: 845,
        name: "Shiga Prefecture",
        state_code: "25",
        latitude: "35.32920140",
        longitude: "136.05632120",
        type: null,
      },
      {
        id: 826,
        name: "Shimane Prefecture",
        state_code: "32",
        latitude: "35.12440940",
        longitude: "132.62934460",
        type: null,
      },
      {
        id: 825,
        name: "Shizuoka Prefecture",
        state_code: "22",
        latitude: "35.09293970",
        longitude: "138.31902760",
        type: null,
      },
      {
        id: 854,
        name: "Tochigi Prefecture",
        state_code: "09",
        latitude: "36.67147390",
        longitude: "139.85472660",
        type: null,
      },
      {
        id: 836,
        name: "Tokushima Prefecture",
        state_code: "36",
        latitude: "33.94196550",
        longitude: "134.32365570",
        type: null,
      },
      {
        id: 823,
        name: "Tokyo",
        state_code: "13",
        latitude: "35.67619190",
        longitude: "139.65031060",
        type: null,
      },
      {
        id: 850,
        name: "Tottori Prefecture",
        state_code: "31",
        latitude: "35.35731610",
        longitude: "133.40666180",
        type: null,
      },
      {
        id: 838,
        name: "Toyama Prefecture",
        state_code: "16",
        latitude: "36.69582660",
        longitude: "137.21370710",
        type: null,
      },
      {
        id: 844,
        name: "Wakayama Prefecture",
        state_code: "30",
        latitude: "33.94809140",
        longitude: "135.37453580",
        type: null,
      },
      {
        id: 837,
        name: "Yamagata Prefecture",
        state_code: "06",
        latitude: "38.53705640",
        longitude: "140.14351980",
        type: null,
      },
      {
        id: 835,
        name: "Yamaguchi Prefecture",
        state_code: "35",
        latitude: "34.27967690",
        longitude: "131.52127420",
        type: null,
      },
      {
        id: 852,
        name: "Yamanashi Prefecture",
        state_code: "19",
        latitude: "35.66351130",
        longitude: "138.63888790",
        type: null,
      },
    ],
  },
  {
    name: "Jersey",
    iso3: "JEY",
    iso2: "JE",
    numeric_code: "832",
    phone_code: "+44-1534",
    capital: "Saint Helier",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".je",
    native: "Jersey",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Channel Island",
    timezones: [
      {
        zoneName: "Europe/Jersey",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "저지 섬",
      "pt-BR": "Jersey",
      pt: "Jersey",
      nl: "Jersey",
      hr: "Jersey",
      fa: "جرزی",
      de: "Jersey",
      es: "Jersey",
      fr: "Jersey",
      ja: "ジャージー",
      it: "Isola di Jersey",
      cn: "泽西岛",
      tr: "Jersey",
    },
    latitude: "49.25000000",
    longitude: "-2.16666666",
    emoji: "🇯🇪",
    emojiU: "U+1F1EF U+1F1EA",
    states: [],
  },
  {
    name: "Jordan",
    iso3: "JOR",
    iso2: "JO",
    numeric_code: "400",
    phone_code: "962",
    capital: "Amman",
    currency: "JOD",
    currency_name: "Jordanian dinar",
    currency_symbol: "ا.د",
    tld: ".jo",
    native: "الأردن",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Jordanian",
    timezones: [
      {
        zoneName: "Asia/Amman",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "요르단",
      "pt-BR": "Jordânia",
      pt: "Jordânia",
      nl: "Jordanië",
      hr: "Jordan",
      fa: "اردن",
      de: "Jordanien",
      es: "Jordania",
      fr: "Jordanie",
      ja: "ヨルダン",
      it: "Giordania",
      cn: "约旦",
      tr: "Ürdün",
    },
    latitude: "31.00000000",
    longitude: "36.00000000",
    emoji: "🇯🇴",
    emojiU: "U+1F1EF U+1F1F4",
    states: [
      {
        id: 963,
        name: "Ajloun",
        state_code: "AJ",
        latitude: "32.33255840",
        longitude: "35.75168440",
        type: null,
      },
      {
        id: 965,
        name: "Amman",
        state_code: "AM",
        latitude: "31.94536330",
        longitude: "35.92838950",
        type: null,
      },
      {
        id: 959,
        name: "Aqaba",
        state_code: "AQ",
        latitude: "29.53208600",
        longitude: "35.00628210",
        type: null,
      },
      {
        id: 961,
        name: "Balqa",
        state_code: "BA",
        latitude: "32.03668060",
        longitude: "35.72884800",
        type: null,
      },
      {
        id: 960,
        name: "Irbid",
        state_code: "IR",
        latitude: "32.55696360",
        longitude: "35.84789650",
        type: null,
      },
      {
        id: 966,
        name: "Jerash",
        state_code: "JA",
        latitude: "32.27472370",
        longitude: "35.89609540",
        type: null,
      },
      {
        id: 956,
        name: "Karak",
        state_code: "KA",
        latitude: "31.18535270",
        longitude: "35.70476820",
        type: null,
      },
      {
        id: 964,
        name: "Ma'an",
        state_code: "MN",
        latitude: "30.19267890",
        longitude: "35.72493190",
        type: null,
      },
      {
        id: 958,
        name: "Madaba",
        state_code: "MD",
        latitude: "31.71960970",
        longitude: "35.79327540",
        type: null,
      },
      {
        id: 962,
        name: "Mafraq",
        state_code: "MA",
        latitude: "32.34169230",
        longitude: "36.20201750",
        type: null,
      },
      {
        id: 957,
        name: "Tafilah",
        state_code: "AT",
        latitude: "30.83380630",
        longitude: "35.61605130",
        type: null,
      },
      {
        id: 967,
        name: "Zarqa",
        state_code: "AZ",
        latitude: "32.06085050",
        longitude: "36.09421210",
        type: null,
      },
    ],
  },
  {
    name: "Kazakhstan",
    iso3: "KAZ",
    iso2: "KZ",
    numeric_code: "398",
    phone_code: "7",
    capital: "Astana",
    currency: "KZT",
    currency_name: "Kazakhstani tenge",
    currency_symbol: "лв",
    tld: ".kz",
    native: "Қазақстан",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Kazakhstani, Kazakh",
    timezones: [
      {
        zoneName: "Asia/Almaty",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "ALMT",
        tzName: "Alma-Ata Time[1",
      },
      {
        zoneName: "Asia/Aqtau",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time",
      },
      {
        zoneName: "Asia/Aqtobe",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "AQTT",
        tzName: "Aqtobe Time",
      },
      {
        zoneName: "Asia/Atyrau",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MSD+1",
        tzName: "Moscow Daylight Time+1",
      },
      {
        zoneName: "Asia/Oral",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "ORAT",
        tzName: "Oral Time",
      },
      {
        zoneName: "Asia/Qostanay",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "QYZST",
        tzName: "Qyzylorda Summer Time",
      },
      {
        zoneName: "Asia/Qyzylorda",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "QYZT",
        tzName: "Qyzylorda Summer Time",
      },
    ],
    translations: {
      kr: "카자흐스탄",
      "pt-BR": "Cazaquistão",
      pt: "Cazaquistão",
      nl: "Kazachstan",
      hr: "Kazahstan",
      fa: "قزاقستان",
      de: "Kasachstan",
      es: "Kazajistán",
      fr: "Kazakhstan",
      ja: "カザフスタン",
      it: "Kazakistan",
      cn: "哈萨克斯坦",
      tr: "Kazakistan",
    },
    latitude: "48.00000000",
    longitude: "68.00000000",
    emoji: "🇰🇿",
    emojiU: "U+1F1F0 U+1F1FF",
    states: [
      {
        id: 145,
        name: "Akmola Region",
        state_code: "AKM",
        latitude: "51.91653200",
        longitude: "69.41104940",
        type: null,
      },
      {
        id: 151,
        name: "Aktobe Region",
        state_code: "AKT",
        latitude: "48.77970780",
        longitude: "57.99743780",
        type: null,
      },
      {
        id: 152,
        name: "Almaty",
        state_code: "ALA",
        latitude: "43.22201460",
        longitude: "76.85124850",
        type: null,
      },
      {
        id: 143,
        name: "Almaty Region",
        state_code: "ALM",
        latitude: "45.01192270",
        longitude: "78.42293920",
        type: null,
      },
      {
        id: 153,
        name: "Atyrau Region",
        state_code: "ATY",
        latitude: "47.10761880",
        longitude: "51.91413300",
        type: null,
      },
      {
        id: 155,
        name: "Baikonur",
        state_code: "BAY",
        latitude: "45.96458510",
        longitude: "63.30524280",
        type: null,
      },
      {
        id: 154,
        name: "East Kazakhstan Region",
        state_code: "VOS",
        latitude: "48.70626870",
        longitude: "80.79225340",
        type: null,
      },
      {
        id: 147,
        name: "Jambyl Region",
        state_code: "ZHA",
        latitude: "44.22203080",
        longitude: "72.36579670",
        type: null,
      },
      {
        id: 150,
        name: "Karaganda Region",
        state_code: "KAR",
        latitude: "47.90221820",
        longitude: "71.77068070",
        type: null,
      },
      {
        id: 157,
        name: "Kostanay Region",
        state_code: "KUS",
        latitude: "51.50770960",
        longitude: "64.04790730",
        type: null,
      },
      {
        id: 142,
        name: "Kyzylorda Region",
        state_code: "KZY",
        latitude: "44.69226130",
        longitude: "62.65718850",
        type: null,
      },
      {
        id: 141,
        name: "Mangystau Region",
        state_code: "MAN",
        latitude: "44.59080200",
        longitude: "53.84995080",
        type: null,
      },
      {
        id: 144,
        name: "North Kazakhstan Region",
        state_code: "SEV",
        latitude: "54.16220660",
        longitude: "69.93870710",
        type: null,
      },
      {
        id: 156,
        name: "Nur-Sultan",
        state_code: "AST",
        latitude: "51.16052270",
        longitude: "71.47035580",
        type: null,
      },
      {
        id: 146,
        name: "Pavlodar Region",
        state_code: "PAV",
        latitude: "52.28784440",
        longitude: "76.97334530",
        type: null,
      },
      {
        id: 149,
        name: "Turkestan Region",
        state_code: "YUZ",
        latitude: "43.36669580",
        longitude: "68.40944050",
        type: null,
      },
      {
        id: 148,
        name: "West Kazakhstan Province",
        state_code: "ZAP",
        latitude: "49.56797270",
        longitude: "50.80666160",
        type: null,
      },
    ],
  },
  {
    name: "Kenya",
    iso3: "KEN",
    iso2: "KE",
    numeric_code: "404",
    phone_code: "254",
    capital: "Nairobi",
    currency: "KES",
    currency_name: "Kenyan shilling",
    currency_symbol: "KSh",
    tld: ".ke",
    native: "Kenya",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Kenyan",
    timezones: [
      {
        zoneName: "Africa/Nairobi",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "케냐",
      "pt-BR": "Quênia",
      pt: "Quénia",
      nl: "Kenia",
      hr: "Kenija",
      fa: "کنیا",
      de: "Kenia",
      es: "Kenia",
      fr: "Kenya",
      ja: "ケニア",
      it: "Kenya",
      cn: "肯尼亚",
      tr: "Kenya",
    },
    latitude: "1.00000000",
    longitude: "38.00000000",
    emoji: "🇰🇪",
    emojiU: "U+1F1F0 U+1F1EA",
    states: [
      {
        id: 181,
        name: "Baringo",
        state_code: "01",
        latitude: "0.85549880",
        longitude: "36.08934060",
        type: "county",
      },
      {
        id: 210,
        name: "Bomet",
        state_code: "02",
        latitude: "-0.80150090",
        longitude: "35.30272260",
        type: "county",
      },
      {
        id: 168,
        name: "Bungoma",
        state_code: "03",
        latitude: "0.56952520",
        longitude: "34.55837660",
        type: "county",
      },
      {
        id: 161,
        name: "Busia",
        state_code: "04",
        latitude: "0.43465060",
        longitude: "34.24215970",
        type: "county",
      },
      {
        id: 201,
        name: "Elgeyo-Marakwet",
        state_code: "05",
        latitude: "1.04982370",
        longitude: "35.47819260",
        type: "county",
      },
      {
        id: 163,
        name: "Embu",
        state_code: "06",
        latitude: "-0.65604770",
        longitude: "37.72376780",
        type: "county",
      },
      {
        id: 196,
        name: "Garissa",
        state_code: "07",
        latitude: "-0.45322930",
        longitude: "39.64609880",
        type: "county",
      },
      {
        id: 195,
        name: "Homa Bay",
        state_code: "08",
        latitude: "-0.62206550",
        longitude: "34.33103640",
        type: "county",
      },
      {
        id: 170,
        name: "Isiolo",
        state_code: "09",
        latitude: "0.35243520",
        longitude: "38.48499230",
        type: "county",
      },
      {
        id: 197,
        name: "Kajiado",
        state_code: "10",
        latitude: "-2.09807510",
        longitude: "36.78195050",
        type: "county",
      },
      {
        id: 158,
        name: "Kakamega",
        state_code: "11",
        latitude: "0.30789400",
        longitude: "34.77407930",
        type: "county",
      },
      {
        id: 193,
        name: "Kericho",
        state_code: "12",
        latitude: "-0.18279130",
        longitude: "35.47819260",
        type: "county",
      },
      {
        id: 199,
        name: "Kiambu",
        state_code: "13",
        latitude: "-1.03137010",
        longitude: "36.86807910",
        type: "county",
      },
      {
        id: 174,
        name: "Kilifi",
        state_code: "14",
        latitude: "-3.51065080",
        longitude: "39.90932690",
        type: "county",
      },
      {
        id: 167,
        name: "Kirinyaga",
        state_code: "15",
        latitude: "-0.65905650",
        longitude: "37.38272340",
        type: "county",
      },
      {
        id: 159,
        name: "Kisii",
        state_code: "16",
        latitude: "-0.67733400",
        longitude: "34.77960300",
        type: "county",
      },
      {
        id: 171,
        name: "Kisumu",
        state_code: "17",
        latitude: "-0.09170160",
        longitude: "34.76795680",
        type: "county",
      },
      {
        id: 211,
        name: "Kitui",
        state_code: "18",
        latitude: "-1.68328220",
        longitude: "38.31657250",
        type: "county",
      },
      {
        id: 173,
        name: "Kwale",
        state_code: "19",
        latitude: "-4.18161150",
        longitude: "39.46056120",
        type: "county",
      },
      {
        id: 164,
        name: "Laikipia",
        state_code: "20",
        latitude: "0.36060630",
        longitude: "36.78195050",
        type: "county",
      },
      {
        id: 166,
        name: "Lamu",
        state_code: "21",
        latitude: "-2.23550580",
        longitude: "40.47200040",
        type: "county",
      },
      {
        id: 184,
        name: "Machakos",
        state_code: "22",
        latitude: "-1.51768370",
        longitude: "37.26341460",
        type: "county",
      },
      {
        id: 188,
        name: "Makueni",
        state_code: "23",
        latitude: "-2.25587340",
        longitude: "37.89366630",
        type: "county",
      },
      {
        id: 187,
        name: "Mandera",
        state_code: "24",
        latitude: "3.57379910",
        longitude: "40.95868800",
        type: "county",
      },
      {
        id: 194,
        name: "Marsabit",
        state_code: "25",
        latitude: "2.44264030",
        longitude: "37.97845850",
        type: "county",
      },
      {
        id: 198,
        name: "Meru",
        state_code: "26",
        latitude: "0.35571740",
        longitude: "37.80876930",
        type: "county",
      },
      {
        id: 190,
        name: "Migori",
        state_code: "27",
        latitude: "-0.93657020",
        longitude: "34.41982430",
        type: "county",
      },
      {
        id: 200,
        name: "Mombasa",
        state_code: "28",
        latitude: "-3.97682910",
        longitude: "39.71371810",
        type: "county",
      },
      {
        id: 178,
        name: "Murang'a",
        state_code: "29",
        latitude: "-0.78392810",
        longitude: "37.04003390",
        type: "county",
      },
      {
        id: 191,
        name: "Nairobi City",
        state_code: "30",
        latitude: "-1.29206590",
        longitude: "36.82194620",
        type: "county",
      },
      {
        id: 203,
        name: "Nakuru",
        state_code: "31",
        latitude: "-0.30309880",
        longitude: "36.08002600",
        type: "county",
      },
      {
        id: 165,
        name: "Nandi",
        state_code: "32",
        latitude: "0.18358670",
        longitude: "35.12687810",
        type: "county",
      },
      {
        id: 175,
        name: "Narok",
        state_code: "33",
        latitude: "-1.10411100",
        longitude: "36.08934060",
        type: "county",
      },
      {
        id: 209,
        name: "Nyamira",
        state_code: "34",
        latitude: "-0.56694050",
        longitude: "34.93412340",
        type: "county",
      },
      {
        id: 192,
        name: "Nyandarua",
        state_code: "35",
        latitude: "-0.18038550",
        longitude: "36.52296410",
        type: "county",
      },
      {
        id: 180,
        name: "Nyeri",
        state_code: "36",
        latitude: "-0.41969150",
        longitude: "37.04003390",
        type: "county",
      },
      {
        id: 207,
        name: "Samburu",
        state_code: "37",
        latitude: "1.21545060",
        longitude: "36.95410700",
        type: "county",
      },
      {
        id: 186,
        name: "Siaya",
        state_code: "38",
        latitude: "-0.06173280",
        longitude: "34.24215970",
        type: "county",
      },
      {
        id: 176,
        name: "Taita–Taveta",
        state_code: "39",
        latitude: "-3.31606870",
        longitude: "38.48499230",
        type: "county",
      },
      {
        id: 205,
        name: "Tana River",
        state_code: "40",
        latitude: "-1.65184680",
        longitude: "39.65181650",
        type: "county",
      },
      {
        id: 185,
        name: "Tharaka-Nithi",
        state_code: "41",
        latitude: "-0.29648510",
        longitude: "37.72376780",
        type: "county",
      },
      {
        id: 183,
        name: "Trans Nzoia",
        state_code: "42",
        latitude: "1.05666670",
        longitude: "34.95066250",
        type: "county",
      },
      {
        id: 206,
        name: "Turkana",
        state_code: "43",
        latitude: "3.31224770",
        longitude: "35.56578620",
        type: "county",
      },
      {
        id: 169,
        name: "Uasin Gishu",
        state_code: "44",
        latitude: "0.55276380",
        longitude: "35.30272260",
        type: "county",
      },
      {
        id: 202,
        name: "Vihiga",
        state_code: "45",
        latitude: "0.07675530",
        longitude: "34.70776650",
        type: "county",
      },
      {
        id: 182,
        name: "Wajir",
        state_code: "46",
        latitude: "1.63604750",
        longitude: "40.30886260",
        type: "county",
      },
      {
        id: 208,
        name: "West Pokot",
        state_code: "47",
        latitude: "1.62100760",
        longitude: "35.39050460",
        type: "county",
      },
    ],
  },
  {
    name: "Kiribati",
    iso3: "KIR",
    iso2: "KI",
    numeric_code: "296",
    phone_code: "686",
    capital: "Tarawa",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".ki",
    native: "Kiribati",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "I-Kiribati",
    timezones: [
      {
        zoneName: "Pacific/Enderbury",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "PHOT",
        tzName: "Phoenix Island Time",
      },
      {
        zoneName: "Pacific/Kiritimati",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "LINT",
        tzName: "Line Islands Time",
      },
      {
        zoneName: "Pacific/Tarawa",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "GILT",
        tzName: "Gilbert Island Time",
      },
    ],
    translations: {
      kr: "키리바시",
      "pt-BR": "Kiribati",
      pt: "Quiribáti",
      nl: "Kiribati",
      hr: "Kiribati",
      fa: "کیریباتی",
      de: "Kiribati",
      es: "Kiribati",
      fr: "Kiribati",
      ja: "キリバス",
      it: "Kiribati",
      cn: "基里巴斯",
      tr: "Kiribati",
    },
    latitude: "1.41666666",
    longitude: "173.00000000",
    emoji: "🇰🇮",
    emojiU: "U+1F1F0 U+1F1EE",
    states: [
      {
        id: 1831,
        name: "Gilbert Islands",
        state_code: "G",
        latitude: "0.35242620",
        longitude: "174.75526340",
        type: null,
      },
      {
        id: 1832,
        name: "Line Islands",
        state_code: "L",
        latitude: "1.74294390",
        longitude: "-157.21328260",
        type: null,
      },
      {
        id: 1830,
        name: "Phoenix Islands",
        state_code: "P",
        latitude: "33.32843690",
        longitude: "-111.98247740",
        type: null,
      },
    ],
  },
  {
    name: "Kosovo",
    iso3: "XKX",
    iso2: "XK",
    numeric_code: "926",
    phone_code: "383",
    capital: "Pristina",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".xk",
    native: "Republika e Kosovës",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Kosovar, Kosovan",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "코소보",
      cn: "科索沃",
      tr: "Kosova",
    },
    latitude: "42.56129090",
    longitude: "20.34030350",
    emoji: "🇽🇰",
    emojiU: "U+1F1FD U+1F1F0",
    states: [
      {
        id: 4876,
        name: "Đakovica District (Gjakove)",
        state_code: "XDG",
        latitude: "42.43757560",
        longitude: "20.37854380",
        type: null,
      },
      {
        id: 4877,
        name: "Gjilan District",
        state_code: "XGJ",
        latitude: "42.46352060",
        longitude: "21.46940110",
        type: null,
      },
      {
        id: 4878,
        name: "Kosovska Mitrovica District",
        state_code: "XKM",
        latitude: "42.89139090",
        longitude: "20.86599950",
        type: null,
      },
      {
        id: 3738,
        name: "Peć District",
        state_code: "XPE",
        latitude: "42.65921550",
        longitude: "20.28876240",
        type: null,
      },
      {
        id: 4879,
        name: "Pristina (Priştine)",
        state_code: "XPI",
        latitude: "42.66291380",
        longitude: "21.16550280",
        type: null,
      },
      {
        id: 3723,
        name: "Prizren District",
        state_code: "XPR",
        latitude: "42.21525220",
        longitude: "20.74147720",
        type: null,
      },
      {
        id: 4874,
        name: "Uroševac District (Ferizaj)",
        state_code: "XUF",
        latitude: "42.37018440",
        longitude: "21.14832810",
        type: null,
      },
    ],
  },
  {
    name: "Kuwait",
    iso3: "KWT",
    iso2: "KW",
    numeric_code: "414",
    phone_code: "965",
    capital: "Kuwait City",
    currency: "KWD",
    currency_name: "Kuwaiti dinar",
    currency_symbol: "ك.د",
    tld: ".kw",
    native: "الكويت",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Kuwaiti",
    timezones: [
      {
        zoneName: "Asia/Kuwait",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "쿠웨이트",
      "pt-BR": "Kuwait",
      pt: "Kuwait",
      nl: "Koeweit",
      hr: "Kuvajt",
      fa: "کویت",
      de: "Kuwait",
      es: "Kuwait",
      fr: "Koweït",
      ja: "クウェート",
      it: "Kuwait",
      cn: "科威特",
      tr: "Kuveyt",
    },
    latitude: "29.50000000",
    longitude: "45.75000000",
    emoji: "🇰🇼",
    emojiU: "U+1F1F0 U+1F1FC",
    states: [
      {
        id: 977,
        name: "Al Ahmadi",
        state_code: "AH",
        latitude: "28.57451250",
        longitude: "48.10247430",
        type: null,
      },
      {
        id: 975,
        name: "Al Farwaniyah",
        state_code: "FA",
        latitude: "29.27335700",
        longitude: "47.94001540",
        type: null,
      },
      {
        id: 972,
        name: "Al Jahra",
        state_code: "JA",
        latitude: "29.99318310",
        longitude: "47.76347310",
        type: null,
      },
      {
        id: 976,
        name: "Capital",
        state_code: "KU",
        latitude: "26.22851610",
        longitude: "50.58604970",
        type: null,
      },
      {
        id: 973,
        name: "Hawalli",
        state_code: "HA",
        latitude: "29.30567160",
        longitude: "48.03076130",
        type: null,
      },
      {
        id: 974,
        name: "Mubarak Al-Kabeer",
        state_code: "MU",
        latitude: "29.21224000",
        longitude: "48.06051080",
        type: null,
      },
    ],
  },
  {
    name: "Kyrgyzstan",
    iso3: "KGZ",
    iso2: "KG",
    numeric_code: "417",
    phone_code: "996",
    capital: "Bishkek",
    currency: "KGS",
    currency_name: "Kyrgyzstani som",
    currency_symbol: "лв",
    tld: ".kg",
    native: "Кыргызстан",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Kyrgyzstani, Kyrgyz, Kirgiz, Kirghiz",
    timezones: [
      {
        zoneName: "Asia/Bishkek",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "KGT",
        tzName: "Kyrgyzstan Time",
      },
    ],
    translations: {
      kr: "키르기스스탄",
      "pt-BR": "Quirguistão",
      pt: "Quirguizistão",
      nl: "Kirgizië",
      hr: "Kirgistan",
      fa: "قرقیزستان",
      de: "Kirgisistan",
      es: "Kirguizistán",
      fr: "Kirghizistan",
      ja: "キルギス",
      it: "Kirghizistan",
      cn: "吉尔吉斯斯坦",
      tr: "Kirgizistan",
    },
    latitude: "41.00000000",
    longitude: "75.00000000",
    emoji: "🇰🇬",
    emojiU: "U+1F1F0 U+1F1EC",
    states: [
      {
        id: 998,
        name: "Batken Region",
        state_code: "B",
        latitude: "39.97214250",
        longitude: "69.85974060",
        type: null,
      },
      {
        id: 1001,
        name: "Bishkek",
        state_code: "GB",
        latitude: "42.87462120",
        longitude: "74.56976170",
        type: null,
      },
      {
        id: 1004,
        name: "Chuy Region",
        state_code: "C",
        latitude: "42.56550000",
        longitude: "74.40566120",
        type: null,
      },
      {
        id: 1002,
        name: "Issyk-Kul Region",
        state_code: "Y",
        latitude: "42.18594280",
        longitude: "77.56194190",
        type: null,
      },
      {
        id: 1000,
        name: "Jalal-Abad Region",
        state_code: "J",
        latitude: "41.10680800",
        longitude: "72.89880690",
        type: null,
      },
      {
        id: 999,
        name: "Naryn Region",
        state_code: "N",
        latitude: "41.29432270",
        longitude: "75.34121790",
        type: null,
      },
      {
        id: 1003,
        name: "Osh",
        state_code: "GO",
        latitude: "36.06313990",
        longitude: "-95.91828950",
        type: null,
      },
      {
        id: 1005,
        name: "Osh Region",
        state_code: "O",
        latitude: "39.84073660",
        longitude: "72.89880690",
        type: null,
      },
      {
        id: 997,
        name: "Talas Region",
        state_code: "T",
        latitude: "42.28673390",
        longitude: "72.52048270",
        type: null,
      },
    ],
  },
  {
    name: "Laos",
    iso3: "LAO",
    iso2: "LA",
    numeric_code: "418",
    phone_code: "856",
    capital: "Vientiane",
    currency: "LAK",
    currency_name: "Lao kip",
    currency_symbol: "₭",
    tld: ".la",
    native: "ສປປລາວ",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Lao, Laotian",
    timezones: [
      {
        zoneName: "Asia/Vientiane",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "라오스",
      "pt-BR": "Laos",
      pt: "Laos",
      nl: "Laos",
      hr: "Laos",
      fa: "لائوس",
      de: "Laos",
      es: "Laos",
      fr: "Laos",
      ja: "ラオス人民民主共和国",
      it: "Laos",
      cn: "寮人民民主共和国",
      tr: "Laos",
    },
    latitude: "18.00000000",
    longitude: "105.00000000",
    emoji: "🇱🇦",
    emojiU: "U+1F1F1 U+1F1E6",
    states: [
      {
        id: 982,
        name: "Attapeu Province",
        state_code: "AT",
        latitude: "14.93634000",
        longitude: "107.10119310",
        type: null,
      },
      {
        id: 991,
        name: "Bokeo Province",
        state_code: "BK",
        latitude: "20.28726620",
        longitude: "100.70978670",
        type: null,
      },
      {
        id: 985,
        name: "Bolikhamsai Province",
        state_code: "BL",
        latitude: "18.43629240",
        longitude: "104.47233010",
        type: null,
      },
      {
        id: 996,
        name: "Champasak Province",
        state_code: "CH",
        latitude: "14.65786640",
        longitude: "105.96998780",
        type: null,
      },
      {
        id: 989,
        name: "Houaphanh Province",
        state_code: "HO",
        latitude: "20.32541750",
        longitude: "104.10013260",
        type: null,
      },
      {
        id: 986,
        name: "Khammouane Province",
        state_code: "KH",
        latitude: "17.63840660",
        longitude: "105.21948080",
        type: null,
      },
      {
        id: 992,
        name: "Luang Namtha Province",
        state_code: "LM",
        latitude: "20.91701870",
        longitude: "101.16173560",
        type: null,
      },
      {
        id: 978,
        name: "Luang Prabang Province",
        state_code: "LP",
        latitude: "20.06562290",
        longitude: "102.62162110",
        type: null,
      },
      {
        id: 988,
        name: "Oudomxay Province",
        state_code: "OU",
        latitude: "20.49219290",
        longitude: "101.88917210",
        type: null,
      },
      {
        id: 987,
        name: "Phongsaly Province",
        state_code: "PH",
        latitude: "21.59193770",
        longitude: "102.25479190",
        type: null,
      },
      {
        id: 993,
        name: "Sainyabuli Province",
        state_code: "XA",
        latitude: "19.39078860",
        longitude: "101.52480550",
        type: null,
      },
      {
        id: 981,
        name: "Salavan Province",
        state_code: "SL",
        latitude: "15.81710730",
        longitude: "106.25221430",
        type: null,
      },
      {
        id: 990,
        name: "Savannakhet Province",
        state_code: "SV",
        latitude: "16.50653810",
        longitude: "105.59433880",
        type: null,
      },
      {
        id: 984,
        name: "Sekong Province",
        state_code: "XE",
        latitude: "15.57674460",
        longitude: "107.00670310",
        type: null,
      },
      {
        id: 979,
        name: "Vientiane Prefecture",
        state_code: "VT",
        latitude: "18.11054100",
        longitude: "102.52980280",
        type: null,
      },
      {
        id: 980,
        name: "Vientiane Province",
        state_code: "VI",
        latitude: "18.57050630",
        longitude: "102.62162110",
        type: null,
      },
      {
        id: 994,
        name: "Xaisomboun",
        state_code: "XN",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 983,
        name: "Xaisomboun Province",
        state_code: "XS",
        latitude: "18.43629240",
        longitude: "104.47233010",
        type: null,
      },
      {
        id: 995,
        name: "Xiangkhouang Province",
        state_code: "XI",
        latitude: "19.60930030",
        longitude: "103.72891670",
        type: null,
      },
    ],
  },
  {
    name: "Latvia",
    iso3: "LVA",
    iso2: "LV",
    numeric_code: "428",
    phone_code: "371",
    capital: "Riga",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lv",
    native: "Latvija",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Latvian",
    timezones: [
      {
        zoneName: "Europe/Riga",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "라트비아",
      "pt-BR": "Letônia",
      pt: "Letónia",
      nl: "Letland",
      hr: "Latvija",
      fa: "لتونی",
      de: "Lettland",
      es: "Letonia",
      fr: "Lettonie",
      ja: "ラトビア",
      it: "Lettonia",
      cn: "拉脱维亚",
      tr: "Letonya",
    },
    latitude: "57.00000000",
    longitude: "25.00000000",
    emoji: "🇱🇻",
    emojiU: "U+1F1F1 U+1F1FB",
    states: [
      {
        id: 4445,
        name: "Aglona Municipality",
        state_code: "001",
        latitude: "56.10890060",
        longitude: "27.12862270",
        type: null,
      },
      {
        id: 4472,
        name: "Aizkraukle Municipality",
        state_code: "002",
        latitude: "56.64610800",
        longitude: "25.23708540",
        type: null,
      },
      {
        id: 4496,
        name: "Aizpute Municipality",
        state_code: "003",
        latitude: "56.71825960",
        longitude: "21.60727590",
        type: null,
      },
      {
        id: 4499,
        name: "Aknīste Municipality",
        state_code: "004",
        latitude: "56.16130370",
        longitude: "25.74848270",
        type: null,
      },
      {
        id: 4484,
        name: "Aloja Municipality",
        state_code: "005",
        latitude: "57.76713600",
        longitude: "24.87708390",
        type: null,
      },
      {
        id: 4485,
        name: "Alsunga Municipality",
        state_code: "006",
        latitude: "56.98285310",
        longitude: "21.55559190",
        type: null,
      },
      {
        id: 4487,
        name: "Alūksne Municipality",
        state_code: "007",
        latitude: "57.42544850",
        longitude: "27.04249680",
        type: null,
      },
      {
        id: 4497,
        name: "Amata Municipality",
        state_code: "008",
        latitude: "56.99387260",
        longitude: "25.26276750",
        type: null,
      },
      {
        id: 4457,
        name: "Ape Municipality",
        state_code: "009",
        latitude: "57.53926970",
        longitude: "26.69416490",
        type: null,
      },
      {
        id: 4481,
        name: "Auce Municipality",
        state_code: "010",
        latitude: "56.46016800",
        longitude: "22.90547810",
        type: null,
      },
      {
        id: 4427,
        name: "Babīte Municipality",
        state_code: "012",
        latitude: "56.95415500",
        longitude: "23.94539900",
        type: null,
      },
      {
        id: 4482,
        name: "Baldone Municipality",
        state_code: "013",
        latitude: "56.74246000",
        longitude: "24.39115440",
        type: null,
      },
      {
        id: 4498,
        name: "Baltinava Municipality",
        state_code: "014",
        latitude: "56.94584680",
        longitude: "27.64410660",
        type: null,
      },
      {
        id: 4505,
        name: "Balvi Municipality",
        state_code: "015",
        latitude: "57.13262400",
        longitude: "27.26466850",
        type: null,
      },
      {
        id: 4465,
        name: "Bauska Municipality",
        state_code: "016",
        latitude: "56.41018680",
        longitude: "24.20006890",
        type: null,
      },
      {
        id: 4471,
        name: "Beverīna Municipality",
        state_code: "017",
        latitude: "57.51971090",
        longitude: "25.60736540",
        type: null,
      },
      {
        id: 4468,
        name: "Brocēni Municipality",
        state_code: "018",
        latitude: "56.73475410",
        longitude: "22.63573710",
        type: null,
      },
      {
        id: 4411,
        name: "Burtnieki Municipality",
        state_code: "019",
        latitude: "57.69490040",
        longitude: "25.27647770",
        type: null,
      },
      {
        id: 4454,
        name: "Carnikava Municipality",
        state_code: "020",
        latitude: "57.10241210",
        longitude: "24.21086620",
        type: null,
      },
      {
        id: 4469,
        name: "Cēsis Municipality",
        state_code: "022",
        latitude: "57.31028970",
        longitude: "25.26761250",
        type: null,
      },
      {
        id: 4414,
        name: "Cesvaine Municipality",
        state_code: "021",
        latitude: "56.96792640",
        longitude: "26.30831720",
        type: null,
      },
      {
        id: 4410,
        name: "Cibla Municipality",
        state_code: "023",
        latitude: "56.61023440",
        longitude: "27.86965980",
        type: null,
      },
      {
        id: 4504,
        name: "Dagda Municipality",
        state_code: "024",
        latitude: "56.09560890",
        longitude: "27.53245900",
        type: null,
      },
      {
        id: 4463,
        name: "Daugavpils",
        state_code: "DGV",
        latitude: "55.87473600",
        longitude: "26.53617900",
        type: null,
      },
      {
        id: 4492,
        name: "Daugavpils Municipality",
        state_code: "025",
        latitude: "55.89917830",
        longitude: "26.61020120",
        type: null,
      },
      {
        id: 4437,
        name: "Dobele Municipality",
        state_code: "026",
        latitude: "56.62630500",
        longitude: "23.28090660",
        type: null,
      },
      {
        id: 4428,
        name: "Dundaga Municipality",
        state_code: "027",
        latitude: "57.50491670",
        longitude: "22.35051140",
        type: null,
      },
      {
        id: 4458,
        name: "Durbe Municipality",
        state_code: "028",
        latitude: "56.62798570",
        longitude: "21.49162450",
        type: null,
      },
      {
        id: 4448,
        name: "Engure Municipality",
        state_code: "029",
        latitude: "57.16235000",
        longitude: "23.21966340",
        type: null,
      },
      {
        id: 4444,
        name: "Ērgļi Municipality",
        state_code: "030",
        latitude: "56.92370650",
        longitude: "25.67538520",
        type: null,
      },
      {
        id: 4510,
        name: "Garkalne Municipality",
        state_code: "031",
        latitude: "57.01903870",
        longitude: "24.38261810",
        type: null,
      },
      {
        id: 4470,
        name: "Grobiņa Municipality",
        state_code: "032",
        latitude: "56.53963200",
        longitude: "21.16689200",
        type: null,
      },
      {
        id: 4400,
        name: "Gulbene Municipality",
        state_code: "033",
        latitude: "57.21556450",
        longitude: "26.64529550",
        type: null,
      },
      {
        id: 4441,
        name: "Iecava Municipality",
        state_code: "034",
        latitude: "56.59867930",
        longitude: "24.19962720",
        type: null,
      },
      {
        id: 4511,
        name: "Ikšķile Municipality",
        state_code: "035",
        latitude: "56.83736670",
        longitude: "24.49747450",
        type: null,
      },
      {
        id: 4399,
        name: "Ilūkste Municipality",
        state_code: "036",
        latitude: "55.97825470",
        longitude: "26.29650880",
        type: null,
      },
      {
        id: 4449,
        name: "Inčukalns Municipality",
        state_code: "037",
        latitude: "57.09943420",
        longitude: "24.68555700",
        type: null,
      },
      {
        id: 4475,
        name: "Jaunjelgava Municipality",
        state_code: "038",
        latitude: "56.52836590",
        longitude: "25.39214430",
        type: null,
      },
      {
        id: 4407,
        name: "Jaunpiebalga Municipality",
        state_code: "039",
        latitude: "57.14334710",
        longitude: "25.99518880",
        type: null,
      },
      {
        id: 4489,
        name: "Jaunpils Municipality",
        state_code: "040",
        latitude: "56.73141940",
        longitude: "23.01256160",
        type: null,
      },
      {
        id: 4464,
        name: "Jēkabpils",
        state_code: "JKB",
        latitude: "56.50145500",
        longitude: "25.87829900",
        type: null,
      },
      {
        id: 4438,
        name: "Jēkabpils Municipality",
        state_code: "042",
        latitude: "56.29193200",
        longitude: "25.98120170",
        type: null,
      },
      {
        id: 4500,
        name: "Jelgava",
        state_code: "JEL",
        latitude: "56.65110910",
        longitude: "23.72135410",
        type: null,
      },
      {
        id: 4424,
        name: "Jelgava Municipality",
        state_code: "041",
        latitude: "56.58956890",
        longitude: "23.66104810",
        type: null,
      },
      {
        id: 4446,
        name: "Jūrmala",
        state_code: "JUR",
        latitude: "56.94707900",
        longitude: "23.61684850",
        type: null,
      },
      {
        id: 4420,
        name: "Kandava Municipality",
        state_code: "043",
        latitude: "57.03406730",
        longitude: "22.78018130",
        type: null,
      },
      {
        id: 4453,
        name: "Kārsava Municipality",
        state_code: "044",
        latitude: "56.76458420",
        longitude: "27.73582950",
        type: null,
      },
      {
        id: 4412,
        name: "Ķegums Municipality",
        state_code: "051",
        latitude: "56.74753570",
        longitude: "24.71736450",
        type: null,
      },
      {
        id: 4435,
        name: "Ķekava Municipality",
        state_code: "052",
        latitude: "56.80643510",
        longitude: "24.19394930",
        type: null,
      },
      {
        id: 4495,
        name: "Kocēni Municipality",
        state_code: "045",
        latitude: "57.52262920",
        longitude: "25.33495070",
        type: null,
      },
      {
        id: 4452,
        name: "Koknese Municipality",
        state_code: "046",
        latitude: "56.72055600",
        longitude: "25.48939090",
        type: null,
      },
      {
        id: 4474,
        name: "Krāslava Municipality",
        state_code: "047",
        latitude: "55.89514640",
        longitude: "27.18145770",
        type: null,
      },
      {
        id: 4422,
        name: "Krimulda Municipality",
        state_code: "048",
        latitude: "57.17912730",
        longitude: "24.71401270",
        type: null,
      },
      {
        id: 4413,
        name: "Krustpils Municipality",
        state_code: "049",
        latitude: "56.54155780",
        longitude: "26.24463970",
        type: null,
      },
      {
        id: 4490,
        name: "Kuldīga Municipality",
        state_code: "050",
        latitude: "56.96872570",
        longitude: "21.96137390",
        type: null,
      },
      {
        id: 4512,
        name: "Lielvārde Municipality",
        state_code: "053",
        latitude: "56.73929760",
        longitude: "24.97116180",
        type: null,
      },
      {
        id: 4460,
        name: "Liepāja",
        state_code: "LPX",
        latitude: "56.50466780",
        longitude: "21.01080600",
        type: null,
      },
      {
        id: 4488,
        name: "Līgatne Municipality",
        state_code: "055",
        latitude: "57.19442040",
        longitude: "25.09406810",
        type: null,
      },
      {
        id: 4418,
        name: "Limbaži Municipality",
        state_code: "054",
        latitude: "57.54032270",
        longitude: "24.71344510",
        type: null,
      },
      {
        id: 4401,
        name: "Līvāni Municipality",
        state_code: "056",
        latitude: "56.35509420",
        longitude: "26.17251900",
        type: null,
      },
      {
        id: 4419,
        name: "Lubāna Municipality",
        state_code: "057",
        latitude: "56.89992690",
        longitude: "26.71987890",
        type: null,
      },
      {
        id: 4501,
        name: "Ludza Municipality",
        state_code: "058",
        latitude: "56.54595900",
        longitude: "27.71431990",
        type: null,
      },
      {
        id: 4433,
        name: "Madona Municipality",
        state_code: "059",
        latitude: "56.85989230",
        longitude: "26.22762010",
        type: null,
      },
      {
        id: 4461,
        name: "Mālpils Municipality",
        state_code: "061",
        latitude: "57.00841190",
        longitude: "24.95742780",
        type: null,
      },
      {
        id: 4450,
        name: "Mārupe Municipality",
        state_code: "062",
        latitude: "56.89657170",
        longitude: "24.04600490",
        type: null,
      },
      {
        id: 4513,
        name: "Mazsalaca Municipality",
        state_code: "060",
        latitude: "57.92677490",
        longitude: "25.06698950",
        type: null,
      },
      {
        id: 4451,
        name: "Mērsrags Municipality",
        state_code: "063",
        latitude: "57.33068810",
        longitude: "23.10237070",
        type: null,
      },
      {
        id: 4398,
        name: "Naukšēni Municipality",
        state_code: "064",
        latitude: "57.92953610",
        longitude: "25.51192660",
        type: null,
      },
      {
        id: 4432,
        name: "Nereta Municipality",
        state_code: "065",
        latitude: "56.19866550",
        longitude: "25.32529690",
        type: null,
      },
      {
        id: 4436,
        name: "Nīca Municipality",
        state_code: "066",
        latitude: "56.34649830",
        longitude: "21.06549300",
        type: null,
      },
      {
        id: 4416,
        name: "Ogre Municipality",
        state_code: "067",
        latitude: "56.81473550",
        longitude: "24.60445550",
        type: null,
      },
      {
        id: 4417,
        name: "Olaine Municipality",
        state_code: "068",
        latitude: "56.79523530",
        longitude: "24.01535890",
        type: null,
      },
      {
        id: 4442,
        name: "Ozolnieki Municipality",
        state_code: "069",
        latitude: "56.67563050",
        longitude: "23.89948160",
        type: null,
      },
      {
        id: 4507,
        name: "Pārgauja Municipality",
        state_code: "070",
        latitude: "57.36481220",
        longitude: "24.98220450",
        type: null,
      },
      {
        id: 4467,
        name: "Pāvilosta Municipality",
        state_code: "071",
        latitude: "56.88654240",
        longitude: "21.19468490",
        type: null,
      },
      {
        id: 4405,
        name: "Pļaviņas Municipality",
        state_code: "072",
        latitude: "56.61773130",
        longitude: "25.71940430",
        type: null,
      },
      {
        id: 4483,
        name: "Preiļi Municipality",
        state_code: "073",
        latitude: "56.15111570",
        longitude: "26.74397670",
        type: null,
      },
      {
        id: 4429,
        name: "Priekule Municipality",
        state_code: "074",
        latitude: "56.41794130",
        longitude: "21.55033360",
        type: null,
      },
      {
        id: 4506,
        name: "Priekuļi Municipality",
        state_code: "075",
        latitude: "57.36171380",
        longitude: "25.44104230",
        type: null,
      },
      {
        id: 4479,
        name: "Rauna Municipality",
        state_code: "076",
        latitude: "57.33169300",
        longitude: "25.61003390",
        type: null,
      },
      {
        id: 4509,
        name: "Rēzekne",
        state_code: "REZ",
        latitude: "56.50992230",
        longitude: "27.33313570",
        type: null,
      },
      {
        id: 4455,
        name: "Rēzekne Municipality",
        state_code: "077",
        latitude: "56.32736380",
        longitude: "27.32843310",
        type: null,
      },
      {
        id: 4502,
        name: "Riebiņi Municipality",
        state_code: "078",
        latitude: "56.34361900",
        longitude: "26.80181380",
        type: null,
      },
      {
        id: 4491,
        name: "Riga",
        state_code: "RIX",
        latitude: "56.94964870",
        longitude: "24.10518650",
        type: null,
      },
      {
        id: 4440,
        name: "Roja Municipality",
        state_code: "079",
        latitude: "57.50467130",
        longitude: "22.80121640",
        type: null,
      },
      {
        id: 4493,
        name: "Ropaži Municipality",
        state_code: "080",
        latitude: "56.96157860",
        longitude: "24.60104760",
        type: null,
      },
      {
        id: 4503,
        name: "Rucava Municipality",
        state_code: "081",
        latitude: "56.15931240",
        longitude: "21.16181210",
        type: null,
      },
      {
        id: 4423,
        name: "Rugāji Municipality",
        state_code: "082",
        latitude: "57.00560230",
        longitude: "27.13172030",
        type: null,
      },
      {
        id: 4426,
        name: "Rūjiena Municipality",
        state_code: "084",
        latitude: "57.89372910",
        longitude: "25.33910080",
        type: null,
      },
      {
        id: 4404,
        name: "Rundāle Municipality",
        state_code: "083",
        latitude: "56.40972100",
        longitude: "24.01241390",
        type: null,
      },
      {
        id: 4434,
        name: "Sala Municipality",
        state_code: "085",
        latitude: "59.96796130",
        longitude: "16.49782170",
        type: null,
      },
      {
        id: 4396,
        name: "Salacgrīva Municipality",
        state_code: "086",
        latitude: "57.75808830",
        longitude: "24.35431810",
        type: null,
      },
      {
        id: 4402,
        name: "Salaspils Municipality",
        state_code: "087",
        latitude: "56.86081520",
        longitude: "24.34978810",
        type: null,
      },
      {
        id: 4439,
        name: "Saldus Municipality",
        state_code: "088",
        latitude: "56.66650880",
        longitude: "22.49354930",
        type: null,
      },
      {
        id: 4443,
        name: "Saulkrasti Municipality",
        state_code: "089",
        latitude: "57.25794180",
        longitude: "24.41831460",
        type: null,
      },
      {
        id: 4408,
        name: "Sēja Municipality",
        state_code: "090",
        latitude: "57.20069950",
        longitude: "24.59228210",
        type: null,
      },
      {
        id: 4476,
        name: "Sigulda Municipality",
        state_code: "091",
        latitude: "57.10550920",
        longitude: "24.83142590",
        type: null,
      },
      {
        id: 4415,
        name: "Skrīveri Municipality",
        state_code: "092",
        latitude: "56.67613910",
        longitude: "25.09788490",
        type: null,
      },
      {
        id: 4447,
        name: "Skrunda Municipality",
        state_code: "093",
        latitude: "56.66434580",
        longitude: "22.00457290",
        type: null,
      },
      {
        id: 4462,
        name: "Smiltene Municipality",
        state_code: "094",
        latitude: "57.42303320",
        longitude: "25.90027800",
        type: null,
      },
      {
        id: 4478,
        name: "Stopiņi Municipality",
        state_code: "095",
        latitude: "56.93644900",
        longitude: "24.28729490",
        type: null,
      },
      {
        id: 4494,
        name: "Strenči Municipality",
        state_code: "096",
        latitude: "57.62254710",
        longitude: "25.80480860",
        type: null,
      },
      {
        id: 4459,
        name: "Talsi Municipality",
        state_code: "097",
        latitude: "57.34152080",
        longitude: "22.57131250",
        type: null,
      },
      {
        id: 4480,
        name: "Tērvete Municipality",
        state_code: "098",
        latitude: "56.41192010",
        longitude: "23.31883320",
        type: null,
      },
      {
        id: 4409,
        name: "Tukums Municipality",
        state_code: "099",
        latitude: "56.96728680",
        longitude: "23.15243790",
        type: null,
      },
      {
        id: 4508,
        name: "Vaiņode Municipality",
        state_code: "100",
        latitude: "56.41542710",
        longitude: "21.85139840",
        type: null,
      },
      {
        id: 4425,
        name: "Valka Municipality",
        state_code: "101",
        latitude: "57.77439000",
        longitude: "26.01700500",
        type: null,
      },
      {
        id: 4473,
        name: "Valmiera",
        state_code: "VMR",
        latitude: "57.53846590",
        longitude: "25.42636180",
        type: null,
      },
      {
        id: 4431,
        name: "Varakļāni Municipality",
        state_code: "102",
        latitude: "56.66880060",
        longitude: "26.56364140",
        type: null,
      },
      {
        id: 4406,
        name: "Vārkava Municipality",
        state_code: "103",
        latitude: "56.24657440",
        longitude: "26.56643710",
        type: null,
      },
      {
        id: 4466,
        name: "Vecpiebalga Municipality",
        state_code: "104",
        latitude: "57.06033560",
        longitude: "25.81615920",
        type: null,
      },
      {
        id: 4397,
        name: "Vecumnieki Municipality",
        state_code: "105",
        latitude: "56.60623370",
        longitude: "24.52218910",
        type: null,
      },
      {
        id: 4421,
        name: "Ventspils",
        state_code: "VEN",
        latitude: "57.39372160",
        longitude: "21.56470660",
        type: null,
      },
      {
        id: 4403,
        name: "Ventspils Municipality",
        state_code: "106",
        latitude: "57.28336820",
        longitude: "21.85875580",
        type: null,
      },
      {
        id: 4456,
        name: "Viesīte Municipality",
        state_code: "107",
        latitude: "56.31130850",
        longitude: "25.50704640",
        type: null,
      },
      {
        id: 4477,
        name: "Viļaka Municipality",
        state_code: "108",
        latitude: "57.17222630",
        longitude: "27.66731880",
        type: null,
      },
      {
        id: 4486,
        name: "Viļāni Municipality",
        state_code: "109",
        latitude: "56.54561710",
        longitude: "26.91679270",
        type: null,
      },
      {
        id: 4430,
        name: "Zilupe Municipality",
        state_code: "110",
        latitude: "56.30189850",
        longitude: "28.13395900",
        type: null,
      },
    ],
  },
  {
    name: "Lebanon",
    iso3: "LBN",
    iso2: "LB",
    numeric_code: "422",
    phone_code: "961",
    capital: "Beirut",
    currency: "LBP",
    currency_name: "Lebanese pound",
    currency_symbol: "£",
    tld: ".lb",
    native: "لبنان",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Lebanese",
    timezones: [
      {
        zoneName: "Asia/Beirut",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "레바논",
      "pt-BR": "Líbano",
      pt: "Líbano",
      nl: "Libanon",
      hr: "Libanon",
      fa: "لبنان",
      de: "Libanon",
      es: "Líbano",
      fr: "Liban",
      ja: "レバノン",
      it: "Libano",
      cn: "黎巴嫩",
      tr: "Lübnan",
    },
    latitude: "33.83333333",
    longitude: "35.83333333",
    emoji: "🇱🇧",
    emojiU: "U+1F1F1 U+1F1E7",
    states: [
      {
        id: 2285,
        name: "Akkar",
        state_code: "AK",
        latitude: "34.53287630",
        longitude: "36.13281320",
        type: null,
      },
      {
        id: 2283,
        name: "Baalbek-Hermel",
        state_code: "BH",
        latitude: "34.26585560",
        longitude: "36.34980970",
        type: null,
      },
      {
        id: 2286,
        name: "Beirut",
        state_code: "BA",
        latitude: "33.88861060",
        longitude: "35.49547720",
        type: null,
      },
      {
        id: 2287,
        name: "Beqaa",
        state_code: "BI",
        latitude: "33.84626620",
        longitude: "35.90194890",
        type: null,
      },
      {
        id: 2282,
        name: "Mount Lebanon",
        state_code: "JL",
        latitude: "33.81008580",
        longitude: "35.59731390",
        type: null,
      },
      {
        id: 2288,
        name: "Nabatieh",
        state_code: "NA",
        latitude: "33.37716930",
        longitude: "35.48382930",
        type: null,
      },
      {
        id: 2284,
        name: "North",
        state_code: "AS",
        latitude: "34.43806250",
        longitude: "35.83082330",
        type: null,
      },
      {
        id: 2281,
        name: "South",
        state_code: "JA",
        latitude: "33.27214790",
        longitude: "35.20327780",
        type: null,
      },
    ],
  },
  {
    name: "Lesotho",
    iso3: "LSO",
    iso2: "LS",
    numeric_code: "426",
    phone_code: "266",
    capital: "Maseru",
    currency: "LSL",
    currency_name: "Lesotho loti",
    currency_symbol: "L",
    tld: ".ls",
    native: "Lesotho",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Basotho",
    timezones: [
      {
        zoneName: "Africa/Maseru",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "레소토",
      "pt-BR": "Lesoto",
      pt: "Lesoto",
      nl: "Lesotho",
      hr: "Lesoto",
      fa: "لسوتو",
      de: "Lesotho",
      es: "Lesotho",
      fr: "Lesotho",
      ja: "レソト",
      it: "Lesotho",
      cn: "莱索托",
      tr: "Lesotho",
    },
    latitude: "-29.50000000",
    longitude: "28.50000000",
    emoji: "🇱🇸",
    emojiU: "U+1F1F1 U+1F1F8",
    states: [
      {
        id: 3030,
        name: "Berea District",
        state_code: "D",
        latitude: "41.36616140",
        longitude: "-81.85430260",
        type: null,
      },
      {
        id: 3029,
        name: "Butha-Buthe District",
        state_code: "B",
        latitude: "-28.76537540",
        longitude: "28.24681480",
        type: null,
      },
      {
        id: 3026,
        name: "Leribe District",
        state_code: "C",
        latitude: "-28.86380650",
        longitude: "28.04788260",
        type: null,
      },
      {
        id: 3022,
        name: "Mafeteng District",
        state_code: "E",
        latitude: "-29.80410080",
        longitude: "27.50261740",
        type: null,
      },
      {
        id: 3028,
        name: "Maseru District",
        state_code: "A",
        latitude: "-29.51656500",
        longitude: "27.83114280",
        type: null,
      },
      {
        id: 3023,
        name: "Mohale's Hoek District",
        state_code: "F",
        latitude: "-30.14259170",
        longitude: "27.46738450",
        type: null,
      },
      {
        id: 3024,
        name: "Mokhotlong District",
        state_code: "J",
        latitude: "-29.25731930",
        longitude: "28.95286450",
        type: null,
      },
      {
        id: 3025,
        name: "Qacha's Nek District",
        state_code: "H",
        latitude: "-30.11145650",
        longitude: "28.67897900",
        type: null,
      },
      {
        id: 3027,
        name: "Quthing District",
        state_code: "G",
        latitude: "-30.40156870",
        longitude: "27.70801330",
        type: null,
      },
      {
        id: 3031,
        name: "Thaba-Tseka District",
        state_code: "K",
        latitude: "-29.52389750",
        longitude: "28.60897520",
        type: null,
      },
    ],
  },
  {
    name: "Liberia",
    iso3: "LBR",
    iso2: "LR",
    numeric_code: "430",
    phone_code: "231",
    capital: "Monrovia",
    currency: "LRD",
    currency_name: "Liberian dollar",
    currency_symbol: "$",
    tld: ".lr",
    native: "Liberia",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Liberian",
    timezones: [
      {
        zoneName: "Africa/Monrovia",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "라이베리아",
      "pt-BR": "Libéria",
      pt: "Libéria",
      nl: "Liberia",
      hr: "Liberija",
      fa: "لیبریا",
      de: "Liberia",
      es: "Liberia",
      fr: "Liberia",
      ja: "リベリア",
      it: "Liberia",
      cn: "利比里亚",
      tr: "Liberya",
    },
    latitude: "6.50000000",
    longitude: "-9.50000000",
    emoji: "🇱🇷",
    emojiU: "U+1F1F1 U+1F1F7",
    states: [
      {
        id: 3041,
        name: "Bomi County",
        state_code: "BM",
        latitude: "6.75629260",
        longitude: "-10.84514670",
        type: null,
      },
      {
        id: 3034,
        name: "Bong County",
        state_code: "BG",
        latitude: "6.82950190",
        longitude: "-9.36730840",
        type: null,
      },
      {
        id: 3044,
        name: "Gbarpolu County",
        state_code: "GP",
        latitude: "7.49526370",
        longitude: "-10.08072980",
        type: null,
      },
      {
        id: 3040,
        name: "Grand Bassa County",
        state_code: "GB",
        latitude: "6.23084520",
        longitude: "-9.81249350",
        type: null,
      },
      {
        id: 3036,
        name: "Grand Cape Mount County",
        state_code: "CM",
        latitude: "7.04677580",
        longitude: "-11.07117580",
        type: null,
      },
      {
        id: 3039,
        name: "Grand Gedeh County",
        state_code: "GG",
        latitude: "5.92220780",
        longitude: "-8.22129790",
        type: null,
      },
      {
        id: 3045,
        name: "Grand Kru County",
        state_code: "GK",
        latitude: "4.76138620",
        longitude: "-8.22129790",
        type: null,
      },
      {
        id: 3037,
        name: "Lofa County",
        state_code: "LO",
        latitude: "8.19111840",
        longitude: "-9.72326730",
        type: null,
      },
      {
        id: 3043,
        name: "Margibi County",
        state_code: "MG",
        latitude: "6.51518750",
        longitude: "-10.30488970",
        type: null,
      },
      {
        id: 3042,
        name: "Maryland County",
        state_code: "MY",
        latitude: "39.04575490",
        longitude: "-76.64127120",
        type: null,
      },
      {
        id: 3032,
        name: "Montserrado County",
        state_code: "MO",
        latitude: "6.55258150",
        longitude: "-10.52961150",
        type: null,
      },
      {
        id: 3046,
        name: "Nimba",
        state_code: "NI",
        latitude: "7.61666670",
        longitude: "-8.41666670",
        type: null,
      },
      {
        id: 3033,
        name: "River Cess County",
        state_code: "RI",
        latitude: "5.90253280",
        longitude: "-9.45615500",
        type: null,
      },
      {
        id: 3038,
        name: "River Gee County",
        state_code: "RG",
        latitude: "5.26048940",
        longitude: "-7.87216000",
        type: null,
      },
      {
        id: 3035,
        name: "Sinoe County",
        state_code: "SI",
        latitude: "5.49871000",
        longitude: "-8.66005860",
        type: null,
      },
    ],
  },
  {
    name: "Libya",
    iso3: "LBY",
    iso2: "LY",
    numeric_code: "434",
    phone_code: "218",
    capital: "Tripolis",
    currency: "LYD",
    currency_name: "Libyan dinar",
    currency_symbol: "د.ل",
    tld: ".ly",
    native: "‏ليبيا",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Libyan",
    timezones: [
      {
        zoneName: "Africa/Tripoli",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "리비아",
      "pt-BR": "Líbia",
      pt: "Líbia",
      nl: "Libië",
      hr: "Libija",
      fa: "لیبی",
      de: "Libyen",
      es: "Libia",
      fr: "Libye",
      ja: "リビア",
      it: "Libia",
      cn: "利比亚",
      tr: "Libya",
    },
    latitude: "25.00000000",
    longitude: "17.00000000",
    emoji: "🇱🇾",
    emojiU: "U+1F1F1 U+1F1FE",
    states: [
      {
        id: 2964,
        name: "Al Wahat District",
        state_code: "WA",
        latitude: "29.04668080",
        longitude: "21.85685860",
        type: null,
      },
      {
        id: 2981,
        name: "Benghazi",
        state_code: "BA",
        latitude: "32.11942420",
        longitude: "20.08679090",
        type: null,
      },
      {
        id: 2966,
        name: "Derna District",
        state_code: "DR",
        latitude: "32.75561300",
        longitude: "22.63774320",
        type: null,
      },
      {
        id: 2969,
        name: "Ghat District",
        state_code: "GT",
        latitude: "24.96403710",
        longitude: "10.17592850",
        type: null,
      },
      {
        id: 2980,
        name: "Jabal al Akhdar",
        state_code: "JA",
        latitude: "23.18560810",
        longitude: "57.37138790",
        type: null,
      },
      {
        id: 2974,
        name: "Jabal al Gharbi District",
        state_code: "JG",
        latitude: "30.26380320",
        longitude: "12.80547530",
        type: null,
      },
      {
        id: 2979,
        name: "Jafara",
        state_code: "JI",
        latitude: "32.45259040",
        longitude: "12.94355360",
        type: null,
      },
      {
        id: 2970,
        name: "Jufra",
        state_code: "JU",
        latitude: "27.98351350",
        longitude: "16.91225100",
        type: null,
      },
      {
        id: 2972,
        name: "Kufra District",
        state_code: "KF",
        latitude: "23.31123890",
        longitude: "21.85685860",
        type: null,
      },
      {
        id: 2968,
        name: "Marj District",
        state_code: "MJ",
        latitude: "32.05503630",
        longitude: "21.18911510",
        type: null,
      },
      {
        id: 2978,
        name: "Misrata District",
        state_code: "MI",
        latitude: "32.32558840",
        longitude: "15.09925560",
        type: null,
      },
      {
        id: 2961,
        name: "Murqub",
        state_code: "MB",
        latitude: "32.45996770",
        longitude: "14.10013260",
        type: null,
      },
      {
        id: 2967,
        name: "Murzuq District",
        state_code: "MQ",
        latitude: "25.91822620",
        longitude: "13.92600010",
        type: null,
      },
      {
        id: 2976,
        name: "Nalut District",
        state_code: "NL",
        latitude: "31.87423480",
        longitude: "10.97504840",
        type: null,
      },
      {
        id: 2962,
        name: "Nuqat al Khams",
        state_code: "NQ",
        latitude: "32.69149090",
        longitude: "11.88917210",
        type: null,
      },
      {
        id: 2965,
        name: "Sabha District",
        state_code: "SB",
        latitude: "27.03654060",
        longitude: "14.42902360",
        type: null,
      },
      {
        id: 2977,
        name: "Sirte District",
        state_code: "SR",
        latitude: "31.18968900",
        longitude: "16.57019270",
        type: null,
      },
      {
        id: 2971,
        name: "Tripoli District",
        state_code: "TB",
        latitude: "32.64080210",
        longitude: "13.26634790",
        type: null,
      },
      {
        id: 2973,
        name: "Wadi al Hayaa District",
        state_code: "WD",
        latitude: "26.42259260",
        longitude: "12.62162110",
        type: null,
      },
      {
        id: 2975,
        name: "Wadi al Shatii District",
        state_code: "WS",
        latitude: "27.73514680",
        longitude: "12.43805810",
        type: null,
      },
      {
        id: 2963,
        name: "Zawiya District",
        state_code: "ZA",
        latitude: "32.76302820",
        longitude: "12.73649620",
        type: null,
      },
    ],
  },
  {
    name: "Liechtenstein",
    iso3: "LIE",
    iso2: "LI",
    numeric_code: "438",
    phone_code: "423",
    capital: "Vaduz",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".li",
    native: "Liechtenstein",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Liechtenstein",
    timezones: [
      {
        zoneName: "Europe/Vaduz",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "리히텐슈타인",
      "pt-BR": "Liechtenstein",
      pt: "Listenstaine",
      nl: "Liechtenstein",
      hr: "Lihtenštajn",
      fa: "لیختن‌اشتاین",
      de: "Liechtenstein",
      es: "Liechtenstein",
      fr: "Liechtenstein",
      ja: "リヒテンシュタイン",
      it: "Liechtenstein",
      cn: "列支敦士登",
      tr: "Lihtenştayn",
    },
    latitude: "47.26666666",
    longitude: "9.53333333",
    emoji: "🇱🇮",
    emojiU: "U+1F1F1 U+1F1EE",
    states: [
      {
        id: 458,
        name: "Balzers",
        state_code: "01",
        latitude: "42.05283570",
        longitude: "-88.03668480",
        type: null,
      },
      {
        id: 451,
        name: "Eschen",
        state_code: "02",
        latitude: "40.76695740",
        longitude: "-73.95228210",
        type: null,
      },
      {
        id: 457,
        name: "Gamprin",
        state_code: "03",
        latitude: "47.21324900",
        longitude: "9.50251950",
        type: null,
      },
      {
        id: 455,
        name: "Mauren",
        state_code: "04",
        latitude: "47.21892850",
        longitude: "9.54173500",
        type: null,
      },
      {
        id: 454,
        name: "Planken",
        state_code: "05",
        latitude: "40.66505760",
        longitude: "-73.50479800",
        type: null,
      },
      {
        id: 453,
        name: "Ruggell",
        state_code: "06",
        latitude: "47.25292220",
        longitude: "9.54021270",
        type: null,
      },
      {
        id: 450,
        name: "Schaan",
        state_code: "07",
        latitude: "47.12043400",
        longitude: "9.59416020",
        type: null,
      },
      {
        id: 449,
        name: "Schellenberg",
        state_code: "08",
        latitude: "47.23096600",
        longitude: "9.54678430",
        type: null,
      },
      {
        id: 459,
        name: "Triesen",
        state_code: "09",
        latitude: "47.10979880",
        longitude: "9.52482960",
        type: null,
      },
      {
        id: 456,
        name: "Triesenberg",
        state_code: "10",
        latitude: "47.12245110",
        longitude: "9.57019850",
        type: null,
      },
      {
        id: 452,
        name: "Vaduz",
        state_code: "11",
        latitude: "47.14103030",
        longitude: "9.52092770",
        type: null,
      },
    ],
  },
  {
    name: "Lithuania",
    iso3: "LTU",
    iso2: "LT",
    numeric_code: "440",
    phone_code: "370",
    capital: "Vilnius",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lt",
    native: "Lietuva",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Lithuanian",
    timezones: [
      {
        zoneName: "Europe/Vilnius",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "리투아니아",
      "pt-BR": "Lituânia",
      pt: "Lituânia",
      nl: "Litouwen",
      hr: "Litva",
      fa: "لیتوانی",
      de: "Litauen",
      es: "Lituania",
      fr: "Lituanie",
      ja: "リトアニア",
      it: "Lituania",
      cn: "立陶宛",
      tr: "Litvanya",
    },
    latitude: "56.00000000",
    longitude: "24.00000000",
    emoji: "🇱🇹",
    emojiU: "U+1F1F1 U+1F1F9",
    states: [
      {
        id: 1561,
        name: "Akmenė District Municipality",
        state_code: "01",
        latitude: "56.24550290",
        longitude: "22.74711690",
        type: null,
      },
      {
        id: 1605,
        name: "Alytus City Municipality",
        state_code: "02",
        latitude: "54.39629380",
        longitude: "24.04587610",
        type: null,
      },
      {
        id: 1574,
        name: "Alytus County",
        state_code: "AL",
        latitude: "54.20002140",
        longitude: "24.15126340",
        type: null,
      },
      {
        id: 1599,
        name: "Alytus District Municipality",
        state_code: "03",
        latitude: "54.32974960",
        longitude: "24.19609310",
        type: null,
      },
      {
        id: 1603,
        name: "Birštonas Municipality",
        state_code: "05",
        latitude: "54.56696640",
        longitude: "24.00930980",
        type: null,
      },
      {
        id: 1566,
        name: "Biržai District Municipality",
        state_code: "06",
        latitude: "56.20177190",
        longitude: "24.75601180",
        type: null,
      },
      {
        id: 1579,
        name: "Druskininkai municipality",
        state_code: "07",
        latitude: "53.99336850",
        longitude: "24.03424380",
        type: null,
      },
      {
        id: 1559,
        name: "Elektrėnai municipality",
        state_code: "08",
        latitude: "54.76539340",
        longitude: "24.77405830",
        type: null,
      },
      {
        id: 1562,
        name: "Ignalina District Municipality",
        state_code: "09",
        latitude: "55.40903420",
        longitude: "26.32848930",
        type: null,
      },
      {
        id: 1567,
        name: "Jonava District Municipality",
        state_code: "10",
        latitude: "55.07272420",
        longitude: "24.27933370",
        type: null,
      },
      {
        id: 1581,
        name: "Joniškis District Municipality",
        state_code: "11",
        latitude: "56.23607300",
        longitude: "23.61365790",
        type: null,
      },
      {
        id: 1555,
        name: "Jurbarkas District Municipality",
        state_code: "12",
        latitude: "55.07740700",
        longitude: "22.74195690",
        type: null,
      },
      {
        id: 1583,
        name: "Kaišiadorys District Municipality",
        state_code: "13",
        latitude: "54.85886690",
        longitude: "24.42779290",
        type: null,
      },
      {
        id: 1591,
        name: "Kalvarija municipality",
        state_code: "14",
        latitude: "54.37616740",
        longitude: "23.19203210",
        type: null,
      },
      {
        id: 1580,
        name: "Kaunas City Municipality",
        state_code: "15",
        latitude: "54.91453260",
        longitude: "23.90535180",
        type: null,
      },
      {
        id: 1556,
        name: "Kaunas County",
        state_code: "KU",
        latitude: "54.98728630",
        longitude: "23.95257360",
        type: null,
      },
      {
        id: 1565,
        name: "Kaunas District Municipality",
        state_code: "16",
        latitude: "54.99362360",
        longitude: "23.63249410",
        type: null,
      },
      {
        id: 1575,
        name: "Kazlų Rūda municipality",
        state_code: "17",
        latitude: "54.78075260",
        longitude: "23.48402430",
        type: null,
      },
      {
        id: 1584,
        name: "Kėdainiai District Municipality",
        state_code: "18",
        latitude: "55.35609470",
        longitude: "23.98326830",
        type: null,
      },
      {
        id: 1618,
        name: "Kelmė District Municipality",
        state_code: "19",
        latitude: "55.62663520",
        longitude: "22.87817200",
        type: null,
      },
      {
        id: 1597,
        name: "Klaipeda City Municipality",
        state_code: "20",
        latitude: "55.70329480",
        longitude: "21.14427950",
        type: null,
      },
      {
        id: 1600,
        name: "Klaipėda County",
        state_code: "KL",
        latitude: "55.65197440",
        longitude: "21.37439560",
        type: null,
      },
      {
        id: 1604,
        name: "Klaipėda District Municipality",
        state_code: "21",
        latitude: "55.68416150",
        longitude: "21.44164640",
        type: null,
      },
      {
        id: 1571,
        name: "Kretinga District Municipality",
        state_code: "22",
        latitude: "55.88384200",
        longitude: "21.23509190",
        type: null,
      },
      {
        id: 1585,
        name: "Kupiškis District Municipality",
        state_code: "23",
        latitude: "55.84287410",
        longitude: "25.02958160",
        type: null,
      },
      {
        id: 1611,
        name: "Lazdijai District Municipality",
        state_code: "24",
        latitude: "54.23432670",
        longitude: "23.51565050",
        type: null,
      },
      {
        id: 1570,
        name: "Marijampolė County",
        state_code: "MR",
        latitude: "54.78199710",
        longitude: "23.13413650",
        type: null,
      },
      {
        id: 1610,
        name: "Marijampolė Municipality",
        state_code: "25",
        latitude: "54.57110940",
        longitude: "23.48593710",
        type: null,
      },
      {
        id: 1557,
        name: "Mažeikiai District Municipality",
        state_code: "26",
        latitude: "56.30924390",
        longitude: "22.34146800",
        type: null,
      },
      {
        id: 1582,
        name: "Molėtai District Municipality",
        state_code: "27",
        latitude: "55.22653090",
        longitude: "25.41800110",
        type: null,
      },
      {
        id: 1563,
        name: "Neringa Municipality",
        state_code: "28",
        latitude: "55.45724030",
        longitude: "21.08390050",
        type: null,
      },
      {
        id: 1612,
        name: "Pagėgiai municipality",
        state_code: "29",
        latitude: "55.17213200",
        longitude: "21.96836140",
        type: null,
      },
      {
        id: 1595,
        name: "Pakruojis District Municipality",
        state_code: "30",
        latitude: "56.07326050",
        longitude: "23.93899060",
        type: null,
      },
      {
        id: 1588,
        name: "Palanga City Municipality",
        state_code: "31",
        latitude: "55.92019800",
        longitude: "21.06776140",
        type: null,
      },
      {
        id: 1589,
        name: "Panevėžys City Municipality",
        state_code: "32",
        latitude: "55.73479150",
        longitude: "24.35747740",
        type: null,
      },
      {
        id: 1558,
        name: "Panevėžys County",
        state_code: "PN",
        latitude: "55.97480490",
        longitude: "25.07947670",
        type: null,
      },
      {
        id: 1614,
        name: "Panevėžys District Municipality",
        state_code: "33",
        latitude: "55.61667280",
        longitude: "24.31422830",
        type: null,
      },
      {
        id: 1616,
        name: "Pasvalys District Municipality",
        state_code: "34",
        latitude: "56.06046190",
        longitude: "24.39629100",
        type: null,
      },
      {
        id: 1553,
        name: "Plungė District Municipality",
        state_code: "35",
        latitude: "55.91078400",
        longitude: "21.84540690",
        type: null,
      },
      {
        id: 1578,
        name: "Prienai District Municipality",
        state_code: "36",
        latitude: "54.63835800",
        longitude: "23.94680090",
        type: null,
      },
      {
        id: 1568,
        name: "Radviliškis District Municipality",
        state_code: "37",
        latitude: "55.81083990",
        longitude: "23.54648700",
        type: null,
      },
      {
        id: 1587,
        name: "Raseiniai District Municipality",
        state_code: "38",
        latitude: "55.38194990",
        longitude: "23.11561290",
        type: null,
      },
      {
        id: 1590,
        name: "Rietavas municipality",
        state_code: "39",
        latitude: "55.70217190",
        longitude: "21.99865640",
        type: null,
      },
      {
        id: 1615,
        name: "Rokiškis District Municipality",
        state_code: "40",
        latitude: "55.95550390",
        longitude: "25.58592490",
        type: null,
      },
      {
        id: 1576,
        name: "Šakiai District Municipality",
        state_code: "41",
        latitude: "54.95267100",
        longitude: "23.04801990",
        type: null,
      },
      {
        id: 1577,
        name: "Šalčininkai District Municipality",
        state_code: "42",
        latitude: "54.30976700",
        longitude: "25.38756400",
        type: null,
      },
      {
        id: 1609,
        name: "Šiauliai City Municipality",
        state_code: "43",
        latitude: "55.93490850",
        longitude: "23.31368230",
        type: null,
      },
      {
        id: 1586,
        name: "Šiauliai County",
        state_code: "SA",
        latitude: "55.99857510",
        longitude: "23.13800510",
        type: null,
      },
      {
        id: 1554,
        name: "Šiauliai District Municipality",
        state_code: "44",
        latitude: "55.97214560",
        longitude: "23.03323710",
        type: null,
      },
      {
        id: 1613,
        name: "Šilalė District Municipality",
        state_code: "45",
        latitude: "55.49268000",
        longitude: "22.18455590",
        type: null,
      },
      {
        id: 1607,
        name: "Šilutė District Municipality",
        state_code: "46",
        latitude: "55.35041400",
        longitude: "21.46598590",
        type: null,
      },
      {
        id: 1594,
        name: "Širvintos District Municipality",
        state_code: "47",
        latitude: "55.04310200",
        longitude: "24.95698100",
        type: null,
      },
      {
        id: 1617,
        name: "Skuodas District Municipality",
        state_code: "48",
        latitude: "56.27021690",
        longitude: "21.52143310",
        type: null,
      },
      {
        id: 1560,
        name: "Švenčionys District Municipality",
        state_code: "49",
        latitude: "55.10280980",
        longitude: "26.00718550",
        type: null,
      },
      {
        id: 1573,
        name: "Tauragė County",
        state_code: "TA",
        latitude: "55.30725860",
        longitude: "22.35729390",
        type: null,
      },
      {
        id: 1572,
        name: "Tauragė District Municipality",
        state_code: "50",
        latitude: "55.25036600",
        longitude: "22.29095000",
        type: null,
      },
      {
        id: 1569,
        name: "Telšiai County",
        state_code: "TE",
        latitude: "56.10266160",
        longitude: "22.11139150",
        type: null,
      },
      {
        id: 1608,
        name: "Telšiai District Municipality",
        state_code: "51",
        latitude: "55.91752150",
        longitude: "22.34518400",
        type: null,
      },
      {
        id: 1593,
        name: "Trakai District Municipality",
        state_code: "52",
        latitude: "54.63791130",
        longitude: "24.93468940",
        type: null,
      },
      {
        id: 1596,
        name: "Ukmergė District Municipality",
        state_code: "53",
        latitude: "55.24526500",
        longitude: "24.77607490",
        type: null,
      },
      {
        id: 1621,
        name: "Utena County",
        state_code: "UT",
        latitude: "55.53189690",
        longitude: "25.79046990",
        type: null,
      },
      {
        id: 1598,
        name: "Utena District Municipality",
        state_code: "54",
        latitude: "55.50846140",
        longitude: "25.68326420",
        type: null,
      },
      {
        id: 1602,
        name: "Varėna District Municipality",
        state_code: "55",
        latitude: "54.22033300",
        longitude: "24.57899700",
        type: null,
      },
      {
        id: 1620,
        name: "Vilkaviškis District Municipality",
        state_code: "56",
        latitude: "54.65194500",
        longitude: "23.03515500",
        type: null,
      },
      {
        id: 1606,
        name: "Vilnius City Municipality",
        state_code: "57",
        latitude: "54.67107610",
        longitude: "25.28787210",
        type: null,
      },
      {
        id: 1601,
        name: "Vilnius County",
        state_code: "VL",
        latitude: "54.80865020",
        longitude: "25.21821390",
        type: null,
      },
      {
        id: 1592,
        name: "Vilnius District Municipality",
        state_code: "58",
        latitude: "54.77325780",
        longitude: "25.58671130",
        type: null,
      },
      {
        id: 1564,
        name: "Visaginas Municipality",
        state_code: "59",
        latitude: "55.59411800",
        longitude: "26.43079540",
        type: null,
      },
      {
        id: 1619,
        name: "Zarasai District Municipality",
        state_code: "60",
        latitude: "55.73096090",
        longitude: "26.24529500",
        type: null,
      },
    ],
  },
  {
    name: "Luxembourg",
    iso3: "LUX",
    iso2: "LU",
    numeric_code: "442",
    phone_code: "352",
    capital: "Luxembourg",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".lu",
    native: "Luxembourg",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Luxembourg, Luxembourgish",
    timezones: [
      {
        zoneName: "Europe/Luxembourg",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "룩셈부르크",
      "pt-BR": "Luxemburgo",
      pt: "Luxemburgo",
      nl: "Luxemburg",
      hr: "Luksemburg",
      fa: "لوکزامبورگ",
      de: "Luxemburg",
      es: "Luxemburgo",
      fr: "Luxembourg",
      ja: "ルクセンブルク",
      it: "Lussemburgo",
      cn: "卢森堡",
      tr: "Lüksemburg",
    },
    latitude: "49.75000000",
    longitude: "6.16666666",
    emoji: "🇱🇺",
    emojiU: "U+1F1F1 U+1F1FA",
    states: [
      {
        id: 1518,
        name: "Canton of Capellen",
        state_code: "CA",
        latitude: "49.64039310",
        longitude: "5.95538460",
        type: null,
      },
      {
        id: 1521,
        name: "Canton of Clervaux",
        state_code: "CL",
        latitude: "50.05463130",
        longitude: "6.02858750",
        type: null,
      },
      {
        id: 1513,
        name: "Canton of Diekirch",
        state_code: "DI",
        latitude: "49.86717840",
        longitude: "6.15956330",
        type: null,
      },
      {
        id: 1515,
        name: "Canton of Echternach",
        state_code: "EC",
        latitude: "49.81141330",
        longitude: "6.41756350",
        type: null,
      },
      {
        id: 1517,
        name: "Canton of Esch-sur-Alzette",
        state_code: "ES",
        latitude: "49.50088050",
        longitude: "5.98609250",
        type: null,
      },
      {
        id: 1525,
        name: "Canton of Grevenmacher",
        state_code: "GR",
        latitude: "49.68084100",
        longitude: "6.44075930",
        type: null,
      },
      {
        id: 1527,
        name: "Canton of Luxembourg",
        state_code: "LU",
        latitude: "49.63010250",
        longitude: "6.15201850",
        type: null,
      },
      {
        id: 1522,
        name: "Canton of Mersch",
        state_code: "ME",
        latitude: "49.75429060",
        longitude: "6.12921850",
        type: null,
      },
      {
        id: 1516,
        name: "Canton of Redange",
        state_code: "RD",
        latitude: "49.76455000",
        longitude: "5.88948000",
        type: null,
      },
      {
        id: 1519,
        name: "Canton of Remich",
        state_code: "RM",
        latitude: "49.54501700",
        longitude: "6.36742220",
        type: null,
      },
      {
        id: 1523,
        name: "Canton of Vianden",
        state_code: "VD",
        latitude: "49.93419240",
        longitude: "6.20199170",
        type: null,
      },
      {
        id: 1526,
        name: "Canton of Wiltz",
        state_code: "WI",
        latitude: "49.96622000",
        longitude: "5.93243060",
        type: null,
      },
      {
        id: 1524,
        name: "Diekirch District",
        state_code: "D",
        latitude: "49.86717200",
        longitude: "6.15963620",
        type: null,
      },
      {
        id: 1520,
        name: "Grevenmacher District",
        state_code: "G",
        latitude: "49.68085100",
        longitude: "6.44075240",
        type: null,
      },
      {
        id: 1514,
        name: "Luxembourg District",
        state_code: "L",
        latitude: "49.59537060",
        longitude: "6.13331780",
        type: null,
      },
    ],
  },
  {
    name: "Macau S.A.R.",
    iso3: "MAC",
    iso2: "MO",
    numeric_code: "446",
    phone_code: "853",
    capital: "Macao",
    currency: "MOP",
    currency_name: "Macanese pataca",
    currency_symbol: "$",
    tld: ".mo",
    native: "澳門",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Macanese, Chinese",
    timezones: [
      {
        zoneName: "Asia/Macau",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "마카오",
      "pt-BR": "Macau",
      pt: "Macau",
      nl: "Macao",
      hr: "Makao",
      fa: "مکائو",
      de: "Macao",
      es: "Macao",
      fr: "Macao",
      ja: "マカオ",
      it: "Macao",
      cn: "中国澳门",
      tr: "Makao",
    },
    latitude: "22.16666666",
    longitude: "113.55000000",
    emoji: "🇲🇴",
    emojiU: "U+1F1F2 U+1F1F4",
    states: [],
  },
  {
    name: "Madagascar",
    iso3: "MDG",
    iso2: "MG",
    numeric_code: "450",
    phone_code: "261",
    capital: "Antananarivo",
    currency: "MGA",
    currency_name: "Malagasy ariary",
    currency_symbol: "Ar",
    tld: ".mg",
    native: "Madagasikara",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Malagasy",
    timezones: [
      {
        zoneName: "Indian/Antananarivo",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "마다가스카르",
      "pt-BR": "Madagascar",
      pt: "Madagáscar",
      nl: "Madagaskar",
      hr: "Madagaskar",
      fa: "ماداگاسکار",
      de: "Madagaskar",
      es: "Madagascar",
      fr: "Madagascar",
      ja: "マダガスカル",
      it: "Madagascar",
      cn: "马达加斯加",
      tr: "Madagaskar",
    },
    latitude: "-20.00000000",
    longitude: "47.00000000",
    emoji: "🇲🇬",
    emojiU: "U+1F1F2 U+1F1EC",
    states: [
      {
        id: 2951,
        name: "Antananarivo Province",
        state_code: "T",
        latitude: "-18.70514740",
        longitude: "46.82528380",
        type: null,
      },
      {
        id: 2950,
        name: "Antsiranana Province",
        state_code: "D",
        latitude: "-13.77153900",
        longitude: "49.52799960",
        type: null,
      },
      {
        id: 2948,
        name: "Fianarantsoa Province",
        state_code: "F",
        latitude: "-22.35362400",
        longitude: "46.82528380",
        type: null,
      },
      {
        id: 2953,
        name: "Mahajanga Province",
        state_code: "M",
        latitude: "-16.52388300",
        longitude: "46.51626200",
        type: null,
      },
      {
        id: 2952,
        name: "Toamasina Province",
        state_code: "A",
        latitude: "-18.14428110",
        longitude: "49.39578360",
        type: null,
      },
      {
        id: 2949,
        name: "Toliara Province",
        state_code: "U",
        latitude: "-23.35161910",
        longitude: "43.68549360",
        type: null,
      },
    ],
  },
  {
    name: "Malawi",
    iso3: "MWI",
    iso2: "MW",
    numeric_code: "454",
    phone_code: "265",
    capital: "Lilongwe",
    currency: "MWK",
    currency_name: "Malawian kwacha",
    currency_symbol: "MK",
    tld: ".mw",
    native: "Malawi",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Malawian",
    timezones: [
      {
        zoneName: "Africa/Blantyre",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "말라위",
      "pt-BR": "Malawi",
      pt: "Malávi",
      nl: "Malawi",
      hr: "Malavi",
      fa: "مالاوی",
      de: "Malawi",
      es: "Malawi",
      fr: "Malawi",
      ja: "マラウイ",
      it: "Malawi",
      cn: "马拉维",
      tr: "Malavi",
    },
    latitude: "-13.50000000",
    longitude: "34.00000000",
    emoji: "🇲🇼",
    emojiU: "U+1F1F2 U+1F1FC",
    states: [
      {
        id: 3096,
        name: "Balaka District",
        state_code: "BA",
        latitude: "-15.05065950",
        longitude: "35.08285880",
        type: null,
      },
      {
        id: 3102,
        name: "Blantyre District",
        state_code: "BL",
        latitude: "-15.67785410",
        longitude: "34.95066250",
        type: null,
      },
      {
        id: 3092,
        name: "Central Region",
        state_code: "C",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3107,
        name: "Chikwawa District",
        state_code: "CK",
        latitude: "-16.19584460",
        longitude: "34.77407930",
        type: null,
      },
      {
        id: 3109,
        name: "Chiradzulu District",
        state_code: "CR",
        latitude: "-15.74231510",
        longitude: "35.25879640",
        type: null,
      },
      {
        id: 3087,
        name: "Chitipa district",
        state_code: "CT",
        latitude: "-9.70376550",
        longitude: "33.27002530",
        type: null,
      },
      {
        id: 3097,
        name: "Dedza District",
        state_code: "DE",
        latitude: "-14.18945850",
        longitude: "34.24215970",
        type: null,
      },
      {
        id: 3090,
        name: "Dowa District",
        state_code: "DO",
        latitude: "-13.60412560",
        longitude: "33.88577470",
        type: null,
      },
      {
        id: 3091,
        name: "Karonga District",
        state_code: "KR",
        latitude: "-9.90363650",
        longitude: "33.97500180",
        type: null,
      },
      {
        id: 3094,
        name: "Kasungu District",
        state_code: "KS",
        latitude: "-13.13670650",
        longitude: "33.25879300",
        type: null,
      },
      {
        id: 3093,
        name: "Likoma District",
        state_code: "LK",
        latitude: "-12.05840050",
        longitude: "34.73540310",
        type: null,
      },
      {
        id: 3101,
        name: "Lilongwe District",
        state_code: "LI",
        latitude: "-14.04752280",
        longitude: "33.61757700",
        type: null,
      },
      {
        id: 3082,
        name: "Machinga District",
        state_code: "MH",
        latitude: "-14.94072630",
        longitude: "35.47819260",
        type: null,
      },
      {
        id: 3110,
        name: "Mangochi District",
        state_code: "MG",
        latitude: "-14.13882480",
        longitude: "35.03881640",
        type: null,
      },
      {
        id: 3099,
        name: "Mchinji District",
        state_code: "MC",
        latitude: "-13.74015250",
        longitude: "32.98883190",
        type: null,
      },
      {
        id: 3103,
        name: "Mulanje District",
        state_code: "MU",
        latitude: "-15.93464340",
        longitude: "35.52200120",
        type: null,
      },
      {
        id: 3084,
        name: "Mwanza District",
        state_code: "MW",
        latitude: "-2.46711970",
        longitude: "32.89868120",
        type: null,
      },
      {
        id: 3104,
        name: "Mzimba District",
        state_code: "MZ",
        latitude: "-11.74754520",
        longitude: "33.52800720",
        type: null,
      },
      {
        id: 3095,
        name: "Nkhata Bay District",
        state_code: "NB",
        latitude: "-11.71850420",
        longitude: "34.33103640",
        type: null,
      },
      {
        id: 3100,
        name: "Nkhotakota District",
        state_code: "NK",
        latitude: "-12.75419610",
        longitude: "34.24215970",
        type: null,
      },
      {
        id: 3105,
        name: "Northern Region",
        state_code: "N",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3085,
        name: "Nsanje District",
        state_code: "NS",
        latitude: "-16.72882020",
        longitude: "35.17087410",
        type: null,
      },
      {
        id: 3088,
        name: "Ntcheu District",
        state_code: "NU",
        latitude: "-14.90375380",
        longitude: "34.77407930",
        type: null,
      },
      {
        id: 3111,
        name: "Ntchisi District",
        state_code: "NI",
        latitude: "-13.28419920",
        longitude: "33.88577470",
        type: null,
      },
      {
        id: 3108,
        name: "Phalombe District",
        state_code: "PH",
        latitude: "-15.70920380",
        longitude: "35.65328480",
        type: null,
      },
      {
        id: 3089,
        name: "Rumphi District",
        state_code: "RU",
        latitude: "-10.78515370",
        longitude: "34.33103640",
        type: null,
      },
      {
        id: 3086,
        name: "Salima District",
        state_code: "SA",
        latitude: "-13.68095860",
        longitude: "34.41982430",
        type: null,
      },
      {
        id: 3106,
        name: "Southern Region",
        state_code: "S",
        latitude: "32.75049570",
        longitude: "-97.33154760",
        type: null,
      },
      {
        id: 3098,
        name: "Thyolo District",
        state_code: "TH",
        latitude: "-16.12991770",
        longitude: "35.12687810",
        type: null,
      },
      {
        id: 3083,
        name: "Zomba District",
        state_code: "ZO",
        latitude: "-15.37658570",
        longitude: "35.33565180",
        type: null,
      },
    ],
  },
  {
    name: "Malaysia",
    iso3: "MYS",
    iso2: "MY",
    numeric_code: "458",
    phone_code: "60",
    capital: "Kuala Lumpur",
    currency: "MYR",
    currency_name: "Malaysian ringgit",
    currency_symbol: "RM",
    tld: ".my",
    native: "Malaysia",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Malaysian",
    timezones: [
      {
        zoneName: "Asia/Kuala_Lumpur",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time",
      },
      {
        zoneName: "Asia/Kuching",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "MYT",
        tzName: "Malaysia Time",
      },
    ],
    translations: {
      kr: "말레이시아",
      "pt-BR": "Malásia",
      pt: "Malásia",
      nl: "Maleisië",
      hr: "Malezija",
      fa: "مالزی",
      de: "Malaysia",
      es: "Malasia",
      fr: "Malaisie",
      ja: "マレーシア",
      it: "Malesia",
      cn: "马来西亚",
      tr: "Malezya",
    },
    latitude: "2.50000000",
    longitude: "112.50000000",
    emoji: "🇲🇾",
    emojiU: "U+1F1F2 U+1F1FE",
    states: [
      {
        id: 1950,
        name: "Johor",
        state_code: "01",
        latitude: "1.48536820",
        longitude: "103.76181540",
        type: null,
      },
      {
        id: 1947,
        name: "Kedah",
        state_code: "02",
        latitude: "6.11839640",
        longitude: "100.36845950",
        type: null,
      },
      {
        id: 1946,
        name: "Kelantan",
        state_code: "03",
        latitude: "6.12539690",
        longitude: "102.23807100",
        type: null,
      },
      {
        id: 1949,
        name: "Kuala Lumpur",
        state_code: "14",
        latitude: "3.13900300",
        longitude: "101.68685500",
        type: null,
      },
      {
        id: 1935,
        name: "Labuan",
        state_code: "15",
        latitude: "5.28314560",
        longitude: "115.23082500",
        type: null,
      },
      {
        id: 1941,
        name: "Malacca",
        state_code: "04",
        latitude: "2.18959400",
        longitude: "102.25008680",
        type: null,
      },
      {
        id: 1948,
        name: "Negeri Sembilan",
        state_code: "05",
        latitude: "2.72580580",
        longitude: "101.94237820",
        type: null,
      },
      {
        id: 1940,
        name: "Pahang",
        state_code: "06",
        latitude: "3.81263180",
        longitude: "103.32562040",
        type: null,
      },
      {
        id: 1939,
        name: "Penang",
        state_code: "07",
        latitude: "5.41639350",
        longitude: "100.33267860",
        type: null,
      },
      {
        id: 1943,
        name: "Perak",
        state_code: "08",
        latitude: "4.59211260",
        longitude: "101.09010900",
        type: null,
      },
      {
        id: 1938,
        name: "Perlis",
        state_code: "09",
        latitude: "29.92270940",
        longitude: "-90.12285590",
        type: null,
      },
      {
        id: 1945,
        name: "Putrajaya",
        state_code: "16",
        latitude: "2.92636100",
        longitude: "101.69644500",
        type: null,
      },
      {
        id: 1936,
        name: "Sabah",
        state_code: "12",
        latitude: "5.97883980",
        longitude: "116.07531990",
        type: null,
      },
      {
        id: 1937,
        name: "Sarawak",
        state_code: "13",
        latitude: "1.55327830",
        longitude: "110.35921270",
        type: null,
      },
      {
        id: 1944,
        name: "Selangor",
        state_code: "10",
        latitude: "3.07383790",
        longitude: "101.51834690",
        type: null,
      },
      {
        id: 1942,
        name: "Terengganu",
        state_code: "11",
        latitude: "5.31169160",
        longitude: "103.13241540",
        type: null,
      },
    ],
  },
  {
    name: "Maldives",
    iso3: "MDV",
    iso2: "MV",
    numeric_code: "462",
    phone_code: "960",
    capital: "Male",
    currency: "MVR",
    currency_name: "Maldivian rufiyaa",
    currency_symbol: "Rf",
    tld: ".mv",
    native: "Maldives",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Maldivian",
    timezones: [
      {
        zoneName: "Indian/Maldives",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "MVT",
        tzName: "Maldives Time",
      },
    ],
    translations: {
      kr: "몰디브",
      "pt-BR": "Maldivas",
      pt: "Maldivas",
      nl: "Maldiven",
      hr: "Maldivi",
      fa: "مالدیو",
      de: "Malediven",
      es: "Maldivas",
      fr: "Maldives",
      ja: "モルディブ",
      it: "Maldive",
      cn: "马尔代夫",
      tr: "Maldivler",
    },
    latitude: "3.25000000",
    longitude: "73.00000000",
    emoji: "🇲🇻",
    emojiU: "U+1F1F2 U+1F1FB",
    states: [
      {
        id: 2594,
        name: "Addu Atoll",
        state_code: "01",
        latitude: "-0.63009950",
        longitude: "73.15856260",
        type: null,
      },
      {
        id: 2587,
        name: "Alif Alif Atoll",
        state_code: "02",
        latitude: "4.08500000",
        longitude: "72.85154790",
        type: null,
      },
      {
        id: 2600,
        name: "Alif Dhaal Atoll",
        state_code: "00",
        latitude: "3.65433020",
        longitude: "72.80427970",
        type: null,
      },
      {
        id: 2604,
        name: "Central Province",
        state_code: "CE",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2590,
        name: "Dhaalu Atoll",
        state_code: "17",
        latitude: "2.84685020",
        longitude: "72.94605660",
        type: null,
      },
      {
        id: 2599,
        name: "Faafu Atoll",
        state_code: "14",
        latitude: "3.23094090",
        longitude: "72.94605660",
        type: null,
      },
      {
        id: 2598,
        name: "Gaafu Alif Atoll",
        state_code: "27",
        latitude: "0.61248130",
        longitude: "73.32370800",
        type: null,
      },
      {
        id: 2603,
        name: "Gaafu Dhaalu Atoll",
        state_code: "28",
        latitude: "0.35880400",
        longitude: "73.18216230",
        type: null,
      },
      {
        id: 2595,
        name: "Gnaviyani Atoll",
        state_code: "29",
        latitude: "-0.30064250",
        longitude: "73.42391430",
        type: null,
      },
      {
        id: 2586,
        name: "Haa Alif Atoll",
        state_code: "07",
        latitude: "6.99034880",
        longitude: "72.94605660",
        type: null,
      },
      {
        id: 2597,
        name: "Haa Dhaalu Atoll",
        state_code: "23",
        latitude: "6.57827170",
        longitude: "72.94605660",
        type: null,
      },
      {
        id: 2596,
        name: "Kaafu Atoll",
        state_code: "26",
        latitude: "4.45589790",
        longitude: "73.55941280",
        type: null,
      },
      {
        id: 2601,
        name: "Laamu Atoll",
        state_code: "05",
        latitude: "1.94307370",
        longitude: "73.41802110",
        type: null,
      },
      {
        id: 2607,
        name: "Lhaviyani Atoll",
        state_code: "03",
        latitude: "5.37470210",
        longitude: "73.51229280",
        type: null,
      },
      {
        id: 2609,
        name: "Malé",
        state_code: "MLE",
        latitude: "46.34888670",
        longitude: "10.90724890",
        type: null,
      },
      {
        id: 2608,
        name: "Meemu Atoll",
        state_code: "12",
        latitude: "3.00903450",
        longitude: "73.51229280",
        type: null,
      },
      {
        id: 2592,
        name: "Noonu Atoll",
        state_code: "25",
        latitude: "5.85512760",
        longitude: "73.32370800",
        type: null,
      },
      {
        id: 2589,
        name: "North Central Province",
        state_code: "NC",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2588,
        name: "North Province",
        state_code: "NO",
        latitude: "8.88550270",
        longitude: "80.27673270",
        type: null,
      },
      {
        id: 2602,
        name: "Raa Atoll",
        state_code: "13",
        latitude: "5.60064570",
        longitude: "72.94605660",
        type: null,
      },
      {
        id: 2585,
        name: "Shaviyani Atoll",
        state_code: "24",
        latitude: "6.17511000",
        longitude: "73.13496050",
        type: null,
      },
      {
        id: 2606,
        name: "South Central Province",
        state_code: "SC",
        latitude: "7.25649960",
        longitude: "80.72144170",
        type: null,
      },
      {
        id: 2605,
        name: "South Province",
        state_code: "SU",
        latitude: "-21.74820060",
        longitude: "166.17837390",
        type: null,
      },
      {
        id: 2591,
        name: "Thaa Atoll",
        state_code: "08",
        latitude: "2.43111610",
        longitude: "73.18216230",
        type: null,
      },
      {
        id: 2593,
        name: "Upper South Province",
        state_code: "US",
        latitude: "0.23070000",
        longitude: "73.27948460",
        type: null,
      },
      {
        id: 2584,
        name: "Vaavu Atoll",
        state_code: "04",
        latitude: "3.39554380",
        longitude: "73.51229280",
        type: null,
      },
    ],
  },
  {
    name: "Mali",
    iso3: "MLI",
    iso2: "ML",
    numeric_code: "466",
    phone_code: "223",
    capital: "Bamako",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ml",
    native: "Mali",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Malian, Malinese",
    timezones: [
      {
        zoneName: "Africa/Bamako",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "말리",
      "pt-BR": "Mali",
      pt: "Mali",
      nl: "Mali",
      hr: "Mali",
      fa: "مالی",
      de: "Mali",
      es: "Mali",
      fr: "Mali",
      ja: "マリ",
      it: "Mali",
      cn: "马里",
      tr: "Mali",
    },
    latitude: "17.00000000",
    longitude: "-4.00000000",
    emoji: "🇲🇱",
    emojiU: "U+1F1F2 U+1F1F1",
    states: [
      {
        id: 253,
        name: "Bamako",
        state_code: "BKO",
        latitude: "12.63923160",
        longitude: "-8.00288920",
        type: null,
      },
      {
        id: 258,
        name: "Gao Region",
        state_code: "7",
        latitude: "16.90663320",
        longitude: "1.52086240",
        type: null,
      },
      {
        id: 252,
        name: "Kayes Region",
        state_code: "1",
        latitude: "14.08183080",
        longitude: "-9.90181310",
        type: null,
      },
      {
        id: 257,
        name: "Kidal Region",
        state_code: "8",
        latitude: "18.79868320",
        longitude: "1.83183340",
        type: null,
      },
      {
        id: 250,
        name: "Koulikoro Region",
        state_code: "2",
        latitude: "13.80180740",
        longitude: "-7.43813550",
        type: null,
      },
      {
        id: 251,
        name: "Ménaka Region",
        state_code: "9",
        latitude: "15.91564210",
        longitude: "2.39617400",
        type: null,
      },
      {
        id: 255,
        name: "Mopti Region",
        state_code: "5",
        latitude: "14.63380390",
        longitude: "-3.41955270",
        type: null,
      },
      {
        id: 249,
        name: "Ségou Region",
        state_code: "4",
        latitude: "13.83944560",
        longitude: "-6.06791940",
        type: null,
      },
      {
        id: 254,
        name: "Sikasso Region",
        state_code: "3",
        latitude: "10.89051860",
        longitude: "-7.43813550",
        type: null,
      },
      {
        id: 256,
        name: "Taoudénit Region",
        state_code: "10",
        latitude: "22.67641320",
        longitude: "-3.97891430",
        type: null,
      },
      {
        id: 248,
        name: "Tombouctou Region",
        state_code: "6",
        latitude: "21.05267060",
        longitude: "-3.74350900",
        type: null,
      },
    ],
  },
  {
    name: "Malta",
    iso3: "MLT",
    iso2: "MT",
    numeric_code: "470",
    phone_code: "356",
    capital: "Valletta",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mt",
    native: "Malta",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Maltese",
    timezones: [
      {
        zoneName: "Europe/Malta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "몰타",
      "pt-BR": "Malta",
      pt: "Malta",
      nl: "Malta",
      hr: "Malta",
      fa: "مالت",
      de: "Malta",
      es: "Malta",
      fr: "Malte",
      ja: "マルタ",
      it: "Malta",
      cn: "马耳他",
      tr: "Malta",
    },
    latitude: "35.83333333",
    longitude: "14.58333333",
    emoji: "🇲🇹",
    emojiU: "U+1F1F2 U+1F1F9",
    states: [
      {
        id: 110,
        name: "Attard",
        state_code: "01",
        latitude: "35.89049670",
        longitude: "14.41993220",
        type: null,
      },
      {
        id: 108,
        name: "Balzan",
        state_code: "02",
        latitude: "35.89574140",
        longitude: "14.45340650",
        type: null,
      },
      {
        id: 107,
        name: "Birgu",
        state_code: "03",
        latitude: "35.88792140",
        longitude: "14.52256200",
        type: null,
      },
      {
        id: 97,
        name: "Birkirkara",
        state_code: "04",
        latitude: "35.89547060",
        longitude: "14.46650720",
        type: null,
      },
      {
        id: 88,
        name: "Birżebbuġa",
        state_code: "05",
        latitude: "35.81359890",
        longitude: "14.52474630",
        type: null,
      },
      {
        id: 138,
        name: "Cospicua",
        state_code: "06",
        latitude: "35.88067530",
        longitude: "14.52183380",
        type: null,
      },
      {
        id: 117,
        name: "Dingli",
        state_code: "07",
        latitude: "35.86273090",
        longitude: "14.38501070",
        type: null,
      },
      {
        id: 129,
        name: "Fgura",
        state_code: "08",
        latitude: "35.87382690",
        longitude: "14.52329010",
        type: null,
      },
      {
        id: 84,
        name: "Floriana",
        state_code: "09",
        latitude: "45.49521850",
        longitude: "-73.71395760",
        type: null,
      },
      {
        id: 134,
        name: "Fontana",
        state_code: "10",
        latitude: "34.09223350",
        longitude: "-117.43504800",
        type: null,
      },
      {
        id: 130,
        name: "Għajnsielem",
        state_code: "13",
        latitude: "36.02479660",
        longitude: "14.28029610",
        type: null,
      },
      {
        id: 92,
        name: "Għarb",
        state_code: "14",
        latitude: "36.06890900",
        longitude: "14.20180980",
        type: null,
      },
      {
        id: 120,
        name: "Għargħur",
        state_code: "15",
        latitude: "35.92205690",
        longitude: "14.45631760",
        type: null,
      },
      {
        id: 106,
        name: "Għasri",
        state_code: "16",
        latitude: "36.06680750",
        longitude: "14.21924750",
        type: null,
      },
      {
        id: 124,
        name: "Għaxaq",
        state_code: "17",
        latitude: "35.84403590",
        longitude: "14.51600900",
        type: null,
      },
      {
        id: 118,
        name: "Gudja",
        state_code: "11",
        latitude: "35.84698030",
        longitude: "14.50290400",
        type: null,
      },
      {
        id: 113,
        name: "Gżira",
        state_code: "12",
        latitude: "35.90589700",
        longitude: "14.49533380",
        type: null,
      },
      {
        id: 105,
        name: "Ħamrun",
        state_code: "18",
        latitude: "35.88612370",
        longitude: "14.48834420",
        type: null,
      },
      {
        id: 93,
        name: "Iklin",
        state_code: "19",
        latitude: "35.90987740",
        longitude: "14.45777320",
        type: null,
      },
      {
        id: 99,
        name: "Kalkara",
        state_code: "21",
        latitude: "35.89142420",
        longitude: "14.53202780",
        type: null,
      },
      {
        id: 91,
        name: "Kerċem",
        state_code: "22",
        latitude: "36.04479390",
        longitude: "14.22506050",
        type: null,
      },
      {
        id: 82,
        name: "Kirkop",
        state_code: "23",
        latitude: "35.84378620",
        longitude: "14.48543240",
        type: null,
      },
      {
        id: 126,
        name: "Lija",
        state_code: "24",
        latitude: "49.18007600",
        longitude: "-123.10331700",
        type: null,
      },
      {
        id: 77,
        name: "Luqa",
        state_code: "25",
        latitude: "35.85828650",
        longitude: "14.48688830",
        type: null,
      },
      {
        id: 128,
        name: "Marsa",
        state_code: "26",
        latitude: "34.03195870",
        longitude: "-118.44555350",
        type: null,
      },
      {
        id: 137,
        name: "Marsaskala",
        state_code: "27",
        latitude: "35.86036400",
        longitude: "14.55678760",
        type: null,
      },
      {
        id: 78,
        name: "Marsaxlokk",
        state_code: "28",
        latitude: "35.84116990",
        longitude: "14.53930970",
        type: null,
      },
      {
        id: 89,
        name: "Mdina",
        state_code: "29",
        latitude: "35.88809300",
        longitude: "14.40683570",
        type: null,
      },
      {
        id: 102,
        name: "Mellieħa",
        state_code: "30",
        latitude: "35.95235290",
        longitude: "14.35009750",
        type: null,
      },
      {
        id: 109,
        name: "Mġarr",
        state_code: "31",
        latitude: "35.91893270",
        longitude: "14.36173430",
        type: null,
      },
      {
        id: 140,
        name: "Mosta",
        state_code: "32",
        latitude: "35.91415040",
        longitude: "14.42284270",
        type: null,
      },
      {
        id: 74,
        name: "Mqabba",
        state_code: "33",
        latitude: "35.84441430",
        longitude: "14.46941860",
        type: null,
      },
      {
        id: 96,
        name: "Msida",
        state_code: "34",
        latitude: "35.89563880",
        longitude: "14.48688830",
        type: null,
      },
      {
        id: 131,
        name: "Mtarfa",
        state_code: "35",
        latitude: "35.88951250",
        longitude: "14.39519530",
        type: null,
      },
      {
        id: 132,
        name: "Munxar",
        state_code: "36",
        latitude: "36.02880580",
        longitude: "14.22506050",
        type: null,
      },
      {
        id: 133,
        name: "Nadur",
        state_code: "37",
        latitude: "36.04470190",
        longitude: "14.29192730",
        type: null,
      },
      {
        id: 112,
        name: "Naxxar",
        state_code: "38",
        latitude: "35.93175180",
        longitude: "14.43157460",
        type: null,
      },
      {
        id: 115,
        name: "Paola",
        state_code: "39",
        latitude: "38.57223530",
        longitude: "-94.87912940",
        type: null,
      },
      {
        id: 125,
        name: "Pembroke",
        state_code: "40",
        latitude: "34.68016260",
        longitude: "-79.19503730",
        type: null,
      },
      {
        id: 127,
        name: "Pietà",
        state_code: "41",
        latitude: "42.21862000",
        longitude: "-83.73464700",
        type: null,
      },
      {
        id: 79,
        name: "Qala",
        state_code: "42",
        latitude: "36.03886280",
        longitude: "14.31810100",
        type: null,
      },
      {
        id: 119,
        name: "Qormi",
        state_code: "43",
        latitude: "35.87643880",
        longitude: "14.46941860",
        type: null,
      },
      {
        id: 111,
        name: "Qrendi",
        state_code: "44",
        latitude: "35.83284880",
        longitude: "14.45486210",
        type: null,
      },
      {
        id: 83,
        name: "Rabat",
        state_code: "46",
        latitude: "33.97159040",
        longitude: "-6.84981290",
        type: null,
      },
      {
        id: 87,
        name: "Saint Lawrence",
        state_code: "50",
        latitude: "38.95780560",
        longitude: "-95.25656890",
        type: null,
      },
      {
        id: 75,
        name: "San Ġwann",
        state_code: "49",
        latitude: "35.90773650",
        longitude: "14.47524160",
        type: null,
      },
      {
        id: 116,
        name: "Sannat",
        state_code: "52",
        latitude: "36.01926430",
        longitude: "14.25994370",
        type: null,
      },
      {
        id: 94,
        name: "Santa Luċija",
        state_code: "53",
        latitude: "35.85614200",
        longitude: "14.50436000",
        type: null,
      },
      {
        id: 90,
        name: "Santa Venera",
        state_code: "54",
        latitude: "35.89022010",
        longitude: "14.47669740",
        type: null,
      },
      {
        id: 136,
        name: "Senglea",
        state_code: "20",
        latitude: "35.88730410",
        longitude: "14.51673710",
        type: null,
      },
      {
        id: 98,
        name: "Siġġiewi",
        state_code: "55",
        latitude: "35.84637420",
        longitude: "14.43157460",
        type: null,
      },
      {
        id: 104,
        name: "Sliema",
        state_code: "56",
        latitude: "35.91100810",
        longitude: "14.50290400",
        type: null,
      },
      {
        id: 100,
        name: "St. Julian's",
        state_code: "48",
        latitude: "42.21225130",
        longitude: "-85.89171270",
        type: null,
      },
      {
        id: 139,
        name: "St. Paul's Bay",
        state_code: "51",
        latitude: "35.93601700",
        longitude: "14.39665030",
        type: null,
      },
      {
        id: 86,
        name: "Swieqi",
        state_code: "57",
        latitude: "35.91911820",
        longitude: "14.46941860",
        type: null,
      },
      {
        id: 122,
        name: "Ta' Xbiex",
        state_code: "58",
        latitude: "35.89914480",
        longitude: "14.49635190",
        type: null,
      },
      {
        id: 103,
        name: "Tarxien",
        state_code: "59",
        latitude: "35.86725520",
        longitude: "14.51164050",
        type: null,
      },
      {
        id: 95,
        name: "Valletta",
        state_code: "60",
        latitude: "35.89890850",
        longitude: "14.51455280",
        type: null,
      },
      {
        id: 101,
        name: "Victoria",
        state_code: "45",
        latitude: "28.80526740",
        longitude: "-97.00359820",
        type: null,
      },
      {
        id: 114,
        name: "Xagħra",
        state_code: "61",
        latitude: "36.05084500",
        longitude: "14.26748200",
        type: null,
      },
      {
        id: 121,
        name: "Xewkija",
        state_code: "62",
        latitude: "36.02992360",
        longitude: "14.25994370",
        type: null,
      },
      {
        id: 81,
        name: "Xgħajra",
        state_code: "63",
        latitude: "35.88682820",
        longitude: "14.54723910",
        type: null,
      },
      {
        id: 123,
        name: "Żabbar",
        state_code: "64",
        latitude: "35.87247150",
        longitude: "14.54513540",
        type: null,
      },
      {
        id: 85,
        name: "Żebbuġ Gozo",
        state_code: "65",
        latitude: "36.07164030",
        longitude: "14.24540800",
        type: null,
      },
      {
        id: 80,
        name: "Żebbuġ Malta",
        state_code: "66",
        latitude: "35.87646480",
        longitude: "14.43908400",
        type: null,
      },
      {
        id: 135,
        name: "Żejtun",
        state_code: "67",
        latitude: "35.85487140",
        longitude: "14.53639690",
        type: null,
      },
      {
        id: 76,
        name: "Żurrieq",
        state_code: "68",
        latitude: "35.82163060",
        longitude: "14.48106480",
        type: null,
      },
    ],
  },
  {
    name: "Man (Isle of)",
    iso3: "IMN",
    iso2: "IM",
    numeric_code: "833",
    phone_code: "+44-1624",
    capital: "Douglas, Isle of Man",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".im",
    native: "Isle of Man",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Manx",
    timezones: [
      {
        zoneName: "Europe/Isle_of_Man",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "맨 섬",
      "pt-BR": "Ilha de Man",
      pt: "Ilha de Man",
      nl: "Isle of Man",
      hr: "Otok Man",
      fa: "جزیره من",
      de: "Insel Man",
      es: "Isla de Man",
      fr: "Île de Man",
      ja: "マン島",
      it: "Isola di Man",
      cn: "马恩岛",
      tr: "Man Adasi",
    },
    latitude: "54.25000000",
    longitude: "-4.50000000",
    emoji: "🇮🇲",
    emojiU: "U+1F1EE U+1F1F2",
    states: [],
  },
  {
    name: "Marshall Islands",
    iso3: "MHL",
    iso2: "MH",
    numeric_code: "584",
    phone_code: "692",
    capital: "Majuro",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mh",
    native: "M̧ajeļ",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Marshallese",
    timezones: [
      {
        zoneName: "Pacific/Kwajalein",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time",
      },
      {
        zoneName: "Pacific/Majuro",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "MHT",
        tzName: "Marshall Islands Time",
      },
    ],
    translations: {
      kr: "마셜 제도",
      "pt-BR": "Ilhas Marshall",
      pt: "Ilhas Marshall",
      nl: "Marshalleilanden",
      hr: "Maršalovi Otoci",
      fa: "جزایر مارشال",
      de: "Marshallinseln",
      es: "Islas Marshall",
      fr: "Îles Marshall",
      ja: "マーシャル諸島",
      it: "Isole Marshall",
      cn: "马绍尔群岛",
      tr: "Marşal Adalari",
    },
    latitude: "9.00000000",
    longitude: "168.00000000",
    emoji: "🇲🇭",
    emojiU: "U+1F1F2 U+1F1ED",
    states: [
      {
        id: 2574,
        name: "Ralik Chain",
        state_code: "L",
        latitude: "8.13614600",
        longitude: "164.88679560",
        type: null,
      },
      {
        id: 2573,
        name: "Ratak Chain",
        state_code: "T",
        latitude: "10.27632760",
        longitude: "170.55009370",
        type: null,
      },
    ],
  },
  {
    name: "Martinique",
    iso3: "MTQ",
    iso2: "MQ",
    numeric_code: "474",
    phone_code: "596",
    capital: "Fort-de-France",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mq",
    native: "Martinique",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Martiniquais, Martinican",
    timezones: [
      {
        zoneName: "America/Martinique",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "마르티니크",
      "pt-BR": "Martinica",
      pt: "Martinica",
      nl: "Martinique",
      hr: "Martinique",
      fa: "مونتسرات",
      de: "Martinique",
      es: "Martinica",
      fr: "Martinique",
      ja: "マルティニーク",
      it: "Martinica",
      cn: "马提尼克岛",
      tr: "Martinik",
    },
    latitude: "14.66666700",
    longitude: "-61.00000000",
    emoji: "🇲🇶",
    emojiU: "U+1F1F2 U+1F1F6",
    states: [],
  },
  {
    name: "Mauritania",
    iso3: "MRT",
    iso2: "MR",
    numeric_code: "478",
    phone_code: "222",
    capital: "Nouakchott",
    currency: "MRO",
    currency_name: "Mauritanian ouguiya",
    currency_symbol: "MRU",
    tld: ".mr",
    native: "موريتانيا",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Mauritanian",
    timezones: [
      {
        zoneName: "Africa/Nouakchott",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "모리타니",
      "pt-BR": "Mauritânia",
      pt: "Mauritânia",
      nl: "Mauritanië",
      hr: "Mauritanija",
      fa: "موریتانی",
      de: "Mauretanien",
      es: "Mauritania",
      fr: "Mauritanie",
      ja: "モーリタニア",
      it: "Mauritania",
      cn: "毛里塔尼亚",
      tr: "Moritanya",
    },
    latitude: "20.00000000",
    longitude: "-12.00000000",
    emoji: "🇲🇷",
    emojiU: "U+1F1F2 U+1F1F7",
    states: [
      {
        id: 3344,
        name: "Adrar",
        state_code: "07",
        latitude: "19.86521760",
        longitude: "-12.80547530",
        type: "region",
      },
      {
        id: 3349,
        name: "Assaba",
        state_code: "03",
        latitude: "16.77595580",
        longitude: "-11.52480550",
        type: "region",
      },
      {
        id: 3339,
        name: "Brakna",
        state_code: "05",
        latitude: "17.23175610",
        longitude: "-13.17403480",
        type: "region",
      },
      {
        id: 3346,
        name: "Dakhlet Nouadhibou",
        state_code: "08",
        latitude: "20.59855880",
        longitude: "-16.25221430",
        type: "region",
      },
      {
        id: 3341,
        name: "Gorgol",
        state_code: "04",
        latitude: "15.97173570",
        longitude: "-12.62162110",
        type: "region",
      },
      {
        id: 3350,
        name: "Guidimaka",
        state_code: "10",
        latitude: "15.25573310",
        longitude: "-12.25479190",
        type: "region",
      },
      {
        id: 3338,
        name: "Hodh Ech Chargui",
        state_code: "01",
        latitude: "18.67370260",
        longitude: "-7.09287700",
        type: "region",
      },
      {
        id: 3351,
        name: "Hodh El Gharbi",
        state_code: "02",
        latitude: "16.69121490",
        longitude: "-9.54509740",
        type: "region",
      },
      {
        id: 3342,
        name: "Inchiri",
        state_code: "12",
        latitude: "20.02805610",
        longitude: "-15.40680790",
        type: "region",
      },
      {
        id: 3343,
        name: "Nouakchott-Nord",
        state_code: "14",
        latitude: "18.11302050",
        longitude: "-15.89949560",
        type: "region",
      },
      {
        id: 3352,
        name: "Nouakchott-Ouest",
        state_code: "13",
        latitude: "18.15113570",
        longitude: "-15.99349100",
        type: "region",
      },
      {
        id: 3347,
        name: "Nouakchott-Sud",
        state_code: "15",
        latitude: "17.97092880",
        longitude: "-15.94648740",
        type: "region",
      },
      {
        id: 3345,
        name: "Tagant",
        state_code: "09",
        latitude: "18.54675270",
        longitude: "-9.90181310",
        type: "region",
      },
      {
        id: 3340,
        name: "Tiris Zemmour",
        state_code: "11",
        latitude: "24.57737640",
        longitude: "-9.90181310",
        type: "region",
      },
      {
        id: 3348,
        name: "Trarza",
        state_code: "06",
        latitude: "17.86649640",
        longitude: "-14.65878210",
        type: "region",
      },
    ],
  },
  {
    name: "Mauritius",
    iso3: "MUS",
    iso2: "MU",
    numeric_code: "480",
    phone_code: "230",
    capital: "Port Louis",
    currency: "MUR",
    currency_name: "Mauritian rupee",
    currency_symbol: "₨",
    tld: ".mu",
    native: "Maurice",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mauritian",
    timezones: [
      {
        zoneName: "Indian/Mauritius",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MUT",
        tzName: "Mauritius Time",
      },
    ],
    translations: {
      kr: "모리셔스",
      "pt-BR": "Maurício",
      pt: "Maurícia",
      nl: "Mauritius",
      hr: "Mauricijus",
      fa: "موریس",
      de: "Mauritius",
      es: "Mauricio",
      fr: "Île Maurice",
      ja: "モーリシャス",
      it: "Mauritius",
      cn: "毛里求斯",
      tr: "Morityus",
    },
    latitude: "-20.28333333",
    longitude: "57.55000000",
    emoji: "🇲🇺",
    emojiU: "U+1F1F2 U+1F1FA",
    states: [
      {
        id: 3248,
        name: "Agalega Islands",
        state_code: "AG",
        latitude: "-10.40000000",
        longitude: "56.61666670",
        type: "dependency",
      },
      {
        id: 3259,
        name: "Black River",
        state_code: "BL",
        latitude: "-20.37084920",
        longitude: "57.39486490",
        type: "district",
      },
      {
        id: 3254,
        name: "Flacq",
        state_code: "FL",
        latitude: "-20.22578360",
        longitude: "57.71192740",
        type: "district",
      },
      {
        id: 3264,
        name: "Grand Port",
        state_code: "GP",
        latitude: "-20.38515460",
        longitude: "57.66657420",
        type: "district",
      },
      {
        id: 3253,
        name: "Moka",
        state_code: "MO",
        latitude: "-20.23997820",
        longitude: "57.57592600",
        type: "district",
      },
      {
        id: 3250,
        name: "Pamplemousses",
        state_code: "PA",
        latitude: "-20.11360080",
        longitude: "57.57592600",
        type: "district",
      },
      {
        id: 3263,
        name: "Plaines Wilhems",
        state_code: "PW",
        latitude: "-20.30548720",
        longitude: "57.48535610",
        type: "district",
      },
      {
        id: 3260,
        name: "Port Louis",
        state_code: "PL",
        latitude: "-20.16089120",
        longitude: "57.50122220",
        type: "district",
      },
      {
        id: 3261,
        name: "Rivière du Rempart",
        state_code: "RR",
        latitude: "-20.05609830",
        longitude: "57.65523890",
        type: "district",
      },
      {
        id: 3249,
        name: "Rodrigues Island",
        state_code: "RO",
        latitude: "-19.72453850",
        longitude: "63.42721850",
        type: "dependency",
      },
      {
        id: 3251,
        name: "Saint Brandon Islands",
        state_code: "CC",
        latitude: "-16.58333300",
        longitude: "59.61666700",
        type: "dependency",
      },
      {
        id: 3257,
        name: "Savanne",
        state_code: "SA",
        latitude: "-20.47395300",
        longitude: "57.48535610",
        type: "district",
      },
    ],
  },
  {
    name: "Mayotte",
    iso3: "MYT",
    iso2: "YT",
    numeric_code: "175",
    phone_code: "262",
    capital: "Mamoudzou",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".yt",
    native: "Mayotte",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mahoran",
    timezones: [
      {
        zoneName: "Indian/Mayotte",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "마요트",
      "pt-BR": "Mayotte",
      pt: "Mayotte",
      nl: "Mayotte",
      hr: "Mayotte",
      fa: "مایوت",
      de: "Mayotte",
      es: "Mayotte",
      fr: "Mayotte",
      ja: "マヨット",
      it: "Mayotte",
      cn: "马约特",
      tr: "Mayotte",
    },
    latitude: "-12.83333333",
    longitude: "45.16666666",
    emoji: "🇾🇹",
    emojiU: "U+1F1FE U+1F1F9",
    states: [],
  },
  {
    name: "Mexico",
    iso3: "MEX",
    iso2: "MX",
    numeric_code: "484",
    phone_code: "52",
    capital: "Ciudad de México",
    currency: "MXN",
    currency_name: "Mexican peso",
    currency_symbol: "$",
    tld: ".mx",
    native: "México",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Mexican",
    timezones: [
      {
        zoneName: "America/Bahia_Banderas",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Cancun",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Chihuahua",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Hermosillo",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Matamoros",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Mazatlan",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Merida",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Mexico_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Monterrey",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Ojinaga",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Tijuana",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
    ],
    translations: {
      kr: "멕시코",
      "pt-BR": "México",
      pt: "México",
      nl: "Mexico",
      hr: "Meksiko",
      fa: "مکزیک",
      de: "Mexiko",
      es: "México",
      fr: "Mexique",
      ja: "メキシコ",
      it: "Messico",
      cn: "墨西哥",
      tr: "Meksika",
    },
    latitude: "23.00000000",
    longitude: "-102.00000000",
    emoji: "🇲🇽",
    emojiU: "U+1F1F2 U+1F1FD",
    states: [
      {
        id: 3456,
        name: "Aguascalientes",
        state_code: "AGU",
        latitude: "21.88525620",
        longitude: "-102.29156770",
        type: "state",
      },
      {
        id: 3457,
        name: "Baja California",
        state_code: "BCN",
        latitude: "30.84063380",
        longitude: "-115.28375850",
        type: "state",
      },
      {
        id: 3460,
        name: "Baja California Sur",
        state_code: "BCS",
        latitude: "26.04444460",
        longitude: "-111.66607250",
        type: "state",
      },
      {
        id: 3475,
        name: "Campeche",
        state_code: "CAM",
        latitude: "19.83012510",
        longitude: "-90.53490870",
        type: "state",
      },
      {
        id: 3451,
        name: "Chiapas",
        state_code: "CHP",
        latitude: "16.75693180",
        longitude: "-93.12923530",
        type: "state",
      },
      {
        id: 3447,
        name: "Chihuahua",
        state_code: "CHH",
        latitude: "28.63299570",
        longitude: "-106.06910040",
        type: "state",
      },
      {
        id: 3473,
        name: "Ciudad de México",
        state_code: "CMX",
        latitude: "19.43260770",
        longitude: "-99.13320800",
        type: "federal district",
      },
      {
        id: 3471,
        name: "Coahuila de Zaragoza",
        state_code: "COA",
        latitude: "27.05867600",
        longitude: "-101.70682940",
        type: "state",
      },
      {
        id: 3472,
        name: "Colima",
        state_code: "COL",
        latitude: "19.24523420",
        longitude: "-103.72408680",
        type: "state",
      },
      {
        id: 3453,
        name: "Durango",
        state_code: "DUR",
        latitude: "37.27528000",
        longitude: "-107.88006670",
        type: "state",
      },
      {
        id: 3450,
        name: "Estado de México",
        state_code: "MEX",
        latitude: "23.63450100",
        longitude: "-102.55278400",
        type: "state",
      },
      {
        id: 3469,
        name: "Guanajuato",
        state_code: "GUA",
        latitude: "21.01901450",
        longitude: "-101.25735860",
        type: "state",
      },
      {
        id: 3459,
        name: "Guerrero",
        state_code: "GRO",
        latitude: "17.43919260",
        longitude: "-99.54509740",
        type: "state",
      },
      {
        id: 3470,
        name: "Hidalgo",
        state_code: "HID",
        latitude: "26.10035470",
        longitude: "-98.26306840",
        type: "state",
      },
      {
        id: 4857,
        name: "Jalisco",
        state_code: "JAL",
        latitude: "20.65953820",
        longitude: "-103.34943760",
        type: "state",
      },
      {
        id: 3474,
        name: "Michoacán de Ocampo",
        state_code: "MIC",
        latitude: "19.56651920",
        longitude: "-101.70682940",
        type: "state",
      },
      {
        id: 3465,
        name: "Morelos",
        state_code: "MOR",
        latitude: "18.68130490",
        longitude: "-99.10134980",
        type: "state",
      },
      {
        id: 3477,
        name: "Nayarit",
        state_code: "NAY",
        latitude: "21.75138440",
        longitude: "-104.84546190",
        type: "state",
      },
      {
        id: 3452,
        name: "Nuevo León",
        state_code: "NLE",
        latitude: "25.59217200",
        longitude: "-99.99619470",
        type: "state",
      },
      {
        id: 3448,
        name: "Oaxaca",
        state_code: "OAX",
        latitude: "17.07318420",
        longitude: "-96.72658890",
        type: "state",
      },
      {
        id: 3476,
        name: "Puebla",
        state_code: "PUE",
        latitude: "19.04143980",
        longitude: "-98.20627270",
        type: "state",
      },
      {
        id: 3455,
        name: "Querétaro",
        state_code: "QUE",
        latitude: "20.58879320",
        longitude: "-100.38988810",
        type: "state",
      },
      {
        id: 3467,
        name: "Quintana Roo",
        state_code: "ROO",
        latitude: "19.18173930",
        longitude: "-88.47913760",
        type: "state",
      },
      {
        id: 3461,
        name: "San Luis Potosí",
        state_code: "SLP",
        latitude: "22.15646990",
        longitude: "-100.98554090",
        type: "state",
      },
      {
        id: 3449,
        name: "Sinaloa",
        state_code: "SIN",
        latitude: "25.17210910",
        longitude: "-107.47951730",
        type: "state",
      },
      {
        id: 3468,
        name: "Sonora",
        state_code: "SON",
        latitude: "37.98294960",
        longitude: "-120.38217240",
        type: "state",
      },
      {
        id: 3454,
        name: "Tabasco",
        state_code: "TAB",
        latitude: "17.84091730",
        longitude: "-92.61892730",
        type: "state",
      },
      {
        id: 3463,
        name: "Tamaulipas",
        state_code: "TAM",
        latitude: "24.26694000",
        longitude: "-98.83627550",
        type: "state",
      },
      {
        id: 3458,
        name: "Tlaxcala",
        state_code: "TLA",
        latitude: "19.31815400",
        longitude: "-98.23749540",
        type: "state",
      },
      {
        id: 3464,
        name: "Veracruz de Ignacio de la Llave",
        state_code: "VER",
        latitude: "19.17377300",
        longitude: "-96.13422410",
        type: "state",
      },
      {
        id: 3466,
        name: "Yucatán",
        state_code: "YUC",
        latitude: "20.70987860",
        longitude: "-89.09433770",
        type: "state",
      },
      {
        id: 3462,
        name: "Zacatecas",
        state_code: "ZAC",
        latitude: "22.77085550",
        longitude: "-102.58324260",
        type: "state",
      },
    ],
  },
  {
    name: "Micronesia",
    iso3: "FSM",
    iso2: "FM",
    numeric_code: "583",
    phone_code: "691",
    capital: "Palikir",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".fm",
    native: "Micronesia",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Micronesian",
    timezones: [
      {
        zoneName: "Pacific/Chuuk",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "CHUT",
        tzName: "Chuuk Time",
      },
      {
        zoneName: "Pacific/Kosrae",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "KOST",
        tzName: "Kosrae Time",
      },
      {
        zoneName: "Pacific/Pohnpei",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "PONT",
        tzName: "Pohnpei Standard Time",
      },
    ],
    translations: {
      kr: "미크로네시아 연방",
      "pt-BR": "Micronésia",
      pt: "Micronésia",
      nl: "Micronesië",
      hr: "Mikronezija",
      fa: "ایالات فدرال میکرونزی",
      de: "Mikronesien",
      es: "Micronesia",
      fr: "Micronésie",
      ja: "ミクロネシア連邦",
      it: "Micronesia",
      cn: "密克罗尼西亚",
      tr: "Mikronezya",
    },
    latitude: "6.91666666",
    longitude: "158.25000000",
    emoji: "🇫🇲",
    emojiU: "U+1F1EB U+1F1F2",
    states: [
      {
        id: 2580,
        name: "Chuuk State",
        state_code: "TRK",
        latitude: "7.13867590",
        longitude: "151.55930650",
        type: null,
      },
      {
        id: 2583,
        name: "Kosrae State",
        state_code: "KSA",
        latitude: "5.30956180",
        longitude: "162.98148770",
        type: null,
      },
      {
        id: 2581,
        name: "Pohnpei State",
        state_code: "PNI",
        latitude: "6.85412540",
        longitude: "158.26238220",
        type: null,
      },
      {
        id: 2582,
        name: "Yap State",
        state_code: "YAP",
        latitude: "8.67164900",
        longitude: "142.84393350",
        type: null,
      },
    ],
  },
  {
    name: "Moldova",
    iso3: "MDA",
    iso2: "MD",
    numeric_code: "498",
    phone_code: "373",
    capital: "Chisinau",
    currency: "MDL",
    currency_name: "Moldovan leu",
    currency_symbol: "L",
    tld: ".md",
    native: "Moldova",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Moldovan",
    timezones: [
      {
        zoneName: "Europe/Chisinau",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "몰도바",
      "pt-BR": "Moldávia",
      pt: "Moldávia",
      nl: "Moldavië",
      hr: "Moldova",
      fa: "مولداوی",
      de: "Moldawie",
      es: "Moldavia",
      fr: "Moldavie",
      ja: "モルドバ共和国",
      it: "Moldavia",
      cn: "摩尔多瓦",
      tr: "Moldova",
    },
    latitude: "47.00000000",
    longitude: "29.00000000",
    emoji: "🇲🇩",
    emojiU: "U+1F1F2 U+1F1E9",
    states: [
      {
        id: 4368,
        name: "Anenii Noi District",
        state_code: "AN",
        latitude: "46.87956630",
        longitude: "29.23121750",
        type: null,
      },
      {
        id: 4393,
        name: "Bălți Municipality",
        state_code: "BA",
        latitude: "47.75399470",
        longitude: "27.91841480",
        type: null,
      },
      {
        id: 4379,
        name: "Basarabeasca District",
        state_code: "BS",
        latitude: "46.42370600",
        longitude: "28.89354920",
        type: null,
      },
      {
        id: 4362,
        name: "Bender Municipality",
        state_code: "BD",
        latitude: "46.82275510",
        longitude: "29.46201010",
        type: null,
      },
      {
        id: 4375,
        name: "Briceni District",
        state_code: "BR",
        latitude: "48.36320220",
        longitude: "27.07503980",
        type: null,
      },
      {
        id: 4391,
        name: "Cahul District",
        state_code: "CA",
        latitude: "45.89394040",
        longitude: "28.18902750",
        type: null,
      },
      {
        id: 4366,
        name: "Călărași District",
        state_code: "CL",
        latitude: "47.28694600",
        longitude: "28.27453100",
        type: null,
      },
      {
        id: 4380,
        name: "Cantemir District",
        state_code: "CT",
        latitude: "46.27717420",
        longitude: "28.20096530",
        type: null,
      },
      {
        id: 4365,
        name: "Căușeni District",
        state_code: "CS",
        latitude: "46.65547150",
        longitude: "29.40912220",
        type: null,
      },
      {
        id: 4373,
        name: "Chișinău Municipality",
        state_code: "CU",
        latitude: "47.01045290",
        longitude: "28.86381020",
        type: null,
      },
      {
        id: 4360,
        name: "Cimișlia District",
        state_code: "CM",
        latitude: "46.52508510",
        longitude: "28.77218350",
        type: null,
      },
      {
        id: 4390,
        name: "Criuleni District",
        state_code: "CR",
        latitude: "47.21361140",
        longitude: "29.15575190",
        type: null,
      },
      {
        id: 4384,
        name: "Dondușeni District",
        state_code: "DO",
        latitude: "48.23383050",
        longitude: "27.59980870",
        type: null,
      },
      {
        id: 4392,
        name: "Drochia District",
        state_code: "DR",
        latitude: "48.07977880",
        longitude: "27.86041140",
        type: null,
      },
      {
        id: 4383,
        name: "Dubăsari District",
        state_code: "DU",
        latitude: "47.26439420",
        longitude: "29.15503480",
        type: null,
      },
      {
        id: 4387,
        name: "Edineț District",
        state_code: "ED",
        latitude: "48.16789910",
        longitude: "27.29361430",
        type: null,
      },
      {
        id: 4381,
        name: "Fălești District",
        state_code: "FA",
        latitude: "47.56477250",
        longitude: "27.72655930",
        type: null,
      },
      {
        id: 4370,
        name: "Florești District",
        state_code: "FL",
        latitude: "47.86678490",
        longitude: "28.33918640",
        type: null,
      },
      {
        id: 4385,
        name: "Gagauzia",
        state_code: "GA",
        latitude: "46.09794350",
        longitude: "28.63846450",
        type: null,
      },
      {
        id: 4367,
        name: "Glodeni District",
        state_code: "GL",
        latitude: "47.77901560",
        longitude: "27.51680100",
        type: null,
      },
      {
        id: 4382,
        name: "Hîncești District",
        state_code: "HI",
        latitude: "46.82811470",
        longitude: "28.58508890",
        type: null,
      },
      {
        id: 4369,
        name: "Ialoveni District",
        state_code: "IA",
        latitude: "46.86308600",
        longitude: "28.82342180",
        type: null,
      },
      {
        id: 4363,
        name: "Nisporeni District",
        state_code: "NI",
        latitude: "47.07513490",
        longitude: "28.17681550",
        type: null,
      },
      {
        id: 4389,
        name: "Ocnița District",
        state_code: "OC",
        latitude: "48.41104350",
        longitude: "27.47680920",
        type: null,
      },
      {
        id: 4361,
        name: "Orhei District",
        state_code: "OR",
        latitude: "47.38604000",
        longitude: "28.83030820",
        type: null,
      },
      {
        id: 4394,
        name: "Rezina District",
        state_code: "RE",
        latitude: "47.71804470",
        longitude: "28.88710240",
        type: null,
      },
      {
        id: 4376,
        name: "Rîșcani District",
        state_code: "RI",
        latitude: "47.90701530",
        longitude: "27.53749960",
        type: null,
      },
      {
        id: 4364,
        name: "Sîngerei District",
        state_code: "SI",
        latitude: "47.63891340",
        longitude: "28.13718160",
        type: null,
      },
      {
        id: 4388,
        name: "Șoldănești District",
        state_code: "SD",
        latitude: "47.81473890",
        longitude: "28.78895860",
        type: null,
      },
      {
        id: 4374,
        name: "Soroca District",
        state_code: "SO",
        latitude: "48.15497430",
        longitude: "28.28707830",
        type: null,
      },
      {
        id: 4378,
        name: "Ștefan Vodă District",
        state_code: "SV",
        latitude: "46.55404880",
        longitude: "29.70224200",
        type: null,
      },
      {
        id: 4377,
        name: "Strășeni District",
        state_code: "ST",
        latitude: "47.14502670",
        longitude: "28.61367360",
        type: null,
      },
      {
        id: 4372,
        name: "Taraclia District",
        state_code: "TA",
        latitude: "45.89865100",
        longitude: "28.66716440",
        type: null,
      },
      {
        id: 4371,
        name: "Telenești District",
        state_code: "TE",
        latitude: "47.49839620",
        longitude: "28.36760190",
        type: null,
      },
      {
        id: 4395,
        name: "Transnistria autonomous territorial unit",
        state_code: "SN",
        latitude: "47.21529720",
        longitude: "29.46380540",
        type: null,
      },
      {
        id: 4386,
        name: "Ungheni District",
        state_code: "UN",
        latitude: "47.23057670",
        longitude: "27.78926610",
        type: null,
      },
    ],
  },
  {
    name: "Monaco",
    iso3: "MCO",
    iso2: "MC",
    numeric_code: "492",
    phone_code: "377",
    capital: "Monaco",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mc",
    native: "Monaco",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Monegasque, Monacan",
    timezones: [
      {
        zoneName: "Europe/Monaco",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "모나코",
      "pt-BR": "Mônaco",
      pt: "Mónaco",
      nl: "Monaco",
      hr: "Monako",
      fa: "موناکو",
      de: "Monaco",
      es: "Mónaco",
      fr: "Monaco",
      ja: "モナコ",
      it: "Principato di Monaco",
      cn: "摩纳哥",
      tr: "Monako",
    },
    latitude: "43.73333333",
    longitude: "7.40000000",
    emoji: "🇲🇨",
    emojiU: "U+1F1F2 U+1F1E8",
    states: [
      {
        id: 4917,
        name: "La Colle",
        state_code: "CL",
        latitude: "43.73274650",
        longitude: "7.41372760",
        type: null,
      },
      {
        id: 4918,
        name: "La Condamine",
        state_code: "CO",
        latitude: "43.73506650",
        longitude: "7.41990600",
        type: null,
      },
      {
        id: 4919,
        name: "Moneghetti",
        state_code: "MG",
        latitude: "43.73649270",
        longitude: "7.41533830",
        type: null,
      },
    ],
  },
  {
    name: "Mongolia",
    iso3: "MNG",
    iso2: "MN",
    numeric_code: "496",
    phone_code: "976",
    capital: "Ulan Bator",
    currency: "MNT",
    currency_name: "Mongolian tögrög",
    currency_symbol: "₮",
    tld: ".mn",
    native: "Монгол улс",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Mongolian",
    timezones: [
      {
        zoneName: "Asia/Choibalsan",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CHOT",
        tzName: "Choibalsan Standard Time",
      },
      {
        zoneName: "Asia/Hovd",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "HOVT",
        tzName: "Hovd Time",
      },
      {
        zoneName: "Asia/Ulaanbaatar",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "ULAT",
        tzName: "Ulaanbaatar Standard Time",
      },
    ],
    translations: {
      kr: "몽골",
      "pt-BR": "Mongólia",
      pt: "Mongólia",
      nl: "Mongolië",
      hr: "Mongolija",
      fa: "مغولستان",
      de: "Mongolei",
      es: "Mongolia",
      fr: "Mongolie",
      ja: "モンゴル",
      it: "Mongolia",
      cn: "蒙古",
      tr: "Moğolistan",
    },
    latitude: "46.00000000",
    longitude: "105.00000000",
    emoji: "🇲🇳",
    emojiU: "U+1F1F2 U+1F1F3",
    states: [
      {
        id: 1973,
        name: "Arkhangai Province",
        state_code: "073",
        latitude: "47.89711010",
        longitude: "100.72401650",
        type: null,
      },
      {
        id: 1969,
        name: "Bayan-Ölgii Province",
        state_code: "071",
        latitude: "48.39832540",
        longitude: "89.66259150",
        type: null,
      },
      {
        id: 1976,
        name: "Bayankhongor Province",
        state_code: "069",
        latitude: "45.15267070",
        longitude: "100.10736670",
        type: null,
      },
      {
        id: 1961,
        name: "Bulgan Province",
        state_code: "067",
        latitude: "48.96909130",
        longitude: "102.88317230",
        type: null,
      },
      {
        id: 1962,
        name: "Darkhan-Uul Province",
        state_code: "037",
        latitude: "49.46484340",
        longitude: "105.97459190",
        type: null,
      },
      {
        id: 1963,
        name: "Dornod Province",
        state_code: "061",
        latitude: "47.46581540",
        longitude: "115.39271200",
        type: null,
      },
      {
        id: 1981,
        name: "Dornogovi Province",
        state_code: "063",
        latitude: "43.96538890",
        longitude: "109.17734590",
        type: null,
      },
      {
        id: 1970,
        name: "Dundgovi Province",
        state_code: "059",
        latitude: "45.58227860",
        longitude: "106.76442090",
        type: null,
      },
      {
        id: 1972,
        name: "Govi-Altai Province",
        state_code: "065",
        latitude: "45.45112270",
        longitude: "95.85057660",
        type: null,
      },
      {
        id: 1978,
        name: "Govisümber Province",
        state_code: "064",
        latitude: "46.47627540",
        longitude: "108.55706270",
        type: null,
      },
      {
        id: 1974,
        name: "Khentii Province",
        state_code: "039",
        latitude: "47.60812090",
        longitude: "109.93728560",
        type: null,
      },
      {
        id: 1964,
        name: "Khovd Province",
        state_code: "043",
        latitude: "47.11296540",
        longitude: "92.31107520",
        type: null,
      },
      {
        id: 1975,
        name: "Khövsgöl Province",
        state_code: "041",
        latitude: "50.22044840",
        longitude: "100.32137680",
        type: null,
      },
      {
        id: 1967,
        name: "Ömnögovi Province",
        state_code: "053",
        latitude: "43.50002400",
        longitude: "104.28611160",
        type: null,
      },
      {
        id: 1966,
        name: "Orkhon Province",
        state_code: "035",
        latitude: "49.00470500",
        longitude: "104.30165270",
        type: null,
      },
      {
        id: 1965,
        name: "Övörkhangai Province",
        state_code: "055",
        latitude: "45.76243920",
        longitude: "103.09170320",
        type: null,
      },
      {
        id: 1980,
        name: "Selenge Province",
        state_code: "049",
        latitude: "50.00592730",
        longitude: "106.44341080",
        type: null,
      },
      {
        id: 1977,
        name: "Sükhbaatar Province",
        state_code: "051",
        latitude: "46.56531630",
        longitude: "113.53808360",
        type: null,
      },
      {
        id: 1968,
        name: "Töv Province",
        state_code: "047",
        latitude: "47.21240560",
        longitude: "106.41541000",
        type: null,
      },
      {
        id: 1971,
        name: "Uvs Province",
        state_code: "046",
        latitude: "49.64497070",
        longitude: "93.27365760",
        type: null,
      },
      {
        id: 1979,
        name: "Zavkhan Province",
        state_code: "057",
        latitude: "48.23881470",
        longitude: "96.07030190",
        type: null,
      },
    ],
  },
  {
    name: "Montenegro",
    iso3: "MNE",
    iso2: "ME",
    numeric_code: "499",
    phone_code: "382",
    capital: "Podgorica",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".me",
    native: "Црна Гора",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Montenegrin",
    timezones: [
      {
        zoneName: "Europe/Podgorica",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "몬테네그로",
      "pt-BR": "Montenegro",
      pt: "Montenegro",
      nl: "Montenegro",
      hr: "Crna Gora",
      fa: "مونته‌نگرو",
      de: "Montenegro",
      es: "Montenegro",
      fr: "Monténégro",
      ja: "モンテネグロ",
      it: "Montenegro",
      cn: "黑山",
      tr: "Karadağ",
    },
    latitude: "42.50000000",
    longitude: "19.30000000",
    emoji: "🇲🇪",
    emojiU: "U+1F1F2 U+1F1EA",
    states: [
      {
        id: 23,
        name: "Andrijevica Municipality",
        state_code: "01",
        latitude: "42.73624770",
        longitude: "19.78595560",
        type: null,
      },
      {
        id: 13,
        name: "Bar Municipality",
        state_code: "02",
        latitude: "42.12781190",
        longitude: "19.14043800",
        type: null,
      },
      {
        id: 21,
        name: "Berane Municipality",
        state_code: "03",
        latitude: "42.82572890",
        longitude: "19.90205090",
        type: null,
      },
      {
        id: 25,
        name: "Bijelo Polje Municipality",
        state_code: "04",
        latitude: "43.08465260",
        longitude: "19.71154720",
        type: null,
      },
      {
        id: 30,
        name: "Budva Municipality",
        state_code: "05",
        latitude: "42.31407200",
        longitude: "18.83138320",
        type: null,
      },
      {
        id: 14,
        name: "Danilovgrad Municipality",
        state_code: "07",
        latitude: "42.58357000",
        longitude: "19.14043800",
        type: null,
      },
      {
        id: 24,
        name: "Gusinje Municipality",
        state_code: "22",
        latitude: "42.55634550",
        longitude: "19.83060510",
        type: null,
      },
      {
        id: 31,
        name: "Kolašin Municipality",
        state_code: "09",
        latitude: "42.76019160",
        longitude: "19.42591140",
        type: null,
      },
      {
        id: 26,
        name: "Kotor Municipality",
        state_code: "10",
        latitude: "42.57402610",
        longitude: "18.64131450",
        type: null,
      },
      {
        id: 22,
        name: "Mojkovac Municipality",
        state_code: "11",
        latitude: "42.96880180",
        longitude: "19.52110630",
        type: null,
      },
      {
        id: 17,
        name: "Nikšić Municipality",
        state_code: "12",
        latitude: "42.79971840",
        longitude: "18.76009630",
        type: null,
      },
      {
        id: 28,
        name: "Old Royal Capital Cetinje",
        state_code: "06",
        latitude: "42.39309590",
        longitude: "18.91159640",
        type: null,
      },
      {
        id: 12,
        name: "Petnjica Municipality",
        state_code: "23",
        latitude: "42.93534800",
        longitude: "20.02114490",
        type: null,
      },
      {
        id: 19,
        name: "Plav Municipality",
        state_code: "13",
        latitude: "42.60013370",
        longitude: "19.94075410",
        type: null,
      },
      {
        id: 20,
        name: "Pljevlja Municipality",
        state_code: "14",
        latitude: "43.27233830",
        longitude: "19.28315310",
        type: null,
      },
      {
        id: 16,
        name: "Plužine Municipality",
        state_code: "15",
        latitude: "43.15933840",
        longitude: "18.85514840",
        type: null,
      },
      {
        id: 27,
        name: "Podgorica Municipality",
        state_code: "16",
        latitude: "42.36938340",
        longitude: "19.28315310",
        type: null,
      },
      {
        id: 15,
        name: "Rožaje Municipality",
        state_code: "17",
        latitude: "42.84083890",
        longitude: "20.16706280",
        type: null,
      },
      {
        id: 18,
        name: "Šavnik Municipality",
        state_code: "18",
        latitude: "42.96037560",
        longitude: "19.14043800",
        type: null,
      },
      {
        id: 29,
        name: "Tivat Municipality",
        state_code: "19",
        latitude: "42.42348000",
        longitude: "18.71851840",
        type: null,
      },
      {
        id: 33,
        name: "Ulcinj Municipality",
        state_code: "20",
        latitude: "41.96527950",
        longitude: "19.30694320",
        type: null,
      },
      {
        id: 32,
        name: "Žabljak Municipality",
        state_code: "21",
        latitude: "43.15551520",
        longitude: "19.12260180",
        type: null,
      },
    ],
  },
  {
    name: "Montserrat",
    iso3: "MSR",
    iso2: "MS",
    numeric_code: "500",
    phone_code: "+1-664",
    capital: "Plymouth",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".ms",
    native: "Montserrat",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Montserratian",
    timezones: [
      {
        zoneName: "America/Montserrat",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "몬트세랫",
      "pt-BR": "Montserrat",
      pt: "Monserrate",
      nl: "Montserrat",
      hr: "Montserrat",
      fa: "مایوت",
      de: "Montserrat",
      es: "Montserrat",
      fr: "Montserrat",
      ja: "モントセラト",
      it: "Montserrat",
      cn: "蒙特塞拉特",
      tr: "Montserrat",
    },
    latitude: "16.75000000",
    longitude: "-62.20000000",
    emoji: "🇲🇸",
    emojiU: "U+1F1F2 U+1F1F8",
    states: [],
  },
  {
    name: "Morocco",
    iso3: "MAR",
    iso2: "MA",
    numeric_code: "504",
    phone_code: "212",
    capital: "Rabat",
    currency: "MAD",
    currency_name: "Moroccan dirham",
    currency_symbol: "DH",
    tld: ".ma",
    native: "المغرب",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Moroccan",
    timezones: [
      {
        zoneName: "Africa/Casablanca",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time",
      },
    ],
    translations: {
      kr: "모로코",
      "pt-BR": "Marrocos",
      pt: "Marrocos",
      nl: "Marokko",
      hr: "Maroko",
      fa: "مراکش",
      de: "Marokko",
      es: "Marruecos",
      fr: "Maroc",
      ja: "モロッコ",
      it: "Marocco",
      cn: "摩洛哥",
      tr: "Fas",
    },
    latitude: "32.00000000",
    longitude: "-5.00000000",
    emoji: "🇲🇦",
    emojiU: "U+1F1F2 U+1F1E6",
    states: [
      {
        id: 4928,
        name: "Agadir-Ida-Ou-Tanane",
        state_code: "AGD",
        latitude: "30.64620910",
        longitude: "-9.83390610",
        type: "prefecture",
      },
      {
        id: 3320,
        name: "Al Haouz",
        state_code: "HAO",
        latitude: "31.29567290",
        longitude: "-7.87216000",
        type: "province",
      },
      {
        id: 3267,
        name: "Al Hoceïma",
        state_code: "HOC",
        latitude: "35.24455890",
        longitude: "-3.93174680",
        type: "province",
      },
      {
        id: 3266,
        name: "Aousserd (EH)",
        state_code: "AOU",
        latitude: "22.55215380",
        longitude: "-14.32973530",
        type: "province",
      },
      {
        id: 3297,
        name: "Assa-Zag (EH-partial)",
        state_code: "ASZ",
        latitude: "28.14023950",
        longitude: "-9.72326730",
        type: "province",
      },
      {
        id: 3321,
        name: "Azilal",
        state_code: "AZI",
        latitude: "32.00426200",
        longitude: "-6.57833870",
        type: "province",
      },
      {
        id: 3272,
        name: "Béni Mellal",
        state_code: "BEM",
        latitude: "32.34244300",
        longitude: "-6.37579900",
        type: "province",
      },
      {
        id: 3278,
        name: "Béni Mellal-Khénifra",
        state_code: "05",
        latitude: "32.57191840",
        longitude: "-6.06791940",
        type: "region",
      },
      {
        id: 3304,
        name: "Benslimane",
        state_code: "BES",
        latitude: "33.61896980",
        longitude: "-7.13055360",
        type: "province",
      },
      {
        id: 3285,
        name: "Berkane",
        state_code: "BER",
        latitude: "34.88408760",
        longitude: "-2.34188700",
        type: "province",
      },
      {
        id: 4929,
        name: "Berrechid",
        state_code: "BRR",
        latitude: "33.26025230",
        longitude: "-7.59848370",
        type: "province",
      },
      {
        id: 3275,
        name: "Boujdour (EH)",
        state_code: "BOD",
        latitude: "26.12524930",
        longitude: "-14.48473470",
        type: "province",
      },
      {
        id: 3270,
        name: "Boulemane",
        state_code: "BOM",
        latitude: "33.36251590",
        longitude: "-4.73033970",
        type: "province",
      },
      {
        id: 4930,
        name: "Casablanca",
        state_code: "CAS",
        latitude: "33.57226780",
        longitude: "-7.65703260",
        type: "prefecture",
      },
      {
        id: 3303,
        name: "Casablanca-Settat",
        state_code: "06",
        latitude: "33.21608720",
        longitude: "-7.43813550",
        type: "region",
      },
      {
        id: 3310,
        name: "Chefchaouen",
        state_code: "CHE",
        latitude: "35.01817200",
        longitude: "-5.14320680",
        type: "province",
      },
      {
        id: 3274,
        name: "Chichaoua",
        state_code: "CHI",
        latitude: "31.53835810",
        longitude: "-8.76463880",
        type: "province",
      },
      {
        id: 3302,
        name: "Chtouka-Ait Baha",
        state_code: "CHT",
        latitude: "30.10724220",
        longitude: "-9.27855830",
        type: "province",
      },
      {
        id: 3306,
        name: "Dakhla-Oued Ed-Dahab (EH)",
        state_code: "12",
        latitude: "22.73378920",
        longitude: "-14.28611160",
        type: "region",
      },
      {
        id: 3290,
        name: "Drâa-Tafilalet",
        state_code: "08",
        latitude: "31.14995380",
        longitude: "-5.39395510",
        type: "region",
      },
      {
        id: 4931,
        name: "Driouch",
        state_code: "DRI",
        latitude: "34.97603200",
        longitude: "-3.39644930",
        type: "province",
      },
      {
        id: 3291,
        name: "El Hajeb",
        state_code: "HAJ",
        latitude: "33.68573500",
        longitude: "-5.36778440",
        type: "province",
      },
      {
        id: 3280,
        name: "El Jadida",
        state_code: "JDI",
        latitude: "33.23163260",
        longitude: "-8.50071160",
        type: "province",
      },
      {
        id: 3309,
        name: "El Kelâa des Sraghna",
        state_code: "KES",
        latitude: "32.05227670",
        longitude: "-7.35165580",
        type: "province",
      },
      {
        id: 3299,
        name: "Errachidia",
        state_code: "ERR",
        latitude: "31.90512750",
        longitude: "-4.72775280",
        type: "province",
      },
      {
        id: 3292,
        name: "Es-Semara (EH-partial)",
        state_code: "ESM",
        latitude: "26.74185600",
        longitude: "-11.67836710",
        type: "province",
      },
      {
        id: 3316,
        name: "Essaouira",
        state_code: "ESI",
        latitude: "31.50849260",
        longitude: "-9.75950410",
        type: "province",
      },
      {
        id: 3300,
        name: "Fahs-Anjra",
        state_code: "FAH",
        latitude: "35.76019920",
        longitude: "-5.66683060",
        type: "province",
      },
      {
        id: 4932,
        name: "Fès",
        state_code: "FES",
        latitude: "34.02395790",
        longitude: "-5.03675990",
        type: "prefecture",
      },
      {
        id: 3313,
        name: "Fès-Meknès",
        state_code: "03",
        latitude: "34.06252900",
        longitude: "-4.72775280",
        type: "region",
      },
      {
        id: 3301,
        name: "Figuig",
        state_code: "FIG",
        latitude: "32.10926130",
        longitude: "-1.22980600",
        type: "province",
      },
      {
        id: 4933,
        name: "Fquih Ben Salah",
        state_code: "FQH",
        latitude: "32.50016800",
        longitude: "-6.71007170",
        type: "province",
      },
      {
        id: 3265,
        name: "Guelmim",
        state_code: "GUE",
        latitude: "28.98836590",
        longitude: "-10.05274980",
        type: "province",
      },
      {
        id: 3305,
        name: "Guelmim-Oued Noun (EH-partial)",
        state_code: "10",
        latitude: "28.48442810",
        longitude: "-10.08072980",
        type: "region",
      },
      {
        id: 4934,
        name: "Guercif",
        state_code: "GUF",
        latitude: "34.23450360",
        longitude: "-3.38130050",
        type: "province",
      },
      {
        id: 3325,
        name: "Ifrane",
        state_code: "IFR",
        latitude: "33.52280620",
        longitude: "-5.11095520",
        type: "province",
      },
      {
        id: 3294,
        name: "Inezgane-Ait Melloul",
        state_code: "INE",
        latitude: "30.35090980",
        longitude: "-9.38951100",
        type: "prefecture",
      },
      {
        id: 3307,
        name: "Jerada",
        state_code: "JRA",
        latitude: "34.30617910",
        longitude: "-2.17941360",
        type: "province",
      },
      {
        id: 3308,
        name: "Kénitra",
        state_code: "KEN",
        latitude: "34.25405030",
        longitude: "-6.58901660",
        type: "province",
      },
      {
        id: 3276,
        name: "Khémisset",
        state_code: "KHE",
        latitude: "33.81537040",
        longitude: "-6.05733020",
        type: "province",
      },
      {
        id: 3317,
        name: "Khénifra",
        state_code: "KHN",
        latitude: "32.93404710",
        longitude: "-5.66157100",
        type: "province",
      },
      {
        id: 3326,
        name: "Khouribga",
        state_code: "KHO",
        latitude: "32.88602300",
        longitude: "-6.92086550",
        type: "province",
      },
      {
        id: 3271,
        name: "L'Oriental",
        state_code: "02",
        latitude: "37.06968300",
        longitude: "-94.51227700",
        type: "region",
      },
      {
        id: 3293,
        name: "Laâyoune (EH)",
        state_code: "LAA",
        latitude: "27.15003840",
        longitude: "-13.19907580",
        type: "province",
      },
      {
        id: 3298,
        name: "Laâyoune-Sakia El Hamra (EH-partial)",
        state_code: "11",
        latitude: "27.86831940",
        longitude: "-11.98046130",
        type: "region",
      },
      {
        id: 3268,
        name: "Larache",
        state_code: "LAR",
        latitude: "35.17442710",
        longitude: "-6.14739640",
        type: "province",
      },
      {
        id: 4936,
        name: "M’diq-Fnideq",
        state_code: "MDF",
        latitude: "35.77330190",
        longitude: "-5.51433000",
        type: "prefecture",
      },
      {
        id: 4935,
        name: "Marrakech",
        state_code: "MAR",
        latitude: "31.63460230",
        longitude: "-8.07789320",
        type: "prefecture",
      },
      {
        id: 3288,
        name: "Marrakesh-Safi",
        state_code: "07",
        latitude: "31.73308330",
        longitude: "-8.13385580",
        type: "region",
      },
      {
        id: 3284,
        name: "Médiouna",
        state_code: "MED",
        latitude: "33.45409390",
        longitude: "-7.51660200",
        type: "province",
      },
      {
        id: 4937,
        name: "Meknès",
        state_code: "MEK",
        latitude: "33.88100000",
        longitude: "-5.57303970",
        type: "prefecture",
      },
      {
        id: 4938,
        name: "Midelt",
        state_code: "MID",
        latitude: "32.68550790",
        longitude: "-4.75017090",
        type: "province",
      },
      {
        id: 4939,
        name: "Mohammadia",
        state_code: "MOH",
        latitude: "33.68737490",
        longitude: "-7.42391420",
        type: "prefecture",
      },
      {
        id: 3315,
        name: "Moulay Yacoub",
        state_code: "MOU",
        latitude: "34.08744790",
        longitude: "-5.17840190",
        type: "province",
      },
      {
        id: 3281,
        name: "Nador",
        state_code: "NAD",
        latitude: "34.91719260",
        longitude: "-2.85771050",
        type: "province",
      },
      {
        id: 3287,
        name: "Nouaceur",
        state_code: "NOU",
        latitude: "33.36703930",
        longitude: "-7.57325370",
        type: "province",
      },
      {
        id: 3269,
        name: "Ouarzazate",
        state_code: "OUA",
        latitude: "30.93354360",
        longitude: "-6.93701600",
        type: "province",
      },
      {
        id: 3319,
        name: "Oued Ed-Dahab (EH)",
        state_code: "OUD",
        latitude: "22.73378920",
        longitude: "-14.28611160",
        type: "province",
      },
      {
        id: 4941,
        name: "Ouezzane",
        state_code: "OUZ",
        latitude: "34.80634500",
        longitude: "-5.59145050",
        type: "province",
      },
      {
        id: 4940,
        name: "Oujda-Angad",
        state_code: "OUJ",
        latitude: "34.68375040",
        longitude: "-2.29932390",
        type: "prefecture",
      },
      {
        id: 4942,
        name: "Rabat",
        state_code: "RAB",
        latitude: "33.96919900",
        longitude: "-6.92730290",
        type: "prefecture",
      },
      {
        id: 4927,
        name: "Rabat-Salé-Kénitra",
        state_code: "04",
        latitude: "34.07686400",
        longitude: "-7.34544760",
        type: "region",
      },
      {
        id: 4943,
        name: "Rehamna",
        state_code: "REH",
        latitude: "32.20329050",
        longitude: "-8.56896710",
        type: "province",
      },
      {
        id: 3311,
        name: "Safi",
        state_code: "SAF",
        latitude: "32.29898720",
        longitude: "-9.10134980",
        type: "province",
      },
      {
        id: 4944,
        name: "Salé",
        state_code: "SAL",
        latitude: "34.03775700",
        longitude: "-6.84270730",
        type: "prefecture",
      },
      {
        id: 3289,
        name: "Sefrou",
        state_code: "SEF",
        latitude: "33.83052440",
        longitude: "-4.83531540",
        type: "province",
      },
      {
        id: 3282,
        name: "Settat",
        state_code: "SET",
        latitude: "32.99242420",
        longitude: "-7.62226650",
        type: "province",
      },
      {
        id: 4945,
        name: "Sidi Bennour",
        state_code: "SIB",
        latitude: "32.64926020",
        longitude: "-8.44714530",
        type: "province",
      },
      {
        id: 4946,
        name: "Sidi Ifni",
        state_code: "SIF",
        latitude: "29.36657970",
        longitude: "-10.21084850",
        type: "province",
      },
      {
        id: 3279,
        name: "Sidi Kacem",
        state_code: "SIK",
        latitude: "34.22601720",
        longitude: "-5.71291640",
        type: "province",
      },
      {
        id: 4952,
        name: "Sidi Slimane",
        state_code: "SIL",
        latitude: "34.27378280",
        longitude: "-5.98059720",
        type: "province",
      },
      {
        id: 4947,
        name: "Skhirate-Témara",
        state_code: "SKH",
        latitude: "33.76224250",
        longitude: "-7.04190520",
        type: "prefecture",
      },
      {
        id: 3295,
        name: "Souss-Massa",
        state_code: "09",
        latitude: "30.27506110",
        longitude: "-8.13385580",
        type: "region",
      },
      {
        id: 3286,
        name: "Tan-Tan (EH-partial)",
        state_code: "TNT",
        latitude: "28.03012000",
        longitude: "-11.16173560",
        type: "province",
      },
      {
        id: 4950,
        name: "Tanger-Assilah",
        state_code: "TNG",
        latitude: "35.76325390",
        longitude: "-5.90450980",
        type: "prefecture",
      },
      {
        id: 3324,
        name: "Tanger-Tétouan-Al Hoceïma",
        state_code: "01",
        latitude: "35.26295580",
        longitude: "-5.56172790",
        type: "region",
      },
      {
        id: 3323,
        name: "Taounate",
        state_code: "TAO",
        latitude: "34.53691700",
        longitude: "-4.63986930",
        type: "province",
      },
      {
        id: 3322,
        name: "Taourirt",
        state_code: "TAI",
        latitude: "34.21259800",
        longitude: "-2.69838680",
        type: "province",
      },
      {
        id: 4948,
        name: "Tarfaya (EH-partial)",
        state_code: "TAF",
        latitude: "27.93777010",
        longitude: "-12.92940630",
        type: "province",
      },
      {
        id: 3314,
        name: "Taroudannt",
        state_code: "TAR",
        latitude: "30.47271260",
        longitude: "-8.87487650",
        type: "province",
      },
      {
        id: 3312,
        name: "Tata",
        state_code: "TAT",
        latitude: "29.75087700",
        longitude: "-7.97563430",
        type: "province",
      },
      {
        id: 3296,
        name: "Taza",
        state_code: "TAZ",
        latitude: "34.27889530",
        longitude: "-3.58126920",
        type: "province",
      },
      {
        id: 3318,
        name: "Tétouan",
        state_code: "TET",
        latitude: "35.58889950",
        longitude: "-5.36255160",
        type: "province",
      },
      {
        id: 4949,
        name: "Tinghir",
        state_code: "TIN",
        latitude: "31.48507940",
        longitude: "-6.20192980",
        type: "province",
      },
      {
        id: 3277,
        name: "Tiznit",
        state_code: "TIZ",
        latitude: "29.69339200",
        longitude: "-9.73215700",
        type: "province",
      },
      {
        id: 4951,
        name: "Youssoufia",
        state_code: "YUS",
        latitude: "32.02006790",
        longitude: "-8.86926480",
        type: "province",
      },
      {
        id: 3283,
        name: "Zagora",
        state_code: "ZAG",
        latitude: "30.57860930",
        longitude: "-5.89871390",
        type: "province",
      },
    ],
  },
  {
    name: "Mozambique",
    iso3: "MOZ",
    iso2: "MZ",
    numeric_code: "508",
    phone_code: "258",
    capital: "Maputo",
    currency: "MZN",
    currency_name: "Mozambican metical",
    currency_symbol: "MT",
    tld: ".mz",
    native: "Moçambique",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Mozambican",
    timezones: [
      {
        zoneName: "Africa/Maputo",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "모잠비크",
      "pt-BR": "Moçambique",
      pt: "Moçambique",
      nl: "Mozambique",
      hr: "Mozambik",
      fa: "موزامبیک",
      de: "Mosambik",
      es: "Mozambique",
      fr: "Mozambique",
      ja: "モザンビーク",
      it: "Mozambico",
      cn: "莫桑比克",
      tr: "Mozambik",
    },
    latitude: "-18.25000000",
    longitude: "35.00000000",
    emoji: "🇲🇿",
    emojiU: "U+1F1F2 U+1F1FF",
    states: [
      {
        id: 3327,
        name: "Cabo Delgado Province",
        state_code: "P",
        latitude: "-12.33354740",
        longitude: "39.32062410",
        type: null,
      },
      {
        id: 3329,
        name: "Gaza Province",
        state_code: "G",
        latitude: "-23.02219280",
        longitude: "32.71813750",
        type: null,
      },
      {
        id: 3330,
        name: "Inhambane Province",
        state_code: "I",
        latitude: "-22.85279970",
        longitude: "34.55087580",
        type: null,
      },
      {
        id: 3337,
        name: "Manica Province",
        state_code: "B",
        latitude: "-19.50597870",
        longitude: "33.43835300",
        type: null,
      },
      {
        id: 3335,
        name: "Maputo",
        state_code: "MPM",
        latitude: "-25.96924800",
        longitude: "32.57317460",
        type: null,
      },
      {
        id: 3332,
        name: "Maputo Province",
        state_code: "L",
        latitude: "-25.25698760",
        longitude: "32.53727410",
        type: null,
      },
      {
        id: 3336,
        name: "Nampula Province",
        state_code: "N",
        latitude: "-14.76049310",
        longitude: "39.32062410",
        type: null,
      },
      {
        id: 3333,
        name: "Niassa Province",
        state_code: "A",
        latitude: "-12.78262020",
        longitude: "36.60939260",
        type: null,
      },
      {
        id: 3331,
        name: "Sofala Province",
        state_code: "S",
        latitude: "-19.20390730",
        longitude: "34.86241660",
        type: null,
      },
      {
        id: 3334,
        name: "Tete Province",
        state_code: "T",
        latitude: "-15.65960560",
        longitude: "32.71813750",
        type: null,
      },
      {
        id: 3328,
        name: "Zambezia Province",
        state_code: "Q",
        latitude: "-16.56389870",
        longitude: "36.60939260",
        type: null,
      },
    ],
  },
  {
    name: "Myanmar",
    iso3: "MMR",
    iso2: "MM",
    numeric_code: "104",
    phone_code: "95",
    capital: "Nay Pyi Taw",
    currency: "MMK",
    currency_name: "Burmese kyat",
    currency_symbol: "K",
    tld: ".mm",
    native: "မြန်မာ",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Burmese",
    timezones: [
      {
        zoneName: "Asia/Yangon",
        gmtOffset: 23400,
        gmtOffsetName: "UTC+06:30",
        abbreviation: "MMT",
        tzName: "Myanmar Standard Time",
      },
    ],
    translations: {
      kr: "미얀마",
      "pt-BR": "Myanmar",
      pt: "Myanmar",
      nl: "Myanmar",
      hr: "Mijanmar",
      fa: "میانمار",
      de: "Myanmar",
      es: "Myanmar",
      fr: "Myanmar",
      ja: "ミャンマー",
      it: "Birmania",
      cn: "缅甸",
      tr: "Myanmar",
    },
    latitude: "22.00000000",
    longitude: "98.00000000",
    emoji: "🇲🇲",
    emojiU: "U+1F1F2 U+1F1F2",
    states: [
      {
        id: 2142,
        name: "Ayeyarwady Region",
        state_code: "07",
        latitude: "17.03421250",
        longitude: "95.22666750",
        type: null,
      },
      {
        id: 2141,
        name: "Bago",
        state_code: "02",
        latitude: "17.32207110",
        longitude: "96.46632860",
        type: null,
      },
      {
        id: 2137,
        name: "Chin State",
        state_code: "14",
        latitude: "22.00869780",
        longitude: "93.58126920",
        type: null,
      },
      {
        id: 2143,
        name: "Kachin State",
        state_code: "11",
        latitude: "25.85090400",
        longitude: "97.43813550",
        type: null,
      },
      {
        id: 2144,
        name: "Kayah State",
        state_code: "12",
        latitude: "19.23420610",
        longitude: "97.26528580",
        type: null,
      },
      {
        id: 2133,
        name: "Kayin State",
        state_code: "13",
        latitude: "16.94593460",
        longitude: "97.95928630",
        type: null,
      },
      {
        id: 2136,
        name: "Magway Region",
        state_code: "03",
        latitude: "19.88713860",
        longitude: "94.72775280",
        type: null,
      },
      {
        id: 2134,
        name: "Mandalay Region",
        state_code: "04",
        latitude: "21.56190580",
        longitude: "95.89871390",
        type: null,
      },
      {
        id: 2147,
        name: "Mon State",
        state_code: "15",
        latitude: "16.30031330",
        longitude: "97.69822720",
        type: null,
      },
      {
        id: 2146,
        name: "Naypyidaw Union Territory",
        state_code: "18",
        latitude: "19.93862450",
        longitude: "96.15269850",
        type: null,
      },
      {
        id: 2138,
        name: "Rakhine State",
        state_code: "16",
        latitude: "20.10408180",
        longitude: "93.58126920",
        type: null,
      },
      {
        id: 2145,
        name: "Sagaing Region",
        state_code: "01",
        latitude: "24.42838100",
        longitude: "95.39395510",
        type: null,
      },
      {
        id: 2139,
        name: "Shan State",
        state_code: "17",
        latitude: "22.03619850",
        longitude: "98.13385580",
        type: null,
      },
      {
        id: 2140,
        name: "Tanintharyi Region",
        state_code: "05",
        latitude: "12.47068760",
        longitude: "99.01289260",
        type: null,
      },
      {
        id: 2135,
        name: "Yangon Region",
        state_code: "06",
        latitude: "16.91434880",
        longitude: "96.15269850",
        type: null,
      },
    ],
  },
  {
    name: "Namibia",
    iso3: "NAM",
    iso2: "NA",
    numeric_code: "516",
    phone_code: "264",
    capital: "Windhoek",
    currency: "NAD",
    currency_name: "Namibian dollar",
    currency_symbol: "$",
    tld: ".na",
    native: "Namibia",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "Namibian",
    timezones: [
      {
        zoneName: "Africa/Windhoek",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "WAST",
        tzName: "West Africa Summer Time",
      },
    ],
    translations: {
      kr: "나미비아",
      "pt-BR": "Namíbia",
      pt: "Namíbia",
      nl: "Namibië",
      hr: "Namibija",
      fa: "نامیبیا",
      de: "Namibia",
      es: "Namibia",
      fr: "Namibie",
      ja: "ナミビア",
      it: "Namibia",
      cn: "纳米比亚",
      tr: "Namibya",
    },
    latitude: "-22.00000000",
    longitude: "17.00000000",
    emoji: "🇳🇦",
    emojiU: "U+1F1F3 U+1F1E6",
    states: [
      {
        id: 43,
        name: "Erongo Region",
        state_code: "ER",
        latitude: "-22.25656820",
        longitude: "15.40680790",
        type: null,
      },
      {
        id: 38,
        name: "Hardap Region",
        state_code: "HA",
        latitude: "-24.23101340",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 45,
        name: "Karas Region",
        state_code: "KA",
        latitude: "-26.84296450",
        longitude: "17.29028390",
        type: null,
      },
      {
        id: 36,
        name: "Kavango East Region",
        state_code: "KE",
        latitude: "-18.27104800",
        longitude: "18.42760470",
        type: null,
      },
      {
        id: 35,
        name: "Kavango West Region",
        state_code: "KW",
        latitude: "-18.27104800",
        longitude: "18.42760470",
        type: null,
      },
      {
        id: 44,
        name: "Khomas Region",
        state_code: "KH",
        latitude: "-22.63778540",
        longitude: "17.10119310",
        type: null,
      },
      {
        id: 34,
        name: "Kunene Region",
        state_code: "KU",
        latitude: "-19.40863170",
        longitude: "13.91439900",
        type: null,
      },
      {
        id: 40,
        name: "Ohangwena Region",
        state_code: "OW",
        latitude: "-17.59792910",
        longitude: "16.81783770",
        type: null,
      },
      {
        id: 41,
        name: "Omaheke Region",
        state_code: "OH",
        latitude: "-21.84666510",
        longitude: "19.18800470",
        type: null,
      },
      {
        id: 39,
        name: "Omusati Region",
        state_code: "OS",
        latitude: "-18.40702940",
        longitude: "14.84546190",
        type: null,
      },
      {
        id: 37,
        name: "Oshana Region",
        state_code: "ON",
        latitude: "-18.43050640",
        longitude: "15.68817880",
        type: null,
      },
      {
        id: 42,
        name: "Oshikoto Region",
        state_code: "OT",
        latitude: "-18.41525750",
        longitude: "16.91225100",
        type: null,
      },
      {
        id: 46,
        name: "Otjozondjupa Region",
        state_code: "OD",
        latitude: "-20.54869160",
        longitude: "17.66888700",
        type: null,
      },
      {
        id: 47,
        name: "Zambezi Region",
        state_code: "CA",
        latitude: "-17.81934190",
        longitude: "23.95364660",
        type: null,
      },
    ],
  },
  {
    name: "Nauru",
    iso3: "NRU",
    iso2: "NR",
    numeric_code: "520",
    phone_code: "674",
    capital: "Yaren",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nr",
    native: "Nauru",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Nauruan",
    timezones: [
      {
        zoneName: "Pacific/Nauru",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NRT",
        tzName: "Nauru Time",
      },
    ],
    translations: {
      kr: "나우루",
      "pt-BR": "Nauru",
      pt: "Nauru",
      nl: "Nauru",
      hr: "Nauru",
      fa: "نائورو",
      de: "Nauru",
      es: "Nauru",
      fr: "Nauru",
      ja: "ナウル",
      it: "Nauru",
      cn: "瑙鲁",
      tr: "Nauru",
    },
    latitude: "-0.53333333",
    longitude: "166.91666666",
    emoji: "🇳🇷",
    emojiU: "U+1F1F3 U+1F1F7",
    states: [
      {
        id: 4656,
        name: "Aiwo District",
        state_code: "01",
        latitude: "-0.53400120",
        longitude: "166.91388730",
        type: null,
      },
      {
        id: 4658,
        name: "Anabar District",
        state_code: "02",
        latitude: "-0.51335170",
        longitude: "166.94846240",
        type: null,
      },
      {
        id: 4667,
        name: "Anetan District",
        state_code: "03",
        latitude: "-0.50643430",
        longitude: "166.94270060",
        type: null,
      },
      {
        id: 4663,
        name: "Anibare District",
        state_code: "04",
        latitude: "-0.52947580",
        longitude: "166.95134320",
        type: null,
      },
      {
        id: 4660,
        name: "Baiti District",
        state_code: "05",
        latitude: "-0.51043100",
        longitude: "166.92757440",
        type: null,
      },
      {
        id: 4665,
        name: "Boe District",
        state_code: "06",
        latitude: "39.07327760",
        longitude: "-94.57104980",
        type: null,
      },
      {
        id: 4662,
        name: "Buada District",
        state_code: "07",
        latitude: "-0.53287770",
        longitude: "166.92685410",
        type: null,
      },
      {
        id: 4666,
        name: "Denigomodu District",
        state_code: "08",
        latitude: "-0.52479640",
        longitude: "166.91676890",
        type: null,
      },
      {
        id: 4654,
        name: "Ewa District",
        state_code: "09",
        latitude: "-0.50872410",
        longitude: "166.93693840",
        type: null,
      },
      {
        id: 4661,
        name: "Ijuw District",
        state_code: "10",
        latitude: "-0.52027670",
        longitude: "166.95710460",
        type: null,
      },
      {
        id: 4657,
        name: "Meneng District",
        state_code: "11",
        latitude: "-0.54672400",
        longitude: "166.93837900",
        type: null,
      },
      {
        id: 4659,
        name: "Nibok District",
        state_code: "12",
        latitude: "-0.51962080",
        longitude: "166.91893010",
        type: null,
      },
      {
        id: 4655,
        name: "Uaboe District",
        state_code: "13",
        latitude: "-0.52022220",
        longitude: "166.93117610",
        type: null,
      },
      {
        id: 4664,
        name: "Yaren District",
        state_code: "14",
        latitude: "-0.54668570",
        longitude: "166.92109130",
        type: null,
      },
    ],
  },
  {
    name: "Nepal",
    iso3: "NPL",
    iso2: "NP",
    numeric_code: "524",
    phone_code: "977",
    capital: "Kathmandu",
    currency: "NPR",
    currency_name: "Nepalese rupee",
    currency_symbol: "₨",
    tld: ".np",
    native: "नपल",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Nepali, Nepalese",
    timezones: [
      {
        zoneName: "Asia/Kathmandu",
        gmtOffset: 20700,
        gmtOffsetName: "UTC+05:45",
        abbreviation: "NPT",
        tzName: "Nepal Time",
      },
    ],
    translations: {
      kr: "네팔",
      "pt-BR": "Nepal",
      pt: "Nepal",
      nl: "Nepal",
      hr: "Nepal",
      fa: "نپال",
      de: "Népal",
      es: "Nepal",
      fr: "Népal",
      ja: "ネパール",
      it: "Nepal",
      cn: "尼泊尔",
      tr: "Nepal",
    },
    latitude: "28.00000000",
    longitude: "84.00000000",
    emoji: "🇳🇵",
    emojiU: "U+1F1F3 U+1F1F5",
    states: [
      {
        id: 2082,
        name: "Bagmati Zone",
        state_code: "BA",
        latitude: "28.03675770",
        longitude: "85.43755740",
        type: null,
      },
      {
        id: 2071,
        name: "Bheri Zone",
        state_code: "BH",
        latitude: "28.51745600",
        longitude: "81.77870210",
        type: null,
      },
      {
        id: 2073,
        name: "Central Region",
        state_code: "1",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2080,
        name: "Dhaulagiri Zone",
        state_code: "DH",
        latitude: "28.61117600",
        longitude: "83.50702030",
        type: null,
      },
      {
        id: 2069,
        name: "Eastern Development Region",
        state_code: "4",
        latitude: "27.33090720",
        longitude: "87.06242610",
        type: null,
      },
      {
        id: 2068,
        name: "Far-Western Development Region",
        state_code: "5",
        latitude: "29.29878710",
        longitude: "80.98710740",
        type: null,
      },
      {
        id: 2081,
        name: "Gandaki Zone",
        state_code: "GA",
        latitude: "28.37320370",
        longitude: "84.43827210",
        type: null,
      },
      {
        id: 2076,
        name: "Janakpur Zone",
        state_code: "JA",
        latitude: "27.21108990",
        longitude: "86.01215730",
        type: null,
      },
      {
        id: 2079,
        name: "Karnali Zone",
        state_code: "KA",
        latitude: "29.38625550",
        longitude: "82.38857830",
        type: null,
      },
      {
        id: 2072,
        name: "Kosi Zone",
        state_code: "KO",
        latitude: "27.05365240",
        longitude: "87.30161320",
        type: null,
      },
      {
        id: 2074,
        name: "Lumbini Zone",
        state_code: "LU",
        latitude: "27.45000000",
        longitude: "83.25000000",
        type: null,
      },
      {
        id: 2083,
        name: "Mahakali Zone",
        state_code: "MA",
        latitude: "29.36010790",
        longitude: "80.54384500",
        type: null,
      },
      {
        id: 2070,
        name: "Mechi Zone",
        state_code: "ME",
        latitude: "26.87600070",
        longitude: "87.93348030",
        type: null,
      },
      {
        id: 2066,
        name: "Mid-Western Region",
        state_code: "2",
        latitude: "38.41118410",
        longitude: "-90.38320980",
        type: null,
      },
      {
        id: 2075,
        name: "Narayani Zone",
        state_code: "NA",
        latitude: "27.36117660",
        longitude: "84.85679320",
        type: null,
      },
      {
        id: 2077,
        name: "Rapti Zone",
        state_code: "RA",
        latitude: "28.27434700",
        longitude: "82.38857830",
        type: null,
      },
      {
        id: 2084,
        name: "Sagarmatha Zone",
        state_code: "SA",
        latitude: "27.32382630",
        longitude: "86.74163740",
        type: null,
      },
      {
        id: 2078,
        name: "Seti Zone",
        state_code: "SE",
        latitude: "29.69054270",
        longitude: "81.33994140",
        type: null,
      },
      {
        id: 2067,
        name: "Western Region",
        state_code: "3",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Netherlands",
    iso3: "NLD",
    iso2: "NL",
    numeric_code: "528",
    phone_code: "31",
    capital: "Amsterdam",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".nl",
    native: "Nederland",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Dutch, Netherlandic",
    timezones: [
      {
        zoneName: "Europe/Amsterdam",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "네덜란드 ",
      "pt-BR": "Holanda",
      pt: "Países Baixos",
      nl: "Nederland",
      hr: "Nizozemska",
      fa: "پادشاهی هلند",
      de: "Niederlande",
      es: "Países Bajos",
      fr: "Pays-Bas",
      ja: "オランダ",
      it: "Paesi Bassi",
      cn: "荷兰",
      tr: "Hollanda",
    },
    latitude: "52.50000000",
    longitude: "5.75000000",
    emoji: "🇳🇱",
    emojiU: "U+1F1F3 U+1F1F1",
    states: [
      {
        id: 2624,
        name: "Bonaire",
        state_code: "BQ1",
        latitude: "12.20189020",
        longitude: "-68.26238220",
        type: "special municipality",
      },
      {
        id: 2613,
        name: "Drenthe",
        state_code: "DR",
        latitude: "52.94760120",
        longitude: "6.62305860",
        type: "province",
      },
      {
        id: 2619,
        name: "Flevoland",
        state_code: "FL",
        latitude: "52.52797810",
        longitude: "5.59535080",
        type: "province",
      },
      {
        id: 2622,
        name: "Friesland",
        state_code: "FR",
        latitude: "53.16416420",
        longitude: "5.78175420",
        type: "province",
      },
      {
        id: 2611,
        name: "Gelderland",
        state_code: "GE",
        latitude: "52.04515500",
        longitude: "5.87182350",
        type: "province",
      },
      {
        id: 2617,
        name: "Groningen",
        state_code: "GR",
        latitude: "53.21938350",
        longitude: "6.56650170",
        type: "province",
      },
      {
        id: 2615,
        name: "Limburg",
        state_code: "LI",
        latitude: "51.44272380",
        longitude: "6.06087260",
        type: "province",
      },
      {
        id: 2623,
        name: "North Brabant",
        state_code: "NB",
        latitude: "51.48265370",
        longitude: "5.23216870",
        type: "province",
      },
      {
        id: 2612,
        name: "North Holland",
        state_code: "NH",
        latitude: "52.52058690",
        longitude: "4.78847400",
        type: "province",
      },
      {
        id: 2618,
        name: "Overijssel",
        state_code: "OV",
        latitude: "52.43878140",
        longitude: "6.50164110",
        type: "province",
      },
      {
        id: 2621,
        name: "Saba",
        state_code: "BQ2",
        latitude: "17.63546420",
        longitude: "-63.23267630",
        type: "special municipality",
      },
      {
        id: 2616,
        name: "Sint Eustatius",
        state_code: "BQ3",
        latitude: "17.48903060",
        longitude: "-62.97355500",
        type: "special municipality",
      },
      {
        id: 2614,
        name: "South Holland",
        state_code: "ZH",
        latitude: "51.99667920",
        longitude: "4.55973970",
        type: "province",
      },
      {
        id: 2610,
        name: "Utrecht",
        state_code: "UT",
        latitude: "52.09073740",
        longitude: "5.12142010",
        type: "province",
      },
      {
        id: 2620,
        name: "Zeeland",
        state_code: "ZE",
        latitude: "51.49403090",
        longitude: "3.84968150",
        type: "province",
      },
    ],
  },
  {
    name: "New Caledonia",
    iso3: "NCL",
    iso2: "NC",
    numeric_code: "540",
    phone_code: "687",
    capital: "Noumea",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".nc",
    native: "Nouvelle-Calédonie",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "New Caledonian",
    timezones: [
      {
        zoneName: "Pacific/Noumea",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "NCT",
        tzName: "New Caledonia Time",
      },
    ],
    translations: {
      kr: "누벨칼레도니",
      "pt-BR": "Nova Caledônia",
      pt: "Nova Caledónia",
      nl: "Nieuw-Caledonië",
      hr: "Nova Kaledonija",
      fa: "کالدونیای جدید",
      de: "Neukaledonien",
      es: "Nueva Caledonia",
      fr: "Nouvelle-Calédonie",
      ja: "ニューカレドニア",
      it: "Nuova Caledonia",
      cn: "新喀里多尼亚",
      tr: "Yeni Kaledonya",
    },
    latitude: "-21.50000000",
    longitude: "165.50000000",
    emoji: "🇳🇨",
    emojiU: "U+1F1F3 U+1F1E8",
    states: [
      {
        id: 5227,
        name: "Loyalty Islands Province",
        state_code: "03",
        latitude: "-20.96670000",
        longitude: "167.23330000",
        type: "province",
      },
      {
        id: 5226,
        name: "North Province",
        state_code: "02",
        latitude: "-22.27580000",
        longitude: "166.45800000",
        type: "province",
      },
      {
        id: 5225,
        name: "South Province",
        state_code: "01",
        latitude: "-22.27580000",
        longitude: "166.45800000",
        type: "province",
      },
    ],
  },
  {
    name: "New Zealand",
    iso3: "NZL",
    iso2: "NZ",
    numeric_code: "554",
    phone_code: "64",
    capital: "Wellington",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nz",
    native: "New Zealand",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "New Zealand, NZ",
    timezones: [
      {
        zoneName: "Pacific/Auckland",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "NZDT",
        tzName: "New Zealand Daylight Time",
      },
      {
        zoneName: "Pacific/Chatham",
        gmtOffset: 49500,
        gmtOffsetName: "UTC+13:45",
        abbreviation: "CHAST",
        tzName: "Chatham Standard Time",
      },
    ],
    translations: {
      kr: "뉴질랜드",
      "pt-BR": "Nova Zelândia",
      pt: "Nova Zelândia",
      nl: "Nieuw-Zeeland",
      hr: "Novi Zeland",
      fa: "نیوزیلند",
      de: "Neuseeland",
      es: "Nueva Zelanda",
      fr: "Nouvelle-Zélande",
      ja: "ニュージーランド",
      it: "Nuova Zelanda",
      cn: "新西兰",
      tr: "Yeni Zelanda",
    },
    latitude: "-41.00000000",
    longitude: "174.00000000",
    emoji: "🇳🇿",
    emojiU: "U+1F1F3 U+1F1FF",
    states: [
      {
        id: 4072,
        name: "Auckland Region",
        state_code: "AUK",
        latitude: "-36.66753280",
        longitude: "174.77333250",
        type: null,
      },
      {
        id: 4074,
        name: "Bay of Plenty Region",
        state_code: "BOP",
        latitude: "-37.42339170",
        longitude: "176.74163740",
        type: null,
      },
      {
        id: 4066,
        name: "Canterbury Region",
        state_code: "CAN",
        latitude: "-43.75422750",
        longitude: "171.16372450",
        type: null,
      },
      {
        id: 4067,
        name: "Chatham Islands",
        state_code: "CIT",
        latitude: "-44.00575230",
        longitude: "-176.54006740",
        type: null,
      },
      {
        id: 4068,
        name: "Gisborne District",
        state_code: "GIS",
        latitude: "-38.13581740",
        longitude: "178.32393090",
        type: null,
      },
      {
        id: 4075,
        name: "Hawke's Bay Region",
        state_code: "HKB",
        latitude: "-39.60165970",
        longitude: "176.58044730",
        type: null,
      },
      {
        id: 4060,
        name: "Manawatu-Wanganui Region",
        state_code: "MWT",
        latitude: "-39.72733560",
        longitude: "175.43755740",
        type: null,
      },
      {
        id: 4063,
        name: "Marlborough Region",
        state_code: "MBH",
        latitude: "-41.59168830",
        longitude: "173.76240530",
        type: null,
      },
      {
        id: 4070,
        name: "Nelson Region",
        state_code: "NSN",
        latitude: "-41.29853970",
        longitude: "173.24414910",
        type: null,
      },
      {
        id: 4059,
        name: "Northland Region",
        state_code: "NTL",
        latitude: "-35.41361720",
        longitude: "173.93208060",
        type: null,
      },
      {
        id: 4062,
        name: "Otago Region",
        state_code: "OTA",
        latitude: "-45.47906710",
        longitude: "170.15475670",
        type: null,
      },
      {
        id: 4071,
        name: "Southland Region",
        state_code: "STL",
        latitude: "-45.84891590",
        longitude: "167.67553870",
        type: null,
      },
      {
        id: 4069,
        name: "Taranaki Region",
        state_code: "TKI",
        latitude: "-39.35381490",
        longitude: "174.43827210",
        type: null,
      },
      {
        id: 4073,
        name: "Tasman District",
        state_code: "TAS",
        latitude: "-41.45711840",
        longitude: "172.82097400",
        type: null,
      },
      {
        id: 4061,
        name: "Waikato Region",
        state_code: "WKO",
        latitude: "-37.61908620",
        longitude: "175.02334600",
        type: null,
      },
      {
        id: 4065,
        name: "Wellington Region",
        state_code: "WGN",
        latitude: "-41.02993230",
        longitude: "175.43755740",
        type: null,
      },
      {
        id: 4064,
        name: "West Coast Region",
        state_code: "WTC",
        latitude: "62.41136340",
        longitude: "-149.07297140",
        type: null,
      },
    ],
  },
  {
    name: "Nicaragua",
    iso3: "NIC",
    iso2: "NI",
    numeric_code: "558",
    phone_code: "505",
    capital: "Managua",
    currency: "NIO",
    currency_name: "Nicaraguan córdoba",
    currency_symbol: "C$",
    tld: ".ni",
    native: "Nicaragua",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Nicaraguan",
    timezones: [
      {
        zoneName: "America/Managua",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
    ],
    translations: {
      kr: "니카라과",
      "pt-BR": "Nicarágua",
      pt: "Nicarágua",
      nl: "Nicaragua",
      hr: "Nikaragva",
      fa: "نیکاراگوئه",
      de: "Nicaragua",
      es: "Nicaragua",
      fr: "Nicaragua",
      ja: "ニカラグア",
      it: "Nicaragua",
      cn: "尼加拉瓜",
      tr: "Nikaragua",
    },
    latitude: "13.00000000",
    longitude: "-85.00000000",
    emoji: "🇳🇮",
    emojiU: "U+1F1F3 U+1F1EE",
    states: [
      {
        id: 946,
        name: "Boaco",
        state_code: "BO",
        latitude: "12.46928400",
        longitude: "-85.66146820",
        type: "department",
      },
      {
        id: 950,
        name: "Carazo",
        state_code: "CA",
        latitude: "11.72747290",
        longitude: "-86.21584970",
        type: "department",
      },
      {
        id: 954,
        name: "Chinandega",
        state_code: "CI",
        latitude: "12.88200620",
        longitude: "-87.14228950",
        type: "department",
      },
      {
        id: 940,
        name: "Chontales",
        state_code: "CO",
        latitude: "11.93947170",
        longitude: "-85.18940450",
        type: "department",
      },
      {
        id: 945,
        name: "Estelí",
        state_code: "ES",
        latitude: "13.08511390",
        longitude: "-86.36301970",
        type: "department",
      },
      {
        id: 943,
        name: "Granada",
        state_code: "GR",
        latitude: "11.93440730",
        longitude: "-85.95600050",
        type: "department",
      },
      {
        id: 955,
        name: "Jinotega",
        state_code: "JI",
        latitude: "13.08839070",
        longitude: "-85.99939970",
        type: "department",
      },
      {
        id: 944,
        name: "León",
        state_code: "LE",
        latitude: "12.50920370",
        longitude: "-86.66110830",
        type: "department",
      },
      {
        id: 948,
        name: "Madriz",
        state_code: "MD",
        latitude: "13.47260050",
        longitude: "-86.45920910",
        type: "department",
      },
      {
        id: 941,
        name: "Managua",
        state_code: "MN",
        latitude: "12.13916990",
        longitude: "-86.33767610",
        type: "department",
      },
      {
        id: 953,
        name: "Masaya",
        state_code: "MS",
        latitude: "11.97593280",
        longitude: "-86.07334980",
        type: "department",
      },
      {
        id: 947,
        name: "Matagalpa",
        state_code: "MT",
        latitude: "12.94984360",
        longitude: "-85.43755740",
        type: "department",
      },
      {
        id: 951,
        name: "North Caribbean Coast",
        state_code: "AN",
        latitude: "13.83944560",
        longitude: "-83.93208060",
        type: "autonomous region",
      },
      {
        id: 4964,
        name: "Nueva Segovia",
        state_code: "NS",
        latitude: "13.76570610",
        longitude: "-86.53700390",
        type: "department",
      },
      {
        id: 949,
        name: "Río San Juan",
        state_code: "SJ",
        latitude: "11.47816100",
        longitude: "-84.77333250",
        type: "department",
      },
      {
        id: 942,
        name: "Rivas",
        state_code: "RI",
        latitude: "11.40234900",
        longitude: "-85.68457800",
        type: "department",
      },
      {
        id: 952,
        name: "South Caribbean Coast",
        state_code: "AS",
        latitude: "12.19185020",
        longitude: "-84.10128610",
        type: "autonomous region",
      },
    ],
  },
  {
    name: "Niger",
    iso3: "NER",
    iso2: "NE",
    numeric_code: "562",
    phone_code: "227",
    capital: "Niamey",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".ne",
    native: "Niger",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Nigerien",
    timezones: [
      {
        zoneName: "Africa/Niamey",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "니제르",
      "pt-BR": "Níger",
      pt: "Níger",
      nl: "Niger",
      hr: "Niger",
      fa: "نیجر",
      de: "Niger",
      es: "Níger",
      fr: "Niger",
      ja: "ニジェール",
      it: "Niger",
      cn: "尼日尔",
      tr: "Nijer",
    },
    latitude: "16.00000000",
    longitude: "8.00000000",
    emoji: "🇳🇪",
    emojiU: "U+1F1F3 U+1F1EA",
    states: [
      {
        id: 71,
        name: "Agadez Region",
        state_code: "1",
        latitude: "20.66707520",
        longitude: "12.07182810",
        type: null,
      },
      {
        id: 72,
        name: "Diffa Region",
        state_code: "2",
        latitude: "13.67686470",
        longitude: "12.71351210",
        type: null,
      },
      {
        id: 68,
        name: "Dosso Region",
        state_code: "3",
        latitude: "13.15139470",
        longitude: "3.41955270",
        type: null,
      },
      {
        id: 70,
        name: "Maradi Region",
        state_code: "4",
        latitude: "13.80180740",
        longitude: "7.43813550",
        type: null,
      },
      {
        id: 73,
        name: "Tahoua Region",
        state_code: "5",
        latitude: "16.09025430",
        longitude: "5.39395510",
        type: null,
      },
      {
        id: 67,
        name: "Tillabéri Region",
        state_code: "6",
        latitude: "14.64895250",
        longitude: "2.14502450",
        type: null,
      },
      {
        id: 69,
        name: "Zinder Region",
        state_code: "7",
        latitude: "15.17188810",
        longitude: "10.26001250",
        type: null,
      },
    ],
  },
  {
    name: "Nigeria",
    iso3: "NGA",
    iso2: "NG",
    numeric_code: "566",
    phone_code: "234",
    capital: "Abuja",
    currency: "NGN",
    currency_name: "Nigerian naira",
    currency_symbol: "₦",
    tld: ".ng",
    native: "Nigeria",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Nigerian",
    timezones: [
      {
        zoneName: "Africa/Lagos",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WAT",
        tzName: "West Africa Time",
      },
    ],
    translations: {
      kr: "나이지리아",
      "pt-BR": "Nigéria",
      pt: "Nigéria",
      nl: "Nigeria",
      hr: "Nigerija",
      fa: "نیجریه",
      de: "Nigeria",
      es: "Nigeria",
      fr: "Nigéria",
      ja: "ナイジェリア",
      it: "Nigeria",
      cn: "尼日利亚",
      tr: "Nijerya",
    },
    latitude: "10.00000000",
    longitude: "8.00000000",
    emoji: "🇳🇬",
    emojiU: "U+1F1F3 U+1F1EC",
    states: [
      {
        id: 303,
        name: "Abia",
        state_code: "AB",
        latitude: "5.45273540",
        longitude: "7.52484140",
        type: "state",
      },
      {
        id: 293,
        name: "Abuja Federal Capital Territory",
        state_code: "FC",
        latitude: "8.89406910",
        longitude: "7.18604020",
        type: "capital territory",
      },
      {
        id: 320,
        name: "Adamawa",
        state_code: "AD",
        latitude: "9.32647510",
        longitude: "12.39838530",
        type: "state",
      },
      {
        id: 304,
        name: "Akwa Ibom",
        state_code: "AK",
        latitude: "4.90573710",
        longitude: "7.85366750",
        type: "state",
      },
      {
        id: 315,
        name: "Anambra",
        state_code: "AN",
        latitude: "6.22089970",
        longitude: "6.93695590",
        type: "state",
      },
      {
        id: 312,
        name: "Bauchi",
        state_code: "BA",
        latitude: "10.77606240",
        longitude: "9.99919430",
        type: "state",
      },
      {
        id: 305,
        name: "Bayelsa",
        state_code: "BY",
        latitude: "4.77190710",
        longitude: "6.06985260",
        type: "state",
      },
      {
        id: 291,
        name: "Benue",
        state_code: "BE",
        latitude: "7.33690240",
        longitude: "8.74036870",
        type: "state",
      },
      {
        id: 307,
        name: "Borno",
        state_code: "BO",
        latitude: "11.88463560",
        longitude: "13.15196650",
        type: "state",
      },
      {
        id: 314,
        name: "Cross River",
        state_code: "CR",
        latitude: "5.87017240",
        longitude: "8.59880140",
        type: "state",
      },
      {
        id: 316,
        name: "Delta",
        state_code: "DE",
        latitude: "33.74537840",
        longitude: "-90.73545080",
        type: "state",
      },
      {
        id: 311,
        name: "Ebonyi",
        state_code: "EB",
        latitude: "6.26492320",
        longitude: "8.01373020",
        type: "state",
      },
      {
        id: 318,
        name: "Edo",
        state_code: "ED",
        latitude: "6.63418310",
        longitude: "5.93040560",
        type: "state",
      },
      {
        id: 309,
        name: "Ekiti",
        state_code: "EK",
        latitude: "7.71898620",
        longitude: "5.31095050",
        type: "state",
      },
      {
        id: 289,
        name: "Enugu",
        state_code: "EN",
        latitude: "6.53635300",
        longitude: "7.43561940",
        type: "state",
      },
      {
        id: 310,
        name: "Gombe",
        state_code: "GO",
        latitude: "10.36377950",
        longitude: "11.19275870",
        type: "state",
      },
      {
        id: 308,
        name: "Imo",
        state_code: "IM",
        latitude: "5.57201220",
        longitude: "7.05882190",
        type: "state",
      },
      {
        id: 288,
        name: "Jigawa",
        state_code: "JI",
        latitude: "12.22801200",
        longitude: "9.56158670",
        type: "state",
      },
      {
        id: 294,
        name: "Kaduna",
        state_code: "KD",
        latitude: "10.37640060",
        longitude: "7.70945370",
        type: "state",
      },
      {
        id: 300,
        name: "Kano",
        state_code: "KN",
        latitude: "11.74706980",
        longitude: "8.52471070",
        type: "state",
      },
      {
        id: 313,
        name: "Katsina",
        state_code: "KT",
        latitude: "12.37967070",
        longitude: "7.63057480",
        type: "state",
      },
      {
        id: 290,
        name: "Kebbi",
        state_code: "KE",
        latitude: "11.49420030",
        longitude: "4.23333550",
        type: "state",
      },
      {
        id: 298,
        name: "Kogi",
        state_code: "KO",
        latitude: "7.73373250",
        longitude: "6.69058360",
        type: "state",
      },
      {
        id: 295,
        name: "Kwara",
        state_code: "KW",
        latitude: "8.96689610",
        longitude: "4.38740510",
        type: "state",
      },
      {
        id: 306,
        name: "Lagos",
        state_code: "LA",
        latitude: "6.52437930",
        longitude: "3.37920570",
        type: "state",
      },
      {
        id: 301,
        name: "Nasarawa",
        state_code: "NA",
        latitude: "8.49979080",
        longitude: "8.19969370",
        type: "state",
      },
      {
        id: 317,
        name: "Niger",
        state_code: "NI",
        latitude: "9.93092240",
        longitude: "5.59832100",
        type: "state",
      },
      {
        id: 323,
        name: "Ogun",
        state_code: "OG",
        latitude: "6.99797470",
        longitude: "3.47373780",
        type: "state",
      },
      {
        id: 321,
        name: "Ondo",
        state_code: "ON",
        latitude: "6.91486820",
        longitude: "5.14781440",
        type: "state",
      },
      {
        id: 322,
        name: "Osun",
        state_code: "OS",
        latitude: "7.56289640",
        longitude: "4.51995930",
        type: "state",
      },
      {
        id: 296,
        name: "Oyo",
        state_code: "OY",
        latitude: "8.15738090",
        longitude: "3.61465340",
        type: "state",
      },
      {
        id: 302,
        name: "Plateau",
        state_code: "PL",
        latitude: "9.21820930",
        longitude: "9.51794880",
        type: "state",
      },
      {
        id: 4926,
        name: "Rivers",
        state_code: "RI",
        latitude: "5.02134200",
        longitude: "6.43760220",
        type: "state",
      },
      {
        id: 292,
        name: "Sokoto",
        state_code: "SO",
        latitude: "13.05331430",
        longitude: "5.32227220",
        type: "state",
      },
      {
        id: 319,
        name: "Taraba",
        state_code: "TA",
        latitude: "7.99936160",
        longitude: "10.77398630",
        type: "state",
      },
      {
        id: 297,
        name: "Yobe",
        state_code: "YO",
        latitude: "12.29387600",
        longitude: "11.43904110",
        type: "state",
      },
      {
        id: 299,
        name: "Zamfara",
        state_code: "ZA",
        latitude: "12.12218050",
        longitude: "6.22358190",
        type: "state",
      },
    ],
  },
  {
    name: "Niue",
    iso3: "NIU",
    iso2: "NU",
    numeric_code: "570",
    phone_code: "683",
    capital: "Alofi",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".nu",
    native: "Niuē",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Niuean",
    timezones: [
      {
        zoneName: "Pacific/Niue",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "NUT",
        tzName: "Niue Time",
      },
    ],
    translations: {
      kr: "니우에",
      "pt-BR": "Niue",
      pt: "Niue",
      nl: "Niue",
      hr: "Niue",
      fa: "نیووی",
      de: "Niue",
      es: "Niue",
      fr: "Niue",
      ja: "ニウエ",
      it: "Niue",
      cn: "纽埃",
      tr: "Niue",
    },
    latitude: "-19.03333333",
    longitude: "-169.86666666",
    emoji: "🇳🇺",
    emojiU: "U+1F1F3 U+1F1FA",
    states: [],
  },
  {
    name: "Norfolk Island",
    iso3: "NFK",
    iso2: "NF",
    numeric_code: "574",
    phone_code: "672",
    capital: "Kingston",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".nf",
    native: "Norfolk Island",
    region: "Oceania",
    region_id: "5",
    subregion: "Australia and New Zealand",
    subregion_id: "19",
    nationality: "Norfolk Island",
    timezones: [
      {
        zoneName: "Pacific/Norfolk",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "NFT",
        tzName: "Norfolk Time",
      },
    ],
    translations: {
      kr: "노퍽 섬",
      "pt-BR": "Ilha Norfolk",
      pt: "Ilha Norfolk",
      nl: "Norfolkeiland",
      hr: "Otok Norfolk",
      fa: "جزیره نورفک",
      de: "Norfolkinsel",
      es: "Isla de Norfolk",
      fr: "Île de Norfolk",
      ja: "ノーフォーク島",
      it: "Isola Norfolk",
      cn: "诺福克岛",
      tr: "Norfolk Adasi",
    },
    latitude: "-29.03333333",
    longitude: "167.95000000",
    emoji: "🇳🇫",
    emojiU: "U+1F1F3 U+1F1EB",
    states: [],
  },
  {
    name: "North Korea",
    iso3: "PRK",
    iso2: "KP",
    numeric_code: "408",
    phone_code: "850",
    capital: "Pyongyang",
    currency: "KPW",
    currency_name: "North Korean Won",
    currency_symbol: "₩",
    tld: ".kp",
    native: "북한",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "North Korean",
    timezones: [
      {
        zoneName: "Asia/Pyongyang",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time",
      },
    ],
    translations: {
      kr: "조선민주주의인민공화국",
      "pt-BR": "Coreia do Norte",
      pt: "Coreia do Norte",
      nl: "Noord-Korea",
      hr: "Sjeverna Koreja",
      fa: "کره جنوبی",
      de: "Nordkorea",
      es: "Corea del Norte",
      fr: "Corée du Nord",
      ja: "朝鮮民主主義人民共和国",
      it: "Corea del Nord",
      cn: "朝鲜",
      tr: "Kuzey Kore",
    },
    latitude: "40.00000000",
    longitude: "127.00000000",
    emoji: "🇰🇵",
    emojiU: "U+1F1F0 U+1F1F5",
    states: [
      {
        id: 3998,
        name: "Chagang Province",
        state_code: "04",
        latitude: "40.72028090",
        longitude: "126.56211370",
        type: null,
      },
      {
        id: 3999,
        name: "Kangwon Province",
        state_code: "07",
        latitude: "38.84323930",
        longitude: "127.55970670",
        type: null,
      },
      {
        id: 3995,
        name: "North Hamgyong Province",
        state_code: "09",
        latitude: "41.81487580",
        longitude: "129.45819550",
        type: null,
      },
      {
        id: 4004,
        name: "North Hwanghae Province",
        state_code: "06",
        latitude: "38.37860850",
        longitude: "126.43643630",
        type: null,
      },
      {
        id: 4002,
        name: "North Pyongan Province",
        state_code: "03",
        latitude: "39.92556180",
        longitude: "125.39280250",
        type: null,
      },
      {
        id: 4005,
        name: "Pyongyang",
        state_code: "01",
        latitude: "39.03921930",
        longitude: "125.76252410",
        type: null,
      },
      {
        id: 4001,
        name: "Rason",
        state_code: "13",
        latitude: "42.25690630",
        longitude: "130.29771860",
        type: null,
      },
      {
        id: 3996,
        name: "Ryanggang Province",
        state_code: "10",
        latitude: "41.23189210",
        longitude: "128.50763590",
        type: null,
      },
      {
        id: 4000,
        name: "South Hamgyong Province",
        state_code: "08",
        latitude: "40.37253390",
        longitude: "128.29888400",
        type: null,
      },
      {
        id: 4003,
        name: "South Hwanghae Province",
        state_code: "05",
        latitude: "38.20072150",
        longitude: "125.47819260",
        type: null,
      },
      {
        id: 3997,
        name: "South Pyongan Province",
        state_code: "02",
        latitude: "39.35391780",
        longitude: "126.16827100",
        type: null,
      },
    ],
  },
  {
    name: "North Macedonia",
    iso3: "MKD",
    iso2: "MK",
    numeric_code: "807",
    phone_code: "389",
    capital: "Skopje",
    currency: "MKD",
    currency_name: "Denar",
    currency_symbol: "ден",
    tld: ".mk",
    native: "Северна Македонија",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Macedonian",
    timezones: [
      {
        zoneName: "Europe/Skopje",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "북마케도니아",
      "pt-BR": "Macedônia do Norte",
      pt: "Macedónia do Norte",
      nl: "Noord-Macedonië",
      hr: "Sjeverna Makedonija",
      fa: "ﻢﻗﺩﻮﻨﯿﻫ ﺶﻣﺎﻠﯾ",
      de: "Nordmazedonien",
      es: "Macedonia del Norte",
      fr: "Macédoine du Nord",
      ja: "北マケドニア",
      it: "Macedonia del Nord",
      cn: "北馬其頓",
      tr: "Kuzey Makedonya",
    },
    latitude: "41.83333333",
    longitude: "22.00000000",
    emoji: "🇲🇰",
    emojiU: "U+1F1F2 U+1F1F0",
    states: [
      {
        id: 703,
        name: "Aerodrom Municipality",
        state_code: "01",
        latitude: "41.94643630",
        longitude: "21.49317130",
        type: null,
      },
      {
        id: 656,
        name: "Aračinovo Municipality",
        state_code: "02",
        latitude: "42.02473810",
        longitude: "21.57664070",
        type: null,
      },
      {
        id: 716,
        name: "Berovo Municipality",
        state_code: "03",
        latitude: "41.66619290",
        longitude: "22.76288300",
        type: null,
      },
      {
        id: 679,
        name: "Bitola Municipality",
        state_code: "04",
        latitude: "41.03633020",
        longitude: "21.33219740",
        type: null,
      },
      {
        id: 649,
        name: "Bogdanci Municipality",
        state_code: "05",
        latitude: "41.18696160",
        longitude: "22.59602680",
        type: null,
      },
      {
        id: 721,
        name: "Bogovinje Municipality",
        state_code: "06",
        latitude: "41.92363710",
        longitude: "20.91638870",
        type: null,
      },
      {
        id: 652,
        name: "Bosilovo Municipality",
        state_code: "07",
        latitude: "41.49048640",
        longitude: "22.78671740",
        type: null,
      },
      {
        id: 660,
        name: "Brvenica Municipality",
        state_code: "08",
        latitude: "41.96814820",
        longitude: "20.98195860",
        type: null,
      },
      {
        id: 694,
        name: "Butel Municipality",
        state_code: "09",
        latitude: "42.08950680",
        longitude: "21.46336100",
        type: null,
      },
      {
        id: 704,
        name: "Čair Municipality",
        state_code: "79",
        latitude: "41.99303550",
        longitude: "21.43653180",
        type: null,
      },
      {
        id: 676,
        name: "Čaška Municipality",
        state_code: "80",
        latitude: "41.64743800",
        longitude: "21.69141150",
        type: null,
      },
      {
        id: 702,
        name: "Centar Municipality",
        state_code: "77",
        latitude: "41.96989340",
        longitude: "21.42162670",
        type: null,
      },
      {
        id: 720,
        name: "Centar Župa Municipality",
        state_code: "78",
        latitude: "41.46522590",
        longitude: "20.59305480",
        type: null,
      },
      {
        id: 644,
        name: "Češinovo-Obleševo Municipality",
        state_code: "81",
        latitude: "41.86393160",
        longitude: "22.26224600",
        type: null,
      },
      {
        id: 715,
        name: "Čučer-Sandevo Municipality",
        state_code: "82",
        latitude: "42.14839460",
        longitude: "21.40374070",
        type: null,
      },
      {
        id: 645,
        name: "Debarca Municipality",
        state_code: "22",
        latitude: "41.35840770",
        longitude: "20.85529190",
        type: null,
      },
      {
        id: 695,
        name: "Delčevo Municipality",
        state_code: "23",
        latitude: "41.96843870",
        longitude: "22.76288300",
        type: null,
      },
      {
        id: 687,
        name: "Demir Hisar Municipality",
        state_code: "25",
        latitude: "41.22708300",
        longitude: "21.14142260",
        type: null,
      },
      {
        id: 655,
        name: "Demir Kapija Municipality",
        state_code: "24",
        latitude: "41.37955380",
        longitude: "22.21455710",
        type: null,
      },
      {
        id: 697,
        name: "Dojran Municipality",
        state_code: "26",
        latitude: "41.24366720",
        longitude: "22.69137640",
        type: null,
      },
      {
        id: 675,
        name: "Dolneni Municipality",
        state_code: "27",
        latitude: "41.46409350",
        longitude: "21.40374070",
        type: null,
      },
      {
        id: 657,
        name: "Drugovo Municipality",
        state_code: "28",
        latitude: "41.44081530",
        longitude: "20.92682010",
        type: null,
      },
      {
        id: 707,
        name: "Gazi Baba Municipality",
        state_code: "17",
        latitude: "42.01629610",
        longitude: "21.49913340",
        type: null,
      },
      {
        id: 648,
        name: "Gevgelija Municipality",
        state_code: "18",
        latitude: "41.21186060",
        longitude: "22.38146240",
        type: null,
      },
      {
        id: 722,
        name: "Gjorče Petrov Municipality",
        state_code: "29",
        latitude: "42.06063740",
        longitude: "21.32027360",
        type: null,
      },
      {
        id: 693,
        name: "Gostivar Municipality",
        state_code: "19",
        latitude: "41.80255410",
        longitude: "20.90893780",
        type: null,
      },
      {
        id: 708,
        name: "Gradsko Municipality",
        state_code: "20",
        latitude: "41.59916080",
        longitude: "21.88070640",
        type: null,
      },
      {
        id: 684,
        name: "Greater Skopje",
        state_code: "85",
        latitude: "41.99812940",
        longitude: "21.42543550",
        type: null,
      },
      {
        id: 690,
        name: "Ilinden Municipality",
        state_code: "34",
        latitude: "41.99574430",
        longitude: "21.56769750",
        type: null,
      },
      {
        id: 678,
        name: "Jegunovce Municipality",
        state_code: "35",
        latitude: "42.07407200",
        longitude: "21.12204780",
        type: null,
      },
      {
        id: 674,
        name: "Karbinci",
        state_code: "37",
        latitude: "41.81801590",
        longitude: "22.23247580",
        type: null,
      },
      {
        id: 681,
        name: "Karpoš Municipality",
        state_code: "38",
        latitude: "41.97096610",
        longitude: "21.39181680",
        type: null,
      },
      {
        id: 713,
        name: "Kavadarci Municipality",
        state_code: "36",
        latitude: "41.28900680",
        longitude: "21.99994350",
        type: null,
      },
      {
        id: 688,
        name: "Kičevo Municipality",
        state_code: "40",
        latitude: "41.51291120",
        longitude: "20.95250650",
        type: null,
      },
      {
        id: 686,
        name: "Kisela Voda Municipality",
        state_code: "39",
        latitude: "41.92748000",
        longitude: "21.49317130",
        type: null,
      },
      {
        id: 723,
        name: "Kočani Municipality",
        state_code: "42",
        latitude: "41.98583740",
        longitude: "22.40530460",
        type: null,
      },
      {
        id: 665,
        name: "Konče Municipality",
        state_code: "41",
        latitude: "41.51710110",
        longitude: "22.38146240",
        type: null,
      },
      {
        id: 641,
        name: "Kratovo Municipality",
        state_code: "43",
        latitude: "42.05371410",
        longitude: "22.07148350",
        type: null,
      },
      {
        id: 677,
        name: "Kriva Palanka Municipality",
        state_code: "44",
        latitude: "42.20584540",
        longitude: "22.33079650",
        type: null,
      },
      {
        id: 647,
        name: "Krivogaštani Municipality",
        state_code: "45",
        latitude: "41.30823060",
        longitude: "21.36796890",
        type: null,
      },
      {
        id: 714,
        name: "Kruševo Municipality",
        state_code: "46",
        latitude: "41.37693310",
        longitude: "21.26065540",
        type: null,
      },
      {
        id: 683,
        name: "Kumanovo Municipality",
        state_code: "47",
        latitude: "42.07326130",
        longitude: "21.78531430",
        type: null,
      },
      {
        id: 659,
        name: "Lipkovo Municipality",
        state_code: "48",
        latitude: "42.20066260",
        longitude: "21.61837550",
        type: null,
      },
      {
        id: 705,
        name: "Lozovo Municipality",
        state_code: "49",
        latitude: "41.78181390",
        longitude: "21.90008270",
        type: null,
      },
      {
        id: 701,
        name: "Makedonska Kamenica Municipality",
        state_code: "51",
        latitude: "42.06946040",
        longitude: "22.54834900",
        type: null,
      },
      {
        id: 692,
        name: "Makedonski Brod Municipality",
        state_code: "52",
        latitude: "41.51330880",
        longitude: "21.21743290",
        type: null,
      },
      {
        id: 669,
        name: "Mavrovo and Rostuša Municipality",
        state_code: "50",
        latitude: "41.60924270",
        longitude: "20.60124880",
        type: null,
      },
      {
        id: 653,
        name: "Mogila Municipality",
        state_code: "53",
        latitude: "41.14796450",
        longitude: "21.45143690",
        type: null,
      },
      {
        id: 664,
        name: "Negotino Municipality",
        state_code: "54",
        latitude: "41.49899850",
        longitude: "22.09532970",
        type: null,
      },
      {
        id: 696,
        name: "Novaci Municipality",
        state_code: "55",
        latitude: "41.04426610",
        longitude: "21.45888940",
        type: null,
      },
      {
        id: 718,
        name: "Novo Selo Municipality",
        state_code: "56",
        latitude: "41.43255800",
        longitude: "22.88204890",
        type: null,
      },
      {
        id: 699,
        name: "Ohrid Municipality",
        state_code: "58",
        latitude: "41.06820880",
        longitude: "20.75992660",
        type: null,
      },
      {
        id: 682,
        name: "Oslomej Municipality",
        state_code: "57",
        latitude: "41.57583910",
        longitude: "21.02219600",
        type: null,
      },
      {
        id: 685,
        name: "Pehčevo Municipality",
        state_code: "60",
        latitude: "41.77371320",
        longitude: "22.88204890",
        type: null,
      },
      {
        id: 698,
        name: "Petrovec Municipality",
        state_code: "59",
        latitude: "41.90298970",
        longitude: "21.68992100",
        type: null,
      },
      {
        id: 670,
        name: "Plasnica Municipality",
        state_code: "61",
        latitude: "41.45463490",
        longitude: "21.10565390",
        type: null,
      },
      {
        id: 666,
        name: "Prilep Municipality",
        state_code: "62",
        latitude: "41.26931420",
        longitude: "21.71376940",
        type: null,
      },
      {
        id: 646,
        name: "Probištip Municipality",
        state_code: "63",
        latitude: "41.95891460",
        longitude: "22.16686700",
        type: null,
      },
      {
        id: 709,
        name: "Radoviš Municipality",
        state_code: "64",
        latitude: "41.64955310",
        longitude: "22.47682870",
        type: null,
      },
      {
        id: 717,
        name: "Rankovce Municipality",
        state_code: "65",
        latitude: "42.18081410",
        longitude: "22.09532970",
        type: null,
      },
      {
        id: 712,
        name: "Resen Municipality",
        state_code: "66",
        latitude: "40.93680930",
        longitude: "21.04604070",
        type: null,
      },
      {
        id: 691,
        name: "Rosoman Municipality",
        state_code: "67",
        latitude: "41.48480060",
        longitude: "21.88070640",
        type: null,
      },
      {
        id: 667,
        name: "Saraj Municipality",
        state_code: "68",
        latitude: "41.98694960",
        longitude: "21.26065540",
        type: null,
      },
      {
        id: 719,
        name: "Sopište Municipality",
        state_code: "70",
        latitude: "41.86384920",
        longitude: "21.30834990",
        type: null,
      },
      {
        id: 643,
        name: "Staro Nagoričane Municipality",
        state_code: "71",
        latitude: "42.21916920",
        longitude: "21.90455410",
        type: null,
      },
      {
        id: 661,
        name: "Štip Municipality",
        state_code: "83",
        latitude: "41.70792970",
        longitude: "22.19071220",
        type: null,
      },
      {
        id: 700,
        name: "Struga Municipality",
        state_code: "72",
        latitude: "41.31737440",
        longitude: "20.66456830",
        type: null,
      },
      {
        id: 710,
        name: "Strumica Municipality",
        state_code: "73",
        latitude: "41.43780040",
        longitude: "22.64274280",
        type: null,
      },
      {
        id: 711,
        name: "Studeničani Municipality",
        state_code: "74",
        latitude: "41.92256390",
        longitude: "21.53639650",
        type: null,
      },
      {
        id: 680,
        name: "Šuto Orizari Municipality",
        state_code: "84",
        latitude: "42.02904160",
        longitude: "21.40970270",
        type: null,
      },
      {
        id: 640,
        name: "Sveti Nikole Municipality",
        state_code: "69",
        latitude: "41.89803120",
        longitude: "21.99994350",
        type: null,
      },
      {
        id: 654,
        name: "Tearce Municipality",
        state_code: "75",
        latitude: "42.07775110",
        longitude: "21.05349230",
        type: null,
      },
      {
        id: 663,
        name: "Tetovo Municipality",
        state_code: "76",
        latitude: "42.02748600",
        longitude: "20.95066360",
        type: null,
      },
      {
        id: 671,
        name: "Valandovo Municipality",
        state_code: "10",
        latitude: "41.32119090",
        longitude: "22.50066930",
        type: null,
      },
      {
        id: 658,
        name: "Vasilevo Municipality",
        state_code: "11",
        latitude: "41.47416990",
        longitude: "22.64221280",
        type: null,
      },
      {
        id: 651,
        name: "Veles Municipality",
        state_code: "13",
        latitude: "41.72744260",
        longitude: "21.71376940",
        type: null,
      },
      {
        id: 662,
        name: "Vevčani Municipality",
        state_code: "12",
        latitude: "41.24075430",
        longitude: "20.59156490",
        type: null,
      },
      {
        id: 672,
        name: "Vinica Municipality",
        state_code: "14",
        latitude: "41.85710200",
        longitude: "22.57218810",
        type: null,
      },
      {
        id: 650,
        name: "Vraneštica Municipality",
        state_code: "15",
        latitude: "41.48290870",
        longitude: "21.05796320",
        type: null,
      },
      {
        id: 689,
        name: "Vrapčište Municipality",
        state_code: "16",
        latitude: "41.87911600",
        longitude: "20.83145000",
        type: null,
      },
      {
        id: 642,
        name: "Zajas Municipality",
        state_code: "31",
        latitude: "41.60303280",
        longitude: "20.87913430",
        type: null,
      },
      {
        id: 706,
        name: "Zelenikovo Municipality",
        state_code: "32",
        latitude: "41.87338120",
        longitude: "21.60272500",
        type: null,
      },
      {
        id: 668,
        name: "Želino Municipality",
        state_code: "30",
        latitude: "41.90065310",
        longitude: "21.11757670",
        type: null,
      },
      {
        id: 673,
        name: "Zrnovci Municipality",
        state_code: "33",
        latitude: "41.82282210",
        longitude: "22.41722560",
        type: null,
      },
    ],
  },
  {
    name: "Northern Mariana Islands",
    iso3: "MNP",
    iso2: "MP",
    numeric_code: "580",
    phone_code: "+1-670",
    capital: "Saipan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".mp",
    native: "Northern Mariana Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Northern Marianan",
    timezones: [
      {
        zoneName: "Pacific/Saipan",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "ChST",
        tzName: "Chamorro Standard Time",
      },
    ],
    translations: {
      kr: "북마리아나 제도",
      "pt-BR": "Ilhas Marianas",
      pt: "Ilhas Marianas",
      nl: "Noordelijke Marianeneilanden",
      hr: "Sjevernomarijanski otoci",
      fa: "جزایر ماریانای شمالی",
      de: "Nördliche Marianen",
      es: "Islas Marianas del Norte",
      fr: "Îles Mariannes du Nord",
      ja: "北マリアナ諸島",
      it: "Isole Marianne Settentrionali",
      cn: "北马里亚纳群岛",
      tr: "Kuzey Mariana Adalari",
    },
    latitude: "15.20000000",
    longitude: "145.75000000",
    emoji: "🇲🇵",
    emojiU: "U+1F1F2 U+1F1F5",
    states: [],
  },
  {
    name: "Norway",
    iso3: "NOR",
    iso2: "NO",
    numeric_code: "578",
    phone_code: "47",
    capital: "Oslo",
    currency: "NOK",
    currency_name: "Norwegian krone",
    currency_symbol: "kr",
    tld: ".no",
    native: "Norge",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Norwegian",
    timezones: [
      {
        zoneName: "Europe/Oslo",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "노르웨이",
      "pt-BR": "Noruega",
      pt: "Noruega",
      nl: "Noorwegen",
      hr: "Norveška",
      fa: "نروژ",
      de: "Norwegen",
      es: "Noruega",
      fr: "Norvège",
      ja: "ノルウェー",
      it: "Norvegia",
      cn: "挪威",
      tr: "Norveç",
    },
    latitude: "62.00000000",
    longitude: "10.00000000",
    emoji: "🇳🇴",
    emojiU: "U+1F1F3 U+1F1F4",
    states: [
      {
        id: 1014,
        name: "Agder",
        state_code: "42",
        latitude: "58.74069340",
        longitude: "6.75315210",
        type: "county",
      },
      {
        id: 1009,
        name: "Innlandet",
        state_code: "34",
        latitude: "61.19357870",
        longitude: "5.50832660",
        type: "county",
      },
      {
        id: 1026,
        name: "Jan Mayen",
        state_code: "22",
        latitude: "71.03181800",
        longitude: "-8.29203460",
        type: "arctic region",
      },
      {
        id: 1020,
        name: "Møre og Romsdal",
        state_code: "15",
        latitude: "62.84068330",
        longitude: "7.00714300",
        type: "county",
      },
      {
        id: 1025,
        name: "Nordland",
        state_code: "18",
        latitude: "67.69305800",
        longitude: "12.70739360",
        type: "county",
      },
      {
        id: 1007,
        name: "Oslo",
        state_code: "03",
        latitude: "59.91386880",
        longitude: "10.75224540",
        type: "county",
      },
      {
        id: 1021,
        name: "Rogaland",
        state_code: "11",
        latitude: "59.14895440",
        longitude: "6.01434320",
        type: "county",
      },
      {
        id: 1013,
        name: "Svalbard",
        state_code: "21",
        latitude: "77.87497250",
        longitude: "20.97518210",
        type: "arctic region",
      },
      {
        id: 1015,
        name: "Troms og Finnmark",
        state_code: "54",
        latitude: "69.77890670",
        longitude: "18.99401840",
        type: "county",
      },
      {
        id: 1006,
        name: "Trøndelag",
        state_code: "50",
        latitude: "63.54201250",
        longitude: "10.93692670",
        type: "county",
      },
      {
        id: 1024,
        name: "Vestfold og Telemark",
        state_code: "38",
        latitude: "59.41174820",
        longitude: "7.76471750",
        type: "county",
      },
      {
        id: 1018,
        name: "Vestland",
        state_code: "46",
        latitude: "60.90694420",
        longitude: "3.96270810",
        type: "county",
      },
      {
        id: 1011,
        name: "Viken",
        state_code: "30",
        latitude: "59.96530050",
        longitude: "7.45051440",
        type: "county",
      },
    ],
  },
  {
    name: "Oman",
    iso3: "OMN",
    iso2: "OM",
    numeric_code: "512",
    phone_code: "968",
    capital: "Muscat",
    currency: "OMR",
    currency_name: "Omani rial",
    currency_symbol: ".ع.ر",
    tld: ".om",
    native: "عمان",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Omani",
    timezones: [
      {
        zoneName: "Asia/Muscat",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time",
      },
    ],
    translations: {
      kr: "오만",
      "pt-BR": "Omã",
      pt: "Omã",
      nl: "Oman",
      hr: "Oman",
      fa: "عمان",
      de: "Oman",
      es: "Omán",
      fr: "Oman",
      ja: "オマーン",
      it: "oman",
      cn: "阿曼",
      tr: "Umman",
    },
    latitude: "21.00000000",
    longitude: "57.00000000",
    emoji: "🇴🇲",
    emojiU: "U+1F1F4 U+1F1F2",
    states: [
      {
        id: 3058,
        name: "Ad Dakhiliyah",
        state_code: "DA",
        latitude: "22.85887580",
        longitude: "57.53943560",
        type: null,
      },
      {
        id: 3047,
        name: "Ad Dhahirah",
        state_code: "ZA",
        latitude: "23.21616740",
        longitude: "56.49074440",
        type: null,
      },
      {
        id: 3048,
        name: "Al Batinah North",
        state_code: "BS",
        latitude: "24.34198460",
        longitude: "56.72989040",
        type: null,
      },
      {
        id: 3050,
        name: "Al Batinah Region",
        state_code: "BA",
        latitude: "24.34198460",
        longitude: "56.72989040",
        type: null,
      },
      {
        id: 3049,
        name: "Al Batinah South",
        state_code: "BJ",
        latitude: "23.43149030",
        longitude: "57.42397960",
        type: null,
      },
      {
        id: 3059,
        name: "Al Buraimi",
        state_code: "BU",
        latitude: "24.16714130",
        longitude: "56.11422530",
        type: null,
      },
      {
        id: 3056,
        name: "Al Wusta",
        state_code: "WU",
        latitude: "19.95710780",
        longitude: "56.27568460",
        type: null,
      },
      {
        id: 3053,
        name: "Ash Sharqiyah North",
        state_code: "SS",
        latitude: "22.71411960",
        longitude: "58.53080640",
        type: null,
      },
      {
        id: 3051,
        name: "Ash Sharqiyah Region",
        state_code: "SH",
        latitude: "22.71411960",
        longitude: "58.53080640",
        type: null,
      },
      {
        id: 3054,
        name: "Ash Sharqiyah South",
        state_code: "SJ",
        latitude: "22.01582490",
        longitude: "59.32519220",
        type: null,
      },
      {
        id: 3057,
        name: "Dhofar",
        state_code: "ZU",
        latitude: "17.03221210",
        longitude: "54.14252140",
        type: null,
      },
      {
        id: 3052,
        name: "Musandam",
        state_code: "MU",
        latitude: "26.19861440",
        longitude: "56.24609490",
        type: null,
      },
      {
        id: 3055,
        name: "Muscat",
        state_code: "MA",
        latitude: "23.58803070",
        longitude: "58.38287170",
        type: null,
      },
    ],
  },
  {
    name: "Pakistan",
    iso3: "PAK",
    iso2: "PK",
    numeric_code: "586",
    phone_code: "92",
    capital: "Islamabad",
    currency: "PKR",
    currency_name: "Pakistani rupee",
    currency_symbol: "₨",
    tld: ".pk",
    native: "Pakistan",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Pakistani",
    timezones: [
      {
        zoneName: "Asia/Karachi",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "PKT",
        tzName: "Pakistan Standard Time",
      },
    ],
    translations: {
      kr: "파키스탄",
      "pt-BR": "Paquistão",
      pt: "Paquistão",
      nl: "Pakistan",
      hr: "Pakistan",
      fa: "پاکستان",
      de: "Pakistan",
      es: "Pakistán",
      fr: "Pakistan",
      ja: "パキスタン",
      it: "Pakistan",
      cn: "巴基斯坦",
      tr: "Pakistan",
    },
    latitude: "30.00000000",
    longitude: "70.00000000",
    emoji: "🇵🇰",
    emojiU: "U+1F1F5 U+1F1F0",
    states: [
      {
        id: 3172,
        name: "Azad Kashmir",
        state_code: "JK",
        latitude: "33.92590550",
        longitude: "73.78103340",
        type: null,
      },
      {
        id: 3174,
        name: "Balochistan",
        state_code: "BA",
        latitude: "28.49073320",
        longitude: "65.09577920",
        type: null,
      },
      {
        id: 3173,
        name: "Federally Administered Tribal Areas",
        state_code: "TA",
        latitude: "32.66747600",
        longitude: "69.85974060",
        type: null,
      },
      {
        id: 3170,
        name: "Gilgit-Baltistan",
        state_code: "GB",
        latitude: "35.80256670",
        longitude: "74.98318080",
        type: null,
      },
      {
        id: 3169,
        name: "Islamabad Capital Territory",
        state_code: "IS",
        latitude: "33.72049970",
        longitude: "73.04052770",
        type: null,
      },
      {
        id: 3171,
        name: "Khyber Pakhtunkhwa",
        state_code: "KP",
        latitude: "34.95262050",
        longitude: "72.33111300",
        type: null,
      },
      {
        id: 3176,
        name: "Punjab",
        state_code: "PB",
        latitude: "31.14713050",
        longitude: "75.34121790",
        type: null,
      },
      {
        id: 3175,
        name: "Sindh",
        state_code: "SD",
        latitude: "25.89430180",
        longitude: "68.52471490",
        type: null,
      },
    ],
  },
  {
    name: "Palau",
    iso3: "PLW",
    iso2: "PW",
    numeric_code: "585",
    phone_code: "680",
    capital: "Melekeok",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pw",
    native: "Palau",
    region: "Oceania",
    region_id: "5",
    subregion: "Micronesia",
    subregion_id: "21",
    nationality: "Palauan",
    timezones: [
      {
        zoneName: "Pacific/Palau",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "PWT",
        tzName: "Palau Time",
      },
    ],
    translations: {
      kr: "팔라우",
      "pt-BR": "Palau",
      pt: "Palau",
      nl: "Palau",
      hr: "Palau",
      fa: "پالائو",
      de: "Palau",
      es: "Palau",
      fr: "Palaos",
      ja: "パラオ",
      it: "Palau",
      cn: "帕劳",
      tr: "Palau",
    },
    latitude: "7.50000000",
    longitude: "134.50000000",
    emoji: "🇵🇼",
    emojiU: "U+1F1F5 U+1F1FC",
    states: [
      {
        id: 4540,
        name: "Aimeliik",
        state_code: "002",
        latitude: "7.44558590",
        longitude: "134.50308780",
        type: null,
      },
      {
        id: 4528,
        name: "Airai",
        state_code: "004",
        latitude: "7.39661180",
        longitude: "134.56902250",
        type: null,
      },
      {
        id: 4538,
        name: "Angaur",
        state_code: "010",
        latitude: "6.90922300",
        longitude: "134.13879340",
        type: null,
      },
      {
        id: 4529,
        name: "Hatohobei",
        state_code: "050",
        latitude: "3.00706580",
        longitude: "131.12377810",
        type: null,
      },
      {
        id: 4539,
        name: "Kayangel",
        state_code: "100",
        latitude: "8.07000000",
        longitude: "134.70277800",
        type: null,
      },
      {
        id: 4532,
        name: "Koror",
        state_code: "150",
        latitude: "7.33756460",
        longitude: "134.48894690",
        type: null,
      },
      {
        id: 4530,
        name: "Melekeok",
        state_code: "212",
        latitude: "7.51502860",
        longitude: "134.59725180",
        type: null,
      },
      {
        id: 4537,
        name: "Ngaraard",
        state_code: "214",
        latitude: "7.60794000",
        longitude: "134.63486450",
        type: null,
      },
      {
        id: 4533,
        name: "Ngarchelong",
        state_code: "218",
        latitude: "7.71054690",
        longitude: "134.63016460",
        type: null,
      },
      {
        id: 4527,
        name: "Ngardmau",
        state_code: "222",
        latitude: "7.58504860",
        longitude: "134.55960890",
        type: null,
      },
      {
        id: 4531,
        name: "Ngatpang",
        state_code: "224",
        latitude: "7.47109940",
        longitude: "134.52664660",
        type: null,
      },
      {
        id: 4536,
        name: "Ngchesar",
        state_code: "226",
        latitude: "7.45232800",
        longitude: "134.57843420",
        type: null,
      },
      {
        id: 4541,
        name: "Ngeremlengui",
        state_code: "227",
        latitude: "7.51983970",
        longitude: "134.55960890",
        type: null,
      },
      {
        id: 4534,
        name: "Ngiwal",
        state_code: "228",
        latitude: "7.56147640",
        longitude: "134.61606190",
        type: null,
      },
      {
        id: 4526,
        name: "Peleliu",
        state_code: "350",
        latitude: "7.00229060",
        longitude: "134.24316280",
        type: null,
      },
      {
        id: 4535,
        name: "Sonsorol",
        state_code: "370",
        latitude: "5.32681190",
        longitude: "132.22391170",
        type: null,
      },
    ],
  },
  {
    name: "Palestinian Territory Occupied",
    iso3: "PSE",
    iso2: "PS",
    numeric_code: "275",
    phone_code: "970",
    capital: "East Jerusalem",
    currency: "ILS",
    currency_name: "Israeli new shekel",
    currency_symbol: "₪",
    tld: ".ps",
    native: "فلسطين",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Palestinian",
    timezones: [
      {
        zoneName: "Asia/Gaza",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Asia/Hebron",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "팔레스타인 영토",
      "pt-BR": "Palestina",
      pt: "Palestina",
      nl: "Palestijnse gebieden",
      hr: "Palestina",
      fa: "فلسطین",
      de: "Palästina",
      es: "Palestina",
      fr: "Palestine",
      ja: "パレスチナ",
      it: "Palestina",
      cn: "巴勒斯坦",
      tr: "Filistin",
    },
    latitude: "31.90000000",
    longitude: "35.20000000",
    emoji: "🇵🇸",
    emojiU: "U+1F1F5 U+1F1F8",
    states: [
      {
        id: 5118,
        name: "Bethlehem",
        state_code: "BTH",
        latitude: "31.70539960",
        longitude: "35.19368770",
        type: "governorate",
      },
      {
        id: 5119,
        name: "Deir El Balah",
        state_code: "DEB",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5120,
        name: "Gaza",
        state_code: "GZA",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5121,
        name: "Hebron",
        state_code: "HBN",
        latitude: "31.53260010",
        longitude: "35.06394750",
        type: "governorate",
      },
      {
        id: 5122,
        name: "Jenin",
        state_code: "JEN",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5123,
        name: "Jericho and Al Aghwar",
        state_code: "JRH",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5124,
        name: "Jerusalem",
        state_code: "JEM",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5125,
        name: "Khan Yunis",
        state_code: "KYS",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5126,
        name: "Nablus",
        state_code: "NBS",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5127,
        name: "North Gaza",
        state_code: "NGZ",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5128,
        name: "Qalqilya",
        state_code: "QQA",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5129,
        name: "Rafah",
        state_code: "RFH",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5130,
        name: "Ramallah",
        state_code: "RBH",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5131,
        name: "Salfit",
        state_code: "SLT",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5132,
        name: "Tubas",
        state_code: "TBS",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
      {
        id: 5133,
        name: "Tulkarm",
        state_code: "TKM",
        latitude: null,
        longitude: null,
        type: "governorate",
      },
    ],
  },
  {
    name: "Panama",
    iso3: "PAN",
    iso2: "PA",
    numeric_code: "591",
    phone_code: "507",
    capital: "Panama City",
    currency: "PAB",
    currency_name: "Panamanian balboa",
    currency_symbol: "B/.",
    tld: ".pa",
    native: "Panamá",
    region: "Americas",
    region_id: "2",
    subregion: "Central America",
    subregion_id: "9",
    nationality: "Panamanian",
    timezones: [
      {
        zoneName: "America/Panama",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "파나마",
      "pt-BR": "Panamá",
      pt: "Panamá",
      nl: "Panama",
      hr: "Panama",
      fa: "پاناما",
      de: "Panama",
      es: "Panamá",
      fr: "Panama",
      ja: "パナマ",
      it: "Panama",
      cn: "巴拿马",
      tr: "Panama",
    },
    latitude: "9.00000000",
    longitude: "-80.00000000",
    emoji: "🇵🇦",
    emojiU: "U+1F1F5 U+1F1E6",
    states: [
      {
        id: 1393,
        name: "Bocas del Toro Province",
        state_code: "1",
        latitude: "9.41655210",
        longitude: "-82.52077870",
        type: null,
      },
      {
        id: 1397,
        name: "Chiriquí Province",
        state_code: "4",
        latitude: "8.58489800",
        longitude: "-82.38857830",
        type: null,
      },
      {
        id: 1387,
        name: "Coclé Province",
        state_code: "2",
        latitude: "8.62660680",
        longitude: "-80.36586500",
        type: null,
      },
      {
        id: 1386,
        name: "Colón Province",
        state_code: "3",
        latitude: "9.18519890",
        longitude: "-80.05349230",
        type: null,
      },
      {
        id: 1385,
        name: "Darién Province",
        state_code: "5",
        latitude: "7.86817130",
        longitude: "-77.83672820",
        type: null,
      },
      {
        id: 1396,
        name: "Emberá-Wounaan Comarca",
        state_code: "EM",
        latitude: "8.37669830",
        longitude: "-77.65361250",
        type: null,
      },
      {
        id: 1388,
        name: "Guna Yala",
        state_code: "KY",
        latitude: "9.23443950",
        longitude: "-78.19262500",
        type: null,
      },
      {
        id: 1389,
        name: "Herrera Province",
        state_code: "6",
        latitude: "7.77042820",
        longitude: "-80.72144170",
        type: null,
      },
      {
        id: 1390,
        name: "Los Santos Province",
        state_code: "7",
        latitude: "7.59093020",
        longitude: "-80.36586500",
        type: null,
      },
      {
        id: 1391,
        name: "Ngöbe-Buglé Comarca",
        state_code: "NB",
        latitude: "8.65958330",
        longitude: "-81.77870210",
        type: null,
      },
      {
        id: 1394,
        name: "Panamá Oeste Province",
        state_code: "10",
        latitude: "9.11967510",
        longitude: "-79.29021330",
        type: null,
      },
      {
        id: 1395,
        name: "Panamá Province",
        state_code: "8",
        latitude: "9.11967510",
        longitude: "-79.29021330",
        type: null,
      },
      {
        id: 1392,
        name: "Veraguas Province",
        state_code: "9",
        latitude: "8.12310330",
        longitude: "-81.07546570",
        type: null,
      },
    ],
  },
  {
    name: "Papua New Guinea",
    iso3: "PNG",
    iso2: "PG",
    numeric_code: "598",
    phone_code: "675",
    capital: "Port Moresby",
    currency: "PGK",
    currency_name: "Papua New Guinean kina",
    currency_symbol: "K",
    tld: ".pg",
    native: "Papua Niugini",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Papua New Guinean, Papuan",
    timezones: [
      {
        zoneName: "Pacific/Bougainville",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "BST",
        tzName: "Bougainville Standard Time[6",
      },
      {
        zoneName: "Pacific/Port_Moresby",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "PGT",
        tzName: "Papua New Guinea Time",
      },
    ],
    translations: {
      kr: "파푸아뉴기니",
      "pt-BR": "Papua Nova Guiné",
      pt: "Papua Nova Guiné",
      nl: "Papoea-Nieuw-Guinea",
      hr: "Papua Nova Gvineja",
      fa: "پاپوآ گینه نو",
      de: "Papua-Neuguinea",
      es: "Papúa Nueva Guinea",
      fr: "Papouasie-Nouvelle-Guinée",
      ja: "パプアニューギニア",
      it: "Papua Nuova Guinea",
      cn: "巴布亚新几内亚",
      tr: "Papua Yeni Gine",
    },
    latitude: "-6.00000000",
    longitude: "147.00000000",
    emoji: "🇵🇬",
    emojiU: "U+1F1F5 U+1F1EC",
    states: [
      {
        id: 4831,
        name: "Bougainville",
        state_code: "NSB",
        latitude: "-6.37539190",
        longitude: "155.38071010",
        type: null,
      },
      {
        id: 4847,
        name: "Central Province",
        state_code: "CPM",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4846,
        name: "Chimbu Province",
        state_code: "CPK",
        latitude: "-6.30876820",
        longitude: "144.87312190",
        type: null,
      },
      {
        id: 4834,
        name: "East New Britain",
        state_code: "EBR",
        latitude: "-4.61289430",
        longitude: "151.88773210",
        type: null,
      },
      {
        id: 4845,
        name: "Eastern Highlands Province",
        state_code: "EHG",
        latitude: "-6.58616740",
        longitude: "145.66896360",
        type: null,
      },
      {
        id: 4848,
        name: "Enga Province",
        state_code: "EPW",
        latitude: "-5.30058490",
        longitude: "143.56356370",
        type: null,
      },
      {
        id: 4839,
        name: "Gulf",
        state_code: "GPK",
        latitude: "37.05483150",
        longitude: "-94.43704190",
        type: null,
      },
      {
        id: 4833,
        name: "Hela",
        state_code: "HLA",
        latitude: "42.33295160",
        longitude: "-83.04826180",
        type: null,
      },
      {
        id: 4832,
        name: "Jiwaka Province",
        state_code: "JWK",
        latitude: "-5.86911540",
        longitude: "144.69727740",
        type: null,
      },
      {
        id: 4843,
        name: "Madang Province",
        state_code: "MPM",
        latitude: "-4.98497330",
        longitude: "145.13758340",
        type: null,
      },
      {
        id: 4842,
        name: "Manus Province",
        state_code: "MRL",
        latitude: "-2.09411690",
        longitude: "146.87609510",
        type: null,
      },
      {
        id: 4849,
        name: "Milne Bay Province",
        state_code: "MBA",
        latitude: "-9.52214510",
        longitude: "150.67496530",
        type: null,
      },
      {
        id: 4835,
        name: "Morobe Province",
        state_code: "MPL",
        latitude: "-6.80137370",
        longitude: "146.56164700",
        type: null,
      },
      {
        id: 4841,
        name: "New Ireland Province",
        state_code: "NIK",
        latitude: "-4.28532560",
        longitude: "152.92059180",
        type: null,
      },
      {
        id: 4838,
        name: "Oro Province",
        state_code: "NPP",
        latitude: "-8.89880630",
        longitude: "148.18929210",
        type: null,
      },
      {
        id: 4837,
        name: "Port Moresby",
        state_code: "NCD",
        latitude: "-9.44380040",
        longitude: "147.18026710",
        type: null,
      },
      {
        id: 4836,
        name: "Sandaun Province",
        state_code: "SAN",
        latitude: "-3.71261790",
        longitude: "141.68342750",
        type: null,
      },
      {
        id: 4844,
        name: "Southern Highlands Province",
        state_code: "SHM",
        latitude: "-6.41790830",
        longitude: "143.56356370",
        type: null,
      },
      {
        id: 4830,
        name: "West New Britain Province",
        state_code: "WBK",
        latitude: "-5.70474320",
        longitude: "150.02594660",
        type: null,
      },
      {
        id: 4840,
        name: "Western Highlands Province",
        state_code: "WHM",
        latitude: "-5.62681280",
        longitude: "144.25931180",
        type: null,
      },
      {
        id: 4850,
        name: "Western Province",
        state_code: "WPD",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Paraguay",
    iso3: "PRY",
    iso2: "PY",
    numeric_code: "600",
    phone_code: "595",
    capital: "Asuncion",
    currency: "PYG",
    currency_name: "Paraguayan guarani",
    currency_symbol: "₲",
    tld: ".py",
    native: "Paraguay",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Paraguayan",
    timezones: [
      {
        zoneName: "America/Asuncion",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PYST",
        tzName: "Paraguay Summer Time",
      },
    ],
    translations: {
      kr: "파라과이",
      "pt-BR": "Paraguai",
      pt: "Paraguai",
      nl: "Paraguay",
      hr: "Paragvaj",
      fa: "پاراگوئه",
      de: "Paraguay",
      es: "Paraguay",
      fr: "Paraguay",
      ja: "パラグアイ",
      it: "Paraguay",
      cn: "巴拉圭",
      tr: "Paraguay",
    },
    latitude: "-23.00000000",
    longitude: "-58.00000000",
    emoji: "🇵🇾",
    emojiU: "U+1F1F5 U+1F1FE",
    states: [
      {
        id: 2785,
        name: "Alto Paraguay Department",
        state_code: "16",
        latitude: "-20.08525080",
        longitude: "-59.47209040",
        type: null,
      },
      {
        id: 2784,
        name: "Alto Paraná Department",
        state_code: "10",
        latitude: "-25.60755460",
        longitude: "-54.96118360",
        type: null,
      },
      {
        id: 2782,
        name: "Amambay Department",
        state_code: "13",
        latitude: "-22.55902720",
        longitude: "-56.02499820",
        type: null,
      },
      {
        id: 5221,
        name: "Asuncion",
        state_code: "ASU",
        latitude: "-25.29682970",
        longitude: "-57.68066230",
        type: "capital",
      },
      {
        id: 2780,
        name: "Boquerón Department",
        state_code: "19",
        latitude: "-21.74492540",
        longitude: "-60.95400730",
        type: null,
      },
      {
        id: 2773,
        name: "Caaguazú",
        state_code: "5",
        latitude: "-25.46458180",
        longitude: "-56.01385100",
        type: null,
      },
      {
        id: 2775,
        name: "Caazapá",
        state_code: "6",
        latitude: "-26.18277130",
        longitude: "-56.37123270",
        type: null,
      },
      {
        id: 2771,
        name: "Canindeyú",
        state_code: "14",
        latitude: "-24.13787350",
        longitude: "-55.66896360",
        type: null,
      },
      {
        id: 2777,
        name: "Central Department",
        state_code: "11",
        latitude: "36.15592290",
        longitude: "-95.96620750",
        type: null,
      },
      {
        id: 2779,
        name: "Concepción Department",
        state_code: "1",
        latitude: "-23.42142640",
        longitude: "-57.43444510",
        type: null,
      },
      {
        id: 2783,
        name: "Cordillera Department",
        state_code: "3",
        latitude: "-25.22894910",
        longitude: "-57.01116810",
        type: null,
      },
      {
        id: 2772,
        name: "Guairá Department",
        state_code: "4",
        latitude: "-25.88109320",
        longitude: "-56.29293810",
        type: null,
      },
      {
        id: 2778,
        name: "Itapúa",
        state_code: "7",
        latitude: "-26.79236230",
        longitude: "-55.66896360",
        type: null,
      },
      {
        id: 2786,
        name: "Misiones Department",
        state_code: "8",
        latitude: "-26.84335120",
        longitude: "-57.10131880",
        type: null,
      },
      {
        id: 2781,
        name: "Ñeembucú Department",
        state_code: "12",
        latitude: "-27.02991140",
        longitude: "-57.82539500",
        type: null,
      },
      {
        id: 2774,
        name: "Paraguarí Department",
        state_code: "9",
        latitude: "-25.62621740",
        longitude: "-57.15206420",
        type: null,
      },
      {
        id: 2770,
        name: "Presidente Hayes Department",
        state_code: "15",
        latitude: "-23.35126050",
        longitude: "-58.73736340",
        type: null,
      },
      {
        id: 2776,
        name: "San Pedro Department",
        state_code: "2",
        latitude: "-24.19486680",
        longitude: "-56.56164700",
        type: null,
      },
    ],
  },
  {
    name: "Peru",
    iso3: "PER",
    iso2: "PE",
    numeric_code: "604",
    phone_code: "51",
    capital: "Lima",
    currency: "PEN",
    currency_name: "Peruvian sol",
    currency_symbol: "S/.",
    tld: ".pe",
    native: "Perú",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Peruvian",
    timezones: [
      {
        zoneName: "America/Lima",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "PET",
        tzName: "Peru Time",
      },
    ],
    translations: {
      kr: "페루",
      "pt-BR": "Peru",
      pt: "Peru",
      nl: "Peru",
      hr: "Peru",
      fa: "پرو",
      de: "Peru",
      es: "Perú",
      fr: "Pérou",
      ja: "ペルー",
      it: "Perù",
      cn: "秘鲁",
      tr: "Peru",
    },
    latitude: "-10.00000000",
    longitude: "-76.00000000",
    emoji: "🇵🇪",
    emojiU: "U+1F1F5 U+1F1EA",
    states: [
      {
        id: 3685,
        name: "Amazonas",
        state_code: "AMA",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 3680,
        name: "Áncash",
        state_code: "ANC",
        latitude: "-9.32504970",
        longitude: "-77.56194190",
        type: null,
      },
      {
        id: 3699,
        name: "Apurímac",
        state_code: "APU",
        latitude: "-14.05045330",
        longitude: "-73.08774900",
        type: null,
      },
      {
        id: 3681,
        name: "Arequipa",
        state_code: "ARE",
        latitude: "-16.40904740",
        longitude: "-71.53745100",
        type: null,
      },
      {
        id: 3692,
        name: "Ayacucho",
        state_code: "AYA",
        latitude: "-13.16387370",
        longitude: "-74.22356410",
        type: null,
      },
      {
        id: 3688,
        name: "Cajamarca",
        state_code: "CAJ",
        latitude: "-7.16174650",
        longitude: "-78.51278550",
        type: null,
      },
      {
        id: 3701,
        name: "Callao",
        state_code: "CAL",
        latitude: "-12.05084910",
        longitude: "-77.12598430",
        type: null,
      },
      {
        id: 3691,
        name: "Cusco",
        state_code: "CUS",
        latitude: "-13.53195000",
        longitude: "-71.96746260",
        type: null,
      },
      {
        id: 3679,
        name: "Huancavelica",
        state_code: "HUV",
        latitude: "-12.78619780",
        longitude: "-74.97640240",
        type: null,
      },
      {
        id: 3687,
        name: "Huanuco",
        state_code: "HUC",
        latitude: "-9.92076480",
        longitude: "-76.24108430",
        type: null,
      },
      {
        id: 3700,
        name: "Ica",
        state_code: "ICA",
        latitude: "42.35288320",
        longitude: "-71.04300970",
        type: null,
      },
      {
        id: 3693,
        name: "Junín",
        state_code: "JUN",
        latitude: "-11.15819250",
        longitude: "-75.99263060",
        type: null,
      },
      {
        id: 3683,
        name: "La Libertad",
        state_code: "LAL",
        latitude: "13.49069700",
        longitude: "-89.30846070",
        type: null,
      },
      {
        id: 3702,
        name: "Lambayeque",
        state_code: "LAM",
        latitude: "-6.71976660",
        longitude: "-79.90807570",
        type: null,
      },
      {
        id: 3695,
        name: "Lima",
        state_code: "LIM",
        latitude: "-12.04637310",
        longitude: "-77.04275400",
        type: null,
      },
      {
        id: 4922,
        name: "Loreto",
        state_code: "LOR",
        latitude: "-4.37416430",
        longitude: "-76.13042640",
        type: null,
      },
      {
        id: 3678,
        name: "Madre de Dios",
        state_code: "MDD",
        latitude: "-11.76687050",
        longitude: "-70.81199530",
        type: null,
      },
      {
        id: 3698,
        name: "Moquegua",
        state_code: "MOQ",
        latitude: "-17.19273610",
        longitude: "-70.93281380",
        type: null,
      },
      {
        id: 3686,
        name: "Pasco",
        state_code: "PAS",
        latitude: "46.23050490",
        longitude: "-119.09223160",
        type: null,
      },
      {
        id: 3697,
        name: "Piura",
        state_code: "PIU",
        latitude: "-5.17828840",
        longitude: "-80.65488820",
        type: null,
      },
      {
        id: 3682,
        name: "Puno",
        state_code: "PUN",
        latitude: "-15.84022180",
        longitude: "-70.02188050",
        type: null,
      },
      {
        id: 3694,
        name: "San Martín",
        state_code: "SAM",
        latitude: "37.08494640",
        longitude: "-121.61022160",
        type: null,
      },
      {
        id: 3696,
        name: "Tacna",
        state_code: "TAC",
        latitude: "-18.00656790",
        longitude: "-70.24627410",
        type: null,
      },
      {
        id: 3689,
        name: "Tumbes",
        state_code: "TUM",
        latitude: "-3.55649210",
        longitude: "-80.42708850",
        type: null,
      },
      {
        id: 3684,
        name: "Ucayali",
        state_code: "UCA",
        latitude: "-9.82511830",
        longitude: "-73.08774900",
        type: null,
      },
    ],
  },
  {
    name: "Philippines",
    iso3: "PHL",
    iso2: "PH",
    numeric_code: "608",
    phone_code: "63",
    capital: "Manila",
    currency: "PHP",
    currency_name: "Philippine peso",
    currency_symbol: "₱",
    tld: ".ph",
    native: "Pilipinas",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Philippine, Filipino",
    timezones: [
      {
        zoneName: "Asia/Manila",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "PHT",
        tzName: "Philippine Time",
      },
    ],
    translations: {
      kr: "필리핀",
      "pt-BR": "Filipinas",
      pt: "Filipinas",
      nl: "Filipijnen",
      hr: "Filipini",
      fa: "جزایر الندفیلیپین",
      de: "Philippinen",
      es: "Filipinas",
      fr: "Philippines",
      ja: "フィリピン",
      it: "Filippine",
      cn: "菲律宾",
      tr: "Filipinler",
    },
    latitude: "13.00000000",
    longitude: "122.00000000",
    emoji: "🇵🇭",
    emojiU: "U+1F1F5 U+1F1ED",
    states: [
      {
        id: 1324,
        name: "Abra",
        state_code: "ABR",
        latitude: "42.49708300",
        longitude: "-96.38441000",
        type: "province",
      },
      {
        id: 1323,
        name: "Agusan del Norte",
        state_code: "AGN",
        latitude: "8.94562590",
        longitude: "125.53192340",
        type: "province",
      },
      {
        id: 1326,
        name: "Agusan del Sur",
        state_code: "AGS",
        latitude: "8.04638880",
        longitude: "126.06153840",
        type: "province",
      },
      {
        id: 1331,
        name: "Aklan",
        state_code: "AKL",
        latitude: "11.81661090",
        longitude: "122.09415410",
        type: "province",
      },
      {
        id: 1337,
        name: "Albay",
        state_code: "ALB",
        latitude: "13.17748270",
        longitude: "123.52800720",
        type: "province",
      },
      {
        id: 1336,
        name: "Antique",
        state_code: "ANT",
        latitude: "37.03586950",
        longitude: "-95.63616940",
        type: "province",
      },
      {
        id: 1334,
        name: "Apayao",
        state_code: "APA",
        latitude: "18.01203040",
        longitude: "121.17103890",
        type: "province",
      },
      {
        id: 1341,
        name: "Aurora",
        state_code: "AUR",
        latitude: "36.97089100",
        longitude: "-93.71797900",
        type: "province",
      },
      {
        id: 1316,
        name: "Autonomous Region in Muslim Mindanao",
        state_code: "14",
        latitude: "6.95683130",
        longitude: "124.24215970",
        type: "region",
      },
      {
        id: 1346,
        name: "Basilan",
        state_code: "BAS",
        latitude: "6.42963490",
        longitude: "121.98701650",
        type: "province",
      },
      {
        id: 1344,
        name: "Bataan",
        state_code: "BAN",
        latitude: "14.64168420",
        longitude: "120.48184460",
        type: "province",
      },
      {
        id: 1352,
        name: "Batanes",
        state_code: "BTN",
        latitude: "20.44850740",
        longitude: "121.97081290",
        type: "province",
      },
      {
        id: 1359,
        name: "Batangas",
        state_code: "BTG",
        latitude: "13.75646510",
        longitude: "121.05830760",
        type: "province",
      },
      {
        id: 1363,
        name: "Benguet",
        state_code: "BEN",
        latitude: "16.55772570",
        longitude: "120.80394740",
        type: "province",
      },
      {
        id: 1304,
        name: "Bicol",
        state_code: "05",
        latitude: "13.42098850",
        longitude: "123.41367360",
        type: "region",
      },
      {
        id: 1274,
        name: "Biliran",
        state_code: "BIL",
        latitude: "11.58331520",
        longitude: "124.46418480",
        type: "province",
      },
      {
        id: 1272,
        name: "Bohol",
        state_code: "BOH",
        latitude: "9.84999110",
        longitude: "124.14354270",
        type: "province",
      },
      {
        id: 1270,
        name: "Bukidnon",
        state_code: "BUK",
        latitude: "8.05150540",
        longitude: "124.92299460",
        type: "province",
      },
      {
        id: 1278,
        name: "Bulacan",
        state_code: "BUL",
        latitude: "14.79427350",
        longitude: "120.87990080",
        type: "province",
      },
      {
        id: 1279,
        name: "Cagayan",
        state_code: "CAG",
        latitude: "18.24896290",
        longitude: "121.87878330",
        type: "province",
      },
      {
        id: 1342,
        name: "Cagayan Valley",
        state_code: "02",
        latitude: "16.97537580",
        longitude: "121.81070790",
        type: "region",
      },
      {
        id: 1294,
        name: "Calabarzon",
        state_code: "40",
        latitude: "14.10078030",
        longitude: "121.07937050",
        type: "region",
      },
      {
        id: 1283,
        name: "Camarines Norte",
        state_code: "CAN",
        latitude: "14.13902650",
        longitude: "122.76330360",
        type: "province",
      },
      {
        id: 1287,
        name: "Camarines Sur",
        state_code: "CAS",
        latitude: "13.52501970",
        longitude: "123.34861470",
        type: "province",
      },
      {
        id: 1285,
        name: "Camiguin",
        state_code: "CAM",
        latitude: "9.17321640",
        longitude: "124.72987650",
        type: "province",
      },
      {
        id: 1292,
        name: "Capiz",
        state_code: "CAP",
        latitude: "11.55288160",
        longitude: "122.74072300",
        type: "province",
      },
      {
        id: 1314,
        name: "Caraga",
        state_code: "13",
        latitude: "8.80145620",
        longitude: "125.74068820",
        type: "region",
      },
      {
        id: 1301,
        name: "Catanduanes",
        state_code: "CAT",
        latitude: "13.70886840",
        longitude: "124.24215970",
        type: "province",
      },
      {
        id: 1307,
        name: "Cavite",
        state_code: "CAV",
        latitude: "14.47912970",
        longitude: "120.89696340",
        type: "province",
      },
      {
        id: 1306,
        name: "Cebu",
        state_code: "CEB",
        latitude: "10.31569920",
        longitude: "123.88543660",
        type: "province",
      },
      {
        id: 1345,
        name: "Central Luzon",
        state_code: "03",
        latitude: "15.48277220",
        longitude: "120.71200230",
        type: "region",
      },
      {
        id: 1308,
        name: "Central Visayas",
        state_code: "07",
        latitude: "9.81687500",
        longitude: "124.06414190",
        type: "region",
      },
      {
        id: 1311,
        name: "Compostela Valley",
        state_code: "COM",
        latitude: "7.51251500",
        longitude: "126.17626150",
        type: "province",
      },
      {
        id: 1335,
        name: "Cordillera Administrative",
        state_code: "15",
        latitude: "17.35125420",
        longitude: "121.17188510",
        type: "region",
      },
      {
        id: 1320,
        name: "Cotabato",
        state_code: "NCO",
        latitude: "7.20466680",
        longitude: "124.23104390",
        type: "province",
      },
      {
        id: 1340,
        name: "Davao",
        state_code: "11",
        latitude: "7.30416220",
        longitude: "126.08934060",
        type: "region",
      },
      {
        id: 1319,
        name: "Davao del Norte",
        state_code: "DAV",
        latitude: "7.56176990",
        longitude: "125.65328480",
        type: "province",
      },
      {
        id: 1318,
        name: "Davao del Sur",
        state_code: "DAS",
        latitude: "6.76626870",
        longitude: "125.32842690",
        type: "province",
      },
      {
        id: 1309,
        name: "Davao Occidental",
        state_code: "DVO",
        latitude: "6.09413960",
        longitude: "125.60954740",
        type: "province",
      },
      {
        id: 1289,
        name: "Davao Oriental",
        state_code: "DAO",
        latitude: "7.31715850",
        longitude: "126.54198870",
        type: "province",
      },
      {
        id: 1291,
        name: "Dinagat Islands",
        state_code: "DIN",
        latitude: "10.12818160",
        longitude: "125.60954740",
        type: "province",
      },
      {
        id: 1290,
        name: "Eastern Samar",
        state_code: "EAS",
        latitude: "11.50007310",
        longitude: "125.49999080",
        type: "province",
      },
      {
        id: 1322,
        name: "Eastern Visayas",
        state_code: "08",
        latitude: "12.24455330",
        longitude: "125.03881640",
        type: "region",
      },
      {
        id: 1303,
        name: "Guimaras",
        state_code: "GUI",
        latitude: "10.59286610",
        longitude: "122.63250810",
        type: "province",
      },
      {
        id: 1300,
        name: "Ifugao",
        state_code: "IFU",
        latitude: "16.83307920",
        longitude: "121.17103890",
        type: "province",
      },
      {
        id: 1355,
        name: "Ilocos",
        state_code: "01",
        latitude: "16.08321440",
        longitude: "120.61998950",
        type: "region",
      },
      {
        id: 1298,
        name: "Ilocos Norte",
        state_code: "ILN",
        latitude: "18.16472810",
        longitude: "120.71155920",
        type: "province",
      },
      {
        id: 1321,
        name: "Ilocos Sur",
        state_code: "ILS",
        latitude: "17.22786640",
        longitude: "120.57395790",
        type: "province",
      },
      {
        id: 1315,
        name: "Iloilo",
        state_code: "ILI",
        latitude: "10.72015010",
        longitude: "122.56210630",
        type: "province",
      },
      {
        id: 1313,
        name: "Isabela",
        state_code: "ISA",
        latitude: "18.50077590",
        longitude: "-67.02434620",
        type: "province",
      },
      {
        id: 1312,
        name: "Kalinga",
        state_code: "KAL",
        latitude: "17.47404220",
        longitude: "121.35416310",
        type: "province",
      },
      {
        id: 1317,
        name: "La Union",
        state_code: "LUN",
        latitude: "38.87668780",
        longitude: "-77.12809120",
        type: "province",
      },
      {
        id: 1328,
        name: "Laguna",
        state_code: "LAG",
        latitude: "33.54271890",
        longitude: "-117.78535680",
        type: "province",
      },
      {
        id: 1327,
        name: "Lanao del Norte",
        state_code: "LAN",
        latitude: "7.87218110",
        longitude: "123.88577470",
        type: "province",
      },
      {
        id: 1333,
        name: "Lanao del Sur",
        state_code: "LAS",
        latitude: "7.82317600",
        longitude: "124.41982430",
        type: "province",
      },
      {
        id: 1332,
        name: "Leyte",
        state_code: "LEY",
        latitude: "10.86245360",
        longitude: "124.88111950",
        type: "province",
      },
      {
        id: 1330,
        name: "Maguindanao",
        state_code: "MAG",
        latitude: "6.94225810",
        longitude: "124.41982430",
        type: "province",
      },
      {
        id: 1329,
        name: "Marinduque",
        state_code: "MAD",
        latitude: "13.47671710",
        longitude: "121.90321920",
        type: "province",
      },
      {
        id: 1338,
        name: "Masbate",
        state_code: "MAS",
        latitude: "12.35743460",
        longitude: "123.55040760",
        type: "province",
      },
      {
        id: 1347,
        name: "Metro Manila",
        state_code: "NCR",
        latitude: "14.60905370",
        longitude: "121.02225650",
        type: "province",
      },
      {
        id: 1299,
        name: "Mimaropa",
        state_code: "41",
        latitude: "9.84320650",
        longitude: "118.73647830",
        type: "region",
      },
      {
        id: 1343,
        name: "Misamis Occidental",
        state_code: "MSC",
        latitude: "8.33749030",
        longitude: "123.70706190",
        type: "province",
      },
      {
        id: 1348,
        name: "Misamis Oriental",
        state_code: "MSR",
        latitude: "8.50455580",
        longitude: "124.62195920",
        type: "province",
      },
      {
        id: 1353,
        name: "Mountain Province",
        state_code: "MOU",
        latitude: "40.70754370",
        longitude: "-73.95010330",
        type: "province",
      },
      {
        id: 1351,
        name: "Negros Occidental",
        state_code: "NEC",
        latitude: "10.29256090",
        longitude: "123.02465180",
        type: "province",
      },
      {
        id: 1350,
        name: "Negros Oriental",
        state_code: "NER",
        latitude: "9.62820830",
        longitude: "122.98883190",
        type: "province",
      },
      {
        id: 1339,
        name: "Northern Mindanao",
        state_code: "10",
        latitude: "8.02016350",
        longitude: "124.68565090",
        type: "region",
      },
      {
        id: 1349,
        name: "Northern Samar",
        state_code: "NSA",
        latitude: "12.36131990",
        longitude: "124.77407930",
        type: "province",
      },
      {
        id: 1360,
        name: "Nueva Ecija",
        state_code: "NUE",
        latitude: "15.57837500",
        longitude: "121.11126150",
        type: "province",
      },
      {
        id: 1358,
        name: "Nueva Vizcaya",
        state_code: "NUV",
        latitude: "16.33011070",
        longitude: "121.17103890",
        type: "province",
      },
      {
        id: 1356,
        name: "Occidental Mindoro",
        state_code: "MDC",
        latitude: "13.10241110",
        longitude: "120.76512840",
        type: "province",
      },
      {
        id: 1354,
        name: "Oriental Mindoro",
        state_code: "MDR",
        latitude: "13.05645980",
        longitude: "121.40694170",
        type: "province",
      },
      {
        id: 1361,
        name: "Palawan",
        state_code: "PLW",
        latitude: "9.83494930",
        longitude: "118.73836150",
        type: "province",
      },
      {
        id: 1365,
        name: "Pampanga",
        state_code: "PAM",
        latitude: "15.07940900",
        longitude: "120.61998950",
        type: "province",
      },
      {
        id: 1364,
        name: "Pangasinan",
        state_code: "PAN",
        latitude: "15.89490550",
        longitude: "120.28631830",
        type: "province",
      },
      {
        id: 1275,
        name: "Quezon",
        state_code: "QUE",
        latitude: "14.03139060",
        longitude: "122.11309090",
        type: "province",
      },
      {
        id: 1273,
        name: "Quirino",
        state_code: "QUI",
        latitude: "16.27004240",
        longitude: "121.53700030",
        type: "province",
      },
      {
        id: 1271,
        name: "Rizal",
        state_code: "RIZ",
        latitude: "14.60374460",
        longitude: "121.30840880",
        type: "province",
      },
      {
        id: 1269,
        name: "Romblon",
        state_code: "ROM",
        latitude: "12.57780160",
        longitude: "122.26914600",
        type: "province",
      },
      {
        id: 1277,
        name: "Sarangani",
        state_code: "SAR",
        latitude: "5.92671750",
        longitude: "124.99475100",
        type: "province",
      },
      {
        id: 1276,
        name: "Siquijor",
        state_code: "SIG",
        latitude: "9.19987790",
        longitude: "123.59519250",
        type: "province",
      },
      {
        id: 1310,
        name: "Soccsksargen",
        state_code: "12",
        latitude: "6.27069180",
        longitude: "124.68565090",
        type: "region",
      },
      {
        id: 1281,
        name: "Sorsogon",
        state_code: "SOR",
        latitude: "12.99270950",
        longitude: "124.01474640",
        type: "province",
      },
      {
        id: 1280,
        name: "South Cotabato",
        state_code: "SCO",
        latitude: "6.33575650",
        longitude: "124.77407930",
        type: "province",
      },
      {
        id: 1284,
        name: "Southern Leyte",
        state_code: "SLE",
        latitude: "10.33462060",
        longitude: "125.17087410",
        type: "province",
      },
      {
        id: 1282,
        name: "Sultan Kudarat",
        state_code: "SUK",
        latitude: "6.50694010",
        longitude: "124.41982430",
        type: "province",
      },
      {
        id: 1288,
        name: "Sulu",
        state_code: "SLU",
        latitude: "5.97490110",
        longitude: "121.03351000",
        type: "province",
      },
      {
        id: 1286,
        name: "Surigao del Norte",
        state_code: "SUN",
        latitude: "9.51482800",
        longitude: "125.69699840",
        type: "province",
      },
      {
        id: 1296,
        name: "Surigao del Sur",
        state_code: "SUR",
        latitude: "8.54049060",
        longitude: "126.11447580",
        type: "province",
      },
      {
        id: 1295,
        name: "Tarlac",
        state_code: "TAR",
        latitude: "15.47547860",
        longitude: "120.59634920",
        type: "province",
      },
      {
        id: 1293,
        name: "Tawi-Tawi",
        state_code: "TAW",
        latitude: "5.13381100",
        longitude: "119.95092600",
        type: "province",
      },
      {
        id: 5115,
        name: "Western Samar",
        state_code: "WSA",
        latitude: "12.00002060",
        longitude: "124.99124520",
        type: "province",
      },
      {
        id: 1305,
        name: "Western Visayas",
        state_code: "06",
        latitude: "11.00498360",
        longitude: "122.53727410",
        type: "region",
      },
      {
        id: 1297,
        name: "Zambales",
        state_code: "ZMB",
        latitude: "15.50817660",
        longitude: "119.96978080",
        type: "province",
      },
      {
        id: 1302,
        name: "Zamboanga del Norte",
        state_code: "ZAN",
        latitude: "8.38862820",
        longitude: "123.16888830",
        type: "province",
      },
      {
        id: 1357,
        name: "Zamboanga del Sur",
        state_code: "ZAS",
        latitude: "7.83830540",
        longitude: "123.29666570",
        type: "province",
      },
      {
        id: 1325,
        name: "Zamboanga Peninsula",
        state_code: "09",
        latitude: "8.15407700",
        longitude: "123.25879300",
        type: "region",
      },
      {
        id: 1362,
        name: "Zamboanga Sibugay",
        state_code: "ZSI",
        latitude: "7.52252470",
        longitude: "122.31075170",
        type: "province",
      },
    ],
  },
  {
    name: "Pitcairn Island",
    iso3: "PCN",
    iso2: "PN",
    numeric_code: "612",
    phone_code: "870",
    capital: "Adamstown",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".pn",
    native: "Pitcairn Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Pitcairn Island",
    timezones: [
      {
        zoneName: "Pacific/Pitcairn",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
    ],
    translations: {
      kr: "핏케언 제도",
      "pt-BR": "Ilhas Pitcairn",
      pt: "Ilhas Picárnia",
      nl: "Pitcairneilanden",
      hr: "Pitcairnovo otočje",
      fa: "پیتکرن",
      de: "Pitcairn",
      es: "Islas Pitcairn",
      fr: "Îles Pitcairn",
      ja: "ピトケアン",
      it: "Isole Pitcairn",
      cn: "皮特凯恩群岛",
      tr: "Pitcairn Adalari",
    },
    latitude: "-25.06666666",
    longitude: "-130.10000000",
    emoji: "🇵🇳",
    emojiU: "U+1F1F5 U+1F1F3",
    states: [],
  },
  {
    name: "Poland",
    iso3: "POL",
    iso2: "PL",
    numeric_code: "616",
    phone_code: "48",
    capital: "Warsaw",
    currency: "PLN",
    currency_name: "Polish złoty",
    currency_symbol: "zł",
    tld: ".pl",
    native: "Polska",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Polish",
    timezones: [
      {
        zoneName: "Europe/Warsaw",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "폴란드",
      "pt-BR": "Polônia",
      pt: "Polónia",
      nl: "Polen",
      hr: "Poljska",
      fa: "لهستان",
      de: "Polen",
      es: "Polonia",
      fr: "Pologne",
      ja: "ポーランド",
      it: "Polonia",
      cn: "波兰",
      tr: "Polonya",
    },
    latitude: "52.00000000",
    longitude: "20.00000000",
    emoji: "🇵🇱",
    emojiU: "U+1F1F5 U+1F1F1",
    states: [
      {
        id: 1634,
        name: "Greater Poland Voivodeship",
        state_code: "WP",
        latitude: "52.27998600",
        longitude: "17.35229390",
        type: null,
      },
      {
        id: 1625,
        name: "Kuyavian-Pomeranian Voivodeship",
        state_code: "KP",
        latitude: "53.16483630",
        longitude: "18.48342240",
        type: null,
      },
      {
        id: 1635,
        name: "Lesser Poland Voivodeship",
        state_code: "MA",
        latitude: "49.72253060",
        longitude: "20.25033580",
        type: null,
      },
      {
        id: 1629,
        name: "Lower Silesian Voivodeship",
        state_code: "DS",
        latitude: "51.13398610",
        longitude: "16.88419610",
        type: null,
      },
      {
        id: 1638,
        name: "Lublin Voivodeship",
        state_code: "LU",
        latitude: "51.24935190",
        longitude: "23.10113920",
        type: null,
      },
      {
        id: 1631,
        name: "Lubusz Voivodeship",
        state_code: "LB",
        latitude: "52.22746120",
        longitude: "15.25591030",
        type: null,
      },
      {
        id: 1636,
        name: "Łódź Voivodeship",
        state_code: "LD",
        latitude: "51.46347710",
        longitude: "19.17269740",
        type: null,
      },
      {
        id: 1637,
        name: "Masovian Voivodeship",
        state_code: "MZ",
        latitude: "51.89271820",
        longitude: "21.00216790",
        type: null,
      },
      {
        id: 1622,
        name: "Opole Voivodeship",
        state_code: "OP",
        latitude: "50.80037610",
        longitude: "17.93798900",
        type: null,
      },
      {
        id: 1626,
        name: "Podkarpackie Voivodeship",
        state_code: "PK",
        latitude: "50.05747490",
        longitude: "22.08956910",
        type: null,
      },
      {
        id: 1632,
        name: "Podlaskie Voivodeship",
        state_code: "PD",
        latitude: "53.06971590",
        longitude: "22.96746390",
        type: null,
      },
      {
        id: 1624,
        name: "Pomeranian Voivodeship",
        state_code: "PM",
        latitude: "54.29442520",
        longitude: "18.15311640",
        type: null,
      },
      {
        id: 1623,
        name: "Silesian Voivodeship",
        state_code: "SL",
        latitude: "50.57165950",
        longitude: "19.32197680",
        type: null,
      },
      {
        id: 1630,
        name: "Świętokrzyskie Voivodeship",
        state_code: "SK",
        latitude: "50.62610410",
        longitude: "20.94062790",
        type: null,
      },
      {
        id: 1628,
        name: "Warmian-Masurian Voivodeship",
        state_code: "WN",
        latitude: "53.86711170",
        longitude: "20.70278610",
        type: null,
      },
      {
        id: 1633,
        name: "West Pomeranian Voivodeship",
        state_code: "ZP",
        latitude: "53.46578910",
        longitude: "15.18225810",
        type: null,
      },
    ],
  },
  {
    name: "Portugal",
    iso3: "PRT",
    iso2: "PT",
    numeric_code: "620",
    phone_code: "351",
    capital: "Lisbon",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".pt",
    native: "Portugal",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Portuguese",
    timezones: [
      {
        zoneName: "Atlantic/Azores",
        gmtOffset: -3600,
        gmtOffsetName: "UTC-01:00",
        abbreviation: "AZOT",
        tzName: "Azores Standard Time",
      },
      {
        zoneName: "Atlantic/Madeira",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
      {
        zoneName: "Europe/Lisbon",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
    ],
    translations: {
      kr: "포르투갈",
      "pt-BR": "Portugal",
      pt: "Portugal",
      nl: "Portugal",
      hr: "Portugal",
      fa: "پرتغال",
      de: "Portugal",
      es: "Portugal",
      fr: "Portugal",
      ja: "ポルトガル",
      it: "Portogallo",
      cn: "葡萄牙",
      tr: "Portekiz",
    },
    latitude: "39.50000000",
    longitude: "-8.00000000",
    emoji: "🇵🇹",
    emojiU: "U+1F1F5 U+1F1F9",
    states: [
      {
        id: 2233,
        name: "Açores",
        state_code: "20",
        latitude: "37.74124880",
        longitude: "-25.67559440",
        type: null,
      },
      {
        id: 2235,
        name: "Aveiro",
        state_code: "01",
        latitude: "40.72090230",
        longitude: "-8.57210160",
        type: null,
      },
      {
        id: 2230,
        name: "Beja",
        state_code: "02",
        latitude: "37.96877860",
        longitude: "-7.87216000",
        type: null,
      },
      {
        id: 2244,
        name: "Braga",
        state_code: "03",
        latitude: "41.55038800",
        longitude: "-8.42613010",
        type: null,
      },
      {
        id: 2229,
        name: "Bragança",
        state_code: "04",
        latitude: "41.80616520",
        longitude: "-6.75674270",
        type: null,
      },
      {
        id: 2241,
        name: "Castelo Branco",
        state_code: "05",
        latitude: "39.86313230",
        longitude: "-7.48141630",
        type: null,
      },
      {
        id: 2246,
        name: "Coimbra",
        state_code: "06",
        latitude: "40.20579940",
        longitude: "-8.41369000",
        type: null,
      },
      {
        id: 2236,
        name: "Évora",
        state_code: "07",
        latitude: "38.57444680",
        longitude: "-7.90765530",
        type: null,
      },
      {
        id: 2239,
        name: "Faro",
        state_code: "08",
        latitude: "37.01935480",
        longitude: "-7.93043970",
        type: null,
      },
      {
        id: 4859,
        name: "Guarda",
        state_code: "09",
        latitude: "40.53859720",
        longitude: "-7.26757720",
        type: null,
      },
      {
        id: 2240,
        name: "Leiria",
        state_code: "10",
        latitude: "39.77095320",
        longitude: "-8.79218360",
        type: null,
      },
      {
        id: 2228,
        name: "Lisbon",
        state_code: "11",
        latitude: "38.72232630",
        longitude: "-9.13927140",
        type: null,
      },
      {
        id: 2231,
        name: "Madeira",
        state_code: "30",
        latitude: "32.76070740",
        longitude: "-16.95947230",
        type: null,
      },
      {
        id: 2232,
        name: "Portalegre",
        state_code: "12",
        latitude: "39.29670860",
        longitude: "-7.42847550",
        type: null,
      },
      {
        id: 2243,
        name: "Porto",
        state_code: "13",
        latitude: "41.14766290",
        longitude: "-8.60789730",
        type: null,
      },
      {
        id: 2238,
        name: "Santarém",
        state_code: "14",
        latitude: "39.23666870",
        longitude: "-8.68599440",
        type: null,
      },
      {
        id: 2242,
        name: "Setúbal",
        state_code: "15",
        latitude: "38.52409330",
        longitude: "-8.89258760",
        type: null,
      },
      {
        id: 2245,
        name: "Viana do Castelo",
        state_code: "16",
        latitude: "41.69180460",
        longitude: "-8.83445100",
        type: null,
      },
      {
        id: 2234,
        name: "Vila Real",
        state_code: "17",
        latitude: "41.30035270",
        longitude: "-7.74572740",
        type: null,
      },
      {
        id: 2237,
        name: "Viseu",
        state_code: "18",
        latitude: "40.65884240",
        longitude: "-7.91475600",
        type: null,
      },
    ],
  },
  {
    name: "Puerto Rico",
    iso3: "PRI",
    iso2: "PR",
    numeric_code: "630",
    phone_code: "+1-787 and 1-939",
    capital: "San Juan",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".pr",
    native: "Puerto Rico",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Puerto Rican",
    timezones: [
      {
        zoneName: "America/Puerto_Rico",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "푸에르토리코",
      "pt-BR": "Porto Rico",
      pt: "Porto Rico",
      nl: "Puerto Rico",
      hr: "Portoriko",
      fa: "پورتو ریکو",
      de: "Puerto Rico",
      es: "Puerto Rico",
      fr: "Porto Rico",
      ja: "プエルトリコ",
      it: "Porto Rico",
      cn: "波多黎各",
      tr: "Porto Riko",
    },
    latitude: "18.25000000",
    longitude: "-66.50000000",
    emoji: "🇵🇷",
    emojiU: "U+1F1F5 U+1F1F7",
    states: [
      {
        id: 5134,
        name: "Adjuntas",
        state_code: "001",
        latitude: "18.16348480",
        longitude: "-66.72315800",
        type: "municipalities",
      },
      {
        id: 5135,
        name: "Aguada",
        state_code: "003",
        latitude: "18.38015790",
        longitude: "-67.18870400",
        type: "municipalities",
      },
      {
        id: 5136,
        name: "Aguadilla",
        state_code: "005",
        latitude: "18.42744540",
        longitude: "-67.15406980",
        type: "municipalities",
      },
      {
        id: 5137,
        name: "Aguas Buenas",
        state_code: "007",
        latitude: "18.25689890",
        longitude: "-66.10294420",
        type: "municipalities",
      },
      {
        id: 5138,
        name: "Aibonito",
        state_code: "009",
        latitude: "18.13995940",
        longitude: "-66.26600160",
        type: "municipalities",
      },
      {
        id: 5139,
        name: "Añasco",
        state_code: "011",
        latitude: "18.28544760",
        longitude: "-67.14029350",
        type: "municipalities",
      },
      {
        id: 5140,
        name: "Arecibo",
        state_code: "013",
        latitude: "18.47051370",
        longitude: "-66.72184720",
        type: "municipalities",
      },
      {
        id: 5081,
        name: "Arecibo",
        state_code: "AR",
        latitude: "18.47055556",
        longitude: "-66.72083333",
        type: "region",
      },
      {
        id: 5141,
        name: "Arroyo",
        state_code: "015",
        latitude: "17.99642200",
        longitude: "-66.09248790",
        type: "municipalities",
      },
      {
        id: 5142,
        name: "Barceloneta",
        state_code: "017",
        latitude: "41.38010610",
        longitude: "2.18969570",
        type: "municipalities",
      },
      {
        id: 5143,
        name: "Barranquitas",
        state_code: "019",
        latitude: "18.18662420",
        longitude: "-66.30628020",
        type: "municipalities",
      },
      {
        id: 5144,
        name: "Bayamón",
        state_code: "021",
        latitude: "18.38939600",
        longitude: "-66.16532240",
        type: "municipalities",
      },
      {
        id: 5076,
        name: "Bayamon",
        state_code: "BY",
        latitude: "18.17777778",
        longitude: "-66.11333333",
        type: "region",
      },
      {
        id: 5145,
        name: "Cabo Rojo",
        state_code: "023",
        latitude: "18.08662650",
        longitude: "-67.14573470",
        type: "municipalities",
      },
      {
        id: 5146,
        name: "Caguas",
        state_code: "025",
        latitude: "18.23879950",
        longitude: "-66.03524900",
        type: "municipalities",
      },
      {
        id: 5079,
        name: "Caguas",
        state_code: "CG",
        latitude: "18.23333333",
        longitude: "-66.03333333",
        type: "region",
      },
      {
        id: 5147,
        name: "Camuy",
        state_code: "027",
        latitude: "18.48383300",
        longitude: "-66.84489940",
        type: "municipalities",
      },
      {
        id: 5148,
        name: "Canóvanas",
        state_code: "029",
        latitude: "18.37487480",
        longitude: "-65.89975330",
        type: "municipalities",
      },
      {
        id: 5149,
        name: "Carolina",
        state_code: "031",
        latitude: "18.36808770",
        longitude: "-66.04247340",
        type: "municipalities",
      },
      {
        id: 5077,
        name: "Carolina",
        state_code: "CL",
        latitude: "18.38888889",
        longitude: "-65.96666667",
        type: "region",
      },
      {
        id: 5150,
        name: "Cataño",
        state_code: "033",
        latitude: "18.44653550",
        longitude: "-66.13557750",
        type: "municipalities",
      },
      {
        id: 5151,
        name: "Cayey",
        state_code: "035",
        latitude: "18.11190510",
        longitude: "-66.16600000",
        type: "municipalities",
      },
      {
        id: 5152,
        name: "Ceiba",
        state_code: "037",
        latitude: "18.24751770",
        longitude: "-65.90849530",
        type: "municipalities",
      },
      {
        id: 5153,
        name: "Ciales",
        state_code: "039",
        latitude: "18.33606220",
        longitude: "-66.46878230",
        type: "municipalities",
      },
      {
        id: 5154,
        name: "Cidra",
        state_code: "041",
        latitude: "18.17579140",
        longitude: "-66.16127790",
        type: "municipalities",
      },
      {
        id: 5155,
        name: "Coamo",
        state_code: "043",
        latitude: "18.07996160",
        longitude: "-66.35794730",
        type: "municipalities",
      },
      {
        id: 5156,
        name: "Comerío",
        state_code: "045",
        latitude: "18.21920010",
        longitude: "-66.22560220",
        type: "municipalities",
      },
      {
        id: 5157,
        name: "Corozal",
        state_code: "047",
        latitude: "18.40308020",
        longitude: "-88.39675360",
        type: "municipalities",
      },
      {
        id: 5158,
        name: "Culebra",
        state_code: "049",
        latitude: "18.31039400",
        longitude: "-65.30307050",
        type: "municipalities",
      },
      {
        id: 5159,
        name: "Dorado",
        state_code: "051",
        latitude: "43.14805560",
        longitude: "-77.57722220",
        type: "municipalities",
      },
      {
        id: 5160,
        name: "Fajardo",
        state_code: "053",
        latitude: "18.32521480",
        longitude: "-65.65393560",
        type: "municipalities",
      },
      {
        id: 5161,
        name: "Florida",
        state_code: "054",
        latitude: "27.66482740",
        longitude: "-81.51575350",
        type: "municipalities",
      },
      {
        id: 5162,
        name: "Guánica",
        state_code: "055",
        latitude: "17.97251450",
        longitude: "-66.90862640",
        type: "municipalities",
      },
      {
        id: 5163,
        name: "Guayama",
        state_code: "057",
        latitude: "17.98413280",
        longitude: "-66.11377670",
        type: "municipalities",
      },
      {
        id: 5164,
        name: "Guayanilla",
        state_code: "059",
        latitude: "18.01913140",
        longitude: "-66.79184200",
        type: "municipalities",
      },
      {
        id: 5165,
        name: "Guaynabo",
        state_code: "061",
        latitude: "18.36129540",
        longitude: "-66.11029570",
        type: "municipalities",
      },
      {
        id: 5080,
        name: "Guaynabo",
        state_code: "GN",
        latitude: "18.36666667",
        longitude: "-66.10000000",
        type: "region",
      },
      {
        id: 5166,
        name: "Gurabo",
        state_code: "063",
        latitude: "18.25439870",
        longitude: "-65.97294210",
        type: "municipalities",
      },
      {
        id: 5167,
        name: "Hatillo",
        state_code: "065",
        latitude: "18.42846420",
        longitude: "-66.78753210",
        type: "municipalities",
      },
      {
        id: 5168,
        name: "Hormigueros",
        state_code: "067",
        latitude: "18.13346380",
        longitude: "-67.11281230",
        type: "municipalities",
      },
      {
        id: 5169,
        name: "Humacao",
        state_code: "069",
        latitude: "18.15157360",
        longitude: "-65.82485290",
        type: "municipalities",
      },
      {
        id: 5170,
        name: "Isabela",
        state_code: "071",
        latitude: "16.97537580",
        longitude: "121.81070790",
        type: "municipalities",
      },
      {
        id: 5171,
        name: "Jayuya",
        state_code: "073",
        latitude: "18.21856740",
        longitude: "-66.59156170",
        type: "municipalities",
      },
      {
        id: 5172,
        name: "Juana Díaz",
        state_code: "075",
        latitude: "18.05343720",
        longitude: "-66.50750790",
        type: "municipalities",
      },
      {
        id: 5173,
        name: "Juncos",
        state_code: "077",
        latitude: "18.22745580",
        longitude: "-65.92099700",
        type: "municipalities",
      },
      {
        id: 5174,
        name: "Lajas",
        state_code: "079",
        latitude: "18.04996200",
        longitude: "-67.05934490",
        type: "municipalities",
      },
      {
        id: 5175,
        name: "Lares",
        state_code: "081",
        latitude: "34.02508020",
        longitude: "-118.45945930",
        type: "municipalities",
      },
      {
        id: 5176,
        name: "Las Marías",
        state_code: "083",
        latitude: "35.83822380",
        longitude: "-78.61035660",
        type: "municipalities",
      },
      {
        id: 5177,
        name: "Las Piedras",
        state_code: "085",
        latitude: "18.18557530",
        longitude: "-65.87362450",
        type: "municipalities",
      },
      {
        id: 5178,
        name: "Loíza",
        state_code: "087",
        latitude: "18.43299040",
        longitude: "-65.87836000",
        type: "municipalities",
      },
      {
        id: 5179,
        name: "Luquillo",
        state_code: "089",
        latitude: "18.37245070",
        longitude: "-65.71655110",
        type: "municipalities",
      },
      {
        id: 5180,
        name: "Manatí",
        state_code: "091",
        latitude: "18.41812150",
        longitude: "-66.52627830",
        type: "municipalities",
      },
      {
        id: 5181,
        name: "Maricao",
        state_code: "093",
        latitude: "18.18079020",
        longitude: "-66.97990010",
        type: "municipalities",
      },
      {
        id: 5182,
        name: "Maunabo",
        state_code: "095",
        latitude: "18.00718850",
        longitude: "-65.89932890",
        type: "municipalities",
      },
      {
        id: 5183,
        name: "Mayagüez",
        state_code: "097",
        latitude: "18.20134520",
        longitude: "-67.14515490",
        type: "municipalities",
      },
      {
        id: 5083,
        name: "Mayagüez",
        state_code: "MG",
        latitude: "18.20111111",
        longitude: "-67.13972222",
        type: "region",
      },
      {
        id: 5184,
        name: "Moca",
        state_code: "099",
        latitude: "18.39679290",
        longitude: "-67.14790350",
        type: "municipalities",
      },
      {
        id: 5185,
        name: "Morovis",
        state_code: "101",
        latitude: "18.32578500",
        longitude: "-66.40655920",
        type: "municipalities",
      },
      {
        id: 5186,
        name: "Naguabo",
        state_code: "103",
        latitude: "18.21162470",
        longitude: "-65.73488410",
        type: "municipalities",
      },
      {
        id: 5187,
        name: "Naranjito",
        state_code: "105",
        latitude: "18.30078610",
        longitude: "-66.24489040",
        type: "municipalities",
      },
      {
        id: 5188,
        name: "Orocovis",
        state_code: "107",
        latitude: "18.22692240",
        longitude: "-66.39116860",
        type: "municipalities",
      },
      {
        id: 5189,
        name: "Patillas",
        state_code: "109",
        latitude: "18.00373810",
        longitude: "-66.01340590",
        type: "municipalities",
      },
      {
        id: 5190,
        name: "Peñuelas",
        state_code: "111",
        latitude: "18.06335770",
        longitude: "-66.72738960",
        type: "municipalities",
      },
      {
        id: 5191,
        name: "Ponce",
        state_code: "113",
        latitude: "18.01107680",
        longitude: "-66.61406160",
        type: "municipalities",
      },
      {
        id: 5078,
        name: "Ponce",
        state_code: "PO",
        latitude: "18.00000000",
        longitude: "-66.61666667",
        type: "region",
      },
      {
        id: 5192,
        name: "Quebradillas",
        state_code: "115",
        latitude: "18.47383300",
        longitude: "-66.93851200",
        type: "municipalities",
      },
      {
        id: 5193,
        name: "Rincón",
        state_code: "117",
        latitude: "18.34015140",
        longitude: "-67.24994590",
        type: "municipalities",
      },
      {
        id: 5194,
        name: "Río Grande",
        state_code: "119",
        latitude: "28.81063826",
        longitude: "-101.83538780",
        type: "municipalities",
      },
      {
        id: 5195,
        name: "Sabana Grande",
        state_code: "121",
        latitude: "18.07773920",
        longitude: "-66.96045490",
        type: "municipalities",
      },
      {
        id: 5196,
        name: "Salinas",
        state_code: "123",
        latitude: "36.67773720",
        longitude: "-121.65550130",
        type: "municipalities",
      },
      {
        id: 5197,
        name: "San Germán",
        state_code: "125",
        latitude: "18.08070820",
        longitude: "-67.04110960",
        type: "municipalities",
      },
      {
        id: 5198,
        name: "San Juan",
        state_code: "127",
        latitude: "18.46320300",
        longitude: "-66.11475710",
        type: "municipalities",
      },
      {
        id: 5075,
        name: "San Juan",
        state_code: "SJ",
        latitude: "18.45000000",
        longitude: "-66.06666667",
        type: "region",
      },
      {
        id: 5199,
        name: "San Lorenzo",
        state_code: "129",
        latitude: "18.18869120",
        longitude: "-65.97658620",
        type: "municipalities",
      },
      {
        id: 5200,
        name: "San Sebastián",
        state_code: "131",
        latitude: "43.31833400",
        longitude: "-1.98123130",
        type: "municipalities",
      },
      {
        id: 5201,
        name: "Santa Isabel",
        state_code: "133",
        latitude: "17.96607750",
        longitude: "-66.40489200",
        type: "municipalities",
      },
      {
        id: 5202,
        name: "Toa Alta",
        state_code: "135",
        latitude: "18.38828230",
        longitude: "-66.24822370",
        type: "municipalities",
      },
      {
        id: 5203,
        name: "Toa Baja",
        state_code: "137",
        latitude: "18.44447090",
        longitude: "-66.25432930",
        type: "municipalities",
      },
      {
        id: 5082,
        name: "Toa Baja",
        state_code: "TB",
        latitude: "18.44388900",
        longitude: "-66.25972200",
        type: "region",
      },
      {
        id: 5204,
        name: "Trujillo Alto",
        state_code: "139",
        latitude: "18.35467190",
        longitude: "-66.00738760",
        type: "municipalities",
      },
      {
        id: 5084,
        name: "Trujillo Alto",
        state_code: "TA",
        latitude: "18.36277800",
        longitude: "-66.01750000",
        type: "region",
      },
      {
        id: 5205,
        name: "Utuado",
        state_code: "141",
        latitude: "18.26550950",
        longitude: "-66.70045190",
        type: "municipalities",
      },
      {
        id: 5206,
        name: "Vega Alta",
        state_code: "143",
        latitude: "18.41217030",
        longitude: "-66.33128050",
        type: "municipalities",
      },
      {
        id: 5207,
        name: "Vega Baja",
        state_code: "145",
        latitude: "18.44614590",
        longitude: "-66.40419670",
        type: "municipalities",
      },
      {
        id: 5208,
        name: "Vieques",
        state_code: "147",
        latitude: "18.12628540",
        longitude: "-65.44009850",
        type: "municipalities",
      },
      {
        id: 5209,
        name: "Villalba",
        state_code: "149",
        latitude: "18.12175540",
        longitude: "-66.49857870",
        type: "municipalities",
      },
      {
        id: 5210,
        name: "Yabucoa",
        state_code: "151",
        latitude: "18.05052010",
        longitude: "-65.87932880",
        type: "municipalities",
      },
      {
        id: 5211,
        name: "Yauco",
        state_code: "153",
        latitude: "18.03496400",
        longitude: "-66.84989830",
        type: "municipalities",
      },
    ],
  },
  {
    name: "Qatar",
    iso3: "QAT",
    iso2: "QA",
    numeric_code: "634",
    phone_code: "974",
    capital: "Doha",
    currency: "QAR",
    currency_name: "Qatari riyal",
    currency_symbol: "ق.ر",
    tld: ".qa",
    native: "قطر",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Qatari",
    timezones: [
      {
        zoneName: "Asia/Qatar",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "카타르",
      "pt-BR": "Catar",
      pt: "Catar",
      nl: "Qatar",
      hr: "Katar",
      fa: "قطر",
      de: "Katar",
      es: "Catar",
      fr: "Qatar",
      ja: "カタール",
      it: "Qatar",
      cn: "卡塔尔",
      tr: "Katar",
    },
    latitude: "25.50000000",
    longitude: "51.25000000",
    emoji: "🇶🇦",
    emojiU: "U+1F1F6 U+1F1E6",
    states: [
      {
        id: 3182,
        name: "Al Daayen",
        state_code: "ZA",
        latitude: "25.57845590",
        longitude: "51.48213870",
        type: null,
      },
      {
        id: 3183,
        name: "Al Khor",
        state_code: "KH",
        latitude: "25.68040780",
        longitude: "51.49685020",
        type: null,
      },
      {
        id: 3177,
        name: "Al Rayyan Municipality",
        state_code: "RA",
        latitude: "25.25225510",
        longitude: "51.43887130",
        type: null,
      },
      {
        id: 3179,
        name: "Al Wakrah",
        state_code: "WA",
        latitude: "25.16593140",
        longitude: "51.59755240",
        type: null,
      },
      {
        id: 3178,
        name: "Al-Shahaniya",
        state_code: "SH",
        latitude: "25.41063860",
        longitude: "51.18460250",
        type: null,
      },
      {
        id: 3181,
        name: "Doha",
        state_code: "DA",
        latitude: "25.28544730",
        longitude: "51.53103980",
        type: null,
      },
      {
        id: 3180,
        name: "Madinat ash Shamal",
        state_code: "MS",
        latitude: "26.11827430",
        longitude: "51.21572650",
        type: null,
      },
      {
        id: 3184,
        name: "Umm Salal Municipality",
        state_code: "US",
        latitude: "25.48752420",
        longitude: "51.39656800",
        type: null,
      },
    ],
  },
  {
    name: "Reunion",
    iso3: "REU",
    iso2: "RE",
    numeric_code: "638",
    phone_code: "262",
    capital: "Saint-Denis",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".re",
    native: "La Réunion",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Reunionese, Reunionnais",
    timezones: [
      {
        zoneName: "Indian/Reunion",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "RET",
        tzName: "Réunion Time",
      },
    ],
    translations: {
      kr: "레위니옹",
      "pt-BR": "Reunião",
      pt: "Reunião",
      nl: "Réunion",
      hr: "Réunion",
      fa: "رئونیون",
      de: "Réunion",
      es: "Reunión",
      fr: "Réunion",
      ja: "レユニオン",
      it: "Riunione",
      cn: "留尼汪岛",
      tr: "Réunion",
    },
    latitude: "-21.15000000",
    longitude: "55.50000000",
    emoji: "🇷🇪",
    emojiU: "U+1F1F7 U+1F1EA",
    states: [],
  },
  {
    name: "Romania",
    iso3: "ROU",
    iso2: "RO",
    numeric_code: "642",
    phone_code: "40",
    capital: "Bucharest",
    currency: "RON",
    currency_name: "Romanian leu",
    currency_symbol: "lei",
    tld: ".ro",
    native: "România",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Romanian",
    timezones: [
      {
        zoneName: "Europe/Bucharest",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "루마니아",
      "pt-BR": "Romênia",
      pt: "Roménia",
      nl: "Roemenië",
      hr: "Rumunjska",
      fa: "رومانی",
      de: "Rumänien",
      es: "Rumania",
      fr: "Roumanie",
      ja: "ルーマニア",
      it: "Romania",
      cn: "罗马尼亚",
      tr: "Romanya",
    },
    latitude: "46.00000000",
    longitude: "25.00000000",
    emoji: "🇷🇴",
    emojiU: "U+1F1F7 U+1F1F4",
    states: [
      {
        id: 4724,
        name: "Alba",
        state_code: "AB",
        latitude: "44.70091530",
        longitude: "8.03569110",
        type: null,
      },
      {
        id: 4739,
        name: "Arad County",
        state_code: "AR",
        latitude: "46.22836510",
        longitude: "21.65978190",
        type: null,
      },
      {
        id: 4722,
        name: "Arges",
        state_code: "AG",
        latitude: "45.07225270",
        longitude: "24.81427260",
        type: null,
      },
      {
        id: 4744,
        name: "Bacău County",
        state_code: "BC",
        latitude: "46.32581840",
        longitude: "26.66237800",
        type: null,
      },
      {
        id: 4723,
        name: "Bihor County",
        state_code: "BH",
        latitude: "47.01575160",
        longitude: "22.17226600",
        type: null,
      },
      {
        id: 4733,
        name: "Bistrița-Năsăud County",
        state_code: "BN",
        latitude: "47.24861070",
        longitude: "24.53228140",
        type: null,
      },
      {
        id: 4740,
        name: "Botoșani County",
        state_code: "BT",
        latitude: "47.89240420",
        longitude: "26.75917810",
        type: null,
      },
      {
        id: 4736,
        name: "Braila",
        state_code: "BR",
        latitude: "45.26524630",
        longitude: "27.95947140",
        type: null,
      },
      {
        id: 4759,
        name: "Brașov County",
        state_code: "BV",
        latitude: "45.77818440",
        longitude: "25.22258000",
        type: null,
      },
      {
        id: 4730,
        name: "Bucharest",
        state_code: "B",
        latitude: "44.42676740",
        longitude: "26.10253840",
        type: null,
      },
      {
        id: 4756,
        name: "Buzău County",
        state_code: "BZ",
        latitude: "45.33509120",
        longitude: "26.71075780",
        type: null,
      },
      {
        id: 4732,
        name: "Călărași County",
        state_code: "CL",
        latitude: "44.36587150",
        longitude: "26.75486070",
        type: null,
      },
      {
        id: 4753,
        name: "Caraș-Severin County",
        state_code: "CS",
        latitude: "45.11396460",
        longitude: "22.07409930",
        type: null,
      },
      {
        id: 4734,
        name: "Cluj County",
        state_code: "CJ",
        latitude: "46.79417970",
        longitude: "23.61214920",
        type: null,
      },
      {
        id: 4737,
        name: "Constanța County",
        state_code: "CT",
        latitude: "44.21298700",
        longitude: "28.25500550",
        type: null,
      },
      {
        id: 4754,
        name: "Covasna County",
        state_code: "CV",
        latitude: "45.94263470",
        longitude: "25.89189840",
        type: null,
      },
      {
        id: 4745,
        name: "Dâmbovița County",
        state_code: "DB",
        latitude: "44.92898930",
        longitude: "25.42538500",
        type: null,
      },
      {
        id: 4742,
        name: "Dolj County",
        state_code: "DJ",
        latitude: "44.16230220",
        longitude: "23.63250540",
        type: null,
      },
      {
        id: 4747,
        name: "Galați County",
        state_code: "GL",
        latitude: "45.78005690",
        longitude: "27.82515760",
        type: null,
      },
      {
        id: 4726,
        name: "Giurgiu County",
        state_code: "GR",
        latitude: "43.90370760",
        longitude: "25.96992650",
        type: null,
      },
      {
        id: 4750,
        name: "Gorj County",
        state_code: "GJ",
        latitude: "44.94855950",
        longitude: "23.24270790",
        type: null,
      },
      {
        id: 4749,
        name: "Harghita County",
        state_code: "HR",
        latitude: "46.49285070",
        longitude: "25.64566960",
        type: null,
      },
      {
        id: 4721,
        name: "Hunedoara County",
        state_code: "HD",
        latitude: "45.79363800",
        longitude: "22.99759930",
        type: null,
      },
      {
        id: 4743,
        name: "Ialomița County",
        state_code: "IL",
        latitude: "44.60313300",
        longitude: "27.37899140",
        type: null,
      },
      {
        id: 4735,
        name: "Iași County",
        state_code: "IS",
        latitude: "47.26796530",
        longitude: "27.21856620",
        type: null,
      },
      {
        id: 4725,
        name: "Ilfov County",
        state_code: "IF",
        latitude: "44.53554800",
        longitude: "26.23248860",
        type: null,
      },
      {
        id: 4760,
        name: "Maramureș County",
        state_code: "MM",
        latitude: "46.55699040",
        longitude: "24.67232150",
        type: null,
      },
      {
        id: 4751,
        name: "Mehedinți County",
        state_code: "MH",
        latitude: "44.55150530",
        longitude: "22.90441570",
        type: null,
      },
      {
        id: 4915,
        name: "Mureș County",
        state_code: "MS",
        latitude: "46.55699040",
        longitude: "24.67232150",
        type: null,
      },
      {
        id: 4731,
        name: "Neamț County",
        state_code: "NT",
        latitude: "46.97586850",
        longitude: "26.38187640",
        type: null,
      },
      {
        id: 4738,
        name: "Olt County",
        state_code: "OT",
        latitude: "44.20079700",
        longitude: "24.50229810",
        type: null,
      },
      {
        id: 4729,
        name: "Prahova County",
        state_code: "PH",
        latitude: "45.08919060",
        longitude: "26.08293130",
        type: null,
      },
      {
        id: 4741,
        name: "Sălaj County",
        state_code: "SJ",
        latitude: "47.20908130",
        longitude: "23.21219010",
        type: null,
      },
      {
        id: 4746,
        name: "Satu Mare County",
        state_code: "SM",
        latitude: "47.76689050",
        longitude: "22.92413770",
        type: null,
      },
      {
        id: 4755,
        name: "Sibiu County",
        state_code: "SB",
        latitude: "45.92691060",
        longitude: "24.22548070",
        type: null,
      },
      {
        id: 4720,
        name: "Suceava County",
        state_code: "SV",
        latitude: "47.55055480",
        longitude: "25.74106200",
        type: null,
      },
      {
        id: 4728,
        name: "Teleorman County",
        state_code: "TR",
        latitude: "44.01604910",
        longitude: "25.29866280",
        type: null,
      },
      {
        id: 4748,
        name: "Timiș County",
        state_code: "TM",
        latitude: "45.81389020",
        longitude: "21.33310550",
        type: null,
      },
      {
        id: 4727,
        name: "Tulcea County",
        state_code: "TL",
        latitude: "45.04505650",
        longitude: "29.03249120",
        type: null,
      },
      {
        id: 4757,
        name: "Vâlcea County",
        state_code: "VL",
        latitude: "45.07980510",
        longitude: "24.08352830",
        type: null,
      },
      {
        id: 4752,
        name: "Vaslui County",
        state_code: "VS",
        latitude: "46.46310590",
        longitude: "27.73980310",
        type: null,
      },
      {
        id: 4758,
        name: "Vrancea County",
        state_code: "VN",
        latitude: "45.81348760",
        longitude: "27.06575310",
        type: null,
      },
    ],
  },
  {
    name: "Russia",
    iso3: "RUS",
    iso2: "RU",
    numeric_code: "643",
    phone_code: "7",
    capital: "Moscow",
    currency: "RUB",
    currency_name: "Russian ruble",
    currency_symbol: "₽",
    tld: ".ru",
    native: "Россия",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Russian",
    timezones: [
      {
        zoneName: "Asia/Anadyr",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "ANAT",
        tzName: "Anadyr Time[4",
      },
      {
        zoneName: "Asia/Barnaul",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Chita",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Irkutsk",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "IRKT",
        tzName: "Irkutsk Time",
      },
      {
        zoneName: "Asia/Kamchatka",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "PETT",
        tzName: "Kamchatka Time",
      },
      {
        zoneName: "Asia/Khandyga",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Krasnoyarsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Magadan",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "MAGT",
        tzName: "Magadan Time",
      },
      {
        zoneName: "Asia/Novokuznetsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "KRAT",
        tzName: "Krasnoyarsk Time",
      },
      {
        zoneName: "Asia/Novosibirsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "NOVT",
        tzName: "Novosibirsk Time",
      },
      {
        zoneName: "Asia/Omsk",
        gmtOffset: 21600,
        gmtOffsetName: "UTC+06:00",
        abbreviation: "OMST",
        tzName: "Omsk Time",
      },
      {
        zoneName: "Asia/Sakhalin",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SAKT",
        tzName: "Sakhalin Island Time",
      },
      {
        zoneName: "Asia/Srednekolymsk",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SRET",
        tzName: "Srednekolymsk Time",
      },
      {
        zoneName: "Asia/Tomsk",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "MSD+3",
        tzName: "Moscow Daylight Time+3",
      },
      {
        zoneName: "Asia/Ust-Nera",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time",
      },
      {
        zoneName: "Asia/Vladivostok",
        gmtOffset: 36000,
        gmtOffsetName: "UTC+10:00",
        abbreviation: "VLAT",
        tzName: "Vladivostok Time",
      },
      {
        zoneName: "Asia/Yakutsk",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "YAKT",
        tzName: "Yakutsk Time",
      },
      {
        zoneName: "Asia/Yekaterinburg",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "YEKT",
        tzName: "Yekaterinburg Time",
      },
      {
        zoneName: "Europe/Astrakhan",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Kaliningrad",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Kirov",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Moscow",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Samara",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Saratov",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSD",
        tzName: "Moscow Daylight Time+4",
      },
      {
        zoneName: "Europe/Ulyanovsk",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SAMT",
        tzName: "Samara Time",
      },
      {
        zoneName: "Europe/Volgograd",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "MSK",
        tzName: "Moscow Standard Time",
      },
    ],
    translations: {
      kr: "러시아",
      "pt-BR": "Rússia",
      pt: "Rússia",
      nl: "Rusland",
      hr: "Rusija",
      fa: "روسیه",
      de: "Russland",
      es: "Rusia",
      fr: "Russie",
      ja: "ロシア連邦",
      it: "Russia",
      cn: "俄罗斯联邦",
      tr: "Rusya",
    },
    latitude: "60.00000000",
    longitude: "100.00000000",
    emoji: "🇷🇺",
    emojiU: "U+1F1F7 U+1F1FA",
    states: [
      {
        id: 1911,
        name: "Altai Krai",
        state_code: "ALT",
        latitude: "51.79362980",
        longitude: "82.67585960",
        type: null,
      },
      {
        id: 1876,
        name: "Altai Republic",
        state_code: "AL",
        latitude: "50.61819240",
        longitude: "86.21993080",
        type: null,
      },
      {
        id: 1858,
        name: "Amur Oblast",
        state_code: "AMU",
        latitude: "54.60350650",
        longitude: "127.48017210",
        type: null,
      },
      {
        id: 1849,
        name: "Arkhangelsk",
        state_code: "ARK",
        latitude: "64.54585490",
        longitude: "40.55057690",
        type: null,
      },
      {
        id: 1866,
        name: "Astrakhan Oblast",
        state_code: "AST",
        latitude: "46.13211660",
        longitude: "48.06101150",
        type: null,
      },
      {
        id: 1903,
        name: "Belgorod Oblast",
        state_code: "BEL",
        latitude: "50.71069260",
        longitude: "37.75333770",
        type: null,
      },
      {
        id: 1867,
        name: "Bryansk Oblast",
        state_code: "BRY",
        latitude: "53.04085990",
        longitude: "33.26909000",
        type: null,
      },
      {
        id: 1893,
        name: "Chechen Republic",
        state_code: "CE",
        latitude: "43.40233010",
        longitude: "45.71874680",
        type: null,
      },
      {
        id: 1845,
        name: "Chelyabinsk Oblast",
        state_code: "CHE",
        latitude: "54.43194220",
        longitude: "60.87889630",
        type: null,
      },
      {
        id: 1859,
        name: "Chukotka Autonomous Okrug",
        state_code: "CHU",
        latitude: "65.62983550",
        longitude: "171.69521590",
        type: null,
      },
      {
        id: 1914,
        name: "Chuvash Republic",
        state_code: "CU",
        latitude: "55.55959920",
        longitude: "46.92835350",
        type: null,
      },
      {
        id: 1880,
        name: "Irkutsk",
        state_code: "IRK",
        latitude: "52.28548340",
        longitude: "104.28902220",
        type: null,
      },
      {
        id: 1864,
        name: "Ivanovo Oblast",
        state_code: "IVA",
        latitude: "57.10568540",
        longitude: "41.48300840",
        type: null,
      },
      {
        id: 1835,
        name: "Jewish Autonomous Oblast",
        state_code: "YEV",
        latitude: "48.48081470",
        longitude: "131.76573670",
        type: null,
      },
      {
        id: 1892,
        name: "Kabardino-Balkar Republic",
        state_code: "KB",
        latitude: "43.39324690",
        longitude: "43.56284980",
        type: null,
      },
      {
        id: 1902,
        name: "Kaliningrad",
        state_code: "KGD",
        latitude: "54.71042640",
        longitude: "20.45221440",
        type: null,
      },
      {
        id: 1844,
        name: "Kaluga Oblast",
        state_code: "KLU",
        latitude: "54.38726660",
        longitude: "35.18890940",
        type: null,
      },
      {
        id: 1865,
        name: "Kamchatka Krai",
        state_code: "KAM",
        latitude: "61.43439810",
        longitude: "166.78841310",
        type: null,
      },
      {
        id: 1869,
        name: "Karachay-Cherkess Republic",
        state_code: "KC",
        latitude: "43.88451430",
        longitude: "41.73039390",
        type: null,
      },
      {
        id: 1897,
        name: "Kemerovo Oblast",
        state_code: "KEM",
        latitude: "54.75746480",
        longitude: "87.40552880",
        type: null,
      },
      {
        id: 1873,
        name: "Khabarovsk Krai",
        state_code: "KHA",
        latitude: "50.58884310",
        longitude: "135.00000000",
        type: null,
      },
      {
        id: 1838,
        name: "Khanty-Mansi Autonomous Okrug",
        state_code: "KHM",
        latitude: "62.22870620",
        longitude: "70.64100570",
        type: null,
      },
      {
        id: 1890,
        name: "Kirov Oblast",
        state_code: "KIR",
        latitude: "58.41985290",
        longitude: "50.20972480",
        type: null,
      },
      {
        id: 1899,
        name: "Komi Republic",
        state_code: "KO",
        latitude: "63.86305390",
        longitude: "54.83126900",
        type: null,
      },
      {
        id: 1910,
        name: "Kostroma Oblast",
        state_code: "KOS",
        latitude: "58.55010690",
        longitude: "43.95411020",
        type: null,
      },
      {
        id: 1891,
        name: "Krasnodar Krai",
        state_code: "KDA",
        latitude: "45.64152890",
        longitude: "39.70559770",
        type: null,
      },
      {
        id: 1840,
        name: "Krasnoyarsk Krai",
        state_code: "KYA",
        latitude: "64.24797580",
        longitude: "95.11041760",
        type: null,
      },
      {
        id: 1915,
        name: "Kurgan Oblast",
        state_code: "KGN",
        latitude: "55.44815480",
        longitude: "65.11809750",
        type: null,
      },
      {
        id: 1855,
        name: "Kursk Oblast",
        state_code: "KRS",
        latitude: "51.76340260",
        longitude: "35.38118120",
        type: null,
      },
      {
        id: 1896,
        name: "Leningrad Oblast",
        state_code: "LEN",
        latitude: "60.07932080",
        longitude: "31.89266450",
        type: null,
      },
      {
        id: 1889,
        name: "Lipetsk Oblast",
        state_code: "LIP",
        latitude: "52.52647020",
        longitude: "39.20322690",
        type: null,
      },
      {
        id: 1839,
        name: "Magadan Oblast",
        state_code: "MAG",
        latitude: "62.66434170",
        longitude: "153.91499100",
        type: null,
      },
      {
        id: 1870,
        name: "Mari El Republic",
        state_code: "ME",
        latitude: "56.43845700",
        longitude: "47.96077580",
        type: null,
      },
      {
        id: 1901,
        name: "Moscow",
        state_code: "MOW",
        latitude: "55.75582600",
        longitude: "37.61729990",
        type: null,
      },
      {
        id: 1882,
        name: "Moscow Oblast",
        state_code: "MOS",
        latitude: "55.34039600",
        longitude: "38.29176510",
        type: null,
      },
      {
        id: 1843,
        name: "Murmansk Oblast",
        state_code: "MUR",
        latitude: "67.84426740",
        longitude: "35.08841020",
        type: null,
      },
      {
        id: 1836,
        name: "Nenets Autonomous Okrug",
        state_code: "NEN",
        latitude: "67.60783370",
        longitude: "57.63383310",
        type: null,
      },
      {
        id: 1857,
        name: "Nizhny Novgorod Oblast",
        state_code: "NIZ",
        latitude: "55.79951590",
        longitude: "44.02967690",
        type: null,
      },
      {
        id: 1834,
        name: "Novgorod Oblast",
        state_code: "NGR",
        latitude: "58.24275520",
        longitude: "32.56651900",
        type: null,
      },
      {
        id: 1888,
        name: "Novosibirsk",
        state_code: "NVS",
        latitude: "54.98326930",
        longitude: "82.89638310",
        type: null,
      },
      {
        id: 1846,
        name: "Omsk Oblast",
        state_code: "OMS",
        latitude: "55.05546690",
        longitude: "73.31673420",
        type: null,
      },
      {
        id: 1886,
        name: "Orenburg Oblast",
        state_code: "ORE",
        latitude: "51.76340260",
        longitude: "54.61881880",
        type: null,
      },
      {
        id: 1908,
        name: "Oryol Oblast",
        state_code: "ORL",
        latitude: "52.78564140",
        longitude: "36.92423440",
        type: null,
      },
      {
        id: 1909,
        name: "Penza Oblast",
        state_code: "PNZ",
        latitude: "53.14121050",
        longitude: "44.09400480",
        type: null,
      },
      {
        id: 1871,
        name: "Perm Krai",
        state_code: "PER",
        latitude: "58.82319290",
        longitude: "56.58724810",
        type: null,
      },
      {
        id: 1833,
        name: "Primorsky Krai",
        state_code: "PRI",
        latitude: "45.05256410",
        longitude: "135.00000000",
        type: null,
      },
      {
        id: 1863,
        name: "Pskov Oblast",
        state_code: "PSK",
        latitude: "56.77085990",
        longitude: "29.09400900",
        type: null,
      },
      {
        id: 1852,
        name: "Republic of Adygea",
        state_code: "AD",
        latitude: "44.82291550",
        longitude: "40.17544630",
        type: null,
      },
      {
        id: 1854,
        name: "Republic of Bashkortostan",
        state_code: "BA",
        latitude: "54.23121720",
        longitude: "56.16452570",
        type: null,
      },
      {
        id: 1842,
        name: "Republic of Buryatia",
        state_code: "BU",
        latitude: "54.83311460",
        longitude: "112.40605300",
        type: null,
      },
      {
        id: 1850,
        name: "Republic of Dagestan",
        state_code: "DA",
        latitude: "42.14318860",
        longitude: "47.09497990",
        type: null,
      },
      {
        id: 1884,
        name: "Republic of Ingushetia",
        state_code: "IN",
        latitude: "43.40516980",
        longitude: "44.82029990",
        type: null,
      },
      {
        id: 1883,
        name: "Republic of Kalmykia",
        state_code: "KL",
        latitude: "46.18671760",
        longitude: "45.00000000",
        type: null,
      },
      {
        id: 1841,
        name: "Republic of Karelia",
        state_code: "KR",
        latitude: "63.15587020",
        longitude: "32.99055520",
        type: null,
      },
      {
        id: 1877,
        name: "Republic of Khakassia",
        state_code: "KK",
        latitude: "53.04522810",
        longitude: "90.39821450",
        type: null,
      },
      {
        id: 1898,
        name: "Republic of Mordovia",
        state_code: "MO",
        latitude: "54.23694410",
        longitude: "44.06839700",
        type: null,
      },
      {
        id: 1853,
        name: "Republic of North Ossetia-Alania",
        state_code: "SE",
        latitude: "43.04513020",
        longitude: "44.28709720",
        type: null,
      },
      {
        id: 1861,
        name: "Republic of Tatarstan",
        state_code: "TA",
        latitude: "55.18023640",
        longitude: "50.72639450",
        type: null,
      },
      {
        id: 1837,
        name: "Rostov Oblast",
        state_code: "ROS",
        latitude: "47.68532470",
        longitude: "41.82589520",
        type: null,
      },
      {
        id: 1905,
        name: "Ryazan Oblast",
        state_code: "RYA",
        latitude: "54.38759640",
        longitude: "41.25956610",
        type: null,
      },
      {
        id: 1879,
        name: "Saint Petersburg",
        state_code: "SPE",
        latitude: "59.93105840",
        longitude: "30.36090960",
        type: null,
      },
      {
        id: 1848,
        name: "Sakha Republic",
        state_code: "SA",
        latitude: "66.76134510",
        longitude: "124.12375300",
        type: null,
      },
      {
        id: 1875,
        name: "Sakhalin",
        state_code: "SAK",
        latitude: "50.69098480",
        longitude: "142.95056890",
        type: null,
      },
      {
        id: 1862,
        name: "Samara Oblast",
        state_code: "SAM",
        latitude: "53.41838390",
        longitude: "50.47255280",
        type: null,
      },
      {
        id: 1887,
        name: "Saratov Oblast",
        state_code: "SAR",
        latitude: "51.83692630",
        longitude: "46.75393970",
        type: null,
      },
      {
        id: 1885,
        name: "Smolensk Oblast",
        state_code: "SMO",
        latitude: "54.98829940",
        longitude: "32.66773780",
        type: null,
      },
      {
        id: 1868,
        name: "Stavropol Krai",
        state_code: "STA",
        latitude: "44.66809930",
        longitude: "43.52021400",
        type: null,
      },
      {
        id: 1894,
        name: "Sverdlovsk",
        state_code: "SVE",
        latitude: "56.84309930",
        longitude: "60.64540860",
        type: null,
      },
      {
        id: 1878,
        name: "Tambov Oblast",
        state_code: "TAM",
        latitude: "52.64165890",
        longitude: "41.42164510",
        type: null,
      },
      {
        id: 1872,
        name: "Tomsk Oblast",
        state_code: "TOM",
        latitude: "58.89698820",
        longitude: "82.67655000",
        type: null,
      },
      {
        id: 1895,
        name: "Tula Oblast",
        state_code: "TUL",
        latitude: "54.16376800",
        longitude: "37.56495070",
        type: null,
      },
      {
        id: 1900,
        name: "Tuva Republic",
        state_code: "TY",
        latitude: "51.88726690",
        longitude: "95.62601720",
        type: null,
      },
      {
        id: 1860,
        name: "Tver Oblast",
        state_code: "TVE",
        latitude: "57.00216540",
        longitude: "33.98531420",
        type: null,
      },
      {
        id: 1907,
        name: "Tyumen Oblast",
        state_code: "TYU",
        latitude: "56.96343870",
        longitude: "66.94827800",
        type: null,
      },
      {
        id: 1913,
        name: "Udmurt Republic",
        state_code: "UD",
        latitude: "57.06702180",
        longitude: "53.02779480",
        type: null,
      },
      {
        id: 1856,
        name: "Ulyanovsk Oblast",
        state_code: "ULY",
        latitude: "53.97933570",
        longitude: "47.77624250",
        type: null,
      },
      {
        id: 1881,
        name: "Vladimir Oblast",
        state_code: "VLA",
        latitude: "56.15534650",
        longitude: "40.59266850",
        type: null,
      },
      {
        id: 4916,
        name: "Volgograd Oblast",
        state_code: "VGG",
        latitude: "49.25873930",
        longitude: "39.81544630",
        type: null,
      },
      {
        id: 1874,
        name: "Vologda Oblast",
        state_code: "VLG",
        latitude: "59.87067110",
        longitude: "40.65554110",
        type: null,
      },
      {
        id: 1906,
        name: "Voronezh Oblast",
        state_code: "VOR",
        latitude: "50.85897130",
        longitude: "39.86443740",
        type: null,
      },
      {
        id: 1847,
        name: "Yamalo-Nenets Autonomous Okrug",
        state_code: "YAN",
        latitude: "66.06530570",
        longitude: "76.93451930",
        type: null,
      },
      {
        id: 1851,
        name: "Yaroslavl Oblast",
        state_code: "YAR",
        latitude: "57.89915230",
        longitude: "38.83886330",
        type: null,
      },
      {
        id: 1904,
        name: "Zabaykalsky Krai",
        state_code: "ZAB",
        latitude: "53.09287710",
        longitude: "116.96765610",
        type: null,
      },
    ],
  },
  {
    name: "Rwanda",
    iso3: "RWA",
    iso2: "RW",
    numeric_code: "646",
    phone_code: "250",
    capital: "Kigali",
    currency: "RWF",
    currency_name: "Rwandan franc",
    currency_symbol: "FRw",
    tld: ".rw",
    native: "Rwanda",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Rwandan",
    timezones: [
      {
        zoneName: "Africa/Kigali",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "르완다",
      "pt-BR": "Ruanda",
      pt: "Ruanda",
      nl: "Rwanda",
      hr: "Ruanda",
      fa: "رواندا",
      de: "Ruanda",
      es: "Ruanda",
      fr: "Rwanda",
      ja: "ルワンダ",
      it: "Ruanda",
      cn: "卢旺达",
      tr: "Ruanda",
    },
    latitude: "-2.00000000",
    longitude: "30.00000000",
    emoji: "🇷🇼",
    emojiU: "U+1F1F7 U+1F1FC",
    states: [
      {
        id: 261,
        name: "Eastern Province",
        state_code: "02",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 262,
        name: "Kigali district",
        state_code: "01",
        latitude: "-1.94407270",
        longitude: "30.06188510",
        type: null,
      },
      {
        id: 263,
        name: "Northern Province",
        state_code: "03",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 259,
        name: "Southern Province",
        state_code: "05",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 260,
        name: "Western Province",
        state_code: "04",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Saint Helena",
    iso3: "SHN",
    iso2: "SH",
    numeric_code: "654",
    phone_code: "290",
    capital: "Jamestown",
    currency: "SHP",
    currency_name: "Saint Helena pound",
    currency_symbol: "£",
    tld: ".sh",
    native: "Saint Helena",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Saint Helenian",
    timezones: [
      {
        zoneName: "Atlantic/St_Helena",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "세인트헬레나",
      "pt-BR": "Santa Helena",
      pt: "Santa Helena",
      nl: "Sint-Helena",
      hr: "Sveta Helena",
      fa: "سنت هلنا، اسنشن و تریستان دا کونا",
      de: "Sankt Helena",
      es: "Santa Helena",
      fr: "Sainte-Hélène",
      ja: "セントヘレナ・アセンションおよびトリスタンダクーニャ",
      it: "Sant'Elena",
      cn: "圣赫勒拿",
      tr: "Saint Helena",
    },
    latitude: "-15.95000000",
    longitude: "-5.70000000",
    emoji: "🇸🇭",
    emojiU: "U+1F1F8 U+1F1ED",
    states: [],
  },
  {
    name: "Saint Kitts and Nevis",
    iso3: "KNA",
    iso2: "KN",
    numeric_code: "659",
    phone_code: "+1-869",
    capital: "Basseterre",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".kn",
    native: "Saint Kitts and Nevis",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Kittitian or Nevisian",
    timezones: [
      {
        zoneName: "America/St_Kitts",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트키츠 네비스",
      "pt-BR": "São Cristóvão e Neves",
      pt: "São Cristóvão e Neves",
      nl: "Saint Kitts en Nevis",
      hr: "Sveti Kristof i Nevis",
      fa: "سنت کیتس و نویس",
      de: "St. Kitts und Nevis",
      es: "San Cristóbal y Nieves",
      fr: "Saint-Christophe-et-Niévès",
      ja: "セントクリストファー・ネイビス",
      it: "Saint Kitts e Nevis",
      cn: "圣基茨和尼维斯",
      tr: "Saint Kitts Ve Nevis",
    },
    latitude: "17.33333333",
    longitude: "-62.75000000",
    emoji: "🇰🇳",
    emojiU: "U+1F1F0 U+1F1F3",
    states: [
      {
        id: 3833,
        name: "Christ Church Nichola Town Parish",
        state_code: "01",
        latitude: "17.36048120",
        longitude: "-62.76178370",
        type: null,
      },
      {
        id: 3832,
        name: "Nevis",
        state_code: "N",
        latitude: "17.15535580",
        longitude: "-62.57960260",
        type: null,
      },
      {
        id: 3836,
        name: "Saint Anne Sandy Point Parish",
        state_code: "02",
        latitude: "17.37253330",
        longitude: "-62.84411330",
        type: null,
      },
      {
        id: 3837,
        name: "Saint George Gingerland Parish",
        state_code: "04",
        latitude: "17.12577590",
        longitude: "-62.56198110",
        type: null,
      },
      {
        id: 3835,
        name: "Saint James Windward Parish",
        state_code: "05",
        latitude: "17.17696330",
        longitude: "-62.57960260",
        type: null,
      },
      {
        id: 3845,
        name: "Saint John Capisterre Parish",
        state_code: "06",
        latitude: "17.38103410",
        longitude: "-62.79118330",
        type: null,
      },
      {
        id: 3840,
        name: "Saint John Figtree Parish",
        state_code: "07",
        latitude: "17.11557480",
        longitude: "-62.60310040",
        type: null,
      },
      {
        id: 3841,
        name: "Saint Kitts",
        state_code: "K",
        latitude: "17.34337960",
        longitude: "-62.75590430",
        type: null,
      },
      {
        id: 3844,
        name: "Saint Mary Cayon Parish",
        state_code: "08",
        latitude: "17.34620710",
        longitude: "-62.73826710",
        type: null,
      },
      {
        id: 3834,
        name: "Saint Paul Capisterre Parish",
        state_code: "09",
        latitude: "17.40166830",
        longitude: "-62.82573320",
        type: null,
      },
      {
        id: 3838,
        name: "Saint Paul Charlestown Parish",
        state_code: "10",
        latitude: "17.13462970",
        longitude: "-62.61338160",
        type: null,
      },
      {
        id: 3831,
        name: "Saint Peter Basseterre Parish",
        state_code: "11",
        latitude: "17.31029110",
        longitude: "-62.71475330",
        type: null,
      },
      {
        id: 3839,
        name: "Saint Thomas Lowland Parish",
        state_code: "12",
        latitude: "17.16505130",
        longitude: "-62.60897530",
        type: null,
      },
      {
        id: 3842,
        name: "Saint Thomas Middle Island Parish",
        state_code: "13",
        latitude: "17.33488130",
        longitude: "-62.80882510",
        type: null,
      },
      {
        id: 3843,
        name: "Trinity Palmetto Point Parish",
        state_code: "15",
        latitude: "17.30635190",
        longitude: "-62.76178370",
        type: null,
      },
    ],
  },
  {
    name: "Saint Lucia",
    iso3: "LCA",
    iso2: "LC",
    numeric_code: "662",
    phone_code: "+1-758",
    capital: "Castries",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".lc",
    native: "Saint Lucia",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint Lucian",
    timezones: [
      {
        zoneName: "America/St_Lucia",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트루시아",
      "pt-BR": "Santa Lúcia",
      pt: "Santa Lúcia",
      nl: "Saint Lucia",
      hr: "Sveta Lucija",
      fa: "سنت لوسیا",
      de: "Saint Lucia",
      es: "Santa Lucía",
      fr: "Saint-Lucie",
      ja: "セントルシア",
      it: "Santa Lucia",
      cn: "圣卢西亚",
      tr: "Saint Lucia",
    },
    latitude: "13.88333333",
    longitude: "-60.96666666",
    emoji: "🇱🇨",
    emojiU: "U+1F1F1 U+1F1E8",
    states: [
      {
        id: 3757,
        name: "Anse la Raye Quarter",
        state_code: "01",
        latitude: "13.94594240",
        longitude: "-61.03694680",
        type: null,
      },
      {
        id: 3761,
        name: "Canaries",
        state_code: "12",
        latitude: "28.29156370",
        longitude: "-16.62913040",
        type: null,
      },
      {
        id: 3758,
        name: "Castries Quarter",
        state_code: "02",
        latitude: "14.01010940",
        longitude: "-60.98746870",
        type: null,
      },
      {
        id: 3760,
        name: "Choiseul Quarter",
        state_code: "03",
        latitude: "13.77501540",
        longitude: "-61.04859100",
        type: null,
      },
      {
        id: 3767,
        name: "Dauphin Quarter",
        state_code: "04",
        latitude: "14.01033960",
        longitude: "-60.91909880",
        type: null,
      },
      {
        id: 3756,
        name: "Dennery Quarter",
        state_code: "05",
        latitude: "13.92673930",
        longitude: "-60.91909880",
        type: null,
      },
      {
        id: 3766,
        name: "Gros Islet Quarter",
        state_code: "06",
        latitude: "14.08435780",
        longitude: "-60.94527940",
        type: null,
      },
      {
        id: 3759,
        name: "Laborie Quarter",
        state_code: "07",
        latitude: "13.75227830",
        longitude: "-60.99328890",
        type: null,
      },
      {
        id: 3762,
        name: "Micoud Quarter",
        state_code: "08",
        latitude: "13.82118710",
        longitude: "-60.90019340",
        type: null,
      },
      {
        id: 3765,
        name: "Praslin Quarter",
        state_code: "09",
        latitude: "13.87523920",
        longitude: "-60.89946630",
        type: null,
      },
      {
        id: 3764,
        name: "Soufrière Quarter",
        state_code: "10",
        latitude: "13.85709860",
        longitude: "-61.05732480",
        type: null,
      },
      {
        id: 3763,
        name: "Vieux Fort Quarter",
        state_code: "11",
        latitude: "13.72060800",
        longitude: "-60.94964330",
        type: null,
      },
    ],
  },
  {
    name: "Saint Pierre and Miquelon",
    iso3: "SPM",
    iso2: "PM",
    numeric_code: "666",
    phone_code: "508",
    capital: "Saint-Pierre",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".pm",
    native: "Saint-Pierre-et-Miquelon",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "Saint-Pierrais or Miquelonnais",
    timezones: [
      {
        zoneName: "America/Miquelon",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "PMDT",
        tzName: "Pierre & Miquelon Daylight Time",
      },
    ],
    translations: {
      kr: "생피에르 미클롱",
      "pt-BR": "Saint-Pierre e Miquelon",
      pt: "São Pedro e Miquelon",
      nl: "Saint Pierre en Miquelon",
      hr: "Sveti Petar i Mikelon",
      fa: "سن پیر و میکلن",
      de: "Saint-Pierre und Miquelon",
      es: "San Pedro y Miquelón",
      fr: "Saint-Pierre-et-Miquelon",
      ja: "サンピエール島・ミクロン島",
      it: "Saint-Pierre e Miquelon",
      cn: "圣皮埃尔和密克隆",
      tr: "Saint Pierre Ve Miquelon",
    },
    latitude: "46.83333333",
    longitude: "-56.33333333",
    emoji: "🇵🇲",
    emojiU: "U+1F1F5 U+1F1F2",
    states: [],
  },
  {
    name: "Saint Vincent and the Grenadines",
    iso3: "VCT",
    iso2: "VC",
    numeric_code: "670",
    phone_code: "+1-784",
    capital: "Kingstown",
    currency: "XCD",
    currency_name: "Eastern Caribbean dollar",
    currency_symbol: "$",
    tld: ".vc",
    native: "Saint Vincent and the Grenadines",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint Vincentian, Vincentian",
    timezones: [
      {
        zoneName: "America/St_Vincent",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트빈센트 그레나딘",
      "pt-BR": "São Vicente e Granadinas",
      pt: "São Vicente e Granadinas",
      nl: "Saint Vincent en de Grenadines",
      hr: "Sveti Vincent i Grenadini",
      fa: "سنت وینسنت و گرنادین‌ها",
      de: "Saint Vincent und die Grenadinen",
      es: "San Vicente y Granadinas",
      fr: "Saint-Vincent-et-les-Grenadines",
      ja: "セントビンセントおよびグレナディーン諸島",
      it: "Saint Vincent e Grenadine",
      cn: "圣文森特和格林纳丁斯",
      tr: "Saint Vincent Ve Grenadinler",
    },
    latitude: "13.25000000",
    longitude: "-61.20000000",
    emoji: "🇻🇨",
    emojiU: "U+1F1FB U+1F1E8",
    states: [
      {
        id: 3389,
        name: "Charlotte Parish",
        state_code: "01",
        latitude: "13.21754510",
        longitude: "-61.16362440",
        type: null,
      },
      {
        id: 3388,
        name: "Grenadines Parish",
        state_code: "06",
        latitude: "13.01229650",
        longitude: "-61.22773010",
        type: null,
      },
      {
        id: 3386,
        name: "Saint Andrew Parish",
        state_code: "02",
        latitude: "43.02429990",
        longitude: "-81.20250000",
        type: null,
      },
      {
        id: 3387,
        name: "Saint David Parish",
        state_code: "03",
        latitude: "43.85230950",
        longitude: "-79.52366540",
        type: null,
      },
      {
        id: 3384,
        name: "Saint George Parish",
        state_code: "04",
        latitude: "42.95760900",
        longitude: "-81.32670500",
        type: null,
      },
      {
        id: 3385,
        name: "Saint Patrick Parish",
        state_code: "05",
        latitude: "39.75091860",
        longitude: "-94.84505560",
        type: null,
      },
    ],
  },
  {
    name: "Saint-Barthelemy",
    iso3: "BLM",
    iso2: "BL",
    numeric_code: "652",
    phone_code: "590",
    capital: "Gustavia",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".bl",
    native: "Saint-Barthélemy",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Barthelemois",
    timezones: [
      {
        zoneName: "America/St_Barthelemy",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "생바르텔레미",
      "pt-BR": "São Bartolomeu",
      pt: "São Bartolomeu",
      nl: "Saint Barthélemy",
      hr: "Saint Barthélemy",
      fa: "سن-بارتلمی",
      de: "Saint-Barthélemy",
      es: "San Bartolomé",
      fr: "Saint-Barthélemy",
      ja: "サン・バルテルミー",
      it: "Antille Francesi",
      cn: "圣巴泰勒米",
      tr: "Saint Barthélemy",
    },
    latitude: "18.50000000",
    longitude: "-63.41666666",
    emoji: "🇧🇱",
    emojiU: "U+1F1E7 U+1F1F1",
    states: [],
  },
  {
    name: "Saint-Martin (French part)",
    iso3: "MAF",
    iso2: "MF",
    numeric_code: "663",
    phone_code: "590",
    capital: "Marigot",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".mf",
    native: "Saint-Martin",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Saint-Martinoise",
    timezones: [
      {
        zoneName: "America/Marigot",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "세인트마틴 섬",
      "pt-BR": "Saint Martin",
      pt: "Ilha São Martinho",
      nl: "Saint-Martin",
      hr: "Sveti Martin",
      fa: "سینت مارتن",
      de: "Saint Martin",
      es: "Saint Martin",
      fr: "Saint-Martin",
      ja: "サン・マルタン（フランス領）",
      it: "Saint Martin",
      cn: "密克罗尼西亚",
      tr: "Saint Martin",
    },
    latitude: "18.08333333",
    longitude: "-63.95000000",
    emoji: "🇲🇫",
    emojiU: "U+1F1F2 U+1F1EB",
    states: [],
  },
  {
    name: "Samoa",
    iso3: "WSM",
    iso2: "WS",
    numeric_code: "882",
    phone_code: "685",
    capital: "Apia",
    currency: "WST",
    currency_name: "Samoan tālā",
    currency_symbol: "SAT",
    tld: ".ws",
    native: "Samoa",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Samoan",
    timezones: [
      {
        zoneName: "Pacific/Apia",
        gmtOffset: 50400,
        gmtOffsetName: "UTC+14:00",
        abbreviation: "WST",
        tzName: "West Samoa Time",
      },
    ],
    translations: {
      kr: "사모아",
      "pt-BR": "Samoa",
      pt: "Samoa",
      nl: "Samoa",
      hr: "Samoa",
      fa: "ساموآ",
      de: "Samoa",
      es: "Samoa",
      fr: "Samoa",
      ja: "サモア",
      it: "Samoa",
      cn: "萨摩亚",
      tr: "Samoa",
    },
    latitude: "-13.58333333",
    longitude: "-172.33333333",
    emoji: "🇼🇸",
    emojiU: "U+1F1FC U+1F1F8",
    states: [
      {
        id: 4763,
        name: "A'ana",
        state_code: "AA",
        latitude: "-13.89841800",
        longitude: "-171.97529950",
        type: null,
      },
      {
        id: 4761,
        name: "Aiga-i-le-Tai",
        state_code: "AL",
        latitude: "-13.85137910",
        longitude: "-172.03254010",
        type: null,
      },
      {
        id: 4765,
        name: "Atua",
        state_code: "AT",
        latitude: "-13.97870530",
        longitude: "-171.62542830",
        type: null,
      },
      {
        id: 4764,
        name: "Fa'asaleleaga",
        state_code: "FA",
        latitude: "-13.63076380",
        longitude: "-172.23659810",
        type: null,
      },
      {
        id: 4769,
        name: "Gaga'emauga",
        state_code: "GE",
        latitude: "-13.54286660",
        longitude: "-172.36688700",
        type: null,
      },
      {
        id: 4771,
        name: "Gaga'ifomauga",
        state_code: "GI",
        latitude: "-13.54680070",
        longitude: "-172.49693310",
        type: null,
      },
      {
        id: 4767,
        name: "Palauli",
        state_code: "PA",
        latitude: "-13.72945790",
        longitude: "-172.45361150",
        type: null,
      },
      {
        id: 4762,
        name: "Satupa'itea",
        state_code: "SA",
        latitude: "-13.65382140",
        longitude: "-172.61592710",
        type: null,
      },
      {
        id: 4770,
        name: "Tuamasaga",
        state_code: "TU",
        latitude: "-13.91635920",
        longitude: "-171.82243620",
        type: null,
      },
      {
        id: 4768,
        name: "Va'a-o-Fonoti",
        state_code: "VF",
        latitude: "-13.94709030",
        longitude: "-171.54318720",
        type: null,
      },
      {
        id: 4766,
        name: "Vaisigano",
        state_code: "VS",
        latitude: "-13.54138270",
        longitude: "-172.70233830",
        type: null,
      },
    ],
  },
  {
    name: "San Marino",
    iso3: "SMR",
    iso2: "SM",
    numeric_code: "674",
    phone_code: "378",
    capital: "San Marino",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".sm",
    native: "San Marino",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Sammarinese",
    timezones: [
      {
        zoneName: "Europe/San_Marino",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "산마리노",
      "pt-BR": "San Marino",
      pt: "São Marinho",
      nl: "San Marino",
      hr: "San Marino",
      fa: "سان مارینو",
      de: "San Marino",
      es: "San Marino",
      fr: "Saint-Marin",
      ja: "サンマリノ",
      it: "San Marino",
      cn: "圣马力诺",
      tr: "San Marino",
    },
    latitude: "43.76666666",
    longitude: "12.41666666",
    emoji: "🇸🇲",
    emojiU: "U+1F1F8 U+1F1F2",
    states: [
      {
        id: 59,
        name: "Acquaviva",
        state_code: "01",
        latitude: "41.86715970",
        longitude: "14.74694790",
        type: null,
      },
      {
        id: 61,
        name: "Borgo Maggiore",
        state_code: "06",
        latitude: "43.95748820",
        longitude: "12.45525460",
        type: null,
      },
      {
        id: 60,
        name: "Chiesanuova",
        state_code: "02",
        latitude: "45.42261720",
        longitude: "7.65038540",
        type: null,
      },
      {
        id: 64,
        name: "Domagnano",
        state_code: "03",
        latitude: "43.95019290",
        longitude: "12.46815370",
        type: null,
      },
      {
        id: 62,
        name: "Faetano",
        state_code: "04",
        latitude: "43.93489670",
        longitude: "12.48965540",
        type: null,
      },
      {
        id: 66,
        name: "Fiorentino",
        state_code: "05",
        latitude: "43.90783370",
        longitude: "12.45812090",
        type: null,
      },
      {
        id: 63,
        name: "Montegiardino",
        state_code: "08",
        latitude: "43.90529990",
        longitude: "12.48105420",
        type: null,
      },
      {
        id: 58,
        name: "San Marino",
        state_code: "07",
        latitude: "43.94236000",
        longitude: "12.45777700",
        type: null,
      },
      {
        id: 65,
        name: "Serravalle",
        state_code: "09",
        latitude: "44.72320840",
        longitude: "8.85740050",
        type: null,
      },
    ],
  },
  {
    name: "Sao Tome and Principe",
    iso3: "STP",
    iso2: "ST",
    numeric_code: "678",
    phone_code: "239",
    capital: "Sao Tome",
    currency: "STD",
    currency_name: "Dobra",
    currency_symbol: "Db",
    tld: ".st",
    native: "São Tomé e Príncipe",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "Sao Tomean",
    timezones: [
      {
        zoneName: "Africa/Sao_Tome",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "상투메 프린시페",
      "pt-BR": "São Tomé e Príncipe",
      pt: "São Tomé e Príncipe",
      nl: "Sao Tomé en Principe",
      hr: "Sveti Toma i Princip",
      fa: "کواترو دو فرویرو",
      de: "São Tomé und Príncipe",
      es: "Santo Tomé y Príncipe",
      fr: "Sao Tomé-et-Principe",
      ja: "サントメ・プリンシペ",
      it: "São Tomé e Príncipe",
      cn: "圣多美和普林西比",
      tr: "Sao Tome Ve Prinsipe",
    },
    latitude: "1.00000000",
    longitude: "7.00000000",
    emoji: "🇸🇹",
    emojiU: "U+1F1F8 U+1F1F9",
    states: [
      {
        id: 270,
        name: "Príncipe Province",
        state_code: "P",
        latitude: "1.61393810",
        longitude: "7.40569280",
        type: null,
      },
      {
        id: 271,
        name: "São Tomé Province",
        state_code: "S",
        latitude: "0.33019240",
        longitude: "6.73334300",
        type: null,
      },
    ],
  },
  {
    name: "Saudi Arabia",
    iso3: "SAU",
    iso2: "SA",
    numeric_code: "682",
    phone_code: "966",
    capital: "Riyadh",
    currency: "SAR",
    currency_name: "Saudi riyal",
    currency_symbol: "﷼",
    tld: ".sa",
    native: "المملكة العربية السعودية",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Saudi, Saudi Arabian",
    timezones: [
      {
        zoneName: "Asia/Riyadh",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "사우디아라비아",
      "pt-BR": "Arábia Saudita",
      pt: "Arábia Saudita",
      nl: "Saoedi-Arabië",
      hr: "Saudijska Arabija",
      fa: "عربستان سعودی",
      de: "Saudi-Arabien",
      es: "Arabia Saudí",
      fr: "Arabie Saoudite",
      ja: "サウジアラビア",
      it: "Arabia Saudita",
      cn: "沙特阿拉伯",
      tr: "Suudi Arabistan",
    },
    latitude: "25.00000000",
    longitude: "45.00000000",
    emoji: "🇸🇦",
    emojiU: "U+1F1F8 U+1F1E6",
    states: [
      {
        id: 2853,
        name: "'Asir",
        state_code: "14",
        latitude: "19.09690620",
        longitude: "42.86378750",
        type: "region",
      },
      {
        id: 2859,
        name: "Al Bahah",
        state_code: "11",
        latitude: "20.27227390",
        longitude: "41.44125100",
        type: "region",
      },
      {
        id: 2857,
        name: "Al Jawf",
        state_code: "12",
        latitude: "29.88735600",
        longitude: "39.32062410",
        type: "region",
      },
      {
        id: 2851,
        name: "Al Madinah",
        state_code: "03",
        latitude: "24.84039770",
        longitude: "39.32062410",
        type: "region",
      },
      {
        id: 2861,
        name: "Al-Qassim",
        state_code: "05",
        latitude: "26.20782600",
        longitude: "43.48373800",
        type: "region",
      },
      {
        id: 2856,
        name: "Eastern Province",
        state_code: "04",
        latitude: "24.04399320",
        longitude: "45.65892250",
        type: "region",
      },
      {
        id: 2855,
        name: "Ha'il",
        state_code: "06",
        latitude: "27.70761430",
        longitude: "41.91964710",
        type: "region",
      },
      {
        id: 2858,
        name: "Jizan",
        state_code: "09",
        latitude: "17.17381760",
        longitude: "42.70761070",
        type: "region",
      },
      {
        id: 2850,
        name: "Makkah",
        state_code: "02",
        latitude: "21.52355840",
        longitude: "41.91964710",
        type: "region",
      },
      {
        id: 2860,
        name: "Najran",
        state_code: "10",
        latitude: "18.35146640",
        longitude: "45.60071080",
        type: "region",
      },
      {
        id: 2854,
        name: "Northern Borders",
        state_code: "08",
        latitude: "30.07991620",
        longitude: "42.86378750",
        type: "region",
      },
      {
        id: 2849,
        name: "Riyadh",
        state_code: "01",
        latitude: "22.75543850",
        longitude: "46.20915470",
        type: "region",
      },
      {
        id: 2852,
        name: "Tabuk",
        state_code: "07",
        latitude: "28.24533350",
        longitude: "37.63866220",
        type: "region",
      },
    ],
  },
  {
    name: "Senegal",
    iso3: "SEN",
    iso2: "SN",
    numeric_code: "686",
    phone_code: "221",
    capital: "Dakar",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".sn",
    native: "Sénégal",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Senegalese",
    timezones: [
      {
        zoneName: "Africa/Dakar",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "세네갈",
      "pt-BR": "Senegal",
      pt: "Senegal",
      nl: "Senegal",
      hr: "Senegal",
      fa: "سنگال",
      de: "Senegal",
      es: "Senegal",
      fr: "Sénégal",
      ja: "セネガル",
      it: "Senegal",
      cn: "塞内加尔",
      tr: "Senegal",
    },
    latitude: "14.00000000",
    longitude: "-14.00000000",
    emoji: "🇸🇳",
    emojiU: "U+1F1F8 U+1F1F3",
    states: [
      {
        id: 473,
        name: "Dakar",
        state_code: "DK",
        latitude: "14.71667700",
        longitude: "-17.46768610",
        type: null,
      },
      {
        id: 480,
        name: "Diourbel Region",
        state_code: "DB",
        latitude: "14.72830850",
        longitude: "-16.25221430",
        type: null,
      },
      {
        id: 479,
        name: "Fatick",
        state_code: "FK",
        latitude: "14.33901670",
        longitude: "-16.41114250",
        type: null,
      },
      {
        id: 475,
        name: "Kaffrine",
        state_code: "KA",
        latitude: "14.10520200",
        longitude: "-15.54157550",
        type: null,
      },
      {
        id: 483,
        name: "Kaolack",
        state_code: "KL",
        latitude: "14.16520830",
        longitude: "-16.07577490",
        type: null,
      },
      {
        id: 481,
        name: "Kédougou",
        state_code: "KE",
        latitude: "12.56046070",
        longitude: "-12.17470770",
        type: null,
      },
      {
        id: 474,
        name: "Kolda",
        state_code: "KD",
        latitude: "12.91074950",
        longitude: "-14.95056710",
        type: null,
      },
      {
        id: 485,
        name: "Louga",
        state_code: "LG",
        latitude: "15.61417680",
        longitude: "-16.22868000",
        type: null,
      },
      {
        id: 476,
        name: "Matam",
        state_code: "MT",
        latitude: "15.66002250",
        longitude: "-13.25769060",
        type: null,
      },
      {
        id: 477,
        name: "Saint-Louis",
        state_code: "SL",
        latitude: "38.62700250",
        longitude: "-90.19940420",
        type: null,
      },
      {
        id: 482,
        name: "Sédhiou",
        state_code: "SE",
        latitude: "12.70460400",
        longitude: "-15.55623040",
        type: null,
      },
      {
        id: 486,
        name: "Tambacounda Region",
        state_code: "TC",
        latitude: "13.56190110",
        longitude: "-13.17403480",
        type: null,
      },
      {
        id: 484,
        name: "Thiès Region",
        state_code: "TH",
        latitude: "14.79100520",
        longitude: "-16.93586040",
        type: null,
      },
      {
        id: 478,
        name: "Ziguinchor",
        state_code: "ZG",
        latitude: "12.56414790",
        longitude: "-16.26398250",
        type: null,
      },
    ],
  },
  {
    name: "Serbia",
    iso3: "SRB",
    iso2: "RS",
    numeric_code: "688",
    phone_code: "381",
    capital: "Belgrade",
    currency: "RSD",
    currency_name: "Serbian dinar",
    currency_symbol: "din",
    tld: ".rs",
    native: "Србија",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Serbian",
    timezones: [
      {
        zoneName: "Europe/Belgrade",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "세르비아",
      "pt-BR": "Sérvia",
      pt: "Sérvia",
      nl: "Servië",
      hr: "Srbija",
      fa: "صربستان",
      de: "Serbien",
      es: "Serbia",
      fr: "Serbie",
      ja: "セルビア",
      it: "Serbia",
      cn: "塞尔维亚",
      tr: "Sirbistan",
    },
    latitude: "44.00000000",
    longitude: "21.00000000",
    emoji: "🇷🇸",
    emojiU: "U+1F1F7 U+1F1F8",
    states: [
      {
        id: 3728,
        name: "Belgrade",
        state_code: "00",
        latitude: "44.78656800",
        longitude: "20.44892160",
        type: null,
      },
      {
        id: 3717,
        name: "Bor District",
        state_code: "14",
        latitude: "44.06989180",
        longitude: "22.09850860",
        type: null,
      },
      {
        id: 3732,
        name: "Braničevo District",
        state_code: "11",
        latitude: "44.69822460",
        longitude: "21.54467750",
        type: null,
      },
      {
        id: 3716,
        name: "Central Banat District",
        state_code: "02",
        latitude: "45.47884850",
        longitude: "20.60825220",
        type: null,
      },
      {
        id: 3715,
        name: "Jablanica District",
        state_code: "23",
        latitude: "42.94815600",
        longitude: "21.81293210",
        type: null,
      },
      {
        id: 3724,
        name: "Kolubara District",
        state_code: "09",
        latitude: "44.35098110",
        longitude: "20.00043050",
        type: null,
      },
      {
        id: 3719,
        name: "Mačva District",
        state_code: "08",
        latitude: "44.59253140",
        longitude: "19.50822460",
        type: null,
      },
      {
        id: 3727,
        name: "Moravica District",
        state_code: "17",
        latitude: "43.84147000",
        longitude: "20.29049870",
        type: null,
      },
      {
        id: 3722,
        name: "Nišava District",
        state_code: "20",
        latitude: "43.37389020",
        longitude: "21.93223310",
        type: null,
      },
      {
        id: 3714,
        name: "North Bačka District",
        state_code: "01",
        latitude: "45.98033940",
        longitude: "19.59070010",
        type: null,
      },
      {
        id: 3736,
        name: "North Banat District",
        state_code: "03",
        latitude: "45.90683900",
        longitude: "19.99934170",
        type: null,
      },
      {
        id: 3721,
        name: "Pčinja District",
        state_code: "24",
        latitude: "42.58363620",
        longitude: "22.14302150",
        type: null,
      },
      {
        id: 3712,
        name: "Pirot District",
        state_code: "22",
        latitude: "43.08740360",
        longitude: "22.59830440",
        type: null,
      },
      {
        id: 3741,
        name: "Podunavlje District",
        state_code: "10",
        latitude: "44.47291560",
        longitude: "20.99014260",
        type: null,
      },
      {
        id: 3737,
        name: "Pomoravlje District",
        state_code: "13",
        latitude: "43.95913790",
        longitude: "21.27135300",
        type: null,
      },
      {
        id: 3720,
        name: "Rasina District",
        state_code: "19",
        latitude: "43.52635250",
        longitude: "21.15881780",
        type: null,
      },
      {
        id: 3725,
        name: "Raška District",
        state_code: "18",
        latitude: "43.33734610",
        longitude: "20.57340050",
        type: null,
      },
      {
        id: 3711,
        name: "South Bačka District",
        state_code: "06",
        latitude: "45.48903440",
        longitude: "19.69761870",
        type: null,
      },
      {
        id: 3713,
        name: "South Banat District",
        state_code: "04",
        latitude: "45.00274570",
        longitude: "21.05425090",
        type: null,
      },
      {
        id: 3740,
        name: "Srem District",
        state_code: "07",
        latitude: "45.00291710",
        longitude: "19.80137730",
        type: null,
      },
      {
        id: 3734,
        name: "Šumadija District",
        state_code: "12",
        latitude: "44.20506780",
        longitude: "20.78565650",
        type: null,
      },
      {
        id: 3718,
        name: "Toplica District",
        state_code: "21",
        latitude: "43.19065920",
        longitude: "21.34077620",
        type: null,
      },
      {
        id: 3733,
        name: "Vojvodina",
        state_code: "VO",
        latitude: "45.26086510",
        longitude: "19.83193380",
        type: null,
      },
      {
        id: 3726,
        name: "West Bačka District",
        state_code: "05",
        latitude: "45.73553850",
        longitude: "19.18973640",
        type: null,
      },
      {
        id: 3731,
        name: "Zaječar District",
        state_code: "15",
        latitude: "43.90150480",
        longitude: "22.27380110",
        type: null,
      },
      {
        id: 3729,
        name: "Zlatibor District",
        state_code: "16",
        latitude: "43.64541700",
        longitude: "19.71014550",
        type: null,
      },
    ],
  },
  {
    name: "Seychelles",
    iso3: "SYC",
    iso2: "SC",
    numeric_code: "690",
    phone_code: "248",
    capital: "Victoria",
    currency: "SCR",
    currency_name: "Seychellois rupee",
    currency_symbol: "SRe",
    tld: ".sc",
    native: "Seychelles",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Seychellois",
    timezones: [
      {
        zoneName: "Indian/Mahe",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "SCT",
        tzName: "Seychelles Time",
      },
    ],
    translations: {
      kr: "세이셸",
      "pt-BR": "Seicheles",
      pt: "Seicheles",
      nl: "Seychellen",
      hr: "Sejšeli",
      fa: "سیشل",
      de: "Seychellen",
      es: "Seychelles",
      fr: "Seychelles",
      ja: "セーシェル",
      it: "Seychelles",
      cn: "塞舌尔",
      tr: "Seyşeller",
    },
    latitude: "-4.58333333",
    longitude: "55.66666666",
    emoji: "🇸🇨",
    emojiU: "U+1F1F8 U+1F1E8",
    states: [
      {
        id: 513,
        name: "Anse Boileau",
        state_code: "02",
        latitude: "-4.70472680",
        longitude: "55.48593630",
        type: null,
      },
      {
        id: 502,
        name: "Anse Royale",
        state_code: "05",
        latitude: "-4.74079880",
        longitude: "55.50810120",
        type: null,
      },
      {
        id: 506,
        name: "Anse-aux-Pins",
        state_code: "01",
        latitude: "-4.69004430",
        longitude: "55.51502890",
        type: null,
      },
      {
        id: 508,
        name: "Au Cap",
        state_code: "04",
        latitude: "-4.70597230",
        longitude: "55.50810120",
        type: null,
      },
      {
        id: 497,
        name: "Baie Lazare",
        state_code: "06",
        latitude: "-4.74825250",
        longitude: "55.48593630",
        type: null,
      },
      {
        id: 514,
        name: "Baie Sainte Anne",
        state_code: "07",
        latitude: "47.05259000",
        longitude: "-64.95245790",
        type: null,
      },
      {
        id: 512,
        name: "Beau Vallon",
        state_code: "08",
        latitude: "-4.62109670",
        longitude: "55.42778020",
        type: null,
      },
      {
        id: 515,
        name: "Bel Air",
        state_code: "09",
        latitude: "34.10024550",
        longitude: "-118.45946300",
        type: null,
      },
      {
        id: 505,
        name: "Bel Ombre",
        state_code: "10",
        latitude: "-20.50100950",
        longitude: "57.42596240",
        type: null,
      },
      {
        id: 517,
        name: "Cascade",
        state_code: "11",
        latitude: "44.51628210",
        longitude: "-116.04179830",
        type: null,
      },
      {
        id: 503,
        name: "Glacis",
        state_code: "12",
        latitude: "47.11573030",
        longitude: "-70.30281830",
        type: null,
      },
      {
        id: 500,
        name: "Grand'Anse Mahé",
        state_code: "13",
        latitude: "-4.67739200",
        longitude: "55.46377700",
        type: null,
      },
      {
        id: 504,
        name: "Grand'Anse Praslin",
        state_code: "14",
        latitude: "-4.31762190",
        longitude: "55.70783630",
        type: null,
      },
      {
        id: 495,
        name: "La Digue",
        state_code: "15",
        latitude: "49.76669220",
        longitude: "-97.15466290",
        type: null,
      },
      {
        id: 516,
        name: "La Rivière Anglaise",
        state_code: "16",
        latitude: "-4.61061500",
        longitude: "55.45408410",
        type: null,
      },
      {
        id: 499,
        name: "Les Mamelles",
        state_code: "24",
        latitude: "38.82505050",
        longitude: "-90.48345170",
        type: null,
      },
      {
        id: 494,
        name: "Mont Buxton",
        state_code: "17",
        latitude: "-4.61666670",
        longitude: "55.44577680",
        type: null,
      },
      {
        id: 498,
        name: "Mont Fleuri",
        state_code: "18",
        latitude: "-4.63565430",
        longitude: "55.45546880",
        type: null,
      },
      {
        id: 511,
        name: "Plaisance",
        state_code: "19",
        latitude: "45.60709500",
        longitude: "-75.11427450",
        type: null,
      },
      {
        id: 510,
        name: "Pointe La Rue",
        state_code: "20",
        latitude: "-4.68048900",
        longitude: "55.51918570",
        type: null,
      },
      {
        id: 507,
        name: "Port Glaud",
        state_code: "21",
        latitude: "-4.64885230",
        longitude: "55.41947530",
        type: null,
      },
      {
        id: 501,
        name: "Roche Caiman",
        state_code: "25",
        latitude: "-4.63960280",
        longitude: "55.46793150",
        type: null,
      },
      {
        id: 496,
        name: "Saint Louis",
        state_code: "22",
        latitude: "38.62700250",
        longitude: "-90.19940420",
        type: null,
      },
      {
        id: 509,
        name: "Takamaka",
        state_code: "23",
        latitude: "37.96459170",
        longitude: "-1.22177270",
        type: null,
      },
    ],
  },
  {
    name: "Sierra Leone",
    iso3: "SLE",
    iso2: "SL",
    numeric_code: "694",
    phone_code: "232",
    capital: "Freetown",
    currency: "SLL",
    currency_name: "Sierra Leonean leone",
    currency_symbol: "Le",
    tld: ".sl",
    native: "Sierra Leone",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Sierra Leonean",
    timezones: [
      {
        zoneName: "Africa/Freetown",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "시에라리온",
      "pt-BR": "Serra Leoa",
      pt: "Serra Leoa",
      nl: "Sierra Leone",
      hr: "Sijera Leone",
      fa: "سیرالئون",
      de: "Sierra Leone",
      es: "Sierra Leone",
      fr: "Sierra Leone",
      ja: "シエラレオネ",
      it: "Sierra Leone",
      cn: "塞拉利昂",
      tr: "Sierra Leone",
    },
    latitude: "8.50000000",
    longitude: "-11.50000000",
    emoji: "🇸🇱",
    emojiU: "U+1F1F8 U+1F1F1",
    states: [
      {
        id: 914,
        name: "Eastern Province",
        state_code: "E",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 911,
        name: "Northern Province",
        state_code: "N",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 912,
        name: "Southern Province",
        state_code: "S",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 913,
        name: "Western Area",
        state_code: "W",
        latitude: "40.25459690",
        longitude: "-80.24554440",
        type: null,
      },
    ],
  },
  {
    name: "Singapore",
    iso3: "SGP",
    iso2: "SG",
    numeric_code: "702",
    phone_code: "65",
    capital: "Singapur",
    currency: "SGD",
    currency_name: "Singapore dollar",
    currency_symbol: "$",
    tld: ".sg",
    native: "Singapore",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Singaporean",
    timezones: [
      {
        zoneName: "Asia/Singapore",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "SGT",
        tzName: "Singapore Time",
      },
    ],
    translations: {
      kr: "싱가포르",
      "pt-BR": "Singapura",
      pt: "Singapura",
      nl: "Singapore",
      hr: "Singapur",
      fa: "سنگاپور",
      de: "Singapur",
      es: "Singapur",
      fr: "Singapour",
      ja: "シンガポール",
      it: "Singapore",
      cn: "新加坡",
      tr: "Singapur",
    },
    latitude: "1.36666666",
    longitude: "103.80000000",
    emoji: "🇸🇬",
    emojiU: "U+1F1F8 U+1F1EC",
    states: [
      {
        id: 4651,
        name: "Central Singapore",
        state_code: "01",
        latitude: "1.28840000",
        longitude: "103.85350000",
        type: "district",
      },
      {
        id: 4649,
        name: "North East",
        state_code: "02",
        latitude: "1.38240000",
        longitude: "103.89720000",
        type: "district",
      },
      {
        id: 4653,
        name: "North West",
        state_code: "03",
        latitude: "1.41800000",
        longitude: "103.82750000",
        type: "district",
      },
      {
        id: 4650,
        name: "South East",
        state_code: "04",
        latitude: "1.35710000",
        longitude: "103.70040000",
        type: "district",
      },
      {
        id: 4652,
        name: "South West",
        state_code: "05",
        latitude: "1.35710000",
        longitude: "103.94510000",
        type: "district",
      },
    ],
  },
  {
    name: "Sint Maarten (Dutch part)",
    iso3: "SXM",
    iso2: "SX",
    numeric_code: "534",
    phone_code: "1721",
    capital: "Philipsburg",
    currency: "ANG",
    currency_name: "Netherlands Antillean guilder",
    currency_symbol: "ƒ",
    tld: ".sx",
    native: "Sint Maarten",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Sint Maarten",
    timezones: [
      {
        zoneName: "America/Anguilla",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "신트마르턴",
      "pt-BR": "Sint Maarten",
      pt: "São Martinho",
      nl: "Sint Maarten",
      fa: "سینت مارتن",
      de: "Sint Maarten (niederl. Teil)",
      fr: "Saint Martin (partie néerlandaise)",
      it: "Saint Martin (parte olandese)",
      cn: "圣马丁岛（荷兰部分）",
      tr: "Sint Maarten",
    },
    latitude: "18.03333300",
    longitude: "-63.05000000",
    emoji: "🇸🇽",
    emojiU: "U+1F1F8 U+1F1FD",
    states: [],
  },
  {
    name: "Slovakia",
    iso3: "SVK",
    iso2: "SK",
    numeric_code: "703",
    phone_code: "421",
    capital: "Bratislava",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".sk",
    native: "Slovensko",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Slovak",
    timezones: [
      {
        zoneName: "Europe/Bratislava",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "슬로바키아",
      "pt-BR": "Eslováquia",
      pt: "Eslováquia",
      nl: "Slowakije",
      hr: "Slovačka",
      fa: "اسلواکی",
      de: "Slowakei",
      es: "República Eslovaca",
      fr: "Slovaquie",
      ja: "スロバキア",
      it: "Slovacchia",
      cn: "斯洛伐克",
      tr: "Slovakya",
    },
    latitude: "48.66666666",
    longitude: "19.50000000",
    emoji: "🇸🇰",
    emojiU: "U+1F1F8 U+1F1F0",
    states: [
      {
        id: 4352,
        name: "Banská Bystrica Region",
        state_code: "BC",
        latitude: "48.53124990",
        longitude: "19.38287400",
        type: null,
      },
      {
        id: 4356,
        name: "Bratislava Region",
        state_code: "BL",
        latitude: "48.31183040",
        longitude: "17.19732990",
        type: null,
      },
      {
        id: 4353,
        name: "Košice Region",
        state_code: "KI",
        latitude: "48.63757370",
        longitude: "21.08342250",
        type: null,
      },
      {
        id: 4357,
        name: "Nitra Region",
        state_code: "NI",
        latitude: "48.01437650",
        longitude: "18.54165040",
        type: null,
      },
      {
        id: 4354,
        name: "Prešov Region",
        state_code: "PV",
        latitude: "49.17167730",
        longitude: "21.37420010",
        type: null,
      },
      {
        id: 4358,
        name: "Trenčín Region",
        state_code: "TC",
        latitude: "48.80867580",
        longitude: "18.23240260",
        type: null,
      },
      {
        id: 4355,
        name: "Trnava Region",
        state_code: "TA",
        latitude: "48.39438980",
        longitude: "17.72162050",
        type: null,
      },
      {
        id: 4359,
        name: "Žilina Region",
        state_code: "ZI",
        latitude: "49.20314350",
        longitude: "19.36457330",
        type: null,
      },
    ],
  },
  {
    name: "Slovenia",
    iso3: "SVN",
    iso2: "SI",
    numeric_code: "705",
    phone_code: "386",
    capital: "Ljubljana",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".si",
    native: "Slovenija",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Slovenian, Slovene",
    timezones: [
      {
        zoneName: "Europe/Ljubljana",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "슬로베니아",
      "pt-BR": "Eslovênia",
      pt: "Eslovénia",
      nl: "Slovenië",
      hr: "Slovenija",
      fa: "اسلوونی",
      de: "Slowenien",
      es: "Eslovenia",
      fr: "Slovénie",
      ja: "スロベニア",
      it: "Slovenia",
      cn: "斯洛文尼亚",
      tr: "Slovenya",
    },
    latitude: "46.11666666",
    longitude: "14.81666666",
    emoji: "🇸🇮",
    emojiU: "U+1F1F8 U+1F1EE",
    states: [
      {
        id: 4183,
        name: "Ajdovščina Municipality",
        state_code: "001",
        latitude: "45.88707760",
        longitude: "13.90428180",
        type: null,
      },
      {
        id: 4326,
        name: "Ankaran Municipality",
        state_code: "213",
        latitude: "45.57845100",
        longitude: "13.73691740",
        type: null,
      },
      {
        id: 4301,
        name: "Beltinci Municipality",
        state_code: "002",
        latitude: "46.60791530",
        longitude: "16.23651270",
        type: null,
      },
      {
        id: 4166,
        name: "Benedikt Municipality",
        state_code: "148",
        latitude: "46.61558410",
        longitude: "15.89572810",
        type: null,
      },
      {
        id: 4179,
        name: "Bistrica ob Sotli Municipality",
        state_code: "149",
        latitude: "46.05655790",
        longitude: "15.66259470",
        type: null,
      },
      {
        id: 4202,
        name: "Bled Municipality",
        state_code: "003",
        latitude: "46.36832660",
        longitude: "14.11457980",
        type: null,
      },
      {
        id: 4278,
        name: "Bloke Municipality",
        state_code: "150",
        latitude: "45.77281410",
        longitude: "14.50634590",
        type: null,
      },
      {
        id: 4282,
        name: "Bohinj Municipality",
        state_code: "004",
        latitude: "46.30056520",
        longitude: "13.94271950",
        type: null,
      },
      {
        id: 4200,
        name: "Borovnica Municipality",
        state_code: "005",
        latitude: "45.90445250",
        longitude: "14.38241890",
        type: null,
      },
      {
        id: 4181,
        name: "Bovec Municipality",
        state_code: "006",
        latitude: "46.33804950",
        longitude: "13.55241740",
        type: null,
      },
      {
        id: 4141,
        name: "Braslovče Municipality",
        state_code: "151",
        latitude: "46.28361920",
        longitude: "15.04183200",
        type: null,
      },
      {
        id: 4240,
        name: "Brda Municipality",
        state_code: "007",
        latitude: "45.99756520",
        longitude: "13.52704740",
        type: null,
      },
      {
        id: 4215,
        name: "Brežice Municipality",
        state_code: "009",
        latitude: "45.90410960",
        longitude: "15.59436390",
        type: null,
      },
      {
        id: 4165,
        name: "Brezovica Municipality",
        state_code: "008",
        latitude: "45.95593510",
        longitude: "14.43499520",
        type: null,
      },
      {
        id: 4147,
        name: "Cankova Municipality",
        state_code: "152",
        latitude: "46.71823700",
        longitude: "16.01972220",
        type: null,
      },
      {
        id: 4310,
        name: "Cerklje na Gorenjskem Municipality",
        state_code: "012",
        latitude: "46.25170540",
        longitude: "14.48579790",
        type: null,
      },
      {
        id: 4162,
        name: "Cerknica Municipality",
        state_code: "013",
        latitude: "45.79662550",
        longitude: "14.39217700",
        type: null,
      },
      {
        id: 4178,
        name: "Cerkno Municipality",
        state_code: "014",
        latitude: "46.12884140",
        longitude: "13.98940270",
        type: null,
      },
      {
        id: 4176,
        name: "Cerkvenjak Municipality",
        state_code: "153",
        latitude: "46.56707110",
        longitude: "15.94297530",
        type: null,
      },
      {
        id: 4191,
        name: "City Municipality of Celje",
        state_code: "011",
        latitude: "46.23974950",
        longitude: "15.26770630",
        type: null,
      },
      {
        id: 4236,
        name: "City Municipality of Novo Mesto",
        state_code: "085",
        latitude: "45.80108240",
        longitude: "15.17100890",
        type: null,
      },
      {
        id: 4151,
        name: "Črenšovci Municipality",
        state_code: "015",
        latitude: "46.57200290",
        longitude: "16.28773460",
        type: null,
      },
      {
        id: 4232,
        name: "Črna na Koroškem Municipality",
        state_code: "016",
        latitude: "46.47045290",
        longitude: "14.84999980",
        type: null,
      },
      {
        id: 4291,
        name: "Črnomelj Municipality",
        state_code: "017",
        latitude: "45.53612250",
        longitude: "15.19441430",
        type: null,
      },
      {
        id: 4304,
        name: "Destrnik Municipality",
        state_code: "018",
        latitude: "46.49223680",
        longitude: "15.87779560",
        type: null,
      },
      {
        id: 4167,
        name: "Divača Municipality",
        state_code: "019",
        latitude: "45.68060690",
        longitude: "13.97203120",
        type: null,
      },
      {
        id: 4295,
        name: "Dobje Municipality",
        state_code: "154",
        latitude: "46.13700370",
        longitude: "15.39412900",
        type: null,
      },
      {
        id: 4216,
        name: "Dobrepolje Municipality",
        state_code: "020",
        latitude: "45.85249510",
        longitude: "14.70831090",
        type: null,
      },
      {
        id: 4252,
        name: "Dobrna Municipality",
        state_code: "155",
        latitude: "46.33561410",
        longitude: "15.22597320",
        type: null,
      },
      {
        id: 4308,
        name: "Dobrova–Polhov Gradec Municipality",
        state_code: "021",
        latitude: "46.06488960",
        longitude: "14.31681950",
        type: null,
      },
      {
        id: 4189,
        name: "Dobrovnik Municipality",
        state_code: "156",
        latitude: "46.65386620",
        longitude: "16.35065940",
        type: null,
      },
      {
        id: 4173,
        name: "Dol pri Ljubljani Municipality",
        state_code: "022",
        latitude: "46.08843860",
        longitude: "14.64247920",
        type: null,
      },
      {
        id: 4281,
        name: "Dolenjske Toplice Municipality",
        state_code: "157",
        latitude: "45.73457110",
        longitude: "15.01294930",
        type: null,
      },
      {
        id: 4159,
        name: "Domžale Municipality",
        state_code: "023",
        latitude: "46.14382690",
        longitude: "14.63752790",
        type: null,
      },
      {
        id: 4290,
        name: "Dornava Municipality",
        state_code: "024",
        latitude: "46.44435130",
        longitude: "15.98891590",
        type: null,
      },
      {
        id: 4345,
        name: "Dravograd Municipality",
        state_code: "025",
        latitude: "46.58921900",
        longitude: "15.02460210",
        type: null,
      },
      {
        id: 4213,
        name: "Duplek Municipality",
        state_code: "026",
        latitude: "46.50100160",
        longitude: "15.75463070",
        type: null,
      },
      {
        id: 4293,
        name: "Gorenja Vas–Poljane Municipality",
        state_code: "027",
        latitude: "46.11165820",
        longitude: "14.11493480",
        type: null,
      },
      {
        id: 4210,
        name: "Gorišnica Municipality",
        state_code: "028",
        latitude: "46.41202710",
        longitude: "16.01330890",
        type: null,
      },
      {
        id: 4284,
        name: "Gorje Municipality",
        state_code: "207",
        latitude: "46.38024580",
        longitude: "14.06853390",
        type: null,
      },
      {
        id: 4343,
        name: "Gornja Radgona Municipality",
        state_code: "029",
        latitude: "46.67670990",
        longitude: "15.99108470",
        type: null,
      },
      {
        id: 4339,
        name: "Gornji Grad Municipality",
        state_code: "030",
        latitude: "46.29617120",
        longitude: "14.80623470",
        type: null,
      },
      {
        id: 4271,
        name: "Gornji Petrovci Municipality",
        state_code: "031",
        latitude: "46.80371280",
        longitude: "16.21913790",
        type: null,
      },
      {
        id: 4217,
        name: "Grad Municipality",
        state_code: "158",
        latitude: "46.80873200",
        longitude: "16.10920600",
        type: null,
      },
      {
        id: 4336,
        name: "Grosuplje Municipality",
        state_code: "032",
        latitude: "45.95576450",
        longitude: "14.65889900",
        type: null,
      },
      {
        id: 4145,
        name: "Hajdina Municipality",
        state_code: "159",
        latitude: "46.41850140",
        longitude: "15.82447220",
        type: null,
      },
      {
        id: 4175,
        name: "Hoče–Slivnica Municipality",
        state_code: "160",
        latitude: "46.47785800",
        longitude: "15.64760050",
        type: null,
      },
      {
        id: 4327,
        name: "Hodoš Municipality",
        state_code: "161",
        latitude: "46.83141340",
        longitude: "16.32106800",
        type: null,
      },
      {
        id: 4193,
        name: "Horjul Municipality",
        state_code: "162",
        latitude: "46.02253780",
        longitude: "14.29862690",
        type: null,
      },
      {
        id: 4341,
        name: "Hrastnik Municipality",
        state_code: "034",
        latitude: "46.14172880",
        longitude: "15.08448940",
        type: null,
      },
      {
        id: 4321,
        name: "Hrpelje–Kozina Municipality",
        state_code: "035",
        latitude: "45.60911920",
        longitude: "13.93791480",
        type: null,
      },
      {
        id: 4152,
        name: "Idrija Municipality",
        state_code: "036",
        latitude: "46.00409390",
        longitude: "13.97754930",
        type: null,
      },
      {
        id: 4286,
        name: "Ig Municipality",
        state_code: "037",
        latitude: "45.95888680",
        longitude: "14.52705280",
        type: null,
      },
      {
        id: 4305,
        name: "Ivančna Gorica Municipality",
        state_code: "039",
        latitude: "45.93958410",
        longitude: "14.80476260",
        type: null,
      },
      {
        id: 4322,
        name: "Izola Municipality",
        state_code: "040",
        latitude: "45.53135570",
        longitude: "13.66646490",
        type: null,
      },
      {
        id: 4337,
        name: "Jesenice Municipality",
        state_code: "041",
        latitude: "46.43670470",
        longitude: "14.05260570",
        type: null,
      },
      {
        id: 4203,
        name: "Jezersko Municipality",
        state_code: "163",
        latitude: "46.39427940",
        longitude: "14.49855590",
        type: null,
      },
      {
        id: 4266,
        name: "Juršinci Municipality",
        state_code: "042",
        latitude: "46.48986510",
        longitude: "15.98092300",
        type: null,
      },
      {
        id: 4180,
        name: "Kamnik Municipality",
        state_code: "043",
        latitude: "46.22216660",
        longitude: "14.60707270",
        type: null,
      },
      {
        id: 4227,
        name: "Kanal ob Soči Municipality",
        state_code: "044",
        latitude: "46.06735300",
        longitude: "13.62033500",
        type: null,
      },
      {
        id: 4150,
        name: "Kidričevo Municipality",
        state_code: "045",
        latitude: "46.39575720",
        longitude: "15.79259060",
        type: null,
      },
      {
        id: 4243,
        name: "Kobarid Municipality",
        state_code: "046",
        latitude: "46.24569710",
        longitude: "13.57869490",
        type: null,
      },
      {
        id: 4325,
        name: "Kobilje Municipality",
        state_code: "047",
        latitude: "46.68518000",
        longitude: "16.39367190",
        type: null,
      },
      {
        id: 4335,
        name: "Kočevje Municipality",
        state_code: "048",
        latitude: "45.64280000",
        longitude: "14.86158380",
        type: null,
      },
      {
        id: 4315,
        name: "Komen Municipality",
        state_code: "049",
        latitude: "45.81752350",
        longitude: "13.74827110",
        type: null,
      },
      {
        id: 4283,
        name: "Komenda Municipality",
        state_code: "164",
        latitude: "46.20648800",
        longitude: "14.53824990",
        type: null,
      },
      {
        id: 4319,
        name: "Koper City Municipality",
        state_code: "050",
        latitude: "45.54805900",
        longitude: "13.73018770",
        type: null,
      },
      {
        id: 4254,
        name: "Kostanjevica na Krki Municipality",
        state_code: "197",
        latitude: "45.83166380",
        longitude: "15.44119060",
        type: null,
      },
      {
        id: 4331,
        name: "Kostel Municipality",
        state_code: "165",
        latitude: "45.49282550",
        longitude: "14.87082350",
        type: null,
      },
      {
        id: 4186,
        name: "Kozje Municipality",
        state_code: "051",
        latitude: "46.07332110",
        longitude: "15.55967190",
        type: null,
      },
      {
        id: 4287,
        name: "Kranj City Municipality",
        state_code: "052",
        latitude: "46.25850210",
        longitude: "14.35435690",
        type: null,
      },
      {
        id: 4340,
        name: "Kranjska Gora Municipality",
        state_code: "053",
        latitude: "46.48452930",
        longitude: "13.78571450",
        type: null,
      },
      {
        id: 4238,
        name: "Križevci Municipality",
        state_code: "166",
        latitude: "46.57018210",
        longitude: "16.10926530",
        type: null,
      },
      {
        id: 4197,
        name: "Kungota",
        state_code: "055",
        latitude: "46.64187930",
        longitude: "15.60362880",
        type: null,
      },
      {
        id: 4211,
        name: "Kuzma Municipality",
        state_code: "056",
        latitude: "46.83510380",
        longitude: "16.08071000",
        type: null,
      },
      {
        id: 4338,
        name: "Laško Municipality",
        state_code: "057",
        latitude: "46.15422360",
        longitude: "15.23614910",
        type: null,
      },
      {
        id: 4142,
        name: "Lenart Municipality",
        state_code: "058",
        latitude: "46.58344240",
        longitude: "15.82621250",
        type: null,
      },
      {
        id: 4225,
        name: "Lendava Municipality",
        state_code: "059",
        latitude: "46.55134830",
        longitude: "16.44198390",
        type: null,
      },
      {
        id: 4347,
        name: "Litija Municipality",
        state_code: "060",
        latitude: "46.05732260",
        longitude: "14.83096360",
        type: null,
      },
      {
        id: 4270,
        name: "Ljubljana City Municipality",
        state_code: "061",
        latitude: "46.05694650",
        longitude: "14.50575150",
        type: null,
      },
      {
        id: 4294,
        name: "Ljubno Municipality",
        state_code: "062",
        latitude: "46.34431250",
        longitude: "14.83354920",
        type: null,
      },
      {
        id: 4351,
        name: "Ljutomer Municipality",
        state_code: "063",
        latitude: "46.51908480",
        longitude: "16.18932160",
        type: null,
      },
      {
        id: 4306,
        name: "Log–Dragomer Municipality",
        state_code: "208",
        latitude: "46.01787470",
        longitude: "14.36877670",
        type: null,
      },
      {
        id: 4350,
        name: "Logatec Municipality",
        state_code: "064",
        latitude: "45.91761100",
        longitude: "14.23514510",
        type: null,
      },
      {
        id: 4174,
        name: "Loška Dolina Municipality",
        state_code: "065",
        latitude: "45.64779080",
        longitude: "14.49731470",
        type: null,
      },
      {
        id: 4158,
        name: "Loški Potok Municipality",
        state_code: "066",
        latitude: "45.69096370",
        longitude: "14.59859700",
        type: null,
      },
      {
        id: 4156,
        name: "Lovrenc na Pohorju Municipality",
        state_code: "167",
        latitude: "46.54196380",
        longitude: "15.40004430",
        type: null,
      },
      {
        id: 4219,
        name: "Luče Municipality",
        state_code: "067",
        latitude: "46.35449250",
        longitude: "14.74715040",
        type: null,
      },
      {
        id: 4302,
        name: "Lukovica Municipality",
        state_code: "068",
        latitude: "46.16962930",
        longitude: "14.69072590",
        type: null,
      },
      {
        id: 4157,
        name: "Majšperk Municipality",
        state_code: "069",
        latitude: "46.35030190",
        longitude: "15.73405950",
        type: null,
      },
      {
        id: 4224,
        name: "Makole Municipality",
        state_code: "198",
        latitude: "46.31686970",
        longitude: "15.66641260",
        type: null,
      },
      {
        id: 4242,
        name: "Maribor City Municipality",
        state_code: "070",
        latitude: "46.55064960",
        longitude: "15.62054390",
        type: null,
      },
      {
        id: 4244,
        name: "Markovci Municipality",
        state_code: "168",
        latitude: "46.38793090",
        longitude: "15.95860140",
        type: null,
      },
      {
        id: 4349,
        name: "Medvode Municipality",
        state_code: "071",
        latitude: "46.14190800",
        longitude: "14.40325960",
        type: null,
      },
      {
        id: 4348,
        name: "Mengeš Municipality",
        state_code: "072",
        latitude: "46.16591220",
        longitude: "14.57196940",
        type: null,
      },
      {
        id: 4323,
        name: "Metlika Municipality",
        state_code: "073",
        latitude: "45.64807150",
        longitude: "15.31778380",
        type: null,
      },
      {
        id: 4265,
        name: "Mežica Municipality",
        state_code: "074",
        latitude: "46.52150270",
        longitude: "14.85213400",
        type: null,
      },
      {
        id: 4223,
        name: "Miklavž na Dravskem Polju Municipality",
        state_code: "169",
        latitude: "46.50826280",
        longitude: "15.69520650",
        type: null,
      },
      {
        id: 4220,
        name: "Miren–Kostanjevica Municipality",
        state_code: "075",
        latitude: "45.84360290",
        longitude: "13.62766470",
        type: null,
      },
      {
        id: 4298,
        name: "Mirna Municipality",
        state_code: "212",
        latitude: "45.95156470",
        longitude: "15.06209770",
        type: null,
      },
      {
        id: 4237,
        name: "Mirna Peč Municipality",
        state_code: "170",
        latitude: "45.84815740",
        longitude: "15.08794500",
        type: null,
      },
      {
        id: 4212,
        name: "Mislinja Municipality",
        state_code: "076",
        latitude: "46.44294030",
        longitude: "15.19876780",
        type: null,
      },
      {
        id: 4297,
        name: "Mokronog–Trebelno Municipality",
        state_code: "199",
        latitude: "45.90885290",
        longitude: "15.15967360",
        type: null,
      },
      {
        id: 4168,
        name: "Moravče Municipality",
        state_code: "077",
        latitude: "46.13627810",
        longitude: "14.74600100",
        type: null,
      },
      {
        id: 4218,
        name: "Moravske Toplice Municipality",
        state_code: "078",
        latitude: "46.68569320",
        longitude: "16.22245820",
        type: null,
      },
      {
        id: 4190,
        name: "Mozirje Municipality",
        state_code: "079",
        latitude: "46.33943500",
        longitude: "14.96024130",
        type: null,
      },
      {
        id: 4318,
        name: "Municipality of Apače",
        state_code: "195",
        latitude: "46.69746790",
        longitude: "15.91025340",
        type: null,
      },
      {
        id: 4309,
        name: "Municipality of Cirkulane",
        state_code: "196",
        latitude: "46.32983220",
        longitude: "15.99806660",
        type: null,
      },
      {
        id: 4344,
        name: "Municipality of Ilirska Bistrica",
        state_code: "038",
        latitude: "45.57913230",
        longitude: "14.28097290",
        type: null,
      },
      {
        id: 4314,
        name: "Municipality of Krško",
        state_code: "054",
        latitude: "45.95896090",
        longitude: "15.49235550",
        type: null,
      },
      {
        id: 4187,
        name: "Municipality of Škofljica",
        state_code: "123",
        latitude: "45.98409620",
        longitude: "14.57466260",
        type: null,
      },
      {
        id: 4313,
        name: "Murska Sobota City Municipality",
        state_code: "080",
        latitude: "46.64321470",
        longitude: "16.15157540",
        type: null,
      },
      {
        id: 4208,
        name: "Muta Municipality",
        state_code: "081",
        latitude: "46.60973660",
        longitude: "15.16299950",
        type: null,
      },
      {
        id: 4177,
        name: "Naklo Municipality",
        state_code: "082",
        latitude: "46.27186630",
        longitude: "14.31569320",
        type: null,
      },
      {
        id: 4329,
        name: "Nazarje Municipality",
        state_code: "083",
        latitude: "46.28217410",
        longitude: "14.92256290",
        type: null,
      },
      {
        id: 4205,
        name: "Nova Gorica City Municipality",
        state_code: "084",
        latitude: "45.97627600",
        longitude: "13.73088810",
        type: null,
      },
      {
        id: 4320,
        name: "Odranci Municipality",
        state_code: "086",
        latitude: "46.59010170",
        longitude: "16.27881650",
        type: null,
      },
      {
        id: 4143,
        name: "Oplotnica",
        state_code: "171",
        latitude: "46.38716300",
        longitude: "15.44581310",
        type: null,
      },
      {
        id: 4221,
        name: "Ormož Municipality",
        state_code: "087",
        latitude: "46.43533330",
        longitude: "16.15437400",
        type: null,
      },
      {
        id: 4199,
        name: "Osilnica Municipality",
        state_code: "088",
        latitude: "45.54184670",
        longitude: "14.71563030",
        type: null,
      },
      {
        id: 4172,
        name: "Pesnica Municipality",
        state_code: "089",
        latitude: "46.60887550",
        longitude: "15.67570510",
        type: null,
      },
      {
        id: 4201,
        name: "Piran Municipality",
        state_code: "090",
        latitude: "45.52888560",
        longitude: "13.56807350",
        type: null,
      },
      {
        id: 4184,
        name: "Pivka Municipality",
        state_code: "091",
        latitude: "45.67892960",
        longitude: "14.25426890",
        type: null,
      },
      {
        id: 4146,
        name: "Podčetrtek Municipality",
        state_code: "092",
        latitude: "46.17395420",
        longitude: "15.60138160",
        type: null,
      },
      {
        id: 4161,
        name: "Podlehnik Municipality",
        state_code: "172",
        latitude: "46.33107820",
        longitude: "15.87858360",
        type: null,
      },
      {
        id: 4234,
        name: "Podvelka Municipality",
        state_code: "093",
        latitude: "46.62219520",
        longitude: "15.38899220",
        type: null,
      },
      {
        id: 4239,
        name: "Poljčane Municipality",
        state_code: "200",
        latitude: "46.31398530",
        longitude: "15.57847910",
        type: null,
      },
      {
        id: 4272,
        name: "Polzela Municipality",
        state_code: "173",
        latitude: "46.28089700",
        longitude: "15.07373210",
        type: null,
      },
      {
        id: 4330,
        name: "Postojna Municipality",
        state_code: "094",
        latitude: "45.77493900",
        longitude: "14.21342630",
        type: null,
      },
      {
        id: 4188,
        name: "Prebold Municipality",
        state_code: "174",
        latitude: "46.23591360",
        longitude: "15.09369120",
        type: null,
      },
      {
        id: 4303,
        name: "Preddvor Municipality",
        state_code: "095",
        latitude: "46.30171390",
        longitude: "14.42181650",
        type: null,
      },
      {
        id: 4274,
        name: "Prevalje Municipality",
        state_code: "175",
        latitude: "46.56211460",
        longitude: "14.88478610",
        type: null,
      },
      {
        id: 4228,
        name: "Ptuj City Municipality",
        state_code: "096",
        latitude: "46.41995350",
        longitude: "15.86968840",
        type: null,
      },
      {
        id: 4288,
        name: "Puconci Municipality",
        state_code: "097",
        latitude: "46.72004180",
        longitude: "16.09977920",
        type: null,
      },
      {
        id: 4204,
        name: "Rače–Fram Municipality",
        state_code: "098",
        latitude: "46.45420830",
        longitude: "15.63294670",
        type: null,
      },
      {
        id: 4195,
        name: "Radeče Municipality",
        state_code: "099",
        latitude: "46.06669540",
        longitude: "15.18204380",
        type: null,
      },
      {
        id: 4292,
        name: "Radenci Municipality",
        state_code: "100",
        latitude: "46.62311210",
        longitude: "16.05069030",
        type: null,
      },
      {
        id: 4275,
        name: "Radlje ob Dravi Municipality",
        state_code: "101",
        latitude: "46.61357320",
        longitude: "15.23544380",
        type: null,
      },
      {
        id: 4231,
        name: "Radovljica Municipality",
        state_code: "102",
        latitude: "46.33558270",
        longitude: "14.20945340",
        type: null,
      },
      {
        id: 4155,
        name: "Ravne na Koroškem Municipality",
        state_code: "103",
        latitude: "46.55211940",
        longitude: "14.95990840",
        type: null,
      },
      {
        id: 4206,
        name: "Razkrižje Municipality",
        state_code: "176",
        latitude: "46.52263390",
        longitude: "16.26686380",
        type: null,
      },
      {
        id: 4160,
        name: "Rečica ob Savinji Municipality",
        state_code: "209",
        latitude: "46.32337900",
        longitude: "14.92236700",
        type: null,
      },
      {
        id: 4253,
        name: "Renče–Vogrsko Municipality",
        state_code: "201",
        latitude: "45.89546170",
        longitude: "13.67856730",
        type: null,
      },
      {
        id: 4235,
        name: "Ribnica Municipality",
        state_code: "104",
        latitude: "45.74003030",
        longitude: "14.72657820",
        type: null,
      },
      {
        id: 4207,
        name: "Ribnica na Pohorju Municipality",
        state_code: "177",
        latitude: "46.53561450",
        longitude: "15.26745380",
        type: null,
      },
      {
        id: 4233,
        name: "Rogaška Slatina Municipality",
        state_code: "106",
        latitude: "46.24539730",
        longitude: "15.62650140",
        type: null,
      },
      {
        id: 4264,
        name: "Rogašovci Municipality",
        state_code: "105",
        latitude: "46.80557850",
        longitude: "16.03452370",
        type: null,
      },
      {
        id: 4209,
        name: "Rogatec Municipality",
        state_code: "107",
        latitude: "46.22866260",
        longitude: "15.69913380",
        type: null,
      },
      {
        id: 4280,
        name: "Ruše Municipality",
        state_code: "108",
        latitude: "46.52062650",
        longitude: "15.48178690",
        type: null,
      },
      {
        id: 4222,
        name: "Šalovci Municipality",
        state_code: "033",
        latitude: "46.85335680",
        longitude: "16.25917910",
        type: null,
      },
      {
        id: 4230,
        name: "Selnica ob Dravi Municipality",
        state_code: "178",
        latitude: "46.55139180",
        longitude: "15.49294100",
        type: null,
      },
      {
        id: 4346,
        name: "Semič Municipality",
        state_code: "109",
        latitude: "45.65205340",
        longitude: "15.18207010",
        type: null,
      },
      {
        id: 4317,
        name: "Šempeter–Vrtojba Municipality",
        state_code: "183",
        latitude: "45.92900950",
        longitude: "13.64155940",
        type: null,
      },
      {
        id: 4299,
        name: "Šenčur Municipality",
        state_code: "117",
        latitude: "46.24336990",
        longitude: "14.41922230",
        type: null,
      },
      {
        id: 4324,
        name: "Šentilj Municipality",
        state_code: "118",
        latitude: "46.68628390",
        longitude: "15.71035670",
        type: null,
      },
      {
        id: 4241,
        name: "Šentjernej Municipality",
        state_code: "119",
        latitude: "45.84341300",
        longitude: "15.33783120",
        type: null,
      },
      {
        id: 4171,
        name: "Šentjur Municipality",
        state_code: "120",
        latitude: "46.26543390",
        longitude: "15.40800000",
        type: null,
      },
      {
        id: 4311,
        name: "Šentrupert Municipality",
        state_code: "211",
        latitude: "45.98731420",
        longitude: "15.08297830",
        type: null,
      },
      {
        id: 4268,
        name: "Sevnica Municipality",
        state_code: "110",
        latitude: "46.00703170",
        longitude: "15.30456790",
        type: null,
      },
      {
        id: 4149,
        name: "Sežana Municipality",
        state_code: "111",
        latitude: "45.72751090",
        longitude: "13.86619310",
        type: null,
      },
      {
        id: 4170,
        name: "Škocjan Municipality",
        state_code: "121",
        latitude: "45.91754540",
        longitude: "15.31017360",
        type: null,
      },
      {
        id: 4316,
        name: "Škofja Loka Municipality",
        state_code: "122",
        latitude: "46.14098440",
        longitude: "14.28118730",
        type: null,
      },
      {
        id: 4169,
        name: "Slovenj Gradec City Municipality",
        state_code: "112",
        latitude: "46.48777180",
        longitude: "15.07294780",
        type: null,
      },
      {
        id: 4332,
        name: "Slovenska Bistrica Municipality",
        state_code: "113",
        latitude: "46.39198130",
        longitude: "15.57278690",
        type: null,
      },
      {
        id: 4198,
        name: "Slovenske Konjice Municipality",
        state_code: "114",
        latitude: "46.33691910",
        longitude: "15.42147080",
        type: null,
      },
      {
        id: 4285,
        name: "Šmarje pri Jelšah Municipality",
        state_code: "124",
        latitude: "46.22870250",
        longitude: "15.51903530",
        type: null,
      },
      {
        id: 4289,
        name: "Šmarješke Toplice Municipality",
        state_code: "206",
        latitude: "45.86803770",
        longitude: "15.23474220",
        type: null,
      },
      {
        id: 4296,
        name: "Šmartno ob Paki Municipality",
        state_code: "125",
        latitude: "46.32903720",
        longitude: "15.03339370",
        type: null,
      },
      {
        id: 4279,
        name: "Šmartno pri Litiji Municipality",
        state_code: "194",
        latitude: "46.04549710",
        longitude: "14.84101330",
        type: null,
      },
      {
        id: 4277,
        name: "Sodražica Municipality",
        state_code: "179",
        latitude: "45.76165650",
        longitude: "14.63528530",
        type: null,
      },
      {
        id: 4261,
        name: "Solčava Municipality",
        state_code: "180",
        latitude: "46.40235260",
        longitude: "14.68023040",
        type: null,
      },
      {
        id: 4248,
        name: "Šoštanj Municipality",
        state_code: "126",
        latitude: "46.37828360",
        longitude: "15.04613780",
        type: null,
      },
      {
        id: 4263,
        name: "Središče ob Dravi",
        state_code: "202",
        latitude: "46.39592820",
        longitude: "16.27049150",
        type: null,
      },
      {
        id: 4259,
        name: "Starše Municipality",
        state_code: "115",
        latitude: "46.46743310",
        longitude: "15.76405460",
        type: null,
      },
      {
        id: 4185,
        name: "Štore Municipality",
        state_code: "127",
        latitude: "46.22225140",
        longitude: "15.31261160",
        type: null,
      },
      {
        id: 4333,
        name: "Straža Municipality",
        state_code: "203",
        latitude: "45.77684280",
        longitude: "15.09486940",
        type: null,
      },
      {
        id: 4164,
        name: "Sveta Ana Municipality",
        state_code: "181",
        latitude: "46.65000000",
        longitude: "15.84527800",
        type: null,
      },
      {
        id: 4260,
        name: "Sveta Trojica v Slovenskih Goricah Municipality",
        state_code: "204",
        latitude: "46.56808090",
        longitude: "15.88230640",
        type: null,
      },
      {
        id: 4229,
        name: "Sveti Andraž v Slovenskih Goricah Municipality",
        state_code: "182",
        latitude: "46.51897470",
        longitude: "15.94982620",
        type: null,
      },
      {
        id: 4255,
        name: "Sveti Jurij ob Ščavnici Municipality",
        state_code: "116",
        latitude: "46.56874520",
        longitude: "16.02225280",
        type: null,
      },
      {
        id: 4328,
        name: "Sveti Jurij v Slovenskih Goricah Municipality",
        state_code: "210",
        latitude: "46.61707910",
        longitude: "15.78046770",
        type: null,
      },
      {
        id: 4273,
        name: "Sveti Tomaž Municipality",
        state_code: "205",
        latitude: "46.48352830",
        longitude: "16.07944200",
        type: null,
      },
      {
        id: 4194,
        name: "Tabor Municipality",
        state_code: "184",
        latitude: "46.21079210",
        longitude: "15.01742490",
        type: null,
      },
      {
        id: 4312,
        name: "Tišina Municipality",
        state_code: "010",
        latitude: "46.65418840",
        longitude: "16.07547810",
        type: null,
      },
      {
        id: 4247,
        name: "Tolmin Municipality",
        state_code: "128",
        latitude: "46.18571880",
        longitude: "13.73198380",
        type: null,
      },
      {
        id: 4246,
        name: "Trbovlje Municipality",
        state_code: "129",
        latitude: "46.15035630",
        longitude: "15.04531370",
        type: null,
      },
      {
        id: 4214,
        name: "Trebnje Municipality",
        state_code: "130",
        latitude: "45.90801630",
        longitude: "15.01319050",
        type: null,
      },
      {
        id: 4153,
        name: "Trnovska Vas Municipality",
        state_code: "185",
        latitude: "46.52940350",
        longitude: "15.88531180",
        type: null,
      },
      {
        id: 4250,
        name: "Tržič Municipality",
        state_code: "131",
        latitude: "46.35935140",
        longitude: "14.30066230",
        type: null,
      },
      {
        id: 4334,
        name: "Trzin Municipality",
        state_code: "186",
        latitude: "46.12982410",
        longitude: "14.55776370",
        type: null,
      },
      {
        id: 4251,
        name: "Turnišče Municipality",
        state_code: "132",
        latitude: "46.61375040",
        longitude: "16.32021000",
        type: null,
      },
      {
        id: 4267,
        name: "Velika Polana Municipality",
        state_code: "187",
        latitude: "46.57317150",
        longitude: "16.34441260",
        type: null,
      },
      {
        id: 4144,
        name: "Velike Lašče Municipality",
        state_code: "134",
        latitude: "45.83365910",
        longitude: "14.63623630",
        type: null,
      },
      {
        id: 4257,
        name: "Veržej Municipality",
        state_code: "188",
        latitude: "46.58411350",
        longitude: "16.16208000",
        type: null,
      },
      {
        id: 4300,
        name: "Videm Municipality",
        state_code: "135",
        latitude: "46.36383300",
        longitude: "15.87812120",
        type: null,
      },
      {
        id: 4196,
        name: "Vipava Municipality",
        state_code: "136",
        latitude: "45.84126740",
        longitude: "13.96096130",
        type: null,
      },
      {
        id: 4148,
        name: "Vitanje Municipality",
        state_code: "137",
        latitude: "46.38153230",
        longitude: "15.29506870",
        type: null,
      },
      {
        id: 4154,
        name: "Vodice Municipality",
        state_code: "138",
        latitude: "46.18966430",
        longitude: "14.49385390",
        type: null,
      },
      {
        id: 4245,
        name: "Vojnik Municipality",
        state_code: "139",
        latitude: "46.29205810",
        longitude: "15.30205800",
        type: null,
      },
      {
        id: 4163,
        name: "Vransko Municipality",
        state_code: "189",
        latitude: "46.23900600",
        longitude: "14.95272490",
        type: null,
      },
      {
        id: 4262,
        name: "Vrhnika Municipality",
        state_code: "140",
        latitude: "45.95027190",
        longitude: "14.32764220",
        type: null,
      },
      {
        id: 4226,
        name: "Vuzenica Municipality",
        state_code: "141",
        latitude: "46.59808360",
        longitude: "15.16572370",
        type: null,
      },
      {
        id: 4269,
        name: "Zagorje ob Savi Municipality",
        state_code: "142",
        latitude: "46.13452020",
        longitude: "14.99643840",
        type: null,
      },
      {
        id: 4258,
        name: "Žalec Municipality",
        state_code: "190",
        latitude: "46.25197120",
        longitude: "15.16500720",
        type: null,
      },
      {
        id: 4182,
        name: "Zavrč Municipality",
        state_code: "143",
        latitude: "46.35713000",
        longitude: "16.04777470",
        type: null,
      },
      {
        id: 4256,
        name: "Železniki Municipality",
        state_code: "146",
        latitude: "46.22563770",
        longitude: "14.16936170",
        type: null,
      },
      {
        id: 4249,
        name: "Žetale Municipality",
        state_code: "191",
        latitude: "46.27428330",
        longitude: "15.79133590",
        type: null,
      },
      {
        id: 4192,
        name: "Žiri Municipality",
        state_code: "147",
        latitude: "46.04724990",
        longitude: "14.10984510",
        type: null,
      },
      {
        id: 4276,
        name: "Žirovnica Municipality",
        state_code: "192",
        latitude: "46.39544030",
        longitude: "14.15396320",
        type: null,
      },
      {
        id: 4342,
        name: "Zreče Municipality",
        state_code: "144",
        latitude: "46.41777860",
        longitude: "15.37094310",
        type: null,
      },
      {
        id: 4307,
        name: "Žužemberk Municipality",
        state_code: "193",
        latitude: "45.82003500",
        longitude: "14.95359190",
        type: null,
      },
    ],
  },
  {
    name: "Solomon Islands",
    iso3: "SLB",
    iso2: "SB",
    numeric_code: "090",
    phone_code: "677",
    capital: "Honiara",
    currency: "SBD",
    currency_name: "Solomon Islands dollar",
    currency_symbol: "Si$",
    tld: ".sb",
    native: "Solomon Islands",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Solomon Island",
    timezones: [
      {
        zoneName: "Pacific/Guadalcanal",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "SBT",
        tzName: "Solomon Islands Time",
      },
    ],
    translations: {
      kr: "솔로몬 제도",
      "pt-BR": "Ilhas Salomão",
      pt: "Ilhas Salomão",
      nl: "Salomonseilanden",
      hr: "Solomonski Otoci",
      fa: "جزایر سلیمان",
      de: "Salomonen",
      es: "Islas Salomón",
      fr: "Îles Salomon",
      ja: "ソロモン諸島",
      it: "Isole Salomone",
      cn: "所罗门群岛",
      tr: "Solomon Adalari",
    },
    latitude: "-8.00000000",
    longitude: "159.00000000",
    emoji: "🇸🇧",
    emojiU: "U+1F1F8 U+1F1E7",
    states: [
      {
        id: 4784,
        name: "Central Province",
        state_code: "CE",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 4781,
        name: "Choiseul Province",
        state_code: "CH",
        latitude: "-7.05014940",
        longitude: "156.95114590",
        type: null,
      },
      {
        id: 4785,
        name: "Guadalcanal Province",
        state_code: "GU",
        latitude: "-9.57732840",
        longitude: "160.14558050",
        type: null,
      },
      {
        id: 4778,
        name: "Honiara",
        state_code: "CT",
        latitude: "-9.44563810",
        longitude: "159.97289990",
        type: null,
      },
      {
        id: 4780,
        name: "Isabel Province",
        state_code: "IS",
        latitude: "-8.05923530",
        longitude: "159.14470810",
        type: null,
      },
      {
        id: 4782,
        name: "Makira-Ulawa Province",
        state_code: "MK",
        latitude: "-10.57374470",
        longitude: "161.80969410",
        type: null,
      },
      {
        id: 4783,
        name: "Malaita Province",
        state_code: "ML",
        latitude: "-8.94461680",
        longitude: "160.90712360",
        type: null,
      },
      {
        id: 4787,
        name: "Rennell and Bellona Province",
        state_code: "RB",
        latitude: "-11.61314350",
        longitude: "160.16939490",
        type: null,
      },
      {
        id: 4779,
        name: "Temotu Province",
        state_code: "TE",
        latitude: "-10.68692900",
        longitude: "166.06239790",
        type: null,
      },
      {
        id: 4786,
        name: "Western Province",
        state_code: "WE",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Somalia",
    iso3: "SOM",
    iso2: "SO",
    numeric_code: "706",
    phone_code: "252",
    capital: "Mogadishu",
    currency: "SOS",
    currency_name: "Somali shilling",
    currency_symbol: "Sh.so.",
    tld: ".so",
    native: "Soomaaliya",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Somali, Somalian",
    timezones: [
      {
        zoneName: "Africa/Mogadishu",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "소말리아",
      "pt-BR": "Somália",
      pt: "Somália",
      nl: "Somalië",
      hr: "Somalija",
      fa: "سومالی",
      de: "Somalia",
      es: "Somalia",
      fr: "Somalie",
      ja: "ソマリア",
      it: "Somalia",
      cn: "索马里",
      tr: "Somali",
    },
    latitude: "10.00000000",
    longitude: "49.00000000",
    emoji: "🇸🇴",
    emojiU: "U+1F1F8 U+1F1F4",
    states: [
      {
        id: 925,
        name: "Awdal Region",
        state_code: "AW",
        latitude: "10.63342850",
        longitude: "43.32946600",
        type: null,
      },
      {
        id: 917,
        name: "Bakool",
        state_code: "BK",
        latitude: "4.36572210",
        longitude: "44.09603110",
        type: null,
      },
      {
        id: 927,
        name: "Banaadir",
        state_code: "BN",
        latitude: "2.11873750",
        longitude: "45.33694590",
        type: null,
      },
      {
        id: 930,
        name: "Bari",
        state_code: "BR",
        latitude: "41.11714320",
        longitude: "16.87187150",
        type: null,
      },
      {
        id: 926,
        name: "Bay",
        state_code: "BY",
        latitude: "37.03655340",
        longitude: "-95.61747670",
        type: null,
      },
      {
        id: 918,
        name: "Galguduud",
        state_code: "GA",
        latitude: "5.18501280",
        longitude: "46.82528380",
        type: null,
      },
      {
        id: 928,
        name: "Gedo",
        state_code: "GE",
        latitude: "3.50392270",
        longitude: "42.23624350",
        type: null,
      },
      {
        id: 915,
        name: "Hiran",
        state_code: "HI",
        latitude: "4.32101500",
        longitude: "45.29938620",
        type: null,
      },
      {
        id: 924,
        name: "Lower Juba",
        state_code: "JH",
        latitude: "0.22402100",
        longitude: "41.60118140",
        type: null,
      },
      {
        id: 921,
        name: "Lower Shebelle",
        state_code: "SH",
        latitude: "1.87664580",
        longitude: "44.24790150",
        type: null,
      },
      {
        id: 922,
        name: "Middle Juba",
        state_code: "JD",
        latitude: "2.07804880",
        longitude: "41.60118140",
        type: null,
      },
      {
        id: 923,
        name: "Middle Shebelle",
        state_code: "SD",
        latitude: "2.92502470",
        longitude: "45.90396890",
        type: null,
      },
      {
        id: 916,
        name: "Mudug",
        state_code: "MU",
        latitude: "6.56567260",
        longitude: "47.76375650",
        type: null,
      },
      {
        id: 920,
        name: "Nugal",
        state_code: "NU",
        latitude: "43.27938610",
        longitude: "17.03392050",
        type: null,
      },
      {
        id: 919,
        name: "Sanaag Region",
        state_code: "SA",
        latitude: "10.39382180",
        longitude: "47.76375650",
        type: null,
      },
      {
        id: 929,
        name: "Togdheer Region",
        state_code: "TO",
        latitude: "9.44605870",
        longitude: "45.29938620",
        type: null,
      },
    ],
  },
  {
    name: "South Africa",
    iso3: "ZAF",
    iso2: "ZA",
    numeric_code: "710",
    phone_code: "27",
    capital: "Pretoria",
    currency: "ZAR",
    currency_name: "South African rand",
    currency_symbol: "R",
    tld: ".za",
    native: "South Africa",
    region: "Africa",
    region_id: "1",
    subregion: "Southern Africa",
    subregion_id: "5",
    nationality: "South African",
    timezones: [
      {
        zoneName: "Africa/Johannesburg",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "SAST",
        tzName: "South African Standard Time",
      },
    ],
    translations: {
      kr: "남아프리카 공화국",
      "pt-BR": "República Sul-Africana",
      pt: "República Sul-Africana",
      nl: "Zuid-Afrika",
      hr: "Južnoafrička Republika",
      fa: "آفریقای جنوبی",
      de: "Republik Südafrika",
      es: "República de Sudáfrica",
      fr: "Afrique du Sud",
      ja: "南アフリカ",
      it: "Sud Africa",
      cn: "南非",
      tr: "Güney Afrika Cumhuriyeti",
    },
    latitude: "-29.00000000",
    longitude: "24.00000000",
    emoji: "🇿🇦",
    emojiU: "U+1F1FF U+1F1E6",
    states: [
      {
        id: 938,
        name: "Eastern Cape",
        state_code: "EC",
        latitude: "-32.29684020",
        longitude: "26.41938900",
        type: null,
      },
      {
        id: 932,
        name: "Free State",
        state_code: "FS",
        latitude: "37.68585250",
        longitude: "-97.28112560",
        type: null,
      },
      {
        id: 936,
        name: "Gauteng",
        state_code: "GP",
        latitude: "-26.27075930",
        longitude: "28.11226790",
        type: null,
      },
      {
        id: 935,
        name: "KwaZulu-Natal",
        state_code: "KZN",
        latitude: "-28.53055390",
        longitude: "30.89582420",
        type: null,
      },
      {
        id: 933,
        name: "Limpopo",
        state_code: "LP",
        latitude: "-23.40129460",
        longitude: "29.41793240",
        type: null,
      },
      {
        id: 937,
        name: "Mpumalanga",
        state_code: "MP",
        latitude: "-25.56533600",
        longitude: "30.52790960",
        type: null,
      },
      {
        id: 934,
        name: "North West",
        state_code: "NW",
        latitude: "32.75885200",
        longitude: "-97.32880600",
        type: null,
      },
      {
        id: 931,
        name: "Northern Cape",
        state_code: "NC",
        latitude: "-29.04668080",
        longitude: "21.85685860",
        type: null,
      },
      {
        id: 939,
        name: "Western Cape",
        state_code: "WC",
        latitude: "-33.22779180",
        longitude: "21.85685860",
        type: null,
      },
    ],
  },
  {
    name: "South Georgia",
    iso3: "SGS",
    iso2: "GS",
    numeric_code: "239",
    phone_code: "500",
    capital: "Grytviken",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".gs",
    native: "South Georgia",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "South Georgia or South Sandwich Islands",
    timezones: [
      {
        zoneName: "Atlantic/South_Georgia",
        gmtOffset: -7200,
        gmtOffsetName: "UTC-02:00",
        abbreviation: "GST",
        tzName: "South Georgia and the South Sandwich Islands Time",
      },
    ],
    translations: {
      kr: "사우스조지아",
      "pt-BR": "Ilhas Geórgias do Sul e Sandwich do Sul",
      pt: "Ilhas Geórgia do Sul e Sanduíche do Sul",
      nl: "Zuid-Georgia en Zuidelijke Sandwicheilanden",
      hr: "Južna Georgija i otočje Južni Sandwich",
      fa: "جزایر جورجیای جنوبی و ساندویچ جنوبی",
      de: "Südgeorgien und die Südlichen Sandwichinseln",
      es: "Islas Georgias del Sur y Sandwich del Sur",
      fr: "Géorgie du Sud-et-les Îles Sandwich du Sud",
      ja: "サウスジョージア・サウスサンドウィッチ諸島",
      it: "Georgia del Sud e Isole Sandwich Meridionali",
      cn: "南乔治亚",
      tr: "Güney Georgia",
    },
    latitude: "-54.50000000",
    longitude: "-37.00000000",
    emoji: "🇬🇸",
    emojiU: "U+1F1EC U+1F1F8",
    states: [],
  },
  {
    name: "South Korea",
    iso3: "KOR",
    iso2: "KR",
    numeric_code: "410",
    phone_code: "82",
    capital: "Seoul",
    currency: "KRW",
    currency_name: "Won",
    currency_symbol: "₩",
    tld: ".kr",
    native: "대한민국",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "South Korean",
    timezones: [
      {
        zoneName: "Asia/Seoul",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "KST",
        tzName: "Korea Standard Time",
      },
    ],
    translations: {
      kr: "대한민국",
      "pt-BR": "Coreia do Sul",
      pt: "Coreia do Sul",
      nl: "Zuid-Korea",
      hr: "Južna Koreja",
      fa: "کره شمالی",
      de: "Südkorea",
      es: "Corea del Sur",
      fr: "Corée du Sud",
      ja: "大韓民国",
      it: "Corea del Sud",
      cn: "韩国",
      tr: "Güney Kore",
    },
    latitude: "37.00000000",
    longitude: "127.50000000",
    emoji: "🇰🇷",
    emojiU: "U+1F1F0 U+1F1F7",
    states: [
      {
        id: 3860,
        name: "Busan",
        state_code: "26",
        latitude: "35.17955430",
        longitude: "129.07564160",
        type: null,
      },
      {
        id: 3846,
        name: "Daegu",
        state_code: "27",
        latitude: "35.87143540",
        longitude: "128.60144500",
        type: null,
      },
      {
        id: 3850,
        name: "Daejeon",
        state_code: "30",
        latitude: "36.35041190",
        longitude: "127.38454750",
        type: null,
      },
      {
        id: 3862,
        name: "Gangwon Province",
        state_code: "42",
        latitude: "37.82280000",
        longitude: "128.15550000",
        type: null,
      },
      {
        id: 3858,
        name: "Gwangju",
        state_code: "29",
        latitude: "35.15954540",
        longitude: "126.85260120",
        type: null,
      },
      {
        id: 3847,
        name: "Gyeonggi Province",
        state_code: "41",
        latitude: "37.41380000",
        longitude: "127.51830000",
        type: null,
      },
      {
        id: 3848,
        name: "Incheon",
        state_code: "28",
        latitude: "37.45625570",
        longitude: "126.70520620",
        type: null,
      },
      {
        id: 3853,
        name: "Jeju",
        state_code: "49",
        latitude: "33.95682780",
        longitude: "-84.13135000",
        type: null,
      },
      {
        id: 3854,
        name: "North Chungcheong Province",
        state_code: "43",
        latitude: "36.80000000",
        longitude: "127.70000000",
        type: null,
      },
      {
        id: 3855,
        name: "North Gyeongsang Province",
        state_code: "47",
        latitude: "36.49190000",
        longitude: "128.88890000",
        type: null,
      },
      {
        id: 3851,
        name: "North Jeolla Province",
        state_code: "45",
        latitude: "35.71750000",
        longitude: "127.15300000",
        type: null,
      },
      {
        id: 3861,
        name: "Sejong City",
        state_code: "50",
        latitude: "34.05233230",
        longitude: "-118.30848970",
        type: null,
      },
      {
        id: 3849,
        name: "Seoul",
        state_code: "11",
        latitude: "37.56653500",
        longitude: "126.97796920",
        type: null,
      },
      {
        id: 3859,
        name: "South Chungcheong Province",
        state_code: "44",
        latitude: "36.51840000",
        longitude: "126.80000000",
        type: null,
      },
      {
        id: 3857,
        name: "South Gyeongsang Province",
        state_code: "48",
        latitude: "35.46060000",
        longitude: "128.21320000",
        type: null,
      },
      {
        id: 3856,
        name: "South Jeolla Province",
        state_code: "46",
        latitude: "34.86790000",
        longitude: "126.99100000",
        type: null,
      },
      {
        id: 3852,
        name: "Ulsan",
        state_code: "31",
        latitude: "35.53837730",
        longitude: "129.31135960",
        type: null,
      },
    ],
  },
  {
    name: "South Sudan",
    iso3: "SSD",
    iso2: "SS",
    numeric_code: "728",
    phone_code: "211",
    capital: "Juba",
    currency: "SSP",
    currency_name: "South Sudanese pound",
    currency_symbol: "£",
    tld: ".ss",
    native: "South Sudan",
    region: "Africa",
    region_id: "1",
    subregion: "Middle Africa",
    subregion_id: "2",
    nationality: "South Sudanese",
    timezones: [
      {
        zoneName: "Africa/Juba",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "남수단",
      "pt-BR": "Sudão do Sul",
      pt: "Sudão do Sul",
      nl: "Zuid-Soedan",
      hr: "Južni Sudan",
      fa: "سودان جنوبی",
      de: "Südsudan",
      es: "Sudán del Sur",
      fr: "Soudan du Sud",
      ja: "南スーダン",
      it: "Sudan del sud",
      cn: "南苏丹",
      tr: "Güney Sudan",
    },
    latitude: "7.00000000",
    longitude: "30.00000000",
    emoji: "🇸🇸",
    emojiU: "U+1F1F8 U+1F1F8",
    states: [
      {
        id: 2092,
        name: "Central Equatoria",
        state_code: "EC",
        latitude: "4.61440630",
        longitude: "31.26263660",
        type: null,
      },
      {
        id: 2093,
        name: "Eastern Equatoria",
        state_code: "EE",
        latitude: "5.06929950",
        longitude: "33.43835300",
        type: null,
      },
      {
        id: 2094,
        name: "Jonglei State",
        state_code: "JG",
        latitude: "7.18196190",
        longitude: "32.35609520",
        type: null,
      },
      {
        id: 2090,
        name: "Lakes",
        state_code: "LK",
        latitude: "37.16282550",
        longitude: "-95.69116230",
        type: null,
      },
      {
        id: 2088,
        name: "Northern Bahr el Ghazal",
        state_code: "BN",
        latitude: "8.53604490",
        longitude: "26.79678490",
        type: null,
      },
      {
        id: 2085,
        name: "Unity",
        state_code: "UY",
        latitude: "37.78712760",
        longitude: "-122.40340790",
        type: null,
      },
      {
        id: 2086,
        name: "Upper Nile",
        state_code: "NU",
        latitude: "9.88942020",
        longitude: "32.71813750",
        type: null,
      },
      {
        id: 2087,
        name: "Warrap",
        state_code: "WR",
        latitude: "8.08862380",
        longitude: "28.64106410",
        type: null,
      },
      {
        id: 2091,
        name: "Western Bahr el Ghazal",
        state_code: "BW",
        latitude: "8.64523990",
        longitude: "25.28375850",
        type: null,
      },
      {
        id: 2089,
        name: "Western Equatoria",
        state_code: "EW",
        latitude: "5.34717990",
        longitude: "28.29943500",
        type: null,
      },
    ],
  },
  {
    name: "Spain",
    iso3: "ESP",
    iso2: "ES",
    numeric_code: "724",
    phone_code: "34",
    capital: "Madrid",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".es",
    native: "España",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Spanish",
    timezones: [
      {
        zoneName: "Africa/Ceuta",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
      {
        zoneName: "Atlantic/Canary",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "WET",
        tzName: "Western European Time",
      },
      {
        zoneName: "Europe/Madrid",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스페인",
      "pt-BR": "Espanha",
      pt: "Espanha",
      nl: "Spanje",
      hr: "Španjolska",
      fa: "اسپانیا",
      de: "Spanien",
      es: "España",
      fr: "Espagne",
      ja: "スペイン",
      it: "Spagna",
      cn: "西班牙",
      tr: "İspanya",
    },
    latitude: "40.00000000",
    longitude: "-4.00000000",
    emoji: "🇪🇸",
    emojiU: "U+1F1EA U+1F1F8",
    states: [
      {
        id: 5089,
        name: "A Coruña",
        state_code: "C",
        latitude: "43.36190400",
        longitude: "-8.43019320",
        type: "province",
      },
      {
        id: 5109,
        name: "Albacete",
        state_code: "AB",
        latitude: "38.99223120",
        longitude: "-1.87809890",
        type: "province",
      },
      {
        id: 5108,
        name: "Alicante",
        state_code: "A",
        latitude: "38.35795460",
        longitude: "-0.54256340",
        type: "province",
      },
      {
        id: 5095,
        name: "Almeria",
        state_code: "AL",
        latitude: "36.84152680",
        longitude: "-2.47462610",
        type: "province",
      },
      {
        id: 5093,
        name: "Araba",
        state_code: "VI",
        latitude: "42.83951190",
        longitude: "-3.84237740",
        type: "province",
      },
      {
        id: 1160,
        name: "Asturias",
        state_code: "O",
        latitude: "43.36139530",
        longitude: "-5.85932670",
        type: "province",
      },
      {
        id: 1189,
        name: "Ávila",
        state_code: "AV",
        latitude: "40.69345110",
        longitude: "-4.89356270",
        type: "province",
      },
      {
        id: 5092,
        name: "Badajoz",
        state_code: "BA",
        latitude: "38.87937480",
        longitude: "-7.02269830",
        type: "province",
      },
      {
        id: 5102,
        name: "Barcelona",
        state_code: "B",
        latitude: "41.39266790",
        longitude: "2.14018910",
        type: "province",
      },
      {
        id: 5094,
        name: "Bizkaia",
        state_code: "BI",
        latitude: "43.21921990",
        longitude: "-3.21110870",
        type: "province",
      },
      {
        id: 1146,
        name: "Burgos",
        state_code: "BU",
        latitude: "42.33807580",
        longitude: "-3.58126920",
        type: "province",
      },
      {
        id: 1190,
        name: "Caceres",
        state_code: "CC",
        latitude: "39.47163130",
        longitude: "-6.42573840",
        type: "province",
      },
      {
        id: 5096,
        name: "Cádiz",
        state_code: "CA",
        latitude: "36.51638510",
        longitude: "-6.29997670",
        type: "province",
      },
      {
        id: 5222,
        name: "Canarias",
        state_code: "CN",
        latitude: "28.29160000",
        longitude: "16.62910000",
        type: "autonomous community",
      },
      {
        id: 1170,
        name: "Cantabria",
        state_code: "S",
        latitude: "43.18283960",
        longitude: "-3.98784270",
        type: "province",
      },
      {
        id: 5110,
        name: "Castellón",
        state_code: "CS",
        latitude: "39.98114350",
        longitude: "0.00884070",
        type: "province",
      },
      {
        id: 5223,
        name: "Ceuta",
        state_code: "CE",
        latitude: "35.88900000",
        longitude: "-5.31870000",
        type: "autonomous city in North Africa",
      },
      {
        id: 5105,
        name: "Ciudad Real",
        state_code: "CR",
        latitude: "38.98607580",
        longitude: "-3.94449750",
        type: "province",
      },
      {
        id: 5097,
        name: "Córdoba",
        state_code: "CO",
        latitude: "36.51638510",
        longitude: "-6.29997670",
        type: "province",
      },
      {
        id: 5106,
        name: "Cuenca",
        state_code: "CU",
        latitude: "40.06200360",
        longitude: "-2.16553440",
        type: "province",
      },
      {
        id: 1191,
        name: "Gipuzkoa",
        state_code: "SS",
        latitude: "43.14523600",
        longitude: "-2.44618250",
        type: "province",
      },
      {
        id: 5103,
        name: "Girona",
        state_code: "GI",
        latitude: "41.98034450",
        longitude: "2.80115770",
        type: "province",
      },
      {
        id: 5098,
        name: "Granada",
        state_code: "GR",
        latitude: "37.18094110",
        longitude: "-3.62629100",
        type: "province",
      },
      {
        id: 5107,
        name: "Guadalajara",
        state_code: "GU",
        latitude: "40.63222140",
        longitude: "-3.19068200",
        type: "province",
      },
      {
        id: 5099,
        name: "Huelva",
        state_code: "H",
        latitude: "37.27086660",
        longitude: "-6.95719990",
        type: "province",
      },
      {
        id: 1177,
        name: "Huesca",
        state_code: "HU",
        latitude: "41.59762750",
        longitude: "-0.90566230",
        type: "province",
      },
      {
        id: 1174,
        name: "Islas Baleares",
        state_code: "PM",
        latitude: "39.35877590",
        longitude: "2.73563280",
        type: "province",
      },
      {
        id: 5100,
        name: "Jaén",
        state_code: "J",
        latitude: "37.78009310",
        longitude: "-3.81437450",
        type: "province",
      },
      {
        id: 1171,
        name: "La Rioja",
        state_code: "LO",
        latitude: "42.28707330",
        longitude: "-2.53960300",
        type: "province",
      },
      {
        id: 1185,
        name: "Las Palmas",
        state_code: "GC",
        latitude: "28.29156370",
        longitude: "-16.62913040",
        type: "province",
      },
      {
        id: 1200,
        name: "León",
        state_code: "LE",
        latitude: "42.59870410",
        longitude: "-5.56708390",
        type: "province",
      },
      {
        id: 5104,
        name: "Lleida",
        state_code: "L",
        latitude: "41.61837310",
        longitude: "0.60242530",
        type: "province",
      },
      {
        id: 5090,
        name: "Lugo",
        state_code: "LU",
        latitude: "43.01231370",
        longitude: "-7.57400960",
        type: "province",
      },
      {
        id: 1158,
        name: "Madrid",
        state_code: "M",
        latitude: "40.41675150",
        longitude: "-3.70383220",
        type: "province",
      },
      {
        id: 5101,
        name: "Málaga",
        state_code: "MA",
        latitude: "36.71820150",
        longitude: "-4.51930600",
        type: "province",
      },
      {
        id: 5224,
        name: "Melilla",
        state_code: "ML",
        latitude: "35.29370000",
        longitude: "-2.93830000",
        type: "autonomous city in North Africa",
      },
      {
        id: 1176,
        name: "Murcia",
        state_code: "MU",
        latitude: "38.13981410",
        longitude: "-1.36621600",
        type: "province",
      },
      {
        id: 1204,
        name: "Navarra",
        state_code: "NA",
        latitude: "42.69539090",
        longitude: "-1.67606910",
        type: "province",
      },
      {
        id: 5091,
        name: "Ourense",
        state_code: "OR",
        latitude: "42.33836130",
        longitude: "-7.88119510",
        type: "province",
      },
      {
        id: 1157,
        name: "Palencia",
        state_code: "P",
        latitude: "42.00968320",
        longitude: "-4.52879490",
        type: "province",
      },
      {
        id: 1167,
        name: "Pontevedra",
        state_code: "PO",
        latitude: "42.43385950",
        longitude: "-8.65685520",
        type: "province",
      },
      {
        id: 1147,
        name: "Salamanca",
        state_code: "SA",
        latitude: "40.95152630",
        longitude: "-6.23759470",
        type: "province",
      },
      {
        id: 5112,
        name: "Santa Cruz de Tenerife",
        state_code: "TF",
        latitude: "28.45789140",
        longitude: "-16.32135390",
        type: "province",
      },
      {
        id: 1192,
        name: "Segovia",
        state_code: "SG",
        latitude: "40.94292960",
        longitude: "-4.10889420",
        type: "province",
      },
      {
        id: 1193,
        name: "Sevilla",
        state_code: "SE",
        latitude: "37.37535010",
        longitude: "-6.02509730",
        type: "province",
      },
      {
        id: 1208,
        name: "Soria",
        state_code: "SO",
        latitude: "41.76654640",
        longitude: "-2.47903060",
        type: "province",
      },
      {
        id: 1203,
        name: "Tarragona",
        state_code: "T",
        latitude: "41.12586420",
        longitude: "1.20356420",
        type: "province",
      },
      {
        id: 5111,
        name: "Teruel",
        state_code: "TE",
        latitude: "40.34504100",
        longitude: "-1.11847440",
        type: "province",
      },
      {
        id: 1205,
        name: "Toledo",
        state_code: "TO",
        latitude: "39.86232000",
        longitude: "-4.06946920",
        type: "province",
      },
      {
        id: 1175,
        name: "Valencia",
        state_code: "V",
        latitude: "39.48401080",
        longitude: "-0.75328090",
        type: "province",
      },
      {
        id: 1183,
        name: "Valladolid",
        state_code: "VA",
        latitude: "41.65173750",
        longitude: "-4.72449500",
        type: "province",
      },
      {
        id: 1161,
        name: "Zamora",
        state_code: "ZA",
        latitude: "41.60957440",
        longitude: "-5.89871390",
        type: "province",
      },
      {
        id: 5113,
        name: "Zaragoza",
        state_code: "Z",
        latitude: "41.65175010",
        longitude: "-0.93000020",
        type: "province",
      },
    ],
  },
  {
    name: "Sri Lanka",
    iso3: "LKA",
    iso2: "LK",
    numeric_code: "144",
    phone_code: "94",
    capital: "Colombo",
    currency: "LKR",
    currency_name: "Sri Lankan rupee",
    currency_symbol: "Rs",
    tld: ".lk",
    native: "śrī laṃkāva",
    region: "Asia",
    region_id: "3",
    subregion: "Southern Asia",
    subregion_id: "14",
    nationality: "Sri Lankan",
    timezones: [
      {
        zoneName: "Asia/Colombo",
        gmtOffset: 19800,
        gmtOffsetName: "UTC+05:30",
        abbreviation: "IST",
        tzName: "Indian Standard Time",
      },
    ],
    translations: {
      kr: "스리랑카",
      "pt-BR": "Sri Lanka",
      pt: "Sri Lanka",
      nl: "Sri Lanka",
      hr: "Šri Lanka",
      fa: "سری‌لانکا",
      de: "Sri Lanka",
      es: "Sri Lanka",
      fr: "Sri Lanka",
      ja: "スリランカ",
      it: "Sri Lanka",
      cn: "斯里兰卡",
      tr: "Sri Lanka",
    },
    latitude: "7.00000000",
    longitude: "81.00000000",
    emoji: "🇱🇰",
    emojiU: "U+1F1F1 U+1F1F0",
    states: [
      {
        id: 2799,
        name: "Ampara District",
        state_code: "52",
        latitude: "7.29116850",
        longitude: "81.67237610",
        type: null,
      },
      {
        id: 2816,
        name: "Anuradhapura District",
        state_code: "71",
        latitude: "8.33183050",
        longitude: "80.40290170",
        type: null,
      },
      {
        id: 2790,
        name: "Badulla District",
        state_code: "81",
        latitude: "6.99340090",
        longitude: "81.05498150",
        type: null,
      },
      {
        id: 2818,
        name: "Batticaloa District",
        state_code: "51",
        latitude: "7.82927810",
        longitude: "81.47183870",
        type: null,
      },
      {
        id: 2798,
        name: "Central Province",
        state_code: "2",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2815,
        name: "Colombo District",
        state_code: "11",
        latitude: "6.92695570",
        longitude: "79.86173060",
        type: null,
      },
      {
        id: 2808,
        name: "Eastern Province",
        state_code: "5",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2792,
        name: "Galle District",
        state_code: "31",
        latitude: "6.05774900",
        longitude: "80.21755720",
        type: null,
      },
      {
        id: 2804,
        name: "Gampaha District",
        state_code: "12",
        latitude: "7.07126190",
        longitude: "80.00877460",
        type: null,
      },
      {
        id: 2791,
        name: "Hambantota District",
        state_code: "33",
        latitude: "6.15358160",
        longitude: "81.12714900",
        type: null,
      },
      {
        id: 2787,
        name: "Jaffna District",
        state_code: "41",
        latitude: "9.69304680",
        longitude: "80.16518540",
        type: null,
      },
      {
        id: 2789,
        name: "Kalutara District",
        state_code: "13",
        latitude: "6.60846860",
        longitude: "80.14285840",
        type: null,
      },
      {
        id: 2788,
        name: "Kandy District",
        state_code: "21",
        latitude: "7.29315880",
        longitude: "80.63501070",
        type: null,
      },
      {
        id: 2797,
        name: "Kegalle District",
        state_code: "92",
        latitude: "7.12040530",
        longitude: "80.32131060",
        type: null,
      },
      {
        id: 2793,
        name: "Kilinochchi District",
        state_code: "42",
        latitude: "9.36779710",
        longitude: "80.32131060",
        type: null,
      },
      {
        id: 2805,
        name: "Mannar District",
        state_code: "43",
        latitude: "8.98095310",
        longitude: "79.90439750",
        type: null,
      },
      {
        id: 2810,
        name: "Matale District",
        state_code: "22",
        latitude: "7.46596460",
        longitude: "80.62342590",
        type: null,
      },
      {
        id: 2806,
        name: "Matara District",
        state_code: "32",
        latitude: "5.94493480",
        longitude: "80.54879970",
        type: null,
      },
      {
        id: 2819,
        name: "Monaragala District",
        state_code: "82",
        latitude: "6.87277810",
        longitude: "81.35068320",
        type: null,
      },
      {
        id: 2814,
        name: "Mullaitivu District",
        state_code: "45",
        latitude: "9.26753880",
        longitude: "80.81282540",
        type: null,
      },
      {
        id: 2800,
        name: "North Central Province",
        state_code: "7",
        latitude: "8.19956380",
        longitude: "80.63269160",
        type: null,
      },
      {
        id: 2817,
        name: "North Western Province",
        state_code: "6",
        latitude: "7.75840910",
        longitude: "80.18750650",
        type: null,
      },
      {
        id: 2813,
        name: "Northern Province",
        state_code: "4",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2794,
        name: "Nuwara Eliya District",
        state_code: "23",
        latitude: "6.96065320",
        longitude: "80.76927580",
        type: null,
      },
      {
        id: 2812,
        name: "Polonnaruwa District",
        state_code: "72",
        latitude: "7.93955670",
        longitude: "81.00034030",
        type: null,
      },
      {
        id: 2796,
        name: "Puttalam District",
        state_code: "62",
        latitude: "8.02599150",
        longitude: "79.84712720",
        type: null,
      },
      {
        id: 2807,
        name: "Ratnapura district",
        state_code: "91",
        latitude: "6.70551680",
        longitude: "80.38483890",
        type: null,
      },
      {
        id: 2803,
        name: "Sabaragamuwa Province",
        state_code: "9",
        latitude: "6.73959410",
        longitude: "80.36586500",
        type: null,
      },
      {
        id: 2801,
        name: "Southern Province",
        state_code: "3",
        latitude: null,
        longitude: null,
        type: null,
      },
      {
        id: 2795,
        name: "Trincomalee District",
        state_code: "53",
        latitude: "8.60130690",
        longitude: "81.11960750",
        type: null,
      },
      {
        id: 2811,
        name: "Uva Province",
        state_code: "8",
        latitude: "6.84276120",
        longitude: "81.33994140",
        type: null,
      },
      {
        id: 2809,
        name: "Vavuniya District",
        state_code: "44",
        latitude: "8.75947390",
        longitude: "80.50003340",
        type: null,
      },
      {
        id: 2802,
        name: "Western Province",
        state_code: "1",
        latitude: null,
        longitude: null,
        type: null,
      },
    ],
  },
  {
    name: "Sudan",
    iso3: "SDN",
    iso2: "SD",
    numeric_code: "729",
    phone_code: "249",
    capital: "Khartoum",
    currency: "SDG",
    currency_name: "Sudanese pound",
    currency_symbol: ".س.ج",
    tld: ".sd",
    native: "السودان",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Sudanese",
    timezones: [
      {
        zoneName: "Africa/Khartoum",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EAT",
        tzName: "Eastern African Time",
      },
    ],
    translations: {
      kr: "수단",
      "pt-BR": "Sudão",
      pt: "Sudão",
      nl: "Soedan",
      hr: "Sudan",
      fa: "سودان",
      de: "Sudan",
      es: "Sudán",
      fr: "Soudan",
      ja: "スーダン",
      it: "Sudan",
      cn: "苏丹",
      tr: "Sudan",
    },
    latitude: "15.00000000",
    longitude: "30.00000000",
    emoji: "🇸🇩",
    emojiU: "U+1F1F8 U+1F1E9",
    states: [
      {
        id: 885,
        name: "Al Jazirah",
        state_code: "GZ",
        latitude: "14.88596110",
        longitude: "33.43835300",
        type: null,
      },
      {
        id: 886,
        name: "Al Qadarif",
        state_code: "GD",
        latitude: "14.02430700",
        longitude: "35.36856790",
        type: null,
      },
      {
        id: 887,
        name: "Blue Nile",
        state_code: "NB",
        latitude: "47.59867300",
        longitude: "-122.33441900",
        type: null,
      },
      {
        id: 896,
        name: "Central Darfur",
        state_code: "DC",
        latitude: "14.37827470",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 892,
        name: "East Darfur",
        state_code: "DE",
        latitude: "14.37827470",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 884,
        name: "Kassala",
        state_code: "KA",
        latitude: "15.45813320",
        longitude: "36.40396290",
        type: null,
      },
      {
        id: 881,
        name: "Khartoum",
        state_code: "KH",
        latitude: "15.50065440",
        longitude: "32.55989940",
        type: null,
      },
      {
        id: 890,
        name: "North Darfur",
        state_code: "DN",
        latitude: "15.76619690",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 893,
        name: "North Kordofan",
        state_code: "KN",
        latitude: "13.83064410",
        longitude: "29.41793240",
        type: null,
      },
      {
        id: 895,
        name: "Northern",
        state_code: "NO",
        latitude: "38.06381700",
        longitude: "-84.46286480",
        type: null,
      },
      {
        id: 880,
        name: "Red Sea",
        state_code: "RS",
        latitude: "20.28023200",
        longitude: "38.51257300",
        type: null,
      },
      {
        id: 891,
        name: "River Nile",
        state_code: "NR",
        latitude: "23.97275950",
        longitude: "32.87492060",
        type: null,
      },
      {
        id: 882,
        name: "Sennar",
        state_code: "SI",
        latitude: "13.56746900",
        longitude: "33.56720450",
        type: null,
      },
      {
        id: 894,
        name: "South Darfur",
        state_code: "DS",
        latitude: "11.64886390",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 883,
        name: "South Kordofan",
        state_code: "KS",
        latitude: "11.19901920",
        longitude: "29.41793240",
        type: null,
      },
      {
        id: 888,
        name: "West Darfur",
        state_code: "DW",
        latitude: "12.84635610",
        longitude: "23.00119890",
        type: null,
      },
      {
        id: 889,
        name: "West Kordofan",
        state_code: "GK",
        latitude: "11.19901920",
        longitude: "29.41793240",
        type: null,
      },
      {
        id: 879,
        name: "White Nile",
        state_code: "NW",
        latitude: "9.33215160",
        longitude: "31.46153000",
        type: null,
      },
    ],
  },
  {
    name: "Suriname",
    iso3: "SUR",
    iso2: "SR",
    numeric_code: "740",
    phone_code: "597",
    capital: "Paramaribo",
    currency: "SRD",
    currency_name: "Surinamese dollar",
    currency_symbol: "$",
    tld: ".sr",
    native: "Suriname",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Surinamese",
    timezones: [
      {
        zoneName: "America/Paramaribo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "SRT",
        tzName: "Suriname Time",
      },
    ],
    translations: {
      kr: "수리남",
      "pt-BR": "Suriname",
      pt: "Suriname",
      nl: "Suriname",
      hr: "Surinam",
      fa: "سورینام",
      de: "Suriname",
      es: "Surinam",
      fr: "Surinam",
      ja: "スリナム",
      it: "Suriname",
      cn: "苏里南",
      tr: "Surinam",
    },
    latitude: "4.00000000",
    longitude: "-56.00000000",
    emoji: "🇸🇷",
    emojiU: "U+1F1F8 U+1F1F7",
    states: [
      {
        id: 2846,
        name: "Brokopondo District",
        state_code: "BR",
        latitude: "4.77102470",
        longitude: "-55.04933750",
        type: null,
      },
      {
        id: 2839,
        name: "Commewijne District",
        state_code: "CM",
        latitude: "5.74021100",
        longitude: "-54.87312190",
        type: null,
      },
      {
        id: 2842,
        name: "Coronie District",
        state_code: "CR",
        latitude: "5.69432710",
        longitude: "-56.29293810",
        type: null,
      },
      {
        id: 2845,
        name: "Marowijne District",
        state_code: "MA",
        latitude: "5.62681280",
        longitude: "-54.25931180",
        type: null,
      },
      {
        id: 2840,
        name: "Nickerie District",
        state_code: "NI",
        latitude: "5.58554690",
        longitude: "-56.83111170",
        type: null,
      },
      {
        id: 2841,
        name: "Para District",
        state_code: "PR",
        latitude: "5.48173180",
        longitude: "-55.22592070",
        type: null,
      },
      {
        id: 2843,
        name: "Paramaribo District",
        state_code: "PM",
        latitude: "5.85203550",
        longitude: "-55.20382780",
        type: null,
      },
      {
        id: 2848,
        name: "Saramacca District",
        state_code: "SA",
        latitude: "5.72408130",
        longitude: "-55.66896360",
        type: null,
      },
      {
        id: 2847,
        name: "Sipaliwini District",
        state_code: "SI",
        latitude: "3.65673820",
        longitude: "-56.20353870",
        type: null,
      },
      {
        id: 2844,
        name: "Wanica District",
        state_code: "WA",
        latitude: "5.73237620",
        longitude: "-55.27012350",
        type: null,
      },
    ],
  },
  {
    name: "Svalbard and Jan Mayen Islands",
    iso3: "SJM",
    iso2: "SJ",
    numeric_code: "744",
    phone_code: "47",
    capital: "Longyearbyen",
    currency: "NOK",
    currency_name: "Norwegian Krone",
    currency_symbol: "kr",
    tld: ".sj",
    native: "Svalbard og Jan Mayen",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Svalbard",
    timezones: [
      {
        zoneName: "Arctic/Longyearbyen",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스발바르 얀마옌 제도",
      "pt-BR": "Svalbard",
      pt: "Svalbard",
      nl: "Svalbard en Jan Mayen",
      hr: "Svalbard i Jan Mayen",
      fa: "سوالبارد و یان ماین",
      de: "Svalbard und Jan Mayen",
      es: "Islas Svalbard y Jan Mayen",
      fr: "Svalbard et Jan Mayen",
      ja: "スヴァールバル諸島およびヤンマイエン島",
      it: "Svalbard e Jan Mayen",
      cn: "斯瓦尔巴和扬马延群岛",
      tr: "Svalbard Ve Jan Mayen",
    },
    latitude: "78.00000000",
    longitude: "20.00000000",
    emoji: "🇸🇯",
    emojiU: "U+1F1F8 U+1F1EF",
    states: [],
  },
  {
    name: "Sweden",
    iso3: "SWE",
    iso2: "SE",
    numeric_code: "752",
    phone_code: "46",
    capital: "Stockholm",
    currency: "SEK",
    currency_name: "Swedish krona",
    currency_symbol: "kr",
    tld: ".se",
    native: "Sverige",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "Swedish",
    timezones: [
      {
        zoneName: "Europe/Stockholm",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스웨덴",
      "pt-BR": "Suécia",
      pt: "Suécia",
      nl: "Zweden",
      hr: "Švedska",
      fa: "سوئد",
      de: "Schweden",
      es: "Suecia",
      fr: "Suède",
      ja: "スウェーデン",
      it: "Svezia",
      cn: "瑞典",
      tr: "İsveç",
    },
    latitude: "62.00000000",
    longitude: "15.00000000",
    emoji: "🇸🇪",
    emojiU: "U+1F1F8 U+1F1EA",
    states: [
      {
        id: 1537,
        name: "Blekinge County",
        state_code: "K",
        latitude: "56.28333333",
        longitude: "15.11666666",
        type: null,
      },
      {
        id: 1534,
        name: "Dalarna County",
        state_code: "W",
        latitude: "61.09170120",
        longitude: "14.66636530",
        type: null,
      },
      {
        id: 1533,
        name: "Gävleborg County",
        state_code: "X",
        latitude: "61.30119930",
        longitude: "16.15342140",
        type: null,
      },
      {
        id: 1546,
        name: "Gotland County",
        state_code: "I",
        latitude: "57.46841210",
        longitude: "18.48674470",
        type: null,
      },
      {
        id: 1548,
        name: "Halland County",
        state_code: "N",
        latitude: "56.89668050",
        longitude: "12.80339930",
        type: null,
      },
      {
        id: 5117,
        name: "Jämtland County",
        state_code: "0",
        latitude: "63.28306200",
        longitude: "14.23828100",
        type: "SE-Z",
      },
      {
        id: 1550,
        name: "Jönköping County",
        state_code: "F",
        latitude: "57.37084340",
        longitude: "14.34391740",
        type: null,
      },
      {
        id: 1544,
        name: "Kalmar County",
        state_code: "H",
        latitude: "57.23501560",
        longitude: "16.18493490",
        type: null,
      },
      {
        id: 1542,
        name: "Kronoberg County",
        state_code: "G",
        latitude: "56.71834030",
        longitude: "14.41146730",
        type: null,
      },
      {
        id: 1538,
        name: "Norrbotten County",
        state_code: "BD",
        latitude: "66.83092160",
        longitude: "20.39919660",
        type: null,
      },
      {
        id: 1539,
        name: "Örebro County",
        state_code: "T",
        latitude: "59.53503600",
        longitude: "15.00657310",
        type: null,
      },
      {
        id: 1536,
        name: "Östergötland County",
        state_code: "E",
        latitude: "58.34536350",
        longitude: "15.51978440",
        type: null,
      },
      {
        id: 1541,
        name: "Skåne County",
        state_code: "M",
        latitude: "55.99025720",
        longitude: "13.59576920",
        type: null,
      },
      {
        id: 1540,
        name: "Södermanland County",
        state_code: "D",
        latitude: "59.03363490",
        longitude: "16.75188990",
        type: null,
      },
      {
        id: 1551,
        name: "Stockholm County",
        state_code: "AB",
        latitude: "59.60249580",
        longitude: "18.13843830",
        type: null,
      },
      {
        id: 1545,
        name: "Uppsala County",
        state_code: "C",
        latitude: "60.00922620",
        longitude: "17.27145880",
        type: null,
      },
      {
        id: 1535,
        name: "Värmland County",
        state_code: "S",
        latitude: "59.72940650",
        longitude: "13.23540240",
        type: null,
      },
      {
        id: 1543,
        name: "Västerbotten County",
        state_code: "AC",
        latitude: "65.33373110",
        longitude: "16.51616940",
        type: null,
      },
      {
        id: 1552,
        name: "Västernorrland County",
        state_code: "Y",
        latitude: "63.42764730",
        longitude: "17.72924440",
        type: null,
      },
      {
        id: 1549,
        name: "Västmanland County",
        state_code: "U",
        latitude: "59.67138790",
        longitude: "16.21589530",
        type: null,
      },
      {
        id: 1547,
        name: "Västra Götaland County",
        state_code: "O",
        latitude: "58.25279260",
        longitude: "13.05964250",
        type: null,
      },
    ],
  },
  {
    name: "Switzerland",
    iso3: "CHE",
    iso2: "CH",
    numeric_code: "756",
    phone_code: "41",
    capital: "Bern",
    currency: "CHF",
    currency_name: "Swiss franc",
    currency_symbol: "CHf",
    tld: ".ch",
    native: "Schweiz",
    region: "Europe",
    region_id: "4",
    subregion: "Western Europe",
    subregion_id: "17",
    nationality: "Swiss",
    timezones: [
      {
        zoneName: "Europe/Zurich",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "스위스",
      "pt-BR": "Suíça",
      pt: "Suíça",
      nl: "Zwitserland",
      hr: "Švicarska",
      fa: "سوئیس",
      de: "Schweiz",
      es: "Suiza",
      fr: "Suisse",
      ja: "スイス",
      it: "Svizzera",
      cn: "瑞士",
      tr: "İsviçre",
    },
    latitude: "47.00000000",
    longitude: "8.00000000",
    emoji: "🇨🇭",
    emojiU: "U+1F1E8 U+1F1ED",
    states: [
      {
        id: 1639,
        name: "Aargau",
        state_code: "AG",
        latitude: "47.38766640",
        longitude: "8.25542950",
        type: "canton",
      },
      {
        id: 1655,
        name: "Appenzell Ausserrhoden",
        state_code: "AR",
        latitude: "47.36648100",
        longitude: "9.30009160",
        type: "canton",
      },
      {
        id: 1649,
        name: "Appenzell Innerrhoden",
        state_code: "AI",
        latitude: "47.31619250",
        longitude: "9.43165730",
        type: "canton",
      },
      {
        id: 1641,
        name: "Basel-Land",
        state_code: "BL",
        latitude: "47.44181220",
        longitude: "7.76440020",
        type: "canton",
      },
      {
        id: 4957,
        name: "Basel-Stadt",
        state_code: "BS",
        latitude: "47.56666700",
        longitude: "7.60000000",
        type: "canton",
      },
      {
        id: 1645,
        name: "Bern",
        state_code: "BE",
        latitude: "46.79886210",
        longitude: "7.70807010",
        type: "canton",
      },
      {
        id: 1640,
        name: "Fribourg",
        state_code: "FR",
        latitude: "46.68167480",
        longitude: "7.11726350",
        type: "canton",
      },
      {
        id: 1647,
        name: "Geneva",
        state_code: "GE",
        latitude: "46.21800730",
        longitude: "6.12169250",
        type: "canton",
      },
      {
        id: 1661,
        name: "Glarus",
        state_code: "GL",
        latitude: "47.04112320",
        longitude: "9.06790000",
        type: "canton",
      },
      {
        id: 1660,
        name: "Graubünden",
        state_code: "GR",
        latitude: "46.65698710",
        longitude: "9.57802570",
        type: "canton",
      },
      {
        id: 1658,
        name: "Jura",
        state_code: "JU",
        latitude: "47.34444740",
        longitude: "7.14306080",
        type: "canton",
      },
      {
        id: 1663,
        name: "Lucerne",
        state_code: "LU",
        latitude: "47.07956710",
        longitude: "8.16624450",
        type: "canton",
      },
      {
        id: 1659,
        name: "Neuchâtel",
        state_code: "NE",
        latitude: "46.98998740",
        longitude: "6.92927320",
        type: "canton",
      },
      {
        id: 1652,
        name: "Nidwalden",
        state_code: "NW",
        latitude: "46.92670160",
        longitude: "8.38499820",
        type: "canton",
      },
      {
        id: 1650,
        name: "Obwalden",
        state_code: "OW",
        latitude: "46.87785800",
        longitude: "8.25124900",
        type: "canton",
      },
      {
        id: 1654,
        name: "Schaffhausen",
        state_code: "SH",
        latitude: "47.70093640",
        longitude: "8.56800400",
        type: "canton",
      },
      {
        id: 1653,
        name: "Schwyz",
        state_code: "SZ",
        latitude: "47.02071380",
        longitude: "8.65298840",
        type: "canton",
      },
      {
        id: 1662,
        name: "Solothurn",
        state_code: "SO",
        latitude: "47.33207170",
        longitude: "7.63883850",
        type: "canton",
      },
      {
        id: 1644,
        name: "St. Gallen",
        state_code: "SG",
        latitude: "47.14562540",
        longitude: "9.35043320",
        type: "canton",
      },
      {
        id: 1657,
        name: "Thurgau",
        state_code: "TG",
        latitude: "47.60378560",
        longitude: "9.05573710",
        type: "canton",
      },
      {
        id: 1643,
        name: "Ticino",
        state_code: "TI",
        latitude: "46.33173400",
        longitude: "8.80045290",
        type: "canton",
      },
      {
        id: 1642,
        name: "Uri",
        state_code: "UR",
        latitude: "41.48606470",
        longitude: "-71.53085370",
        type: "canton",
      },
      {
        id: 1648,
        name: "Valais",
        state_code: "VS",
        latitude: "46.19046140",
        longitude: "7.54492260",
        type: "canton",
      },
      {
        id: 1651,
        name: "Vaud",
        state_code: "VD",
        latitude: "46.56131350",
        longitude: "6.53676500",
        type: "canton",
      },
      {
        id: 1646,
        name: "Zug",
        state_code: "ZG",
        latitude: "47.16615050",
        longitude: "8.51547490",
        type: "canton",
      },
      {
        id: 1656,
        name: "Zürich",
        state_code: "ZH",
        latitude: "47.35953600",
        longitude: "8.63564520",
        type: "canton",
      },
    ],
  },
  {
    name: "Syria",
    iso3: "SYR",
    iso2: "SY",
    numeric_code: "760",
    phone_code: "963",
    capital: "Damascus",
    currency: "SYP",
    currency_name: "Syrian pound",
    currency_symbol: "LS",
    tld: ".sy",
    native: "سوريا",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Syrian",
    timezones: [
      {
        zoneName: "Asia/Damascus",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "시리아",
      "pt-BR": "Síria",
      pt: "Síria",
      nl: "Syrië",
      hr: "Sirija",
      fa: "سوریه",
      de: "Syrien",
      es: "Siria",
      fr: "Syrie",
      ja: "シリア・アラブ共和国",
      it: "Siria",
      cn: "叙利亚",
      tr: "Suriye",
    },
    latitude: "35.00000000",
    longitude: "38.00000000",
    emoji: "🇸🇾",
    emojiU: "U+1F1F8 U+1F1FE",
    states: [
      {
        id: 2941,
        name: "Al-Hasakah",
        state_code: "HA",
        latitude: "36.40551500",
        longitude: "40.79691490",
        type: null,
      },
      {
        id: 2944,
        name: "Al-Raqqah",
        state_code: "RA",
        latitude: "35.95941060",
        longitude: "38.99810520",
        type: null,
      },
      {
        id: 2946,
        name: "Aleppo",
        state_code: "HL",
        latitude: "36.22623930",
        longitude: "37.46813960",
        type: null,
      },
      {
        id: 2936,
        name: "As-Suwayda",
        state_code: "SU",
        latitude: "32.79891560",
        longitude: "36.78195050",
        type: null,
      },
      {
        id: 2939,
        name: "Damascus",
        state_code: "DI",
        latitude: "33.51514440",
        longitude: "36.39313540",
        type: null,
      },
      {
        id: 2945,
        name: "Daraa",
        state_code: "DR",
        latitude: "32.92488130",
        longitude: "36.17626150",
        type: null,
      },
      {
        id: 2937,
        name: "Deir ez-Zor",
        state_code: "DY",
        latitude: "35.28797980",
        longitude: "40.30886260",
        type: null,
      },
      {
        id: 2934,
        name: "Hama",
        state_code: "HM",
        latitude: "35.18878650",
        longitude: "37.21158290",
        type: null,
      },
      {
        id: 2942,
        name: "Homs",
        state_code: "HI",
        latitude: "34.25671230",
        longitude: "38.31657250",
        type: null,
      },
      {
        id: 2940,
        name: "Idlib",
        state_code: "ID",
        latitude: "35.82687980",
        longitude: "36.69572160",
        type: null,
      },
      {
        id: 2938,
        name: "Latakia",
        state_code: "LA",
        latitude: "35.61297910",
        longitude: "36.00232250",
        type: null,
      },
      {
        id: 2943,
        name: "Quneitra",
        state_code: "QU",
        latitude: "33.07763180",
        longitude: "35.89341360",
        type: null,
      },
      {
        id: 2935,
        name: "Rif Dimashq",
        state_code: "RD",
        latitude: "33.51672890",
        longitude: "36.95410700",
        type: null,
      },
      {
        id: 2947,
        name: "Tartus",
        state_code: "TA",
        latitude: "35.00066520",
        longitude: "36.00232250",
        type: null,
      },
    ],
  },
  {
    name: "Taiwan",
    iso3: "TWN",
    iso2: "TW",
    numeric_code: "158",
    phone_code: "886",
    capital: "Taipei",
    currency: "TWD",
    currency_name: "New Taiwan dollar",
    currency_symbol: "$",
    tld: ".tw",
    native: "臺灣",
    region: "Asia",
    region_id: "3",
    subregion: "Eastern Asia",
    subregion_id: "12",
    nationality: "Chinese, Taiwanese",
    timezones: [
      {
        zoneName: "Asia/Taipei",
        gmtOffset: 28800,
        gmtOffsetName: "UTC+08:00",
        abbreviation: "CST",
        tzName: "China Standard Time",
      },
    ],
    translations: {
      kr: "대만",
      "pt-BR": "Taiwan",
      pt: "Taiwan",
      nl: "Taiwan",
      hr: "Tajvan",
      fa: "تایوان",
      de: "Taiwan",
      es: "Taiwán",
      fr: "Taïwan",
      ja: "台湾（中華民国）",
      it: "Taiwan",
      cn: "中国台湾",
      tr: "Tayvan",
    },
    latitude: "23.50000000",
    longitude: "121.00000000",
    emoji: "🇹🇼",
    emojiU: "U+1F1F9 U+1F1FC",
    states: [
      {
        id: 3404,
        name: "Changhua",
        state_code: "CHA",
        latitude: "24.05179630",
        longitude: "120.51613520",
        type: "county",
      },
      {
        id: 3408,
        name: "Chiayi",
        state_code: "CYI",
        latitude: "23.45184280",
        longitude: "120.25546150",
        type: "city",
      },
      {
        id: 3418,
        name: "Chiayi",
        state_code: "CYQ",
        latitude: "23.48007510",
        longitude: "120.44911130",
        type: "county",
      },
      {
        id: 3417,
        name: "Hsinchu",
        state_code: "HSZ",
        latitude: "24.81382870",
        longitude: "120.96747980",
        type: "city",
      },
      {
        id: 3423,
        name: "Hsinchu",
        state_code: "HSQ",
        latitude: "24.83872260",
        longitude: "121.01772460",
        type: "county",
      },
      {
        id: 3411,
        name: "Hualien",
        state_code: "HUA",
        latitude: "23.98715890",
        longitude: "121.60157140",
        type: "county",
      },
      {
        id: 3412,
        name: "Kaohsiung",
        state_code: "KHH",
        latitude: "22.62727840",
        longitude: "120.30143530",
        type: "special municipality",
      },
      {
        id: 4965,
        name: "Keelung",
        state_code: "KEE",
        latitude: "25.12418620",
        longitude: "121.64758340",
        type: "city",
      },
      {
        id: 3415,
        name: "Kinmen",
        state_code: "KIN",
        latitude: "24.34877920",
        longitude: "118.32856440",
        type: "county",
      },
      {
        id: 3420,
        name: "Lienchiang",
        state_code: "LIE",
        latitude: "26.15055560",
        longitude: "119.92888890",
        type: "county",
      },
      {
        id: 3413,
        name: "Miaoli",
        state_code: "MIA",
        latitude: "24.56015900",
        longitude: "120.82142650",
        type: "county",
      },
      {
        id: 3407,
        name: "Nantou",
        state_code: "NAN",
        latitude: "23.96099810",
        longitude: "120.97186380",
        type: "county",
      },
      {
        id: 4966,
        name: "New Taipei",
        state_code: "NWT",
        latitude: "24.98752780",
        longitude: "121.36459470",
        type: "special municipality",
      },
      {
        id: 3403,
        name: "Penghu",
        state_code: "PEN",
        latitude: "23.57118990",
        longitude: "119.57931570",
        type: "county",
      },
      {
        id: 3405,
        name: "Pingtung",
        state_code: "PIF",
        latitude: "22.55197590",
        longitude: "120.54875970",
        type: "county",
      },
      {
        id: 3406,
        name: "Taichung",
        state_code: "TXG",
        latitude: "24.14773580",
        longitude: "120.67364820",
        type: "special municipality",
      },
      {
        id: 3421,
        name: "Tainan",
        state_code: "TNN",
        latitude: "22.99972810",
        longitude: "120.22702770",
        type: "special municipality",
      },
      {
        id: 3422,
        name: "Taipei",
        state_code: "TPE",
        latitude: "25.03296940",
        longitude: "121.56541770",
        type: "special municipality",
      },
      {
        id: 3410,
        name: "Taitung",
        state_code: "TTT",
        latitude: "22.79724470",
        longitude: "121.07137020",
        type: "county",
      },
      {
        id: 3419,
        name: "Taoyuan",
        state_code: "TAO",
        latitude: "24.99362810",
        longitude: "121.30097980",
        type: "special municipality",
      },
      {
        id: 3402,
        name: "Yilan",
        state_code: "ILA",
        latitude: "24.70210730",
        longitude: "121.73775020",
        type: "county",
      },
      {
        id: 3416,
        name: "Yunlin",
        state_code: "YUN",
        latitude: "23.70920330",
        longitude: "120.43133730",
        type: "county",
      },
    ],
  },
  {
    name: "Tajikistan",
    iso3: "TJK",
    iso2: "TJ",
    numeric_code: "762",
    phone_code: "992",
    capital: "Dushanbe",
    currency: "TJS",
    currency_name: "Tajikistani somoni",
    currency_symbol: "SM",
    tld: ".tj",
    native: "Тоҷикистон",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Tajikistani",
    timezones: [
      {
        zoneName: "Asia/Dushanbe",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TJT",
        tzName: "Tajikistan Time",
      },
    ],
    translations: {
      kr: "타지키스탄",
      "pt-BR": "Tajiquistão",
      pt: "Tajiquistão",
      nl: "Tadzjikistan",
      hr: "Tađikistan",
      fa: "تاجیکستان",
      de: "Tadschikistan",
      es: "Tayikistán",
      fr: "Tadjikistan",
      ja: "タジキスタン",
      it: "Tagikistan",
      cn: "塔吉克斯坦",
      tr: "Tacikistan",
    },
    latitude: "39.00000000",
    longitude: "71.00000000",
    emoji: "🇹🇯",
    emojiU: "U+1F1F9 U+1F1EF",
    states: [
      {
        id: 3397,
        name: "districts of Republican Subordination",
        state_code: "RA",
        latitude: "39.08579020",
        longitude: "70.24083250",
        type: null,
      },
      {
        id: 3399,
        name: "Gorno-Badakhshan Autonomous Province",
        state_code: "GB",
        latitude: "38.41273200",
        longitude: "73.08774900",
        type: null,
      },
      {
        id: 3398,
        name: "Khatlon Province",
        state_code: "KT",
        latitude: "37.91135620",
        longitude: "69.09702300",
        type: null,
      },
      {
        id: 3400,
        name: "Sughd Province",
        state_code: "SU",
        latitude: "39.51553260",
        longitude: "69.09702300",
        type: null,
      },
    ],
  },
  {
    name: "Tanzania",
    iso3: "TZA",
    iso2: "TZ",
    numeric_code: "834",
    phone_code: "255",
    capital: "Dodoma",
    currency: "TZS",
    currency_name: "Tanzanian shilling",
    currency_symbol: "TSh",
    tld: ".tz",
    native: "Tanzania",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Tanzanian",
    timezones: [
      {
        zoneName: "Africa/Dar_es_Salaam",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "탄자니아",
      "pt-BR": "Tanzânia",
      pt: "Tanzânia",
      nl: "Tanzania",
      hr: "Tanzanija",
      fa: "تانزانیا",
      de: "Tansania",
      es: "Tanzania",
      fr: "Tanzanie",
      ja: "タンザニア",
      it: "Tanzania",
      cn: "坦桑尼亚",
      tr: "Tanzanya",
    },
    latitude: "-6.00000000",
    longitude: "35.00000000",
    emoji: "🇹🇿",
    emojiU: "U+1F1F9 U+1F1FF",
    states: [
      {
        id: 1491,
        name: "Arusha",
        state_code: "01",
        latitude: "-3.38692540",
        longitude: "36.68299270",
        type: "Region",
      },
      {
        id: 1490,
        name: "Dar es Salaam",
        state_code: "02",
        latitude: "-6.79235400",
        longitude: "39.20832840",
        type: "Region",
      },
      {
        id: 1466,
        name: "Dodoma",
        state_code: "03",
        latitude: "-6.57382280",
        longitude: "36.26308460",
        type: "Region",
      },
      {
        id: 1481,
        name: "Geita",
        state_code: "27",
        latitude: "-2.82422570",
        longitude: "32.26538870",
        type: "Region",
      },
      {
        id: 1489,
        name: "Iringa",
        state_code: "04",
        latitude: "-7.78874420",
        longitude: "35.56578620",
        type: "Region",
      },
      {
        id: 1465,
        name: "Kagera",
        state_code: "05",
        latitude: "-1.30011150",
        longitude: "31.26263660",
        type: "Region",
      },
      {
        id: 1482,
        name: "Katavi",
        state_code: "28",
        latitude: "-6.36771250",
        longitude: "31.26263660",
        type: "Region",
      },
      {
        id: 1478,
        name: "Kigoma",
        state_code: "08",
        latitude: "-4.88240920",
        longitude: "29.66150550",
        type: "Region",
      },
      {
        id: 1467,
        name: "Kilimanjaro",
        state_code: "09",
        latitude: "-4.13369270",
        longitude: "37.80876930",
        type: "Region",
      },
      {
        id: 1483,
        name: "Lindi",
        state_code: "12",
        latitude: "-9.23433940",
        longitude: "38.31657250",
        type: "Region",
      },
      {
        id: 1484,
        name: "Manyara",
        state_code: "26",
        latitude: "-4.31500580",
        longitude: "36.95410700",
        type: "Region",
      },
      {
        id: 1468,
        name: "Mara",
        state_code: "13",
        latitude: "-1.77535380",
        longitude: "34.15319470",
        type: "Region",
      },
      {
        id: 4955,
        name: "Mbeya",
        state_code: "14",
        latitude: "-8.28661120",
        longitude: "32.81325370",
        type: "Region",
      },
      {
        id: 1470,
        name: "Morogoro",
        state_code: "16",
        latitude: "-8.81371730",
        longitude: "36.95410700",
        type: "Region",
      },
      {
        id: 1476,
        name: "Mtwara",
        state_code: "17",
        latitude: "-10.33984550",
        longitude: "40.16574660",
        type: "Region",
      },
      {
        id: 1479,
        name: "Mwanza",
        state_code: "18",
        latitude: "-2.46711970",
        longitude: "32.89868120",
        type: "Region",
      },
      {
        id: 1480,
        name: "Njombe",
        state_code: "29",
        latitude: "-9.24226320",
        longitude: "35.12687810",
        type: "Region",
      },
      {
        id: 1488,
        name: "Pemba North",
        state_code: "06",
        latitude: "-5.03193520",
        longitude: "39.77555710",
        type: "Region",
      },
      {
        id: 1472,
        name: "Pemba South",
        state_code: "10",
        latitude: "-5.31469610",
        longitude: "39.75495110",
        type: "Region",
      },
      {
        id: 1485,
        name: "Pwani",
        state_code: "19",
        latitude: "-7.32377140",
        longitude: "38.82054540",
        type: "Region",
      },
      {
        id: 1477,
        name: "Rukwa",
        state_code: "20",
        latitude: "-8.01094440",
        longitude: "31.44561790",
        type: "Region",
      },
      {
        id: 1486,
        name: "Ruvuma",
        state_code: "21",
        latitude: "-10.68787170",
        longitude: "36.26308460",
        type: "Region",
      },
      {
        id: 1463,
        name: "Shinyanga",
        state_code: "22",
        latitude: "-3.68099610",
        longitude: "33.42714030",
        type: "Region",
      },
      {
        id: 1464,
        name: "Simiyu",
        state_code: "30",
        latitude: "-2.83087380",
        longitude: "34.15319470",
        type: "Region",
      },
      {
        id: 1474,
        name: "Singida",
        state_code: "23",
        latitude: "-6.74533520",
        longitude: "34.15319470",
        type: "Region",
      },
      {
        id: 4956,
        name: "Songwe",
        state_code: "31",
        latitude: "-8.27261200",
        longitude: "31.71131740",
        type: "Region",
      },
      {
        id: 1469,
        name: "Tabora",
        state_code: "24",
        latitude: "-5.03421380",
        longitude: "32.80844960",
        type: "Region",
      },
      {
        id: 1487,
        name: "Tanga",
        state_code: "25",
        latitude: "-5.30497890",
        longitude: "38.31657250",
        type: "Region",
      },
      {
        id: 1473,
        name: "Zanzibar North",
        state_code: "07",
        latitude: "-5.93950930",
        longitude: "39.27910110",
        type: "Region",
      },
      {
        id: 1471,
        name: "Zanzibar South",
        state_code: "11",
        latitude: "-6.26428510",
        longitude: "39.44502810",
        type: "Region",
      },
      {
        id: 1475,
        name: "Zanzibar West",
        state_code: "15",
        latitude: "-6.22981360",
        longitude: "39.25832930",
        type: "Region",
      },
    ],
  },
  {
    name: "Thailand",
    iso3: "THA",
    iso2: "TH",
    numeric_code: "764",
    phone_code: "66",
    capital: "Bangkok",
    currency: "THB",
    currency_name: "Thai baht",
    currency_symbol: "฿",
    tld: ".th",
    native: "ประเทศไทย",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Thai",
    timezones: [
      {
        zoneName: "Asia/Bangkok",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "태국",
      "pt-BR": "Tailândia",
      pt: "Tailândia",
      nl: "Thailand",
      hr: "Tajland",
      fa: "تایلند",
      de: "Thailand",
      es: "Tailandia",
      fr: "Thaïlande",
      ja: "タイ",
      it: "Tailandia",
      cn: "泰国",
      tr: "Tayland",
    },
    latitude: "15.00000000",
    longitude: "100.00000000",
    emoji: "🇹🇭",
    emojiU: "U+1F1F9 U+1F1ED",
    states: [
      {
        id: 3523,
        name: "Amnat Charoen",
        state_code: "37",
        latitude: "15.86567830",
        longitude: "104.62577740",
        type: null,
      },
      {
        id: 3519,
        name: "Ang Thong",
        state_code: "15",
        latitude: "14.58960540",
        longitude: "100.45505200",
        type: null,
      },
      {
        id: 3554,
        name: "Bangkok",
        state_code: "10",
        latitude: "13.75633090",
        longitude: "100.50176510",
        type: null,
      },
      {
        id: 3533,
        name: "Bueng Kan",
        state_code: "38",
        latitude: "18.36091040",
        longitude: "103.64644630",
        type: null,
      },
      {
        id: 3534,
        name: "Buri Ram",
        state_code: "31",
        latitude: "14.99510030",
        longitude: "103.11159150",
        type: null,
      },
      {
        id: 3552,
        name: "Chachoengsao",
        state_code: "24",
        latitude: "13.69041940",
        longitude: "101.07795960",
        type: null,
      },
      {
        id: 3522,
        name: "Chai Nat",
        state_code: "18",
        latitude: "15.18519710",
        longitude: "100.12512500",
        type: null,
      },
      {
        id: 4954,
        name: "Chaiyaphum",
        state_code: "36",
        latitude: "16.00749740",
        longitude: "101.61291720",
        type: null,
      },
      {
        id: 3486,
        name: "Chanthaburi",
        state_code: "22",
        latitude: "12.61124850",
        longitude: "102.10378060",
        type: null,
      },
      {
        id: 3491,
        name: "Chiang Mai",
        state_code: "50",
        latitude: "18.78834390",
        longitude: "98.98530080",
        type: null,
      },
      {
        id: 3498,
        name: "Chiang Rai",
        state_code: "57",
        latitude: "19.91047980",
        longitude: "99.84057600",
        type: null,
      },
      {
        id: 3513,
        name: "Chon Buri",
        state_code: "20",
        latitude: "13.36114310",
        longitude: "100.98467170",
        type: null,
      },
      {
        id: 3526,
        name: "Chumphon",
        state_code: "86",
        latitude: "10.49304960",
        longitude: "99.18001990",
        type: null,
      },
      {
        id: 3550,
        name: "Kalasin",
        state_code: "46",
        latitude: "16.43850800",
        longitude: "103.50609940",
        type: null,
      },
      {
        id: 3516,
        name: "Kamphaeng Phet",
        state_code: "62",
        latitude: "16.48277980",
        longitude: "99.52266180",
        type: null,
      },
      {
        id: 3511,
        name: "Kanchanaburi",
        state_code: "71",
        latitude: "14.10113930",
        longitude: "99.41794310",
        type: null,
      },
      {
        id: 3485,
        name: "Khon Kaen",
        state_code: "40",
        latitude: "16.43219380",
        longitude: "102.82362140",
        type: null,
      },
      {
        id: 3478,
        name: "Krabi",
        state_code: "81",
        latitude: "8.08629970",
        longitude: "98.90628350",
        type: null,
      },
      {
        id: 3544,
        name: "Lampang",
        state_code: "52",
        latitude: "18.28553950",
        longitude: "99.51278950",
        type: null,
      },
      {
        id: 3483,
        name: "Lamphun",
        state_code: "51",
        latitude: "18.57446060",
        longitude: "99.00872210",
        type: null,
      },
      {
        id: 3509,
        name: "Loei",
        state_code: "42",
        latitude: "17.48602320",
        longitude: "101.72230020",
        type: null,
      },
      {
        id: 3543,
        name: "Lop Buri",
        state_code: "16",
        latitude: "14.79950810",
        longitude: "100.65337060",
        type: null,
      },
      {
        id: 3505,
        name: "Mae Hong Son",
        state_code: "58",
        latitude: "19.30202960",
        longitude: "97.96543680",
        type: null,
      },
      {
        id: 3517,
        name: "Maha Sarakham",
        state_code: "44",
        latitude: "16.01320150",
        longitude: "103.16151690",
        type: null,
      },
      {
        id: 3546,
        name: "Mukdahan",
        state_code: "49",
        latitude: "16.54359140",
        longitude: "104.70241210",
        type: null,
      },
      {
        id: 3535,
        name: "Nakhon Nayok",
        state_code: "26",
        latitude: "14.20694660",
        longitude: "101.21305110",
        type: null,
      },
      {
        id: 3503,
        name: "Nakhon Pathom",
        state_code: "73",
        latitude: "13.81402930",
        longitude: "100.03729290",
        type: null,
      },
      {
        id: 3548,
        name: "Nakhon Phanom",
        state_code: "48",
        latitude: "17.39203900",
        longitude: "104.76955080",
        type: null,
      },
      {
        id: 3497,
        name: "Nakhon Ratchasima",
        state_code: "30",
        latitude: "14.97384930",
        longitude: "102.08365200",
        type: null,
      },
      {
        id: 3492,
        name: "Nakhon Sawan",
        state_code: "60",
        latitude: "15.69873820",
        longitude: "100.11996000",
        type: null,
      },
      {
        id: 3520,
        name: "Nakhon Si Thammarat",
        state_code: "80",
        latitude: "8.43248310",
        longitude: "99.95990330",
        type: null,
      },
      {
        id: 3530,
        name: "Nan",
        state_code: "55",
        latitude: "45.52220800",
        longitude: "-122.98632810",
        type: null,
      },
      {
        id: 3553,
        name: "Narathiwat",
        state_code: "96",
        latitude: "6.42546070",
        longitude: "101.82531430",
        type: null,
      },
      {
        id: 3480,
        name: "Nong Bua Lam Phu",
        state_code: "39",
        latitude: "17.22182470",
        longitude: "102.42603680",
        type: null,
      },
      {
        id: 3484,
        name: "Nong Khai",
        state_code: "43",
        latitude: "17.87828030",
        longitude: "102.74126380",
        type: null,
      },
      {
        id: 3495,
        name: "Nonthaburi",
        state_code: "12",
        latitude: "13.85910840",
        longitude: "100.52165080",
        type: null,
      },
      {
        id: 3500,
        name: "Pathum Thani",
        state_code: "13",
        latitude: "14.02083910",
        longitude: "100.52502760",
        type: null,
      },
      {
        id: 3540,
        name: "Pattani",
        state_code: "94",
        latitude: "6.76183080",
        longitude: "101.32325490",
        type: null,
      },
      {
        id: 3507,
        name: "Pattaya",
        state_code: "S",
        latitude: "12.92355570",
        longitude: "100.88245510",
        type: null,
      },
      {
        id: 3549,
        name: "Phangnga",
        state_code: "82",
        latitude: "8.45014140",
        longitude: "98.52553170",
        type: null,
      },
      {
        id: 3488,
        name: "Phatthalung",
        state_code: "93",
        latitude: "7.61668230",
        longitude: "100.07402310",
        type: null,
      },
      {
        id: 3538,
        name: "Phayao",
        state_code: "56",
        latitude: "19.21543670",
        longitude: "100.20236920",
        type: null,
      },
      {
        id: 3515,
        name: "Phetchabun",
        state_code: "67",
        latitude: "16.30166900",
        longitude: "101.11928040",
        type: null,
      },
      {
        id: 3532,
        name: "Phetchaburi",
        state_code: "76",
        latitude: "12.96492150",
        longitude: "99.64258830",
        type: null,
      },
      {
        id: 3514,
        name: "Phichit",
        state_code: "66",
        latitude: "16.27408760",
        longitude: "100.33469910",
        type: null,
      },
      {
        id: 3506,
        name: "Phitsanulok",
        state_code: "65",
        latitude: "16.82112380",
        longitude: "100.26585160",
        type: null,
      },
      {
        id: 3494,
        name: "Phra Nakhon Si Ayutthaya",
        state_code: "14",
        latitude: "14.36923250",
        longitude: "100.58766340",
        type: null,
      },
      {
        id: 3528,
        name: "Phrae",
        state_code: "54",
        latitude: "18.14457740",
        longitude: "100.14028310",
        type: null,
      },
      {
        id: 3536,
        name: "Phuket",
        state_code: "83",
        latitude: "7.88044790",
        longitude: "98.39225040",
        type: null,
      },
      {
        id: 3542,
        name: "Prachin Buri",
        state_code: "25",
        latitude: "14.04206990",
        longitude: "101.66008740",
        type: null,
      },
      {
        id: 3508,
        name: "Prachuap Khiri Khan",
        state_code: "77",
        latitude: "11.79383890",
        longitude: "99.79575640",
        type: null,
      },
      {
        id: 3479,
        name: "Ranong",
        state_code: "85",
        latitude: "9.95287020",
        longitude: "98.60846410",
        type: null,
      },
      {
        id: 3499,
        name: "Ratchaburi",
        state_code: "70",
        latitude: "13.52828930",
        longitude: "99.81342110",
        type: null,
      },
      {
        id: 3518,
        name: "Rayong",
        state_code: "21",
        latitude: "12.68139570",
        longitude: "101.28162610",
        type: null,
      },
      {
        id: 3510,
        name: "Roi Et",
        state_code: "45",
        latitude: "16.05381960",
        longitude: "103.65200360",
        type: null,
      },
      {
        id: 3529,
        name: "Sa Kaeo",
        state_code: "27",
        latitude: "13.82403800",
        longitude: "102.06458390",
        type: null,
      },
      {
        id: 3501,
        name: "Sakon Nakhon",
        state_code: "47",
        latitude: "17.16642110",
        longitude: "104.14860550",
        type: null,
      },
      {
        id: 3481,
        name: "Samut Prakan",
        state_code: "11",
        latitude: "13.59909610",
        longitude: "100.59983190",
        type: null,
      },
      {
        id: 3504,
        name: "Samut Sakhon",
        state_code: "74",
        latitude: "13.54752160",
        longitude: "100.27439560",
        type: null,
      },
      {
        id: 3502,
        name: "Samut Songkhram",
        state_code: "75",
        latitude: "13.40982170",
        longitude: "100.00226450",
        type: null,
      },
      {
        id: 3487,
        name: "Saraburi",
        state_code: "19",
        latitude: "14.52891540",
        longitude: "100.91014210",
        type: null,
      },
      {
        id: 3537,
        name: "Satun",
        state_code: "91",
        latitude: "6.62381580",
        longitude: "100.06737440",
        type: null,
      },
      {
        id: 3547,
        name: "Si Sa Ket",
        state_code: "33",
        latitude: "15.11860090",
        longitude: "104.32200950",
        type: null,
      },
      {
        id: 3490,
        name: "Sing Buri",
        state_code: "17",
        latitude: "14.89362530",
        longitude: "100.39673140",
        type: null,
      },
      {
        id: 3539,
        name: "Songkhla",
        state_code: "90",
        latitude: "7.18976590",
        longitude: "100.59538130",
        type: null,
      },
      {
        id: 3545,
        name: "Sukhothai",
        state_code: "64",
        latitude: "43.64855560",
        longitude: "-79.37466390",
        type: null,
      },
      {
        id: 3524,
        name: "Suphan Buri",
        state_code: "72",
        latitude: "14.47448920",
        longitude: "100.11771280",
        type: null,
      },
      {
        id: 3482,
        name: "Surat Thani",
        state_code: "84",
        latitude: "9.13419490",
        longitude: "99.33341980",
        type: null,
      },
      {
        id: 3531,
        name: "Surin",
        state_code: "32",
        latitude: "37.03582710",
        longitude: "-95.62763670",
        type: null,
      },
      {
        id: 3525,
        name: "Tak",
        state_code: "63",
        latitude: "45.02996460",
        longitude: "-93.10498150",
        type: null,
      },
      {
        id: 3541,
        name: "Trang",
        state_code: "92",
        latitude: "7.56448330",
        longitude: "99.62393340",
        type: null,
      },
      {
        id: 3496,
        name: "Trat",
        state_code: "23",
        latitude: "12.24275630",
        longitude: "102.51747340",
        type: null,
      },
      {
        id: 3512,
        name: "Ubon Ratchathani",
        state_code: "34",
        latitude: "15.24484530",
        longitude: "104.84729950",
        type: null,
      },
      {
        id: 3527,
        name: "Udon Thani",
        state_code: "41",
        latitude: "17.36469690",
        longitude: "102.81589240",
        type: null,
      },
      {
        id: 3551,
        name: "Uthai Thani",
        state_code: "61",
        latitude: "15.38350010",
        longitude: "100.02455270",
        type: null,
      },
      {
        id: 3489,
        name: "Uttaradit",
        state_code: "53",
        latitude: "17.62008860",
        longitude: "100.09929420",
        type: null,
      },
      {
        id: 3493,
        name: "Yala",
        state_code: "95",
        latitude: "44.05791170",
        longitude: "-123.16538480",
        type: null,
      },
      {
        id: 3521,
        name: "Yasothon",
        state_code: "35",
        latitude: "15.79264100",
        longitude: "104.14528270",
        type: null,
      },
    ],
  },
  {
    name: "The Bahamas",
    iso3: "BHS",
    iso2: "BS",
    numeric_code: "044",
    phone_code: "+1-242",
    capital: "Nassau",
    currency: "BSD",
    currency_name: "Bahamian dollar",
    currency_symbol: "B$",
    tld: ".bs",
    native: "Bahamas",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Bahamian",
    timezones: [
      {
        zoneName: "America/Nassau",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America)",
      },
    ],
    translations: {
      kr: "바하마",
      "pt-BR": "Bahamas",
      pt: "Baamas",
      nl: "Bahama’s",
      hr: "Bahami",
      fa: "باهاما",
      de: "Bahamas",
      es: "Bahamas",
      fr: "Bahamas",
      ja: "バハマ",
      it: "Bahamas",
      cn: "巴哈马",
      tr: "Bahamalar",
    },
    latitude: "24.25000000",
    longitude: "-76.00000000",
    emoji: "🇧🇸",
    emojiU: "U+1F1E7 U+1F1F8",
    states: [
      {
        id: 3601,
        name: "Acklins",
        state_code: "AK",
        latitude: "22.36577080",
        longitude: "-74.05351260",
        type: null,
      },
      {
        id: 3628,
        name: "Acklins and Crooked Islands",
        state_code: "AC",
        latitude: "22.36577080",
        longitude: "-74.05351260",
        type: null,
      },
      {
        id: 3593,
        name: "Berry Islands",
        state_code: "BY",
        latitude: "25.62500420",
        longitude: "-77.82522030",
        type: null,
      },
      {
        id: 3629,
        name: "Bimini",
        state_code: "BI",
        latitude: "24.64153250",
        longitude: "-79.85062260",
        type: null,
      },
      {
        id: 3605,
        name: "Black Point",
        state_code: "BP",
        latitude: "41.39510240",
        longitude: "-71.46505560",
        type: null,
      },
      {
        id: 3611,
        name: "Cat Island",
        state_code: "CI",
        latitude: "30.22801360",
        longitude: "-89.10149330",
        type: null,
      },
      {
        id: 3603,
        name: "Central Abaco",
        state_code: "CO",
        latitude: "26.35550290",
        longitude: "-77.14851630",
        type: null,
      },
      {
        id: 3631,
        name: "Central Andros",
        state_code: "CS",
        latitude: "24.46884820",
        longitude: "-77.97386500",
        type: null,
      },
      {
        id: 3596,
        name: "Central Eleuthera",
        state_code: "CE",
        latitude: "25.13620370",
        longitude: "-76.14359150",
        type: null,
      },
      {
        id: 3621,
        name: "Crooked Island",
        state_code: "CK",
        latitude: "22.63909820",
        longitude: "-74.00650900",
        type: null,
      },
      {
        id: 3614,
        name: "East Grand Bahama",
        state_code: "EG",
        latitude: "26.65828230",
        longitude: "-78.22482910",
        type: null,
      },
      {
        id: 3612,
        name: "Exuma",
        state_code: "EX",
        latitude: "23.61925980",
        longitude: "-75.96954650",
        type: null,
      },
      {
        id: 3626,
        name: "Freeport",
        state_code: "FP",
        latitude: "42.29668610",
        longitude: "-89.62122710",
        type: null,
      },
      {
        id: 3619,
        name: "Fresh Creek",
        state_code: "FC",
        latitude: "40.65437560",
        longitude: "-73.89479390",
        type: null,
      },
      {
        id: 3597,
        name: "Governor's Harbour",
        state_code: "GH",
        latitude: "25.19480960",
        longitude: "-76.24396220",
        type: null,
      },
      {
        id: 3632,
        name: "Grand Cay",
        state_code: "GC",
        latitude: "27.21626150",
        longitude: "-78.32305590",
        type: null,
      },
      {
        id: 3595,
        name: "Green Turtle Cay",
        state_code: "GT",
        latitude: "26.77471070",
        longitude: "-77.32957080",
        type: null,
      },
      {
        id: 3613,
        name: "Harbour Island",
        state_code: "HI",
        latitude: "25.50011000",
        longitude: "-76.63405110",
        type: null,
      },
      {
        id: 3598,
        name: "High Rock",
        state_code: "HR",
        latitude: "46.68434150",
        longitude: "-121.90174610",
        type: null,
      },
      {
        id: 3624,
        name: "Hope Town",
        state_code: "HT",
        latitude: "26.50095040",
        longitude: "-76.99598720",
        type: null,
      },
      {
        id: 3609,
        name: "Inagua",
        state_code: "IN",
        latitude: "21.06560660",
        longitude: "-73.32370800",
        type: null,
      },
      {
        id: 3618,
        name: "Kemps Bay",
        state_code: "KB",
        latitude: "24.02364000",
        longitude: "-77.54534900",
        type: null,
      },
      {
        id: 3610,
        name: "Long Island",
        state_code: "LI",
        latitude: "40.78914200",
        longitude: "-73.13496100",
        type: null,
      },
      {
        id: 3625,
        name: "Mangrove Cay",
        state_code: "MC",
        latitude: "24.14814250",
        longitude: "-77.76809520",
        type: null,
      },
      {
        id: 3604,
        name: "Marsh Harbour",
        state_code: "MH",
        latitude: "26.52416530",
        longitude: "-77.09098090",
        type: null,
      },
      {
        id: 3633,
        name: "Mayaguana District",
        state_code: "MG",
        latitude: "22.40177140",
        longitude: "-73.06413960",
        type: null,
      },
      {
        id: 4881,
        name: "New Providence",
        state_code: "NP",
        latitude: "40.69843480",
        longitude: "-74.40154050",
        type: null,
      },
      {
        id: 3594,
        name: "Nichollstown and Berry Islands",
        state_code: "NB",
        latitude: "25.72362340",
        longitude: "-77.83101040",
        type: null,
      },
      {
        id: 3616,
        name: "North Abaco",
        state_code: "NO",
        latitude: "26.78716970",
        longitude: "-77.43577390",
        type: null,
      },
      {
        id: 3617,
        name: "North Andros",
        state_code: "NS",
        latitude: "24.70638050",
        longitude: "-78.01953870",
        type: null,
      },
      {
        id: 3602,
        name: "North Eleuthera",
        state_code: "NE",
        latitude: "25.46475170",
        longitude: "-76.67592200",
        type: null,
      },
      {
        id: 3615,
        name: "Ragged Island",
        state_code: "RI",
        latitude: "41.59743100",
        longitude: "-71.26020200",
        type: null,
      },
      {
        id: 3623,
        name: "Rock Sound",
        state_code: "RS",
        latitude: "39.01424430",
        longitude: "-95.67089890",
        type: null,
      },
      {
        id: 3600,
        name: "Rum Cay District",
        state_code: "RC",
        latitude: "23.68546760",
        longitude: "-74.83901620",
        type: null,
      },
      {
        id: 3620,
        name: "San Salvador and Rum Cay",
        state_code: "SR",
        latitude: "23.68546760",
        longitude: "-74.83901620",
        type: null,
      },
      {
        id: 3627,
        name: "San Salvador Island",
        state_code: "SS",
        latitude: "24.07755460",
        longitude: "-74.47600880",
        type: null,
      },
      {
        id: 3606,
        name: "Sandy Point",
        state_code: "SP",
        latitude: "39.01454640",
        longitude: "-76.39989250",
        type: null,
      },
      {
        id: 3608,
        name: "South Abaco",
        state_code: "SO",
        latitude: "26.06405910",
        longitude: "-77.26350380",
        type: null,
      },
      {
        id: 3622,
        name: "South Andros",
        state_code: "SA",
        latitude: "23.97135560",
        longitude: "-77.60778650",
        type: null,
      },
      {
        id: 3607,
        name: "South Eleuthera",
        state_code: "SE",
        latitude: "24.77085620",
        longitude: "-76.21314740",
        type: null,
      },
      {
        id: 3630,
        name: "Spanish Wells",
        state_code: "SW",
        latitude: "26.32505990",
        longitude: "-81.79803280",
        type: null,
      },
      {
        id: 3599,
        name: "West Grand Bahama",
        state_code: "WG",
        latitude: "26.65944700",
        longitude: "-78.52065000",
        type: null,
      },
    ],
  },
  {
    name: "Timor-Leste",
    iso3: "TLS",
    iso2: "TL",
    numeric_code: "626",
    phone_code: "670",
    capital: "Dili",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tl",
    native: "Timor-Leste",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Timorese",
    timezones: [
      {
        zoneName: "Asia/Dili",
        gmtOffset: 32400,
        gmtOffsetName: "UTC+09:00",
        abbreviation: "TLT",
        tzName: "Timor Leste Time",
      },
    ],
    translations: {
      kr: "동티모르",
      "pt-BR": "Timor Leste",
      pt: "Timor Leste",
      nl: "Oost-Timor",
      hr: "Istočni Timor",
      fa: "تیمور شرقی",
      de: "Timor-Leste",
      es: "Timor Oriental",
      fr: "Timor oriental",
      ja: "東ティモール",
      it: "Timor Est",
      cn: "东帝汶",
      tr: "Doğu Timor",
    },
    latitude: "-8.83333333",
    longitude: "125.91666666",
    emoji: "🇹🇱",
    emojiU: "U+1F1F9 U+1F1F1",
    states: [
      {
        id: 4520,
        name: "Aileu municipality",
        state_code: "AL",
        latitude: "-8.70439940",
        longitude: "125.60954740",
        type: null,
      },
      {
        id: 4518,
        name: "Ainaro Municipality",
        state_code: "AN",
        latitude: "-9.01131710",
        longitude: "125.52200120",
        type: null,
      },
      {
        id: 4521,
        name: "Baucau Municipality",
        state_code: "BA",
        latitude: "-8.47143080",
        longitude: "126.45759910",
        type: null,
      },
      {
        id: 4525,
        name: "Bobonaro Municipality",
        state_code: "BO",
        latitude: "-8.96554060",
        longitude: "125.25879640",
        type: null,
      },
      {
        id: 4522,
        name: "Cova Lima Municipality",
        state_code: "CO",
        latitude: "-9.26503750",
        longitude: "125.25879640",
        type: null,
      },
      {
        id: 4524,
        name: "Dili municipality",
        state_code: "DI",
        latitude: "-8.24496130",
        longitude: "125.58766970",
        type: null,
      },
      {
        id: 4516,
        name: "Ermera District",
        state_code: "ER",
        latitude: "-8.75248020",
        longitude: "125.39872940",
        type: null,
      },
      {
        id: 4523,
        name: "Lautém Municipality",
        state_code: "LA",
        latitude: "-8.36423070",
        longitude: "126.90438450",
        type: null,
      },
      {
        id: 4515,
        name: "Liquiçá Municipality",
        state_code: "LI",
        latitude: "-8.66740950",
        longitude: "125.25879640",
        type: null,
      },
      {
        id: 4517,
        name: "Manatuto District",
        state_code: "MT",
        latitude: "-8.51556080",
        longitude: "126.01592550",
        type: null,
      },
      {
        id: 4519,
        name: "Manufahi Municipality",
        state_code: "MF",
        latitude: "-9.01454950",
        longitude: "125.82799590",
        type: null,
      },
      {
        id: 4514,
        name: "Viqueque Municipality",
        state_code: "VI",
        latitude: "-8.85979180",
        longitude: "126.36335160",
        type: null,
      },
    ],
  },
  {
    name: "Togo",
    iso3: "TGO",
    iso2: "TG",
    numeric_code: "768",
    phone_code: "228",
    capital: "Lome",
    currency: "XOF",
    currency_name: "West African CFA franc",
    currency_symbol: "CFA",
    tld: ".tg",
    native: "Togo",
    region: "Africa",
    region_id: "1",
    subregion: "Western Africa",
    subregion_id: "3",
    nationality: "Togolese",
    timezones: [
      {
        zoneName: "Africa/Lome",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "토고",
      "pt-BR": "Togo",
      pt: "Togo",
      nl: "Togo",
      hr: "Togo",
      fa: "توگو",
      de: "Togo",
      es: "Togo",
      fr: "Togo",
      ja: "トーゴ",
      it: "Togo",
      cn: "多哥",
      tr: "Togo",
    },
    latitude: "8.00000000",
    longitude: "1.16666666",
    emoji: "🇹🇬",
    emojiU: "U+1F1F9 U+1F1EC",
    states: [
      {
        id: 2575,
        name: "Centrale Region",
        state_code: "C",
        latitude: "8.65860290",
        longitude: "1.05861350",
        type: null,
      },
      {
        id: 2579,
        name: "Kara Region",
        state_code: "K",
        latitude: "9.72163930",
        longitude: "1.05861350",
        type: null,
      },
      {
        id: 2576,
        name: "Maritime",
        state_code: "M",
        latitude: "41.65514930",
        longitude: "-83.52784670",
        type: null,
      },
      {
        id: 2577,
        name: "Plateaux Region",
        state_code: "P",
        latitude: "7.61013780",
        longitude: "1.05861350",
        type: null,
      },
      {
        id: 2578,
        name: "Savanes Region",
        state_code: "S",
        latitude: "10.52917810",
        longitude: "0.52578230",
        type: null,
      },
    ],
  },
  {
    name: "Tokelau",
    iso3: "TKL",
    iso2: "TK",
    numeric_code: "772",
    phone_code: "690",
    capital: "",
    currency: "NZD",
    currency_name: "New Zealand dollar",
    currency_symbol: "$",
    tld: ".tk",
    native: "Tokelau",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tokelauan",
    timezones: [
      {
        zoneName: "Pacific/Fakaofo",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TKT",
        tzName: "Tokelau Time",
      },
    ],
    translations: {
      kr: "토켈라우",
      "pt-BR": "Tokelau",
      pt: "Toquelau",
      nl: "Tokelau",
      hr: "Tokelau",
      fa: "توکلائو",
      de: "Tokelau",
      es: "Islas Tokelau",
      fr: "Tokelau",
      ja: "トケラウ",
      it: "Isole Tokelau",
      cn: "托克劳",
      tr: "Tokelau",
    },
    latitude: "-9.00000000",
    longitude: "-172.00000000",
    emoji: "🇹🇰",
    emojiU: "U+1F1F9 U+1F1F0",
    states: [],
  },
  {
    name: "Tonga",
    iso3: "TON",
    iso2: "TO",
    numeric_code: "776",
    phone_code: "676",
    capital: "Nuku'alofa",
    currency: "TOP",
    currency_name: "Tongan paʻanga",
    currency_symbol: "$",
    tld: ".to",
    native: "Tonga",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tongan",
    timezones: [
      {
        zoneName: "Pacific/Tongatapu",
        gmtOffset: 46800,
        gmtOffsetName: "UTC+13:00",
        abbreviation: "TOT",
        tzName: "Tonga Time",
      },
    ],
    translations: {
      kr: "통가",
      "pt-BR": "Tonga",
      pt: "Tonga",
      nl: "Tonga",
      hr: "Tonga",
      fa: "تونگا",
      de: "Tonga",
      es: "Tonga",
      fr: "Tonga",
      ja: "トンガ",
      it: "Tonga",
      cn: "汤加",
      tr: "Tonga",
    },
    latitude: "-20.00000000",
    longitude: "-175.00000000",
    emoji: "🇹🇴",
    emojiU: "U+1F1F9 U+1F1F4",
    states: [
      {
        id: 3913,
        name: "Haʻapai",
        state_code: "02",
        latitude: "-19.75000000",
        longitude: "-174.36666700",
        type: null,
      },
      {
        id: 3915,
        name: "ʻEua",
        state_code: "01",
        latitude: "37.09024000",
        longitude: "-95.71289100",
        type: null,
      },
      {
        id: 3914,
        name: "Niuas",
        state_code: "03",
        latitude: "-15.95940000",
        longitude: "-173.78300000",
        type: null,
      },
      {
        id: 3912,
        name: "Tongatapu",
        state_code: "04",
        latitude: "-21.14659680",
        longitude: "-175.25154820",
        type: null,
      },
      {
        id: 3911,
        name: "Vavaʻu",
        state_code: "05",
        latitude: "-18.62275600",
        longitude: "-173.99029820",
        type: null,
      },
    ],
  },
  {
    name: "Trinidad and Tobago",
    iso3: "TTO",
    iso2: "TT",
    numeric_code: "780",
    phone_code: "+1-868",
    capital: "Port of Spain",
    currency: "TTD",
    currency_name: "Trinidad and Tobago dollar",
    currency_symbol: "$",
    tld: ".tt",
    native: "Trinidad and Tobago",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Trinidadian or Tobagonian",
    timezones: [
      {
        zoneName: "America/Port_of_Spain",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "트리니다드 토바고",
      "pt-BR": "Trinidad e Tobago",
      pt: "Trindade e Tobago",
      nl: "Trinidad en Tobago",
      hr: "Trinidad i Tobago",
      fa: "ترینیداد و توباگو",
      de: "Trinidad und Tobago",
      es: "Trinidad y Tobago",
      fr: "Trinité et Tobago",
      ja: "トリニダード・トバゴ",
      it: "Trinidad e Tobago",
      cn: "特立尼达和多巴哥",
      tr: "Trinidad Ve Tobago",
    },
    latitude: "11.00000000",
    longitude: "-61.00000000",
    emoji: "🇹🇹",
    emojiU: "U+1F1F9 U+1F1F9",
    states: [
      {
        id: 3362,
        name: "Arima",
        state_code: "ARI",
        latitude: "46.79316040",
        longitude: "-71.25843110",
        type: null,
      },
      {
        id: 3366,
        name: "Chaguanas",
        state_code: "CHA",
        latitude: "10.51683870",
        longitude: "-61.41144820",
        type: null,
      },
      {
        id: 3354,
        name: "Couva-Tabaquite-Talparo Regional Corporation",
        state_code: "CTT",
        latitude: "10.42971450",
        longitude: "-61.37352100",
        type: null,
      },
      {
        id: 3367,
        name: "Diego Martin Regional Corporation",
        state_code: "DMN",
        latitude: "10.73622860",
        longitude: "-61.55448360",
        type: null,
      },
      {
        id: 3355,
        name: "Eastern Tobago",
        state_code: "ETO",
        latitude: "11.29793480",
        longitude: "-60.55885240",
        type: null,
      },
      {
        id: 3365,
        name: "Penal-Debe Regional Corporation",
        state_code: "PED",
        latitude: "10.13374020",
        longitude: "-61.44354740",
        type: null,
      },
      {
        id: 3360,
        name: "Point Fortin",
        state_code: "PTF",
        latitude: "10.17027370",
        longitude: "-61.67133860",
        type: null,
      },
      {
        id: 3363,
        name: "Port of Spain",
        state_code: "POS",
        latitude: "10.66031960",
        longitude: "-61.50856250",
        type: null,
      },
      {
        id: 3368,
        name: "Princes Town Regional Corporation",
        state_code: "PRT",
        latitude: "10.17867460",
        longitude: "-61.28019960",
        type: null,
      },
      {
        id: 3356,
        name: "Rio Claro-Mayaro Regional Corporation",
        state_code: "MRC",
        latitude: "10.24128320",
        longitude: "-61.09372060",
        type: null,
      },
      {
        id: 3359,
        name: "San Fernando",
        state_code: "SFO",
        latitude: "34.28194610",
        longitude: "-118.43897190",
        type: null,
      },
      {
        id: 3357,
        name: "San Juan-Laventille Regional Corporation",
        state_code: "SJL",
        latitude: "10.69085780",
        longitude: "-61.45522130",
        type: null,
      },
      {
        id: 3361,
        name: "Sangre Grande Regional Corporation",
        state_code: "SGE",
        latitude: "10.58529390",
        longitude: "-61.13158130",
        type: null,
      },
      {
        id: 3364,
        name: "Siparia Regional Corporation",
        state_code: "SIP",
        latitude: "10.12456260",
        longitude: "-61.56032440",
        type: null,
      },
      {
        id: 3358,
        name: "Tunapuna-Piarco Regional Corporation",
        state_code: "TUP",
        latitude: "10.68590960",
        longitude: "-61.30352480",
        type: null,
      },
      {
        id: 3353,
        name: "Western Tobago",
        state_code: "WTO",
        latitude: "11.18970720",
        longitude: "-60.77954520",
        type: null,
      },
    ],
  },
  {
    name: "Tunisia",
    iso3: "TUN",
    iso2: "TN",
    numeric_code: "788",
    phone_code: "216",
    capital: "Tunis",
    currency: "TND",
    currency_name: "Tunisian dinar",
    currency_symbol: "ت.د",
    tld: ".tn",
    native: "تونس",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Tunisian",
    timezones: [
      {
        zoneName: "Africa/Tunis",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "튀니지",
      "pt-BR": "Tunísia",
      pt: "Tunísia",
      nl: "Tunesië",
      hr: "Tunis",
      fa: "تونس",
      de: "Tunesien",
      es: "Túnez",
      fr: "Tunisie",
      ja: "チュニジア",
      it: "Tunisia",
      cn: "突尼斯",
      tr: "Tunus",
    },
    latitude: "34.00000000",
    longitude: "9.00000000",
    emoji: "🇹🇳",
    emojiU: "U+1F1F9 U+1F1F3",
    states: [
      {
        id: 2550,
        name: "Ariana",
        state_code: "12",
        latitude: "36.99227510",
        longitude: "10.12551640",
        type: "governorate",
      },
      {
        id: 2572,
        name: "Béja",
        state_code: "31",
        latitude: "35.17227160",
        longitude: "8.83076260",
        type: "governorate",
      },
      {
        id: 2566,
        name: "Ben Arous",
        state_code: "13",
        latitude: "36.64356060",
        longitude: "10.21515780",
        type: "governorate",
      },
      {
        id: 2551,
        name: "Bizerte",
        state_code: "23",
        latitude: "37.16093970",
        longitude: "9.63413500",
        type: "governorate",
      },
      {
        id: 2558,
        name: "Gabès",
        state_code: "81",
        latitude: "33.94596480",
        longitude: "9.72326730",
        type: "governorate",
      },
      {
        id: 2556,
        name: "Gafsa",
        state_code: "71",
        latitude: "34.37885050",
        longitude: "8.66005860",
        type: "governorate",
      },
      {
        id: 2552,
        name: "Jendouba",
        state_code: "32",
        latitude: "36.71818620",
        longitude: "8.74811670",
        type: "governorate",
      },
      {
        id: 2564,
        name: "Kairouan",
        state_code: "41",
        latitude: "35.67116630",
        longitude: "10.10054690",
        type: "governorate",
      },
      {
        id: 2570,
        name: "Kasserine",
        state_code: "42",
        latitude: "35.08091480",
        longitude: "8.66005860",
        type: "governorate",
      },
      {
        id: 2562,
        name: "Kebili",
        state_code: "73",
        latitude: "33.70715510",
        longitude: "8.97146230",
        type: "governorate",
      },
      {
        id: 2561,
        name: "Kef",
        state_code: "33",
        latitude: "36.12305120",
        longitude: "8.66005860",
        type: "governorate",
      },
      {
        id: 2568,
        name: "Mahdia",
        state_code: "53",
        latitude: "35.33525580",
        longitude: "10.89030990",
        type: "governorate",
      },
      {
        id: 2555,
        name: "Manouba",
        state_code: "14",
        latitude: "36.84465040",
        longitude: "9.85714160",
        type: "governorate",
      },
      {
        id: 2560,
        name: "Medenine",
        state_code: "82",
        latitude: "33.22805650",
        longitude: "10.89030990",
        type: "governorate",
      },
      {
        id: 2553,
        name: "Monastir",
        state_code: "52",
        latitude: "35.76425150",
        longitude: "10.81128850",
        type: "governorate",
      },
      {
        id: 5116,
        name: "Nabeul",
        state_code: "21",
        latitude: "36.45245910",
        longitude: "10.68032220",
        type: "governorate",
      },
      {
        id: 2557,
        name: "Sfax",
        state_code: "61",
        latitude: "34.86065810",
        longitude: "10.34978950",
        type: "governorate",
      },
      {
        id: 2567,
        name: "Sidi Bouzid",
        state_code: "43",
        latitude: "35.03543860",
        longitude: "9.48393920",
        type: "governorate",
      },
      {
        id: 2563,
        name: "Siliana",
        state_code: "34",
        latitude: "36.08872080",
        longitude: "9.36453350",
        type: "governorate",
      },
      {
        id: 2571,
        name: "Sousse",
        state_code: "51",
        latitude: "35.90222670",
        longitude: "10.34978950",
        type: "governorate",
      },
      {
        id: 2559,
        name: "Tataouine",
        state_code: "83",
        latitude: "32.13441220",
        longitude: "10.08072980",
        type: "governorate",
      },
      {
        id: 2569,
        name: "Tozeur",
        state_code: "72",
        latitude: "33.97894910",
        longitude: "8.04651850",
        type: "governorate",
      },
      {
        id: 2554,
        name: "Tunis",
        state_code: "11",
        latitude: "36.83749460",
        longitude: "10.19273890",
        type: "governorate",
      },
      {
        id: 2565,
        name: "Zaghouan",
        state_code: "22",
        latitude: "36.40911880",
        longitude: "10.14231720",
        type: "governorate",
      },
    ],
  },
  {
    name: "Turkey",
    iso3: "TUR",
    iso2: "TR",
    numeric_code: "792",
    phone_code: "90",
    capital: "Ankara",
    currency: "TRY",
    currency_name: "Turkish lira",
    currency_symbol: "₺",
    tld: ".tr",
    native: "Türkiye",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Turkish",
    timezones: [
      {
        zoneName: "Europe/Istanbul",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "터키",
      "pt-BR": "Turquia",
      pt: "Turquia",
      nl: "Turkije",
      hr: "Turska",
      fa: "ترکیه",
      de: "Türkei",
      es: "Turquía",
      fr: "Turquie",
      ja: "トルコ",
      it: "Turchia",
      cn: "土耳其",
      tr: "Türkiye",
    },
    latitude: "39.00000000",
    longitude: "35.00000000",
    emoji: "🇹🇷",
    emojiU: "U+1F1F9 U+1F1F7",
    states: [
      {
        id: 2212,
        name: "Adana",
        state_code: "01",
        latitude: "37.26123150",
        longitude: "35.39050460",
        type: "province",
      },
      {
        id: 2155,
        name: "Adıyaman",
        state_code: "02",
        latitude: "37.90782910",
        longitude: "38.48499230",
        type: "province",
      },
      {
        id: 2179,
        name: "Afyonkarahisar",
        state_code: "03",
        latitude: "38.73910990",
        longitude: "30.71200230",
        type: "province",
      },
      {
        id: 2193,
        name: "Ağrı",
        state_code: "04",
        latitude: "39.62692180",
        longitude: "43.02159650",
        type: "province",
      },
      {
        id: 2210,
        name: "Aksaray",
        state_code: "68",
        latitude: "38.33520430",
        longitude: "33.97500180",
        type: "province",
      },
      {
        id: 2161,
        name: "Amasya",
        state_code: "05",
        latitude: "40.65166080",
        longitude: "35.90379660",
        type: "province",
      },
      {
        id: 2217,
        name: "Ankara",
        state_code: "06",
        latitude: "39.78052450",
        longitude: "32.71813750",
        type: "province",
      },
      {
        id: 2169,
        name: "Antalya",
        state_code: "07",
        latitude: "37.09516720",
        longitude: "31.07937050",
        type: "province",
      },
      {
        id: 2185,
        name: "Ardahan",
        state_code: "75",
        latitude: "41.11129640",
        longitude: "42.78316740",
        type: "province",
      },
      {
        id: 2191,
        name: "Artvin",
        state_code: "08",
        latitude: "41.07866400",
        longitude: "41.76282230",
        type: "province",
      },
      {
        id: 2187,
        name: "Aydın",
        state_code: "09",
        latitude: "37.81170330",
        longitude: "28.48639630",
        type: "province",
      },
      {
        id: 2175,
        name: "Balıkesir",
        state_code: "10",
        latitude: "39.76167820",
        longitude: "28.11226790",
        type: "province",
      },
      {
        id: 2148,
        name: "Bartın",
        state_code: "74",
        latitude: "41.58105090",
        longitude: "32.46097940",
        type: "province",
      },
      {
        id: 2194,
        name: "Batman",
        state_code: "72",
        latitude: "37.83624960",
        longitude: "41.36057390",
        type: "province",
      },
      {
        id: 2177,
        name: "Bayburt",
        state_code: "69",
        latitude: "40.26032000",
        longitude: "40.22804800",
        type: "province",
      },
      {
        id: 2221,
        name: "Bilecik",
        state_code: "11",
        latitude: "40.05665550",
        longitude: "30.06652360",
        type: "province",
      },
      {
        id: 2153,
        name: "Bingöl",
        state_code: "12",
        latitude: "39.06263540",
        longitude: "40.76960950",
        type: "province",
      },
      {
        id: 2215,
        name: "Bitlis",
        state_code: "13",
        latitude: "38.65231330",
        longitude: "42.42020280",
        type: "province",
      },
      {
        id: 2172,
        name: "Bolu",
        state_code: "14",
        latitude: "40.57597660",
        longitude: "31.57880860",
        type: "province",
      },
      {
        id: 2209,
        name: "Burdur",
        state_code: "15",
        latitude: "37.46126690",
        longitude: "30.06652360",
        type: "province",
      },
      {
        id: 2163,
        name: "Bursa",
        state_code: "16",
        latitude: "40.06554590",
        longitude: "29.23207840",
        type: "province",
      },
      {
        id: 2216,
        name: "Çanakkale",
        state_code: "17",
        latitude: "40.05101040",
        longitude: "26.98524220",
        type: "province",
      },
      {
        id: 2168,
        name: "Çankırı",
        state_code: "18",
        latitude: "40.53690730",
        longitude: "33.58838930",
        type: "province",
      },
      {
        id: 2173,
        name: "Çorum",
        state_code: "19",
        latitude: "40.49982110",
        longitude: "34.59862630",
        type: "province",
      },
      {
        id: 2157,
        name: "Denizli",
        state_code: "20",
        latitude: "37.61283950",
        longitude: "29.23207840",
        type: "province",
      },
      {
        id: 2226,
        name: "Diyarbakır",
        state_code: "21",
        latitude: "38.10663720",
        longitude: "40.54268960",
        type: "province",
      },
      {
        id: 2202,
        name: "Düzce",
        state_code: "81",
        latitude: "40.87705310",
        longitude: "31.31927130",
        type: "province",
      },
      {
        id: 2151,
        name: "Edirne",
        state_code: "22",
        latitude: "41.15172220",
        longitude: "26.51379640",
        type: "province",
      },
      {
        id: 2159,
        name: "Elazığ",
        state_code: "23",
        latitude: "38.49648040",
        longitude: "39.21990290",
        type: "province",
      },
      {
        id: 2160,
        name: "Erzincan",
        state_code: "24",
        latitude: "39.76819140",
        longitude: "39.05013060",
        type: "province",
      },
      {
        id: 2165,
        name: "Erzurum",
        state_code: "25",
        latitude: "40.07467990",
        longitude: "41.66945620",
        type: "province",
      },
      {
        id: 2164,
        name: "Eskişehir",
        state_code: "26",
        latitude: "39.63296570",
        longitude: "31.26263660",
        type: "province",
      },
      {
        id: 2203,
        name: "Gaziantep",
        state_code: "27",
        latitude: "37.07638820",
        longitude: "37.38272340",
        type: "province",
      },
      {
        id: 2186,
        name: "Giresun",
        state_code: "28",
        latitude: "40.64616720",
        longitude: "38.59355110",
        type: "province",
      },
      {
        id: 2204,
        name: "Gümüşhane",
        state_code: "29",
        latitude: "40.28036730",
        longitude: "39.31432530",
        type: "province",
      },
      {
        id: 2190,
        name: "Hakkâri",
        state_code: "30",
        latitude: "37.44593190",
        longitude: "43.74498410",
        type: "province",
      },
      {
        id: 2211,
        name: "Hatay",
        state_code: "31",
        latitude: "36.40184880",
        longitude: "36.34980970",
        type: "province",
      },
      {
        id: 2166,
        name: "Iğdır",
        state_code: "76",
        latitude: "39.88798410",
        longitude: "44.00483650",
        type: "province",
      },
      {
        id: 2222,
        name: "Isparta",
        state_code: "32",
        latitude: "38.02114640",
        longitude: "31.07937050",
        type: "province",
      },
      {
        id: 2170,
        name: "İstanbul",
        state_code: "34",
        latitude: "41.16343020",
        longitude: "28.76644080",
        type: "province",
      },
      {
        id: 2205,
        name: "İzmir",
        state_code: "35",
        latitude: "38.35916930",
        longitude: "27.26761160",
        type: "province",
      },
      {
        id: 2227,
        name: "Kahramanmaraş",
        state_code: "46",
        latitude: "37.75030360",
        longitude: "36.95410700",
        type: "province",
      },
      {
        id: 2223,
        name: "Karabük",
        state_code: "78",
        latitude: "41.18748900",
        longitude: "32.74174190",
        type: "province",
      },
      {
        id: 2184,
        name: "Karaman",
        state_code: "70",
        latitude: "37.24363360",
        longitude: "33.61757700",
        type: "province",
      },
      {
        id: 2208,
        name: "Kars",
        state_code: "36",
        latitude: "40.28076360",
        longitude: "42.99195270",
        type: "province",
      },
      {
        id: 2197,
        name: "Kastamonu",
        state_code: "37",
        latitude: "41.41038630",
        longitude: "33.69983340",
        type: "province",
      },
      {
        id: 2200,
        name: "Kayseri",
        state_code: "38",
        latitude: "38.62568540",
        longitude: "35.74068820",
        type: "province",
      },
      {
        id: 2154,
        name: "Kilis",
        state_code: "79",
        latitude: "36.82047750",
        longitude: "37.16873390",
        type: "province",
      },
      {
        id: 2178,
        name: "Kırıkkale",
        state_code: "71",
        latitude: "39.88768780",
        longitude: "33.75552480",
        type: "province",
      },
      {
        id: 2176,
        name: "Kırklareli",
        state_code: "39",
        latitude: "41.72597950",
        longitude: "27.48383900",
        type: "province",
      },
      {
        id: 2180,
        name: "Kırşehir",
        state_code: "40",
        latitude: "39.22689050",
        longitude: "33.97500180",
        type: "province",
      },
      {
        id: 2195,
        name: "Kocaeli",
        state_code: "41",
        latitude: "40.85327040",
        longitude: "29.88152030",
        type: "province",
      },
      {
        id: 2171,
        name: "Konya",
        state_code: "42",
        latitude: "37.98381340",
        longitude: "32.71813750",
        type: "province",
      },
      {
        id: 2149,
        name: "Kütahya",
        state_code: "43",
        latitude: "39.35813700",
        longitude: "29.60354950",
        type: "province",
      },
      {
        id: 2158,
        name: "Malatya",
        state_code: "44",
        latitude: "38.40150570",
        longitude: "37.95362980",
        type: "province",
      },
      {
        id: 2198,
        name: "Manisa",
        state_code: "45",
        latitude: "38.84193730",
        longitude: "28.11226790",
        type: "province",
      },
      {
        id: 2224,
        name: "Mardin",
        state_code: "47",
        latitude: "37.34429290",
        longitude: "40.61964870",
        type: "province",
      },
      {
        id: 2156,
        name: "Mersin",
        state_code: "33",
        latitude: "36.81208580",
        longitude: "34.64147500",
        type: "province",
      },
      {
        id: 2182,
        name: "Muğla",
        state_code: "48",
        latitude: "37.18358190",
        longitude: "28.48639630",
        type: "province",
      },
      {
        id: 2162,
        name: "Muş",
        state_code: "49",
        latitude: "38.94618880",
        longitude: "41.75389310",
        type: "province",
      },
      {
        id: 2196,
        name: "Nevşehir",
        state_code: "50",
        latitude: "38.69393990",
        longitude: "34.68565090",
        type: "province",
      },
      {
        id: 2189,
        name: "Niğde",
        state_code: "51",
        latitude: "38.09930860",
        longitude: "34.68565090",
        type: "province",
      },
      {
        id: 2174,
        name: "Ordu",
        state_code: "52",
        latitude: "40.79905800",
        longitude: "37.38990050",
        type: "province",
      },
      {
        id: 2214,
        name: "Osmaniye",
        state_code: "80",
        latitude: "37.21302580",
        longitude: "36.17626150",
        type: "province",
      },
      {
        id: 2219,
        name: "Rize",
        state_code: "53",
        latitude: "40.95814970",
        longitude: "40.92269850",
        type: "province",
      },
      {
        id: 2150,
        name: "Sakarya",
        state_code: "54",
        latitude: "40.78885500",
        longitude: "30.40595400",
        type: "province",
      },
      {
        id: 2220,
        name: "Samsun",
        state_code: "55",
        latitude: "41.18648590",
        longitude: "36.13226780",
        type: "province",
      },
      {
        id: 2183,
        name: "Şanlıurfa",
        state_code: "63",
        latitude: "37.35691020",
        longitude: "39.15436770",
        type: "province",
      },
      {
        id: 2207,
        name: "Siirt",
        state_code: "56",
        latitude: "37.86588620",
        longitude: "42.14945230",
        type: "province",
      },
      {
        id: 4854,
        name: "Sinop",
        state_code: "57",
        latitude: "41.55947490",
        longitude: "34.85805320",
        type: "province",
      },
      {
        id: 2181,
        name: "Sivas",
        state_code: "58",
        latitude: "39.44880390",
        longitude: "37.12944970",
        type: "province",
      },
      {
        id: 2225,
        name: "Şırnak",
        state_code: "73",
        latitude: "37.41874810",
        longitude: "42.49183380",
        type: "province",
      },
      {
        id: 2167,
        name: "Tekirdağ",
        state_code: "59",
        latitude: "41.11212270",
        longitude: "27.26761160",
        type: "province",
      },
      {
        id: 2199,
        name: "Tokat",
        state_code: "60",
        latitude: "40.39027130",
        longitude: "36.62518630",
        type: "province",
      },
      {
        id: 2206,
        name: "Trabzon",
        state_code: "61",
        latitude: "40.79924100",
        longitude: "39.58479440",
        type: "province",
      },
      {
        id: 2192,
        name: "Tunceli",
        state_code: "62",
        latitude: "39.30735540",
        longitude: "39.43877780",
        type: "province",
      },
      {
        id: 2201,
        name: "Uşak",
        state_code: "64",
        latitude: "38.54313190",
        longitude: "29.23207840",
        type: "province",
      },
      {
        id: 2152,
        name: "Van",
        state_code: "65",
        latitude: "38.36794170",
        longitude: "43.71827870",
        type: "province",
      },
      {
        id: 2218,
        name: "Yalova",
        state_code: "77",
        latitude: "40.57759860",
        longitude: "29.20883030",
        type: "province",
      },
      {
        id: 2188,
        name: "Yozgat",
        state_code: "66",
        latitude: "39.72719790",
        longitude: "35.10778580",
        type: "province",
      },
      {
        id: 2213,
        name: "Zonguldak",
        state_code: "67",
        latitude: "41.31249170",
        longitude: "31.85982510",
        type: "province",
      },
    ],
  },
  {
    name: "Turkmenistan",
    iso3: "TKM",
    iso2: "TM",
    numeric_code: "795",
    phone_code: "993",
    capital: "Ashgabat",
    currency: "TMT",
    currency_name: "Turkmenistan manat",
    currency_symbol: "T",
    tld: ".tm",
    native: "Türkmenistan",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Turkmen",
    timezones: [
      {
        zoneName: "Asia/Ashgabat",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "TMT",
        tzName: "Turkmenistan Time",
      },
    ],
    translations: {
      kr: "투르크메니스탄",
      "pt-BR": "Turcomenistão",
      pt: "Turquemenistão",
      nl: "Turkmenistan",
      hr: "Turkmenistan",
      fa: "ترکمنستان",
      de: "Turkmenistan",
      es: "Turkmenistán",
      fr: "Turkménistan",
      ja: "トルクメニスタン",
      it: "Turkmenistan",
      cn: "土库曼斯坦",
      tr: "Türkmenistan",
    },
    latitude: "40.00000000",
    longitude: "60.00000000",
    emoji: "🇹🇲",
    emojiU: "U+1F1F9 U+1F1F2",
    states: [
      {
        id: 3374,
        name: "Ahal Region",
        state_code: "A",
        latitude: "38.63993980",
        longitude: "59.47209040",
        type: null,
      },
      {
        id: 3371,
        name: "Ashgabat",
        state_code: "S",
        latitude: "37.96007660",
        longitude: "58.32606290",
        type: null,
      },
      {
        id: 3372,
        name: "Balkan Region",
        state_code: "B",
        latitude: "41.81014720",
        longitude: "21.09373110",
        type: null,
      },
      {
        id: 3373,
        name: "Daşoguz Region",
        state_code: "D",
        latitude: "41.83687370",
        longitude: "59.96519040",
        type: null,
      },
      {
        id: 3370,
        name: "Lebap Region",
        state_code: "L",
        latitude: "38.12724620",
        longitude: "64.71624150",
        type: null,
      },
      {
        id: 3369,
        name: "Mary Region",
        state_code: "M",
        latitude: "36.94816230",
        longitude: "62.45041540",
        type: null,
      },
    ],
  },
  {
    name: "Turks and Caicos Islands",
    iso3: "TCA",
    iso2: "TC",
    numeric_code: "796",
    phone_code: "+1-649",
    capital: "Cockburn Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".tc",
    native: "Turks and Caicos Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "Turks and Caicos Island",
    timezones: [
      {
        zoneName: "America/Grand_Turk",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
    ],
    translations: {
      kr: "터크스 케이커스 제도",
      "pt-BR": "Ilhas Turcas e Caicos",
      pt: "Ilhas Turcas e Caicos",
      nl: "Turks- en Caicoseilanden",
      hr: "Otoci Turks i Caicos",
      fa: "جزایر تورکس و کایکوس",
      de: "Turks- und Caicosinseln",
      es: "Islas Turks y Caicos",
      fr: "Îles Turques-et-Caïques",
      ja: "タークス・カイコス諸島",
      it: "Isole Turks e Caicos",
      cn: "特克斯和凯科斯群岛",
      tr: "Turks Ve Caicos Adalari",
    },
    latitude: "21.75000000",
    longitude: "-71.58333333",
    emoji: "🇹🇨",
    emojiU: "U+1F1F9 U+1F1E8",
    states: [],
  },
  {
    name: "Tuvalu",
    iso3: "TUV",
    iso2: "TV",
    numeric_code: "798",
    phone_code: "688",
    capital: "Funafuti",
    currency: "AUD",
    currency_name: "Australian dollar",
    currency_symbol: "$",
    tld: ".tv",
    native: "Tuvalu",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Tuvaluan",
    timezones: [
      {
        zoneName: "Pacific/Funafuti",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "TVT",
        tzName: "Tuvalu Time",
      },
    ],
    translations: {
      kr: "투발루",
      "pt-BR": "Tuvalu",
      pt: "Tuvalu",
      nl: "Tuvalu",
      hr: "Tuvalu",
      fa: "تووالو",
      de: "Tuvalu",
      es: "Tuvalu",
      fr: "Tuvalu",
      ja: "ツバル",
      it: "Tuvalu",
      cn: "图瓦卢",
      tr: "Tuvalu",
    },
    latitude: "-8.00000000",
    longitude: "178.00000000",
    emoji: "🇹🇻",
    emojiU: "U+1F1F9 U+1F1FB",
    states: [
      {
        id: 3951,
        name: "Funafuti",
        state_code: "FUN",
        latitude: "-8.52114710",
        longitude: "179.19619260",
        type: null,
      },
      {
        id: 3947,
        name: "Nanumanga",
        state_code: "NMG",
        latitude: "-6.28580190",
        longitude: "176.31992800",
        type: null,
      },
      {
        id: 3949,
        name: "Nanumea",
        state_code: "NMA",
        latitude: "-5.68816170",
        longitude: "176.13701480",
        type: null,
      },
      {
        id: 3946,
        name: "Niutao Island Council",
        state_code: "NIT",
        latitude: "-6.10642580",
        longitude: "177.34384290",
        type: null,
      },
      {
        id: 3948,
        name: "Nui",
        state_code: "NUI",
        latitude: "-7.23887680",
        longitude: "177.14852320",
        type: null,
      },
      {
        id: 3952,
        name: "Nukufetau",
        state_code: "NKF",
        latitude: "-8.00000000",
        longitude: "178.50000000",
        type: null,
      },
      {
        id: 3953,
        name: "Nukulaelae",
        state_code: "NKL",
        latitude: "-9.38111100",
        longitude: "179.85222200",
        type: null,
      },
      {
        id: 3950,
        name: "Vaitupu",
        state_code: "VAI",
        latitude: "-7.47673270",
        longitude: "178.67476750",
        type: null,
      },
    ],
  },
  {
    name: "Uganda",
    iso3: "UGA",
    iso2: "UG",
    numeric_code: "800",
    phone_code: "256",
    capital: "Kampala",
    currency: "UGX",
    currency_name: "Ugandan shilling",
    currency_symbol: "USh",
    tld: ".ug",
    native: "Uganda",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Ugandan",
    timezones: [
      {
        zoneName: "Africa/Kampala",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "EAT",
        tzName: "East Africa Time",
      },
    ],
    translations: {
      kr: "우간다",
      "pt-BR": "Uganda",
      pt: "Uganda",
      nl: "Oeganda",
      hr: "Uganda",
      fa: "اوگاندا",
      de: "Uganda",
      es: "Uganda",
      fr: "Uganda",
      ja: "ウガンダ",
      it: "Uganda",
      cn: "乌干达",
      tr: "Uganda",
    },
    latitude: "1.00000000",
    longitude: "32.00000000",
    emoji: "🇺🇬",
    emojiU: "U+1F1FA U+1F1EC",
    states: [
      {
        id: 329,
        name: "Abim District",
        state_code: "314",
        latitude: "2.70669800",
        longitude: "33.65953370",
        type: null,
      },
      {
        id: 361,
        name: "Adjumani District",
        state_code: "301",
        latitude: "3.25485270",
        longitude: "31.71954590",
        type: null,
      },
      {
        id: 392,
        name: "Agago District",
        state_code: "322",
        latitude: "2.92508200",
        longitude: "33.34861470",
        type: null,
      },
      {
        id: 344,
        name: "Alebtong District",
        state_code: "323",
        latitude: "2.25457730",
        longitude: "33.34861470",
        type: null,
      },
      {
        id: 416,
        name: "Amolatar District",
        state_code: "315",
        latitude: "1.60544020",
        longitude: "32.80844960",
        type: null,
      },
      {
        id: 353,
        name: "Amudat District",
        state_code: "324",
        latitude: "1.79162240",
        longitude: "34.90655100",
        type: null,
      },
      {
        id: 352,
        name: "Amuria District",
        state_code: "216",
        latitude: "2.03017000",
        longitude: "33.64275330",
        type: null,
      },
      {
        id: 335,
        name: "Amuru District",
        state_code: "316",
        latitude: "2.96678780",
        longitude: "32.08374450",
        type: null,
      },
      {
        id: 328,
        name: "Apac District",
        state_code: "302",
        latitude: "1.87302630",
        longitude: "32.62774550",
        type: null,
      },
      {
        id: 447,
        name: "Arua District",
        state_code: "303",
        latitude: "2.99598460",
        longitude: "31.17103890",
        type: null,
      },
      {
        id: 441,
        name: "Budaka District",
        state_code: "217",
        latitude: "1.10162770",
        longitude: "33.93039910",
        type: null,
      },
      {
        id: 349,
        name: "Bududa District",
        state_code: "218",
        latitude: "1.00296930",
        longitude: "34.33381230",
        type: null,
      },
      {
        id: 387,
        name: "Bugiri District",
        state_code: "201",
        latitude: "0.53161270",
        longitude: "33.75177230",
        type: null,
      },
      {
        id: 391,
        name: "Buhweju District",
        state_code: "420",
        latitude: "-0.29113590",
        longitude: "30.29741990",
        type: null,
      },
      {
        id: 377,
        name: "Buikwe District",
        state_code: "117",
        latitude: "0.31440460",
        longitude: "32.98883190",
        type: null,
      },
      {
        id: 343,
        name: "Bukedea District",
        state_code: "219",
        latitude: "1.35568980",
        longitude: "34.10867930",
        type: null,
      },
      {
        id: 375,
        name: "Bukomansimbi District",
        state_code: "118",
        latitude: "-0.14327520",
        longitude: "31.60548930",
        type: null,
      },
      {
        id: 385,
        name: "Bukwo District",
        state_code: "220",
        latitude: "1.28186510",
        longitude: "34.72987650",
        type: null,
      },
      {
        id: 428,
        name: "Bulambuli District",
        state_code: "225",
        latitude: "1.47988460",
        longitude: "34.37544140",
        type: null,
      },
      {
        id: 389,
        name: "Buliisa District",
        state_code: "416",
        latitude: "2.02996070",
        longitude: "31.53700030",
        type: null,
      },
      {
        id: 419,
        name: "Bundibugyo District",
        state_code: "401",
        latitude: "0.68517630",
        longitude: "30.02029640",
        type: null,
      },
      {
        id: 381,
        name: "Bunyangabu District",
        state_code: "430",
        latitude: "0.48709180",
        longitude: "30.20510960",
        type: null,
      },
      {
        id: 386,
        name: "Bushenyi District",
        state_code: "402",
        latitude: "-0.48709180",
        longitude: "30.20510960",
        type: null,
      },
      {
        id: 431,
        name: "Busia District",
        state_code: "202",
        latitude: "0.40447310",
        longitude: "34.01958270",
        type: null,
      },
      {
        id: 365,
        name: "Butaleja District",
        state_code: "221",
        latitude: "0.84749220",
        longitude: "33.84112880",
        type: null,
      },
      {
        id: 384,
        name: "Butambala District",
        state_code: "119",
        latitude: "0.17425000",
        longitude: "32.10646680",
        type: null,
      },
      {
        id: 388,
        name: "Butebo District",
        state_code: "233",
        latitude: "1.21411240",
        longitude: "33.90808960",
        type: null,
      },
      {
        id: 414,
        name: "Buvuma District",
        state_code: "120",
        latitude: "-0.37649120",
        longitude: "33.25879300",
        type: null,
      },
      {
        id: 380,
        name: "Buyende District",
        state_code: "226",
        latitude: "1.24136820",
        longitude: "33.12390490",
        type: null,
      },
      {
        id: 396,
        name: "Central Region",
        state_code: "C",
        latitude: "44.29687500",
        longitude: "-94.74017330",
        type: null,
      },
      {
        id: 341,
        name: "Dokolo District",
        state_code: "317",
        latitude: "1.96364210",
        longitude: "33.03387670",
        type: null,
      },
      {
        id: 372,
        name: "Eastern Region",
        state_code: "E",
        latitude: "6.23740360",
        longitude: "-0.45023680",
        type: null,
      },
      {
        id: 366,
        name: "Gomba District",
        state_code: "121",
        latitude: "0.22297910",
        longitude: "31.67393710",
        type: null,
      },
      {
        id: 413,
        name: "Gulu District",
        state_code: "304",
        latitude: "2.81857760",
        longitude: "32.44672380",
        type: null,
      },
      {
        id: 339,
        name: "Ibanda District",
        state_code: "417",
        latitude: "-0.09648900",
        longitude: "30.57395790",
        type: null,
      },
      {
        id: 340,
        name: "Iganga District",
        state_code: "203",
        latitude: "0.66001370",
        longitude: "33.48319060",
        type: null,
      },
      {
        id: 383,
        name: "Isingiro District",
        state_code: "418",
        latitude: "-0.84354300",
        longitude: "30.80394740",
        type: null,
      },
      {
        id: 367,
        name: "Jinja District",
        state_code: "204",
        latitude: "0.53437430",
        longitude: "33.30371430",
        type: null,
      },
      {
        id: 434,
        name: "Kaabong District",
        state_code: "318",
        latitude: "3.51262150",
        longitude: "33.97500180",
        type: null,
      },
      {
        id: 426,
        name: "Kabale District",
        state_code: "404",
        latitude: "-1.24930840",
        longitude: "30.06652360",
        type: null,
      },
      {
        id: 326,
        name: "Kabarole District",
        state_code: "405",
        latitude: "0.58507910",
        longitude: "30.25127280",
        type: null,
      },
      {
        id: 336,
        name: "Kaberamaido District",
        state_code: "213",
        latitude: "1.69633220",
        longitude: "33.21385100",
        type: null,
      },
      {
        id: 403,
        name: "Kagadi District",
        state_code: "427",
        latitude: "0.94007610",
        longitude: "30.81256380",
        type: null,
      },
      {
        id: 399,
        name: "Kakumiro District",
        state_code: "428",
        latitude: "0.78080350",
        longitude: "31.32413890",
        type: null,
      },
      {
        id: 405,
        name: "Kalangala District",
        state_code: "101",
        latitude: "-0.63505780",
        longitude: "32.53727410",
        type: null,
      },
      {
        id: 398,
        name: "Kaliro District",
        state_code: "222",
        latitude: "1.04311070",
        longitude: "33.48319060",
        type: null,
      },
      {
        id: 394,
        name: "Kalungu District",
        state_code: "122",
        latitude: "-0.09528310",
        longitude: "31.76513620",
        type: null,
      },
      {
        id: 382,
        name: "Kampala District",
        state_code: "102",
        latitude: "0.34759640",
        longitude: "32.58251970",
        type: null,
      },
      {
        id: 334,
        name: "Kamuli District",
        state_code: "205",
        latitude: "0.91871070",
        longitude: "33.12390490",
        type: null,
      },
      {
        id: 360,
        name: "Kamwenge District",
        state_code: "413",
        latitude: "0.22579300",
        longitude: "30.48184460",
        type: null,
      },
      {
        id: 373,
        name: "Kanungu District",
        state_code: "414",
        latitude: "-0.81952530",
        longitude: "29.74260400",
        type: null,
      },
      {
        id: 432,
        name: "Kapchorwa District",
        state_code: "206",
        latitude: "1.33502050",
        longitude: "34.39763560",
        type: null,
      },
      {
        id: 440,
        name: "Kasese District",
        state_code: "406",
        latitude: "0.06462850",
        longitude: "30.06652360",
        type: null,
      },
      {
        id: 420,
        name: "Katakwi District",
        state_code: "207",
        latitude: "1.97310300",
        longitude: "34.06414190",
        type: null,
      },
      {
        id: 368,
        name: "Kayunga District",
        state_code: "112",
        latitude: "0.98601820",
        longitude: "32.85357550",
        type: null,
      },
      {
        id: 436,
        name: "Kibaale District",
        state_code: "407",
        latitude: "0.90668020",
        longitude: "31.07937050",
        type: null,
      },
      {
        id: 347,
        name: "Kiboga District",
        state_code: "103",
        latitude: "0.96575900",
        longitude: "31.71954590",
        type: null,
      },
      {
        id: 338,
        name: "Kibuku District",
        state_code: "227",
        latitude: "1.04528740",
        longitude: "33.79925360",
        type: null,
      },
      {
        id: 355,
        name: "Kiruhura District",
        state_code: "419",
        latitude: "-0.19279980",
        longitude: "30.80394740",
        type: null,
      },
      {
        id: 346,
        name: "Kiryandongo District",
        state_code: "421",
        latitude: "2.01799070",
        longitude: "32.08374450",
        type: null,
      },
      {
        id: 409,
        name: "Kisoro District",
        state_code: "408",
        latitude: "-1.22094300",
        longitude: "29.64991620",
        type: null,
      },
      {
        id: 348,
        name: "Kitgum District",
        state_code: "305",
        latitude: "3.33968290",
        longitude: "33.16888830",
        type: null,
      },
      {
        id: 345,
        name: "Koboko District",
        state_code: "319",
        latitude: "3.52370580",
        longitude: "31.03351000",
        type: null,
      },
      {
        id: 401,
        name: "Kole District",
        state_code: "325",
        latitude: "2.37010970",
        longitude: "32.76330360",
        type: null,
      },
      {
        id: 443,
        name: "Kotido District",
        state_code: "306",
        latitude: "3.04156790",
        longitude: "33.88577470",
        type: null,
      },
      {
        id: 425,
        name: "Kumi District",
        state_code: "208",
        latitude: "1.48769990",
        longitude: "33.93039910",
        type: null,
      },
      {
        id: 369,
        name: "Kween District",
        state_code: "228",
        latitude: "1.44387900",
        longitude: "34.59713200",
        type: null,
      },
      {
        id: 325,
        name: "Kyankwanzi District",
        state_code: "123",
        latitude: "1.09660370",
        longitude: "31.71954590",
        type: null,
      },
      {
        id: 437,
        name: "Kyegegwa District",
        state_code: "422",
        latitude: "0.48181930",
        longitude: "31.05500930",
        type: null,
      },
      {
        id: 402,
        name: "Kyenjojo District",
        state_code: "415",
        latitude: "0.60929230",
        longitude: "30.64012310",
        type: null,
      },
      {
        id: 448,
        name: "Kyotera District",
        state_code: "125",
        latitude: "-0.63589880",
        longitude: "31.54556370",
        type: null,
      },
      {
        id: 411,
        name: "Lamwo District",
        state_code: "326",
        latitude: "3.57075680",
        longitude: "32.53727410",
        type: null,
      },
      {
        id: 342,
        name: "Lira District",
        state_code: "307",
        latitude: "2.23161690",
        longitude: "32.94376670",
        type: null,
      },
      {
        id: 445,
        name: "Luuka District",
        state_code: "229",
        latitude: "0.72505990",
        longitude: "33.30371430",
        type: null,
      },
      {
        id: 433,
        name: "Luwero District",
        state_code: "104",
        latitude: "0.82711180",
        longitude: "32.62774550",
        type: null,
      },
      {
        id: 417,
        name: "Lwengo District",
        state_code: "124",
        latitude: "-0.41652880",
        longitude: "31.39989950",
        type: null,
      },
      {
        id: 376,
        name: "Lyantonde District",
        state_code: "114",
        latitude: "-0.22406960",
        longitude: "31.21684660",
        type: null,
      },
      {
        id: 438,
        name: "Manafwa District",
        state_code: "223",
        latitude: "0.90635990",
        longitude: "34.28660910",
        type: null,
      },
      {
        id: 421,
        name: "Maracha District",
        state_code: "320",
        latitude: "3.28731270",
        longitude: "30.94030230",
        type: null,
      },
      {
        id: 356,
        name: "Masaka District",
        state_code: "105",
        latitude: "-0.44636910",
        longitude: "31.90179540",
        type: null,
      },
      {
        id: 354,
        name: "Masindi District",
        state_code: "409",
        latitude: "1.49203630",
        longitude: "31.71954590",
        type: null,
      },
      {
        id: 418,
        name: "Mayuge District",
        state_code: "214",
        latitude: "-0.21829820",
        longitude: "33.57280270",
        type: null,
      },
      {
        id: 350,
        name: "Mbale District",
        state_code: "209",
        latitude: "1.03442740",
        longitude: "34.19768820",
        type: null,
      },
      {
        id: 415,
        name: "Mbarara District",
        state_code: "410",
        latitude: "-0.60715960",
        longitude: "30.65450220",
        type: null,
      },
      {
        id: 435,
        name: "Mitooma District",
        state_code: "423",
        latitude: "-0.61932760",
        longitude: "30.02029640",
        type: null,
      },
      {
        id: 364,
        name: "Mityana District",
        state_code: "115",
        latitude: "0.44548450",
        longitude: "32.08374450",
        type: null,
      },
      {
        id: 395,
        name: "Moroto District",
        state_code: "308",
        latitude: "2.61685450",
        longitude: "34.59713200",
        type: null,
      },
      {
        id: 363,
        name: "Moyo District",
        state_code: "309",
        latitude: "3.56964640",
        longitude: "31.67393710",
        type: null,
      },
      {
        id: 327,
        name: "Mpigi District",
        state_code: "106",
        latitude: "0.22735280",
        longitude: "32.32492360",
        type: null,
      },
      {
        id: 371,
        name: "Mubende District",
        state_code: "107",
        latitude: "0.57727580",
        longitude: "31.53700030",
        type: null,
      },
      {
        id: 410,
        name: "Mukono District",
        state_code: "108",
        latitude: "0.28354760",
        longitude: "32.76330360",
        type: null,
      },
      {
        id: 393,
        name: "Nakapiripirit District",
        state_code: "311",
        latitude: "1.96061730",
        longitude: "34.59713200",
        type: null,
      },
      {
        id: 423,
        name: "Nakaseke District",
        state_code: "116",
        latitude: "1.22308480",
        longitude: "32.08374450",
        type: null,
      },
      {
        id: 406,
        name: "Nakasongola District",
        state_code: "109",
        latitude: "1.34897210",
        longitude: "32.44672380",
        type: null,
      },
      {
        id: 351,
        name: "Namayingo District",
        state_code: "230",
        latitude: "-0.28035750",
        longitude: "33.75177230",
        type: null,
      },
      {
        id: 400,
        name: "Namisindwa District",
        state_code: "234",
        latitude: "0.90710100",
        longitude: "34.35740370",
        type: null,
      },
      {
        id: 337,
        name: "Namutumba District",
        state_code: "224",
        latitude: "0.84926100",
        longitude: "33.66233010",
        type: null,
      },
      {
        id: 430,
        name: "Napak District",
        state_code: "327",
        latitude: "2.36299450",
        longitude: "34.24215970",
        type: null,
      },
      {
        id: 446,
        name: "Nebbi District",
        state_code: "310",
        latitude: "2.44093920",
        longitude: "31.35416310",
        type: null,
      },
      {
        id: 424,
        name: "Ngora District",
        state_code: "231",
        latitude: "1.49081150",
        longitude: "33.75177230",
        type: null,
      },
      {
        id: 332,
        name: "Northern Region",
        state_code: "N",
        latitude: "9.54392690",
        longitude: "-0.90566230",
        type: null,
      },
      {
        id: 422,
        name: "Ntoroko District",
        state_code: "424",
        latitude: "1.07881780",
        longitude: "30.38966510",
        type: null,
      },
      {
        id: 404,
        name: "Ntungamo District",
        state_code: "411",
        latitude: "-0.98073410",
        longitude: "30.25127280",
        type: null,
      },
      {
        id: 378,
        name: "Nwoya District",
        state_code: "328",
        latitude: "2.56244400",
        longitude: "31.90179540",
        type: null,
      },
      {
        id: 374,
        name: "Omoro District",
        state_code: "331",
        latitude: "2.71522300",
        longitude: "32.49200880",
        type: null,
      },
      {
        id: 390,
        name: "Otuke District",
        state_code: "329",
        latitude: "2.52140590",
        longitude: "33.34861470",
        type: null,
      },
      {
        id: 397,
        name: "Oyam District",
        state_code: "321",
        latitude: "2.27762810",
        longitude: "32.44672380",
        type: null,
      },
      {
        id: 408,
        name: "Pader District",
        state_code: "312",
        latitude: "2.94306820",
        longitude: "32.80844960",
        type: null,
      },
      {
        id: 357,
        name: "Pakwach District",
        state_code: "332",
        latitude: "2.46071410",
        longitude: "31.49417380",
        type: null,
      },
      {
        id: 412,
        name: "Pallisa District",
        state_code: "210",
        latitude: "1.23242060",
        longitude: "33.75177230",
        type: null,
      },
      {
        id: 439,
        name: "Rakai District",
        state_code: "110",
        latitude: "-0.70691350",
        longitude: "31.53700030",
        type: null,
      },
      {
        id: 358,
        name: "Rubanda District",
        state_code: "429",
        latitude: "-1.18611900",
        longitude: "29.84535760",
        type: null,
      },
      {
        id: 442,
        name: "Rubirizi District",
        state_code: "425",
        latitude: "-0.26424100",
        longitude: "30.10840330",
        type: null,
      },
      {
        id: 331,
        name: "Rukiga District",
        state_code: "431",
        latitude: "-1.13263370",
        longitude: "30.04341200",
        type: null,
      },
      {
        id: 324,
        name: "Rukungiri District",
        state_code: "412",
        latitude: "-0.75184900",
        longitude: "29.92779470",
        type: null,
      },
      {
        id: 427,
        name: "Sembabule District",
        state_code: "111",
        latitude: "0.06377150",
        longitude: "31.35416310",
        type: null,
      },
      {
        id: 333,
        name: "Serere District",
        state_code: "232",
        latitude: "1.49940330",
        longitude: "33.54900780",
        type: null,
      },
      {
        id: 407,
        name: "Sheema District",
        state_code: "426",
        latitude: "-0.55152980",
        longitude: "30.38966510",
        type: null,
      },
      {
        id: 429,
        name: "Sironko District",
        state_code: "215",
        latitude: "1.23022740",
        longitude: "34.24910640",
        type: null,
      },
      {
        id: 444,
        name: "Soroti District",
        state_code: "211",
        latitude: "1.72291170",
        longitude: "33.52800720",
        type: null,
      },
      {
        id: 359,
        name: "Tororo District",
        state_code: "212",
        latitude: "0.68709940",
        longitude: "34.06414190",
        type: null,
      },
      {
        id: 362,
        name: "Wakiso District",
        state_code: "113",
        latitude: "0.06301900",
        longitude: "32.44672380",
        type: null,
      },
      {
        id: 370,
        name: "Western Region",
        state_code: "W",
        latitude: "40.76672150",
        longitude: "-111.88772030",
        type: null,
      },
      {
        id: 330,
        name: "Yumbe District",
        state_code: "313",
        latitude: "3.46980230",
        longitude: "31.24832910",
        type: null,
      },
      {
        id: 379,
        name: "Zombo District",
        state_code: "330",
        latitude: "2.55442930",
        longitude: "30.94173680",
        type: null,
      },
    ],
  },
  {
    name: "Ukraine",
    iso3: "UKR",
    iso2: "UA",
    numeric_code: "804",
    phone_code: "380",
    capital: "Kyiv",
    currency: "UAH",
    currency_name: "Ukrainian hryvnia",
    currency_symbol: "₴",
    tld: ".ua",
    native: "Україна",
    region: "Europe",
    region_id: "4",
    subregion: "Eastern Europe",
    subregion_id: "15",
    nationality: "Ukrainian",
    timezones: [
      {
        zoneName: "Europe/Kiev",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Simferopol",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "MSK",
        tzName: "Moscow Time",
      },
      {
        zoneName: "Europe/Uzhgorod",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
      {
        zoneName: "Europe/Zaporozhye",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "EET",
        tzName: "Eastern European Time",
      },
    ],
    translations: {
      kr: "우크라이나",
      "pt-BR": "Ucrânia",
      pt: "Ucrânia",
      nl: "Oekraïne",
      hr: "Ukrajina",
      fa: "وکراین",
      de: "Ukraine",
      es: "Ucrania",
      fr: "Ukraine",
      ja: "ウクライナ",
      it: "Ucraina",
      cn: "乌克兰",
      tr: "Ukrayna",
    },
    latitude: "49.00000000",
    longitude: "32.00000000",
    emoji: "🇺🇦",
    emojiU: "U+1F1FA U+1F1E6",
    states: [
      {
        id: 4689,
        name: "Autonomous Republic of Crimea",
        state_code: "43",
        latitude: "44.95211700",
        longitude: "34.10241700",
        type: "republic",
      },
      {
        id: 4680,
        name: "Cherkaska oblast",
        state_code: "71",
        latitude: "49.44443300",
        longitude: "32.05976700",
        type: "region",
      },
      {
        id: 4692,
        name: "Chernihivska oblast",
        state_code: "74",
        latitude: "51.49820000",
        longitude: "31.28934990",
        type: "region",
      },
      {
        id: 4678,
        name: "Chernivetska oblast",
        state_code: "77",
        latitude: "48.29168300",
        longitude: "25.93521700",
        type: "region",
      },
      {
        id: 4675,
        name: "Dnipropetrovska oblast",
        state_code: "12",
        latitude: "48.46471700",
        longitude: "35.04618300",
        type: "region",
      },
      {
        id: 4691,
        name: "Donetska oblast",
        state_code: "14",
        latitude: "48.01588300",
        longitude: "37.80285000",
        type: "region",
      },
      {
        id: 4682,
        name: "Ivano-Frankivska oblast",
        state_code: "26",
        latitude: "48.92263300",
        longitude: "24.71111700",
        type: "region",
      },
      {
        id: 4686,
        name: "Kharkivska oblast",
        state_code: "63",
        latitude: "49.99350000",
        longitude: "36.23038300",
        type: "region",
      },
      {
        id: 4684,
        name: "Khersonska oblast",
        state_code: "65",
        latitude: "46.63541700",
        longitude: "32.61686700",
        type: "region",
      },
      {
        id: 4681,
        name: "Khmelnytska oblast",
        state_code: "68",
        latitude: "49.42298300",
        longitude: "26.98713310",
        type: "region",
      },
      {
        id: 4677,
        name: "Kirovohradska oblast",
        state_code: "35",
        latitude: "48.50793300",
        longitude: "32.26231700",
        type: "region",
      },
      {
        id: 4676,
        name: "Kyiv",
        state_code: "30",
        latitude: "50.45010000",
        longitude: "30.52340000",
        type: "city",
      },
      {
        id: 4671,
        name: "Kyivska oblast",
        state_code: "32",
        latitude: "50.05295060",
        longitude: "30.76671340",
        type: "region",
      },
      {
        id: 4673,
        name: "Luhanska oblast",
        state_code: "09",
        latitude: "48.57404100",
        longitude: "39.30781500",
        type: "region",
      },
      {
        id: 4672,
        name: "Lvivska oblast",
        state_code: "46",
        latitude: "49.83968300",
        longitude: "24.02971700",
        type: "region",
      },
      {
        id: 4679,
        name: "Mykolaivska oblast",
        state_code: "48",
        latitude: "46.97503300",
        longitude: "31.99458290",
        type: "region",
      },
      {
        id: 4688,
        name: "Odeska oblast",
        state_code: "51",
        latitude: "46.48458300",
        longitude: "30.73260000",
        type: "region",
      },
      {
        id: 5071,
        name: "Poltavska oblast",
        state_code: "53",
        latitude: "49.64291960",
        longitude: "32.66753390",
        type: "region",
      },
      {
        id: 4683,
        name: "Rivnenska oblast",
        state_code: "56",
        latitude: "50.61990000",
        longitude: "26.25161700",
        type: "region",
      },
      {
        id: 1912,
        name: "Sevastopol",
        state_code: "40",
        latitude: "44.61665000",
        longitude: "33.52536710",
        type: null,
      },
      {
        id: 4685,
        name: "Sumska oblast",
        state_code: "59",
        latitude: "50.90770000",
        longitude: "34.79810000",
        type: "region",
      },
      {
        id: 4674,
        name: "Ternopilska oblast",
        state_code: "61",
        latitude: "49.55351700",
        longitude: "25.59476700",
        type: "region",
      },
      {
        id: 4669,
        name: "Vinnytska oblast",
        state_code: "05",
        latitude: "49.23308300",
        longitude: "28.46821690",
        type: "region",
      },
      {
        id: 4690,
        name: "Volynska oblast",
        state_code: "07",
        latitude: "50.74723300",
        longitude: "25.32538300",
        type: "region",
      },
      {
        id: 4670,
        name: "Zakarpatska Oblast",
        state_code: "21",
        latitude: "48.62080000",
        longitude: "22.28788300",
        type: "region",
      },
      {
        id: 4687,
        name: "Zaporizka oblast",
        state_code: "23",
        latitude: "47.83880000",
        longitude: "35.13956700",
        type: "region",
      },
      {
        id: 4668,
        name: "Zhytomyrska oblast",
        state_code: "18",
        latitude: "50.25465000",
        longitude: "28.65866690",
        type: "region",
      },
    ],
  },
  {
    name: "United Arab Emirates",
    iso3: "ARE",
    iso2: "AE",
    numeric_code: "784",
    phone_code: "971",
    capital: "Abu Dhabi",
    currency: "AED",
    currency_name: "United Arab Emirates dirham",
    currency_symbol: "إ.د",
    tld: ".ae",
    native: "دولة الإمارات العربية المتحدة",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Emirati, Emirian, Emiri",
    timezones: [
      {
        zoneName: "Asia/Dubai",
        gmtOffset: 14400,
        gmtOffsetName: "UTC+04:00",
        abbreviation: "GST",
        tzName: "Gulf Standard Time",
      },
    ],
    translations: {
      kr: "아랍에미리트",
      "pt-BR": "Emirados árabes Unidos",
      pt: "Emirados árabes Unidos",
      nl: "Verenigde Arabische Emiraten",
      hr: "Ujedinjeni Arapski Emirati",
      fa: "امارات متحده عربی",
      de: "Vereinigte Arabische Emirate",
      es: "Emiratos Árabes Unidos",
      fr: "Émirats arabes unis",
      ja: "アラブ首長国連邦",
      it: "Emirati Arabi Uniti",
      cn: "阿拉伯联合酋长国",
      tr: "Birleşik Arap Emirlikleri",
    },
    latitude: "24.00000000",
    longitude: "54.00000000",
    emoji: "🇦🇪",
    emojiU: "U+1F1E6 U+1F1EA",
    states: [
      {
        id: 3396,
        name: "Abu Dhabi Emirate",
        state_code: "AZ",
        latitude: "24.45388400",
        longitude: "54.37734380",
        type: null,
      },
      {
        id: 3395,
        name: "Ajman Emirate",
        state_code: "AJ",
        latitude: "25.40521650",
        longitude: "55.51364330",
        type: null,
      },
      {
        id: 3391,
        name: "Dubai",
        state_code: "DU",
        latitude: "25.20484930",
        longitude: "55.27078280",
        type: null,
      },
      {
        id: 3393,
        name: "Fujairah",
        state_code: "FU",
        latitude: "25.12880990",
        longitude: "56.32648490",
        type: null,
      },
      {
        id: 3394,
        name: "Ras al-Khaimah",
        state_code: "RK",
        latitude: "25.67413430",
        longitude: "55.98041730",
        type: null,
      },
      {
        id: 3390,
        name: "Sharjah Emirate",
        state_code: "SH",
        latitude: "25.07539740",
        longitude: "55.75784030",
        type: null,
      },
      {
        id: 3392,
        name: "Umm al-Quwain",
        state_code: "UQ",
        latitude: "25.54263240",
        longitude: "55.54753480",
        type: null,
      },
    ],
  },
  {
    name: "United Kingdom",
    iso3: "GBR",
    iso2: "GB",
    numeric_code: "826",
    phone_code: "44",
    capital: "London",
    currency: "GBP",
    currency_name: "British pound",
    currency_symbol: "£",
    tld: ".uk",
    native: "United Kingdom",
    region: "Europe",
    region_id: "4",
    subregion: "Northern Europe",
    subregion_id: "18",
    nationality: "British, UK",
    timezones: [
      {
        zoneName: "Europe/London",
        gmtOffset: 0,
        gmtOffsetName: "UTC±00",
        abbreviation: "GMT",
        tzName: "Greenwich Mean Time",
      },
    ],
    translations: {
      kr: "영국",
      "pt-BR": "Reino Unido",
      pt: "Reino Unido",
      nl: "Verenigd Koninkrijk",
      hr: "Ujedinjeno Kraljevstvo",
      fa: "بریتانیای کبیر و ایرلند شمالی",
      de: "Vereinigtes Königreich",
      es: "Reino Unido",
      fr: "Royaume-Uni",
      ja: "イギリス",
      it: "Regno Unito",
      cn: "英国",
      tr: "Birleşik Krallik",
    },
    latitude: "54.00000000",
    longitude: "-2.00000000",
    emoji: "🇬🇧",
    emojiU: "U+1F1EC U+1F1E7",
    states: [
      {
        id: 2463,
        name: "Aberdeen",
        state_code: "ABE",
        latitude: "57.14971700",
        longitude: "-2.09427800",
        type: null,
      },
      {
        id: 2401,
        name: "Aberdeenshire",
        state_code: "ABD",
        latitude: "57.28687230",
        longitude: "-2.38156840",
        type: null,
      },
      {
        id: 2387,
        name: "Angus",
        state_code: "ANS",
        latitude: "37.27578860",
        longitude: "-95.65010330",
        type: null,
      },
      {
        id: 2533,
        name: "Antrim",
        state_code: "ANT",
        latitude: "54.71953380",
        longitude: "-6.20724980",
        type: null,
      },
      {
        id: 2412,
        name: "Antrim and Newtownabbey",
        state_code: "ANN",
        latitude: "54.69568870",
        longitude: "-5.94810690",
        type: null,
      },
      {
        id: 2498,
        name: "Ards",
        state_code: "ARD",
        latitude: "42.13918510",
        longitude: "-87.86149720",
        type: null,
      },
      {
        id: 2523,
        name: "Ards and North Down",
        state_code: "AND",
        latitude: "54.58996450",
        longitude: "-5.59849720",
        type: null,
      },
      {
        id: 2392,
        name: "Argyll and Bute",
        state_code: "AGB",
        latitude: "56.40062140",
        longitude: "-5.48074800",
        type: null,
      },
      {
        id: 2331,
        name: "Armagh City and District Council",
        state_code: "ARM",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        type: null,
      },
      {
        id: 2324,
        name: "Armagh, Banbridge and Craigavon",
        state_code: "ABC",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        type: null,
      },
      {
        id: 2378,
        name: "Ascension Island",
        state_code: "SH-AC",
        latitude: "-7.94671660",
        longitude: "-14.35591580",
        type: null,
      },
      {
        id: 2363,
        name: "Ballymena Borough",
        state_code: "BLA",
        latitude: "54.86426000",
        longitude: "-6.27910740",
        type: null,
      },
      {
        id: 2361,
        name: "Ballymoney",
        state_code: "BLY",
        latitude: "55.07048880",
        longitude: "-6.51737080",
        type: null,
      },
      {
        id: 2315,
        name: "Banbridge",
        state_code: "BNB",
        latitude: "54.34872900",
        longitude: "-6.27048030",
        type: null,
      },
      {
        id: 2499,
        name: "Barnsley",
        state_code: "BNS",
        latitude: "34.29949560",
        longitude: "-84.98458090",
        type: null,
      },
      {
        id: 2339,
        name: "Bath and North East Somerset",
        state_code: "BAS",
        latitude: "51.32501020",
        longitude: "-2.47662410",
        type: null,
      },
      {
        id: 2507,
        name: "Bedford",
        state_code: "BDF",
        latitude: "32.84401700",
        longitude: "-97.14306710",
        type: null,
      },
      {
        id: 2311,
        name: "Belfast district",
        state_code: "BFS",
        latitude: "54.61703660",
        longitude: "-5.95318610",
        type: null,
      },
      {
        id: 2425,
        name: "Birmingham",
        state_code: "BIR",
        latitude: "33.51858920",
        longitude: "-86.81035670",
        type: null,
      },
      {
        id: 2329,
        name: "Blackburn with Darwen",
        state_code: "BBD",
        latitude: "53.69575220",
        longitude: "-2.46829850",
        type: null,
      },
      {
        id: 2451,
        name: "Blackpool",
        state_code: "BPL",
        latitude: "53.81750530",
        longitude: "-3.03567480",
        type: null,
      },
      {
        id: 2530,
        name: "Blaenau Gwent County Borough",
        state_code: "BGW",
        latitude: "51.78757790",
        longitude: "-3.20439310",
        type: null,
      },
      {
        id: 2504,
        name: "Bolton",
        state_code: "BOL",
        latitude: "44.37264760",
        longitude: "-72.87876250",
        type: null,
      },
      {
        id: 2342,
        name: "Bournemouth",
        state_code: "BMH",
        latitude: "50.71916400",
        longitude: "-1.88076900",
        type: null,
      },
      {
        id: 2470,
        name: "Bracknell Forest",
        state_code: "BRC",
        latitude: "51.41538280",
        longitude: "-0.75364950",
        type: null,
      },
      {
        id: 2529,
        name: "Bradford",
        state_code: "BRD",
        latitude: "53.79598400",
        longitude: "-1.75939800",
        type: null,
      },
      {
        id: 2452,
        name: "Bridgend County Borough",
        state_code: "BGE",
        latitude: "51.50831990",
        longitude: "-3.58120750",
        type: null,
      },
      {
        id: 2395,
        name: "Brighton and Hove",
        state_code: "BNH",
        latitude: "50.82262880",
        longitude: "-0.13704700",
        type: null,
      },
      {
        id: 2405,
        name: "Buckinghamshire",
        state_code: "BKM",
        latitude: "51.80722040",
        longitude: "-0.81276640",
        type: null,
      },
      {
        id: 2459,
        name: "Bury",
        state_code: "BUR",
        latitude: "53.59334980",
        longitude: "-2.29660540",
        type: null,
      },
      {
        id: 2298,
        name: "Caerphilly County Borough",
        state_code: "CAY",
        latitude: "51.66044650",
        longitude: "-3.21787240",
        type: null,
      },
      {
        id: 2517,
        name: "Calderdale",
        state_code: "CLD",
        latitude: "53.72478450",
        longitude: "-1.86583570",
        type: null,
      },
      {
        id: 2423,
        name: "Cambridgeshire",
        state_code: "CAM",
        latitude: "52.20529730",
        longitude: "0.12181950",
        type: null,
      },
      {
        id: 2484,
        name: "Carmarthenshire",
        state_code: "CMN",
        latitude: "51.85723090",
        longitude: "-4.31159590",
        type: null,
      },
      {
        id: 2439,
        name: "Carrickfergus Borough Council",
        state_code: "CKF",
        latitude: "54.72568430",
        longitude: "-5.80937190",
        type: null,
      },
      {
        id: 2525,
        name: "Castlereagh",
        state_code: "CSR",
        latitude: "54.57567900",
        longitude: "-5.88840280",
        type: null,
      },
      {
        id: 2316,
        name: "Causeway Coast and Glens",
        state_code: "CCG",
        latitude: "55.04318300",
        longitude: "-6.67412880",
        type: null,
      },
      {
        id: 2303,
        name: "Central Bedfordshire",
        state_code: "CBF",
        latitude: "52.00297440",
        longitude: "-0.46513890",
        type: null,
      },
      {
        id: 2509,
        name: "Ceredigion",
        state_code: "CGN",
        latitude: "52.21914290",
        longitude: "-3.93212560",
        type: null,
      },
      {
        id: 2444,
        name: "Cheshire East",
        state_code: "CHE",
        latitude: "53.16104460",
        longitude: "-2.21859320",
        type: null,
      },
      {
        id: 2442,
        name: "Cheshire West and Chester",
        state_code: "CHW",
        latitude: "53.23029740",
        longitude: "-2.71511170",
        type: null,
      },
      {
        id: 2528,
        name: "City and County of Cardiff",
        state_code: "CRF",
        latitude: "51.48158100",
        longitude: "-3.17909000",
        type: null,
      },
      {
        id: 2433,
        name: "City and County of Swansea",
        state_code: "SWA",
        latitude: "51.62144000",
        longitude: "-3.94364600",
        type: null,
      },
      {
        id: 2413,
        name: "City of Bristol",
        state_code: "BST",
        latitude: "41.67352200",
        longitude: "-72.94653750",
        type: null,
      },
      {
        id: 2485,
        name: "City of Derby",
        state_code: "DER",
        latitude: "37.54837550",
        longitude: "-97.24851910",
        type: null,
      },
      {
        id: 2475,
        name: "City of Kingston upon Hull",
        state_code: "KHL",
        latitude: "53.76762360",
        longitude: "-0.32741980",
        type: null,
      },
      {
        id: 2318,
        name: "City of Leicester",
        state_code: "LCE",
        latitude: "52.63687780",
        longitude: "-1.13975920",
        type: null,
      },
      {
        id: 2424,
        name: "City of London",
        state_code: "LND",
        latitude: "51.51234430",
        longitude: "-0.09098520",
        type: null,
      },
      {
        id: 2359,
        name: "City of Nottingham",
        state_code: "NGM",
        latitude: "52.95478320",
        longitude: "-1.15810860",
        type: null,
      },
      {
        id: 2297,
        name: "City of Peterborough",
        state_code: "PTE",
        latitude: "44.30936360",
        longitude: "-78.32015300",
        type: null,
      },
      {
        id: 2514,
        name: "City of Plymouth",
        state_code: "PLY",
        latitude: "42.37089410",
        longitude: "-83.46971410",
        type: null,
      },
      {
        id: 2305,
        name: "City of Portsmouth",
        state_code: "POR",
        latitude: "36.83291500",
        longitude: "-76.29755490",
        type: null,
      },
      {
        id: 2294,
        name: "City of Southampton",
        state_code: "STH",
        latitude: "50.90970040",
        longitude: "-1.40435090",
        type: null,
      },
      {
        id: 2506,
        name: "City of Stoke-on-Trent",
        state_code: "STE",
        latitude: "53.00266800",
        longitude: "-2.17940400",
        type: null,
      },
      {
        id: 2372,
        name: "City of Sunderland",
        state_code: "SND",
        latitude: "54.88614890",
        longitude: "-1.47857970",
        type: null,
      },
      {
        id: 2357,
        name: "City of Westminster",
        state_code: "WSM",
        latitude: "39.57659770",
        longitude: "-76.99721260",
        type: null,
      },
      {
        id: 2489,
        name: "City of Wolverhampton",
        state_code: "WLV",
        latitude: "52.58891200",
        longitude: "-2.15646300",
        type: null,
      },
      {
        id: 2426,
        name: "City of York",
        state_code: "YOR",
        latitude: "53.95996510",
        longitude: "-1.08729790",
        type: null,
      },
      {
        id: 2450,
        name: "Clackmannanshire",
        state_code: "CLK",
        latitude: "56.10753510",
        longitude: "-3.75294090",
        type: null,
      },
      {
        id: 2461,
        name: "Coleraine Borough Council",
        state_code: "CLR",
        latitude: "55.14515700",
        longitude: "-6.67598140",
        type: null,
      },
      {
        id: 2352,
        name: "Conwy County Borough",
        state_code: "CWY",
        latitude: "53.29350130",
        longitude: "-3.72651610",
        type: null,
      },
      {
        id: 2445,
        name: "Cookstown District Council",
        state_code: "CKT",
        latitude: "54.64181580",
        longitude: "-6.74438950",
        type: null,
      },
      {
        id: 2312,
        name: "Cornwall",
        state_code: "CON",
        latitude: "50.26604710",
        longitude: "-5.05271250",
        type: null,
      },
      {
        id: 2406,
        name: "County Durham",
        state_code: "DUR",
        latitude: "54.72940990",
        longitude: "-1.88115980",
        type: null,
      },
      {
        id: 2438,
        name: "Coventry",
        state_code: "COV",
        latitude: "52.40682200",
        longitude: "-1.51969300",
        type: null,
      },
      {
        id: 2449,
        name: "Craigavon Borough Council",
        state_code: "CGV",
        latitude: "54.39325920",
        longitude: "-6.45634010",
        type: null,
      },
      {
        id: 2334,
        name: "Cumbria",
        state_code: "CMA",
        latitude: "54.57723230",
        longitude: "-2.79748350",
        type: null,
      },
      {
        id: 2389,
        name: "Darlington",
        state_code: "DAL",
        latitude: "34.29987620",
        longitude: "-79.87617410",
        type: null,
      },
      {
        id: 2497,
        name: "Denbighshire",
        state_code: "DEN",
        latitude: "53.18422880",
        longitude: "-3.42249850",
        type: null,
      },
      {
        id: 2403,
        name: "Derbyshire",
        state_code: "DBY",
        latitude: "53.10467820",
        longitude: "-1.56238850",
        type: null,
      },
      {
        id: 2446,
        name: "Derry City and Strabane",
        state_code: "DRS",
        latitude: "55.00474430",
        longitude: "-7.32092220",
        type: null,
      },
      {
        id: 2417,
        name: "Derry City Council",
        state_code: "DRY",
        latitude: "54.96907780",
        longitude: "-7.19583510",
        type: null,
      },
      {
        id: 2491,
        name: "Devon",
        state_code: "DEV",
        latitude: "50.71555910",
        longitude: "-3.53087500",
        type: null,
      },
      {
        id: 2364,
        name: "Doncaster",
        state_code: "DNC",
        latitude: "53.52282000",
        longitude: "-1.12846200",
        type: null,
      },
      {
        id: 2345,
        name: "Dorset",
        state_code: "DOR",
        latitude: "50.74876350",
        longitude: "-2.34447860",
        type: null,
      },
      {
        id: 2304,
        name: "Down District Council",
        state_code: "DOW",
        latitude: "54.24342870",
        longitude: "-5.95779590",
        type: null,
      },
      {
        id: 2457,
        name: "Dudley",
        state_code: "DUD",
        latitude: "42.04336610",
        longitude: "-71.92760330",
        type: null,
      },
      {
        id: 2415,
        name: "Dumfries and Galloway",
        state_code: "DGY",
        latitude: "55.07010730",
        longitude: "-3.60525810",
        type: null,
      },
      {
        id: 2511,
        name: "Dundee",
        state_code: "DND",
        latitude: "56.46201800",
        longitude: "-2.97072100",
        type: null,
      },
      {
        id: 2508,
        name: "Dungannon and South Tyrone Borough Council",
        state_code: "DGN",
        latitude: "54.50826840",
        longitude: "-6.76658910",
        type: null,
      },
      {
        id: 2374,
        name: "East Ayrshire",
        state_code: "EAY",
        latitude: "55.45184960",
        longitude: "-4.26444780",
        type: null,
      },
      {
        id: 2454,
        name: "East Dunbartonshire",
        state_code: "EDU",
        latitude: "55.97431620",
        longitude: "-4.20229800",
        type: null,
      },
      {
        id: 2462,
        name: "East Lothian",
        state_code: "ELN",
        latitude: "55.94933830",
        longitude: "-2.77044640",
        type: null,
      },
      {
        id: 2333,
        name: "East Renfrewshire",
        state_code: "ERW",
        latitude: "55.77047350",
        longitude: "-4.33598210",
        type: null,
      },
      {
        id: 2370,
        name: "East Riding of Yorkshire",
        state_code: "ERY",
        latitude: "53.84161680",
        longitude: "-0.43441060",
        type: null,
      },
      {
        id: 2414,
        name: "East Sussex",
        state_code: "ESX",
        latitude: "50.90859550",
        longitude: "0.24941660",
        type: null,
      },
      {
        id: 2428,
        name: "Edinburgh",
        state_code: "EDH",
        latitude: "55.95325200",
        longitude: "-3.18826700",
        type: null,
      },
      {
        id: 2336,
        name: "England",
        state_code: "ENG",
        latitude: "52.35551770",
        longitude: "-1.17431970",
        type: null,
      },
      {
        id: 2410,
        name: "Essex",
        state_code: "ESS",
        latitude: "51.57424470",
        longitude: "0.48567810",
        type: null,
      },
      {
        id: 2344,
        name: "Falkirk",
        state_code: "FAL",
        latitude: "56.00187750",
        longitude: "-3.78391310",
        type: null,
      },
      {
        id: 2366,
        name: "Fermanagh and Omagh",
        state_code: "FMO",
        latitude: "54.45135240",
        longitude: "-7.71250180",
        type: null,
      },
      {
        id: 2531,
        name: "Fermanagh District Council",
        state_code: "FER",
        latitude: "54.34479780",
        longitude: "-7.63842180",
        type: null,
      },
      {
        id: 2479,
        name: "Fife",
        state_code: "FIF",
        latitude: "56.20820780",
        longitude: "-3.14951750",
        type: null,
      },
      {
        id: 2437,
        name: "Flintshire",
        state_code: "FLN",
        latitude: "53.16686580",
        longitude: "-3.14189080",
        type: null,
      },
      {
        id: 2431,
        name: "Gateshead",
        state_code: "GAT",
        latitude: "54.95268000",
        longitude: "-1.60341100",
        type: null,
      },
      {
        id: 2404,
        name: "Glasgow",
        state_code: "GLG",
        latitude: "55.86423700",
        longitude: "-4.25180600",
        type: null,
      },
      {
        id: 2373,
        name: "Gloucestershire",
        state_code: "GLS",
        latitude: "51.86421120",
        longitude: "-2.23803350",
        type: null,
      },
      {
        id: 2379,
        name: "Gwynedd",
        state_code: "GWN",
        latitude: "52.92772660",
        longitude: "-4.13348360",
        type: null,
      },
      {
        id: 2466,
        name: "Halton",
        state_code: "HAL",
        latitude: "43.53253720",
        longitude: "-79.87448360",
        type: null,
      },
      {
        id: 2435,
        name: "Hampshire",
        state_code: "HAM",
        latitude: "51.05769480",
        longitude: "-1.30806290",
        type: null,
      },
      {
        id: 2309,
        name: "Hartlepool",
        state_code: "HPL",
        latitude: "54.69174500",
        longitude: "-1.21292600",
        type: null,
      },
      {
        id: 2500,
        name: "Herefordshire",
        state_code: "HEF",
        latitude: "52.07651640",
        longitude: "-2.65441820",
        type: null,
      },
      {
        id: 2369,
        name: "Hertfordshire",
        state_code: "HRT",
        latitude: "51.80978230",
        longitude: "-0.23767440",
        type: null,
      },
      {
        id: 2383,
        name: "Highland",
        state_code: "HLD",
        latitude: "36.29675080",
        longitude: "-95.83803660",
        type: null,
      },
      {
        id: 2388,
        name: "Inverclyde",
        state_code: "IVC",
        latitude: "55.93165690",
        longitude: "-4.68001580",
        type: null,
      },
      {
        id: 2289,
        name: "Isle of Wight",
        state_code: "IOW",
        latitude: "50.69384790",
        longitude: "-1.30473400",
        type: null,
      },
      {
        id: 2343,
        name: "Isles of Scilly",
        state_code: "IOS",
        latitude: "49.92772610",
        longitude: "-6.32749660",
        type: null,
      },
      {
        id: 2464,
        name: "Kent",
        state_code: "KEN",
        latitude: "41.15366740",
        longitude: "-81.35788590",
        type: null,
      },
      {
        id: 2371,
        name: "Kirklees",
        state_code: "KIR",
        latitude: "53.59334320",
        longitude: "-1.80095090",
        type: null,
      },
      {
        id: 2330,
        name: "Knowsley",
        state_code: "KWL",
        latitude: "53.45459400",
        longitude: "-2.85290700",
        type: null,
      },
      {
        id: 2495,
        name: "Lancashire",
        state_code: "LAN",
        latitude: "53.76322540",
        longitude: "-2.70440520",
        type: null,
      },
      {
        id: 2515,
        name: "Larne Borough Council",
        state_code: "LRN",
        latitude: "54.85780030",
        longitude: "-5.82362240",
        type: null,
      },
      {
        id: 2503,
        name: "Leeds",
        state_code: "LDS",
        latitude: "53.80075540",
        longitude: "-1.54907740",
        type: null,
      },
      {
        id: 2516,
        name: "Leicestershire",
        state_code: "LEC",
        latitude: "52.77257100",
        longitude: "-1.20521260",
        type: null,
      },
      {
        id: 2382,
        name: "Limavady Borough Council",
        state_code: "LMV",
        latitude: "55.05168200",
        longitude: "-6.94919440",
        type: null,
      },
      {
        id: 2355,
        name: "Lincolnshire",
        state_code: "LIN",
        latitude: "52.94518890",
        longitude: "-0.16012460",
        type: null,
      },
      {
        id: 2460,
        name: "Lisburn and Castlereagh",
        state_code: "LBC",
        latitude: "54.49815840",
        longitude: "-6.13067910",
        type: null,
      },
      {
        id: 2494,
        name: "Lisburn City Council",
        state_code: "LSB",
        latitude: "54.49815840",
        longitude: "-6.13067910",
        type: null,
      },
      {
        id: 2340,
        name: "Liverpool",
        state_code: "LIV",
        latitude: "32.65649810",
        longitude: "-115.47632410",
        type: null,
      },
      {
        id: 2356,
        name: "London Borough of Barking and Dagenham",
        state_code: "BDG",
        latitude: "51.55406660",
        longitude: "0.13401700",
        type: null,
      },
      {
        id: 2520,
        name: "London Borough of Barnet",
        state_code: "BNE",
        latitude: "51.60496730",
        longitude: "-0.20762950",
        type: null,
      },
      {
        id: 2307,
        name: "London Borough of Bexley",
        state_code: "BEX",
        latitude: "51.45190210",
        longitude: "0.11717860",
        type: null,
      },
      {
        id: 2291,
        name: "London Borough of Brent",
        state_code: "BEN",
        latitude: "51.56728080",
        longitude: "-0.27105680",
        type: null,
      },
      {
        id: 2490,
        name: "London Borough of Bromley",
        state_code: "BRY",
        latitude: "51.36797050",
        longitude: "0.07006200",
        type: null,
      },
      {
        id: 2349,
        name: "London Borough of Camden",
        state_code: "CMD",
        latitude: "51.54547360",
        longitude: "-0.16279020",
        type: null,
      },
      {
        id: 2512,
        name: "London Borough of Croydon",
        state_code: "CRY",
        latitude: "51.38274460",
        longitude: "-0.09851630",
        type: null,
      },
      {
        id: 2532,
        name: "London Borough of Ealing",
        state_code: "EAL",
        latitude: "51.52503660",
        longitude: "-0.34139650",
        type: null,
      },
      {
        id: 2476,
        name: "London Borough of Enfield",
        state_code: "ENF",
        latitude: "51.66229090",
        longitude: "-0.11806510",
        type: null,
      },
      {
        id: 2411,
        name: "London Borough of Hackney",
        state_code: "HCK",
        latitude: "51.57344500",
        longitude: "-0.07243760",
        type: null,
      },
      {
        id: 2448,
        name: "London Borough of Hammersmith and Fulham",
        state_code: "HMF",
        latitude: "51.49901560",
        longitude: "-0.22915000",
        type: null,
      },
      {
        id: 2306,
        name: "London Borough of Haringey",
        state_code: "HRY",
        latitude: "51.59061130",
        longitude: "-0.11097090",
        type: null,
      },
      {
        id: 2385,
        name: "London Borough of Harrow",
        state_code: "HRW",
        latitude: "51.58816270",
        longitude: "-0.34228510",
        type: null,
      },
      {
        id: 2347,
        name: "London Borough of Havering",
        state_code: "HAV",
        latitude: "51.57792400",
        longitude: "0.21208290",
        type: null,
      },
      {
        id: 2376,
        name: "London Borough of Hillingdon",
        state_code: "HIL",
        latitude: "51.53518320",
        longitude: "-0.44813780",
        type: null,
      },
      {
        id: 2380,
        name: "London Borough of Hounslow",
        state_code: "HNS",
        latitude: "51.48283580",
        longitude: "-0.38820620",
        type: null,
      },
      {
        id: 2319,
        name: "London Borough of Islington",
        state_code: "ISL",
        latitude: "51.54650630",
        longitude: "-0.10580580",
        type: null,
      },
      {
        id: 2396,
        name: "London Borough of Lambeth",
        state_code: "LBH",
        latitude: "51.45714770",
        longitude: "-0.12306810",
        type: null,
      },
      {
        id: 2358,
        name: "London Borough of Lewisham",
        state_code: "LEW",
        latitude: "51.44145790",
        longitude: "-0.01170060",
        type: null,
      },
      {
        id: 2483,
        name: "London Borough of Merton",
        state_code: "MRT",
        latitude: "51.40977420",
        longitude: "-0.21080840",
        type: null,
      },
      {
        id: 2418,
        name: "London Borough of Newham",
        state_code: "NWM",
        latitude: "51.52551620",
        longitude: "0.03521630",
        type: null,
      },
      {
        id: 2397,
        name: "London Borough of Redbridge",
        state_code: "RDB",
        latitude: "51.58861210",
        longitude: "0.08239820",
        type: null,
      },
      {
        id: 2501,
        name: "London Borough of Richmond upon Thames",
        state_code: "RIC",
        latitude: "51.46130540",
        longitude: "-0.30377090",
        type: null,
      },
      {
        id: 2432,
        name: "London Borough of Southwark",
        state_code: "SWK",
        latitude: "51.48805720",
        longitude: "-0.07628380",
        type: null,
      },
      {
        id: 2313,
        name: "London Borough of Sutton",
        state_code: "STN",
        latitude: "51.35737620",
        longitude: "-0.17527960",
        type: null,
      },
      {
        id: 2390,
        name: "London Borough of Tower Hamlets",
        state_code: "TWH",
        latitude: "51.52026070",
        longitude: "-0.02933960",
        type: null,
      },
      {
        id: 2326,
        name: "London Borough of Waltham Forest",
        state_code: "WFT",
        latitude: "51.58863830",
        longitude: "-0.01176250",
        type: null,
      },
      {
        id: 2434,
        name: "London Borough of Wandsworth",
        state_code: "WND",
        latitude: "51.45682740",
        longitude: "-0.18966380",
        type: null,
      },
      {
        id: 2322,
        name: "Magherafelt District Council",
        state_code: "MFT",
        latitude: "54.75532790",
        longitude: "-6.60774870",
        type: null,
      },
      {
        id: 2398,
        name: "Manchester",
        state_code: "MAN",
        latitude: "53.48075930",
        longitude: "-2.24263050",
        type: null,
      },
      {
        id: 2381,
        name: "Medway",
        state_code: "MDW",
        latitude: "42.14176410",
        longitude: "-71.39672560",
        type: null,
      },
      {
        id: 2328,
        name: "Merthyr Tydfil County Borough",
        state_code: "MTY",
        latitude: "51.74674740",
        longitude: "-3.38132750",
        type: null,
      },
      {
        id: 2320,
        name: "Metropolitan Borough of Wigan",
        state_code: "WGN",
        latitude: "53.51348120",
        longitude: "-2.61069990",
        type: null,
      },
      {
        id: 2429,
        name: "Mid and East Antrim",
        state_code: "MEA",
        latitude: "54.93993410",
        longitude: "-6.11374230",
        type: null,
      },
      {
        id: 2399,
        name: "Mid Ulster",
        state_code: "MUL",
        latitude: "54.64113010",
        longitude: "-6.75225490",
        type: null,
      },
      {
        id: 2332,
        name: "Middlesbrough",
        state_code: "MDB",
        latitude: "54.57422700",
        longitude: "-1.23495600",
        type: null,
      },
      {
        id: 2519,
        name: "Midlothian",
        state_code: "MLN",
        latitude: "32.47533500",
        longitude: "-97.01031810",
        type: null,
      },
      {
        id: 2416,
        name: "Milton Keynes",
        state_code: "MIK",
        latitude: "52.08520380",
        longitude: "-0.73331330",
        type: null,
      },
      {
        id: 2402,
        name: "Monmouthshire",
        state_code: "MON",
        latitude: "51.81161000",
        longitude: "-2.71634170",
        type: null,
      },
      {
        id: 2360,
        name: "Moray",
        state_code: "MRY",
        latitude: "57.64984760",
        longitude: "-3.31680390",
        type: null,
      },
      {
        id: 2348,
        name: "Moyle District Council",
        state_code: "MYL",
        latitude: "55.20473270",
        longitude: "-6.25317400",
        type: null,
      },
      {
        id: 2351,
        name: "Neath Port Talbot County Borough",
        state_code: "NTL",
        latitude: "51.59785190",
        longitude: "-3.78396680",
        type: null,
      },
      {
        id: 2458,
        name: "Newcastle upon Tyne",
        state_code: "NET",
        latitude: "54.97825200",
        longitude: "-1.61778000",
        type: null,
      },
      {
        id: 2524,
        name: "Newport",
        state_code: "NWP",
        latitude: "37.52782340",
        longitude: "-94.10438760",
        type: null,
      },
      {
        id: 2350,
        name: "Newry and Mourne District Council",
        state_code: "NYM",
        latitude: "54.17425050",
        longitude: "-6.33919920",
        type: null,
      },
      {
        id: 2534,
        name: "Newry, Mourne and Down",
        state_code: "NMD",
        latitude: "54.24342870",
        longitude: "-5.95779590",
        type: null,
      },
      {
        id: 2317,
        name: "Newtownabbey Borough Council",
        state_code: "NTA",
        latitude: "54.67924220",
        longitude: "-5.95911020",
        type: null,
      },
      {
        id: 2473,
        name: "Norfolk",
        state_code: "NFK",
        latitude: "36.85076890",
        longitude: "-76.28587260",
        type: null,
      },
      {
        id: 2535,
        name: "North Ayrshire",
        state_code: "NAY",
        latitude: "55.64167310",
        longitude: "-4.75946000",
        type: null,
      },
      {
        id: 2513,
        name: "North Down Borough Council",
        state_code: "NDN",
        latitude: "54.65362970",
        longitude: "-5.67249250",
        type: null,
      },
      {
        id: 2384,
        name: "North East Lincolnshire",
        state_code: "NEL",
        latitude: "53.56682010",
        longitude: "-0.08150660",
        type: null,
      },
      {
        id: 2487,
        name: "North Lanarkshire",
        state_code: "NLK",
        latitude: "55.86624320",
        longitude: "-3.96131440",
        type: null,
      },
      {
        id: 2453,
        name: "North Lincolnshire",
        state_code: "NLN",
        latitude: "53.60555920",
        longitude: "-0.55965820",
        type: null,
      },
      {
        id: 2430,
        name: "North Somerset",
        state_code: "NSM",
        latitude: "51.38790280",
        longitude: "-2.77810910",
        type: null,
      },
      {
        id: 2521,
        name: "North Tyneside",
        state_code: "NTY",
        latitude: "55.01823990",
        longitude: "-1.48584360",
        type: null,
      },
      {
        id: 2522,
        name: "North Yorkshire",
        state_code: "NYK",
        latitude: "53.99150280",
        longitude: "-1.54120150",
        type: null,
      },
      {
        id: 2480,
        name: "Northamptonshire",
        state_code: "NTH",
        latitude: "52.27299440",
        longitude: "-0.87555150",
        type: null,
      },
      {
        id: 2337,
        name: "Northern Ireland",
        state_code: "NIR",
        latitude: "54.78771490",
        longitude: "-6.49231450",
        type: null,
      },
      {
        id: 2365,
        name: "Northumberland",
        state_code: "NBL",
        latitude: "55.20825420",
        longitude: "-2.07841380",
        type: null,
      },
      {
        id: 2456,
        name: "Nottinghamshire",
        state_code: "NTT",
        latitude: "53.10031900",
        longitude: "-0.99363060",
        type: null,
      },
      {
        id: 2477,
        name: "Oldham",
        state_code: "OLD",
        latitude: "42.20405980",
        longitude: "-71.20481190",
        type: null,
      },
      {
        id: 2314,
        name: "Omagh District Council",
        state_code: "OMH",
        latitude: "54.45135240",
        longitude: "-7.71250180",
        type: null,
      },
      {
        id: 2474,
        name: "Orkney Islands",
        state_code: "ORK",
        latitude: "58.98094010",
        longitude: "-2.96052060",
        type: null,
      },
      {
        id: 2353,
        name: "Outer Hebrides",
        state_code: "ELS",
        latitude: "57.75989180",
        longitude: "-7.01940340",
        type: null,
      },
      {
        id: 2321,
        name: "Oxfordshire",
        state_code: "OXF",
        latitude: "51.76120560",
        longitude: "-1.24646740",
        type: null,
      },
      {
        id: 2486,
        name: "Pembrokeshire",
        state_code: "PEM",
        latitude: "51.67407800",
        longitude: "-4.90887850",
        type: null,
      },
      {
        id: 2325,
        name: "Perth and Kinross",
        state_code: "PKN",
        latitude: "56.39538170",
        longitude: "-3.42835470",
        type: null,
      },
      {
        id: 2302,
        name: "Poole",
        state_code: "POL",
        latitude: "50.71505000",
        longitude: "-1.98724800",
        type: null,
      },
      {
        id: 2441,
        name: "Powys",
        state_code: "POW",
        latitude: "52.64642490",
        longitude: "-3.32609040",
        type: null,
      },
      {
        id: 2455,
        name: "Reading",
        state_code: "RDG",
        latitude: "36.14866590",
        longitude: "-95.98400120",
        type: null,
      },
      {
        id: 2527,
        name: "Redcar and Cleveland",
        state_code: "RCC",
        latitude: "54.59713440",
        longitude: "-1.07759970",
        type: null,
      },
      {
        id: 2443,
        name: "Renfrewshire",
        state_code: "RFW",
        latitude: "55.84665400",
        longitude: "-4.53312590",
        type: null,
      },
      {
        id: 2301,
        name: "Rhondda Cynon Taf",
        state_code: "RCT",
        latitude: "51.64902070",
        longitude: "-3.42886920",
        type: null,
      },
      {
        id: 2327,
        name: "Rochdale",
        state_code: "RCH",
        latitude: "53.60971360",
        longitude: "-2.15610000",
        type: null,
      },
      {
        id: 2308,
        name: "Rotherham",
        state_code: "ROT",
        latitude: "53.43260350",
        longitude: "-1.36350090",
        type: null,
      },
      {
        id: 2492,
        name: "Royal Borough of Greenwich",
        state_code: "GRE",
        latitude: "51.48346270",
        longitude: "0.05862020",
        type: null,
      },
      {
        id: 2368,
        name: "Royal Borough of Kensington and Chelsea",
        state_code: "KEC",
        latitude: "51.49908050",
        longitude: "-0.19382530",
        type: null,
      },
      {
        id: 2481,
        name: "Royal Borough of Kingston upon Thames",
        state_code: "KTT",
        latitude: "51.37811700",
        longitude: "-0.29270900",
        type: null,
      },
      {
        id: 2472,
        name: "Rutland",
        state_code: "RUT",
        latitude: "43.61062370",
        longitude: "-72.97260650",
        type: null,
      },
      {
        id: 2502,
        name: "Saint Helena",
        state_code: "SH-HL",
        latitude: "-15.96501040",
        longitude: "-5.70892410",
        type: null,
      },
      {
        id: 2493,
        name: "Salford",
        state_code: "SLF",
        latitude: "53.48752350",
        longitude: "-2.29012640",
        type: null,
      },
      {
        id: 2341,
        name: "Sandwell",
        state_code: "SAW",
        latitude: "52.53616740",
        longitude: "-2.01079300",
        type: null,
      },
      {
        id: 2335,
        name: "Scotland",
        state_code: "SCT",
        latitude: "56.49067120",
        longitude: "-4.20264580",
        type: null,
      },
      {
        id: 2346,
        name: "Scottish Borders",
        state_code: "SCB",
        latitude: "55.54856970",
        longitude: "-2.78613880",
        type: null,
      },
      {
        id: 2518,
        name: "Sefton",
        state_code: "SFT",
        latitude: "53.50344490",
        longitude: "-2.97035900",
        type: null,
      },
      {
        id: 2295,
        name: "Sheffield",
        state_code: "SHF",
        latitude: "36.09507430",
        longitude: "-80.27884660",
        type: null,
      },
      {
        id: 2300,
        name: "Shetland Islands",
        state_code: "ZET",
        latitude: "60.52965070",
        longitude: "-1.26594090",
        type: null,
      },
      {
        id: 2407,
        name: "Shropshire",
        state_code: "SHR",
        latitude: "52.70636570",
        longitude: "-2.74178490",
        type: null,
      },
      {
        id: 2427,
        name: "Slough",
        state_code: "SLG",
        latitude: "51.51053840",
        longitude: "-0.59504060",
        type: null,
      },
      {
        id: 2469,
        name: "Solihull",
        state_code: "SOL",
        latitude: "52.41181100",
        longitude: "-1.77761000",
        type: null,
      },
      {
        id: 2386,
        name: "Somerset",
        state_code: "SOM",
        latitude: "51.10509700",
        longitude: "-2.92623070",
        type: null,
      },
      {
        id: 2377,
        name: "South Ayrshire",
        state_code: "SAY",
        latitude: "55.45889880",
        longitude: "-4.62919940",
        type: null,
      },
      {
        id: 2400,
        name: "South Gloucestershire",
        state_code: "SGC",
        latitude: "51.52643610",
        longitude: "-2.47284870",
        type: null,
      },
      {
        id: 2362,
        name: "South Lanarkshire",
        state_code: "SLK",
        latitude: "55.67359090",
        longitude: "-3.78196610",
        type: null,
      },
      {
        id: 2409,
        name: "South Tyneside",
        state_code: "STY",
        latitude: "54.96366930",
        longitude: "-1.44186340",
        type: null,
      },
      {
        id: 2323,
        name: "Southend-on-Sea",
        state_code: "SOS",
        latitude: "51.54592690",
        longitude: "0.70771230",
        type: null,
      },
      {
        id: 2290,
        name: "St Helens",
        state_code: "SHN",
        latitude: "45.85896100",
        longitude: "-122.82123560",
        type: null,
      },
      {
        id: 2447,
        name: "Staffordshire",
        state_code: "STS",
        latitude: "52.87927450",
        longitude: "-2.05718680",
        type: null,
      },
      {
        id: 2488,
        name: "Stirling",
        state_code: "STG",
        latitude: "56.11652270",
        longitude: "-3.93690290",
        type: null,
      },
      {
        id: 2394,
        name: "Stockport",
        state_code: "SKP",
        latitude: "53.41063160",
        longitude: "-2.15753320",
        type: null,
      },
      {
        id: 2421,
        name: "Stockton-on-Tees",
        state_code: "STT",
        latitude: "54.57045510",
        longitude: "-1.32898210",
        type: null,
      },
      {
        id: 2393,
        name: "Strabane District Council",
        state_code: "STB",
        latitude: "54.82738650",
        longitude: "-7.46331030",
        type: null,
      },
      {
        id: 2467,
        name: "Suffolk",
        state_code: "SFK",
        latitude: "52.18724720",
        longitude: "0.97078010",
        type: null,
      },
      {
        id: 2526,
        name: "Surrey",
        state_code: "SRY",
        latitude: "51.31475930",
        longitude: "-0.55995010",
        type: null,
      },
      {
        id: 2422,
        name: "Swindon",
        state_code: "SWD",
        latitude: "51.55577390",
        longitude: "-1.77971760",
        type: null,
      },
      {
        id: 2367,
        name: "Tameside",
        state_code: "TAM",
        latitude: "53.48058280",
        longitude: "-2.08098910",
        type: null,
      },
      {
        id: 2310,
        name: "Telford and Wrekin",
        state_code: "TFW",
        latitude: "52.74099160",
        longitude: "-2.48685860",
        type: null,
      },
      {
        id: 2468,
        name: "Thurrock",
        state_code: "THR",
        latitude: "51.49345570",
        longitude: "0.35291970",
        type: null,
      },
      {
        id: 2478,
        name: "Torbay",
        state_code: "TOB",
        latitude: "50.43923290",
        longitude: "-3.53698990",
        type: null,
      },
      {
        id: 2496,
        name: "Torfaen",
        state_code: "TOF",
        latitude: "51.70022530",
        longitude: "-3.04460150",
        type: null,
      },
      {
        id: 2293,
        name: "Trafford",
        state_code: "TRF",
        latitude: "40.38562460",
        longitude: "-79.75893470",
        type: null,
      },
      {
        id: 2375,
        name: "United Kingdom",
        state_code: "UKM",
        latitude: "55.37805100",
        longitude: "-3.43597300",
        type: null,
      },
      {
        id: 2299,
        name: "Vale of Glamorgan",
        state_code: "VGL",
        latitude: "51.40959580",
        longitude: "-3.48481670",
        type: null,
      },
      {
        id: 2465,
        name: "Wakefield",
        state_code: "WKF",
        latitude: "42.50393950",
        longitude: "-71.07233910",
        type: null,
      },
      {
        id: 2338,
        name: "Wales",
        state_code: "WLS",
        latitude: "52.13066070",
        longitude: "-3.78371170",
        type: null,
      },
      {
        id: 2292,
        name: "Walsall",
        state_code: "WLL",
        latitude: "52.58621400",
        longitude: "-1.98291900",
        type: null,
      },
      {
        id: 2420,
        name: "Warrington",
        state_code: "WRT",
        latitude: "40.24927410",
        longitude: "-75.13406040",
        type: null,
      },
      {
        id: 2505,
        name: "Warwickshire",
        state_code: "WAR",
        latitude: "52.26713530",
        longitude: "-1.46752160",
        type: null,
      },
      {
        id: 2471,
        name: "West Berkshire",
        state_code: "WBK",
        latitude: "51.43082550",
        longitude: "-1.14449270",
        type: null,
      },
      {
        id: 2440,
        name: "West Dunbartonshire",
        state_code: "WDU",
        latitude: "55.94509250",
        longitude: "-4.56462590",
        type: null,
      },
      {
        id: 2354,
        name: "West Lothian",
        state_code: "WLN",
        latitude: "55.90701980",
        longitude: "-3.55171670",
        type: null,
      },
      {
        id: 2296,
        name: "West Sussex",
        state_code: "WSX",
        latitude: "50.92801430",
        longitude: "-0.46170750",
        type: null,
      },
      {
        id: 2391,
        name: "Wiltshire",
        state_code: "WIL",
        latitude: "51.34919960",
        longitude: "-1.99271050",
        type: null,
      },
      {
        id: 2482,
        name: "Windsor and Maidenhead",
        state_code: "WNM",
        latitude: "51.47997120",
        longitude: "-0.62425650",
        type: null,
      },
      {
        id: 2408,
        name: "Wirral",
        state_code: "WRL",
        latitude: "53.37271810",
        longitude: "-3.07375400",
        type: null,
      },
      {
        id: 2419,
        name: "Wokingham",
        state_code: "WOK",
        latitude: "51.41045700",
        longitude: "-0.83386100",
        type: null,
      },
      {
        id: 2510,
        name: "Worcestershire",
        state_code: "WOR",
        latitude: "52.25452250",
        longitude: "-2.26683820",
        type: null,
      },
      {
        id: 2436,
        name: "Wrexham County Borough",
        state_code: "WRX",
        latitude: "53.03013780",
        longitude: "-3.02614870",
        type: null,
      },
    ],
  },
  {
    name: "United States",
    iso3: "USA",
    iso2: "US",
    numeric_code: "840",
    phone_code: "1",
    capital: "Washington",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "American",
    timezones: [
      {
        zoneName: "America/Adak",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii–Aleutian Standard Time",
      },
      {
        zoneName: "America/Anchorage",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Boise",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Chicago",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Denver",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Detroit",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Indianapolis",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Knox",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Marengo",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Petersburg",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Tell_City",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Vevay",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Vincennes",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Indiana/Winamac",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Juneau",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Kentucky/Louisville",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Kentucky/Monticello",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Los_Angeles",
        gmtOffset: -28800,
        gmtOffsetName: "UTC-08:00",
        abbreviation: "PST",
        tzName: "Pacific Standard Time (North America",
      },
      {
        zoneName: "America/Menominee",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Metlakatla",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/New_York",
        gmtOffset: -18000,
        gmtOffsetName: "UTC-05:00",
        abbreviation: "EST",
        tzName: "Eastern Standard Time (North America",
      },
      {
        zoneName: "America/Nome",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/North_Dakota/Beulah",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/North_Dakota/Center",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/North_Dakota/New_Salem",
        gmtOffset: -21600,
        gmtOffsetName: "UTC-06:00",
        abbreviation: "CST",
        tzName: "Central Standard Time (North America",
      },
      {
        zoneName: "America/Phoenix",
        gmtOffset: -25200,
        gmtOffsetName: "UTC-07:00",
        abbreviation: "MST",
        tzName: "Mountain Standard Time (North America",
      },
      {
        zoneName: "America/Sitka",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "America/Yakutat",
        gmtOffset: -32400,
        gmtOffsetName: "UTC-09:00",
        abbreviation: "AKST",
        tzName: "Alaska Standard Time",
      },
      {
        zoneName: "Pacific/Honolulu",
        gmtOffset: -36000,
        gmtOffsetName: "UTC-10:00",
        abbreviation: "HST",
        tzName: "Hawaii–Aleutian Standard Time",
      },
    ],
    translations: {
      kr: "미국",
      "pt-BR": "Estados Unidos",
      pt: "Estados Unidos",
      nl: "Verenigde Staten",
      hr: "Sjedinjene Američke Države",
      fa: "ایالات متحده آمریکا",
      de: "Vereinigte Staaten von Amerika",
      es: "Estados Unidos",
      fr: "États-Unis",
      ja: "アメリカ合衆国",
      it: "Stati Uniti D'America",
      cn: "美国",
      tr: "Amerika",
    },
    latitude: "38.00000000",
    longitude: "-97.00000000",
    emoji: "🇺🇸",
    emojiU: "U+1F1FA U+1F1F8",
    states: [
      {
        id: 1456,
        name: "Alabama",
        state_code: "AL",
        latitude: "32.31823140",
        longitude: "-86.90229800",
        type: "state",
      },
      {
        id: 1400,
        name: "Alaska",
        state_code: "AK",
        latitude: "64.20084130",
        longitude: "-149.49367330",
        type: "state",
      },
      {
        id: 1424,
        name: "American Samoa",
        state_code: "AS",
        latitude: "-14.27097200",
        longitude: "-170.13221700",
        type: "outlying area",
      },
      {
        id: 1434,
        name: "Arizona",
        state_code: "AZ",
        latitude: "34.04892810",
        longitude: "-111.09373110",
        type: "state",
      },
      {
        id: 1444,
        name: "Arkansas",
        state_code: "AR",
        latitude: "35.20105000",
        longitude: "-91.83183340",
        type: "state",
      },
      {
        id: 1402,
        name: "Baker Island",
        state_code: "UM-81",
        latitude: "0.19362660",
        longitude: "-176.47690800",
        type: "islands / groups of islands",
      },
      {
        id: 1416,
        name: "California",
        state_code: "CA",
        latitude: "36.77826100",
        longitude: "-119.41793240",
        type: "state",
      },
      {
        id: 1450,
        name: "Colorado",
        state_code: "CO",
        latitude: "39.55005070",
        longitude: "-105.78206740",
        type: "state",
      },
      {
        id: 1435,
        name: "Connecticut",
        state_code: "CT",
        latitude: "41.60322070",
        longitude: "-73.08774900",
        type: "state",
      },
      {
        id: 1399,
        name: "Delaware",
        state_code: "DE",
        latitude: "38.91083250",
        longitude: "-75.52766990",
        type: "state",
      },
      {
        id: 1437,
        name: "District of Columbia",
        state_code: "DC",
        latitude: "38.90719230",
        longitude: "-77.03687070",
        type: "district",
      },
      {
        id: 1436,
        name: "Florida",
        state_code: "FL",
        latitude: "27.66482740",
        longitude: "-81.51575350",
        type: "state",
      },
      {
        id: 1455,
        name: "Georgia",
        state_code: "GA",
        latitude: "32.16562210",
        longitude: "-82.90007510",
        type: "state",
      },
      {
        id: 1412,
        name: "Guam",
        state_code: "GU",
        latitude: "13.44430400",
        longitude: "144.79373100",
        type: "outlying area",
      },
      {
        id: 1411,
        name: "Hawaii",
        state_code: "HI",
        latitude: "19.89676620",
        longitude: "-155.58278180",
        type: "state",
      },
      {
        id: 1398,
        name: "Howland Island",
        state_code: "UM-84",
        latitude: "0.81132190",
        longitude: "-176.61827360",
        type: "islands / groups of islands",
      },
      {
        id: 1460,
        name: "Idaho",
        state_code: "ID",
        latitude: "44.06820190",
        longitude: "-114.74204080",
        type: "state",
      },
      {
        id: 1425,
        name: "Illinois",
        state_code: "IL",
        latitude: "40.63312490",
        longitude: "-89.39852830",
        type: "state",
      },
      {
        id: 1440,
        name: "Indiana",
        state_code: "IN",
        latitude: "40.26719410",
        longitude: "-86.13490190",
        type: "state",
      },
      {
        id: 1459,
        name: "Iowa",
        state_code: "IA",
        latitude: "41.87800250",
        longitude: "-93.09770200",
        type: "state",
      },
      {
        id: 1410,
        name: "Jarvis Island",
        state_code: "UM-86",
        latitude: "-0.37435030",
        longitude: "-159.99672060",
        type: "islands / groups of islands",
      },
      {
        id: 1428,
        name: "Johnston Atoll",
        state_code: "UM-67",
        latitude: "16.72950350",
        longitude: "-169.53364770",
        type: "islands / groups of islands",
      },
      {
        id: 1406,
        name: "Kansas",
        state_code: "KS",
        latitude: "39.01190200",
        longitude: "-98.48424650",
        type: "state",
      },
      {
        id: 1419,
        name: "Kentucky",
        state_code: "KY",
        latitude: "37.83933320",
        longitude: "-84.27001790",
        type: "state",
      },
      {
        id: 1403,
        name: "Kingman Reef",
        state_code: "UM-89",
        latitude: "6.38333300",
        longitude: "-162.41666700",
        type: "islands / groups of islands",
      },
      {
        id: 1457,
        name: "Louisiana",
        state_code: "LA",
        latitude: "30.98429770",
        longitude: "-91.96233270",
        type: "state",
      },
      {
        id: 1453,
        name: "Maine",
        state_code: "ME",
        latitude: "45.25378300",
        longitude: "-69.44546890",
        type: "state",
      },
      {
        id: 1401,
        name: "Maryland",
        state_code: "MD",
        latitude: "39.04575490",
        longitude: "-76.64127120",
        type: "state",
      },
      {
        id: 1433,
        name: "Massachusetts",
        state_code: "MA",
        latitude: "42.40721070",
        longitude: "-71.38243740",
        type: "state",
      },
      {
        id: 1426,
        name: "Michigan",
        state_code: "MI",
        latitude: "44.31484430",
        longitude: "-85.60236430",
        type: "state",
      },
      {
        id: 1438,
        name: "Midway Atoll",
        state_code: "UM-71",
        latitude: "28.20721680",
        longitude: "-177.37349260",
        type: "islands / groups of islands",
      },
      {
        id: 1420,
        name: "Minnesota",
        state_code: "MN",
        latitude: "46.72955300",
        longitude: "-94.68589980",
        type: "state",
      },
      {
        id: 1430,
        name: "Mississippi",
        state_code: "MS",
        latitude: "32.35466790",
        longitude: "-89.39852830",
        type: "state",
      },
      {
        id: 1451,
        name: "Missouri",
        state_code: "MO",
        latitude: "37.96425290",
        longitude: "-91.83183340",
        type: "state",
      },
      {
        id: 1446,
        name: "Montana",
        state_code: "MT",
        latitude: "46.87968220",
        longitude: "-110.36256580",
        type: "state",
      },
      {
        id: 1439,
        name: "Navassa Island",
        state_code: "UM-76",
        latitude: "18.41006890",
        longitude: "-75.01146120",
        type: "islands / groups of islands",
      },
      {
        id: 1408,
        name: "Nebraska",
        state_code: "NE",
        latitude: "41.49253740",
        longitude: "-99.90181310",
        type: "state",
      },
      {
        id: 1458,
        name: "Nevada",
        state_code: "NV",
        latitude: "38.80260970",
        longitude: "-116.41938900",
        type: "state",
      },
      {
        id: 1404,
        name: "New Hampshire",
        state_code: "NH",
        latitude: "43.19385160",
        longitude: "-71.57239530",
        type: "state",
      },
      {
        id: 1417,
        name: "New Jersey",
        state_code: "NJ",
        latitude: "40.05832380",
        longitude: "-74.40566120",
        type: "state",
      },
      {
        id: 1423,
        name: "New Mexico",
        state_code: "NM",
        latitude: "34.51994020",
        longitude: "-105.87009010",
        type: "state",
      },
      {
        id: 1452,
        name: "New York",
        state_code: "NY",
        latitude: "40.71277530",
        longitude: "-74.00597280",
        type: "state",
      },
      {
        id: 1447,
        name: "North Carolina",
        state_code: "NC",
        latitude: "35.75957310",
        longitude: "-79.01929970",
        type: "state",
      },
      {
        id: 1418,
        name: "North Dakota",
        state_code: "ND",
        latitude: "47.55149260",
        longitude: "-101.00201190",
        type: "state",
      },
      {
        id: 1431,
        name: "Northern Mariana Islands",
        state_code: "MP",
        latitude: "15.09790000",
        longitude: "145.67390000",
        type: "outlying area",
      },
      {
        id: 4851,
        name: "Ohio",
        state_code: "OH",
        latitude: "40.41728710",
        longitude: "-82.90712300",
        type: "state",
      },
      {
        id: 1421,
        name: "Oklahoma",
        state_code: "OK",
        latitude: "35.46756020",
        longitude: "-97.51642760",
        type: "state",
      },
      {
        id: 1415,
        name: "Oregon",
        state_code: "OR",
        latitude: "43.80413340",
        longitude: "-120.55420120",
        type: "state",
      },
      {
        id: 1448,
        name: "Palmyra Atoll",
        state_code: "UM-95",
        latitude: "5.88850260",
        longitude: "-162.07866560",
        type: "islands / groups of islands",
      },
      {
        id: 1422,
        name: "Pennsylvania",
        state_code: "PA",
        latitude: "41.20332160",
        longitude: "-77.19452470",
        type: "state",
      },
      {
        id: 1449,
        name: "Puerto Rico",
        state_code: "PR",
        latitude: "18.22083300",
        longitude: "-66.59014900",
        type: "outlying area",
      },
      {
        id: 1461,
        name: "Rhode Island",
        state_code: "RI",
        latitude: "41.58009450",
        longitude: "-71.47742910",
        type: "state",
      },
      {
        id: 1443,
        name: "South Carolina",
        state_code: "SC",
        latitude: "33.83608100",
        longitude: "-81.16372450",
        type: "state",
      },
      {
        id: 1445,
        name: "South Dakota",
        state_code: "SD",
        latitude: "43.96951480",
        longitude: "-99.90181310",
        type: "state",
      },
      {
        id: 1454,
        name: "Tennessee",
        state_code: "TN",
        latitude: "35.51749130",
        longitude: "-86.58044730",
        type: "state",
      },
      {
        id: 1407,
        name: "Texas",
        state_code: "TX",
        latitude: "31.96859880",
        longitude: "-99.90181310",
        type: "state",
      },
      {
        id: 1432,
        name: "United States Minor Outlying Islands",
        state_code: "UM",
        latitude: "19.28231920",
        longitude: "166.64704700",
        type: "outlying area",
      },
      {
        id: 1413,
        name: "United States Virgin Islands",
        state_code: "VI",
        latitude: "18.33576500",
        longitude: "-64.89633500",
        type: "outlying area",
      },
      {
        id: 1414,
        name: "Utah",
        state_code: "UT",
        latitude: "39.32098010",
        longitude: "-111.09373110",
        type: "state",
      },
      {
        id: 1409,
        name: "Vermont",
        state_code: "VT",
        latitude: "44.55880280",
        longitude: "-72.57784150",
        type: "state",
      },
      {
        id: 1427,
        name: "Virginia",
        state_code: "VA",
        latitude: "37.43157340",
        longitude: "-78.65689420",
        type: "state",
      },
      {
        id: 1405,
        name: "Wake Island",
        state_code: "UM-79",
        latitude: "19.27961900",
        longitude: "166.64993480",
        type: "islands / groups of islands",
      },
      {
        id: 1462,
        name: "Washington",
        state_code: "WA",
        latitude: "47.75107410",
        longitude: "-120.74013850",
        type: "state",
      },
      {
        id: 1429,
        name: "West Virginia",
        state_code: "WV",
        latitude: "38.59762620",
        longitude: "-80.45490260",
        type: "state",
      },
      {
        id: 1441,
        name: "Wisconsin",
        state_code: "WI",
        latitude: "43.78443970",
        longitude: "-88.78786780",
        type: "state",
      },
      {
        id: 1442,
        name: "Wyoming",
        state_code: "WY",
        latitude: "43.07596780",
        longitude: "-107.29028390",
        type: "state",
      },
    ],
  },
  {
    name: "United States Minor Outlying Islands",
    iso3: "UMI",
    iso2: "UM",
    numeric_code: "581",
    phone_code: "1",
    capital: "",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".us",
    native: "United States Minor Outlying Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Northern America",
    subregion_id: "6",
    nationality: "American",
    timezones: [
      {
        zoneName: "Pacific/Midway",
        gmtOffset: -39600,
        gmtOffsetName: "UTC-11:00",
        abbreviation: "SST",
        tzName: "Samoa Standard Time",
      },
      {
        zoneName: "Pacific/Wake",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WAKT",
        tzName: "Wake Island Time",
      },
    ],
    translations: {
      kr: "미국령 군소 제도",
      "pt-BR": "Ilhas Menores Distantes dos Estados Unidos",
      pt: "Ilhas Menores Distantes dos Estados Unidos",
      nl: "Kleine afgelegen eilanden van de Verenigde Staten",
      hr: "Mali udaljeni otoci SAD-a",
      fa: "جزایر کوچک حاشیه‌ای ایالات متحده آمریکا",
      de: "Kleinere Inselbesitzungen der Vereinigten Staaten",
      es: "Islas Ultramarinas Menores de Estados Unidos",
      fr: "Îles mineures éloignées des États-Unis",
      ja: "合衆国領有小離島",
      it: "Isole minori esterne degli Stati Uniti d'America",
      cn: "美国本土外小岛屿",
      tr: "Abd Küçük Harici Adalari",
    },
    latitude: "0.00000000",
    longitude: "0.00000000",
    emoji: "🇺🇲",
    emojiU: "U+1F1FA U+1F1F2",
    states: [
      {
        id: 5212,
        name: "Baker Island",
        state_code: "81",
        latitude: "0.19362660",
        longitude: "-176.47690800",
        type: "island",
      },
      {
        id: 5213,
        name: "Howland Island",
        state_code: "84",
        latitude: "0.81132190",
        longitude: "-176.61827360",
        type: "island",
      },
      {
        id: 5214,
        name: "Jarvis Island",
        state_code: "86",
        latitude: "-0.37435030",
        longitude: "-159.99672060",
        type: "island",
      },
      {
        id: 5215,
        name: "Johnston Atoll",
        state_code: "67",
        latitude: "16.72950350",
        longitude: "-169.53364770",
        type: "island",
      },
      {
        id: 5216,
        name: "Kingman Reef",
        state_code: "89",
        latitude: "6.38333300",
        longitude: "-162.41666700",
        type: "island",
      },
      {
        id: 5217,
        name: "Midway Islands",
        state_code: "71",
        latitude: "28.20721680",
        longitude: "-177.37349260",
        type: "island",
      },
      {
        id: 5218,
        name: "Navassa Island",
        state_code: "76",
        latitude: "18.41006890",
        longitude: "-75.01146120",
        type: "island",
      },
      {
        id: 5219,
        name: "Palmyra Atoll",
        state_code: "95",
        latitude: "5.88850260",
        longitude: "-162.07866560",
        type: "island",
      },
      {
        id: 5220,
        name: "Wake Island",
        state_code: "79",
        latitude: "19.27961900",
        longitude: "166.64993480",
        type: "island",
      },
    ],
  },
  {
    name: "Uruguay",
    iso3: "URY",
    iso2: "UY",
    numeric_code: "858",
    phone_code: "598",
    capital: "Montevideo",
    currency: "UYU",
    currency_name: "Uruguayan peso",
    currency_symbol: "$",
    tld: ".uy",
    native: "Uruguay",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Uruguayan",
    timezones: [
      {
        zoneName: "America/Montevideo",
        gmtOffset: -10800,
        gmtOffsetName: "UTC-03:00",
        abbreviation: "UYT",
        tzName: "Uruguay Standard Time",
      },
    ],
    translations: {
      kr: "우루과이",
      "pt-BR": "Uruguai",
      pt: "Uruguai",
      nl: "Uruguay",
      hr: "Urugvaj",
      fa: "اروگوئه",
      de: "Uruguay",
      es: "Uruguay",
      fr: "Uruguay",
      ja: "ウルグアイ",
      it: "Uruguay",
      cn: "乌拉圭",
      tr: "Uruguay",
    },
    latitude: "-33.00000000",
    longitude: "-56.00000000",
    emoji: "🇺🇾",
    emojiU: "U+1F1FA U+1F1FE",
    states: [
      {
        id: 3205,
        name: "Artigas",
        state_code: "AR",
        latitude: "-30.61751120",
        longitude: "-56.95945590",
        type: null,
      },
      {
        id: 3213,
        name: "Canelones",
        state_code: "CA",
        latitude: "-34.54087170",
        longitude: "-55.93076000",
        type: null,
      },
      {
        id: 3211,
        name: "Cerro Largo",
        state_code: "CL",
        latitude: "-32.44110320",
        longitude: "-54.35217530",
        type: null,
      },
      {
        id: 3208,
        name: "Colonia",
        state_code: "CO",
        latitude: "-34.12946780",
        longitude: "-57.66051840",
        type: null,
      },
      {
        id: 3209,
        name: "Durazno",
        state_code: "DU",
        latitude: "-33.02324540",
        longitude: "-56.02846440",
        type: null,
      },
      {
        id: 3203,
        name: "Flores",
        state_code: "FS",
        latitude: "-33.57337530",
        longitude: "-56.89450280",
        type: null,
      },
      {
        id: 3217,
        name: "Florida",
        state_code: "FD",
        latitude: "28.03594950",
        longitude: "-82.45792890",
        type: null,
      },
      {
        id: 3215,
        name: "Lavalleja",
        state_code: "LA",
        latitude: "-33.92261750",
        longitude: "-54.97657940",
        type: null,
      },
      {
        id: 3206,
        name: "Maldonado",
        state_code: "MA",
        latitude: "-34.55979320",
        longitude: "-54.86285520",
        type: null,
      },
      {
        id: 3218,
        name: "Montevideo",
        state_code: "MO",
        latitude: "-34.81815870",
        longitude: "-56.21382560",
        type: null,
      },
      {
        id: 3212,
        name: "Paysandú",
        state_code: "PA",
        latitude: "-32.06673660",
        longitude: "-57.33647890",
        type: null,
      },
      {
        id: 3210,
        name: "Río Negro",
        state_code: "RN",
        latitude: "-32.76763560",
        longitude: "-57.42952070",
        type: null,
      },
      {
        id: 3207,
        name: "Rivera",
        state_code: "RV",
        latitude: "-31.48174210",
        longitude: "-55.24357590",
        type: null,
      },
      {
        id: 3216,
        name: "Rocha",
        state_code: "RO",
        latitude: "-33.96900810",
        longitude: "-54.02148500",
        type: null,
      },
      {
        id: 3220,
        name: "Salto",
        state_code: "SA",
        latitude: "-31.38802800",
        longitude: "-57.96124550",
        type: null,
      },
      {
        id: 3204,
        name: "San José",
        state_code: "SJ",
        latitude: "37.34929680",
        longitude: "-121.90560490",
        type: null,
      },
      {
        id: 3219,
        name: "Soriano",
        state_code: "SO",
        latitude: "-33.51027920",
        longitude: "-57.74981030",
        type: null,
      },
      {
        id: 3221,
        name: "Tacuarembó",
        state_code: "TA",
        latitude: "-31.72068370",
        longitude: "-55.98598870",
        type: null,
      },
      {
        id: 3214,
        name: "Treinta y Tres",
        state_code: "TT",
        latitude: "-33.06850860",
        longitude: "-54.28586270",
        type: null,
      },
    ],
  },
  {
    name: "Uzbekistan",
    iso3: "UZB",
    iso2: "UZ",
    numeric_code: "860",
    phone_code: "998",
    capital: "Tashkent",
    currency: "UZS",
    currency_name: "Uzbekistani soʻm",
    currency_symbol: "лв",
    tld: ".uz",
    native: "O‘zbekiston",
    region: "Asia",
    region_id: "3",
    subregion: "Central Asia",
    subregion_id: "10",
    nationality: "Uzbekistani, Uzbek",
    timezones: [
      {
        zoneName: "Asia/Samarkand",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time",
      },
      {
        zoneName: "Asia/Tashkent",
        gmtOffset: 18000,
        gmtOffsetName: "UTC+05:00",
        abbreviation: "UZT",
        tzName: "Uzbekistan Time",
      },
    ],
    translations: {
      kr: "우즈베키스탄",
      "pt-BR": "Uzbequistão",
      pt: "Usbequistão",
      nl: "Oezbekistan",
      hr: "Uzbekistan",
      fa: "ازبکستان",
      de: "Usbekistan",
      es: "Uzbekistán",
      fr: "Ouzbékistan",
      ja: "ウズベキスタン",
      it: "Uzbekistan",
      cn: "乌兹别克斯坦",
      tr: "Özbekistan",
    },
    latitude: "41.00000000",
    longitude: "64.00000000",
    emoji: "🇺🇿",
    emojiU: "U+1F1FA U+1F1FF",
    states: [
      {
        id: 2540,
        name: "Andijan Region",
        state_code: "AN",
        latitude: "40.76859410",
        longitude: "72.23637900",
        type: null,
      },
      {
        id: 2541,
        name: "Bukhara Region",
        state_code: "BU",
        latitude: "40.25041620",
        longitude: "63.20321510",
        type: null,
      },
      {
        id: 2538,
        name: "Fergana Region",
        state_code: "FA",
        latitude: "40.45680810",
        longitude: "71.28742090",
        type: null,
      },
      {
        id: 2545,
        name: "Jizzakh Region",
        state_code: "JI",
        latitude: "40.47064150",
        longitude: "67.57085360",
        type: null,
      },
      {
        id: 2548,
        name: "Karakalpakstan",
        state_code: "QR",
        latitude: "43.80413340",
        longitude: "59.44579880",
        type: null,
      },
      {
        id: 2537,
        name: "Namangan Region",
        state_code: "NG",
        latitude: "41.05100370",
        longitude: "71.09731700",
        type: null,
      },
      {
        id: 2542,
        name: "Navoiy Region",
        state_code: "NW",
        latitude: "42.69885750",
        longitude: "64.63376850",
        type: null,
      },
      {
        id: 2543,
        name: "Qashqadaryo Region",
        state_code: "QA",
        latitude: "38.89862310",
        longitude: "66.04635340",
        type: null,
      },
      {
        id: 2544,
        name: "Samarqand Region",
        state_code: "SA",
        latitude: "39.62701200",
        longitude: "66.97497310",
        type: null,
      },
      {
        id: 2547,
        name: "Sirdaryo Region",
        state_code: "SI",
        latitude: "40.38638080",
        longitude: "68.71549750",
        type: null,
      },
      {
        id: 2546,
        name: "Surxondaryo Region",
        state_code: "SU",
        latitude: "37.94090050",
        longitude: "67.57085360",
        type: null,
      },
      {
        id: 2536,
        name: "Tashkent",
        state_code: "TK",
        latitude: "41.29949580",
        longitude: "69.24007340",
        type: null,
      },
      {
        id: 2549,
        name: "Tashkent Region",
        state_code: "TO",
        latitude: "41.22132340",
        longitude: "69.85974060",
        type: null,
      },
      {
        id: 2539,
        name: "Xorazm Region",
        state_code: "XO",
        latitude: "41.35653360",
        longitude: "60.85666860",
        type: null,
      },
    ],
  },
  {
    name: "Vanuatu",
    iso3: "VUT",
    iso2: "VU",
    numeric_code: "548",
    phone_code: "678",
    capital: "Port Vila",
    currency: "VUV",
    currency_name: "Vanuatu vatu",
    currency_symbol: "VT",
    tld: ".vu",
    native: "Vanuatu",
    region: "Oceania",
    region_id: "5",
    subregion: "Melanesia",
    subregion_id: "20",
    nationality: "Ni-Vanuatu, Vanuatuan",
    timezones: [
      {
        zoneName: "Pacific/Efate",
        gmtOffset: 39600,
        gmtOffsetName: "UTC+11:00",
        abbreviation: "VUT",
        tzName: "Vanuatu Time",
      },
    ],
    translations: {
      kr: "바누아투",
      "pt-BR": "Vanuatu",
      pt: "Vanuatu",
      nl: "Vanuatu",
      hr: "Vanuatu",
      fa: "وانواتو",
      de: "Vanuatu",
      es: "Vanuatu",
      fr: "Vanuatu",
      ja: "バヌアツ",
      it: "Vanuatu",
      cn: "瓦努阿图",
      tr: "Vanuatu",
    },
    latitude: "-16.00000000",
    longitude: "167.00000000",
    emoji: "🇻🇺",
    emojiU: "U+1F1FB U+1F1FA",
    states: [
      {
        id: 4775,
        name: "Malampa",
        state_code: "MAP",
        latitude: "-16.40114050",
        longitude: "167.60778650",
        type: null,
      },
      {
        id: 4773,
        name: "Penama",
        state_code: "PAM",
        latitude: "-15.37957580",
        longitude: "167.90531820",
        type: null,
      },
      {
        id: 4776,
        name: "Sanma",
        state_code: "SAM",
        latitude: "-15.48400170",
        longitude: "166.91820970",
        type: null,
      },
      {
        id: 4774,
        name: "Shefa",
        state_code: "SEE",
        latitude: "32.80576500",
        longitude: "35.16997100",
        type: null,
      },
      {
        id: 4777,
        name: "Tafea",
        state_code: "TAE",
        latitude: "-18.72378270",
        longitude: "169.06450560",
        type: null,
      },
      {
        id: 4772,
        name: "Torba",
        state_code: "TOB",
        latitude: "37.07653000",
        longitude: "27.45657300",
        type: null,
      },
    ],
  },
  {
    name: "Vatican City State (Holy See)",
    iso3: "VAT",
    iso2: "VA",
    numeric_code: "336",
    phone_code: "379",
    capital: "Vatican City",
    currency: "EUR",
    currency_name: "Euro",
    currency_symbol: "€",
    tld: ".va",
    native: "Vaticano",
    region: "Europe",
    region_id: "4",
    subregion: "Southern Europe",
    subregion_id: "16",
    nationality: "Vatican",
    timezones: [
      {
        zoneName: "Europe/Vatican",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "CET",
        tzName: "Central European Time",
      },
    ],
    translations: {
      kr: "바티칸 시국",
      "pt-BR": "Vaticano",
      pt: "Vaticano",
      nl: "Heilige Stoel",
      hr: "Sveta Stolica",
      fa: "سریر مقدس",
      de: "Heiliger Stuhl",
      es: "Santa Sede",
      fr: "voir Saint",
      ja: "聖座",
      it: "Santa Sede",
      cn: "梵蒂冈",
      tr: "Vatikan",
    },
    latitude: "41.90000000",
    longitude: "12.45000000",
    emoji: "🇻🇦",
    emojiU: "U+1F1FB U+1F1E6",
    states: [],
  },
  {
    name: "Venezuela",
    iso3: "VEN",
    iso2: "VE",
    numeric_code: "862",
    phone_code: "58",
    capital: "Caracas",
    currency: "VES",
    currency_name: "Bolívar",
    currency_symbol: "Bs",
    tld: ".ve",
    native: "Venezuela",
    region: "Americas",
    region_id: "2",
    subregion: "South America",
    subregion_id: "8",
    nationality: "Venezuelan",
    timezones: [
      {
        zoneName: "America/Caracas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "VET",
        tzName: "Venezuelan Standard Time",
      },
    ],
    translations: {
      kr: "베네수엘라",
      "pt-BR": "Venezuela",
      pt: "Venezuela",
      nl: "Venezuela",
      hr: "Venezuela",
      fa: "ونزوئلا",
      de: "Venezuela",
      es: "Venezuela",
      fr: "Venezuela",
      ja: "ベネズエラ・ボリバル共和国",
      it: "Venezuela",
      cn: "委内瑞拉",
      tr: "Venezuela",
    },
    latitude: "8.00000000",
    longitude: "-66.00000000",
    emoji: "🇻🇪",
    emojiU: "U+1F1FB U+1F1EA",
    states: [
      {
        id: 2044,
        name: "Amazonas",
        state_code: "Z",
        latitude: "-3.41684270",
        longitude: "-65.85606460",
        type: "state",
      },
      {
        id: 2050,
        name: "Anzoátegui",
        state_code: "B",
        latitude: "8.59130730",
        longitude: "-63.95861110",
        type: "state",
      },
      {
        id: 4856,
        name: "Apure",
        state_code: "C",
        latitude: "6.92694830",
        longitude: "-68.52471490",
        type: "state",
      },
      {
        id: 2047,
        name: "Aragua",
        state_code: "D",
        latitude: "10.06357580",
        longitude: "-67.28478750",
        type: "state",
      },
      {
        id: 2049,
        name: "Barinas",
        state_code: "E",
        latitude: "8.62314980",
        longitude: "-70.23710450",
        type: "state",
      },
      {
        id: 2039,
        name: "Bolívar",
        state_code: "F",
        latitude: "37.61448380",
        longitude: "-93.41047490",
        type: "state",
      },
      {
        id: 2040,
        name: "Carabobo",
        state_code: "G",
        latitude: "10.11764330",
        longitude: "-68.04775090",
        type: "state",
      },
      {
        id: 2034,
        name: "Cojedes",
        state_code: "H",
        latitude: "9.38166820",
        longitude: "-68.33392750",
        type: "state",
      },
      {
        id: 2051,
        name: "Delta Amacuro",
        state_code: "Y",
        latitude: "8.84993070",
        longitude: "-61.14031960",
        type: "state",
      },
      {
        id: 4855,
        name: "Distrito Capital",
        state_code: "A",
        latitude: "41.26148460",
        longitude: "-95.93108070",
        type: "capital district",
      },
      {
        id: 2035,
        name: "Falcón",
        state_code: "I",
        latitude: "11.18106740",
        longitude: "-69.85974060",
        type: "state",
      },
      {
        id: 2046,
        name: "Federal Dependencies of Venezuela",
        state_code: "W",
        latitude: "10.93770530",
        longitude: "-65.35695730",
        type: "federal dependency",
      },
      {
        id: 2045,
        name: "Guárico",
        state_code: "J",
        latitude: "8.74893090",
        longitude: "-66.23671720",
        type: "state",
      },
      {
        id: 2055,
        name: "La Guaira",
        state_code: "X",
        latitude: "29.30522680",
        longitude: "-94.79138540",
        type: "state",
      },
      {
        id: 2038,
        name: "Lara",
        state_code: "K",
        latitude: "33.98221650",
        longitude: "-118.13227470",
        type: "state",
      },
      {
        id: 2053,
        name: "Mérida",
        state_code: "L",
        latitude: "20.96737020",
        longitude: "-89.59258570",
        type: "state",
      },
      {
        id: 2037,
        name: "Miranda",
        state_code: "M",
        latitude: "42.35193830",
        longitude: "-71.52907660",
        type: "state",
      },
      {
        id: 2054,
        name: "Monagas",
        state_code: "N",
        latitude: "9.32416520",
        longitude: "-63.01475780",
        type: "state",
      },
      {
        id: 2052,
        name: "Nueva Esparta",
        state_code: "O",
        latitude: "10.99707230",
        longitude: "-63.91132960",
        type: "state",
      },
      {
        id: 2036,
        name: "Portuguesa",
        state_code: "P",
        latitude: "9.09439990",
        longitude: "-69.09702300",
        type: "state",
      },
      {
        id: 2056,
        name: "Sucre",
        state_code: "R",
        latitude: "-19.03534500",
        longitude: "-65.25921280",
        type: "state",
      },
      {
        id: 2048,
        name: "Táchira",
        state_code: "S",
        latitude: "7.91370010",
        longitude: "-72.14161320",
        type: "state",
      },
      {
        id: 2043,
        name: "Trujillo",
        state_code: "T",
        latitude: "36.67343430",
        longitude: "-121.62875880",
        type: "state",
      },
      {
        id: 2041,
        name: "Yaracuy",
        state_code: "U",
        latitude: "10.33938900",
        longitude: "-68.81088490",
        type: "state",
      },
      {
        id: 2042,
        name: "Zulia",
        state_code: "V",
        latitude: "10.29102370",
        longitude: "-72.14161320",
        type: "state",
      },
    ],
  },
  {
    name: "Vietnam",
    iso3: "VNM",
    iso2: "VN",
    numeric_code: "704",
    phone_code: "84",
    capital: "Hanoi",
    currency: "VND",
    currency_name: "Vietnamese đồng",
    currency_symbol: "₫",
    tld: ".vn",
    native: "Việt Nam",
    region: "Asia",
    region_id: "3",
    subregion: "South-Eastern Asia",
    subregion_id: "13",
    nationality: "Vietnamese",
    timezones: [
      {
        zoneName: "Asia/Ho_Chi_Minh",
        gmtOffset: 25200,
        gmtOffsetName: "UTC+07:00",
        abbreviation: "ICT",
        tzName: "Indochina Time",
      },
    ],
    translations: {
      kr: "베트남",
      "pt-BR": "Vietnã",
      pt: "Vietname",
      nl: "Vietnam",
      hr: "Vijetnam",
      fa: "ویتنام",
      de: "Vietnam",
      es: "Vietnam",
      fr: "Viêt Nam",
      ja: "ベトナム",
      it: "Vietnam",
      cn: "越南",
      tr: "Vietnam",
    },
    latitude: "16.16666666",
    longitude: "107.83333333",
    emoji: "🇻🇳",
    emojiU: "U+1F1FB U+1F1F3",
    states: [
      {
        id: 3794,
        name: "An Giang",
        state_code: "44",
        latitude: "10.52158360",
        longitude: "105.12589550",
        type: null,
      },
      {
        id: 3770,
        name: "Bà Rịa-Vũng Tàu",
        state_code: "43",
        latitude: "10.54173970",
        longitude: "107.24299760",
        type: null,
      },
      {
        id: 3815,
        name: "Bắc Giang",
        state_code: "54",
        latitude: "21.28199210",
        longitude: "106.19747690",
        type: null,
      },
      {
        id: 3822,
        name: "Bắc Kạn",
        state_code: "53",
        latitude: "22.30329230",
        longitude: "105.87600400",
        type: null,
      },
      {
        id: 3804,
        name: "Bạc Liêu",
        state_code: "55",
        latitude: "9.29400270",
        longitude: "105.72156630",
        type: null,
      },
      {
        id: 3791,
        name: "Bắc Ninh",
        state_code: "56",
        latitude: "21.12144400",
        longitude: "106.11105010",
        type: null,
      },
      {
        id: 3796,
        name: "Bến Tre",
        state_code: "50",
        latitude: "10.24335560",
        longitude: "106.37555100",
        type: null,
      },
      {
        id: 3785,
        name: "Bình Dương",
        state_code: "57",
        latitude: "11.32540240",
        longitude: "106.47701700",
        type: null,
      },
      {
        id: 3830,
        name: "Bình Định",
        state_code: "31",
        latitude: "14.16653240",
        longitude: "108.90268300",
        type: null,
      },
      {
        id: 3797,
        name: "Bình Phước",
        state_code: "58",
        latitude: "11.75118940",
        longitude: "106.72346390",
        type: null,
      },
      {
        id: 3787,
        name: "Bình Thuận",
        state_code: "40",
        latitude: "11.09037030",
        longitude: "108.07207810",
        type: null,
      },
      {
        id: 3778,
        name: "Cà Mau",
        state_code: "59",
        latitude: "9.15267280",
        longitude: "105.19607950",
        type: null,
      },
      {
        id: 4925,
        name: "Cần Thơ",
        state_code: "CT",
        latitude: "10.03418510",
        longitude: "105.72255070",
        type: null,
      },
      {
        id: 3782,
        name: "Cao Bằng",
        state_code: "04",
        latitude: "22.63568900",
        longitude: "106.25221430",
        type: null,
      },
      {
        id: 3806,
        name: "Đà Nẵng",
        state_code: "DN",
        latitude: "16.05440680",
        longitude: "108.20216670",
        type: null,
      },
      {
        id: 3829,
        name: "Đắk Lắk",
        state_code: "33",
        latitude: "12.71001160",
        longitude: "108.23775190",
        type: null,
      },
      {
        id: 3823,
        name: "Đắk Nông",
        state_code: "72",
        latitude: "12.26464760",
        longitude: "107.60980600",
        type: null,
      },
      {
        id: 3773,
        name: "Điện Biên",
        state_code: "71",
        latitude: "21.80423090",
        longitude: "103.10765250",
        type: null,
      },
      {
        id: 3821,
        name: "Đồng Nai",
        state_code: "39",
        latitude: "11.06863050",
        longitude: "107.16759760",
        type: null,
      },
      {
        id: 3769,
        name: "Đồng Tháp",
        state_code: "45",
        latitude: "10.49379890",
        longitude: "105.68817880",
        type: null,
      },
      {
        id: 3813,
        name: "Gia Lai",
        state_code: "30",
        latitude: "13.80789430",
        longitude: "108.10937500",
        type: null,
      },
      {
        id: 3779,
        name: "Hà Giang",
        state_code: "03",
        latitude: "22.80255880",
        longitude: "104.97844940",
        type: null,
      },
      {
        id: 3802,
        name: "Hà Nam",
        state_code: "63",
        latitude: "20.58351960",
        longitude: "105.92299000",
        type: null,
      },
      {
        id: 3810,
        name: "Hà Nội",
        state_code: "HN",
        latitude: "21.02776440",
        longitude: "105.83415980",
        type: null,
      },
      {
        id: 3816,
        name: "Hà Tĩnh",
        state_code: "23",
        latitude: "18.35595370",
        longitude: "105.88774940",
        type: null,
      },
      {
        id: 3827,
        name: "Hải Dương",
        state_code: "61",
        latitude: "20.93734130",
        longitude: "106.31455420",
        type: null,
      },
      {
        id: 3783,
        name: "Hải Phòng",
        state_code: "HP",
        latitude: "20.84491150",
        longitude: "106.68808410",
        type: null,
      },
      {
        id: 3777,
        name: "Hậu Giang",
        state_code: "73",
        latitude: "9.75789800",
        longitude: "105.64125270",
        type: null,
      },
      {
        id: 3811,
        name: "Hồ Chí Minh",
        state_code: "SG",
        latitude: "10.82309890",
        longitude: "106.62966380",
        type: null,
      },
      {
        id: 3799,
        name: "Hòa Bình",
        state_code: "14",
        latitude: "20.68612650",
        longitude: "105.31311850",
        type: null,
      },
      {
        id: 3768,
        name: "Hưng Yên",
        state_code: "66",
        latitude: "20.85257110",
        longitude: "106.01699710",
        type: null,
      },
      {
        id: 3793,
        name: "Khánh Hòa",
        state_code: "34",
        latitude: "12.25850980",
        longitude: "109.05260760",
        type: null,
      },
      {
        id: 3800,
        name: "Kiên Giang",
        state_code: "47",
        latitude: "9.82495870",
        longitude: "105.12589550",
        type: null,
      },
      {
        id: 3772,
        name: "Kon Tum",
        state_code: "28",
        latitude: "14.34974030",
        longitude: "108.00046060",
        type: null,
      },
      {
        id: 3825,
        name: "Lai Châu",
        state_code: "01",
        latitude: "22.38622270",
        longitude: "103.47026310",
        type: null,
      },
      {
        id: 3818,
        name: "Lâm Đồng",
        state_code: "35",
        latitude: "11.57527910",
        longitude: "108.14286690",
        type: null,
      },
      {
        id: 3792,
        name: "Lạng Sơn",
        state_code: "09",
        latitude: "21.85370800",
        longitude: "106.76151900",
        type: null,
      },
      {
        id: 3817,
        name: "Lào Cai",
        state_code: "02",
        latitude: "22.48094310",
        longitude: "103.97549590",
        type: null,
      },
      {
        id: 3808,
        name: "Long An",
        state_code: "41",
        latitude: "10.56071680",
        longitude: "106.64976230",
        type: null,
      },
      {
        id: 3789,
        name: "Nam Định",
        state_code: "67",
        latitude: "20.43882250",
        longitude: "106.16210530",
        type: null,
      },
      {
        id: 3780,
        name: "Nghệ An",
        state_code: "22",
        latitude: "19.23424890",
        longitude: "104.92003650",
        type: null,
      },
      {
        id: 3786,
        name: "Ninh Bình",
        state_code: "18",
        latitude: "20.25061490",
        longitude: "105.97445360",
        type: null,
      },
      {
        id: 3788,
        name: "Ninh Thuận",
        state_code: "36",
        latitude: "11.67387670",
        longitude: "108.86295720",
        type: null,
      },
      {
        id: 3801,
        name: "Phú Thọ",
        state_code: "68",
        latitude: "21.26844300",
        longitude: "105.20455730",
        type: null,
      },
      {
        id: 3824,
        name: "Phú Yên",
        state_code: "32",
        latitude: "13.08818610",
        longitude: "109.09287640",
        type: null,
      },
      {
        id: 3809,
        name: "Quảng Bình",
        state_code: "24",
        latitude: "17.61027150",
        longitude: "106.34874740",
        type: null,
      },
      {
        id: 3776,
        name: "Quảng Nam",
        state_code: "27",
        latitude: "15.53935380",
        longitude: "108.01910200",
        type: null,
      },
      {
        id: 3828,
        name: "Quảng Ngãi",
        state_code: "29",
        latitude: "15.12138730",
        longitude: "108.80441450",
        type: null,
      },
      {
        id: 3814,
        name: "Quảng Ninh",
        state_code: "13",
        latitude: "21.00638200",
        longitude: "107.29251440",
        type: null,
      },
      {
        id: 3803,
        name: "Quảng Trị",
        state_code: "25",
        latitude: "16.74030740",
        longitude: "107.18546790",
        type: null,
      },
      {
        id: 3819,
        name: "Sóc Trăng",
        state_code: "52",
        latitude: "9.60252100",
        longitude: "105.97390490",
        type: null,
      },
      {
        id: 3812,
        name: "Sơn La",
        state_code: "05",
        latitude: "21.10222840",
        longitude: "103.72891670",
        type: null,
      },
      {
        id: 3826,
        name: "Tây Ninh",
        state_code: "37",
        latitude: "11.33515540",
        longitude: "106.10988540",
        type: null,
      },
      {
        id: 3775,
        name: "Thái Bình",
        state_code: "20",
        latitude: "20.44634710",
        longitude: "106.33658280",
        type: null,
      },
      {
        id: 3807,
        name: "Thái Nguyên",
        state_code: "69",
        latitude: "21.56715590",
        longitude: "105.82520380",
        type: null,
      },
      {
        id: 3771,
        name: "Thanh Hóa",
        state_code: "21",
        latitude: "19.80669200",
        longitude: "105.78518160",
        type: null,
      },
      {
        id: 3798,
        name: "Thừa Thiên-Huế",
        state_code: "26",
        latitude: "16.46739700",
        longitude: "107.59053260",
        type: null,
      },
      {
        id: 3781,
        name: "Tiền Giang",
        state_code: "46",
        latitude: "10.44933240",
        longitude: "106.34205040",
        type: null,
      },
      {
        id: 3805,
        name: "Trà Vinh",
        state_code: "51",
        latitude: "9.81274100",
        longitude: "106.29929120",
        type: null,
      },
      {
        id: 3795,
        name: "Tuyên Quang",
        state_code: "07",
        latitude: "21.77672460",
        longitude: "105.22801960",
        type: null,
      },
      {
        id: 3790,
        name: "Vĩnh Long",
        state_code: "49",
        latitude: "10.23957400",
        longitude: "105.95719280",
        type: null,
      },
      {
        id: 3774,
        name: "Vĩnh Phúc",
        state_code: "70",
        latitude: "21.36088050",
        longitude: "105.54743730",
        type: null,
      },
      {
        id: 3784,
        name: "Yên Bái",
        state_code: "06",
        latitude: "21.71676890",
        longitude: "104.89858780",
        type: null,
      },
    ],
  },
  {
    name: "Virgin Islands (British)",
    iso3: "VGB",
    iso2: "VG",
    numeric_code: "092",
    phone_code: "+1-284",
    capital: "Road Town",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vg",
    native: "British Virgin Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "British Virgin Island",
    timezones: [
      {
        zoneName: "America/Tortola",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "영국령 버진아일랜드",
      "pt-BR": "Ilhas Virgens Britânicas",
      pt: "Ilhas Virgens Britânicas",
      nl: "Britse Maagdeneilanden",
      hr: "Britanski Djevičanski Otoci",
      fa: "جزایر ویرجین بریتانیا",
      de: "Britische Jungferninseln",
      es: "Islas Vírgenes del Reino Unido",
      fr: "Îles Vierges britanniques",
      ja: "イギリス領ヴァージン諸島",
      it: "Isole Vergini Britanniche",
      cn: "圣文森特和格林纳丁斯",
      tr: "Britanya Virjin Adalari",
    },
    latitude: "18.43138300",
    longitude: "-64.62305000",
    emoji: "🇻🇬",
    emojiU: "U+1F1FB U+1F1EC",
    states: [],
  },
  {
    name: "Virgin Islands (US)",
    iso3: "VIR",
    iso2: "VI",
    numeric_code: "850",
    phone_code: "+1-340",
    capital: "Charlotte Amalie",
    currency: "USD",
    currency_name: "United States dollar",
    currency_symbol: "$",
    tld: ".vi",
    native: "United States Virgin Islands",
    region: "Americas",
    region_id: "2",
    subregion: "Caribbean",
    subregion_id: "7",
    nationality: "U.S. Virgin Island",
    timezones: [
      {
        zoneName: "America/St_Thomas",
        gmtOffset: -14400,
        gmtOffsetName: "UTC-04:00",
        abbreviation: "AST",
        tzName: "Atlantic Standard Time",
      },
    ],
    translations: {
      kr: "미국령 버진아일랜드",
      "pt-BR": "Ilhas Virgens Americanas",
      pt: "Ilhas Virgens Americanas",
      nl: "Verenigde Staten Maagdeneilanden",
      fa: "جزایر ویرجین آمریکا",
      de: "Amerikanische Jungferninseln",
      es: "Islas Vírgenes de los Estados Unidos",
      fr: "Îles Vierges des États-Unis",
      ja: "アメリカ領ヴァージン諸島",
      it: "Isole Vergini americane",
      cn: "维尔京群岛（美国）",
      tr: "Abd Virjin Adalari",
    },
    latitude: "18.34000000",
    longitude: "-64.93000000",
    emoji: "🇻🇮",
    emojiU: "U+1F1FB U+1F1EE",
    states: [
      {
        id: 5074,
        name: "Saint Croix",
        state_code: "SC",
        latitude: "17.72935200",
        longitude: "-64.73437050",
        type: "district",
      },
      {
        id: 5073,
        name: "Saint John",
        state_code: "SJ",
        latitude: "18.33561690",
        longitude: "-64.80028000",
        type: "district",
      },
      {
        id: 5072,
        name: "Saint Thomas",
        state_code: "ST",
        latitude: "18.34284590",
        longitude: "-65.07701800",
        type: "district",
      },
    ],
  },
  {
    name: "Wallis and Futuna Islands",
    iso3: "WLF",
    iso2: "WF",
    numeric_code: "876",
    phone_code: "681",
    capital: "Mata Utu",
    currency: "XPF",
    currency_name: "CFP franc",
    currency_symbol: "₣",
    tld: ".wf",
    native: "Wallis et Futuna",
    region: "Oceania",
    region_id: "5",
    subregion: "Polynesia",
    subregion_id: "22",
    nationality: "Wallis and Futuna, Wallisian or Futunan",
    timezones: [
      {
        zoneName: "Pacific/Wallis",
        gmtOffset: 43200,
        gmtOffsetName: "UTC+12:00",
        abbreviation: "WFT",
        tzName: "Wallis & Futuna Time",
      },
    ],
    translations: {
      kr: "왈리스 푸투나",
      "pt-BR": "Wallis e Futuna",
      pt: "Wallis e Futuna",
      nl: "Wallis en Futuna",
      hr: "Wallis i Fortuna",
      fa: "والیس و فوتونا",
      de: "Wallis und Futuna",
      es: "Wallis y Futuna",
      fr: "Wallis-et-Futuna",
      ja: "ウォリス・フツナ",
      it: "Wallis e Futuna",
      cn: "瓦利斯群岛和富图纳群岛",
      tr: "Wallis Ve Futuna",
    },
    latitude: "-13.30000000",
    longitude: "-176.20000000",
    emoji: "🇼🇫",
    emojiU: "U+1F1FC U+1F1EB",
    states: [],
  },
  {
    name: "Western Sahara",
    iso3: "ESH",
    iso2: "EH",
    numeric_code: "732",
    phone_code: "212",
    capital: "El-Aaiun",
    currency: "MAD",
    currency_name: "Moroccan Dirham",
    currency_symbol: "MAD",
    tld: ".eh",
    native: "الصحراء الغربية",
    region: "Africa",
    region_id: "1",
    subregion: "Northern Africa",
    subregion_id: "1",
    nationality: "Sahrawi, Sahrawian, Sahraouian",
    timezones: [
      {
        zoneName: "Africa/El_Aaiun",
        gmtOffset: 3600,
        gmtOffsetName: "UTC+01:00",
        abbreviation: "WEST",
        tzName: "Western European Summer Time",
      },
    ],
    translations: {
      kr: "서사하라",
      "pt-BR": "Saara Ocidental",
      pt: "Saara Ocidental",
      nl: "Westelijke Sahara",
      hr: "Zapadna Sahara",
      fa: "جمهوری دموکراتیک عربی صحرا",
      de: "Westsahara",
      es: "Sahara Occidental",
      fr: "Sahara Occidental",
      ja: "西サハラ",
      it: "Sahara Occidentale",
      cn: "西撒哈拉",
      tr: "Bati Sahra",
    },
    latitude: "24.50000000",
    longitude: "-13.00000000",
    emoji: "🇪🇭",
    emojiU: "U+1F1EA U+1F1ED",
    states: [],
  },
  {
    name: "Yemen",
    iso3: "YEM",
    iso2: "YE",
    numeric_code: "887",
    phone_code: "967",
    capital: "Sanaa",
    currency: "YER",
    currency_name: "Yemeni rial",
    currency_symbol: "﷼",
    tld: ".ye",
    native: "اليَمَن",
    region: "Asia",
    region_id: "3",
    subregion: "Western Asia",
    subregion_id: "11",
    nationality: "Yemeni",
    timezones: [
      {
        zoneName: "Asia/Aden",
        gmtOffset: 10800,
        gmtOffsetName: "UTC+03:00",
        abbreviation: "AST",
        tzName: "Arabia Standard Time",
      },
    ],
    translations: {
      kr: "예멘",
      "pt-BR": "Iêmen",
      pt: "Iémen",
      nl: "Jemen",
      hr: "Jemen",
      fa: "یمن",
      de: "Jemen",
      es: "Yemen",
      fr: "Yémen",
      ja: "イエメン",
      it: "Yemen",
      cn: "也门",
      tr: "Yemen",
    },
    latitude: "15.00000000",
    longitude: "48.00000000",
    emoji: "🇾🇪",
    emojiU: "U+1F1FE U+1F1EA",
    states: [
      {
        id: 1242,
        name: "'Adan",
        state_code: "AD",
        latitude: "12.82574810",
        longitude: "44.79438040",
        type: null,
      },
      {
        id: 1250,
        name: "'Amran",
        state_code: "AM",
        latitude: "16.25692140",
        longitude: "43.94367880",
        type: null,
      },
      {
        id: 1237,
        name: "Abyan",
        state_code: "AB",
        latitude: "13.63434130",
        longitude: "46.05632120",
        type: null,
      },
      {
        id: 1240,
        name: "Al Bayda'",
        state_code: "BA",
        latitude: "14.35886620",
        longitude: "45.44980650",
        type: null,
      },
      {
        id: 1241,
        name: "Al Hudaydah",
        state_code: "HU",
        latitude: "15.30530720",
        longitude: "43.01948970",
        type: null,
      },
      {
        id: 1243,
        name: "Al Jawf",
        state_code: "JA",
        latitude: "16.79018190",
        longitude: "45.29938620",
        type: null,
      },
      {
        id: 1251,
        name: "Al Mahrah",
        state_code: "MR",
        latitude: "16.52384230",
        longitude: "51.68342750",
        type: null,
      },
      {
        id: 1235,
        name: "Al Mahwit",
        state_code: "MW",
        latitude: "15.39632290",
        longitude: "43.56069460",
        type: null,
      },
      {
        id: 1232,
        name: "Amanat Al Asimah",
        state_code: "SA",
        latitude: "15.36944510",
        longitude: "44.19100660",
        type: null,
      },
      {
        id: 1246,
        name: "Dhamar",
        state_code: "DH",
        latitude: "14.71953440",
        longitude: "44.24790150",
        type: null,
      },
      {
        id: 1238,
        name: "Hadhramaut",
        state_code: "HD",
        latitude: "16.93041350",
        longitude: "49.36531490",
        type: null,
      },
      {
        id: 1244,
        name: "Hajjah",
        state_code: "HJ",
        latitude: "16.11806310",
        longitude: "43.32946600",
        type: null,
      },
      {
        id: 1233,
        name: "Ibb",
        state_code: "IB",
        latitude: "14.14157170",
        longitude: "44.24790150",
        type: null,
      },
      {
        id: 1245,
        name: "Lahij",
        state_code: "LA",
        latitude: "13.14895880",
        longitude: "44.85054950",
        type: null,
      },
      {
        id: 1234,
        name: "Ma'rib",
        state_code: "MA",
        latitude: "15.51588800",
        longitude: "45.44980650",
        type: null,
      },
      {
        id: 1248,
        name: "Raymah",
        state_code: "RA",
        latitude: "14.62776820",
        longitude: "43.71424840",
        type: null,
      },
      {
        id: 1249,
        name: "Saada",
        state_code: "SD",
        latitude: "16.84765280",
        longitude: "43.94367880",
        type: null,
      },
      {
        id: 1236,
        name: "Sana'a",
        state_code: "SN",
        latitude: "15.31689130",
        longitude: "44.47480180",
        type: null,
      },
      {
        id: 1247,
        name: "Shabwah",
        state_code: "SH",
        latitude: "14.75463030",
        longitude: "46.51626200",
        type: null,
      },
      {
        id: 1239,
        name: "Socotra",
        state_code: "SU",
        latitude: "12.46342050",
        longitude: "53.82373850",
        type: null,
      },
      {
        id: 1231,
        name: "Ta'izz",
        state_code: "TA",
        latitude: "13.57758860",
        longitude: "44.01779890",
        type: null,
      },
    ],
  },
  {
    name: "Zambia",
    iso3: "ZMB",
    iso2: "ZM",
    numeric_code: "894",
    phone_code: "260",
    capital: "Lusaka",
    currency: "ZMW",
    currency_name: "Zambian kwacha",
    currency_symbol: "ZK",
    tld: ".zm",
    native: "Zambia",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Zambian",
    timezones: [
      {
        zoneName: "Africa/Lusaka",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "잠비아",
      "pt-BR": "Zâmbia",
      pt: "Zâmbia",
      nl: "Zambia",
      hr: "Zambija",
      fa: "زامبیا",
      de: "Sambia",
      es: "Zambia",
      fr: "Zambie",
      ja: "ザンビア",
      it: "Zambia",
      cn: "赞比亚",
      tr: "Zambiya",
    },
    latitude: "-15.00000000",
    longitude: "30.00000000",
    emoji: "🇿🇲",
    emojiU: "U+1F1FF U+1F1F2",
    states: [
      {
        id: 1986,
        name: "Central Province",
        state_code: "02",
        latitude: "7.25649960",
        longitude: "80.72144170",
        type: null,
      },
      {
        id: 1984,
        name: "Copperbelt Province",
        state_code: "08",
        latitude: "-13.05700730",
        longitude: "27.54958460",
        type: null,
      },
      {
        id: 1991,
        name: "Eastern Province",
        state_code: "03",
        latitude: "23.16696880",
        longitude: "49.36531490",
        type: null,
      },
      {
        id: 1987,
        name: "Luapula Province",
        state_code: "04",
        latitude: "-11.56483100",
        longitude: "29.04599270",
        type: null,
      },
      {
        id: 1988,
        name: "Lusaka Province",
        state_code: "09",
        latitude: "-15.36571290",
        longitude: "29.23207840",
        type: null,
      },
      {
        id: 1989,
        name: "Muchinga Province",
        state_code: "10",
        latitude: "-15.38219300",
        longitude: "28.26158000",
        type: null,
      },
      {
        id: 1982,
        name: "Northern Province",
        state_code: "05",
        latitude: "8.88550270",
        longitude: "80.27673270",
        type: null,
      },
      {
        id: 1985,
        name: "Northwestern Province",
        state_code: "06",
        latitude: "-13.00502580",
        longitude: "24.90422080",
        type: null,
      },
      {
        id: 1990,
        name: "Southern Province",
        state_code: "07",
        latitude: "6.23737500",
        longitude: "80.54384500",
        type: null,
      },
      {
        id: 1983,
        name: "Western Province",
        state_code: "01",
        latitude: "6.90160860",
        longitude: "80.00877460",
        type: null,
      },
    ],
  },
  {
    name: "Zimbabwe",
    iso3: "ZWE",
    iso2: "ZW",
    numeric_code: "716",
    phone_code: "263",
    capital: "Harare",
    currency: "ZWL",
    currency_name: "Zimbabwe Dollar",
    currency_symbol: "$",
    tld: ".zw",
    native: "Zimbabwe",
    region: "Africa",
    region_id: "1",
    subregion: "Eastern Africa",
    subregion_id: "4",
    nationality: "Zimbabwean",
    timezones: [
      {
        zoneName: "Africa/Harare",
        gmtOffset: 7200,
        gmtOffsetName: "UTC+02:00",
        abbreviation: "CAT",
        tzName: "Central Africa Time",
      },
    ],
    translations: {
      kr: "짐바브웨",
      "pt-BR": "Zimbabwe",
      pt: "Zimbabué",
      nl: "Zimbabwe",
      hr: "Zimbabve",
      fa: "زیمباوه",
      de: "Simbabwe",
      es: "Zimbabue",
      fr: "Zimbabwe",
      ja: "ジンバブエ",
      it: "Zimbabwe",
      cn: "津巴布韦",
      tr: "Zimbabve",
    },
    latitude: "-20.00000000",
    longitude: "30.00000000",
    emoji: "🇿🇼",
    emojiU: "U+1F1FF U+1F1FC",
    states: [
      {
        id: 1956,
        name: "Bulawayo Province",
        state_code: "BU",
        latitude: "-20.14895050",
        longitude: "28.53310380",
        type: null,
      },
      {
        id: 1958,
        name: "Harare Province",
        state_code: "HA",
        latitude: "-17.82162880",
        longitude: "31.04922590",
        type: null,
      },
      {
        id: 1959,
        name: "Manicaland",
        state_code: "MA",
        latitude: "-18.92163860",
        longitude: "32.17460500",
        type: null,
      },
      {
        id: 1955,
        name: "Mashonaland Central Province",
        state_code: "MC",
        latitude: "-16.76442950",
        longitude: "31.07937050",
        type: null,
      },
      {
        id: 1951,
        name: "Mashonaland East Province",
        state_code: "ME",
        latitude: "-18.58716420",
        longitude: "31.26263660",
        type: null,
      },
      {
        id: 1953,
        name: "Mashonaland West Province",
        state_code: "MW",
        latitude: "-17.48510290",
        longitude: "29.78892480",
        type: null,
      },
      {
        id: 1960,
        name: "Masvingo Province",
        state_code: "MV",
        latitude: "-20.62415090",
        longitude: "31.26263660",
        type: null,
      },
      {
        id: 1954,
        name: "Matabeleland North Province",
        state_code: "MN",
        latitude: "-18.53315660",
        longitude: "27.54958460",
        type: null,
      },
      {
        id: 1952,
        name: "Matabeleland South Province",
        state_code: "MS",
        latitude: "-21.05233700",
        longitude: "29.04599270",
        type: null,
      },
      {
        id: 1957,
        name: "Midlands Province",
        state_code: "MI",
        latitude: "-19.05520090",
        longitude: "29.60354950",
        type: null,
      },
    ],
  },
];
